import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Button } from "react-bootstrap";
import AddMoreContributionForm from "./AddMoreContributionForm";
// import { generateArrayOfYears } from '../../../utils/constants'
// import './Give.scss';
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { getUserDetails } from "../../../utils/authHelpers";
// import {giveWithAnExistingCardCheckingPortal} from '../../../store/actions/userDashboardActions/give'
// import {ColorButton} from './AddMoreContributionForm'
import { CardDetailsReview } from "./CardDetailsReview";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";

const initialValues = {
  amount: "",
  contributionType: "",
  // memo: ''
};

export const GiveWithExistingCardForm = ({
  maskedCardNumber,
  expirationDate,
  fullName,
  recordId,
  closeModal,
  token,
}) => {
  const validationSchema = yup.object().shape({
    amount: yup.string().required("Amount is required"),
    contributionType: yup.string().required("ContributionType is required"),
  });
  const [givePayload, setGivePayload] = useState({});
  const [contributionList, setContributionList] = useState(false);
  const [nextView, setNextView] = useState(false);
  // const giveWithAnExistingCardCheckingPortalData = useSelector(state => state.giveWithAnExistingCardCheckingPortalData)
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const user = getUserDetails();
  const themeColor = useThemeColor();

  // const arrayOfYears = generateArrayOfYears();

  const dispatch = useDispatch();

  const listOfContribution = (updatedValues) => {
    if (updatedValues?.listOfContributions?.length) {
      setContributionList(updatedValues.listOfContributions);
    }
    // setContributionValues(updatedValues)
  };

  const handleSubmit = (values) => {
    let payload = {
      recordId,
      orgId: orgInfo.orgId,
      userId: user.userId,
      givingCurrency: orgInfo.defaultCurrency,
      listOfContributions: [
        {
          amount: values.amount,
          contributionType: values.contributionType,
          memo: values.memo,
        },
      ],
      token: token || undefined,
    };
    if (contributionList) {
      payload.listOfContributions = [
        ...payload.listOfContributions,
        ...contributionList,
      ];
    }
    setGivePayload(payload);
    setNextView(true);
    // dispatch(giveWithAnExistingCardCheckingPortal(payload, closeModal))
  };

  return (
    <>
      {nextView ? (
        <CardDetailsReview
          existingGive={true}
          maskedCardNumber={maskedCardNumber}
          expirationDate={expirationDate}
          fullName={fullName}
          giveFormData={givePayload}
          closeModal={closeModal}
        />
      ) : (
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              // isSubmitting,
              // setSubmitting,
              setFieldValue,
            }) => (
              <form className="px-4" onSubmit={handleSubmit}>
                <p>
                  <i className="fa fa-credit-card"></i>&nbsp;
                  {`Card to use: ${maskedCardNumber}`}
                </p>
                <AddMoreContributionForm
                  listOfContribution={listOfContribution}
                  btnWidth={12}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                />
                <Grid container spacing={3} className="mt-5">
                  <Grid item xs={6}>
                    <TextField
                      label="Amount"
                      name="amount"
                      size="small"
                      variant="outlined"
                      type="number"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.amount || ""}
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Contribution Type"
                      name="contributionType"
                      size="small"
                      variant="outlined"
                      fullWidth
                      select
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contributionType || ""}
                      error={Boolean(
                        touched.contributionType && errors.contributionType
                      )}
                      helperText={
                        touched.contributionType && errors.contributionType
                      }
                    >
                      {orgInfo.contributionTypes?.map((list) => (
                        <MenuItem value={list} key={list}>
                          {list}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="mb-8"
                      label="Memo"
                      name="memo"
                      maxLength={100}
                      size="small"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      // defaultValue={''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      value={values.memo || ""}
                      error={Boolean(touched.memo && errors.memo)}
                      helperText={touched.memo && errors.memo}
                    />
                  </Grid>
                </Grid>

                {/* <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <ColorButton className="btn px-10 py-3 mt-5" type="submit" variant="contained" color='primary' backgroundSecondaryColor={backgroundSecondaryColor} backgroundColor={backgroundColor}>
                                    <span>{giveWithAnExistingCardCheckingPortalData?.loading ?  'Please wait...' : 'Give'}</span>
                                    {giveWithAnExistingCardCheckingPortalData?.loading && <span className="ml-3 spinner spinner-white"></span>}
                                  </ColorButton>
                                </Grid>
                            </Grid> */}
                <div className="d-flex w-100 align-items-center justify-content-end">
                  <Button
                    type="submit"
                    className="btn w-100 btn-primary font-weight-bold py-3 px-9 mb-5"
                    style={themeColor.btnStyle}
                  >
                    Next
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
