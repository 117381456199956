import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router";
import { checkIsActive } from "../../_metronic/_helpers";
import { Brand } from "../../components/Brand";
import { useHistory } from "react-router-dom";
import {
  CustomAccordion,
  CustomMenu,
} from "../../components/Common/CustomAccordion.jsx";
import { useWindowDimensions } from "../../utils/customHooks/useWindowDimensions";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import { UserProfileDropdown } from "../../components/UserProfileDropdown";
import { logout } from "../../utils/authHelpers";
import "./Layout.scss";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import {
  getUserProfile,
  contributionProcessingEngines,
} from "../../store/actions";
import { getForm } from "../../store/actions/followup";
import PageLoader from "../../components/Loader/PageLoader";

const drawerWidth = 240;

const useStyles = makeStyles((theme, themeColor) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: "#fff",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    padding: theme.spacing(3),
    width: "100%",
  },
  contentSmallScreen: {
    padding: "10px 5px",
    width: "100%",
  },
}));

function Layout(props) {
  // const urlParam = window.location.pathname;
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const themeColor = useThemeColor();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  const { window, children } = props;
  // const getThemeChangeData = useSelector(state => state.getThemeChangeData, shallowEqual)
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const getFollowUpByOrganizationData = useSelector(
    (state) => state.getFollowUpByOrganization
  );
  const getFormData = useSelector((state) => state.getForm.responseData);
  const contributionProcessingEnginesData = useSelector(
    (state) =>
      state.contributionProcessingEnginesData?.responseData?.returnedRecords
  );
  const classes = useStyles(themeColor);
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [linkRoute, setLinkRoute] = useState("");
  const [idleTiming, setIdleTiming] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const { pathname } = location;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOnIdle = (event) => {
    setIdleTiming(true);
  };

  // console.log(userProfileData, 'userProfileData');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.responseData) {
      dispatch(
        getUserProfile({
          orgId: orgInfo?.orgId,
          userId: user?.responseData?.userId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (orgInfo?.orgId) {
      dispatch(contributionProcessingEngines({ orgId: orgInfo.orgId }));
    }
  }, []);
  useEffect(() => {
    if (getFollowUpByOrganizationData?.responseData) {
      dispatch(
        getForm(getFollowUpByOrganizationData.responseData?.followUpFormId)
      );
    }
  }, [getFollowUpByOrganizationData]);

  const handleOnAction = (event) => {
    setIdleTiming(false);
    clearTimeout(timeoutId);
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: userProfileData
      ? Number(userProfileData?.inactivitySessionTimeOut) * 1000
      : 900000,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    if (idleTiming) {
      const id = setTimeout(() => {
        sessionStorage.setItem("session-out", 1);
        logout();
      }, 120000);
      setTimeoutId(id);
    } else {
      clearTimeout(timeoutId);
    }
  }, [idleTiming]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const MenuList1 = [
    { route: "/dashboard", menuIcon: "fas fa-home", menuText: "Dashboard" },
    {
      route: "/contribution",
      menuIcon: "fas fa-wallet",
      menuText: " Contributions",
    },
    // orgInfo?.landingPageModulesAdvanced?.newHere?.status === 'true' && getFormData?.submitted &&  {route: '/new_register_user', menuIcon:'fas fa-folder-plus', menuText: `${orgInfo?.landingPageModulesAdvanced?.newHere?.label}`},
    { route: "/church411", menuIcon: "fas fa-church", menuText: "Leadership" },
    {
      route: "/prayer",
      menuIcon: "fas fa-praying-hands",
      menuText: "Prayer Wall",
    },
    {
      route: "/testimony",
      menuIcon: "fas fa-bullhorn",
      menuText: "Testimony Wall",
    },
    {
      route: "/user/forms",
      menuIcon: "fas fa-folder-open",
      menuText: "Forms",
    },
    userProfileData?.followUpModule?.isAFollowUpTeamMember === 'true' && {
      route: '/follow_up',
      menuIcon: userProfileData?.followUpModule?.leftNavIcon,
      menuText: userProfileData?.followUpModule?.leftNavLabel
    },
    userProfileData?.supervisorModule?.isAGroupSupervisor === 'true' && {
      route: '/service_unit',
      menuIcon: userProfileData?.supervisorModule?.leftNavIcon,
      menuText: userProfileData?.supervisorModule?.leftNavLabel
    },
    { route: "/my_info", menuIcon: "fas fa-info-circle", menuText: "My Info" },
    { route: "/give_methods", menuIcon: "fas fa-gift", menuText: "Give Methods" },
    contributionProcessingEnginesData &&
      contributionProcessingEnginesData?.length === 1 && {
        route:
          contributionProcessingEnginesData[0]?.type === "CARD" ||
          contributionProcessingEnginesData[0]?.type === "CARDANDCHECK"
            ? "/user/donate"
            : contributionProcessingEnginesData[0]?.type === "PPS"
            ? "/user/donate/pps"
            : contributionProcessingEnginesData[0].type === "PAYPAL"
            ? "/paypal"
            : `/user/give${contributionProcessingEnginesData[0].type}`,
        menuIcon:
          contributionProcessingEnginesData[0].type === "CARDANDCHECK" ||
          contributionProcessingEnginesData[0].type === "CARD" ||
          contributionProcessingEnginesData[0].type === "PPS"
            ? "far fa-credit-card"
            : contributionProcessingEnginesData[0].type === "STRIPE"
            ? "fab fa-cc-stripe"
            : "fab fa-paypal",
        menuText: "Give",
      },
  ];

  // const giveMenu = [
  //   {route: '/user/donate', menuIcon:'far fa-credit-card', menuText:'Card or Check'},
  //   {route: '/paypal', menuIcon:'fab fa-paypal', menuText:'PayPal'},
  //   {route: '/paypal', menuIcon:'fab fa-paypal', menuText:'Paystack'},
  //   {route: '/paypal', menuIcon:'fab fa-paypal', menuText:'Flutterwave'},
  // ]
  const giveMenu = contributionProcessingEnginesData?.map((item) => {
    return {
      route:
        item.type === "CARD" || item.type === 'CHECK'
          ? `/user/donate`
          : item.type === "PPS"
          ? "/user/donate/pps"
          : item?.type === "PAYPAL"
          ? "/paypal"
          : `/user/give/${item.type}`,
      menuText: item?.label,
      menuIcon:
        item.type === "CARD" || item.type === "PPS" || item.type === 'CHECK'
          ? "far fa-credit-card"
          : item.type === "STRIPE"
          ? "fab fa-cc-stripe"
          : "fab fa-paypal",
    };
  });

  // console.log(contributionProcessingEnginesData, 'engine');

  const profileMenu = [
    {
      route: "/mailing",
      menuIcon: "fa fa-location-arrow",
      menuText: "Mailing Address",
    },
    {
      route: "/career",
      menuIcon: "fa fa-briefcase",
      menuText: "Career / Employment",
    },
    {
      route: "/family",
      menuIcon: "fa fa-users",
      menuText: "Family Information",
    },
    {
      route: "/emergency",
      menuIcon: "fa fa-shield-alt",
      menuText: "Emergency Contacts",
    },
  ];

  const settingMenu = [
    {
      route: "/update",
      menuIcon: "far fa-envelope",
      menuText: "Update Email / Password",
    },
    {
      route: "/twoFA",
      menuIcon: "fas fa-key",
      menuText: "Two-Factor Authentication",
    },
    {
      route: "/user/account/delete",
      menuIcon: "fas fa-trash-alt",
      menuText: "Delete my Account",
    },
  ];

  useEffect(() => {
    if (linkRoute) {
      history.push(linkRoute);
    }
    // console.log(linkRoute, expanded, 'link-expanded', pathname)
    setExpanded(true);
  }, [linkRoute, expanded]);

  const handleAdminAccessRedirect = () => {
    // console.log(window, 'window');
    // window.location.replace = `${orgInfo?.privacySecurityStuff?.adminUrl}`
    // window.open(orgInfo?.privacySecurityStuff?.adminUrl, '_blank')
  };
  const handleNewHere = () => {
    history.push("/new_register_user");
  };
  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Brand />
      </div>
      <Divider />
      <List>
        {contributionProcessingEnginesData && (
          <CustomMenu
            menuList={MenuList1}
            linkComponent={Link}
            pathname={pathname}
            color={theme.palette.text.secondary}
          />
        )}
        {contributionProcessingEnginesData?.length > 1 && (
          <CustomAccordion
            color={theme.palette.text.secondary}
            accordionIconClassName="fa fa-hand-holding-heart"
            accordionName="Give"
          >
            <CustomMenu
              menuList={giveMenu}
              linkComponent={Link}
              pathname={pathname}
              color={theme.palette.text.secondary}
            />
          </CustomAccordion>
        )}
        <CustomAccordion
          color={theme.palette.text.secondary}
          accordionIconClassName="far fa-file-alt"
          accordionName="My Profile"
        >
          <CustomMenu
            menuList={profileMenu}
            linkComponent={Link}
            pathname={pathname}
            color={theme.palette.text.secondary}
          />
        </CustomAccordion>
        <CustomAccordion
          color={theme.palette.text.secondary}
          accordionIconClassName="fas fa-cog"
          accordionName="My Settings"
        >
          <CustomMenu
            menuList={settingMenu}
            linkComponent={Link}
            pathname={pathname}
            color={theme.palette.text.secondary}
          />
        </CustomAccordion>
        {userProfileData &&
          userProfileData?.rolesAndPermissions &&
          Object.keys(userProfileData?.rolesAndPermissions).length > 0 && (
            <MenuList
              className="py-0"
              onClick={() =>
                global.window.open(
                  orgInfo?.privacySecurityStuff?.adminUrl,
                  "_blank"
                )
              }
            >
              <MenuItem className="pl-3">
                <ListItemIcon className="list-icon">
                  <span className="svg-icon menu-icon">
                    <i
                      className={`menu-icon fas fa-rocket`}
                      style={{ color: theme.palette.text.secondary }}
                    ></i>
                  </span>
                </ListItemIcon>
                <span className="menu-text">Admin Access</span>
              </MenuItem>
            </MenuList>
          )}
        <MenuList className="py-0" onClick={logout}>
          <MenuItem className="pl-3">
            <ListItemIcon className="list-icon">
              <span className="svg-icon menu-icon">
                <i
                  className={`menu-icon fas fa-sign-out-alt`}
                  style={{ color: theme.palette.text.secondary }}
                ></i>
              </span>
            </ListItemIcon>
            <span className="menu-text">Logout</span>
          </MenuItem>
        </MenuList>
        {orgInfo?.landingPageModulesAdvanced?.newHere?.status === "true" &&
          userProfileData?.showImNewHereForm === "true" && (
            <MenuList className="py-0">
              <MenuItem className="pl-3" onClick={handleNewHere}>
                <ListItemIcon className="list-icon">
                  <span className="svg-icon menu-icon">
                    <i
                      className={`far fa-address-book`}
                      style={{ color: theme.palette.text.secondary }}
                    ></i>
                  </span>
                </ListItemIcon>
                <span className="menu-text">
                  {orgInfo?.landingPageModulesAdvanced?.newHere?.label}
                </span>
              </MenuItem>
            </MenuList>
          )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // if (getFormData === undefined) {
  //   return <PageLoader />
  // }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon style={{ fontSize: 30, color: themeColor.iconColor }} />
            </IconButton>
            {width < 993 ? (
              <h4 className="m-0 text-white">
                {orgInfo ? orgInfo.fullName : ""}
              </h4>
            ) : (
              <div className="header-menu header-menu-mobile">
                <ul className={`menu-nav `}>
                  {/*begin::1 Level*/}
                  {contributionProcessingEnginesData?.length > 1 && (
                    <li
                      className={`menu-item menu-item-rel ${getMenuItemActive(
                        "/user/donate"
                      )}`}
                    >
                      <button
                        style={{
                          background: themeColor?.color,
                          padding: "2px",
                        }}
                        className="rounded btn"
                        onClick={handleClick}
                      >
                        <MenuItem className="menu-link">
                          <span className="text-white">Give</span>
                        </MenuItem>
                      </button>
                    </li>
                  )}
                  <div>
                    {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Open Menu
                  </Button> */}
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {contributionProcessingEnginesData?.map((item, index) => (
                        <MenuItem
                          component={Link}
                          onClick={handleClose}
                          to={
                            item.type === "CARD" || item.type === 'CHECK'
                              ? `/user/donate`
                              : item.type === "PPS"
                              ? `/user/donate/pps`
                              : item?.type === "PAYPAL"
                              ? "/paypal"
                              : `/user/give/${item.type}`
                          }
                          key={index}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                      {/* <MenuItem component={Link} onClick={handleClose} to="/paypal">PayPal</MenuItem> */}
                    </Menu>
                  </div>
                  {contributionProcessingEnginesData?.length === 1 && (
                    <li
                      className={`menu-item menu-item-rel ${getMenuItemActive(
                        "/user/donate"
                      )}`}
                    >
                      <button
                        style={{
                          background: themeColor?.color,
                          padding: "2px",
                        }}
                        className="rounded btn"
                      >
                        <MenuItem
                          component={Link}
                          className="menu-link"
                          to={
                            contributionProcessingEnginesData[0]?.type ===
                              "CARD" ||
                            contributionProcessingEnginesData[0]?.type ===
                              "CHECK"
                              ? "/user/donate"
                              : contributionProcessingEnginesData[0].type ===
                                "PAYPAL"
                              ? "/paypal"
                              : `/user/give${contributionProcessingEnginesData[0].type}`
                          }
                        >
                          <span className="text-white">Give</span>
                        </MenuItem>
                      </button>
                    </li>
                  )}
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      "/church411"
                    )}`}
                  >
                    <button
                      style={{ background: themeColor?.color, padding: "2px" }}
                      className="rounded btn"
                    >
                      <MenuItem
                        component={Link}
                        className="menu-link"
                        to="/church411"
                      >
                        <span className="text-white">Leadership</span>
                      </MenuItem>
                    </button>
                  </li>

                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      "/prayer"
                    )}`}
                  >
                    <button
                      style={{ background: themeColor?.color, padding: "2px" }}
                      className="rounded btn"
                    >
                      <MenuItem
                        component={Link}
                        className="menu-link"
                        to="/prayer"
                      >
                        <span className="text-white">Prayer Wall</span>
                      </MenuItem>
                    </button>
                  </li>

                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      "/testimony"
                    )}`}
                  >
                    <button
                      style={{ background: themeColor?.color, padding: "2px" }}
                      className="rounded btn"
                    >
                      <MenuItem
                        component={Link}
                        className="menu-link"
                        to="/testimony"
                      >
                        <span className="text-white">Testimony Wall</span>
                      </MenuItem>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <UserProfileDropdown />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={width <= 720 ? classes.contentSmallScreen : classes.content}
      >
        <div className={classes.toolbar} />
        {children}
      </main>
      <ConfirmDialog
        open={idleTiming}
        onConfirmDialogClose={() => setIdleTiming(false)}
        text="Your session will time out in 2 minutes!"
        onYesClick={() => setIdleTiming(false)}
        needOne={true}
        buttonTitle="Keep Me Logged In"
      />
    </div>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withRouter(Layout);
