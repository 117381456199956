import React, {useState} from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { epayByToken, processEpayByToken } from '../../store/actions';
import PageLoader from '../Loader/PageLoader';
import './GiveForm/CreditCardComponent.scss';
import images from 'react-payment-inputs/images';
import {months, generateArrayOfYears} from '../../utils/constants'
import {isEmpty, expiryDateCheck, getMaskedNumber} from '../../utils'
import { usePaymentInputs } from 'react-payment-inputs';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import { Grid, TextField, MenuItem, Button } from '@material-ui/core';
import moment from 'moment'
import { ButtonContainer } from '../Common/Button';

const inputShow = ['organizationName', 'firstName', 'lastName', 'email', 'phone', 'zipcode']
export default function Epay() {
    const {search} = useLocation()
    const history = useHistory()
    const dispatch = useDispatch();
    const themeColor = useThemeColor();
    const [cardErrors, setCardErrors] = useState({});
    const [cardNumber, setCardNumber] = useState('');
    const [cvc, setCVC] = useState('');
    const [showSummary, setShowSummary] = useState({
      show : false,
      item: {}
    })
    const [isInvalid, setIsInvalid] = useState({
      show: false,
      message: ''
    })
    const [inputDetails, setInputDetails] = useState({})
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const query = new URLSearchParams(search).get('t')
    const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
    const epayLoading = useSelector(state => state.epayByToken)
    const epayData = useSelector(state => state.epayByToken.responseData)
    // console.log(epayData, 'epay');
    const cardNumberValidator = ({ cardType }) => {
      if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard' || cardType.type === 'amex' || cardType === 'discover') {
        return;
      }
      return 'Card must be Visa, Mastercard, Amex or Discover';
    }
    const backgroundColor = themeColor.color;
    const { meta, getCardNumberProps, getCardImageProps, getCVCProps, getExpiryDateProps } = usePaymentInputs({ cardNumberValidator });
    const getErrormessage = (data) => {
      setIsInvalid({...isInvalid, show: true, message: data})
    }
    React.useEffect(() => {
        if (query) {
          setIsInvalid({...isInvalid, show: false, message: ''})
            dispatch(epayByToken({
                orgId: orgInfo.orgId,
                token: query
            }, getErrormessage))
        }
    }, [query])

    const handleChangeCardNumber = (e) => setCardNumber(e.target.value);
    const handleChangeCVC = (e) => setCVC(e.target.value);
    const handleMonthChange = e => {
      setExpiryMonth(e.target.value || null)
    }
  
    const handleYearChange = e => {
      setExpiryYear(e.target.value || null)
    }

    const handleChange = ({target: {name, value}}) => {
      setInputDetails({...inputDetails, [name]: value})
    }
    const handleCancel = () => {
      history.push('/')
    }
    const { erroredInputs } = meta;

    const handleEdit = () => {
      setShowSummary({...showSummary, show: false})
    }
    React.useEffect(() => {
      if (epayData?.returnedRecords?.length > 0) {
        const data = {}
        epayData.returnedRecords.map((item) => {
          if (item.defaultValue) {
            data[item.name] = item.defaultValue
          }
        })
        setInputDetails(data)
        console.log(data);
      }
    }, [epayData])
    
    const handleSubmit = (e) => {
      e.preventDefault()
      const maskedCreditCard = getMaskedNumber(cardNumber)
      const trimCardNum = cardNumber?.replace(/ /g, '');
      const encodedCardNum = window.btoa(trimCardNum);
      const payload = {
        cardNumber: encodedCardNum,
        maskedCreditCard, 
        cvv: cvc,
        expirationMonth: expiryMonth,
        expirationYear: expiryYear,
        ...inputDetails
      }
      setShowSummary({...showSummary, show: true, item: payload})
      window.scrollTo(0, 0)
    }
    const success = (code) => {
      if (code === '00') {
        return history.push('/epay_success')
      }
      return history.push('/epay_declined')
    }
    const handleProcessPayment = (data) => {
      let temp = {...data}
      temp['orgId'] = orgInfo.orgId
      temp['token'] = query
      dispatch(processEpayByToken(temp, success))
    }

    const confirmPage = (data) => {
      return (
        <>
        <div className="d-flex justify-content-between ">
        <div className="d-flex final-give-info-container">
            {/* <div className="final-give-info"><h4>Card: </h4><p>{creditCardInfo?.creditCardDetails?.maskedCreditCard}</p></div> */}
              {data?.maskedCreditCard &&  <div className="final-give-info"><h4 className="d-flex align-items-center mb-0">Card: </h4><p className="d-flex align-items-center mb-0">{data.maskedCreditCard}</p></div>}
            </div>
            { <p className="d-flex align-items-center mb-0">{data.expirationMonth}/{data.expirationYear.substr(data?.expirationYear?.length - 2, data.expirationYear?.length)}</p>}
        </div>
        <hr className="mb-0" />
        <div className="final-give-info-container mt-5">
              {data.organizationName && <div className="final-give-info"><h4>Name of Organisation:</h4><p>{data.organizationName}</p></div>}
              {data.firstName && <div className="final-give-info"><h4>Name:</h4><p>{data.firstName + ' ' + data.lastName}</p></div>}
              {data.amount && <div className="final-give-info"><h4>Amount:</h4><p>{data.amount + ' ' + orgInfo.defaultCurrency}</p></div>}
              {data.email && <div className="final-give-info"><h4>Email:</h4><p>{data.email}</p></div>}
              {data.phone && <div className="final-give-info"><h4>Phone:</h4><p>{data.phone}</p></div>}
              {data.memo && <div className="final-give-info"><h4>Memo:</h4><p>{data.memo}</p></div>}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={handleEdit}
          color="primary"
        >
          Edit
        </Button>
        <ButtonContainer
          type="submit"
          backgroundColor={backgroundColor}
          onClick={() => handleProcessPayment(data)}
          // className="col-sm-5 mr-5 py-4"
        >
          Process Payment
        </ButtonContainer>
      </div>
        </>
      )
    }
    if (epayLoading?.loading) {
        return <PageLoader />
    }

  return (
    <div  className="login-form login-signin" id="kt_login_signin_form">
        <div className="text-center mb-10 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
          <i className="fas fa-lock"></i>
          </span>
        </div>
        {showSummary.show ? <h3 className="font-size-h3 mb-5">Please verify before clicking on the Process Payment Button below</h3> : <h3 className="font-size-h3 mb-5">Quick Payment Portal</h3>}
        <h5 className="font-size-h6 mb-5">This form is secured using 256-bit SSL encryption</h5>
      </div>
      <hr />
      {showSummary.show ? confirmPage(showSummary.item) : isInvalid?.show ? (<h5 style={{alignContent: 'center', fontWeight: 'bold'}} className='d-flex justify-content-center'>{isInvalid.message}</h5>) : <form action="" className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
      onSubmit={handleSubmit}
      >
        {epayData?.returnedRecords?.map((item, index) => {
            return (
                <Grid key={index}>
                {item.name === 'amount' ? (
                    <Grid item xs={12}>
                    <TextField
                        name={item.name}
                        variant='outlined'
                        defaultValue={item.defaultValue}
                        required={item.isRequired === 'true' ? true : false}
                        label={item.title}
                        fullWidth
                        disabled={item.isEditable === 'true' ? false : true}
                        onChange={handleChange}
                        type='number'
                        value={inputDetails[item.name]}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{item.currency}</InputAdornment>,
                        }}
                        inputProps={
                          { readOnly: item.isEditable === 'true' ? false : true, 
                          min: item.minimumAmount,
                          max: item.maximumAmount
                        }
                        }
                        // readonly={item.isEditable === 'true' ? true : false}
                    />
                    </Grid>
                ) : inputShow.includes(item.name) ? <div className='py-3'> <TextField
                name={item.name}
                variant='outlined'
                defaultValue={item.defaultValue}
                required={item.isRequired === 'true' ? true : false}
                label={item.title}
                fullWidth
                type='text'
                onChange={handleChange}
                value={inputDetails[item.name]}
                inputProps={
                  { readOnly: item.isEditable === 'true' ? false : true, }
                }
                // readonly={item.isEditable === 'true' ? true : false}
            /> </div> : item.name === 'cardNumber' ? (
              <div className='d-flex flex-column'>
              <div className="position-relative mb-4">
          <input className="text-dark-50 form-control credit-card-input h-auto py-5 pr-21 pl-5"
            // value={cardNumber}
            required={item.isRequired === 'true' ? true : false}
            {...getCardNumberProps({ onChange: handleChangeCardNumber })}
            placeholder="Card Number"
          />
          <svg className="position-absolute ccard-img" {...getCardImageProps({ images })} style={{top: '20px', right: '4px'}} />
          <p className="font-weight-bold font-size-xs m-0 text-muted"><span className="">Acceptable cards:</span> {orgInfo.allowedCards?.map((item, index) => {return (<span>{item}{`${orgInfo.allowedCards.length === index +1 ? '' : ','}`} {' '}</span>)})}</p>
          {cardErrors.cardNumber && <small className="text-danger errorText">{erroredInputs.cardNumber}</small>}
        </div>
        </div>
            ) : item.name === 'cvv' ? (
              <div className="position-relative mb-4">
              <TextField 
                label={item.title} 
                {...getCVCProps({ onChange: handleChangeCVC, placeholder: 'CVV' })} 
                // value={cvc || ''} 
                variant="outlined" 
                fullWidth 
                required={item.isRequired === 'true' ? true : false}
                error={cardErrors.cvc} 
                placeholder="CVV"
              />
              <p className="font-weight-bold mb-0 font-size-xs text-muted">3 or 4 digits usually found on the signature strip</p>
              {cardErrors.cvc && <small className="text-danger errorText">{cardErrors.cvc}</small>}
            </div>
            ) : item.name === "expirationMonth" ? (
              // <Grid item xs={6}>
                <div className="select-container py-3">
            <TextField
              name={item.name}
              label={item.title}
              variant="outlined"
              fullWidth
              select
              required={item.isRequired === 'true' ? true : false}
              onChange={handleMonthChange}
              value={expiryMonth || ''}
            >
              {expiryYear === moment().format('YYYY') ?
                months.slice(expiryDateCheck).map((item, index) => <option key={`idx${index}`} value={item.id}>{item.value}</option>) :
                months.map((item, index) => <MenuItem key={`idx${index}`} value={item.id}>{item.value}</MenuItem>)
              }
            </TextField>
            {cardErrors.expiryMonth && <small className="text-danger errorText">{cardErrors.expiryMonth}</small>}
          </div>
              // </Grid>
            ) : item.name === "expirationYear" ? (
              // <Grid item xs={6}>
              <div className="select-container py-3">
            <TextField
              name={item.name}
              label={item.title}
              onChange={handleYearChange}
              value={expiryYear || ''}
              variant="outlined"
              fullWidth
              select
              required={item.isRequired === 'true' ? true : false}
            >
              {generateArrayOfYears().map((year, index) => <MenuItem key={`idx${index}`} value={year}>{year}</MenuItem>)}
            </TextField>
            {cardErrors.expiryYear && <small className="text-danger errorText">{cardErrors.expiryYear}</small>}
          </div>
          // </Grid>
            ) : item.name === 'memo' ? (
              <div className='py-3'> <TextField
                name={item.name}
                variant='outlined'
                defaultValue={item.defaultValue}
                required={item.isRequired === 'true' ? true : false}
                label={item.title}
                fullWidth
                type='text'
                multiline
                value={inputDetails[item.name]}
                rows={3}
                maxLength={100}
                onChange={handleChange}
                inputProps={
                  { readOnly: item.isEditable === 'true' ? false : true, maxLength: 100}
                }
            /> </div>
            ) : null}
                </Grid> 
            )

        })}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={handleCancel}
          color="primary"
        >
          Cancel
        </Button>
        <ButtonContainer
          type="submit"
          backgroundColor={backgroundColor}
          // className="col-sm-5 mr-5 py-4"
        >
          Submit
        </ButtonContainer>
      </div>
      </form>}
    </div>
  )
}
