import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import FormikPhoneInput from '../Common/FormikPhoneInput';
import { forgotPin, resetEndpointDataAction } from '../../store/actions';
import {toast} from 'react-toastify';
import NotificationMesage from '../Common/NotificationMesage';
import {Button} from 'react-bootstrap'
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import 'react-phone-number-input/style.css';
import './form.css';
import './phone.css';

const initialValues = {
  mobile: ''
};

export const ForgotPin = () => {
  const themeColor = useThemeColor();
  const ref = React.createRef();
  const dispatch = useDispatch();

  const [mobileError, setMobileError] = useState('');

  const ipData = useSelector(state => state.ipData, shallowEqual);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const forgotPinData = useSelector(state => state.forgotPinData, shallowEqual);
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.orgColors.primary : '' }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // console.log(values, 'vales')
      const { mobile } = values;
      if(!mobile || (mobile === null) ||!isValidPhoneNumber(mobile) || mobileError){
        // console.log('errorValues');
        return setMobileError('Mobile is Required');
      }
      const resetUrl = window.location.origin  + '/reset_pin';
        dispatch(forgotPin({ orgId: orgInfo.orgId, mobile, resetUrl }))
    }
  });

  useEffect(() => {
    if (forgotPinData?.responseCode === '00') {
      toast.success(<NotificationMesage message={forgotPinData.responseMessage} responseStatus={forgotPinData.responseCode} />, {
        onClose: () => {
          dispatch(resetEndpointDataAction);
        }
      })
    }
    if (forgotPinData?.responseCode === '01') {
      toast.error(<NotificationMesage message={forgotPinData.responseMessage} responseStatus={forgotPinData.responseCode} />, {
        onClose: () => dispatch(resetEndpointDataAction)
      })
    }
  }, [forgotPinData.responseCode])

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="fas fa-lock"></i>
          </span>
        </div>
        <h3 className="font-size-h1 mb-5">Forgot Pin</h3>
        <p className="text-muted font-weight-bold">Enter your mobile and we'll send you link to reset it</p>
      </div>
      <hr />
      {/* {forgotPinData.responseCode === '00' ? */}
      {/* <Alert isSuccess showBackToLoginBtn={true} showCloseBtn={false} msg={forgotPinData.responseMessage} /> : */}
      <>
        {/* {forgotPinData.responseCode === '01' && <Alert isSuccess={false} msg={forgotPinData.responseMessage} />} */}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework mt-10">
          <div className="form-group fv-plugins-icon-container mb-10">
            <FormikPhoneInput
              ref={ref}
              name="mobile"
              formik={formik}
              international
              withCountryCallingCode
              className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${(formik.values.mobile && !isValidPhoneNumber(formik.values.mobile)) || mobileError ? 'is-invalid' : (formik.values.mobile && isValidPhoneNumber(formik.values.mobile)) && !mobileError ? 'is-valid' :  ''}`}
              countryCallingCodeEditable={false}
              defaultCountry={ipData ? ipData.country_code : null}
              value={formik.values.mobile}
              onChange={(e) => formik.setFieldValue("mobile", e)}
            />
            {(formik.values.mobile && !isValidPhoneNumber(formik.values.mobile)) || mobileError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{(formik.values.mobile ? (isValidPhoneNumber(formik.values.mobile) ? setMobileError('')  : 'Invalid mobile number') : 'Mobile number is required') || mobileError}</div>
              </div>
            ) : null}
          </div>
          <div className="d-flex justify-content-between align-items-center my-5">
            <div className="d-flex align-items-center">
              <Link className="text-dark-50 text-hover-primary" to="/">
              <Button variant="secondary" className="btn py-4 px-7">Back to Login</Button>
                {/* <span><i className="las la-angle-left mr-2"></i></span><span className="text-primary">Back to Login</span> */}
                </Link>
            </div>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={forgotPinData.loading}
              style={themeColor.btnStyle}
              className={`btn btn-primary font-weight-bold py-4 px-7`}>
              <span>{forgotPinData.loading ? 'Please wait...' : 'Send Request'}</span>
              {forgotPinData.loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
      </>
      {/* } */}
    </div>
  );
}