import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import { login, resetEndpointDataAction } from "../../../store/actions";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import "../../Forms/form.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import NotificationMesage from "../../Common/NotificationMesage";
import Link from '@material-ui/core/Link'

const initialValues = {
  email: "",
  password: "",
};
export default function DeleteLandingPage() {
  const dispatch = useDispatch();
  const themeColor = useThemeColor();
  let history = useHistory();

  const loginData = useSelector((state) => state.loginData, shallowEqual);
  // const ipData = useSelector(state => state.ipData);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const [toggleMobile, setToggleMobile] = useState(false);
  const [toggleShowPassword, setToggleShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Required field"),
    password: Yup.string().required("Required field"),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const { email, password } = values;
      if (email && password)
        dispatch(
          login(
            {
              email,
              password,
              typeEmailOrMobile: "email",
              orgId: orgInfo.orgId,
            },
            redirectToDashboard
          )
        );
    },
  });

  const toggleShowPasswordFunc = () => {
    setToggleShowPassword(!toggleShowPassword);
  };

  const redirectTo2FA = () => {
    history.push("/2fa_verification");
  };

  const redirectToDashboard = () => {
    history.replace("/user/account/delete");
  };

  React.useEffect(() => {
    if (loginData.responseCode === "01") {
      toast.error(
        <NotificationMesage
          message={loginData.responseMessage}
          responseStatus={loginData.responseCode}
        />,
        {
          onClose: () => dispatch(resetEndpointDataAction),
        }
      );
    }
    if (loginData.required2FAAuth === true && loginData.responseCode === "00") {
      redirectTo2FA();
    }
    if (
      loginData.responseCode === "00" &&
      loginData.required2FAAuth === false
    ) {
      redirectToDashboard();
    }
  }, [loginData.responseCode, loginData]);

  return (
    <div className="login-form login-signin">
      <div className="text-center mb-10 mb-lg-10">
        <h3 className="font-size-h1 mb-5">Delete My Account</h3>
        <p className="font-size-p mb-5">
            Please note that this action is irreversible. All data will be permanently deleted from the church database. Please proceed with care.
        </p>
        <p>You need to login in order to delete your account. If you've forgotten your password, click on the Forgot Password link below</p>
      </div>

      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework mt-10">
        <div className="fv-plugins-icon-container mb-10">
          <div className="position-relative">
            <TextField
              label="Email"
              name="email"
              type="email"
              id="loginEmail"
              autoFocus
              error={
                !toggleMobile &&
                formik.touched.email &&
                formik.errors.email &&
                true
              }
              variant="outlined"
              {...formik.getFieldProps("email")}
              className={`${getInputClasses("email")}`}
              fullWidth
            />
            {/* <i className="fas fa-user email-icon position-absolute"></i> */}
          </div>
        </div>
        <div className="position-relative password">
          <TextField
            label="Password"
            type={toggleShowPassword ? "text" : "password"}
            className={` ${getInputClasses("password")}`}
            id="loginPassword"
            name="password"
            fullWidth
            error={
              !toggleMobile &&
              formik.touched.password &&
              formik.errors.password &&
              true
            }
            variant="outlined"
            {...formik.getFieldProps("password")}
          />
          <span
            className="position-absolute p-2"
            onClick={toggleShowPasswordFunc}
          >
            {toggleShowPassword ? (
              <i className="far fa-eye"></i>
            ) : (
              <i className="far fa-eye-slash"></i>
            )}
          </span>
        </div>
        <button
          id="kt_login_signin_submit"
          type="submit"
          disabled={loginData && loginData.loading}
          style={themeColor.btnStyle}
          className={`btn w-100 mt-5 font-weight-bold text-white py-4 px-9 mb-5`}
        >
          <span>{loginData.loading ? "Please wait..." : "Sign In"}</span>
          {loginData && loginData.loading && (
            <span className="ml-3 spinner spinner-white"></span>
          )}
        </button>
      </form>
        <div className="forgetPasswordContainter">
            <i color="inherit" className="fas fa-lock email-icon mr-2"></i>
            <Link
            // style={{color: themeColor.color}}
            underline="hover"
            component={RouterLink}
            to="/forgot_password"
            color="inherit"
            className="my-3 mr-2 font-size-sm forgotPassword"
            id="kt_login_forgot">
            Forgot Password
            </Link>
        </div>
    </div>
  );
}
