export const actionTypes = {
  GET_IPDATA: "GET_IPDATA",
  FETCH_ORG_INFO: "FETCH_ORG_INFO",
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  START_PAGE_LOADING: "START_PAGE_LOADING",
  STOP_PAGE_LOADING: "STOP_PAGE_LOADING",
  RESET_ENDPOINT_DATA: "RESET_ENDPOINT_DATA",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  FORGOT_PIN_SUCCESS: "FORGOT_PIN_SUCCESS",
  FORGOT_PIN_FAILURE: "FORGOT_PIN_FAILURE",
  VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS:
    "VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS",
  VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE:
    "VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE",
  RESET_PASSWORD_OR_PIN_SUCCESS: "RESET_PASSWORD_OR_PIN_SUCCESS",
  RESET_PASSWORD_OR_PIN_FAILURE: "RESET_PASSWORD_OR_PIN_FAILURE",
  GET_ORG_INFO_LIST: "GET_ORG_INFO_LIST",
  UPDATE_USER_RECORD_SUCCESS: "UPDATE_USER_RECORD_SUCCESS",
  UPDATE_USER_RECORD_FAILURE: "UPDATE_USER_RECORD_FAILURE",
  ORG_INFO_LIST: "ORG_INFO_LIST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",
  START_LOADING_USER_DETAILS: "START_USER_DETAILS",
  DONATED_AMOUNT: "DONATED_AMOUNT",
  DONATED_AMOUNT_ERROR_MSG: "DONATED_AMOUNT_ERROR_MSG",
  SET_CREDIT_CARD_OWNER_INFO: "SET_CREDIT_CARD_OWNER_INFO",
  SET_GIVE_OWNER_INFO: "SET_GIVE_OWNER_INFO",
  SET_CREDIT_CARD_DETAILS: "SET_CREDIT_CARD_DETAILS",
  GIVE_WITH_CARD_SUCCESS: "GIVE_WITH_CARD_SUCCESS",
  GIVE_WITH_CARD_FAILURE: "GIVE_WITH_CARD_FAILURE",
  GIVE_WITH_CHECK_SUCCESS: "GIVE_WITH_CHECK_SUCCESS",
  GIVE_WITH_CHECK_FAILURE: "GIVE_WITH_CHECK_FAILURE",
  RESET_GIVE_RESPONSE: "RESET_GIVE_RESPONSE",
  SET_GIVE_INFO: "SET_GIVE_INFO",
  SET_CHECK_DETAILS: "SET_CHECK_DETAILS",
  CARDS_OR_CHECKING_PROFILES: "CARDS_OR_CHECKING_PROFILES",
  EXISTING_RECURRING_PROFILES_PORTAL: "EXISTING_RECURRING_PROFILES_PORTAL",
  HIDE_GIVE_ON_GIVE_PAGE: "HIDE_GIVE_ON_GIVE_PAGE",
  SHOW_GIVE_ON_GIVE_PAGE: "SHOW_GIVE_ON_GIVE_PAGE",
  GIVE_WITH_NEW_CARD_SUCCESS: "GIVE_WITH_NEW_CARD_SUCCESS",
  GIVE_WITH_NEW_CARD_FAILURE: "GIVE_WITH_NEW_CARD_FAILURE",
  GIVE_WITH_NEW_CHECK_SUCCESS: "GIVE_WITH_NEW_CHECK_SUCCESS",
  GIVE_WITH_NEW_CHECK_FAILURE: "GIVE_WITH_NEW_CHECK_FAILURE",
  DELETE_CARD_OR_CHECKING_PORTAL_SUCCESS:
    "DELETE_CARD_OR_CHECKING_PORTAL_SUCCESS",
  DELETE_CARD_OR_CHECKING_PORTAL_FAILURE:
    "DELETE_CARD_OR_CHECKING_PORTAL_FAILURE",
  SET_AS_DEFAULT_CARD_CHECKING_SUCCESS: "SET_AS_DEFAULT_CARD_CHECKING_SUCCESS",
  SET_AS_DEFAULT_CARD_CHECKING_FAILURE: "SET_AS_DEFAULT_CARD_CHECKING_FAILURE",
  CANCEL_A_RECURRING_SUCCESS: "CANCEL_A_RECURRING_SUCCESS",
  CANCEL_A_RECURRING_FAILURE: "CANCEL_A_RECURRING_FAILURE",
  GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_SUCCESS:
    "GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_SUCCESS",
  GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_FAILURE:
    "GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_FAILURE",
  SETUP_A_NEW_RECURRING_PORTAL_SUCCESS: "SETUP_A_NEW_RECURRING_PORTAL_SUCCESS",
  SETUP_A_NEW_RECURRING_PORTAL_FAILURE: "SETUP_A_NEW_RECURRING_PORTAL_FAILURE",
  UPDATE_A_RECURRING_PORTAL_SUCCESS: "UPDATE_A_RECURRING_PORTAL_SUCCESS",
  UPDATE_A_RECURRING_PORTAL_FAILURE: "UPDATE_A_RECURRING_PORTAL_FAILURE",
  ADD_A_CHILD_SUCCESS: "ADD_A_CHILD_SUCCESS",
  ADD_A_CHILD_FAILURE: "ADD_A_CHILD_FAILURE",
  SHOW_MOBILE_SIDE_BAR: "SHOW_MOBILE_SIDE_BAR",
  SET_HIDE_AMOUNT_INPUT: "SET_HIDE_AMOUNT_INPUT",
  SCROLL_TO_AMOUNT_ERROR_MSG: "SCROLL_TO_AMOUNT_ERROR_MSG",
  PRAYERWALLS_SUCCESS: "PRAYERWALLS_SUCCESS",
  MY_PRAYERWALLS_SUCCESS: "MY_PRAYERWALLS_SUCCESS",
  MY_PRAYERWALLS_FAILURE: "MY_PRAYERWALLS_FAILURE",
  PRAYERWALLSLIKEORPRAY_SUCCESS: "PRAYERWALLSLIKEORPRAY_SUCCESS",
  PRAYERWALLSLIKEORPRAY_FAILURE: "PRAYERWALLSLIKEORPRAY_FAILURE",
  PRAYERWALLSLIKEORPRAY_START_LOADING: "PRAYERWALLSLIKEORPRAY_START_LOADING",
  PRAYERWALLSLIKEORPRAY_STOP_LOADING: "PRAYERWALLSLIKEORPRAY_STOP_LOADING",
  ADDPRAYER_SUCCESS: "ADDPRAYER_SUCCESS",
  ADDPRAYER_FAILURE: "ADDPRAYER_FAILURE",
  ADDPRAYER_START_LOADING: "ADDPRAYER_START_LOADING",
  ADDPRAYER_STOP_LOADING: "ADDPRAYER_STOP_LOADING",
  ARCHIVEPRAYER_SUCCESS: "ARCHIVEPRAYER_SUCCESS",
  ARCHIVEPRAYER_FAILURE: "ARCHIVEPRAYER_FAILURE",
  ARCHIVEPRAYER_START_LOADING: "ARCHIVEPRAYER_START_LOADING",
  ARCHIVEPRAYER_STOP_LOADING: "ARCHIVEPRAYER_STOP_LOADING",
  MARKED_ANSWERED_PRAYER_SUCCESS: "MARKED_ANSWERED_PRAYER_SUCCESS",
  MARKED_ANSWERED_PRAYER_FAILURE: "MARKED_ANSWERED_PRAYER_FAILURE",
  MARKED_ANSWERED_PRAYER_START_LOADING: "MARKED_ANSWERED_PRAYER_START_LOADING",
  MARKED_ANSWERED_PRAYER_STOP_LOADING: "MARKED_ANSWERED_PRAYER_STOP_LOADING",
  FETCH_SECOND_ORG_INFO: "FETCH_SECOND_ORG_INFO",
  SET_OR_UNSET_SPOUSE: "SET_OR_UNSET_SPOUSE",
  SEARCH_FOR_SPOUSE: "SEARCH_FOR_SPOUSE",
  DELETE_CHILD_SUCCESS: "DELETE_CHILD_SUCCESS",
  DELETE_CHILD_FAILURE: "DELETE_CHILD_FAILURE",
  SET_CARD_DETAILS: "SET_CARD_DETAILS",
  SET_CARD_OWNER_DETAILS: "SET_CARD_OWNER_DETAILS",
  SET_THEME_CHANGE: "SET_THEME_CHANGE",
  AUTH_2FA_REQUIRED: "AUTH_2FA_REQUIRED",
  SET_2FA_SUCCESS: "SET_2FA_SUCCESS",
  SET_2FA_ERROR: "SET_2FA_ERROR",
  INITIATE_RSVP_SUCCESS: "INITIATE_RSVP_SUCCESS",
  INITIATE_RSVP_FAILURE: "INITIATE_RSVP_FAILURE",
  VERIFY_RSVP_SUCCESS: "VERIFY_RSVP_SUCCESS",
  VERIFY_RSVP_FAILURE: "VERIFY_RSVP_FAILURE",
  PROCESS_CHECKIN_SUCCESS: "PROCESS_CHECKIN_SUCCESS",
  PROCESS_CHECKIN_FAILURE: "PROCESS_CHECKIN_FAILURE",
  DELETE_GUEST_SUCCESS: "DELETE_GUEST_SUCCESS",
  DELETE_GUEST_FAILURE: "DELETE_GUEST_FAILURE",
  PROCESS_CHECKOUT_SUCCESS: "PROCESS_CHECKOUT_SUCCESS",
  PROCESS_CHECKOUT_FAILURE: "PROCESS_CHECKOUT_FAILURE",
  PROCESS_RSVP_SUCCESS: "PROCESS_RSVP_SUCCESS",
  PROCESS_RSVP_FAILURE: "PROCESS_RSVP_FAILURE",
  CANCEL_RSVP_SUCCESS: "CANCEL_RSVP_SUCCESS",
  CANCEL_RSVP_FAILURE: "CANCEL_RSVP_FAILURE",
  DELETE_GUEST_SUCCESS: "DELETE_GUEST_SUCCESS",
  DELETE_GUEST_FAILURE: "DELETE_GUEST_FAILURE",
  RSVP_INFORMATION_INSTANCE_SUCCESS: "RSVP_INFORMATION_INSTANCE_SUCCESS",
  RSVP_INFORMATION_INSTANCE_FAILURE: "RSVP_INFORMATION_INSTANCE_FAILURE",
  CREATE_MULTIPLE_RSVP: "CREATE_MULTIPLE_RSVP",
  CLEAR_ALL_SELECTED_RSVP: "CLEAR_ALL_SELECTED_RSVP",
  CONTRIBUTION_PROCESSING_ENGINES_SUCCESS:
    "CONTRIBUTION_PROCESSING_ENGINES_SUCCESS",
  CONTRIBUTION_PROCESSING_ENGINES_FAILURE:
    "CONTRIBUTION_PROCESSING_ENGINES_FAILURE",
  PROCESS_PAYPAL_SUCCESS: "PROCESS_PAYPAL_SUCCESS",
  PROCESS_PAYPAL_FAILURE: "PROCESS_PAYPAL_FAILURE",
  SERMON_SUCCESS: "SERMON_SUCCESS",
  SERMON_FAILURE: "SERMON_FAILURE",
  SELECTED_SERMON: "SELECTED_SERMON",
  SEND_NEW_GUEST: "SEND_NEW_GUEST",
  SPOUSE_CHECKIN: "SPOUSE_CHECKIN",
  FAMILY_CHECKIN: "FAMILY_CHECKIN",
  GUEST_CHECKIN: "GUEST_CHECKIN",
  USER_BY_WEB_ACCESS_START: "USER_BY_WEB_ACCESS_START",
  USER_BY_WEB_ACCESS_STOP: "USER_BY_WEB_ACCESS_STOP",
  USER_BY_WEB_ACCESS_SUCCESS: "USER_BY_WEB_ACCESS_SUCCESS",
  USER_BY_WEB_ACCESS_FAILURE: "USER_BY_WEB_ACCESS_FAILURE",
  GET_FOLLOWUP_ORGANISATION_DATA_SUCCESS:
    "GET_FOLLOWUP_ORGANISATION_DATA_SUCCESS",
  GET_FOLLOWUP_ORGANISATION_DATA_FAILURE:
    "GET_FOLLOWUP_ORGANISATION_DATA_FAILURE",
  GET_FOLLOWUP_FORM_SUCCESS: "GET_FOLLOWUP_FORM_SUCCESS",
  GET_FOLLOWUP_FORM_FAILURE: "GET_FOLLOWUP_FORM_FAILURE",
  SUBMIT_FOLLOWUP_FORM_START: "SUBMIT_FOLLOWUP_FORM_START",
  SUBMIT_FOLLOWUP_FORM_STOP: "SUBMIT_FOLLOWUP_FORM_STOP",
  SUBMIT_FOLLOWUP_FORM_SUCCESS: "SUBMIT_FOLLOWUP_FORM_SUCCESS",
  SUBMIT_FOLLOWUP_FORM_FAILURE: "SUBMIT_FOLLOWUP_FORM_FAILURE",
  SELECTED_PAYMENT_ENGINE: "SELECTED_PAYMENT_ENGINE",
  GIVE_VIA_NAV: "GIVE_VIA_NAV",
  CHECKIN_LISITNG_STATUS_SUCCESS: "CHECKIN_LISITNG_STATUS_SUCCESS",
  CHECKIN_LISITNG_STATUS_FAILURE: "CHECKIN_LISITNG_STATUS_FAILURE",
  EPAY_SUCCESS: "EPAY_SUCCESS",
  EPAY_FAILURE: "EPAY_FAILURE",
  PROCESS_EPAY_SUCCESS: "PROCESS_EPAY_SUCCESS",
  PROCESS_EPAY_FAILURE: "PROCESS_EPAY_FAILURE",
  ADD_PHONE_NUMBER_SUCCESS: "ADD_PHONE_NUMBER_SUCCESS",
  ADD_PHONE_NUMBER_FAILURE: "ADD_PHONE_NUMBER_FAILURE",
  DELETE_PHONE_NUMBER_SUCCESS: "DELETE_PHONE_NUMBER_SUCCESS",
  DELETE_PHONE_NUMBER_FAILURE: "DELETE_PHONE_NUMBER_FAILURE",
  START_PHONE_NUMBER: "START_PHONE_NUMBER",
  STOP_PHONE_NUMBER: "STOP_PHONE_NUMBER",
  PREFERRED_PAYMENT_NEW_CHECK_SUCCESS: "PREFERRED_PAYMENT_NEW_CHECK_SUCCESS",
  PREFERRED_PAYMENT_NEW_CHECK_FAILURE: "PREFERRED_PAYMENT_NEW_CHECK_FAILURE",
  PREFERRED_PAYMENT_NEW_CARD_FAILURE: "PREFERRED_PAYMENT_NEW_CARD_FAILURE",
  PREFERRED_PAYMENT_NEW_CARD_SUCCESS: "PREFERRED_PAYMENT_NEW_CARD_SUCCESS",
  START_APP_ACCESS: 'START_APP_ACCESS',
  STOP_APP_ACCESS: 'STOP_APP_ACCESS',
  APP_ACCESS_SUCCESS: 'APP_ACCESS_SUCCESS',
  APP_ACCESS_FAILURE: 'APP_ACCESS_FAILURE',
  QUICKGIVE_SUCCESS : 'QUICKGIVE_SUCCESS',
  QUICKGIVE_FAILURE : 'QUICKGIVE_FAILURE',
  CELEBRATION_SUCCESS_RESPONSE: 'CELEBRATION_SUCCESS_RESPONSE',
  CELEBRATION_FAILURE_RESPONSE: 'CELEBRATION_FAILURE_RESPONSE',
  PRIVACY_SUCCESS_RESPONSE: 'PRIVACY_SUCCESS_RESPONSE',
  PRIVACY_FAILURE_RESPONSE: 'PRIVACY_FAILURE_RESPONSE',
  DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS: 'DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS',
  DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE: 'DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE',
  DYNAMIC_FORMS_ANSWER_AND_QUESTION_SUCCESS: 'DYNAMIC_FORMS_ANSWER_AND_QUESTION_SUCCESS',
  DYNAMIC_FORMS_ANSWER_AND_QUESTION_FAILURE: 'DYNAMIC_FORMS_ANSWER_AND_QUESTION_FAILURE',
  DYNAMIC_FORMS_GET_SCHEMA_SUCCESS: 'DYNAMIC_FORMS_GET_SCHEMA_SUCCESS',
  DYNAMIC_FORMS_GET_SCHEMA_FAILURE: 'DYNAMIC_FORMS_GET_SCHEMA_FAILURE',
  START_LOADING_FOLLOWUP: 'START_LOADING_FOLLOWUP',
  STOP_LOADING_FOLLOWUP: 'STOP_LOADING_FOLLOWUP',
  GET_FOLLOW_UP_BY_ORGANIZATION_ID_SUCCESS: 'GET_FOLLOW_UP_BY_ORGANIZATION_ID_SUCCESS',
  GET_FOLLOW_UP_BY_ORGANIZATION_ID_FAILURE: 'GET_FOLLOW_UP_BY_ORGANIZATION_ID_FAILURE',
  CREATE_ACTIVITY_LOG_SUCCESS: 'CREATE_ACTIVITY_LOG_SUCCESS',
  CREATE_ACTIVITY_LOG_FAILURE: 'CREATE_ACTIVITY_LOG_FAILURE',
  GET_ACTIVITY_LOG_SUCCESS: 'GET_ACTIVITY_LOG_SUCCESS',
  GET_ACTIVITY_LOG_FAILURE: 'GET_ACTIVITY_LOG_FAILURE',
  GET_FOLLOW_UP_FORM_SUCCESS: 'GET_FOLLOW_UP_FORM_SUCCESS',
  GET_FOLLOW_UP_FORM_FAILURE: 'GET_FOLLOW_UP_FORM_FAILURE',
  GET_USERS_GROUP_FAILURE: 'GET_USERS_GROUP_FAILURE',
  GET_USERS_GROUP_SUCCESS: 'GET_USERS_GROUP_SUCCESS',
  GET_USERS_GROUP_PENDING_START: 'GET_USERS_GROUP_PENDING_START',
  GET_USERS_GROUP_PENDING_STOP: 'GET_USERS_GROUP_PENDING_STOP',
  DEPARTMENT_USERS_START: 'DEPARTMENT_USERS_START',
  DEPARTMENT_USERS_STOP: 'DEPARTMENT_USERS_STOP',
  DELETE_DEPARTMENT_GROUP_USERS_SUCCESS: 'DELETE_DEPARTMENT_GROUP_USERS_SUCCESS',
  DELETE_DEPARTMENT_GROUP_USERS_FAILURE: 'DELETE_DEPARTMENT_GROUP_USERS_FAILURE',
  ADD_REMOVE_USER_ADMIN_ACCESS_SUCCESS: 'ADD_REMOVE_USER_ADMIN_ACCESS_SUCCESS',
  ADD_REMOVE_USER_ADMIN_ACCESS_FAIL: 'ADD_REMOVE_USER_ADMIN_ACCESS_FAIL',
  CREATE_DEPARTMENT_GROUP_START: 'CREATE_DEPARTMENT_GROUP_START',
  CREATE_DEPARTMENT_GROUP_STOP: 'CREATE_DEPARTMENT_GROUP_STOP',
  CHILDREN_MODULE_SUCCESS: 'CHILDREN_MODULE_SUCCESS',
  CHILDREN_MODULE_FAILURE: 'CHILDREN_MODULE_FAILURE',
  MEDIA_VAULT_LISTING_SUCCESS: 'MEDIA_VAULT_LISTING_SUCCESS',
  MEDIA_VAULT_LISTING_FAILURE: 'MEDIA_VAULT_LISTING_FAILURE',
  MEDIA_VAULT_LISTING_STOP: 'MEDIA_VAULT_LISTING_STOP',
  MEDIA_VAULT_LISTING_START: 'MEDIA_VAULT_LISTING_START',
  SEND_CHILDREN_COMMUNICATION_SUCCESS: 'SEND_CHILDREN_COMMUNICATION_SUCCESS',
  SEND_CHILDREN_COMMUNICATION_FAILURE: 'SEND_CHILDREN_COMMUNICATION_FAILURE',
  GET_CHILD_CHECKED_IN_TOKEN_START: 'GET_CHILD_CHECKED_IN_TOKEN_START',
  GET_CHILD_CHECKED_IN_TOKEN_STOP: 'GET_CHILD_CHECKED_IN_TOKEN_STOP',
  ORG_MESSAGE: 'ORG_MESSAGE',
  SEND_CHILDREN_COMMUNICATION_PROCESS_SUCCESS: 'SEND_CHILDREN_COMMUNICATION_PROCESS_SUCCESS',
  SEND_CHILDREN_COMMUNICATION_PROCESS_FAILURE: 'SEND_CHILDREN_COMMUNICATION_PROCESS_FAILURE',
  CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP: 'CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP',
  SEARCH_USER_TO_JOIN_START: 'SEARCH_USER_TO_JOIN_START',
  SEARCH_USER_TO_JOIN_SUCCESS: 'SEARCH_USER_TO_JOIN_SUCCESS',
  SEARCH_USER_TO_JOIN_STOP: 'SEARCH_USER_TO_JOIN_STOP',
  POSITIONS_LIST_SUCCESS: 'POSITIONS_LIST_SUCCESS',
  POSITIONS_LIST_FAIL: 'POSITIONS_LIST_FAIL',
  CREATE_DEPARTMENT_LIST_GROUP_SUCCESS: 'CREATE_DEPARTMENT_LIST_GROUP_SUCCESS',
  CREATE_DEPARTMENT_LIST_GROUP_FAILURE: 'CREATE_DEPARTMENT_LIST_GROUP_FAILURE',
  RENAME_DEPARTMENT_GROUP_SUCCESS: 'RENAME_DEPARTMENT_GROUP_SUCCESS',
  RENAME_DEPARTMENT_GROUP_FAILURE: 'RENAME_DEPARTMENT_GROUP_FAILURE',
  CREATE_GROUPS_LOADING_START: 'CREATE_GROUPS_LOADING_START',
  CREATE_GROUPS_SUCCESS: 'CREATE_GROUPS_SUCCESS',
  CREATE_GROUPS_FAIL: 'CREATE_GROUPS_FAIL',
  CREATE_GROUPS_STOP: 'CREATE_GROUPS_STOP',
  UPDATE_GROUPS_SUCCESS: 'UPDATE_GROUPS_SUCCESS',
  UPDATE_GROUPS_FAIL: 'UPDATE_GROUPS_FAIL',
  UPDATE_GROUPS_LOADING_START: 'UPDATE_GROUPS_LOADING_START',
  UPDATE_GROUPS_STOP: 'UPDATE_GROUPS_STOP',
  SEARCHED_USERS: 'SEARCHED_USERS',
  SEARCH_USER_LOADING_START: 'SEARCH_USER_LOADING_START',
  SEARCH_USER_LOADING_STOP: 'SEARCH_USER_LOADING_STOP',
  UPDATE_USER_PHONE_NUMBER_START: 'UPDATE_USER_PHONE_NUMBER_START',
  UPDATE_USER_PHONE_NUMBER_STOP: 'UPDATE_USER_PHONE_NUMBER_STOP',
  CHANGE_GROUP_BANNER_SUCCESS: 'CHANGE_GROUP_BANNER_SUCCESS',
  CHANGE_GROUP_BANNER_FAILURE: 'CHANGE_GROUP_BANNER_FAILURE',
  APPROVE_USER_SUCCESS: 'APPROVE_USER_SUCCESS',
  APPROVE_USER_FAIL: 'APPROVE_USER_FAIL',
  APPROVE_USER_LOADING_START: 'APPROVE_USER_LOADING_START',
  APPROVE_USER_STOP: 'APPROVE_USER_STOP',
  GET_USER_FAMILY_FAILURE: 'GET_USER_FAMILY_FAILURE',
  GET_USER_FAMILY_SUCCESS: 'GET_USER_FAMILY_SUCCESS',
  GIVE_METHODS_LOADING_STOP: "GIVE_METHODS_LOADING_STOP",
  GIVE_METHODS_LOADING_START: "GIVE_METHODS_LOADING_START",
  GET_GIVE_METHODS_SUCCESS: "GET_GIVE_METHODS_SUCCESS",
  UPDATE_PAYMENT_MODE: 'UPDATE_PAYMENT_MODE',
  GENERATE_DELETION_OTP_SUCCESS: 'GENERATE_DELETION_OTP_SUCCESS',
  GENERATE_DELETION_OTP_FAILURE: 'GENERATE_DELETION_OTP_FAILURE',
  DELETE_USERS_ACCOUNT_SUCCESS: 'DELETE_USERS_ACCOUNT_SUCCESS',
  DELETE_USERS_ACCOUNT_FAILURE: 'DELETE_USERS_ACCOUNT_FAILURE',
  MASTER_APP_ORGANIZATION_START: 'MASTER_APP_ORGANIZATION_START', 
  MASTER_APP_ORGANIZATION_STOP: 'MASTER_APP_ORGANIZATION_STOP', 
};
