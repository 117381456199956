import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
// import "./phone.css";

const PhoneInputField = ({
  mobile = "",
  setMobile,
  mobileError,
  classBox = "mb-5",
}) => {
  const ipData = useSelector((state) => state.ipData);
  return (
    <div className="position-relative">
      <PhoneInput
        international={ipData && ipData ? ipData.country_code : null}
        withCountryCallingCode
        countryCallingCodeEditable={false}
        defaultCountry={ipData && ipData ? ipData.country_code : null}
        placeholder="Mobile"
        id="loginMobile"
        value={mobile ? mobile : ""}
        onChange={setMobile}
        className={`${classBox} px-3 mobile`}
        name="mobile"
        country="US"
      />
      {mobile && isValidPhoneNumber(mobile) && <div className="check"></div>}
      {mobile && !isValidPhoneNumber(mobile) && <span className="x">X</span>}
      {mobileError && (
        <p className="text-error my-auto errorMessage">{mobileError}</p>
      )}
    </div>
  );
};

export default PhoneInputField;
