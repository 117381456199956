import React from 'react'
import AudioBackgroundImage from '../../../../_metronic/_assets/audioPlayer1.png'

export default function AudioPlayer({audioUrl}) {
    return (
        <div style={{backgroundImage: `url(${AudioBackgroundImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
        <div style={{textAlign: 'center', paddingTop: '10rem'}}>
          <div style={{backgroundColor: '#fff'}}>
        <audio controls={true} src={audioUrl} controlsList='' className='prayer-wall-aduio'>
          Your browser does not support the
          <code>audio</code> element.
        </audio>

          </div>
        </div>
        </div>
    )
}
