import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

export const CustomAccordion = ({color, accordionIconClassName, accordionName, children }) => 
  <>
    <Accordion className="my-0 accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="pl-3 accordion-summary"
      >
        <ListItemIcon className="list-icon">
          <span className="svg-icon menu-icon">
            <i className={accordionIconClassName} style={{color}}></i>
          </span>
        </ListItemIcon>
        <span className="menu-text">{accordionName}</span>
      </AccordionSummary>
      <Divider />
        <AccordionDetails className="pl-0">
          <MenuList className="py-0">
            {children}
          </MenuList>
        </AccordionDetails>
      <Divider />
    </Accordion>
  <Divider />
</>

export const CustomMenu = ({menuList, linkComponent, pathname, color}) => {
  return <MenuList className="py-0">
    {menuList.map((item, index) => 
     typeof item === 'object' && <MenuItem key={index} component={linkComponent} to={item.route} className='py-3 pl-3' selected={item.route === pathname}>
        <ListItemIcon className="list-icon">
          <span className="svg-icon menu-icon">
            <i className={`menu-icon ${item.menuIcon}`} style={{color}}></i>
          </span>
        </ListItemIcon>
        <span className="menu-text">{item.menuText}</span>
      </MenuItem>
    )}
  </MenuList>
}