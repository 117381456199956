import moment from 'moment'
import React from 'react'
import { useCountdown } from '../../utils/customHooks/useCountdown'
import './nextEvent.css'
import {sermon} from '../../store/actions/index'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'

export default function NextEevnt({data, setCurrentlyPlaying}) {
  let dispatch = useDispatch()
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const [days, hours, minutes, seconds] = useCountdown(data?.startDate)

  React.useEffect(() => {
    if (hours === -1 && minutes === -1 && seconds === -2) {
      // dispatch(sermon(orgInfo.orgId))
      window.location.reload()
      // setCurrentlyPlaying(data.playerId)
    }
  }, [seconds])
  // console.log(seconds, 'seconds');
  
  return (
    <div data-testid="serviceInfo-offline" className="styles__Container-sc-1f600u6-1 hsclWp">
  <div className="styles__ServiceMetaWrapper-sc-1f600u6-2 kHxTJW">
    <div tabindex="0" className="styles__Container-sc-9zg4gi-0 buAwch">
      <div className="Box-sc-9hdf5y-0 Flex-sc-32gemv-0 serviceMeta__StyledFlex-sc-po5v5x-0 cfVFAm bTnGGq jPelAY">
        <h5 font-size="2" color="gray80" className="Box-sc-9hdf5y-0 hiKefI styles__NextServiceHeader-sc-9zg4gi-3 dKmiSh">Next Service</h5>
      </div>
      <h1 className="styles__NextServiceTitle-sc-9zg4gi-4 kUQzIR">{data?.name}</h1>
      <p className="styles__NextServiceTime-sc-9zg4gi-5 emoKvZ">{moment(data?.startDate).format('dddd, MMMM D, hh:mm A')}</p>
    </div>
  </div>
  <div className="styles__Info-sc-1f600u6-3 igOWCa">
    <div data-testid="count-down-wrapper" className="styles__CountDownWrapper-sc-1f600u6-5 jMrCoR">
      <div data-testid="countDownContainer" width="fixed" className="styles__CountDownContainer-sc-139cmb-0 fpWHiB">
        {days > 0 && <div color="Dark" mode="compact" width="fixed" className="styles__TimeBoxContainer-sc-139cmb-1 gNxBoE">
          <h3 font-size="4" font-weight="3" color="Dark" mode="compact" className="Box-sc-9hdf5y-0 kdZkNL styles__CountDownNumber-sc-139cmb-2 gsZKgw">{days}</h3>
          <p color="Dark" mode="compact" className="styles__TypeText-sc-139cmb-3 eJrmZJ">{days === 1 ? 'DAY' : 'DAYS'}</p>
        </div>}
        { <div color="Dark" mode="compact" width="fixed" className="styles__TimeBoxContainer-sc-139cmb-1 gNxBoE">
          <h3 font-size="4" font-weight="3" color="Dark" mode="compact" className="Box-sc-9hdf5y-0 kdZkNL styles__CountDownNumber-sc-139cmb-2 gsZKgw">{Math.max(hours, 0)}</h3>
          <p color="Dark" mode="compact" className="styles__TypeText-sc-139cmb-3 eJrmZJ">{hours === 1 ? 'HOUR' : 'HOURS'}</p>
        </div>}
        <div color="Dark" mode="compact" width="fixed" className="styles__TimeBoxContainer-sc-139cmb-1 gNxBoE">
          <h3 font-size="4" font-weight="3" color="Dark" mode="compact" className="Box-sc-9hdf5y-0 kdZkNL styles__CountDownNumber-sc-139cmb-2 gsZKgw">{Math.max(minutes, 0)}</h3>
          <p color="Dark" mode="compact" className="styles__TypeText-sc-139cmb-3 eJrmZJ">MINUTES</p>
        </div>
        {days === 0 && hours === 0 && <div color="Dark" mode="compact" width="fixed" className="styles__TimeBoxContainer-sc-139cmb-1 gNxBoE">
          <h3 font-size="4" font-weight="3" color="Dark" mode="compact" className="Box-sc-9hdf5y-0 kdZkNL styles__CountDownNumber-sc-139cmb-2 gsZKgw">{Math.max(seconds, 0)}</h3>
          <p color="Dark" mode="compact" className="styles__TypeText-sc-139cmb-3 eJrmZJ">SECONDS</p>
        </div>}
      </div>
    </div>
    {/* <div className="styles__PrayerWrapper-sc-1f600u6-4 gRbWGH"><button data-testid="livePrayer-button" className="styles__Button-sc-1ewtaim-2 iSoNZD"><span className="styles__Icon-sc-1ewtaim-1 ptrWW"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 4H2V16H12V19L15 16H18V4Z" fill="#C6C6C6"></path>
          </svg></span>
        <div className="styles__Text-sc-1ewtaim-0 koscnE">Request Prayer</div>
      </button></div> */}
  </div>
</div>
  )
}
