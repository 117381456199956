import React from "react";
import axios from "axios";
import NotificationMesage from "../../../components/Common/NotificationMesage";
import { toast } from "react-toastify";
import { actionTypes } from "../../actionTypes";
import { apiUrl, asyncMethod, defaultAction, nextgenUrl } from "../../../utils/constants";
import { checkNodeEnvNextGen } from "../../../utils/authHelpers";
import AxiosInstance, { AxiosInstanceCP } from "../../../utils/AxiosConfig";


export const getOrganizationForms = (id) => {
    return async (dispatch) => {
        const url = apiUrl.getOrganizationForms(id)
        try {
          const response = await AxiosInstanceCP({
            method: asyncMethod.GET,
            url,
          });
          const data = await response.data;
          console.log(data);
          if (data) {
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS, data));
          } else {
            toast.error(
              <NotificationMesage
                responseStatus={data.responseCode}
                message={data.responseMessage}
              />
            );
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE));
          }
        } catch (error) {
          console.log(error, "error");
          // toast.error(
          //   <NotificationMesage
          //     responseStatus={error?.response?.data?.responseCode}
          //     message={error?.response?.data?.responseMessage}
          //   />
          // );
        }
      };
  };

export const submitFormAnswer = (id, payload, cb, errorCb) => {
    return async (dispatch) => {
        // dispatch({
        //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
        // });
        const url = apiUrl.submitFormAnswer(id)

        try {
          const response = await AxiosInstanceCP({
            method: asyncMethod.POST,
            url,
            data: payload
          });
          const data = await response.data;
          console.log(data);
          if (data.success) {
            cb()
            toast.success(
              <NotificationMesage
                responseStatus={'00'}
                message={data?.message}
              />
            );
            // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS, data));
          } else {
            toast.error(
              <NotificationMesage
                responseStatus={data.responseCode}
                message={data.responseMessage}
              />
            );
            errorCb()
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE));
          }
        } catch (error) {
          console.log(error, "error");
          errorCb()
          toast.error(
            <NotificationMesage
              responseStatus={error?.response?.data?.responseCode}
              message={error?.response?.data?.responseMessage}
            />
          );
        }
      };
  };

export const submitFormAnswerUpdate = (id, payload, cb, errorCb) => {
    return async (dispatch) => {
        // dispatch({
        //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
        // });
        const url = checkNodeEnvNextGen(
          apiUrl.submitFormAnswer(nextgenUrl.live, id),
          apiUrl.submitFormAnswer(nextgenUrl.staging, id)
        )

        try {
          const response = await AxiosInstance({
            method: asyncMethod.PUT,
            url,
            data: payload
          });
          const data = await response.data;
          console.log(data);
          if (data.responseCode === '00') {
            cb()
            toast.success(
              <NotificationMesage
                responseStatus={data?.responseCode}
                message={data?.responseMessage}
              />
            );
            // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS, data));
          } else {
            toast.error(
              <NotificationMesage
                responseStatus={data.responseCode}
                message={data.responseMessage}
              />
            );
            errorCb()
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE));
          }
        } catch (error) {
          console.log(error, "error");
          errorCb()
          toast.error(
            <NotificationMesage
              responseStatus={error?.response?.data?.responseCode}
              message={error?.response?.data?.responseMessage}
            />
          );
        }
      };
  };

export const submitFormAnswerForUser = (id) => {
    return async (dispatch) => {

        const url = apiUrl.submitFormAnswerForUser(id)

        try {
          const response = await AxiosInstanceCP({
            method: asyncMethod.GET,
            url,
          });
          const data = await response.data;
          console.log(data);
          if (data) {
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS, data));
          } else {
            toast.error(
              <NotificationMesage
                responseStatus={data.responseCode}
                message={data.responseMessage}
              />
            );
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE));
          }
        } catch (error) {
          console.log(error, "error");
          toast.error(
            <NotificationMesage
              responseStatus={error?.response?.data?.responseCode}
              message={error?.response?.data?.responseMessage}
            />
          );
        }
      };
  };

export const getFormQuestionAndAnswer = (id, formId, callback, ...rest) => {
    return async (dispatch) => {
        // dispatch({
        //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
        // });
        const url = apiUrl.getFormQuestionAndAnswer(formId, id)

        try {
          const response = await AxiosInstanceCP({
            method: asyncMethod.GET,
            url,
          });
          const data = await response.data;
          console.log(data);
          if (data) {
            callback(data, ...rest)
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_ANSWER_AND_QUESTION_SUCCESS, data));
          } else {
            toast.error(
              <NotificationMesage
                responseStatus={data.responseCode}
                message={data.responseMessage}
              />
            );
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_ANSWER_AND_QUESTION_FAILURE));
          }
        } catch (error) {
          console.log(error, "error");
          toast.error(
            <NotificationMesage
              responseStatus={error?.response?.data?.responseCode}
              message={error?.response?.data?.responseMessage}
            />
          );
        }
      };
  };

export const getFormSchema = (schemaId,) => {
    return async (dispatch) => {
        // dispatch({
        //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
        // });
        const url = checkNodeEnvNextGen(
          apiUrl.getFormSchema(nextgenUrl.live, schemaId),
          apiUrl.getFormSchema(nextgenUrl.staging, schemaId)
        )

        try {
          const response = await AxiosInstance({
            method: asyncMethod.GET,
            url,
          });
          const data = await response.data;
          console.log(data);
          if (data) {
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_SCHEMA_SUCCESS, data));
          } else {
            toast.error(
              <NotificationMesage
                responseStatus={data.responseCode}
                message={data.responseMessage}
              />
            );
            return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_SCHEMA_FAILURE));
          }
        } catch (error) {
          console.log(error, "error");
          toast.error(
            <NotificationMesage
              responseStatus={error?.response?.data?.responseCode}
              message={error?.response?.data?.responseMessage}
            />
          );
        }
      };
  };