import React, { useState, useEffect } from "react";
import GridView from "./GridView";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ResizeableModalWithoutBtn } from "../../Common/ResizeableModal";
import MainDepartment from "./MainDepartment";
import ViewUserImage from "../Prayer/ViewUserImage";
import { addUsersToDepartment, changeGroupBanner, childrenModuleSpecificInfo, doSearchUser, doSearchUserToJoinDept, editInterestServiceGroup, positionsListing } from "../../../store/actions/userDashboardActions/serviceUnit";
import Communication from "./Communication";
import SuccessPage from "./SuccessPage";
import { actionTypes } from "../../../store/actionTypes";
import { checkstringLength, dataURLtoFile, debounce } from "../../../utils/constants";
import {
  Avatar,
  Tooltip,
  IconButton,
  Icon,
  CircularProgress,
  Grow,
  TextField,
  makeStyles,
  MenuItem,
  Checkbox,
  FormLabel,
  Button
} from '@material-ui/core'
import MUIDataTable from 'mui-datatables';
import { getUserProfile } from "../../../store/actions";
import FormsModal from "./FormsModal";
import LabelledOutline from "./LabelledOutline";
import PhoneInputField from "../../Common/PhoneInputField";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import ChangeGropuBanner from "./ChangeGropuBanner";
// import MomentUtils from '@material-ui/pickers/adapter/moment';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";


export default function ServiceUnit() {
  const dispatch = useDispatch();
  let classes = useStyles();
  const theme = useThemeColor()
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const searchedUserToJoinDeptData = useSelector(
    (state) => state?.searchedUserToJoinDept?.responseData,
    shallowEqual
  );
  const addContactGroupLoading = useSelector((state) => state.addDepartment);
  const renameContactGroupLoding = useSelector(
    (state) => state.renameDepartmentGroup
  );

  const orgInfoList = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const orgInfo = useSelector(
    (state) => state?.orgInfo.responseData,
    shallowEqual
  );
  const { loading } = useSelector(
    (state) => state?.searchedUserToJoinDept,
    shallowEqual
  );
  const createGroupState = useSelector(
    (state) => state?.createGroup,
    shallowEqual
  );

  const positionsListData = useSelector(
    (state) => state?.positionsListing?.responseData?.ReturnedRecords,
    shallowEqual
  );

  const initialState = {
    description: "",
    formId: null,
    groupLeaderUserId: "",
    image: {
      landscapeUrl: "",
      portraitUrl: "",
      title: null,
    },
    name: "",
    organizationId: null,
    // type: "",
    legacyOrganizationId: orgInfo?.orgId,
    isEdit: false,
  };

  const [openGroupUsers, setOpenGroupUsers] = useState({
    show: false,
    item: {},
  });
  const [groupLeader, setgroupLeader] = useState({
    leaderName: "",
    leaderImage: "",
    leaderDescription: "",
    leaderTelephone: "",
    leaderEmail: "",
  });

  const [viewImage, setViewImage] = useState({
    show: false,
    item: {},
  });
  const [groupIntros, setgroupIntros] = useState([
    {
      type: "",
      url: "",
    },
  ]);
  const [meetingTimes, setmeetingTimes] = useState({

  });
  
  const [mobileError, setMobileError] = useState("");

  const [openSendMessage, setOpenSendMessage] = useState({
    show: false,
    item: {},
  });
  const [analyseData, setAnalyseData] = useState(null);
  const [isClose, setIsClose] = useState(false);
  const [openCoverModal, setOpenCoverModal] = useState({
    item: {},
    show: false,
    imgUrl: "",
    imgFile: "",
  });
  const [openJoin, setOpenJoin] = useState(false);
  const [userList, setUserList] = useState([]);
  const [openGroup, setOpenGroup] = useState({
    show: false,
    item: { isAdmin: false, id: '', positionId: '' },
  });
  const [searchGroupUser, setsearchGroupUser] = React.useState(true);
  const [selectedGrpLeader, setSelectedGrpLeader] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
  });
  const [searchUserList, setsearchUserList] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [addGroup, setaddGroup] = useState(initialState);

  const user = JSON.parse(localStorage.getItem("user"));

  // console.log(orgInfo, "orgInfoList");

  const data =typeof userProfileData?.supervisorModule?.serviceUnitsInterestGroups === 'object' && userProfileData?.supervisorModule?.serviceUnitsInterestGroups?.map((data) => {
    return {
      projectImage: data.coverPhotoUrl,
      projectName: data.name,
      // date: `Members: ${data.membersTotal.length}`,
      email: `Members: ${data.membersTotal}`,
      ...data,
    };
  });
  const handleOpenGroupUserModal = (item) => {
    // console.log(item);
    if (item?.membersTotal == "0") {
      return;
    }
    setOpenGroupUsers({ ...openGroupUsers, show: true, item });
  };

  const handleChangeJoinDept = ({target: {value}}) => {
    setOpenGroup({
      ...openGroup,
      item: {
        ...openGroup.item,
        positionId: value
      },
    })
  }

  const doOpenMOdal = (item) => {
    setUserList([]);
    setOpenModal(true);
    setOpenGroup({
      ...openGroup,
      show: false,
      item: {
        ...openGroup.item,
        departmentId: item.id,
        departmentName: item.projectName,
      },
    });
  };
  const closeContactUserModal = () => {
    setOpenGroupUsers({ ...openGroupUsers, show: false, item: {} });
  };

  const handleViewBackgroundImage = (imageData) => {
    setViewImage({ ...viewImage, show: true, item: imageData });
  };

  const closeViewImage = () => {
    setViewImage({ ...viewImage, show: false, item: {} });
  };
  const handleOpenSendMessage = (item) => {
    setOpenSendMessage({ ...openSendMessage, show: true, item });
    dispatch(
      childrenModuleSpecificInfo({
        orgId: orgInfo.orgId,
        adminUserId: user?.responseData?.userId,
      })
    );
  };

  useEffect(() => {
    if (searchedUserToJoinDeptData !== undefined) {
      setUserList(searchedUserToJoinDeptData?.returnedRecords);
    }
  }, [ searchedUserToJoinDeptData]);

  const handleCloseSendMessage = () => {
    setOpenSendMessage({ ...openSendMessage, show: false, item: {} });
  };

  const getAnalyzeData = (data) => {
    // console.log(data, "analyze data");
    setAnalyseData(data);
  };

  const handleChangeCover = (data) => {
    setOpenCoverModal({ ...openCoverModal, show: true, item: data });
  };
  const closeCoverModal = () => {
    setOpenCoverModal({
      ...openCoverModal,
      show: false,
      item: {},
      imgUrl: "",
      imgFile: "",
    });
  };

  const positionsList = () => {
    dispatch(
      positionsListing({
        orgId: orgInfo?.orgId,
        adminUserId: user?.responseData?.userId,
      })
    );
  };

  const handleJoinModal = (item) => {
    setUserList([]);
    positionsList()
    setOpenJoin(true)
    setOpenGroup({
      ...openGroup,
      show: false,
      item: {
        ...openGroup.item,
        departmentId: item.id,
        departmentName: item.projectName,
      },
    });
  }

  const handleOpenAddGroupModal = (item) => {
    setOpenGroup({
      ...openGroup,
      show: true,
      item: {
        ...openGroup.item,
        userId: item.userId,
        name: `${item.firstName} ${item.lastName}`,
        isAdmin: false,
      },
    });
  };

  const updatetable = (data) => {
    setsearchUserList(data?.responseData?.returnedRecords);
  };

  const searchUserToJoin = debounce((value) => {
    if (value.length >= 3) {
      dispatch(
        doSearchUserToJoinDept(
          {
            orgId: orgInfo?.orgId,
            adminUserId: user?.responseData?.userId,
            searchString: value,
            id: openGroup?.item?.departmentId
          },
          updatetable
        )
      );
    }
  }, 400);

  const columns = [
    {
      name: '',
      label: '',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="flex items-center">
              <p>{dataIndex + 1}</p>
            </div>
          );
        },
      },
    },
    {
      name: '', // field name in the row object
      label: 'Picture', // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let user = userList[dataIndex];
          return (
            <div className="flex items-center">
              <Avatar
                className="w-30 h-30"
                src={user?.profilePicture}
                // onClick={() => dialog(user?.profilePicture)}
                // style={{ cursor: 'pointer' }}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'mobile',
      label: 'Phone',
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let user = userList[dataIndex];
          return (
            <div className="flex items-center">
              {checkstringLength(user.mobile, 27)}
            </div>
          );
        },
      },
    },
    {
      name: 'action',
      label: ' ',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          let user = userList[dataIndex];
          return (
            <div className="flex items-center">
              <div className="flex-grow"></div>
              <Tooltip title="Add a User" placement="top">
                <IconButton onClick={() => handleOpenAddGroupModal(user)}>
                  <Icon>add</Icon>
                </IconButton>
              </Tooltip>
              {/* </Link> */}
            </div>
          );
        },
      },
    },
  ];
  const closeAddUserModal = () => {
    setOpenGroup({
      ...openGroup,
      show: false,
      item: { ...openGroup.item, positionId: '', isAdmin: false },
    });
    setOpenJoin(false)
  };

  const handleAddUsetDept = () => {
    const { name, departmentName, isAdmin, ...rest } = openGroup.item;
    const data = { ...rest };
    dispatch(
      addUsersToDepartment(
        {
          orgId: orgInfo?.orgId,
          adminUserId: user?.responseData?.userId,
          isAdmin: `${isAdmin}`,
          ...data,
        },
        () => {
          adduserToGroupCallback();
        }
      )
    );
  };
  const doCloseModal = () => {
    setOpenModal(false);
    setOpenGroup({ ...openGroup, show: false, item: {} });
  };

  const adduserToGroupCallback = () => {
    closeAddUserModal();
    doCloseModal();
    contactListGroupWithoutLoader(); // get profile again
  };

  const addGroupCallback = () => {
    handleCloseAddGroup();
    contactListGroupWithoutLoader();
  };


  const contactListGroupWithoutLoader = () => {
    dispatch(
      getUserProfile({
        orgId: orgInfo?.orgId,
        userId: user?.responseData?.userId,
      })
    );
  }

  const updateEdit = (user) => {
    setaddGroup({
      ...addGroup,
      open: true,
      description: user?.description,
      image: user?.image,
      isEdit: true,
      name: user?.name,
      type: user?.type,
      groupLeaderUserId: user?.groupLeader?.leaderUserId,
      groupId: user?.id,
      ...user
    });
    user?.groupIntros && setgroupIntros([...user?.groupIntros]);
    if (user?.meetingTimeList?.length > 0) {
      let data = {}
      // user?.meetingTimeList?.map((item, index) => {
        //  data[item.day] = item.time
        // })
        const userData = user?.meetingTimeList?.map((item) => (
          data[item.day] = item.time
        ))
      setmeetingTimes(data)
    }
    if (user?.groupLeaderInfo) {
      setsearchGroupUser(true);
      // const words = user?.groupLeader?.leaderName.split(" ");
      setSelectedGrpLeader({
        firstName: user?.groupLeaderInfo?.firstName,
        lastName: user?.groupLeaderInfo?.lastName,
        avatar: user?.groupLeaderInfo?.profilePicture,
      });
    }
  };

  const handleCloseAddGroup = () => {
    setaddGroup(initialState);
    setgroupIntros([
      {
        // displayOrder: "",
        // title: "",
        type: "",
        url: "",
      },
    ]);
    setgroupLeader({
      leaderName: "",
      leaderImage: "",
      leaderDescription: "",
      leaderTelephone: "",
      leaderEmail: "",
    });
    setmeetingTimes({});
    setSelectedGrpLeader({
      firstName: "",
      lastName: "",
      avatar: "",
    });
    setsearchGroupUser(true);
  };

  const lastkey = Object.keys(meetingTimes).pop();
  let lastValue = meetingTimes[Object.keys(meetingTimes).pop()];

  const handleCreateGroup = () => {
    const meetingTimeList = [];
    if (lastkey !== undefined) {
       for (const item in meetingTimes) {
         meetingTimeList.push({
         day: item,
         time: meetingTimes[item]
       })
    }
     }
     const payload = {
       groupType: addGroup.groupType,
       groupName: addGroup.name,
       description: addGroup.description,
       meetingTimeList,
       orgId: orgInfo?.orgId,
       adminUserId: user?.responseData?.userId,
       groupLeaderUserId: addGroup.groupLeaderUserId
     }
     if (addGroup.isEdit) {
       payload['id'] = addGroup.groupId
       return dispatch(editInterestServiceGroup(payload, addGroupCallback))
     }
     // console.log(payload, 'payload');
    //  return dispatch(addInterestServiceGroup(payload, addGroupCallback))
   }

   const handleChange = ({ target: { name, value } }) => {
    let temp = { ...addGroup };
    temp[name] = value;
    setaddGroup(temp);
  };

  const handleChangeLeaderChange = ({ target: { name, value } }) => {
    let temp = { ...groupLeader };
    temp[name] = value;
    setgroupLeader(temp);
  };
  const meetingTime = [
    { name: "Monday", value: "Monday" },
    { name: "Tuesday", value: "Tuesday" },
    { name: "Wednesday", value: "Wednesday" },
    { name: "Thursday", value: "Thursday" },
    { name: "Friday", value: "Friday" },
    { name: "Saturday", value: "Saturday" },
    { name: "Sunday", value: "Sunday" },
  ];

  const DayandTimePicker = ({ day, time }) => {
    const dayTime = {
      day: "",
      time: null,
    };
    return (
      <div className="d-flex" style={{ columnGap: "1rem" }}>
        <TextField
          label="Day"
          value={day}
          onChange={(e) => {
            dayTime.day = e.target.value;
            // if (dayTime.day !== "" && dayTime.time !== null) {
            const { day, time } = dayTime;
            let temp = { ...meetingTimes };
            temp[day] = time;
            if (Object.keys(meetingTimes).length < 2) {
              let data = {};
              data[day] = time;
              setmeetingTimes(data);
            } else {
              delete temp[""];
              setmeetingTimes(temp);
            }
            // }
          }}
          // helperText="Please select your currency"
          select
          fullWidth
          className="w-full pb-3"
          variant="outlined"
          size="small"
          type="text"
          name="suffix"
          placeholder="Select Day"
          errorMessages={["this field is required"]}
        >
          {meetingTime.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            inputVariant="outlined"
            className="mb-5 w-full"
            value={time ? new Date(`October 13, 2014 ${time}`) : null}
            placeholder="Select Time"
            onChange={(date) => {
              dayTime.time = moment(date).format("hh:mm a");
              let temp = { ...meetingTimes };
              temp[day] = dayTime.time;
              if (Object.keys(meetingTimes).length < 2) {
                // let data = {};
                // data[day] = time;
                setmeetingTimes(temp);
              } else {
                delete temp[""];
                setmeetingTimes(temp);
              }
              // }
            }}
            size="small"
          />
        </MuiPickersUtilsProvider>
        {Object.keys(meetingTimes).length > 1 && (
          <Icon
            fontSize="small"
            style={{ fontWeight: "bolder", cursor: "pointer" }}
            onClick={() => {
              let newTime = { ...meetingTimes };
              delete newTime[day];
              setmeetingTimes(newTime);
            }}
          >
            delete
          </Icon>
        )}
      </div>
    );
  };

  const columnsSearch = [
    {
      name: "",
      label: "",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="flex items-center">
              <p>{dataIndex + 1}</p>
            </div>
          );
        },
      },
    },
    {
      name: "", // field name in the row object
      label: "Picture", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let user = searchUserList[dataIndex];
          return (
            <div className="flex items-center">
              <Avatar
                className="w-30 h-30"
                src={user?.profilePicture}
                // onClick={() => dialog(user?.profilePicture)}
                // style={{ cursor: 'pointer' }}
              />
            </div>
          );
        },
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
      },
    },
    {
      name: "action",
      label: " ",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          let user = searchUserList[dataIndex];
          return (
            <div className="flex items-center">
              <div className="flex-grow"></div>
              <Tooltip title="Add a User" placement="top">
                <IconButton
                  onClick={() => {
                    setaddGroup({
                      ...addGroup,
                      groupLeaderUserId: user.userId,
                    });
                    setSelectedGrpLeader({
                      firstName: user.firstName,
                      lastName: user.lastName,
                      avatar: user.profilePicture,
                    });
                    doCloseModal();
                  }}
                >
                  <Icon>add</Icon>
                </IconButton>
              </Tooltip>
              {/* </Link> */}
            </div>
          );
        },
      },
    },
  ];

    // search user
    const searchUser = debounce((value) => {
      if (value.length >= 3) {
        dispatch(
          doSearchUser(
            {
              orgId: orgInfo?.orgId,
              adminUserId: user?.responseData?.userId,
              searchString: value,
            },
            updatetable
          )
        );
      }
    }, 400);

    const handleCallbackCover = () => {
      dispatch(
        getUserProfile({
          orgId: orgInfo?.orgId,
          userId: user?.responseData?.userId,
        })
      );
      closeCoverModal()
    }

    const handleCoverUpload = () => {
      const type = openCoverModal.item.groupType === "INTEREST_GROUP" ? 'interestGroups' : 'serviceGroups'
      const formData = new FormData()
      formData.append('orgId', orgInfo.orgId)
      formData.append('adminUserId', user?.responseData?.userId)
      formData.append('id', openCoverModal.item.id)
      formData.append('groupType', type)
      formData.append('mediaFile',dataURLtoFile(openCoverModal.imgUrl, 'cover.png'))
      dispatch(changeGroupBanner(formData, handleCallbackCover))
    }
  
  return (
    <div>
      {data && <GridView
        sliderValue={50}
        // list={userProfileData?.supervisorModule?.serviceUnitsInterestGroups}
        list={data}
        buttonAction={handleOpenGroupUserModal}
        iconEdit={updateEdit}
        iconAdd={handleJoinModal}
        addTooltip={`Add Member`}
        showImage={true}
        imageTooltip={"Change Group Image"}
        editTooltip={"Edit Group"}
        // imageTooltip={`Change ${orgInfoList?.interestServiceGroupsDropDownList.find((item) => item.name === groupSelect).title} Group Banner`}
        // editTooltip={`Edit ${orgInfoList?.interestServiceGroupsDropDownList.find((item) => item.name === groupSelect).title}`}
        name="Department"
        changeImage={handleChangeCover}
        imageStyle={{
          // width: '240px',
          height: "190px",
          objectFit: "Cover",
        }}
        isViewBackgroundImage={true}
        viewBackgroundImage={handleViewBackgroundImage}
        handleOpenSendMessage={handleOpenSendMessage}
        sendMessage={true}
        showIcon={true}
      />}

      <ResizeableModalWithoutBtn
        modalState={openGroupUsers?.show}
        title={`${openGroupUsers?.item?.name} List`}
        maxWidth="lg"
        fullWidth={true}
        resetModalState={closeContactUserModal}
      >
        <MainDepartment openGroupUsers={openGroupUsers?.item} />
      </ResizeableModalWithoutBtn>

      {openSendMessage?.show && (
        <ResizeableModalWithoutBtn
          modalState={openSendMessage?.show}
          // handleCloseModal={handleCloseSendMessage}
          resetModalState={handleCloseSendMessage}
          title={`${openSendMessage?.item?.name} Users`}
        >
          <Communication
            type="special"
            recipientType="group"
            onClose={handleCloseSendMessage}
            getAnalyzeData={getAnalyzeData}
            setIsClose={setIsClose}
            specialGroup={"deptServiceGroups"}
            selectedChild={openSendMessage?.item}
          />
        </ResizeableModalWithoutBtn>
      )}

      {isClose && (
        <SuccessPage
          setIsClose={setIsClose}
          isClose={isClose}
          analyzeData={analyseData}
          width="md"
        />
      )}

      <ChangeGropuBanner
        openCoverModal={openCoverModal}
        setOpenCoverModal={setOpenCoverModal}
        handleSubmit={handleCoverUpload}
        closeCoverModal={closeCoverModal}
      />

      {viewImage?.show && (
        <ViewUserImage
          isOpen={viewImage?.show}
          image={viewImage?.item?.projectImage}
          handleCloseModal={closeViewImage}
        />
      )}

<ResizeableModalWithoutBtn
        modalState={openJoin}
        resetModalState={() =>setOpenJoin((false))}
        fullWidth={true}
        maxWidth="md"
        title={`Search User to add to ${openGroup?.item?.departmentName}`}
      >
        {loading && (
          <div className="position-relative">
            <div className={classes.loader}>
              {/* <h5 className="mb-0">searching...</h5> */}
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
                color="default"
              />
            </div>
          </div>
        )}
        <span className="search-table">
          <MUIDataTable
            title={"Search User"}
            data={userList}
            columns={columns}
            options={{
              filterType: "textField",
              responsive: "standard",
              filter: false,
              textLabels: {
                body: {
                  noMatch: "", // this would be whatever you want the message to say
                },
              },
              selectableRows: "none", // set checkbox for each row
              // search: false, // set search option
              // filter: false, // set data filter option
              download: false, // set download option
              print: false, // set print option
              pagination: false, //set pagination option
              viewColumns: false, // set column option
              elevation: 0,
              rowsPerPage: 100,
              rowsPerPageOptions: [100, 200, 500, 1000],
              searchOpen: true,
              customSearchRender: (
                searchText,
                handleSearch,
                hideSearch,
                options
              ) => {
                return (
                  <Grow appear in={true} timeout={300}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoFocus
                      onChange={({ target: { value } }) => searchUserToJoin(value)}
                      InputProps={{
                        style: {
                          paddingRight: 0,
                        },
                        startAdornment: (
                          <Icon className="mr-2" fontSize="small">
                            search
                          </Icon>
                        ),
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              hideSearch();
                              dispatch({
                                type: actionTypes.CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP,
                              });
                              setsearchUserList([]);
                            }}
                          >
                            <Icon fontSize="small">clear</Icon>
                          </IconButton>
                        ),
                      }}
                    />
                  </Grow>
                );
              },
            }}
          />
        </span>
      </ResizeableModalWithoutBtn>

      <FormsModal
        open={openGroup?.show}
        onClose={closeAddUserModal}
        title={`Add ${openGroup?.item?.name} to ${openGroup.item.departmentName} Department`}
        submitText="Add user"
        handleFormSubmit={handleAddUsetDept}
        loading={
          isEdit
            ? renameContactGroupLoding?.loading
            : addContactGroupLoading?.loading
        }
        disabled={openGroup?.item?.positionId === ''}
      >
        <TextField
          className="w-full mb-5 mt-3"
          label="Positions"
          name="positions_"
          size="small"
          variant="outlined"
          select
          required
          fullWidth
          value={openGroup?.item?.positionId}
          onChange={handleChangeJoinDept}
        >
          {positionsListData?.map((item, ind) => (
            <MenuItem value={item?.id} key={ind}>
              {item?.name}
            </MenuItem>
          ))}
        </TextField>

        <div className="d-flex align-items-center" style={{ columnGap: '0.5rem' }}>
          <Checkbox
            className={classes.icon}
            // color="primary"
            checked={openGroup?.item?.isAdmin}
            onChange={() =>
              setOpenGroup({
                ...openGroup,
                item: {
                  ...openGroup.item,
                  isAdmin: !openGroup.item.isAdmin,
                },
              })
            }
          />
          <FormLabel component="legend" style={{fontSize: '16px', paddingTop: '8px'}}>Admin Access</FormLabel>
        </div>
      </FormsModal>

      <FormsModal
        open={addGroup?.open}
        onClose={handleCloseAddGroup}
        title='Edit Group'
        // title={
        //   addGroup.isEdit
        //     ? `Edit ${orgInfoList?.interestServiceGroupsDropDownList.find((item) => item.name === groupSelect).title}`
        //     : `Add ${orgInfoList?.interestServiceGroupsDropDownList.find((item) => item.name === groupSelect).title}`
        // }
        submitText={"Submit"}
        handleFormSubmit={handleCreateGroup}
        loading={
          !createGroupState
        }
        disabled={lastkey && lastValue === null}
      >
        <div className="mb-5">
          <TextField
            // id="group-name"
            variant="outlined"
            name="name"
            size="small"
            label="Group Name"
            fullWidth
            required
            onChange={handleChange}
            value={addGroup?.name}
            // inputProps={{
            //   maxlength: 100,
            // }}
            // helperText={`${addGroup.description.length} / 100`}
          />
        </div>
        <div className="mb-5">
          <TextField
            className="mb-5 w-100"
            variant="outlined"
            name="description"
            label="Group description"
            size="small"
            onChange={handleChange}
            value={addGroup?.description}
            placeholder="Group description"
            required
            minRows={4}
            multiline
            // inputProps={{
            //   maxlength: 20,
            // }}
            // inputProps={{
            //   maxlength: 100,
            // }}
            // helperText={`${addGroup.description.length} / 50`}
          />
        </div>
      
        {searchGroupUser && (
          <div className="mb-5">
            <Button
              variant="contained"
              // color="primary"
              style={{ height: "fit-content", color: '#fff', background: theme.color, textTransform: 'capitalize' }}
              onClick={doOpenMOdal}
            >
              <Icon fontSize="small" style={{ fontWeight: "bolder" }}>
                search
              </Icon>
              <span className="pl-2">Add Group Leader</span>
            </Button>
          </div>
        )}
        {searchGroupUser && selectedGrpLeader.firstName && (
          <div className="mb-5">
            <LabelledOutline id="myID" label="Group Leader Details">
              <div className="d-flex mb-3 justify-between items-center">
                <div className="d-block">
                  {selectedGrpLeader.firstName && (
                    <p className="m-0 mb-5">
                      First Name: {selectedGrpLeader.firstName}
                    </p>
                  )}
                  {selectedGrpLeader.lastName && (
                    <p className="m-0">
                      Last Name: {selectedGrpLeader.lastName}
                    </p>
                  )}
                </div>
                <div
                  className="flex justify-between"
                  style={{ rowGap: "0.8rem", flexFlow: "row wrap" }}
                >
                  <span className="relative">
                    <img
                      style={{ width: "7rem", height: "7rem" }}
                      src={`${selectedGrpLeader.avatar}`}
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </LabelledOutline>
          </div>
        )}

{!searchGroupUser && (
          <div className="mb-5">
            <LabelledOutline id="myID" label="Group Leader Details">
              <TextField
                // id="group-name"
                variant="outlined"
                className="mb-5"
                name="leaderName"
                size="small"
                label="Leader Name"
                fullWidth
                required
                onChange={handleChangeLeaderChange}
                value={addGroup?.leaderName}
                // inputProps={{
                //   maxlength: 100,
                // }}
                // helperText={`${addGroup.description.length} / 100`}
              />
              <TextField
                // id="group-name"
                variant="outlined"
                name="leaderEmail"
                className="mb-5"
                size="small"
                label="Leader Email"
                fullWidth
                required
                onChange={handleChangeLeaderChange}
                value={addGroup?.leaderEmail}
                type="email"
                // inputProps={{
                //   maxlength: 100,
                // }}
                // helperText={`${addGroup.description.length} / 100`}
              />
              <TextField
                // id="group-name"
                variant="outlined"
                name="leaderDescription"
                className="mb-5"
                size="small"
                label="Description"
                fullWidth
                required
                onChange={handleChangeLeaderChange}
                value={addGroup?.leaderDescription}
                type="text"
                // inputProps={{
                //   maxlength: 100,
                // }}
                // helperText={`${addGroup.description.length} / 100`}
              />

              <PhoneInputField
                mobile={groupLeader.leaderTelephone}
                setMobile={(e) =>
                  setgroupLeader({
                    ...groupLeader,
                    leaderTelephone: e,
                  })
                }
                mobileError={mobileError}
                classBox="mb-5"
              />
            </LabelledOutline>
          </div>
        )}

        <div className="mb-5">
          <LabelledOutline label="Meeting Times">
            {Object.entries(meetingTimes).map(([key, value], i) => {
              return <DayandTimePicker day={key} time={value} />;
            })}
            <Button
              variant="contained"
              // color="primary"
              style={{ height: "fit-content", color: '#fff', background: theme.color, textTransform: 'capitalize' }}
              onClick={() => setmeetingTimes({ ...meetingTimes, "": null })}
              disabled={lastkey === "" || lastValue === null}
            >
              <Icon fontSize="small" style={{ fontWeight: "bolder" }}>
                add
              </Icon>
              <span className="pl-2">Add Meeting Time</span>
            </Button>
          </LabelledOutline>
        </div>

      </FormsModal>

      <ResizeableModalWithoutBtn
        modalState={openModal}
        resetModalState={doCloseModal}
        fullWidth={true}
        maxWidth="md"
        title="Search Person to add as Group Leader"
      >
        {loading && (
          <div className="position-relative">
            <div className={classes.loader}>
              {/* <h5 className="mb-0">searching...</h5> */}
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
                color="default"
              />
            </div>
          </div>
        )}
        <span className="search-table">
          <MUIDataTable
            title={"Search User"}
            data={searchUserList}
            columns={columnsSearch}
            options={{
              filterType: "textField",
              responsive: "standard",
              filter: false,
              textLabels: {
                body: {
                  noMatch: "", // this would be whatever you want the message to say
                },
              },
              selectableRows: "none", // set checkbox for each row
              // search: false, // set search option
              // filter: false, // set data filter option
              download: false, // set download option
              print: false, // set print option
              pagination: false, //set pagination option
              viewColumns: false, // set column option
              elevation: 0,
              rowsPerPage: 100,
              rowsPerPageOptions: [100, 200, 500, 1000],
              searchOpen: true,
              customSearchRender: (
                searchText,
                handleSearch,
                hideSearch,
                options
              ) => {
                return (
                  <Grow appear in={true} timeout={300}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoFocus
                      onChange={({ target: { value } }) => searchUser(value)}
                      InputProps={{
                        style: {
                          paddingRight: 0,
                        },
                        startAdornment: (
                          <Icon className="mr-2" fontSize="small">
                            search
                          </Icon>
                        ),
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              hideSearch();
                              dispatch({
                                type: actionTypes.CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP,
                              });
                              setsearchUserList([]);
                            }}
                          >
                            <Icon fontSize="small">clear</Icon>
                          </IconButton>
                        ),
                      }}
                    />
                  </Grow>
                );
              },
            }}
          />
        </span>
      </ResizeableModalWithoutBtn>
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  cardHead: {
    display: 'flex',
    justifyContent: 'space between',
    paddingBottom: '10px',
  },
  cardHeadText: {
    paddingTop: '23px',
    paddingLeft: '8px',
  },
  NameAvatarSize: {
    width: '64px',
    height: '64px',
  },
  iconColor: {
    color: '#626262',
  },
  loader: {
    position: 'absolute',
    right: '46%',
    marginTop: '1.2rem',
    display: 'flex',
    columnGap: '1rem',
    alignItems: 'center',
  },
}));
