import { useTheme } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ({name, handleClick, celebrationsListingData}) {
  const theme = useTheme()
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickEvent = (event) => {
    if(name[1]?.list?.length > 0) {
      return handleClick()
    }
    return setAnchorEl(anchorEl ? null : event.currentTarget);
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <>
    <Tooltip open={open} title='No celebrants at this time.' placement='top'>
    <div className='card' style={{width: '25rem', cursor: 'pointer', marginTop: '1rem'}} onClick={handleClickEvent}>
        <img src={name[1]?.banner} class="card-img-top" style={{maxHeight: '184px', objectFit: 'cover'}} alt="..."></img>
        <div class="card-body">
            <h3 style={{color: 'black'}} dangerouslySetInnerHTML={{__html: name[1].label}} />
            {/* {celebrationsListingData[name.route]?.list?.length === 0 && <p style={{color: 'black', padding: '1rem 0px'}}>No Celebrant</p>} */}
        </div>
    </div>
    </Tooltip>
    </>
  )
}
