// // STEP 1 - Include Dependencies
// // Include react
// import React from "react";

// // Include the react-fusioncharts component
// import ReactFC from "react-fusioncharts";

// // Include the fusioncharts library
// import FusionCharts from "fusioncharts";

// // Include the chart type
// import Chart from "fusioncharts/fusioncharts.charts";

// // Include the theme as fusion
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// // Adding the chart and theme as dependency to the core fusioncharts
// ReactFC.fcRoot(FusionCharts, Chart, FusionTheme);

// // STEP 2 - Chart Data

// // STEP 3 - Creating the JSON object to store the chart configurations

// const PrayerCharts = ({data, chartTitle, categoryName}) => {
//   const chartConfigs = {
//     type: "column2d", // The chart type
//     width: "100%", // Width of the chart
//     height: "400", // Height of the chart
//     dataFormat: "json", // Data type
//     dataSource: {
//       // Chart Configuration
//       chart: {
//         //Set the chart caption
//         caption: chartTitle,
//         //Set the x-axis name
//         xAxisName: categoryName,
//         //Set the theme for your chart
//         theme: "fusion"
//       },
//       // Chart Data
//       data
//     }
//   };
//   return (<ReactFC {...chartConfigs} />);
// }

// export default PrayerCharts;

import React from 'react'
import { useSelector } from 'react-redux'
import QuickChart from 'quickchart-js'

export default function PrayerCharts({data, chartTitle}) {
	const getThemeChangeData = useSelector(state => state.getThemeChangeData)
  const labels = []
  const values = []
  data.map((item) => {
    labels.push(item.label)
    values.push(item.value)
  })
  const myChart = new QuickChart();
  myChart.setConfig({
    type: 'bar',
    data: {
      labels,
      datasets: [{
        label: chartTitle,
        data: values
      }]
    },
    options:{
      legend:{
        labels:{
          fontColor: `${getThemeChangeData.theme ? '#fff' : '#252625'}`
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: `${getThemeChangeData.theme ? '#fff' : '#252625'}`
          }
        }],
        yAxes: [{
          ticks: {        
            fontColor: `${getThemeChangeData.theme ? '#fff' : '#252625'}`,
            beginAtZero: true,
          },
        }]
      },
      title:{
        display: true,
        text: chartTitle,
        fontSize: 20,
        fontColor: `${getThemeChangeData.theme ? '#fff' : '#252625'}`
      }
    }
  })
  myChart.setBackgroundColor("transparent")
  // .setWidth(200)
  // myChart().then((result) => {
  //   console.log(result, 'charts');
  // });
  return (
    <div>
      <img src={myChart.getUrl()} alt="chart image" srcset="" height='100%' width='100%' />
    </div>
  )
}
