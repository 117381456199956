import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Button } from 'react-bootstrap'
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { getFormattedDate } from '../../../utils';
import { getUserDetails } from '../../../utils/authHelpers';
import {
  Grid,
  MenuItem,
  TextField,
  Paper
} from '@material-ui/core';
import {updateARecurringPortal} from '../../../store/actions/userDashboardActions/give';
import {ColorButton} from './AddMoreContributionForm'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import './Give.scss';

export const UpdateRecurringGiveForm = ({closeModal, amount, contributionType, recurring, recordId}) => {
  const { handleSubmit, errors, control, watch } = useForm({
    mode: "onBlur",
  });

  const themeColor = useThemeColor();
  
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const updateARecurringPortalData = useSelector(state => state.updateARecurringPortalData, shallowEqual);
 
  const date = new Date();
  const user = getUserDetails();
  const dispatch = useDispatch()

  const onSubmit = (values) => {
    console.log(values, 'values')
    console.log(startDate, 'startDate')

    if(!startDate) return setStartDateError(true);
    else if(startDate) setStartDateError(false);
    const formattedDate = getFormattedDate(startDate);

    if(values && formattedDate){
      const payload = {
        ...values,
        recordId,
        recurringStartDate: formattedDate,
        orgId: orgInfo.orgId,
        userId: user.userId
      }
      console.log(payload, 'payload')
      dispatch(updateARecurringPortal(payload, closeModal))
    }
  }

  return (
    <div className="new-auto-debit-enrollment">
      <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
              <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    className="mb-8"
                    name="amount"
                    label="Amount"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.amount ? true : false}
                  />
                }
                name="amount"
                control={control}
                defaultValue={amount || ""}
                rules={{
                  required: true
                }}
              />
              <Controller
                as={
                  <TextField
                    className="mb-8"
                    label="Contribution Type"
                    name="contributionType"
                    size="small"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    {orgInfo.contributionTypes?.map((list) => (
                      <MenuItem value={list} key={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                  }
                  name="contributionType"
                  control={control}
                  defaultValue={contributionType || ""}
                  rules={{
                    required: true
                  }}
                />
                <Paper className="w-100 mb-8">
                  <DatePicker selected={startDate}
                    onChange={date => setStartDate(date)}
                    minDate={new Date()}
                    maxDate={new Date(date.setMonth(date.getMonth() + 1))}
                    className={`form-control p-5 w-100 ${startDateError ? 'border-danger' : ''}`}
                    placeholderText="Click here to select start date" />
                  {startDateError && <small className="text-danger errorText">Select a Start Date</small>}
                </Paper>
                <Controller
                  as={
                  <TextField
                    className="mb-8"
                    label="Auto-Debit Type"
                    name="recurring"
                    size="small"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    {orgInfo.recurringOptions?.map((list) => (
                      <MenuItem value={list} key={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="recurring"
                control={control}
                defaultValue={recurring || ""}
                rules={{
                  required: true
                }}
              />
                <ColorButton className="w-100 btn py-3 px-7" type="submit" variant="contained" backgroundColor={themeColor.color}>
                  <span>{updateARecurringPortalData?.loading ? 'Please wait...' : 'Update Auto-Debit'}</span>
                  {updateARecurringPortalData?.loading && <span className="ml-3 spinner spinner-white"></span>} 
                </ColorButton>
                {/* </div> */}
              </Grid>
              
          </Grid>
      </form>
    </div>
  )
}