import React from 'react'
import { Button } from 'react-bootstrap'
import { useThemeColor } from '../../utils/customHooks/useThemeColor';

import './Navigator.scss'

const Navigator = ({ goBack, goNext, cardReview, loading, type='button', className, isCustomBtn = false, children, backBtnName='Back'}) => {
  const themeColor = useThemeColor();

  return (
    <div className="d-flex justify-content-between my-8 navigator-wrapper">
      <Button onClick={goBack} variant="secondary" className={`btn btn-lg py-3 ${className}`}>{backBtnName}</Button>
      {!isCustomBtn ? (type === 'submit' ? <Button type="submit" className={`btn btn-lg py-3 ${className}`} style={themeColor.btnStyle} disabled={loading}>
        <span>{cardReview ? cardReview : 'Next'}</span>
        {loading && <span className="ml-3 spinner spinner-white"></span>}
      </Button> :
      <Button type={type} onClick={goNext} className={`btn btn-lg py-3 ${className}`} style={themeColor.btnStyle} disabled={loading}>
        <span>{cardReview ? cardReview : 'Next'}</span>
        {loading && <span className="ml-3 spinner spinner-white"></span>}
      </Button>) :
      children}
    </div>
  )
}

export default Navigator