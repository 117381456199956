import React, { useState } from "react";
// import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import "./GiveOption.scss";
import { MenuItem, TextField } from "@material-ui/core";

export const GiveOption = ({
  getSelectValue,
  giveError,
  classname,
  selectedValue = "",
}) => {
  // const { register, handleSubmit, errors, control } = useForm({
  //   mode: "onBlur",
  // });
  const [selectValue, setSelectValue] = useState(selectedValue);
  const orgInfo = useSelector((state) => state.orgInfo.responseData);
  // const cardDetails = useSelector(state => state.creditCardInfo, shallowEqual)

  const handleChange = (e) => {
    // console.log(e.target.value, 'value selected')
    setSelectValue(e.target.value || null);
    getSelectValue(e.target.value || null);
  };

  return (
    <div className={`give-option mt-5 w-100 ${classname} pt-3`}>
      <TextField
        id="outlined-select-give-type"
        select
        label="Give Type"
        name="giveType"
        onChange={handleChange}
        variant="outlined"
        fullWidth
        error={giveError.giveType}
        helperText={giveError.giveType}
        value={selectValue || ""}
      >
        <MenuItem value="">Please select One</MenuItem>
        {orgInfo?.contributionTypes.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {/* {giveError.giveType && <small className="text-danger errorText">{giveError.giveType}</small>} */}
    </div>
  );
};
