import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  setCreditCardOwnerInfo,
  setHideAmountInput,
  donatedAmount,
  scrollToAmountErrorMsg,
  selectedPaymentOption,
  enableGiveViaNav,
} from "../../../store/actions";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import { InlineErrorMsg } from "../../Common/InlineErrorMsg";
import { PaymentForm } from "./PaymentForm";
import { TextField } from "@material-ui/core";
import "react-phone-number-input/style.css";
import "./ActiveForm.scss";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import NotificationMesage from "../../Common/NotificationMesage";
import { ResizeableModalWithoutBtn } from "../../Common/ResizeableModal";
import { useContributionProcessEngine } from "../../../utils/customHooks/useContributionProcessEngine";
import { InitialGiveForm } from "./InitialGiveForm";

export const ActiveForm = () => {
  const { handleSubmit, errors, control } = useForm({
    mode: "onBlur",
  });
  const [mobile, setMobile] = useState("");
  const [userError, setUserError] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [token, setToken] = useState("");
  const [dynamicAction, setDynamicAction] = useState("submit");
  const dispatch = useDispatch();
  const themeColor = useThemeColor();
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const showPaymentOptionPage = useContributionProcessEngine();
  // console.log(showPaymentOptionPage, 'payment');
  // Global store data
  const ipData = useSelector((state) => state.ipData);
  const cardDetails = useSelector(
    (state) => state.creditCardInfo,
    shallowEqual
  );
  const amount = useSelector((state) => state.donatedAmountData.amount);
  const enableGive = useSelector((state) => state.enableGiveViaNav);
  const selectedPayment = useSelector((state) => state.selectedPaymentOption);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("recaptcha verifiction not successful");
      return;
    }

    const result = await executeRecaptcha("dynamicAction");
    // console.log(result, 'result');
    setToken(result);
  }, [dynamicAction, executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      setToken(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, dynamicAction]);

  const onSubmit = (data) => {
    if (mobileRequired()) return mobileRequired();
    const { firstName, lastName, email } = data;
    const payload = { firstName, lastName, email, mobile, amount };
    if (enableGive.enable || !showPaymentOptionPage.hasMultiple) {
      dispatch(enableGiveViaNav({ disable: false }));
      clickHandler();
      if (token) {
        dispatch(setCreditCardOwnerInfo(payload));
        setShowPayment(true);
      } else {
        toast.error(
          <NotificationMesage
            message="Recaptcha Verification Failed!"
            responseStatus="01"
          />
        );
      }
    } else {
      dispatch(enableGiveViaNav({ disable: true }));
      toast.error("Please select give options");
    }
  };

  /** This effect is to reset donated amount and display the amount input when the component mounts*/
  useEffect(() => {
    dispatch(setHideAmountInput(false));
    dispatch(donatedAmount(0.0));

    dispatch(selectedPaymentOption({ ...selectedPayment, disabled: false }));
  }, []);

  useEffect(() => {
    if (showPaymentOptionPage.hasMultiple) {
      // if (showPaymentOptionPage.hasMultiple) {
      //   setShowPaymentOption(true)
      // }
    }
    if (
      !showPaymentOptionPage.hasMultiple &&
      showPaymentOptionPage.paymentList
    ) {
      dispatch(selectedPaymentOption(showPaymentOptionPage?.paymentList[0]));
      // console.log(showPaymentOptionPage?.paymentList[0]);
      // setShowPaymentOption(false)
    }
  }, [showPaymentOptionPage]);
  // useEffect(() => {
  //   if (enableGive.enable) {
  //     setShowPaymentOption(true)
  //   }
  // }, [enableGive.enable])

  // console.log(enableGive, 'enable');
  const handleNextPayment = (data) => {
    setShowPaymentOption(false);
  };

  const mobileRequired = () => {
    if (selectedPayment.type === "PPS" && !mobile && !cardDetails.mobile) {
      return true;
    }
  };

  return showPayment ? (
    <PaymentForm />
  ) : (
    <div className="login-form login-signin activeForm-wrapper">
      {/* <p>Switch</p> */}
      <div className="text-center mb-10 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="las la-hand-holding-heart"></i>
          </span>
        </div>
        <h3 className="font-size-h3 mb-2">Your Information</h3>
      </div>
      <hr />
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="m-auto">
          <div className="d-md-flex justify-content-md-between">
            <div className="fv-plugins-icon-container mr-md-3 mb-8">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      label="First Name"
                      name="firstName"
                      variant="outlined"
                      type="text"
                      fullWidth
                      defaultValue={cardDetails.firstName || ""}
                      error={errors.firstName}
                    />
                  }
                  name="firstName"
                  control={control}
                  defaultValue={cardDetails.firstName || ""}
                  rules={{
                    required: true,
                  }}
                />
              </div>
              {errors.firstName && (
                <InlineErrorMsg msg="First name is required" />
              )}
            </div>
            <div className="fv-plugins-icon-container ml-md-3 mb-8">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      type="text"
                      fullWidth
                      defaultValue={cardDetails.lastName || ""}
                      error={errors.lastName}
                    />
                  }
                  name="lastName"
                  control={control}
                  defaultValue={cardDetails.lastName || ""}
                  rules={{
                    required: true,
                  }}
                />
              </div>
              {errors.lastName && (
                <InlineErrorMsg msg="Last name is Required" />
              )}
            </div>
          </div>
          <div className="fv-plugins-icon-container mb-8">
            <p style={{ marginBottom: "0.2rem" }}>Phone Number</p>
            <PhoneInput
              international
              withCountryCallingCode
              countryCallingCodeEditable={false}
              defaultCountry={ipData ? ipData.country_code : null}
              placeholder="Mobile"
              value={mobile || cardDetails.mobile}
              onChange={setMobile}
              className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${
                mobile && !isValidPhoneNumber(mobile)
                  ? "is-invalid"
                  : mobile && isValidPhoneNumber(mobile)
                  ? "is-valid"
                  : ""
              }`}
              name="mobile"
              error={
                mobile
                  ? isValidPhoneNumber(mobile)
                    ? undefined
                    : "Invalid mobile number"
                  : "Mobile number is required"
              }
            />
            <small className="text-muted">
              (To get your giving notification by text)
            </small>
            {mobile && !isValidPhoneNumber(mobile) ? (
              <InlineErrorMsg msg="Invalid mobile number" />
            ) : userError.mobile ? (
              <InlineErrorMsg msg={userError.mobile} />
            ) : (
              ""
            )}
            {mobileRequired() && (
              <InlineErrorMsg msg="mobile number is required" />
            )}
          </div>
          <div className="fv-plugins-icon-container mb-8">
            <div className="position-relative">
              <Controller
                as={
                  <TextField
                    label="Email"
                    name="email"
                    variant="outlined"
                    type="email"
                    fullWidth
                    defaultValue={cardDetails.email || ""}
                    error={errors.email}
                  />
                }
                name="email"
                control={control}
                defaultValue={cardDetails.email || ""}
                rules={{
                  required: true,
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid Email",
                  },
                }}
              />
            </div>
            {errors.email?.type === "required" && (
              <InlineErrorMsg msg="Email is Required" />
            )}
            {errors.email?.type === "pattern" && (
              <InlineErrorMsg msg={errors.email.message} />
            )}
          </div>
          <Button
            type="submit"
            className="btn w-100 font-weight-bold py-4 px-9 mb-5"
            style={themeColor.btnStyle}
            block
          >
            Next
          </Button>
        </div>
      </form>
      {/* <ResizeableModalWithoutBtn
        modalState={showPaymentOption}
        resetModalState={() => {}}
        title=""
        maxWidth='xs'
        fullWidth={true}
        // header="Crop and click the button below to continue"
      >
        <InitialGiveForm handleNext={handleNextPayment} />
        </ResizeableModalWithoutBtn> */}
    </div>
  );
};
