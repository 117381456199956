import {
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Button,
  Typography,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ButtonContainer } from "../Common/Button";
import PhoneInputComponent from "../Common/PhoneInputComponent";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import { useSelector, shallowEqual } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLoading } from "echarts";
import ReactDatePicker from "react-datepicker";

export default function DynamicForms({
  data,
  submitData,
  activeStep,
  handleBack,
  completed,
  completedSteps,
  handleComplete,
  totalSteps,
  handleSubmitForm,
}) {
  const { register, handleSubmit, errors, control, reset } = useForm({
    mode: "onBlur",
    // defaultValues: inputData
  });
  const [selectedTime, setSelectedTime] = React.useState("");
  const submitFormLoading = useSelector(state => state.submitForm)
  const [inputData, setInputData] = React.useState({});
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const themeColor = useThemeColor();
  const backgroundColor = themeColor.color;
  const onSubmit = (data) => {
    handleSubmitForm(data)
    setInputData(data)
    // handleComplete();
    // submitData(data, activeStep);
    // reset();
    // e.target.reset();
    //   document.getElementById("myForm").reset();
    //   const text = document.querySelectorAll('#textarea')
    //   console.log(text, 'text');
    //   text.map((item) => console.log(item, '');)
  };
  const classes = useStyles();

  React.useEffect(() => {
    if (Object.values(inputData).length > 0) {
      reset(inputData)
    }
  }, [inputData])
  
  // Array.from(document.querySelectorAll("#textarea")).map(
  //   (item) => (item.value = "")
  // );
  // Array.from(document.querySelectorAll("#input_text")).map(
  //   (item) => (item.value = "")
  // );
  //   console.dir(document.querySelectorAll('#textarea'), 'select');
  return (
    <form
      className="form fv-plugins-bootstrap fv-plugins-framework mt-5"
      onSubmit={handleSubmit(onSubmit)}
      id="myForm"
    >
      {data?.map((item, id) => (
        <div className="py-2 w-100" key={id}>
          {item?.type === "dropdown" && item.name ? (
            <Controller
              as={
                <TextField
                  select
                  name={item?.name}
                  label={item?.label}
                  variant="outlined"
                  fullWidth
                  required={item?.validate !== ''}
                  {...item}
                >
                  {item?.option?.map((val, index) => (
                      typeof val === 'object' ? (<MenuItem value={val.name} key={index}>
                        {val.title}
                      </MenuItem>) : (
                        <MenuItem value={val} key={index}>
                        {val}
                      </MenuItem>
                      )
                    ))}
                </TextField>
              }
              name={item?.name}
              control={control}
              rules={{
                required: item?.validate !== '',
              }}
            />
          ) : item?.type === "text" && item.name ? (
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  label={item.label}
                  name={item.name}
                  required={item?.validate !== ''}
                  id="input_text"
                  {...item}
                />
              }
              control={control}
              name={item.name}
              rules={{
                required: item?.validate !== '',
              }}
            />
          ) : item?.type === "radio" && item.name ? (
            // <FormGroup row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "3rem",
                outline: "1px solid #e2dcdc",
                padding: "7px",
                borderRadius: "4px",
              }}
            >
              <FormLabel style={{ paddingTop: "10px" }}>{item.label}</FormLabel>
              <Controller
                as={
                  <RadioGroup row>
                    {item.option?.map((item, index) => (
                      <FormControlLabel
                        value={item}
                        key={index}
                        control={<Radio />}
                        label={item}
                      />
                    ))}
                  </RadioGroup>
                }
                name={item.name}
                control={control}
                rules={{
                  required: item.validate !== '',
                }}
              />
            </div>
          ) : // </FormGroup>
          item?.type === "date" && item.name ? (
            <div style={{ width: "100%" }}>
              <Controller
                as={
                  <TextField
                    name={item.name}
                    label={item.label}
                    variant="outlined"
                    fullWidth
                    type="date"
                    required={item?.validate !== ''}
                    InputLabelProps={{ shrink: true }}
                    {...item}
                  />
                }
                name={item.name}
                control={control}
                // fullWidth
                // onChange={([selected]) => {
                //     console.log(selected, 'selected date');
                //     return  setSelectedTime(selected)
                // }}
                // onChange={(data) => {
                //     console.log(data, 'selected date111');
                //     // return  setSelectedTime(selected)
                // }}
                // valueName="selected"
                register={register({ required: item.isRequired })}
                rules={{
                  required: item.isRequired,
                }}
              />
            </div>
          ) : item.formElementType === "MYFIRSTNAME" && item.name ? (
            <Controller
              as={
                <TextField
                  label={item.name}
                  name={item.name}
                  fullWidth
                  variant="outlined"
                  required={item?.isRequired}
                />
              }
              name={item.name}
              control={control}
              defaultValue={userProfileData?.userFirstName}
              rules={{
                required: item.isRequired,
              }}
            />
          ) : item.formElementType === "MYLASTNAME" && item.name ? (
            <Controller
              as={
                <TextField
                  label={item.name}
                  name={item.name}
                  fullWidth
                  variant="outlined"
                  required={item?.isRequired}
                />
              }
              name={item.name}
              control={control}
              defaultValue={userProfileData?.userLastName}
              rules={{
                required: item.isRequired,
              }}
            />
          ) : item.formElementType === "MYEMAIL" && item.name ? (
            <Controller
              as={
                <TextField
                  label={item.name}
                  name={item.name}
                  fullWidth
                  variant="outlined"
                  required={item?.isRequired}
                />
              }
              name={item.name}
              control={control}
              defaultValue={userProfileData?.userEmail}
              rules={{
                required: item.isRequired,
              }}
            />
          ) : item.formElementType === "PHONENUMBER" && item.name ? (
            <Controller
              render={({ onChange, value }) => (
                <PhoneInputComponent handlePhone={onChange} />
              )}
              name={item.name}
              control={control}
              rules={{
                required: item.isRequired,
              }}
            />
          ) : item.type === "textarea" && item.name ? (
            <Controller
              as={
                <TextField
                  id="textarea"
                  label={item.label}
                  name={item.name}
                  multiline
                  minRows={4}
                  fullWidth
                  variant="outlined"
                  required={item?.validate !== ''}
                  {...item}
                />
              }
              name={item.name}
              control={control}
              rules={{
                required: item.validate !== '',
              }}
            />
          ) : null}
        </div>
      ))}
            { <div className="py-2">
      {errors?.firstName && <FormHelperText error>First Name is Required</FormHelperText>}
      {errors?.lastName && <FormHelperText error>Last Name is Required</FormHelperText>}
      {errors?.phone && <FormHelperText error>Phone Number is Required</FormHelperText>}
      {errors?.email && <FormHelperText error>Email is Required</FormHelperText>}
      </div>}
       <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          // disabled={activeStep === 0}
          // onClick={handleCancel}
          color="primary"
        >
          Cancel
        </Button>
        <ButtonContainer
          type="submit"
          backgroundColor={backgroundColor}
          disabled={submitFormLoading?.loading}
          // className="col-sm-5 mr-5 py-4"
        >
          {submitFormLoading?.loading ? 'Loading....' : 'Submit'}
          {/* {completedSteps() === totalSteps() - 1 ? "Submit" : "Next"} */}
        </ButtonContainer>
      </div>
      {/* {activeStep !== 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            color="primary"
          >
            Back
          </Button>
          <ButtonContainer
            type="submit"
            backgroundColor={backgroundColor}
            className="col-sm-5 mr-5 py-4"
          >
            {" "}
            {completedSteps() === totalSteps() - 1 ? "Submit" : "Next"}
          </ButtonContainer>
        </div>
      )} */}
      {/* {activeStep === 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonContainer
            type="submit"
            backgroundColor={backgroundColor}
            className="col-sm-5 mr-5 py-4"
          >
            {" "}
            {completedSteps() === totalSteps() - 1 ? "Submit" : "Next"}
          </ButtonContainer>
        </div>
      )} */}
    </form>
  );
}
const useStyles = makeStyles(() => ({
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid white",
    padding: "10px 15px",
    marginBottom: "10px",
    fontSize: "14px",
  },
}));

export const Dynamic = ({
  data,
  submitData,
  activeStep,
  handleBack,
  completed,
  completedSteps,
  handleComplete,
  totalSteps,
  mainData,
}) => {
  const [inputData, setInputData] = React.useState({});
  const themeColor = useThemeColor();
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const backgroundColor = themeColor.color;
  const handleChange = ({ target: { name, value } }) => {
    inputData[name] = value;
  };
  const handlePhoneChange = (data, name) => {
    if (data) {
      inputData[name] = data;
    }
  };
  // console.log(userProfileData, 'profile');
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(inputData, "dtata");
    submitData(inputData, activeStep);
    handleComplete();
    // setInputData({})
    e.target.reset();
  };
  Array.from(document.querySelectorAll("#textarea")).map(
    (item) => (item.value = "")
  );
  Array.from(document.querySelectorAll("#input_text")).map(
    (item) => (item.value = "")
  );
  return (
    <form
      className="form fv-plugins-bootstrap fv-plugins-framework mt-5"
      onSubmit={handleSubmit}
    >
      {data?.map((item, id) => (
        <div className="py-2 w-100" key={id}>
          {item?.formElementType === "DROPDOWN" && item.name ? (
            <TextField
              select
              name={item?.name}
              label={item?.name}
              variant="outlined"
              fullWidth
              required={item?.isRequired}
              onChange={handleChange}
            >
              {item?.options?.map((val, index) => (
                <MenuItem value={val} key={index}>
                  {val}
                </MenuItem>
              ))}
            </TextField>
          ) : item?.formElementType === "TEXT" && item.name ? (
            <TextField
              fullWidth
              variant="outlined"
              label={item.name}
              name={item.name}
              required={item?.isRequired}
              id="input_text"
              onChange={handleChange}
            />
          ) : item.formElementType === "MYFIRSTNAME" && item.name ? (
            <TextField
              label={item.name}
              name={item.name}
              fullWidth
              variant="outlined"
              required={item?.isRequired}
              onChange={handleChange}
              defaultValue={userProfileData?.userFirstName}
            />
          ) : item.formElementType === "MYLASTNAME" && item.name ? (
            <TextField
              label={item.name}
              name={item.name}
              fullWidth
              variant="outlined"
              required={item?.isRequired}
              onChange={handleChange}
              defaultValue={userProfileData?.userLastName}
            />
          ) : item.formElementType === "MYEMAIL" && item.name ? (
            <TextField
              label={item.name}
              name={item.name}
              fullWidth
              variant="outlined"
              required={item?.isRequired}
              onChange={handleChange}
              defaultValue={userProfileData?.userEmail}
            />
          ) : item.formElementType === "TEXTAREA" && item.name ? (
            <TextField
              id="textarea"
              label={item.name}
              name={item.name}
              multiline
              minRows={4}
              fullWidth
              variant="outlined"
              required={item?.isRequired}
              onChange={handleChange}
            />
          ) : item?.formElementType === "DATE" && item.name ? (
            <TextField
              name={item.name}
              label={item.name}
              variant="outlined"
              fullWidth
              type="date"
              onChange={handleChange}
              required={item?.isRequired}
              InputLabelProps={{ shrink: true }}
            />
          ) : item?.formElementType === "SINGLECHOICE" && item.name ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "3rem",
                outline: "1px solid #e2dcdc",
                padding: "7px",
                borderRadius: "4px",
              }}
            >
              <FormLabel style={{ paddingTop: "10px" }}>{item.name}</FormLabel>
              {/* <Controller as ={ */}
              <RadioGroup row onChange={handleChange} name={item.name}>
                {item.options?.map((item, index) => (
                  <FormControlLabel
                    value={item}
                    control={<Radio />}
                    label={item}
                    key={index}
                  />
                ))}
              </RadioGroup>
              {/* }
                    name={item.name}
                    control={control}
                    rules={{
                        required: item.isRequired
                    }}
                    /> */}
            </div>
          ) : item.formElementType === "PHONENUMBER" && item.name ? (
            <PhoneInputComponent
              name={item.name}
              label={item.name}
              handlePhone={(data, name) => handlePhoneChange(data, item.name)}
            />
          ) : null}
        </div>
      ))}
      {activeStep !== 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            color="primary"
          >
            Back
          </Button>
          <ButtonContainer
            type="submit"
            backgroundColor={backgroundColor}
            className="col-sm-5 mr-5 py-4"
          >
            {" "}
            {completedSteps() === totalSteps() - 1 ? "Submit" : "Next"}
          </ButtonContainer>
        </div>
      )}
      {activeStep === 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonContainer
            type="submit"
            backgroundColor={backgroundColor}
            className="col-sm-5 mr-5 py-4"
          >
            {" "}
            {completedSteps() === totalSteps() - 1 ? "Submit" : "Next"}
          </ButtonContainer>
        </div>
      )}
    </form>
  );
};
