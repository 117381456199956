import React from 'react'
import { Home } from './Home';
import { useSelector } from 'react-redux';
import {useThemeColor} from '../../utils/customHooks/useThemeColor';

export default function EpaySuccess() {
    const themeColor = useThemeColor();
    const processEpayByTokenData = useSelector((state) => state.processEpayByToken)
    React.useEffect(() => {
      window.scroll(0,0)
    }, [])
  return (
    <Home>
        <div className="login-form login-signin activeForm-wrapper px-3">
        <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={themeColor.btnStyle}>
              {/* {giveFailure ? <i className="fas fa-exclamation"></i> : */}
              <i className="fas fa-check-circle"></i>
            </span>
          </div> 
          <h4 className="font-size-h4 mb-2">{processEpayByTokenData.responseMessage}</h4>
              <a href={processEpayByTokenData?.responseData?.invoiceLink} download>
          <h5 className='font-size-h6 pt-3' role="button" tabindex="0">Click here to downlaod the reciept 
              <i className="fas fa-file-pdf px-3" ></i>
          </h5>
              </a>
        </div>
        </div>
    </Home>
  )
}
