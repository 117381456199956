import React from "react";
import DeleteLandingPage from "../../components/User/DeleteAccount/DeleteLandingPage";
import { Home } from './Home'

export const DeletePage = () => {
    return (
        <>
        <Home>
            <DeleteLandingPage />
        </Home>
        </>
    )
}