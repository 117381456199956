import React from "react";
import { Home } from "./Home";
import TwoFactorAuthentication from "../../components/Forms/2FA";

export default function TwoFactorAuth() {
  return (
    <Home>
      <TwoFactorAuthentication />
    </Home>
  );
}
