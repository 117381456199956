import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Route from './Routes'
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import { orange, lightBlue, deepPurple, deepOrange } from "@material-ui/core/colors";
import { ToastContainer } from 'react-toastify';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useMediaQuery } from "react-responsive";
import { getIpData, fetchOrgInfo, contributionProcessingEngines, setThemeChange } from './store/actions';
import PageLoader from './components/Loader/PageLoader';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import {Helmet} from "react-helmet";
import { setupAxiosInterceptors } from './utils/AxiosConfig';
import CampaignLoader from './pages/GiveCampaignV2/CampaignLoader';


function App() {
  const [themeType, setThemeType] = useState(false);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const lightThemeColor = orgInfo?.orgThemeColors?.lightTheme?.primary;
  const getThemeChangeData = useSelector(state => state.getThemeChangeData, shallowEqual)
  // contribution processing engine data from the store
  const contributionProcessingEnginesData = useSelector(state => state.contributionProcessingEnginesData, shallowEqual)
  const secondOrgInfo = useSelector(state => state.secondOrgInfo.responseData, shallowEqual)
  const dispatch = useDispatch();
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)"
    },
    undefined
  );

  const darkMode = JSON.parse(localStorage.getItem('darkMode'));
  // console.log(darkMode, 'darkMode')
  const privacyRoute = window.location.hostname === 'privacy.churchpad.com'
  // console.log(window.location.hostname, 'host name app');
  useEffect(() => {
    dispatch(getIpData());
    if (!privacyRoute) {
      dispatch(fetchOrgInfo());
    }
    if(darkMode != undefined || darkMode != null) return dispatch(setThemeChange({theme: darkMode}));
    else return dispatch(setThemeChange({theme: systemPrefersDark}));
  }, [])

  useEffect(() => {
    if(getThemeChangeData.theme){
      setThemeType(true);
    } else {
      setThemeType(false);
    }
  }, [getThemeChangeData])

  const paletteType = themeType ? 'dark' : 'light';
  const mainPrimaryColor = themeType ? orange[500] : lightBlue[500];
  const mainSecondaryColor = themeType ? deepOrange[900] : deepPurple[500];

  const theme = createMuiTheme({
    palette: {
      type: paletteType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      }
    },
    typography:{
      fontFamily: `Poppins, Helvetica, "sans-serif"`,
      // fontSize: '1.1rem'
      fontSize: '1.2rem',
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: `${getThemeChangeData.theme ? '#252625' : '#FFFFFF'}`
        }
      },
      MuiAppBar: {
        root: {
          backgroundColor: `${getThemeChangeData.theme ? '#252625' : lightThemeColor }`
        }
      }
    }
  })

  // console.log(contributionProcessingEnginesData, 'contributionProcessingEnginesData');

  const changeFavicon = () => {
    const iconsData = secondOrgInfo?.favIcons?.map((item) => {
      return {
        src: item.url,
        sizes: item.size,
        type: item.type
      }
    })
    const mainfestData = {
      short_name: secondOrgInfo?.shortName,
      name: secondOrgInfo?.fullName,
      icons: iconsData,
      start_url: secondOrgInfo?.dedicatedUrl,
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    }
    const favicon = document.getElementById("favicon");
    const mainfest = document.getElementById("mainfest_");
    const appleTouch = document.getElementById("apple_touch");
    favicon.setAttribute('href', secondOrgInfo?.favIcons[4].url)
    favicon.href = secondOrgInfo?.favIcons[4].url;
    mainfest.setAttribute('href', JSON.stringify(mainfestData))
    appleTouch.setAttribute('href', secondOrgInfo?.favIcons[4]?.url)
  }

  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
      if ((window.location.protocol !== "https:")){
        window.location.protocol = "https";
      }
    }
  }, []);

  const campaginRoutes = ["/gc", "/dgc"];

  const isCampaignPage = campaginRoutes.includes(window.location.pathname);
  setupAxiosInterceptors();
  const routesToShow = () => {
    if (isCampaignPage && !orgInfo) {
      return <CampaignLoader />;
    }
    if (!orgInfo && !privacyRoute) {
      return <PageLoader />;
    }
    return <Route />;
  };
  return (
    <>
    {secondOrgInfo && <Helmet>
          <title>{secondOrgInfo.metaData.general.title}</title>
      <meta name={secondOrgInfo.metaData.general.author} content={secondOrgInfo.metaData.general.description} />
    </Helmet>}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routesToShow()}
        {!privacyRoute && secondOrgInfo && changeFavicon()}
        <ToastContainer  toastClassName="toast-container" bodyClassName="toast-container" className="toast-container2"/>
      </ThemeProvider>
    </>
  );
}

export default App;
