import React from 'react'
import axios from 'axios';
import {toast} from 'react-toastify'
import { actionTypes } from '../../actionTypes';
import { checkNodeEnv } from '../../../utils/authHelpers';
import { defaultAction, apiUrl } from '../../../utils/constants';
import { axiosPayload, startLoadingAction, resetEndpointDataAction, stopLoadingAction } from '../index';
import NotificationMesage from '../../../components/Common/NotificationMesage';


export const setOrUnsetSpouse = (payload, callback) => {
    return async (dispatch) => {
        dispatch(startLoadingAction);
        const url = checkNodeEnv(apiUrl.setOrUnset('live-api'), apiUrl.setOrUnset('test-api'));
        try {
          const response = await axios(axiosPayload(url, payload));
          const data = await response.data
          if(data.responseCode === '00'){
            dispatch(defaultAction(actionTypes.SET_OR_UNSET_SPOUSE, data));
            dispatch(stopLoadingAction)
            callback();
            toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
          } else{
            dispatch(stopLoadingAction)
            toast.error(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
            return dispatch(defaultAction())
          }
        } catch(error) {
          toast.warn('Network Error. Please check your connectivity and try again')
          // console.log(error, 'card or Checking Profile Error')
        }
    }
}

export const searchForSpouse = (payload) => {
    return async (dispatch) => {
        dispatch(startLoadingAction);
        const url = checkNodeEnv(apiUrl.searchForSpouse('live-api'), apiUrl.searchForSpouse('test-api'));
        try {
          const response = await axios(axiosPayload(url, payload));
          const data = await response.data
          if(data.responseCode === '00'){
            dispatch(defaultAction(actionTypes.SEARCH_FOR_SPOUSE, data));
            dispatch(stopLoadingAction)
          } else{
            dispatch(stopLoadingAction)
            return dispatch(defaultAction())
          }
        } catch(error) {
          toast.warn('Network Error. Please check your connectivity and try again')
          // console.log(error, 'card or Checking Profile Error')
        }
    }
}

export const getUserFamilyDataMportal = (payload) => {
    return async (dispatch) => {
        dispatch(startLoadingAction);
        const url = checkNodeEnv(apiUrl.getUserFamilyDataMportal('live-api'), apiUrl.getUserFamilyDataMportal('test-api'));
        try {
          const response = await axios(axiosPayload(url, payload));
          const data = await response.data
          if(data.responseCode === '00'){
            dispatch(defaultAction(actionTypes.GET_USER_FAMILY_SUCCESS, data));
            dispatch(stopLoadingAction)
          } else{
            dispatch(stopLoadingAction)
            return  dispatch(defaultAction(actionTypes.GET_USER_FAMILY_FAILURE, data));
          }
        } catch(error) {
          toast.warn('Network Error. Please check your connectivity and try again')
          // console.log(error, 'card or Checking Profile Error')
        }
    }
}