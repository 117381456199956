import React from 'react'
import { useSelector } from 'react-redux'
import { Dialog } from '@material-ui/core'
import { Button } from 'react-bootstrap'

const ConfirmationDialog = ({
    open,
    onConfirmDialogClose,
    text,
    title = 'Confirm',
    onYesClick,
    isLoading,
    needOne,
    buttonTitle
}) => {
    const orgInfo = useSelector(state => state.orgInfo.responseData);
    const primaryBgColor = {backgroundColor: orgInfo ? orgInfo?.colorThemes[0]?.primary : '', border: 'none'}
    return (
        <Dialog maxWidth="xs" open={open} onClose={onConfirmDialogClose}>
            <div className="p-8 text-center w-360 mx-auto">
                <h4 className="capitalize m-0 mb-5">{title}</h4>
                <p>{text}</p>
                <div className="flex justify-center pt-2 m-2">
                    {isLoading ? (
                    <Button
                    disabled={true}
                    >
                        <span>Please wait... <span className="ml-3 pr-5 spinner spinner-white"></span></span>
                        {/* {} */}
                    </Button>) : 
                    needOne ?  <Button
                        className="btn btn-lg py-2 px-10 mx-5"
                        // className="m-2 rounded hover-bg-primary px-6"
                        variant="primary"
                        // variant="outlined"
                        color="primary"
                        onClick={onYesClick}
                        style={primaryBgColor}
                    >
                        {buttonTitle}
                    </Button> : 
                    <>
                    <Button
                        className="btn btn-lg py-2 px-10 mx-5"
                        // className="m-2 rounded hover-bg-primary px-6"
                        variant="primary"
                        // variant="outlined"
                        color="primary"
                        onClick={onYesClick}
                        style={primaryBgColor}
                    >
                        Yes
                    </Button>
                    <Button
                        className="btn btn-lg py-2 px-10 mx-5"
                        // className="m-2 rounded hover-bg-secondary px-6"
                        // variant="outlined"
                        variant="secondary"
                        color="secondary"
                        onClick={onConfirmDialogClose}
                    >
                        No
                    </Button>
                    </>
                    // }
                    }
                </div>
            </div>
        </Dialog>
    )
}

export default ConfirmationDialog