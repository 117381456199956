import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Link from '@material-ui/core/Link'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { TextField, Switch, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {toast} from 'react-toastify';
import { login, resetEndpointDataAction } from '../../store/actions';
import NotificationMesage from '../Common/NotificationMesage';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import 'react-phone-number-input/style.css';
import './form.css';
import './phone.css';
import Alert from '@material-ui/lab/Alert';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';


const initialValues = {
  email: '',
  password: '',
};

export const PurpleSwitch = withStyles({
  switchBase: {
    // color: props => props.primaryColor,
    '&$checked': {
      color: props => props.primaryColor,
    },
    '&$checked + $track': {
      backgroundColor: props => props.primaryColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const Login = () => {
  const MAX_LENGTH = 4;
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const themeColor = useThemeColor();


  // Local State
  const [mobile, setMobile] = useState('');
  const [pin, setPin] = useState('');
  const [toggleMobile, setToggleMobile] = useState(false);
  const [toggleShowPassword, setToggleShowPassword] = useState(false);
  const [toggleShowPin, setToggleShowPin] = useState(false);
  const [pinError, setPinError] = useState({});
  const [mobileError, setMobileError] = useState('');
  const [token, setToken] = useState('');
  const [dynamicAction, setDynamicAction] = useState('submit');
  const [value, setValue] = React.useState('email');

  // Global store data
  const ipData = useSelector(state => state.ipData);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const loginData = useSelector(state => state.loginData, shallowEqual)
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.orgColors.primary : '' }
  // console.log(orgInfo, 'orgInfo')
  let { from } = location.state || { from: { pathname:  "/dashboard" } };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format').required('Required field'),
    password: Yup.string()
      .required('Required field')
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };


  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      // console.log('recaptcha verifiction not successful');
      return;
    }

    const result = await executeRecaptcha('dynamicAction');
    // console.log(result, 'result');
    setToken(result);
    // setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
  }, [dynamicAction, executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      setToken(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, dynamicAction]);

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const { email, password } = values;
      if (value === 'phoneNumber') {
        return handleMobileSubmit(password)
      }
      if (email && password) dispatch(login({ emailOrPhone: email, password, orgId: orgInfo.orgId }, redirectToDashboard));
    }
  });

  const redirectToDashboard = () => {
    const getMaterApp = localStorage.getItem('master_app')
    if (getMaterApp) {
      return history.replace('/user/account/delete')
    }
    history.replace(from)
  }

  const redirectTo2FA = () => {
    history.push('/2fa_verification')
  }

  const handleMobileSubmit = (password) => {
    // e.preventDefault()
    if (token) {
      if (mobile) {
        if (!isValidPhoneNumber(mobile)) setMobileError('Invalid phone number')
      } else setMobileError('Mobile is required')
      // if (!pin) {
      //   return setPinError({ ...pinError, msg: 'Pin is required', status: true })
      // }
      if (mobile && !mobileError.status) {
        // console.log(pin, mobile, '...payload')
        return dispatch(login({ emailOrPhone: mobile, password, orgId: orgInfo.orgId }));
      }
    }
  }

  const toggleShowPasswordFunc = () => {
    setToggleShowPassword(!toggleShowPassword);
  };

  const toggleShowPinFunc = () => {
    setToggleShowPin(!toggleShowPin);
  };

  const toggleMobilefunc = ({target: {checked}}) => {
    // console.log(checked, 'checkoed');
    // console.log(target, 'target');
    setToggleMobile(checked);
    formik.resetForm({ email: '', password: '' });
    setPinError({});
    setPin('')
  };

  const onPinChange = (e) => {
    const pinInput = e.target.value.replace(/\D/g, "").slice(0, 4);
    const letters = /^[A-Za-z]+$/;
    if (e.target.value.match(letters)) return setPinError({ ...pinError, msg: 'Pin must be a number', status: true })
    // if (pinInput.length < MAX_LENGTH) setPinError({ ...pinError, msg: 'Pin must not be less than 4 numbers', status: true })
    // if (pinInput.length >= MAX_LENGTH) setPinError({ ...pinError, msg: '', status: false });
    setPin(pinInput);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  React.useEffect(() => {
    if (loginData.responseCode === '01') {
      // toast.error(<NotificationMesage message={loginData.responseMessage} responseStatus={loginData.responseCode} />, {
      //   // onClose: () => dispatch(resetEndpointDataAction)
      // })
    }
    if (loginData.required2FAAuth === true && loginData.responseCode === '00') {
      redirectTo2FA()
    }
    if (loginData.responseCode === '00' && loginData.required2FAAuth === false) {
      redirectToDashboard()
    }
  }, [loginData.responseCode, loginData])

  const handlePhoneInput = (data) => {
    console.log(data, 'data');
    formik.setFieldValue('mobile', data)
    setMobile(data)
  }

  return (
    <div  className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="las la-user"></i>
          </span>
        </div>
        <h3 className="font-size-h1 mb-5">Sign In</h3>
        <p className="text-muted font-weight-bold">{value === 'email' ? 'Enter your Email and password' : 'Enter your Phone Number and password'}</p>
      </div>
      <hr />
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} row>
        <FormControlLabel value="email" control={<Radio />} label="Email" />
        <FormControlLabel value="phoneNumber" control={<Radio />} label="Phone Number" />
      </RadioGroup>

      {/* end::Head */}
      {sessionStorage.getItem('session-out') && <Alert severity="error">Your session has expired. Please log in again!</Alert>}
      {/*begin::Form*/}

      {/* Display error message */}
      {loginData.responseCode === '01' && <Alert severity="error">{loginData.responseMessage}</Alert>}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework mt-5">
        {/* {loginData && loginData.responseCode === '01' && <Alert isSuccess={false} msg={loginData.responseMessage} />} */}
        <div className="fv-plugins-icon-container mb-10">
          {value === 'phoneNumber' ? (
            <>
              <p >Phone Number</p>
              <PhoneInput
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                defaultCountry={ipData ? ipData.country_code : null}
                placeholder="Mobile"
                id="loginMobile"
                value={mobile}
                onChange={handlePhoneInput}
                className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${(mobile && !isValidPhoneNumber(mobile)) ? 'is-invalid' : (mobile && isValidPhoneNumber(mobile)) ? 'is-valid' : ''}`}
                name="mobile"
                error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid mobile number') : 'Mobile number is required'}
              />
            </>
          ) : (
              <div className="position-relative">
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  id="loginEmail"
                  autoFocus
                  error={!toggleMobile && formik.touched.email && formik.errors.email && true}
                  variant="outlined"
                  {...formik.getFieldProps('email')}
                  className={`${getInputClasses(
                    'email'
                  )}`}
                  fullWidth
                />
                {/* <i className="fas fa-user email-icon position-absolute"></i> */}
              </div>
            )}
          {!toggleMobile && formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block errorText">{formik.errors.email}</div>
            </div>
          ) : null}
          {(toggleMobile && mobile && !isValidPhoneNumber(mobile)) || mobileError ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{(mobile ? (isValidPhoneNumber(mobile) ? setMobileError('') : 'Invalid mobile number') : 'Mobile number is required') || mobileError}</div>
            </div>
          ) : null}
        </div>
        <div className="fv-plugins-icon-container">
          {toggleMobile ? (
            <div className="position-relative password">
              <div className="position-relative">
                <TextField
                  name="pin"
                  label="Pin"
                  type={toggleShowPin ? 'tel' : 'password'}
                  id="loginPin"
                  value={pin}
                  maxLength={MAX_LENGTH}
                  className={` ${pinError.status ? 'is-invalid' : pinError.status === false ? 'is-valid' : ''}`}
                  inputMode="numeric"
                  onChange={onPinChange}
                  fullWidth
                  variant="outlined"
                  // onInput = {(e) =>{
                  //   e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                // }}
                />
                {/* <i className="las la-key email-icon key-icon position-absolute"></i> */}

              </div>
              <span className="position-absolute p-2" onClick={toggleShowPinFunc}>
                {toggleShowPin ? (
                  <i className="far fa-eye"></i>
                ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
              </span>
            </div>
          ) : (
              <div className="position-relative password">
                <TextField
                  label="Password"
                  type={toggleShowPassword ? 'text' : 'password'}
                  className={` ${getInputClasses(
                    'password'
                  )}`}
                  id="loginPassword"
                  name="password"
                  fullWidth
                  error={!toggleMobile && formik.touched.password && formik.errors.password && true}
                  variant="outlined"
                  {...formik.getFieldProps('password')}
                />
                <span className="position-absolute p-2" onClick={toggleShowPasswordFunc}>
                  {toggleShowPassword ? (
                    <i className="far fa-eye"></i>
                  ) : (
                      <i className="far fa-eye-slash"></i>
                    )}
                </span>
                {/* <i className="fas fa-lock email-icon position-absolute"></i> */}
              </div>
            )}
          {!toggleMobile && formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block errorText">{formik.errors.password}</div>
            </div>
          ) : null}
          {toggleMobile && pinError.status ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{pinError.msg}</div>
            </div>
          ) : null}
        </div>

        <div
          className="d-flex mt-5 flex-wrap justify-content-between align-items-center">
          {/* <div>
            <FormControlLabel
              className="font-size-sm"
              control={<PurpleSwitch name='toggle' checked={toggleMobile} onChange={toggleMobilefunc} size="small" primaryColor={themeColor.color} />}
              // control={   <Switch
              //   checked={toggleMobile}
              //   onChange={toggleMobilefunc}
              //   color="primary"
              //   name="checkedB"
              //   inputProps={{ 'aria-label': 'primary checkbox' }}
              // />}
              label={`Or sign in with ${toggleMobile ? 'email' : 'mobile'}`}
            />
          </div> */}
          <div>
            {toggleMobile ? (
              <div className="forgetPasswordContainter">
                <i style={{color: themeColor.color}} className="las la-key email-icon key-icon mr-2"></i>
                <Link
                  component={RouterLink}
                  // style={{color: themeColor.color}}
                  to="/forgot_pin"
                  color="inherit"
                  className="my-3 mr-2 font-size-sm forgotPassword"
                  id="kt_login_forgot">
                  Forgot Pin
                </Link>
              </div>
            ) : (
              <div className="forgetPasswordContainter">
                  <i color="inherit" className="fas fa-lock email-icon mr-2"></i>
                  <Link
                    // style={{color: themeColor.color}}
                    underline="hover"
                    component={RouterLink}
                    to={`/forgot_password?type=email`}
                    color="inherit"
                    className="my-3 mr-2 font-size-sm forgotPassword"
                    id="kt_login_forgot">
                    Forgot Password
                  </Link>
                </div>
              )}
          </div>
        </div>
        {toggleMobile ? <button
          id="kt_login_signin_submit"
          type="button"
          disabled={loginData && loginData.loading}
          style={themeColor.btnStyle}
          onClick={handleMobileSubmit}
          className={`btn w-100 mt-5 font-weight-bold text-white py-4 px-9 mb-5`}>
          <span>{loginData.loading ? 'Please wait...' : 'Sign In'}</span>
          {loginData && loginData.loading && <span className="ml-3 spinner spinner-white"></span>}        </button> :
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={loginData && loginData.loading}
            style={themeColor.btnStyle}
            className={`btn w-100 mt-5 font-weight-bold text-white py-4 px-9 mb-5`}>
            <span>{loginData.loading ? 'Please wait...' : 'Sign In'}</span>
            {loginData && loginData.loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>}
        <div className="w-100 ">
          <p className="my-3">
            Don't have an account? 
            <Link 
              to="/signup"
              component={RouterLink} 
              style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}}
            >
              <span className="ml-2" style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}}>Sign Up</span>
            </Link>
          </p>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default Login;