import React from 'react';
import { RsvpCard } from './RsvpCard';
import './rsvp.scss';

export const RsvpGroup = ({ subGroup, firstIndex, getMainEventObj }) => {

  const getMainEvt = (item, index, isCardSelected) => {
    getMainEventObj(isCardSelected, firstIndex, index, item)
  }

  return subGroup
    .sort((a, b) => b.isUserRsvped - a.isUserRsvped)
    .map((item, index) => {
      return <RsvpCard 
        key={index} 
        index={index}
        getEventObj={getMainEvt}
        item={item} 
    />})
}