import React from "react";
import { Paper } from "@material-ui/core";
import { useSelector } from "react-redux";
import { SubSection } from "../SubSection";
import { GiveSubSection } from "../GiveSubSection";
import { DonateAmount } from "../Forms/GiveForm/DonateAmount";
import "./MainSection.css";
import { useContributionProcessEngine } from "../../utils/customHooks/useContributionProcessEngine";

const MainSection = (props) => {
  const urlParam = window.location.pathname;
  const { hideAmountInput } = useSelector((state) => state.donatedAmountData);
  const { isAdvanced } = useSelector((state) => state.getPaymentMode);
  const showPaymentOptionPage = useContributionProcessEngine();

  return (
    <Paper elevation={0}>
      <div className="position-relative">
        {urlParam === "/give" && !hideAmountInput && isAdvanced ? (
          <GiveSubSection>
            <DonateAmount
              defaultWrap={
                !showPaymentOptionPage.hasMultiple ? "pt-0" : undefined
              }
            />
          </GiveSubSection>
        ) : (
          <SubSection />
        )}

        <Paper
          elevation={3}
          className={`${
            urlParam !== "/give" ? "mainsection-give" : ""
          } mainsection-container p-10 mx-auto main-section`}
        >
          {props.children}
        </Paper>
      </div>
    </Paper>
  );
};

export default MainSection;
