/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu } from '@material-ui/core'
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import {logout} from '../utils/authHelpers';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { DarkToggle } from '../components/Common/DarkToggle';
import {useThemeColor} from '../utils/customHooks/useThemeColor.jsx';
import { setThemeChange} from '../store/actions';

export function UserProfileDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const getThemeChangeData = useSelector(state => state.getThemeChangeData, shallowEqual)
  const userDetails = useSelector(
    (state) => state.getUserProfileData?.responseData,
    shallowEqual
  );

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.log(userDetails, 'userDetails')
  const userData = JSON.parse(localStorage.getItem('user'))
  const dispatch = useDispatch();
  const theme = useThemeColor();

  const handleThemeChange = () => {
    dispatch(setThemeChange({theme: !getThemeChangeData.theme}));
  }
  
  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(getThemeChangeData.theme) )
  }, [getThemeChangeData])

  return (
    <div className="d-flex flex-row align-items-center">
      {/* <Switch checked={getThemeChangeData.theme} onChange={handleThemeChange} /> */}
      <DarkToggle
        checked={getThemeChangeData.theme}
        onChange={handleThemeChange}
      />
          <div
            className={
              "w-auto d-flex align-items-center px-2"
            }
            onClick={handleClick}
          >
            
            {/* <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi
            </span>{" "} */}
            {userDetails?.userFirstName && <span className="text-dark-50 font-size-base d-none d-md-inline mr-3">
             Hi {userDetails?.userFirstName}
            </span>}
            <Avatar alt="Remy Sharp" className="cursor" src={userDetails?.userProfilePicSmall} />
          </div>
        
        <Menu 
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="navi navi-spacer-x-0 pt-5">
            <Link to="/dashboard" onClick={handleClose} className="navi-item px-8">
              <div className="navi-link">
                <ListItemIcon className="list-icon">
                  <span className="svg-icon menu-icon">
                    <i className='fas fa-user'></i>
                  </span>
                </ListItemIcon>
                <div className="navi-text">
                  <div className={`font-weight-bold ${theme.isDarkMode ? 'text-white' : ''}`}>Dashboard</div>
                  {/* <div className="text-muted">view personal information</div> */}
                </div>
              </div>
            </Link>

            <Link to="/family" onClick={handleClose} className="navi-item px-8">
              {/* <MenuItem>
                <i className='fa fa-users' ></i> <p>Family Information</p>
              </MenuItem> */}
              <div className="navi-link">
                <ListItemIcon className="list-icon">
                  <span className="svg-icon menu-icon">
                    <i className='fa fa-users'></i>
                  </span>
                </ListItemIcon>
                <div className="navi-text">
                  <div className={`font-weight-bold ${theme.isDarkMode ? 'text-white' : ''}`}>Family Information</div>
                </div>
              </div>
            </Link>

            <Link to="/update" onClick={handleClose} className="navi-item px-8">
              <div className="navi-link">
                <ListItemIcon className="list-icon">
                  <span className="svg-icon menu-icon">
                    <i className='fas fa-cog'></i>
                  </span>
                </ListItemIcon>
                <div className="navi-text">
                  <div className={`font-weight-bold ${theme.isDarkMode ? 'text-white' : ''}`}>Settings</div>
                  {/* <div className="text-muted">update Authentication data</div> */}
                </div>
              </div>
            </Link>

            {userDetails?.allowAdminBackendAccess === 'true' && <a 
              href={`${orgInfo?.dedicatedUrlAdmin}cpauth_admin?t=${userDetails?.userToken}`} 
              target="_blank" 
              rel="noreferrer" 
              // href={`${orgInfo?.dedicatedUrl}cpauth?t=${userDetails?.userToken}`}
              // onClick={handleClose} 
              className="navi-item px-8"
            >
              <div className="navi-link">
                <ListItemIcon className="list-icon">
                  <span className="svg-icon menu-icon">
                    <i className='fa fa-lock'></i>
                  </span>
                </ListItemIcon>
                <div className="navi-text">
                  <div className={`font-weight-bold ${theme.isDarkMode ? 'text-white' : ''}`}>Admin Portal</div>
                  {/* <div className="text-muted">update Authentication data</div> */}
                </div>
              </div>
            </a>}

            <Link to="/twoFA"  onClick={handleClose} className="navi-item px-8">
              <div className="navi-link">
                <ListItemIcon className="list-icon">
                  <span className="svg-icon menu-icon">
                    <i className='fas fa-key'></i>
                  </span>
                </ListItemIcon>
                <div className="navi-text">
                  <div className={`font-weight-bold ${theme.isDarkMode ? 'text-white' : ''}`}>Two-Factor Authentication</div>
                  {/* <div className="text-muted">update Authentication data</div> */}
                </div>
              </div>
            </Link>
            <div className="navi-separator mt-3"></div>

            <div className="navi-footer  px-8 py-5">
              <Link
                onClick={logout}
                className="btn font-weight-bold text-white"
                style={{backgroundColor: theme.color}}
              >
                <i className={`menu-icon text-white fas fa-sign-out-alt mr-3`}></i>
                Logout
              </Link>
            </div>
          </div>
        {/* </Dropdown.Menu> */}
        </Menu>
      {/* </Dropdown> */}
    </div>
  );
}
