import React from 'react';
import './Footer.css';

export const FooterIcon = ({ socialMediaLink, socialMedia, socialMediaStyle }) => {
  return (
    <a href={socialMediaLink}>
      <span className={socialMedia}>
        <i className={socialMediaStyle}></i>
      </span>
    </a>
  )
};