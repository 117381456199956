import React, { useEffect } from "react";
import { MenuItem, Tooltip, Icon, FormControlLabel, Checkbox } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import {
//   createActivityLog,
//   updateActivityLog,
//   getActivityLog,
//   followUpLogActivity,
// //   followUpListings
// } from "redux/actions/FollowUp";
import FormsModal from "../../Common/FormsModal";
import { getUserDetails } from "../../../utils/authHelpers";
import { followUpListings, followUpLogActivity } from "../../../store/actions/userDashboardActions/followup";
import UploadBtn from "../../Common/UploadBtn";
import { iconTypes } from "../../../utils/constants";
// import { iconTypes } from "utils/constants";


const CreateFollowActivityLogForm = ({
    onClose,
    selectedValue,
    open,
    create = true,
  }) => {
    // console.log(selectedValue, "selectedValue");
    // const initialValue = {
    //   activityType: selectedValue?.activityType,
    //   comment: selectedValue?.comments
    // }
    // const activityTypes = ["Phone Call", "Email", "House Visit", "Meet Up"];
    const [inputPayload, setInputPayload] = React.useState({});
    const [htmlInput, setHtml] = React.useState("");
    const [icons, setIcons] = React.useState({});
    const [isGuestWantsToJoin, setIsGuestWantsToJoin] = React.useState(false)
    const [guestWantsToJoin, setGuestWantsToJoin] = React.useState(false)
    const [activityFiles, setActivityFiles] = React.useState([]);
  
    const handleChange = ({ target: { value, name } }) => {
      let temp = { ...inputPayload };
      temp[name] = value;
      setInputPayload(temp);
    };
  
    useEffect(() => {
      if (inputPayload?.activityType === 'close') {
          setIsGuestWantsToJoin(true)
        } else {
          setIsGuestWantsToJoin(false)
        }
    }, [inputPayload])
    
  
    const orgInfo = useSelector(
      (state) => state.orgInfoList.responseData,
      shallowEqual
    );
    const orgInfoData = useSelector(
      (state) => state.orgInfo.responseData,
      shallowEqual
    );
    const iconsRepo = useSelector(
      (state) => state.orgInfo?.responseData?.iconsRepo
    );
  
    const user = getUserDetails()
  
    const dispatch = useDispatch();
    // const user = JSON.parse(localStorage.getItem('user'));
  
    useEffect(() => {
      if (selectedValue?.activityType) {
        return setInputPayload({
          activityType: selectedValue.activityType,
          comment: selectedValue.comments,
          ...inputPayload,
        });
      }
    }, [selectedValue]);
    useEffect(() => {
      const icons = iconsRepo?.reduce((obj, item) => {
        obj[item["name"]] = item;
        return obj;
      }, {});
      setIcons(icons);
    }, [iconsRepo]);
    //   useEffect(() => {
    //     if(selectedValue.comments){
    //       return setInputPayload({ comment: selectedValue.comments, ...inputPayload })
    //     }
    //   }, [selectedValue?.comments])
    const handleCallback = () => {
      dispatch(followUpListings({
        orgId: orgInfoData.orgId,
        adminUserId: user?.userId,
        type:  "assignedToMe",
  
      }))
      onClose();
      setInputPayload({});
    };
    const handleFormSubmit = () => {
      // console.log(inputPayload, "input");
      const formData = new FormData()
      formData.append('orgId', orgInfoData.orgId)
      formData.append('guestWantsToJoin', guestWantsToJoin.toString())
      formData.append('adminUserId', user?.userId)
      formData.append('recordId', selectedValue.recordId)
      formData.append('type', inputPayload.activityType)
      if (activityFiles.length > 0 ) {
        const activities = activityFiles?.map((item, index) => formData.append(`media${index+1}`, item))
      }
    //   activityFiles.length > 0 && activityFiles?.map((item, index) => (
    //     formData.append(`media${index+1}`, item)
    //   ))
      formData.append('message', inputPayload.message)
      dispatch(followUpLogActivity(formData, handleCallback))
    };
    // console.log(inputPayload, "payload");
    const handleCheck = ({target: {checked}}) => {
      setGuestWantsToJoin(checked)
    }
    const handleMediaUpload = (e) => {
      if (activityFiles.length < 6) {
        for (let i = 0; i < e.target.files.length; i++) {
          setActivityFiles([...activityFiles, e.target.files[i]]);
        }
      } else {
        return;
      }
    };
  
    const handleClose = () => {
      onClose()
      setInputPayload({})
      setHtml('')
      setActivityFiles([])
    }
    return ( 
      <FormsModal
        handleFormSubmit={handleFormSubmit}
        title={`${!create ? "Edit" : "Create"} Activity Log Form`}
        open={open}
        // loading={addMember.loading}
        submitText="Submit"
        onClose={handleClose}
        // disabled={!data.name && !data.codes}
      >
        {/* <p className="mb-7">Name: <strong>{modalData.title} {modalData.firstName} {modalData.lastName}</strong></p> */}
        <TextValidator
          className="mb-5 w-100"
          variant="outlined"
          size="small"
          label="Activity Type"
          onChange={handleChange}
          type="text"
          name="activityType"
          required
          select
          value={inputPayload.activityType}
          validators={["required"]}
          errorMessages={["this field is required"]}
        >
          {orgInfo.followUpActivityTypesList.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.title}
            </MenuItem>
          ))}
        </TextValidator>
        <TextValidator
          className="mb-5 w-100"
          variant="outlined"
          size="small"
          label="Message"
          onChange={handleChange}
          type="text"
          name="message"
          required
          multiline
          minRows={4}
          value={inputPayload.message}
          validators={["required"]}
          errorMessages={["this field is required"]}
        />
        {/* <EditorComponent
          data={inputPayload?.message}
          getTypedInput={getTypedInput}
        /> */}
        {isGuestWantsToJoin && <FormControlLabel control={<Checkbox checked={guestWantsToJoin} onChange={handleCheck} name='guestWantsToJoin' />} label="Member joins our church" />}
  
        <div
          className="d-flex justify-content-start mb-5"
          style={{ columnGap: "2rem", flexFlow: "row wrap" }}
        >
          {activityFiles.map((item, i) => {
            const attachment = item.name.split(".").pop();
            const iconType = iconTypes[attachment];
            const icon = icons[iconType];
            return (
              <span className="position-relative mb-3">
                <Tooltip title={`delete Image`} placement="top">
                  <Icon
                    fontSize="small"
                    color="primary"
                    className="mr-1 position-absolute cursor-pointer"
                    style={{ right: "0", position: "absolute", top: "-10px" }}
                    onClick={() => {
                      let temp = [...activityFiles];
                      temp.splice(i, 1);
                      setActivityFiles(temp);
                    }}
                  >
                    delete
                  </Icon>
                </Tooltip>
                <Tooltip title={`${item.name}`} placement="top">
                  <img
                    style={{ width: "5rem", height: "7rem" }}
                    src={icon.url}
                    alt=""
                  />
                </Tooltip>
              </span>
            );
          })}
        </div>
        <div className="mt-3">
          <UploadBtn
            btnLabel="Upload Files"
            onChange={handleMediaUpload}
            disabled={activityFiles.length === 6}
            // loading={loading}
            multiple={true}
            accept=".png, .jpg, .jpeg, .xlsx,.xls, .doc,.txt, .docx,.ppt, .pptx,.pdf"
          />
        </div>
      </FormsModal>
    );
  };
  export default CreateFollowActivityLogForm;
  