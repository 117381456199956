import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { showMobileSideBar } from "../../store/actions";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";

import "./Navbar.scss";

export const MobileMenu = ({ showAside }) => {
  const dispatch = useDispatch();
  const themeColor = useThemeColor();
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const { give, checkIn, rsvp, newHere, liveStream, celebrationWall }  = orgInfo?.landingPageModulesAdvanced;
  // const { give, checkIn, rsvp, rsvpLabel } = orgInfo?.landingPageModules;
  // const primaryColor = orgInfo.orgColors.primary;
  // const container = window !== undefined ? () => window().document.body : undefined;
  const handleDrawerToggle = () => {
    dispatch(showMobileSideBar({ isMobileShow: !showAside }));
  };
  return (
    <Drawer
      // className="mobile-menu-toggle"
      anchor="left"
      // container={container}
      // variant="temporary"
      // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={showAside}
      // close
      onClose={handleDrawerToggle}
      // classes={{
      //   paper: classes.drawerPaper,
      // }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Nav className={`ml-auto flex-column`}>
        {give?.status === 'true' && (
          <Nav.Item>
            <NavLink
              to="/give"
              className="mr-5 p-5"
              style={{
                display: "block",
                padding: "10px",
                color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
                width: "100%",
                borderBottom: "1px solid #ddd",
              }}
              activeStyle={{
                color: "#fff",
                backgroundColor: themeColor.color,
                padding: "10px",
                width: "100%",
              }}
              exact
            >
              <span className="las la-hand-holding-heart mr-2"></span>
              {give?.label}
            </NavLink>
          </Nav.Item>
        )}
        {checkIn?.status === 'true' && (
          <Nav.Item>
            <NavLink
              to="/checkin"
              className="mr-5 p-5"
              style={{
                display: "block",
                padding: "10px",
                color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
                width: "100%",
                borderBottom: "1px solid #ddd",
              }}
              activeStyle={{
                color: "#fff",
                backgroundColor: themeColor.color,
                padding: "10px",
                width: "100%",
              }}
              exact
            >
              <span className="las la-user-check mr-2"></span>
              {checkIn?.label}
            </NavLink>
          </Nav.Item>
        )}
        {rsvp.status === 'true' && (
          <Nav.Item>
            <NavLink
              to="/rsvp"
              className="mr-5 p-5"
              style={{
                display: "block",
                padding: "10px",
                color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
                width: "100%",
                borderBottom: "1px solid #ddd",
              }}
              activeStyle={{
                color: "#fff",
                backgroundColor: themeColor.color,
                padding: "10px",
                width: "100%",
              }}
              exact
            >
              <span className="las la-bookmark mr-2"></span>
              {rsvp?.label}
            </NavLink>
          </Nav.Item>
        )}
        {newHere?.status === "true" && (
          <Nav.Item>
            <NavLink
              to="/new"
              className="mr-5 p-5"
              style={{
                color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
                width: "100%",
                borderBottom: "1px solid #ddd",
                display: "block",
                padding: "10px",
              }}
              activeStyle={{
                color: "#fff",
                backgroundColor: themeColor.color,
                padding: "10px",
                width: "100%",
              }}
              exact
            >
              <span className="far fa-address-book mr-2"></span>
              {newHere?.label}
            </NavLink>
          </Nav.Item>
        )}
        {liveStream?.status === "true" && (
          <Nav.Item>
            <NavLink
              to="/live"
              className="mr-5 p-5"
              style={{
                color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
                width: "100%",
                borderBottom: "1px solid #ddd",
                display: "block",
                padding: "10px",
              }}
              activeStyle={{
                color: "#fff",
                backgroundColor: themeColor.color,
                padding: "10px",
                width: "100%",
              }}
              exact
            >
              <span className="fab fa-steam mr-2"></span>
              {liveStream?.label}
            </NavLink>
          </Nav.Item>
        )}
        {celebrationWall?.status === 'true' && <Nav.Item>
          <NavLink 
              to="/celebrate"
              className="mr-5 p-5"
              style={{
                color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
                width: "100%",
                borderBottom: "1px solid #ddd",
                display: "block",
                padding: "10px",
              }}
              activeStyle={{
                color: "#fff",
                backgroundColor: themeColor.color,
                padding: "10px",
                width: "100%",
              }}
              exact
            >
            <span className="fas fa-glass-cheers mr-2"></span>
        {celebrationWall?.label}</NavLink>
        </Nav.Item>}
        <Nav.Item>
          <NavLink
            to="/"
            className="mr-5 p-5"
            style={{
              display: "block",
              padding: "10px",
              borderBottom: "1px solid #ddd",
              color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
              width: "100%",
            }}
            activeStyle={{
              color: "#fff",
              backgroundColor: themeColor.color,
              padding: "10px",
              width: "100%",
            }}
            exact
          >
            <span className="las la-user mr-2"></span>
            Sign In
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/signup"
            className="mr-5 p-5"
            style={{
              display: "block",
              padding: "10px",
              color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
              width: "100%",
              borderBottom: "1px solid #ddd",
            }}
            activeStyle={{
              color: "#fff",
              backgroundColor: themeColor.color,
              padding: "10px",
              width: "100%",
            }}
            exact
          >
            <span className="las la-clipboard-check mr-2"></span>
            Sign Up
          </NavLink>
        </Nav.Item>
        {/* <Nav.Item>
          <NavLink
            to="/sermon"
            className="mr-5 p-5"
            style={{
              display: 'block',
              padding: '10px',
              color: themeColor.color,
              width: '100%',
              borderBottom: '1px solid #ddd',
            }}
            activeStyle={{
              color: '#fff',
              backgroundColor: themeColor.color,
              padding: '10px',
              width: '100%',
            }}
            exact
          >
            <span className="las la-film mr-2"></span>
            Sermon
          </NavLink>
        </Nav.Item> */}
      </Nav>
    </Drawer>
  );
};
