import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Home } from "./Home";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { loginByToken } from '../../store/actions';
import PageLoader from '../../components/Loader/PageLoader';

export default function LoginByToken() {
    const {search} = useLocation()
    const history = useHistory()
    const dispatch = useDispatch();
    const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
    const loginData = useSelector(state => state.loginData, shallowEqual)
    const query = new URLSearchParams(search).get('t')
    const redirect = () => {
        history.push('/dashboard')
    }
    React.useEffect(() => {
      if (query) {
        dispatch(loginByToken({
            orgId: orgInfo.orgId,
            token: query
        }, redirect))
      }
    }, [query])

    if (loginData?.loading) {
        return <PageLoader />
    }
    
  return (
    <Home>
        Loading...
    </Home>
  )
}
