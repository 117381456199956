import React, {useEffect} from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getUserProfileByWebAccessToken } from '../../../store/actions';
import PageLoader from '../../Loader/PageLoader';

export default function Donate() {
    const userProfileDataLoading = useSelector(
        (state) => state.getUserProfileData,
        shallowEqual
      );

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const orgId = params.get('orgId');
    const userWebAccessToken = params.get('userWebAccessToken');
    const userId = params.get('userId');
    let dispatch = useDispatch()
    useEffect(() => {
        if (orgId && userWebAccessToken && userId) {
            dispatch(getUserProfileByWebAccessToken({
                orgId,
                userId,
                userWebAccessToken
            })) 
        }
    }, [orgId, userWebAccessToken, userId])
    if (userProfileDataLoading?.loading) {
        return <PageLoader />
    }
    return (
        <div>
            
        </div>
    )
}
