import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import { checkInInformation, processCheckin, deleteGuest, processCheckout, getUserProfile } from "../../../store/actions/index";
import {useWindowDimensions} from '../../../utils/customHooks/useWindowDimensions';
import moment from "moment";


export const CheckinCard = ({ 
  index,
  item,
  selectedItem,
  selectedSpouse,
  selectedGuest,
  newGuestToCheckin,
  toggleCheckin,
  getEventData,
  resetGuestCheckinData,
  handleSelectedCard,
  children,
  setSelectionError,
  handleReadyToCheckin,
  setNewGuestToCheckin,
  setCheckinCancel }) => {
  // console.log(item, 'item')
  // console.log(index, 'index')
  const dispatch = useDispatch()
  let {width} = useWindowDimensions();
  let themeColor = useThemeColor();
  const [cardIndex, setCardIndex] = useState(-1)
  const [selectedCard, setSelectedCard] = useState(false);
  const [checkInData, setCheckInData] = useState(null);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const getUserProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const processCheckinData = useSelector(
    (state) => state.processCheckinData,
    shallowEqual
  );
  
  const handleCheckIn = () => {
    console.log(item.id, 'newIndex')
    // console.log(newIndex, 'newIndex')
    if (handleReadyToCheckin()) {
    let userIdsToCheckIn = [];
    setCardIndex(index);
    selectedItem.map((item) => {
      userIdsToCheckIn.push(item.id)
    })
    selectedGuest.map((item) => {
      userIdsToCheckIn.push(item.id)
    })
    // selectedSpouse.map((item) => {
    userIdsToCheckIn.push(getUserProfileData?.userId)
    if(selectedSpouse.spouseId){
      userIdsToCheckIn.push(selectedSpouse.spouseId)
    }
    // })
    const payload = {
      orgId: orgInfo.orgId,
      userId: getUserProfileData?.userId,
      eventId: item.id,
      userIdsToCheckIn,
      newGuestsToCheckIn: newGuestToCheckin,
      checkInLocation: toggleCheckin
    }
      console.log(payload, 'payload');
    return dispatch(processCheckin(payload, handleCheckinCallback))
  }
    // if(index=== newIndex){
    // }
  }

  const getIndexValue = (indexValue) => {
    if(indexValue === index){
      setSelectedCard(true);
      handleSelectedCard(true, indexValue)
      // handleCheckIn();
    }
    if (selectedCard && indexValue === index && toggleCheckin) {
      // if (handleReadyToCheckin()) {
        handleCheckIn();
      // }
    }
  }
  const handleFinalSubmit = () => {
    handleCheckIn()
  }
  const handleCloseDetails = () => {
    setSelectedCard(false)
    handleSelectedCard(false, '')
  }

  const getcheckInInformation = () => {
    checkInInformation(window.location.search)
      .then((result) => {
        dispatch(
          getUserProfile({
            orgId: orgInfo?.orgId,
            userId: result.responseData.userId,
          })
        );
        console.log(result.responseData, "checkin data");
        if (result.responseCode === "00") {
          setCheckInData(result.responseData)
          let newData = [];
          result.responseData.events.map((item, index) => {
            let exist = result.responseData.userExistingCheckedIn.find((el) => el.eventId === item.id);
            if (exist) {
              item.check = true
              newData.push(item);
            } else {
              item.check = false
              newData.push(item);
            }
          });
          // console.log(newData, 'new data');
          // newData.length <= 0 ? setEventData(result.responseData.events) :
          newData.length <= 0 ? getEventData(result.responseData.events) :
          // setEventData(newData);
          getEventData(newData)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const handleCheckinCallback = () => {
    getcheckInInformation();
    resetGuestCheckinData();
    setNewGuestToCheckin(arr => [...arr, []]);
    // handleSelectedCard(false, '')
    handleCloseDetails()
  }

  const handleCheckinCancel = (item) => {
    console.log(item, 'itemzzzzz')
    setCheckinCancel(item)
  }
  return (
    <div
      className={`${
        themeColor.textColor === 'white' ? "main-card-container rounded-lg bg-dark" : "main-card-container rounded-lg"
      }`}
    >
      <div className="card">
        {width >= 720 && <div className={`${
        themeColor.textColor === 'white' ? "card-container bg-color" : "card-container"
      }`}>
          <div className="time-container">
            <h4 className="time-text">
              {moment(item.startTime, ["H.mm"]).format(
                "h:mm A"
              )}
            </h4>
            <span>-</span>
            <h4 className="time-text">
              {moment(item.endTime, ["H.mm"]).format("h:mm A")}
            </h4>
          </div>
          <div className="details-container">
            <h4>{item.name}</h4>
            <span>{item.description}</span>
            <p className="location-container">
              <span className="location"></span>{" "}
              {item.locationName}
            </p>
          </div>
        </div>}
        {width <= 720 && (
          <div className={`${
            themeColor.textColor === 'white' ? "card-container bg-color" : "card-container"
            }`}>
            <div className="details-container">
            <h4>{item.name}</h4>
            <span>{item.description}</span>
            <p className="location-container">
              <span className="location"></span>{" "}
              {item.locationName}
            </p>
          </div>
          <h4 className="time-text">
          {moment(item.startTime, ["H.mm"]).format(
                "h:mm A"
              )} - {moment(item.endTime, ["H.mm"]).format("h:mm A")}
            </h4>
            </div>
        )}
      </div>
      {children}
      {item.canCheckIn === 'true' && <div className="w-100 d-flex">
        {!item.check ? (
          // <div
          //   onClick={() => handleCheckIn(item)}
          //   className="w-50 cursor px-10 py-5 text-center  font-weight-bolder  checkin-btn text-white"
          //   style={{cursor: processCheckinData.loading && 'not-allowed !important', backgroundColor: themeColor.color}}
          // >
          //   {/* <span className='text-center'>{processCheckinData.loading ? 'Please wait...' : `${checkInData.checkInButtonLabel}`} </span> */}
          //   <span className='text-center'>{processCheckinData.loading ? 'Please wait...' : `CheckIn`} </span>
          // </div>
          <CheckInBtn 
            index={index}
            item={item}
            getIndexValue={getIndexValue}
            selectedCard={selectedCard}
            // handleCheckIn={handleCheckIn}
            getcheckInInformation={getcheckInInformation}
            resetGuestCheckinData={resetGuestCheckinData}
            processCheckinData={processCheckinData}
            themeColor={themeColor}
            checkInData={checkInData}
            selectedItem={selectedItem}
            selectedSpouse={selectedSpouse}
            selectedGuest={selectedGuest}
            newGuestToCheckin={newGuestToCheckin}
            toggleCheckin={toggleCheckin}
            handleFinalSubmit={handleFinalSubmit}
          />
        ) : (
          <div className="w-100 cursor px-10 py-5 text-center  font-weight-bolder  bg-danger checkin-btn text-white"
            // onClick={() => setCheckoutShow({...checkOutShow, show: true, id: item.id})}
            // onClick={() => handleCheckinCancel(item)}
          >
            {/* {checkInData?.checkOutButtonLabel} */}
            {/* Check-Out */}
            Already Check-In
          </div>
        )}
        {selectedCard && <div className="w-50 px-10 py-5 text-center  font-weight-bolder  bg-gray-400 cursor" 
            onClick={handleCloseDetails} 
           >
            Cancel
            {/* {checkInData.checkOutButtonLabel}  */}
          </div>}
        {/* {!item.check ? ( */}
          {/* <div className="w-50 px-10 py-5 text-center  font-weight-bolder  bg-gray-400" */}
            {/* // onClick={handleCheckinCancel} */}
          {/* > */}
            {/* {checkInData.checkOutButtonLabel} */} 
          {/* </div> */}

      </div>}
      {item.canCheckIn === 'false' && <div
          className="w-100 px-10 py-5 text-center  font-weight-bolder  checkin-btn text-white"
          style={{cursor: 'not-allowed !important', backgroundColor: themeColor.color}}
        >
          <span className='text-center'> Can't Checkin these event</span>
        </div>}
    </div>
  )
};

const CheckInBtn = ({
  // handleCheckIn,
  item,
  index,
  // processCheckinData,
  themeColor,
  getIndexValue,
  selectedCard,
  checkInData,
  selectedItem,
  selectedSpouse,
  selectedGuest,
  newGuestToCheckin,
  toggleCheckin,
  getcheckInInformation,
  resetGuestCheckinData,
  handleFinalSubmit
  }) => {
  const [checkIndex, setCheckIndex] = useState(-1)
  // console.log(index, 'index')
  const handleCheckInBtn = () => {
    console.log(index, 'indexss');
    getIndexValue(index)
    setCheckIndex(index)
  }

  const dispatch = useDispatch();

  // const orgInfo = useSelector(
  //   (state) => state.orgInfo.responseData,
  //   shallowEqual
  // );

  // const getUserProfileData = useSelector(
  //   (state) => state.getUserProfileData.responseData,
  //   shallowEqual
  // );
  const processCheckinData = useSelector(
    (state) => state.processCheckinData,
    shallowEqual
  );

  // const handleCheckIn = () => {
  //   let userIdsToCheckIn = [];
  //   // setCardIndex(index);
  //   selectedItem.map((item) => {
  //     userIdsToCheckIn.push(item.id)
  //   })
  //   selectedGuest.map((item) => {
  //     userIdsToCheckIn.push(item.id)
  //   })
  //   // selectedSpouse.map((item) => {
  //   userIdsToCheckIn.push(selectedSpouse.spouseId)
  //   // })
  //   userIdsToCheckIn.push(getUserProfileData?.userId)
  //   const payload = {
  //     orgId: orgInfo.orgId,
  //     userId: getUserProfileData?.userId,
  //     eventId: item.id,
  //     userIdsToCheckIn,
  //     newGuestsToCheckIn: newGuestToCheckin,
  //     checkInLocation: toggleCheckin
  //   }
  //   return dispatch(processCheckin(payload, handleCheckinCallback))
  //   // if(index=== newIndex){
  //   //   console.log(payload, 'seleeeee');
  //   // }
  // }

  // const handleCheckinCallback = () => {
  //   getcheckInInformation();
  //   resetGuestCheckinData();
  // }
  
  useEffect(() => {
    console.log(checkIndex, 'checkIndex')
    getIndexValue(checkIndex)
  }, [checkIndex])
  
  return (
    <div
    onClick={selectedCard ? handleFinalSubmit : handleCheckInBtn}
    // onClick={handleCheckIn}
    
    className={`${selectedCard ? 'w-50' : 'w-100'} cursor px-10 py-5 text-center  font-weight-bolder  checkin-btn`}
    style={{cursor: processCheckinData.loading && 'not-allowed !important', backgroundColor: themeColor.color}}
    >
      <span className='text-center text-white'>{processCheckinData.loading && selectedCard ? 'Please wait...' : `Check-In`} </span>
      {/* <span className='text-center'>CheckIn</span> */}
    </div>
  )
}

export const FutureEventCard = ({item}) => {
  let {width} = useWindowDimensions();
  let themeColor = useThemeColor();
  return (
      <div
        className={`${
          themeColor.textColor === 'white' ? "main-card-container rounded-lg bg-dark" : "main-card-container rounded-lg"
        }`}
      >
        <div className="card">
          {width >= 720 && <div className={`${
          themeColor.textColor === 'white' ? "card-container bg-color p-2" : "card-container p-2"
        }`}>
            <div className="time-container d-flex justify-content-between">
              <div>
              <h4 className="time-text">
                {moment(item.startTime, ["H.mm"]).format(
                  "h:mm A"
                )}
              </h4>
              <span>-</span>
              <h4 className="time-text">
                {moment(item.endTime, ["H.mm"]).format("h:mm A")}
              </h4>
              </div>
              <h4>{moment(item.date).format('ddd MMM D, YYYY')}</h4>
            </div>
            <div className="details-container">
              <h4>{item.name}</h4>
              <span>{item.description}</span>
              <p className="location-container">
                <span className="location"></span>{" "}
                {item.locationName}
              </p>
            </div>
          </div>}
          {width <= 720 && (
            <div className={`${
              themeColor.textColor === 'white' ? "card-container bg-color p-2" : "card-container p-2"
              }`}>
              <div className="details-container">
              <h4>{item.name}</h4>
              <span>{item.description}</span>
              <p className="location-container">
                <span className="location"></span>{" "}
                {item.locationName}
              </p>
            </div>
            <h4 className="time-text">
            {moment(item.startTime, ["H.mm"]).format(
                  "h:mm A"
                )} - {moment(item.endTime, ["H.mm"]).format("h:mm A")}
              </h4>
              <h4 className='time-text'>
                {moment(item.date).format('ddd MMM D, YYYY')}
              </h4>
              </div>
          )}
        </div>
      </div>
    )
}