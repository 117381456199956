import React from "react";
import { Home } from "./Home";
import { RSVPMainPage } from "../../components/Forms/RSVP/RSVPMainPage";
import { Helmet } from "react-helmet";
import { useSelector, shallowEqual } from "react-redux";

export const ReserveASeatMainPage = () => {
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  return (
    <>
      {secondOrgInfo && (
        <Helmet>
          <title>
            {window.location.pathname.substr(1) === "checkin"
              ? secondOrgInfo.metaData.checkIn.title
              : secondOrgInfo.metaData.rsvp.title}
          </title>
          <meta
            name={
              window.location.pathname.substr(1) === "checkin"
                ? secondOrgInfo.metaData.checkIn.author
                : secondOrgInfo.metaData.rsvp.author
            }
            content={
              window.location.pathname.substr(1) === "checkin"
                ? secondOrgInfo.metaData.checkIn.description
                : secondOrgInfo.metaData.rsvp.description
            }
          />
          <meta
            name='Keywords'
            content={
              window.location.pathname.substr(1) === "checkin"
                ? secondOrgInfo.metaData.checkIn.keywords.toString()
                : secondOrgInfo.metaData.rsvp.keywords.toString()
            }
          />
        </Helmet>
      )}
      <Home>
        <RSVPMainPage />
      </Home>
      ;
    </>
  );
};
