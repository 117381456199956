import { checkNodeEnv } from "../../utils/authHelpers";

import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { actionTypes } from "../actionTypes";
import { defaultAction, apiUrl } from "../../utils/constants";
import NotificationMesage from "../../components/Common/NotificationMesage";
import {
  axiosPayload,
  startLoadingAction,
  resetEndpointDataAction,
  stopLoadingAction,
} from ".";

export const getGivingCampaignInfo = (payload, callback) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.getGivingCampaignInfo("live-api"),
      apiUrl.getGivingCampaignInfo("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // toast.success(
        //   <NotificationMesage
        //     message={data.responseMessage}
        //     responseStatus={data.responseCode}
        //   />
        // );
        return callback(data.responseData);
        // return dispatch(
        //   defaultAction(actionTypes.GET_USER_DATA_SMART_GIVE_SUCCESS)
        // );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        // return dispatch(
        //   defaultAction(actionTypes.UPDATE_A_RECURRING_PORTAL_FAILURE)
        // );
      }
    } catch (error) {}
  };
};

export const getDynamicGivingCampaignInfo = (payload, callback) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.getDynamicGivingCampaignInfo("live-api"),
      apiUrl.getDynamicGivingCampaignInfo("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      // if (data.responseCode === "00") {
      //   dispatch(stopLoadingAction);
      //   // toast.success(
      //   //   <NotificationMesage
      //   //     message={data.responseMessage}
      //   //     responseStatus={data.responseCode}
      //   //   />
      //   // );
      //   return callback(data.responseData);
      //   // return dispatch(
      //   //   defaultAction(actionTypes.GET_USER_DATA_SMART_GIVE_SUCCESS)
      //   // );
      // } else {
      //   dispatch(stopLoadingAction);
      //   toast.error(
      //     <NotificationMesage
      //       message={data.responseMessage}
      //       responseStatus={data.responseCode}
      //     />
      //   );
      //   // return dispatch(
      //   //   defaultAction(actionTypes.UPDATE_A_RECURRING_PORTAL_FAILURE)
      //   // );
      // }
      dispatch(stopLoadingAction);
      return callback(data);
    } catch (error) {}
  };
};
