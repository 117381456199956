import React from "react";
import axios from "axios";
import NotificationMesage from "../../../components/Common/NotificationMesage";
import { toast } from "react-toastify";
import { actionTypes } from "../../actionTypes";
import { checkNodeEnv } from "../../../utils/authHelpers";
import { defaultAction, apiUrl } from "../../../utils/constants";
import {
  axiosPayload,
  startLoadingAction,
  resetEndpointDataAction,
  stopLoadingAction,
  axiosMultipartPayload
} from "../index";

export const prayerWalls = (payload) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.prayerWalls('live-api'),
      apiUrl.prayerWalls("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        return dispatch(defaultAction(actionTypes.PRAYERWALLS_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        return dispatch(defaultAction(actionTypes.PRAYERWALLS_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};
export const myPrayerWalls = (payload) => {
  return async (dispatch) => {
    // dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.prayerWalls('live-api'),
      apiUrl.prayerWalls("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        // dispatch(stopLoadingAction);
        return dispatch(defaultAction(actionTypes.MY_PRAYERWALLS_SUCCESS, data));
      } else {
        // dispatch(stopLoadingAction);
        return dispatch(defaultAction(actionTypes.MY_PRAYERWALLS_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};
export const prayerWallsWithoutLoader = (payload) => {
  return async (dispatch) => {
    // dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.prayerWalls('live-api'),
      apiUrl.prayerWalls("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        return dispatch(defaultAction(actionTypes.PRAYERWALLS_SUCCESS, data));
      } else {
        // dispatch(stopLoadingAction);
        return dispatch(defaultAction(actionTypes.PRAYERWALLS_FAILURE, data));
      }
    } catch (error) {
      console.log(error, 'errr');
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const prayerWallsLikeOrPray = (payload, callback, callback2) => {
  return async (dispatch) => {
    dispatch(defaultAction(actionTypes.PRAYERWALLSLIKEORPRAY_START_LOADING));
    const url = checkNodeEnv(
      apiUrl.prayerWallsLikeOrPray('live-api'),
      apiUrl.prayerWallsLikeOrPray("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(defaultAction(actionTypes.PRAYERWALLSLIKEORPRAY_STOP_LOADING));
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback()
        callback2()
        return dispatch(
          defaultAction(actionTypes.PRAYERWALLSLIKEORPRAY_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.PRAYERWALLSLIKEORPRAY_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};
export const addPrayerWalls = (payload, callback) => {
  return async (dispatch) => {
    dispatch(defaultAction(actionTypes.ADDPRAYER_START_LOADING));
    const url = checkNodeEnv(
      apiUrl.addPrayerWalls('live-api'),
      apiUrl.addPrayerWalls("test-api")
    );
    try {
      const response = await axios(axiosMultipartPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback()
        dispatch(defaultAction(actionTypes.ADDPRAYER_SUCCESS, data));
        toast.success(
          <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
          />
          );
          // callback();
        // callback2();
        return dispatch(defaultAction(actionTypes.ADDPRAYER_FAILURE, data));
      } else {
        dispatch(defaultAction(actionTypes.ADDPRAYER_STOP_LOADING));
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(defaultAction(actionTypes.ADDPRAYER_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};
export const archivePrayer = (payload, callback, callback2) => {
  return async (dispatch) => {
    dispatch(defaultAction(actionTypes.ARCHIVEPRAYER_START_LOADING));
    const url = checkNodeEnv(
      apiUrl.archivePrayerUrl('live-api'),
      apiUrl.archivePrayerUrl("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(defaultAction(actionTypes.ARCHIVEPRAYER_STOP_LOADING));
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        callback2();
        return dispatch(defaultAction(actionTypes.ARCHIVEPRAYER_SUCCESS, data));
      } else {
        dispatch(defaultAction(actionTypes.ARCHIVEPRAYER_STOP_LOADING));
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(defaultAction(actionTypes.ARCHIVEPRAYER_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};
export const markedAnsweredPrayer = (payload, callback, callback2) => {
  return async (dispatch) => {
    dispatch(defaultAction(actionTypes.MARKED_ANSWERED_PRAYER_START_LOADING));
    const url = checkNodeEnv(
      apiUrl.answeredPrayerUrl('live-api'),
      apiUrl.answeredPrayerUrl("test-api")
    );
    try {
      const response = await axios(axiosMultipartPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(defaultAction(actionTypes.MARKED_ANSWERED_PRAYER_STOP_LOADING));
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        callback2();
        return dispatch(
          defaultAction(actionTypes.MARKED_ANSWERED_PRAYER_SUCCESS, data)
        );
      } else {
        dispatch(defaultAction(actionTypes.MARKED_ANSWERED_PRAYER_STOP_LOADING));
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MARKED_ANSWERED_PRAYER_FAILURE, data)
        );
      }
    } catch (error) {
      console.log(error, 'mark');
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};
