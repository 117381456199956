import React from 'react'
import {useSelector, shallowEqual} from 'react-redux';
import { Paper } from '@material-ui/core';
import { useThemeColor } from '../utils/customHooks/useThemeColor'
import './Section.scss'
import {useContributionProcessEngine} from '../utils/customHooks/useContributionProcessEngine'

export const GiveSubSection = ({ children }) => {
  const themeColor = useThemeColor()
  const selectedPayment = useSelector(state => state.selectedPaymentOption)
  const showPaymentOptionPage = useContributionProcessEngine()
  // const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual)
  // const mainSectionBg = {backgroundColor:  themeColor.color};
  return (
    <Paper square className={`text-center ${selectedPayment.currency && showPaymentOptionPage.hasMultiple ?  'give-section': !showPaymentOptionPage.hasMultiple && showPaymentOptionPage.paymentList ? 'one-give-option' : 'give-without'}`} style={themeColor.btnStyle}>
      {children}
    </Paper>
)
  }