import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
// import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { updateUserRecord, getUserProfile } from "../../../store/actions";
// import { Tabs, Tab, TabContainer } from "react-bootstrap";
import Forms from "./Forms";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SkeletonLoader from "../../Loader/PageLoader";
import {toast} from 'react-toastify'
import NotificatioMessage from '../../Common/NotificationMesage'
import {useWindowDimensions} from '../../../utils/customHooks/useWindowDimensions'
import PhoneInput from '../../Common/PhoneInputComponent'

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  let {width} = useWindowDimensions()
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

export const EmergencyContact = () => {
  const [value, setValue] = React.useState(0);
  const userDetails = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const userDetailsLoading = useSelector(
    (state) => state.getUserProfileData,
    shallowEqual
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const userData = useSelector(
    (state) => state.updateUserData,
    shallowEqual
  );
  const orgInfoLoading = useSelector((state) => state.orgInfo, shallowEqual);
  const updateUserData = useSelector(
    (state) => state.updateUserData,
    shallowEqual
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  let updateCareerResult;

  const [mobile, setMobile] = useState('')
  const [mobile2, setMobile2] = useState('')
  const [userProfileData, setUserData] = useState([])
  const [reload, setReload] = useState(false)

  const formDetails = [
    {
      label: "First Name",
      name: "emergencyContact1FirstName",
      type: "text",
      defaultValue: userDetails && userDetails.userEmergencyContact1FirstName,
      errorMsg: "First name is Required",
    },
    {
      label: "Last Name",
      name: "emergencyContact1LastName",
      type: "text",
      defaultValue: userDetails && userDetails.userEmergencyContact1LastName,
      errorMsg: "Last Name is Required",
    },
    {
      name: "emergencyContact1Email",
      label: "Email",
      defaultValue: userDetails && userDetails.userEmergencyContact1Email,
      type: "email",
      errorMsg: "Email is Required",
      pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      patternMsg: 'Invalid Email'
    },
  ];
  const formDetails2 = [
    {
      label: "First Name",
      name: "emergencyContact2FirstName",
      type: "text",
      defaultValue: userDetails && userDetails.userEmergencyContact2FirstName,
      errorMsg: "First name is Required",
    },
    {
      label: "Last Name",
      name: "emergencyContact2LastName",
      type: "text",
      defaultValue: userDetails && userDetails.userEmergencyContact2LastName,
      errorMsg: "Last Name is Required",
    },
    {
      name: "emergencyContact2Email",
      label: "Email",
      defaultValue: userDetails && userDetails.userEmergencyContact2Email,
      type: "email",
      errorMsg: "Email is Required",
      pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      patternMsg: 'Invalid Email'
    },
  ];

  useEffect(() => {
    if (userDetails) setReload(true)
    // setUserData(userDetails)
    // setValue(0)
    setReload(true)
  }, [userDetails])

  const handleRedirect =() => {
    dispatch(getUserProfile({'orgId': orgInfo.orgId, 'userId': userDetails?.userId}))
    // window.location.reload()
  }

  const handleFormData = (data) => {
    const {emergencyContact1Email, emergencyContact1FirstName, emergencyContact1LastName, emergencyContact1Phone} = data;
    const payload = {
      emergencyContact1Email,
      emergencyContact1FirstName,
      emergencyContact1LastName,
      emergencyContact1Phone: mobile,
      userId: userDetails.userId,
      orgId: orgInfo.orgId,
    }
    dispatch(
      updateUserRecord(payload, handleRedirect)
    );
  };
  const handleFormData2 = (data) => {
    const {emergencyContact2Email, emergencyContact2FirstName, emergencyContact2LastName, emergencyContact2Phone} = data;
    const payload = {
      emergencyContact2Email,
      emergencyContact2FirstName,
      emergencyContact2LastName,
      emergencyContact2Phone: mobile2,
      userId: userDetails.userId,
      orgId: orgInfo.orgId,
    }
    dispatch(
      updateUserRecord(payload, handleRedirect)
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  let {width} = useWindowDimensions()
  const handlePhoneInput = (data) => {
    setMobile(data)
  }
  const handlePhoneInput2 = (data) => {
    setMobile2(data)
  }

  // if (orgInfoLoading.loading || userDetailsLoading?.loading) {
  //   return <SkeletonLoader />;
  // }
  return (
    <Paper className="w-100 page-wrapper my-8 p-6">
      <div>
        <span className="font-size-h3 mr-2">My Emergency Contacts</span>
        <span className="text-muted">In the event you can't reach me</span>
      </div>
      <hr />
      {userDetails && reload && <div className="col-md-10 m-auto">
        <Paper square className={`${width >= 720 ? 'w-50 mx-auto': 'w-100 mx-auto'}`} elevation={2}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="emergency tabs"
            variant="fullWidth"
          >
            <Tab label="Emergency Contact 1" {...a11yProps(0)} />
            <Tab label="Emergency Contact 2" {...a11yProps(1)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0}>
              <PhoneInput defaultMoblie={userDetails?.userEmergencyContact1Phone} handlePhone={handlePhoneInput}/>
              {userDetails && <Forms
                formDetails={formDetails}
                buttonName="Update"
                handleFormData={handleFormData}
                loading={userData.loading}
              />}
            </TabPanel>
            <TabPanel value={value} index={1}>
            <PhoneInput defaultMoblie={userDetails?.userEmergencyContact2Phone} handlePhone={handlePhoneInput2}/>
              {userDetails && <Forms
                formDetails={formDetails2}
                buttonName="Update"
                handleFormData={handleFormData2}
                loading={userData.loading}
              />}
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </div>}
    </Paper>
  );
};