import React from 'react'
import {motion} from 'framer-motion'
import EmptyIcon from '../Icons/EmptyIcon'

export default function Empty() {
    return (
        <motion.div
         style={{display: 'flex', justifyContent: 'center'}}
         animate={{
             x: 0,
             opacity: 1
         }}
         initial={{
             x: 100,
             opacity: 0
         }}
         transition={{ stiffness: 150, type: "spring" }}
        >
            <div className="mt-2 text-center text-capitalize">
            <EmptyIcon />
            <h3>Sorry, no records found</h3>
            </div>
        </motion.div>
    )
}
