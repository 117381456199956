import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  SigninPage,
  SignupPage,
  ForgotPasswordPage,
  ForgotPinPage,
  ReserveASeatPage,
  ReserveASeatMainPage,
  GivePage,
  VerifyPinOrPasswordPage,
  CheckInMain,
  LivePage,
  DonatePage,
  AppAccessPage,
  QGivePage,
  DeletePage,
} from "../pages/LandingPage/";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  ContributionPage,
  MyInfoPage,
  Give,
  FamilyPage,
  MailingPage,
  Church411Page,
  CareerPage,
  PrayerPage,
  TestimonyPage,
  EmergencyPage,
  UpdateEmailPage,
  TwoFAPage,
  UserDashboardPage,
  PaypalPage,
  DeleteAccountPage,
} from "../pages/UserDashboardPage/";
import {
  getOrgInfoList,
  getUserProfile,
  secondOrgList,
  contributionProcessingEngines,
} from "../store/actions";
import {
  SuccessGivePage,
  FailureGivePage,
} from "../components/User/Paypal/SuccessGivePage";
import ErrorPage from "../pages/Error";
import Layout from "../pages/UserDashboardPage/Layout";
import TwoFactorAuthentication from "../pages/LandingPage/TwoFactorAuth";
import SuccessPage from "../components/SuccessPage";
import { getFollowUpByOrganization } from "../store/actions/followup";
import FollowupPage from "../pages/LandingPage/FollowupPage";
import FollowupSuccessPage from "../pages/LandingPage/FollowupSuccessPage";
import NewHerePage from "../pages/UserDashboardPage/NewHerePage";
import { PaymentOptionPage } from "../pages/UserDashboardPage/PaymentOptionPage";
import LoginByToken from "../pages/LandingPage/LoginByToken";
import EpayPage from "../pages/LandingPage/EpayPage";
import EpaySuccess from "../pages/LandingPage/EpaySuccess";
import EpayUnsuccess from "../pages/LandingPage/EPayUnsuccess";
import PaymentStatus from "../components/Forms/GiveForm/PaymentStatus";
import CelebrationCarousel from "../components/Celebration/CelebrationCarousel";
import CelebrationMain from "../components/Celebration/CelebrationMain";
import Celebration from "../pages/LandingPage/Celebration";
import { FormsPage } from "../pages/UserDashboardPage/Forms";
import { ViewFormPage } from "../pages/UserDashboardPage/ViewForms";
import Forms from "../pages/LandingPage/Forms";
import FollowUpPage from "../pages/UserDashboardPage/FollowUpPage";
import ViewLogPage from "../pages/UserDashboardPage/ViewLogPage";
import ServiceUnitPage from "../pages/UserDashboardPage/ServiceUnitPage";
import useOnBoarding from "../utils/customHooks/useOnBoarding";
import PrivacyPage from "../pages/LandingPage/Privacy";
import Privacy from "../components/Privacy/Privacy";
import SmartGive from "../pages/SmartGive";
import GivingCampaign from "../pages/GiveCampaignV2";
// import GivingCampaign from "../pages/GiveCampaignV2";
import DynamicGivingCampaign from "../pages/GiveCampaignV2/DynamicGiveCampaign";
import MasterAppPage from "../pages/LandingPage/MasterAppPage";
import GiveMethods from "../pages/UserDashboardPage/GiveMethods/GiveMethods";
import { SubmittedFormPage } from "../pages/UserDashboardPage/SubmittedFormPage";

const UserDashboardRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const AdminRedirect = () => {
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  useEffect(() => {
    if (window.location.pathname === "/admin") {
      return window.open(orgInfo?.privacySecurityStuff?.adminUrl);
    }
  }, []);
  return <></>;
};

export const Routes = () => {
  const dispatch = useDispatch();
  // const history = useHistory()
  // const location = useLocation();

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const { isAdvanced } = useSelector(
    (state) => state.getPaymentMode,
    shallowEqual
  );
  const {
    give = {},
    checkIn = {},
    rsvp = {},
    newHere = {},
    liveStream = {},
  } = orgInfo?.landingPageModulesAdvanced || {
    give: {},
    checkIn: {},
    rsvp: {},
    newHere: {},
    liveStream: {},
  };
  useEffect(() => {
    const getMaterApp = JSON.parse(localStorage.getItem('master_app'))
    if (!getMaterApp && window.location.hostname !== "privacy.churchpad.com" || window.location.pathname === '/churchpad' ) {
      dispatch(getOrgInfoList({ orgId: orgInfo.orgId }));
      dispatch(secondOrgList({ orgId: orgInfo.orgId }));
    }

    if (getMaterApp) {
      // dispatch(getOrgInfoList({ orgId: orgInfo.orgId }));
      // dispatch(secondOrgList({ orgId: orgInfo.orgId }));
    }
    // dispatch(getFollowUpByOrganization(orgInfo.orgId))
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/admin") {
      return window.open(orgInfo?.privacySecurityStuff?.adminUrl, "_self");
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname !== window.location.pathname.toLowerCase()) {
      console.log(window.location.pathname, 'location.pathname');
      window.history.replaceState(window.location.pathname.toLowerCase(), '')
      // history.replace(location.pathname.toLowerCase());
      // window.location.pathname
    }
  }, []);

  const { churchName, isOnBoarding } = useOnBoarding();

  function getBasename() {
    if (isOnBoarding && churchName) return `/${churchName}`;
    return "";
  }

  // console.log(window.location.hostname, "host name route");

  if (window.location.hostname === "privacy.churchpad.com") {
    return (
      <Router>
        <Switch>
          <Route path="/" exact>
            <Privacy privacy_host />
          </Route>
        </Switch>
      </Router>
    );
  }

  return (
    <Router basename={getBasename()}>
      <Switch>
        <Route path="/" exact component={SigninPage} />
        <Route path="/signup" sensitive exact component={SignupPage} />
        <Route path="/forgot_password" exact component={ForgotPasswordPage} />
        <Route
          path="/reset_password"
          exact
          component={VerifyPinOrPasswordPage}
        />
        <Route path="/reset_pin" exact component={VerifyPinOrPasswordPage} />
        <Route path="/forgot_pin" exact component={ForgotPinPage} />
        <Route path="/forms" exact component={Forms} />
        <Route path="/delete-my-account" exact component={DeletePage} />
        <Route path="/churchpad" sensitive exact component={MasterAppPage} />
        <Route
          path="/2fa_verification"
          exact
          component={TwoFactorAuthentication}
        />
        {liveStream?.status === "true" && (
          <Route path="/live" sensitive exact component={LivePage} />
        )}
        <Route path="/admin" sensitive exact component={AdminRedirect} />
        {newHere?.status === "true" && (
          <Route path="/new" sensitive exact component={FollowupPage} />
        )}
        <Route
          path="/follow_up_success"
          exact
          component={FollowupSuccessPage}
        />
        <Route path="/cpauth" exact component={LoginByToken} />
        <Route path="/epay" exact component={EpayPage} />
        <Route path="/app" exact component={AppAccessPage} />
        {/* <Route path='/qgive' exact component={QGivePage} /> */}
        <Route path="/epay_success" exact component={EpaySuccess} />
        <Route path="/epay_declined" exact component={EpayUnsuccess} />
        <Route sensitive path="/celebration" exact component={Celebration} />
        <Route
          path="/celebration/:name"
          exact
          component={CelebrationCarousel}
        />
        <Route path="/privacy" exact component={PrivacyPage} />
        {rsvp?.status === "true" && (
          <Route path="/rsvp" exact component={ReserveASeatPage} />
        )}
        {rsvp?.status === "true" && (
          <Route path="/rsvp/main" exact component={ReserveASeatMainPage} />
        )}
        {checkIn?.status === "true" && (
          <Route path="/checkin/main" exact component={CheckInMain} />
        )}
        {checkIn?.status === "true" && (
          <Route path="/checkin" exact component={ReserveASeatPage} />
        )}
        {/* {give?.status === 'true' && <Route path="/give" exact component={GivePage} />}  */}
        <Route exact sensitive path="/qgive">
          <Redirect to="/give" />
        </Route>

        {give?.status === "true" && (
          <Route
            path="/give"
            exact
            component={isAdvanced ? GivePage : QGivePage}
          />
        )}
        <Route path="/sgive" sensitive exact component={SmartGive} />
        <Route path="/gc" exact component={GivingCampaign} />
        <Route path="/dgc" exact component={DynamicGivingCampaign} />
        {give?.status === "true" && (
          <Route path="/success" exact component={SuccessPage} />
        )}
        <Route
          path="/paypal/mobile/success"
          exact
          render={() => <SuccessPage fromMobileApp={true} />}
        />
        <Route
          path="/paypal/mobile/failure"
          exact
          render={() => <SuccessPage giveFailure={true} />}
        />
        <Route path="/donate" exact component={DonatePage} />
        <Route
          path="/payment_status/:name/:status"
          exact
          component={PaymentStatus}
        />
        <UserDashboardRoute
          path="/dashboard"
          exact
          component={UserDashboardPage}
        />
        <UserDashboardRoute
          path="/contribution"
          exact
          component={ContributionPage}
        />
        {/* <Layout> */}
        <UserDashboardRoute path="/update" exact component={UpdateEmailPage} />
        <UserDashboardRoute path="/user/donate" exact component={Give} />
        <UserDashboardRoute path="/user/donate/pps" exact component={Give} />
        <UserDashboardRoute path="/emergency" exact component={EmergencyPage} />
        <UserDashboardRoute path="/church411" exact component={Church411Page} />
        <UserDashboardRoute path="/my_info" exact component={MyInfoPage} />
        <UserDashboardRoute path="/family" exact component={FamilyPage} />
        <UserDashboardRoute path="/mailing" exact component={MailingPage} />
        <UserDashboardRoute path="/career" exact component={CareerPage} />
        <UserDashboardRoute path="/prayer" exact component={PrayerPage} />
        <UserDashboardRoute path="/testimony" exact component={TestimonyPage} />
        <UserDashboardRoute path="/twoFA" exact component={TwoFAPage} />
        <UserDashboardRoute path="/paypal" exact component={PaypalPage} />
        <UserDashboardRoute
          path="/user/give/:name"
          exact
          component={PaymentOptionPage}
        />
        <UserDashboardRoute
          path="/paypal/success"
          exact
          component={SuccessGivePage}
        />
        <UserDashboardRoute
          path="/paypal/failure"
          exact
          component={FailureGivePage}
        />
        <UserDashboardRoute
          path="/new_register_user"
          exact
          component={NewHerePage}
        />
        <UserDashboardRoute path="/user/forms" exact component={FormsPage} />
        <UserDashboardRoute path="/follow_up" exact component={FollowUpPage} />
        <UserDashboardRoute path="/view_forms" exact component={ViewFormPage} />
        <UserDashboardRoute
          path="/submitted_form/:id"
          exact
          component={SubmittedFormPage}
        />
        <UserDashboardRoute
          path="/follow-up/view-log"
          exact
          component={ViewLogPage}
        />
        <UserDashboardRoute
          path="/service_unit"
          exact
          component={ServiceUnitPage}
        />
        <UserDashboardRoute
          path="/user/account/delete"
          exact
          component={DeleteAccountPage}
        />
        <UserDashboardRoute
          path="/give_methods"
          exact
          component={GiveMethods}
        />
        <UserDashboardRoute path="*" component={ErrorPage} />
        {/* </Layout> */}
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Router>
  );
};

export default Routes;
