import React from 'react'
import {Stepper, Step, StepLabel, StepButton, Button, Typography} from '@material-ui/core';
import DynamicForms, {Dynamic} from './DynamicForms';
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import FirstStep from './FirstStep';
import SecondStep from './SecondStep'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getFormLegacy, submitFormLegacy } from '../../store/actions/followup';
import { useHistory } from 'react-router-dom';


export default function StepperComponent({data, handleSubmit, isDashboard}) {
    const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [pageNumber, setPageNumber] = React.useState(1)
  const themeColor = useThemeColor();
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const getFormData = useSelector(
    (state) => state.getForm.responseData,
    shallowEqual
  );
  const userDetails = useSelector(
    (state) => state.getUserProfileData?.responseData,
    shallowEqual
  );

  const [inputData, setInputData] = React.useState({})
  const [inputData1, setInputData1] = React.useState({})
  const totalSteps = () => {
    return 2;
  };
  const dispatch = useDispatch();
  const history = useHistory()
  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleNext = (data) => {
    // console.log(data, 'input data');
    if (activeStep === 0 ){
      setInputData(data)
      return submitForm(data)
    }
    if (activeStep === 1) {
      setInputData1(data)
    }
      if (isLastStep()) {
        return submitForm(data)
      }
       setActiveStep(activeStep + 1);
  };
  const callbackFunction = () => {
    !isDashboard ? history.push('/follow_up_success') : history.push('/dashboard')
   }
  // const submitForm = (data) => {
  //   data['orgId'] = orgInfo?.orgId;
  //   const payload = {
  //     ...inputData,
  //     ...data
  //   }
  //  dispatch(submitFormLegacy(payload, callbackFunction)) 
  // }
  const submitForm = (data) => {
    data['orgId'] = orgInfo?.orgId;
    const payload = {
      // ...inputData,
      ...data
    }
   dispatch(submitFormLegacy(payload, callbackFunction)) 
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setPageNumber(pageNumber - 1)
  };

  React.useEffect(() => {
    if (isDashboard) {
      let temp = {...inputData}
      temp.gender = userDetails?.userGender
      temp.firstName = userDetails?.userFirstName
      temp.lastName = userDetails?.userLastName
      temp.email = userDetails?.userEmail
      temp.phone = userDetails?.userPhoneNumber
      temp.address = userDetails?.userAddress1
      temp.salutation = userDetails?.userSalutation
      setInputData(temp)
    }
  }, [isDashboard])

  React.useEffect(() => {
    dispatch(getFormLegacy({orgId: orgInfo?.orgId}))
  }, [])
  
  
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    // handleSubmit()
    setPageNumber(pageNumber + 1)
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  function getStepContent(step) {
    console.log(step);
    switch (step) {
      case 0:
        return <FirstStep handleNext={handleNext} 
        handleBack={handleBack} 
        activeStep={activeStep} 
        inputData={inputData}
        />;
      case 1:
        return <SecondStep 
         handleNext={handleNext} 
         handleBack={handleBack} 
         activeStep={activeStep} 
         inputData={inputData1}
         setInputData1={setInputData1}
         />;
      case 2:
        return 'Step 3: This is the bit I really care about!';
      default:
        return 'Unknown step';
    }
  }
    return (
        <div>
             {/* <Stepper alternativeLabel activeStep={activeStep}>
        {data.pages.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)} completed={completed[index]}>
              {`Step ${index + 1}`}
            </StepButton>
          </Step>
        ))}
      </Stepper> */}
        <div className="text-center mb-10 mb-lg-10">
        {!isDashboard && <div>
          <span className="icon" 
          style={themeColor.btnStyle}
          >
            <i className="far fa-address-book"></i>
          </span>
        </div>}
        <h3 className="font-size-h1 mb-5">I'm New Here</h3>
        {/* <h3 className="font-size-h1 mb-5">{`Step ${activeStep + 1}`} </h3> */}
      </div>
      <div>
        {allStepsCompleted() ? (
          // {}
          <div>
            <Typography >
              All steps completed - you&apos;re finished
            </Typography>
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </div>
        ) : (
          <div className={`${isDashboard ? 'col-md-6 m-auto' : ''}`}>
            <DynamicForms inputData={inputData} data={getFormData?.returnedRecords} handleSubmitForm={submitForm}/>
            {/* {isDashboard && Object.keys(inputData).length > 0  && <Typography >{getStepContent(activeStep)}</Typography>}
            {!isDashboard  && <Typography >{getStepContent(activeStep)}</Typography>} */}
            {/* <Dynamic data={data.pages[activeStep]?.elements} submitData={handleSubmit} activeStep={activeStep} handleBack={handleBack}  
            completed={completed} completedSteps={completedSteps}
            handleComplete={handleComplete} totalSteps={totalSteps} mainData={data}
            /> */}
            {/* <div> */}
              {/* <Button disabled={activeStep === 0} onClick={handleBack} >
                Back
              </Button> */}
                                    {/* <div>
              <Button disabled={activeStep === 0} onClick={handleBack} >
                Back
              </Button> */}
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                // className={classes.button}
              >
                Next
              </Button> */}
              {/* {activeStep !== data.pages.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleComplete} type='submit'>
                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Next'}
                  </Button>
                ))}
            </div> */}
          </div>
        )}
      </div>
        </div>
    )
}
