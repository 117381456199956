// import React from 'react'
// // import { Chart } from "react-google-charts";

// // Include the react-fusioncharts component
// import ReactFC from "react-fusioncharts";

// // Include the fusioncharts library
// import FusionCharts from "fusioncharts";

// // Include the chart type
// import Chart from "fusioncharts/fusioncharts.charts";

// // Include the theme as fusion
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// // Adding the chart and theme as dependency to the core fusioncharts
// ReactFC.fcRoot(FusionCharts, Chart, FusionTheme);

// export const DonutChartComponent = ({data}) => {
//   const chartConfigs = {
//     type: "pie2d", // The chart type
//     width: '100%', // Width of the chart
//     height: "400", // Height of the chart
//     dataFormat: "json", // Data type
//     dataSource: {
//       // Chart Configuration
//       chart: {
//         //Set the chart caption
//         // caption: "Stars per Languages",
//         animateClockwise: 1,
//         showPercentValues: 1,
//         theme: 'fusion',
//         showLabels: 0,
//         valuePosition: "inside",
//         showPercentInTooltip: "0",
//         decimals: "1",
//       },
//       // Chart Data
//       data
//     }
//   };
//   return (<ReactFC {...chartConfigs} />);
// }
import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { useSelector, shallowEqual } from 'react-redux';


export const DonutChartComponent = ({ height='400px', color = ['#5C7BD9', '#40B27D', '#7ED3F4', '#FF7070', '#FFDC60', '#99aaa2'], data }) => {

    const getThemeChangeData = useSelector(state => state.getThemeChangeData, shallowEqual)
    const option = {
        legend: {
            show: true,
            itemGap: 20,
            icon: 'circle',
            bottom: -5,
            textStyle: {
                color: getThemeChangeData.theme ? "#FFFFFF": "#000000",
                fontSize: 13,
                fontFamily: 'Poppins',
            },
        },
        tooltip: {
            show: false,
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        xAxis: [
            {
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
            },
        ],
        yAxis: [
            {
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
            },
        ],

        series: [
            {
                name: 'Traffic Rate',
                type: 'pie',
                radius: ['45%', '72.55%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                hoverOffset: 5,
                stillShowZeroSum: false,
                label: {
                    normal: {
                        show: false,
                        position: 'center', // shows the description data to center, turn off to show in right side
                        textStyle: {
                            color: getThemeChangeData.theme ? "#FFFFFF": "#000000",
                            fontSize: 13,
                            fontFamily: 'Poppins',
                        },
                        formatter: '{a}',
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '14',
                            fontWeight: 'normal',
                            color: "rgba(15, 21, 77, 1)"
                        },
                        formatter: '{b} \n{c} ({d}%)',
                    },
                },
                labelLine: {
                    normal: {
                        show: false,
                    },
                },
                data,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    }

    return (
        <ReactEcharts
            style={{ height: height }}
            option={{
                ...option,
                color: [...color],
            }}
            // theme={getThemeChangeData.theme ? 'dark' : 'light'}
        />
    )
}
