import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { verifyForgotPassword, resetEndpointDataAction } from '../../store/actions';
import { ResetPinForm } from './ResetPinForm'
import { PageLoader} from '../Loader/Loader';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import { ResetPasswordForm } from './ResetPasswordForm'
import NotificationMesage from '../Common/NotificationMesage';
import {toast} from 'react-toastify'
import { getQueryString } from '../../utils'
import 'react-phone-number-input/style.css';
import './form.css';

export const VerifyPasswordOrPin = () => {
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const token = urlParams.get('t');
  const token = getQueryString('t')
  const dispatch = useDispatch();
  const history = useHistory();
  const themeColor = useThemeColor();

  // Global store data
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const verifyForgotPasswordOrPinData = useSelector(state => state.verifyForgotPasswordOrPinData, shallowEqual)
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.orgColors.primary : '' }



  useEffect(() => {
    const payload = {
      orgId: orgInfo.orgId,
      token
    }
    dispatch(verifyForgotPassword(payload))
  }, [])

  useEffect(() => {
    if (verifyForgotPasswordOrPinData?.responseCode === '01') {
      history.push('/')
      toast.error(<NotificationMesage message={verifyForgotPasswordOrPinData?.responseMessage} responseStatus={verifyForgotPasswordOrPinData?.responseCode} />, {
        onClose: () => {
          dispatch(resetEndpointDataAction);
        } 
      })
    }
  }, [verifyForgotPasswordOrPinData])
  
    return verifyForgotPasswordOrPinData?.loading ?
        <PageLoader /> :
        verifyForgotPasswordOrPinData?.responseCode === '00' &&
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-10">
            <div>
              <span className="icon" style={themeColor.btnStyle}>
                <i className="fas fa-lock"></i>
              </span>
            </div>
            <h3 className="font-size-h1 mb-5">{`Reset ${verifyForgotPasswordOrPinData?.responseData?.tokenType === 'pin' ? 'Pin' : 'Password'}`}</h3>
            <p className="text-muted font-weight-bold">{`Enter your ${
              verifyForgotPasswordOrPinData?.responseData?.tokenType === 'pin' ? 'pin and confirm pin' : 'password and confirm password'
            }`}</p>
          </div>
          <hr />
          {verifyForgotPasswordOrPinData?.responseData?.tokenType === 'password' ?
          <ResetPasswordForm /> :
          <ResetPinForm />}
        </div>

}
