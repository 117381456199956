import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { actionTypes } from "../../actionTypes";
import { checkNodeEnv } from "../../../utils/authHelpers";
import { defaultAction, apiUrl } from "../../../utils/constants";
import {
  axiosPayload,
  startLoadingAction,
  resetEndpointDataAction,
  stopLoadingAction,
} from "../index";
import NotificationMesage from "../../../components/Common/NotificationMesage";
import { AxiosInstancePublicCp } from "../../../utils/AxiosConfig";

export const cardsOrCheckingProfiles = (payload) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.cardsOrCheckingProfiles("live-api"),
      apiUrl.cardsOrCheckingProfiles("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data) {
        dispatch(defaultAction(actionTypes.CARDS_OR_CHECKING_PROFILES, data));
        dispatch(stopLoadingAction);
      } else {
        dispatch(stopLoadingAction);
        return dispatch(defaultAction());
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
    }
  };
};

export const existingRecurringProfilesPortal = (payload) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.existingRecurringProfilesPortal("live-api"),
      apiUrl.existingRecurringProfilesPortal("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data) {
        dispatch(
          defaultAction(actionTypes.EXISTING_RECURRING_PROFILES_PORTAL, data)
        );
        dispatch(stopLoadingAction);
      } else {
        dispatch(stopLoadingAction);
        return dispatch(defaultAction());
      }
    } catch (error) {
      // console.log(error, 'existing recurring profile error')
    }
  };
};

export const giveWithANewCard = (payload, callback) => {
  return async (dispatch) => {
    // dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.giveWithANewCard("live-api"),
      apiUrl.giveWithANewCard("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
        callback();
        return dispatch(
          defaultAction(actionTypes.GIVE_WITH_NEW_CARD_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.GIVE_WITH_NEW_CARD_FAILURE, data)
        );
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      dispatch(stopLoadingAction);
      return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const setCardDetails = (payload) => (dispatch) => {
  return dispatch(defaultAction(actionTypes.SET_CARD_DETAILS, payload));
};

export const setCardOwnerDetails = (payload) => (dispatch) => {
  return dispatch(defaultAction(actionTypes.SET_CARD_OWNER_DETAILS, payload));
};

export const setCheckDetails = (payload) => (dispatch) => {
  console.log(payload, "setCheckDetails payload");
  return dispatch(defaultAction(actionTypes.SET_CHECK_DETAILS, payload));
};

export const deleteCardOrCheckingPortal = (payload, callback) => {
  // console.log(payload, 'payload')
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.deleteCardOrCheckingPortal("live-api"),
      apiUrl.deleteCardOrCheckingPortal("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      // console.log(data, 'givewithCardResponse')
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        return dispatch(
          defaultAction(
            actionTypes.DELETE_CARD_OR_CHECKING_PORTAL_SUCCESS,
            data
          )
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(
            actionTypes.DELETE_CARD_OR_CHECKING_PORTAL_FAILURE,
            data
          )
        );
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      // dispatch(stopLoadingAction)
      // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const setAsDefaultCardChecking = (payload, callback) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.setAsDefaultCardChecking("live-api"),
      apiUrl.setAsDefaultCardChecking("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.SET_AS_DEFAULT_CARD_CHECKING_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.SET_AS_DEFAULT_CARD_CHECKING_FAILURE, data)
        );
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      // dispatch(stopLoadingAction)
      // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const cancelARecurring = (payload, callback) => {
  console.log(payload, "payload");
  return async (dispatch) => {
    // dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.cancelARecurring("live-api"),
      apiUrl.cancelARecurring("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      console.log(data, "cancelARecurringResponse");
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.CANCEL_A_RECURRING_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.CANCEL_A_RECURRING_FAILURE, data)
        );
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      // dispatch(stopLoadingAction)
      // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const giveWithAnExistingCardCheckingPortal = (
  payload,
  callback,
  isSmartGive = false
) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.giveWithAnExistingCardCheckingPortal("live-api"),
      apiUrl.giveWithAnExistingCardCheckingPortal("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        !isSmartGive &&
          toast.success(
            <NotificationMesage
              message={data.responseMessage}
              responseStatus={data.responseCode}
            />
          );
        callback(data.responseMessage);
        return dispatch(
          defaultAction(
            actionTypes.GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_SUCCESS,
            data
          )
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(
            actionTypes.GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_FAILURE,
            data
          )
        );
      }
    } catch (error) {}
  };
};

export const updateARecurringPortal = (payload, callback) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.updateARecurringPortal("live-api"),
      apiUrl.updateARecurringPortal("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      console.log(data, "data");
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.UPDATE_A_RECURRING_PORTAL_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.UPDATE_A_RECURRING_PORTAL_FAILURE, data)
        );
      }
    } catch (error) {}
  };
};

export const giveWithANewCheck = (payload, callback) => {
  console.log(payload, "payload");
  return async (dispatch) => {
    // dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.giveWithANewCheck("live-api"),
      apiUrl.giveWithANewCheck("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      // console.log(data, 'givewithCardResponse')
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
        callback();
        return dispatch(
          defaultAction(actionTypes.GIVE_WITH_NEW_CHECK_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.GIVE_WITH_NEW_CHECK_FAILURE, data)
        );
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      // dispatch(stopLoadingAction)
      // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const setupANewRecurringPortal = (payload, callback) => {
  // console.log(payload, 'payload')
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.setupANewRecurringPortal("live-api"),
      apiUrl.setupANewRecurringPortal("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      // console.log(data, 'givewithCardResponse')
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.SETUP_A_NEW_RECURRING_PORTAL_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(
          defaultAction(actionTypes.SETUP_A_NEW_RECURRING_PORTAL_FAILURE, data)
        );
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      // dispatch(stopLoadingAction)
      // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const hideGiveOnGivePageAction = (isGivePage) => {
  return async (dispatch) => {
    if (isGivePage) {
      dispatch({ type: actionTypes.HIDE_GIVE_ON_GIVE_PAGE });
    } else dispatch({ type: actionTypes.SHOW_GIVE_ON_GIVE_PAGE });
  };
};

export const giveWithCard = (payload, callback) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.giveWithCard("live-api"),
      apiUrl.giveWithCard("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback(data.responseMessage);
      } else {
        dispatch(stopLoadingAction);
        callback(data);
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      dispatch(stopLoadingAction);
      // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const giveWithCheck = (payload, callback) => {
  return async (dispatch) => {
    // dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.giveWithCheck("live-api"),
      apiUrl.giveWithCheck("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
        callback(data.responseMessage);
        // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        // toast.error(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
        // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_FAILURE, data));
      }
    } catch (error) {
      // console.log(error, 'card or Checking Profile Error')
      dispatch(stopLoadingAction);
      // return dispatch(defaultAction(actionTypes.GIVE_WITH_NEW_CARD_ERRO));
    }
  };
};

export const getGiveMethods = (params, cb) => {
  return async (dispatch) => {
    const url = apiUrl.giveMethods();
    dispatch({
      type: actionTypes.GIVE_METHODS_LOADING_START,
    });
    try {
      const response = await AxiosInstancePublicCp.get(url, { params });
      const data = await response;
      if (data.data.success) {
        dispatch({
          type: actionTypes.GET_GIVE_METHODS_SUCCESS,
          payload: data.data.data,
        });
        cb && cb();
      }
    } catch (error) {
      if (!error.response.success) {
        toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      }
      return dispatch({
        type: actionTypes.GIVE_METHODS_LOADING_STOP,
      });
    }
  };
};
