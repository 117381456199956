import React from 'react'
import { Home } from './Home';
import { useSelector } from 'react-redux';
import {useThemeColor} from '../../utils/customHooks/useThemeColor';
import { ButtonContainer } from '../../components/Common/Button';
import { useHistory } from 'react-router-dom';

export default function EpayUnsuccess() {
    const history = useHistory()
    const processEpayByTokenData = useSelector((state) => state.processEpayByToken)
    const epayData = useSelector(state => state.epayByToken.responseData)
    const themeColor = useThemeColor();
    const backgroundColor = themeColor.color;
    const handleTryAgain = () => {
      if (!epayData?.token) {
        return history.push('/')
      }
        return history.push(`/epay/?t=${epayData?.token}`)
    }

    React.useEffect(() => {
      window.scroll(0,0)
    }, [])
    
  return (
    <Home>
        <div className="login-form login-signin activeForm-wrapper px-3">
        <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={{background: 'red'}}>
              {/* {giveFailure ? <i className="fas fa-exclamation"></i> : */}
              <i className="fas fa-times-circle"></i>
            </span>
          </div> 
          <h3 className="font-size-h3 mb-2">{processEpayByTokenData.responseMessage}</h3>
          <h5 className='font-size-h6 pt-3'>
          {processEpayByTokenData?.responseData?.reason}
          </h5>
          <ButtonContainer
          backgroundColor={backgroundColor}
          onClick={handleTryAgain}
          >
              Try again
          </ButtonContainer>
        </div>
        </div>
    </Home>
  )
}
