import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import IntlCurrencyInput from "react-intl-currency-input";
import { useWindowDimensions } from "../../../utils/customHooks/useWindowDimensions";
import { MAX_AMOUNT_LENGTH } from "../../../utils/constants";
import {
  donatedAmount,
  displayDonatedAmountErrorMsg,
  scrollToAmountErrorMsg,
} from "../../../store/actions";
import "./DonateAmount.scss";
import { InitialGiveForm } from "./InitialGiveForm";

const currencyConfig = {
  locale: "en-US",
  // locale: "en-IN",
  formats: {
    number: {
      USD: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      GBP: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      NGN: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      CAD: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      EUR: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const DonateAmount = ({
  handleBlur,
  defaultWrap = "pt-8 pt-md-0",
  textColor,
}) => {
  const [amountDonated, setAmountDonated] = useState("0.00");
  const ref = useRef();
  const dispatch = useDispatch();
  const { amount, errorMsg, scrollToErrorMsg } = useSelector(
    (state) => state.donatedAmountData
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const selectedPayment = useSelector((state) => state.selectedPaymentOption);
  const { width } = useWindowDimensions();
  const decimalAmount = Number(amountDonated)
    .toFixed(2)
    .toString();
  const handleCloseAlert = () => {
    dispatch(
      displayDonatedAmountErrorMsg({
        amountErrorMsg: "",
        showAmountError: false,
        scrollToAmountInput: false,
      })
    );
    dispatch(scrollToAmountErrorMsg(false));
  };

  // const onValueChange = (value) => {
  //   const to2Decimal = value/100;
  // }

  const scroll = () => {
    ref && ref.current && ref.current.scrollIntoView({ behaviour: "smooth" });
  };
  /**
   *
   * @param {*} event
   * @param {*} value
   * @param {*} maskedValue // masked value (ex: R$1234,56)
   */
  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();
    setAmountDonated(value);
    dispatch(donatedAmount(value));
  };

  useEffect(() => {
    if (amount === "0.00") setAmountDonated(0.0);
    if (scrollToErrorMsg === true) scroll();
    if (errorMsg?.showAmountError === true)
      setTimeout(() => handleCloseAlert(), 7000);
  }, [amount, scrollToErrorMsg, errorMsg?.showAmountError]);
  // console.log(selectedPayment, 'payment');
  // console.log(selectedPayment.currency, 'payment');

  return (
    <>
      <InitialGiveForm />
      {selectedPayment.type && (
        <div
          ref={ref}
          className={`row g-2 donate-wrapper  m-auto ${defaultWrap}`}
        >
          <div
            className={`position-relative w-100  ${
              textColor
                ? "font-size-h4 font-weight-boldest text-center"
                : "give-title text-white mb-10"
            }`}
          >
            Give Amount
          </div>
          <div className="input-holder position-relative m-auto">
            <div className="position-relative">
              <div className="d-inline-block input-container position-relative">
                <span
                  className={`position-absolute usd-text display-5 ${
                    textColor ? "" : "text-white"
                  }`}
                >
                  {selectedPayment
                    ? selectedPayment.currency
                    : orgInfo.defaultCurrency}
                </span>
                <IntlCurrencyInput
                  currency={
                    selectedPayment.currency
                      ? selectedPayment.currency
                      : orgInfo.defaultCurrency
                  }
                  config={currencyConfig}
                  onChange={handleChange}
                  max={MAX_AMOUNT_LENGTH}
                  style={{
                    width: decimalAmount.length + "ch",
                    // fontSize: width < 500 ? '45px' : '85px'
                    // color: textColor ? textColor : undefined,
                  }}
                  className={`amountInput text-center ${
                    textColor ? "" : "text-white"
                  }`}
                  value={amount}
                  onBlur={handleBlur}
                  // type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
                {/* <DonateInput
              onValueChange={onValueChange}
              value={amountDonated}
              className="amountInput text-center text-white"
            /> */}
              </div>
              {errorMsg?.showAmountError && (
                <div
                  className="error-alert position-absolute mt-5 alert alert-custom alert-outline-2x font-weight-bold col-xs-12 col-md-4 text-danger bg-white shadow-lg fade show mb-5 p-2"
                  role="alert"
                >
                  <div className="alert-icon">
                    <i className="text-danger fas fa-exclamation-circle"></i>
                  </div>
                  <div className="alert-text">{errorMsg?.amountErrorMsg}</div>
                  <div className="alert-close" onClick={handleCloseAlert}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="fas fa-times"></i>
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
