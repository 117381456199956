import React from 'react'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import { AntTab, TabPanel, AntTabs, a11yProps} from 'Pages/FollowUp/Listing/Listing';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { deleteUsersFromDepartmentGroup, groupUsers } from 'redux/actions/People';
import ViewGroupUsers from './ViewGroupUsers';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { deleteUsersFromDepartmentGroup, groupUsers } from '../../../store/actions/userDashboardActions/serviceUnit';
import { getUserProfile } from '../../../store/actions';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function MainDepartment({openGroupUsers, contactListGroupWithoutLoader, type}) {
    const dispatch = useDispatch()
    const orgInfo = useSelector(
      (state) => state?.orgInfo.responseData,
      shallowEqual
    );
    const user = JSON.parse(localStorage.getItem('user'));
    const groupUsersData = useSelector((state) => state.groupUsers.responseData) 

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const status = value === 0 ? 'APPROVED' : value === 1 ? 'PENDING': 'REJECTED'
      const getData = () => {
        dispatch(groupUsers({status, groupd: openGroupUsers?.uuid}))
        dispatch(getUserProfile({
          orgId: orgInfo?.orgId,
          userId: user?.responseData?.userId,
        }))
      }
      React.useEffect(() => {
        dispatch(groupUsers({status, groupd: openGroupUsers?.uuid}))
      }, [value])

      const handleDelete = (item, cb) => {
        const payload = {
          orgId: orgInfo.orgId,
          adminUserId: user?.responseData?.userId,
          usersIdList: [item?.legacyUserId],
          id: item?.groupId,
        };
        dispatch(
          deleteUsersFromDepartmentGroup(payload, () => cb())
        );
      }
  return (
    <div>
        <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                {['Approved', 'Pending', 'Rejected']?.map((item, index) => (
                  <AntTab label={item} {...a11yProps(index)} />
                ))}

              </AntTabs>

              <TabPanel value={value} index={0}>
                <ViewGroupUsers handleDelete={handleDelete} status={status} getData={getData} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ViewGroupUsers handleDelete={handleDelete} status={status} getData={getData} />

              </TabPanel>
              <TabPanel value={value} index={2}>
                <ViewGroupUsers handleDelete={handleDelete} status={status} getData={getData} />
              </TabPanel>
    </div>
  )
}

export const AntTabs = withStyles({
    root: {
      borderBottom: "1px solid #e8e8e8",
    },
    indicator: {
      backgroundColor: "#1890ff",
    },
  })(Tabs);
  
  export const AntTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$selected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);
  
  export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(3),
    },
    demo1: {
      backgroundColor: theme.palette.background.paper,
    },
    demo2: {
      backgroundColor: "#2e1534",
    },
  }));
  
  export function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }
  
  export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const matches = useMediaQuery('(max-width:768px)');
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={matches ? 1 : 3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
