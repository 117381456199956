import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { appAccess } from '../../store/actions';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import PageLoader from '../Loader/PageLoader';

export default function AppAccess() {
    const orgInfo = useSelector(
        (state) => state.orgInfo.responseData,
        shallowEqual
      );
      const appAccessLoading = useSelector((state) => state.appAccess)
      const appAccessData = useSelector((state) => state.appAccess.responseData)
    const dispatch = useDispatch()
    const themeColor = useThemeColor();

    React.useEffect(() => {
      dispatch(appAccess({orgId: orgInfo.orgId,}))
    }, [])
    
    console.log(themeColor); 
    console.log(appAccessData); 

    if (appAccessLoading?.loading) {
        return <PageLoader />
    }
  return (
    <div>
        {appAccessData?.returnedRecords?.map((item, index) => (
            <div style={{display: 'flex', justifyContent: 'flex-start', paddingTop: item.label === 'Android Playstore - Click here' ? '1rem': '', fontSize: '16px'}} key={index}>
            {item.label === 'iOS App Store - Click here' ? <i className="fab fa-apple" style={{fontSize: '24px', alignSelf: 'center', color: themeColor.color, paddingRight: '1.5rem'}}></i> : <i className="fab fa-android" style={{fontSize: '24px', alignSelf: 'center', color: themeColor.color, paddingRight: '1.5rem'}}></i>}
                <a href={item?.url}>{item?.label}</a>
            </div>
        ))}
    </div>
  )
}
