import React, {useEffect, useState} from 'react'
import { useThemeColor } from '../../utils/customHooks/useThemeColor'
import { MenuItem, TextField } from '@material-ui/core'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchOrgInfoMasterApp, getOrgInfoList, organizationList, secondOrgList } from '../../store/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';


export default function MasterApp() {
    const themeColor = useThemeColor()
    const dispatch = useDispatch()
    const history = useHistory()
    const organizationListData = useSelector((state) => state.organizationList, shallowEqual);

    const [selectedOrganization, setSelectedOrganization] = useState(null)
    useEffect(() => {
      dispatch(organizationList())
    }, [])

    const handleSelectedChurch = (data) => {
      setSelectedOrganization(data)
      // console.log(data, 'data');
    }

    const handleSubmit = (e) => {
      e.preventDefault()
      if (selectedOrganization.user_portal_url == '0') {
        return window.location.href = `https://www.churchpad.com`
        } 
        dispatch(getOrgInfoList({ orgId: selectedOrganization.org_id }));
        dispatch(secondOrgList({ orgId: selectedOrganization.org_id }));
        dispatch(fetchOrgInfoMasterApp(selectedOrganization.user_portal_url))
        localStorage.setItem('master_app', JSON.stringify(selectedOrganization))
      return window.location.href = `https://${selectedOrganization.user_portal_url}/delete-my-account`
    }
    
  return (
    <div className="login-form login-signin">
        <div className="text-center mb-10 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="las la-clipboard-check"></i>
          </span>
        </div>
        <h3 className="font-size-h1 mb-5">Church Pad</h3>
        <p className="text-muted font-weight-bold">
          Select your organization
        </p>
      </div>
      <hr />
      <form action="" className="form fv-plugins-bootstrap fv-plugins-framework mt-10" onSubmit={handleSubmit}>
        <div className='pb-4'>
          <Autocomplete
            options={organizationListData?.data?.data ?? []}
            getOptionLabel={(option) => option.full_company_name}
            fullWidth
            onChange={(event, newValue) => {
              console.log(newValue);
              handleSelectedChurch(newValue)
            }}
            renderInput={(params) => <TextField {...params} label="Organizations" required variant="outlined" />}
          />
            {/* <TextField
                select
                label='Organizations'
                variant='outlined'
                fullWidth
                name='organization'
            >
                <MenuItem>Please Select</MenuItem>
                {organizationListData?.data?.data?.map((item) => (
                  <MenuItem value={item.org_id} key={item.org_id}>{item.full_company_name}</MenuItem>
                ))}
                </TextField> */}
        </div>
        <button
          type='submit'
          disabled={selectedOrganization === null}
          style={themeColor.btnStyle}
          className={`btn w-100 font-weight-bold py-4 px-9 mb-5`}
        >
          <span className="text-white">
            Submit
            {/* {signupData.loading ? "Please wait..." : "Sign Up"} */}
          </span>
          {/* {signupData && signupData.loading && (
            <span className="ml-3 spinner spinner-white"></span>
          )} */}
        </button>
      </form>
    </div>
  )
}
