import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Radio from "@material-ui/core/Radio";
import { PaymentForm } from "./PaymentForm";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { CreditCardComponent } from "./CreditCardComponent";
import Navigator from "../../Navigator";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import {
  enableGiveViaNav,
  processPaypal,
  selectedPaymentOption,
} from "../../../store/actions";
import "./initialGiveForm.css";
import { Button, TextField, MenuItem, makeStyles } from "@material-ui/core";
import PaymentToggle from "../../PaymentToggle";

export const InitialGiveForm = ({ handleNext }) => {
  const [goBackStep, setGoBackStep] = useState("");
  const dispatch = useDispatch();
  const themeColor = useThemeColor();
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const contributionProcessingEnginesData = useSelector(
    (state) =>
      state.contributionProcessingEnginesData?.responseData?.returnedRecords
  );
  const creditCardInfo = useSelector((state) => state.creditCardInfo);
  const processPaypalData = useSelector((state) => state.processPaypalData);
  const enableGive = useSelector((state) => state.enableGiveViaNav);
  const selectedPayment = useSelector((state) => state.selectedPaymentOption);

  // const contributionEngine = useContributionProcessEngine()

  const amount = useSelector((state) => state.donatedAmountData.amount);

  const [giveMethod, setGiveMethod] = React.useState("");
  const [giveMethodError, setGiveMethodError] = useState(false);
  const [isCardOrCheck, setIsCardOrCheck] = React.useState(false);

  const handleChange = (event) => {
    setGiveMethod(event.target.value);
    // console.log(event.target.value);
    dispatch(enableGiveViaNav({ enable: true }));
    // handleNext()
  };

  const goBack = () => {
    setGoBackStep(true);
  };
  // console.log(selectedPayment, 'selectedPayment');
  // This method return the payment object
  const paymentMethod = (type) => {
    return contributionProcessingEnginesData.filter((item) => {
      if (item.type === type) {
        return item;
      }
    });
  };

  useEffect(() => {
    if (
      processPaypalData?.links &&
      processPaypalData?.links[1].rel === "approve"
    ) {
      window.open(processPaypalData?.links[1].href, "_self");
    }
  }, [processPaypalData.status]);

  const goNext = () => {
    if (
      giveMethod === "CARDANDCHECK" ||
      giveMethod === "CARD" ||
      giveMethod === "CHECK"
    ) {
      setIsCardOrCheck(true);
    }
    if (giveMethod === "PAYPAL") {
      const paypalPayment = paymentMethod("PAYPAL");
      const currencyCode = paypalPayment.currency;
      const payload = {
        cancelUrl: "http://localhost",
        currencyCode,
        email: creditCardInfo.email,
        firstName: creditCardInfo.firstName,
        lastName: creditCardInfo.lastName,
        listOfContributions: [
          {
            amount,
            contributionType: creditCardInfo.contributionType,
            memo: creditCardInfo.memo,
          },
        ],
        mobile: creditCardInfo.mobile,
        orgId: orgInfo.orgId,
        successUrl: `${window.location.origin}/success`,
        totalAmount: amount,
      };
      dispatch(processPaypal(payload));
    }
    if (!giveMethod) {
      return setGiveMethodError(true);
    }
  };

  // console.log(contributionProcessingEnginesData, 'give method');
  useEffect(() => {
    if (giveMethod) {
      setGiveMethodError(false);
      const data = contributionProcessingEnginesData?.filter(
        (item) => item.type === giveMethod
      );
      // console.log(data, "data");
      dispatch(selectedPaymentOption(data[0]));
      // handleNext(data[0])
    }
  }, [giveMethod]);
  const classes = useStyles();
  return (
    <>
      {goBackStep ? (
        <PaymentForm />
      ) : isCardOrCheck ? (
        <CreditCardComponent setPreviousPage={"giveOptionForm"} />
      ) : (
        <div className="login-form login-signin activeForm-wrapper">
          <hr />
          <div className="paymentToggle-container">
            <PaymentToggle />
          </div>
          {contributionProcessingEnginesData?.length > 1 && (
            <div className="d-flex justify-content-center pt-md-4 pt-4">
              {selectedPayment?.disabled ? (
                <p style={{ color: "#fff", fontSize: "1.5rem" }}>
                  Contribution Type: {selectedPayment.type}
                </p>
              ) : (
                <div className="form-width col-sm-8">
                  <TextField
                    label={
                      selectedPayment.type
                        ? "Contribution Option"
                        : "Contribution Options - Please select one"
                    }
                    select
                    name="paymentOption"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    error={enableGive.disable}
                    value={selectedPayment.type || ""}
                    className="select-option text-white"
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssLabel,
                      },
                    }}
                    disabled={selectedPayment.disabled}
                    InputProps={{
                      classes: {
                        root: classes.notchedOutline,
                        focused: classes.notchedOutline,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  >
                    {/* <MenuItem>Please Select one</MenuItem> */}
                    {contributionProcessingEnginesData?.map((item, index) => (
                      <MenuItem key={index} value={item.type}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cssLabel: {
    color: "rgb(255, 255, 255) !important",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "rgb(255, 255, 255) !important",
    color: "rgb(255, 255, 255)",
  },
}));
