import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Empty from "../../../components/Common/Empty";
import { getGiveMethods } from "../../../store/actions/userDashboardActions/give";
import Layout from "../Layout";
import GiveMethodTable from "./GiveMethodTable";
import "./styles.scss";

const GiveMethods = () => {
  const dispatch = useDispatch();

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const { data: giveMethodsData } = useSelector(
    (state) => state?.giveMethods,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getGiveMethods({ org_id: orgInfo.orgId }));
  }, []);

  return (
    <Layout>
      <div className="w-100 give-methods-page">
        <div className="row pt-3">
          <div className="col-md-12 pl-2">
            <div className="table-responsive w-100">
              {giveMethodsData?.length === 0 ? (
                <Empty />
              ) : (
                <GiveMethodTable
                  giveMethodsData={giveMethodsData}
                  showTitle={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GiveMethods;
