import React from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutocompleteComponent({data, labelFunc, label, setValue, value, selectedTeacher, multiple=false, loading=false, disable=false, freeSolo=true, defaultValue=null, ...rest }) {
    // console.log(data);
    return (
        <div style={{width: '100%'}}>
           <Autocomplete
                id="combo-box-demo"
                value={value}
                options={data}
                getOptionLabel={labelFunc}
                defaultValue={defaultValue}
                onChange={(event, newValue) => {
                    // console.log(newValue, 'value complete');
                    selectedTeacher(newValue)
                  }}
                  freeSolo={freeSolo}
                  filterSelectedOptions
                  size='small'
                  multiple={multiple}
                  loading={loading}
                  disabled={disable}
                  {...rest}
                // style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label={label} variant="outlined" onChange={(e) => setValue(e.target.value)} />}
            /> 
        </div>
    )
}
