const useOnBoarding = () => {
    const isOnBoarding = window.location.hostname === "onboarding.churchpad.com";
    var path = window.location.pathname;
    var parts = path.split("/");
    var churchName = parts[1];
  
    return {
      isOnBoarding,
      churchName,
    };
  };
  
  export default useOnBoarding;