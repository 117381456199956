import React from "react";
import ModalComponent from "../../Common/Modal";
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import "./Prayerwall.scss";

export default function ViewUserImage({
  isOpen,
  handleCloseModal,
  image,
  backgroundColor,
  title
}) {
  return (
    <div>
      <ResizeableModalWithoutBtn
        modalState={isOpen}
        resetModalState={handleCloseModal}
        title={title}
        backgroundColor={backgroundColor}
        hideFooter={true}
        useType='prayerwalls'
        maxWidth="xs"
        fullWidth={true}
      >
        <div className="d-flex justify-content-center view-image">
          <img
            src={image}
            alt="user"
            // className="rounded-circle"
            onError={(e) => {
              if (e.target.src !== image)
                e.target.src =
                  "https://dev.churchpad.com/xTextersImageFilesLoc/i/2/vi2__1606075062__yawxmoxnukq8__ddesign9.png";
            }}
          />
        </div>
      </ResizeableModalWithoutBtn>
    </div>
  );
}
