import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";

import { Grid, Card, Tooltip, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
// import { ViewImage } from "components/Prayerwalls/PrayerTable";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getUserDetails } from "../../../utils/authHelpers";
import { guestActivityLog } from "../../../store/actions/userDashboardActions/followup";
import ViewUserImage from "../Prayer/ViewUserImage";

export default function ViewLog({modal=false}) {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openImage, setOpenImage] = useState({
    show: false,
    item: "",
  });
  const getActivityLogData = useSelector(
    (state) => state.getActivityLogData?.responseData
  );
  const user = getUserDetails();
  const orgInfo = useSelector((state) => state.orgInfo.responseData);
  useEffect(() => {
    if (!getActivityLogData && !modal) {
      dispatch(
        guestActivityLog({
          orgId: orgInfo?.orgId,
          adminUserId: user?.userId,
          recordId: location.state.recordId,
          guestId: location.state.guestId,
        })
      );
    }
  }, [getActivityLogData]);

  const handleback = () => {
    history.push({
      pathname: "/follow_up",
      state: id,
    });
  };
  console.log(id, "id");
  const handleViewImage = (data) => {
    console.log(data, 'data');
    setOpenImage({ ...openImage, show: true, item: data });
  };

  const closeViewImage = () => {
    setOpenImage({ ...openImage, show: false, item: "" });
  };
  return (
    <div className="px-4 pt-5">
      <Grid container spacing={3}>
        {/* <Grid item xs={3}>
        </Grid> */}
        <Grid item xs={4}>
          <div className="d-flex">
            {!modal && (
              <div style={{ alignSelf: "center" }} className="px-3">
                <IconButton onClick={handleback}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
            )}
            <div className="d-block">
              <p className="font-weight-bold">Full Name</p>
              <p className="">{getActivityLogData?.guestFullName}</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="d-block">
            <p className="font-weight-bold">Email</p>
            <p className="">{getActivityLogData?.email}</p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="d-block">
            <p className="font-weight-bold">Phone</p>
            <p className="">{getActivityLogData?.phone}</p>
          </div>
        </Grid>
      </Grid>
      {getActivityLogData?.activitiesLog?.map((item, i) => (
        <div className="py-2">
            <Card elevation={6} className="px-6 py-2 mb-1" key={i}>
            {/* {item && editorData(item?.activityMessage)} */}
            <div className="d-flex col-gap-2  ">
                <div>
                <img
                    style={{ height: "1.5rem" }}
                    src={item.activityIconUrl}
                    alt=""
                />
                </div>
                <div className="pl-6">
                <p>{item?.activityLog}</p>
                <p>Type: {item?.activityType}</p>
                <p>
                    Date: {moment(item.activityDate).format("MMM D, YYYY h:mm A")}
                </p>
                <div className="msg">
                    {item?.activityMessage && (
                    <p className="mb-1">Message: {item?.activityMessage}</p>
                    )}
                </div>
                {item?.activityAttachedFiles?.length > 0 &&
                    item.activityAttachedFiles?.map((option, index) =>
                    option?.iconType === "image" ? (
                        <Tooltip
                        title={`${option.filename}`}
                        placement="top"
                        key={index}
                        >
                        <span className="px-2">
                            <img
                            key={index}
                            style={{ height: "2rem", cursor: "pointer" }}
                            src={option.iconUrl}
                            alt=""
                            onClick={() => handleViewImage(option.documentUrl)}
                            />
                        </span>
                        </Tooltip>
                    ) : (
                        <a
                        href={`${option.documentUrl}`}
                        download={option.filename}
                        target="_blank"
                        rel="noreferrer"
                        >
                        <Tooltip
                            title={`${option.filename}`}
                            placement="top"
                            key={i}
                        >
                            <img
                            style={{ height: "2rem" }}
                            src={option.iconUrl}
                            alt=""
                            // onClick={() => {
                            //   openFiles(
                            //     item.documentUrl,
                            //     item.iconType
                            //   );
                            // }}
                            // className={
                            //   isValidType(item.iconType) &&
                            //   `cursor-pointer`
                            // }
                            />
                        </Tooltip>
                        </a>
                    )
                    )}
                </div>
            </div>
            </Card>
        </div>
      ))}
      {openImage?.show && <ViewUserImage 
        isOpen={openImage?.show} 
        image={openImage?.item}
        handleCloseModal={closeViewImage}
      />}
    </div>
  );
}
