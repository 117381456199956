import { actionTypes } from '../../actionTypes';

export const cardsOrCheckingProfilesData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.CARDS_OR_CHECKING_PROFILES:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export const existingRecurringProfilesPortalData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.EXISTING_RECURRING_PROFILES_PORTAL:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export const newCardDetails = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.SET_CARD_DETAILS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.SET_CARD_OWNER_DETAILS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}

export const newCheckDetails = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.SET_CHECK_DETAILS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.SET_CARD_DETAILS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}

export const giveWithANewCardData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.GIVE_WITH_NEW_CARD_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.GIVE_WITH_NEW_CARD_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case actionTypes.RESET_ENDPOINT_DATA:
      return {}
    default:
      return state;
  }
}

export const giveWithANewCheckData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.GIVE_WITH_NEW_CHECK_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.GIVE_WITH_NEW_CHECK_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case actionTypes.RESET_ENDPOINT_DATA:
      return {}
    default:
      return state;
  }
}

export const deleteCardOrCheckingPortalData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.DELETE_CARD_OR_CHECKING_PORTAL_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.DELETE_CARD_OR_CHECKING_PORTAL_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case actionTypes.RESET_ENDPOINT_DATA:
      return {}
    default:
      return state;
  }
}

export const setAsDefaultCardCheckingData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.SET_AS_DEFAULT_CARD_CHECKING_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.SET_AS_DEFAULT_CARD_CHECKING_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case actionTypes.RESET_ENDPOINT_DATA:
      return {}
    default:
      return state;
  }
}

export const cancelARecurringData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.CANCEL_A_RECURRING_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.CANCEL_A_RECURRING_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case actionTypes.RESET_ENDPOINT_DATA:
      return {}
    default:
      return state;
  }
}

export const setupANewRecurringPortalData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.SETUP_A_NEW_RECURRING_PORTAL_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.SETUP_A_NEW_RECURRING_PORTAL_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export const updateARecurringPortalData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.UPDATE_A_RECURRING_PORTAL_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.UPDATE_A_RECURRING_PORTAL_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export const giveWithAnExistingCardCheckingPortalData = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.GIVE_WITH_AN_EXISTING_CARD_CHECKING_PORTAL_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case actionTypes.RESET_ENDPOINT_DATA:
      return {}
    default:
      return state;
  }
}

export const hideGiveOnGivePage = (state = {hideGiveBtn: false}, action) => {
  switch(action.type) {
    case actionTypes.HIDE_GIVE_ON_GIVE_PAGE:
      return ({...state, hideGiveBtn: true});
    case actionTypes.SHOW_GIVE_ON_GIVE_PAGE:
      return ({...state, hideGiveBtn: false});
    default:
      return state;
  }
}


export const giveMethods = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_METHODS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GIVE_METHODS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_GIVE_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
