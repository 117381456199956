import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { getPrivacyPolicyHtml, getPrivacyPolicyHtmlWithoutPayload } from '../../store/actions'

export default function Privacy({privacy_host}) {
  const dispatch = useDispatch()

  const privacyRoute = window.location.hostname === 'privacy.churchpad.com'
  
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const getPrivacyPolicyHtmlData = useSelector(
    (state) => state.getPrivacyPolicyHtml.responseData,
    shallowEqual
  );

  React.useEffect(() => {
    if (privacyRoute) {
      dispatch(getPrivacyPolicyHtmlWithoutPayload())
    } else {
      dispatch(getPrivacyPolicyHtml({orgId: orgInfo?.orgId}))
    }
  }, [])
  
  return (
    <div className={privacyRoute ? 'privacy_host_container' : ''}>
        <h1 style={{textAlign: 'center', fontSize: '2rem', fontWeight: 'bolder'}}>{getPrivacyPolicyHtmlData?.header}</h1>
        <p style={{textAlign: 'center', paddingBottom: '1rem'}}>{getPrivacyPolicyHtmlData?.subHeader}</p>
        <div dangerouslySetInnerHTML={{__html: getPrivacyPolicyHtmlData?.htmlContent}} />
    </div>
  )
}
