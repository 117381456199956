import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import moment from "moment";
import {
  Flutterwave,
  paystack,
  selectedPaymentOption,
  setGiveInfo,
  StripePayment,
} from "../../../store/actions";
import { isEmpty } from "../../../utils";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import "./PaymentForm.scss";
import { InitialGiveForm } from "./InitialGiveForm";
import {
  displayDonatedAmountErrorMsg,
  scrollToAmountErrorMsg,
} from "../../../store/actions";
// components
import Button from "@material-ui/core/Button";
import { GiveOption } from "./GiveOption";
import RecurringGivingPlan from "./RecurringGivingPlan";
import Navigator from "../../Navigator";
import { ActiveForm } from "./ActiveForm";
import { processPaypal } from "../../../store/actions";
import { CreditCardComponent } from "./CreditCardComponent";
import { useContributionProcessEngine } from "../../../utils/customHooks/useContributionProcessEngine";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import ExistingCardOrCheck from "./ExistingCardOrCheck";

const PaymentModule = ({ primaryBgColor, showPaymentOptionPage }) => (
  <div className="text-center mb-10 mb-lg-10">
    <div>
      <span className="icon" style={primaryBgColor}>
        <i className="las la-donate"></i>
      </span>
    </div>
    {showPaymentOptionPage.hasMultiple && (
      <h3 className="font-size-h3 mb-2">Pick Give Type</h3>
    )}
  </div>
);

export const PaymentForm = () => {
  const [active, setActive] = useState(true);
  const [activeRec, setActiveRec] = useState(false);
  const [goBackStep, setGoBackStep] = useState(false);
  const [goNextStep, setGoNextStep] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedFreqValue, setSelectedFreqValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [giveError, setGiveError] = useState(false);
  const [showMultiple, setShowMultiple] = useState({});
  const [isCard, setIsCard] = useState(false);
  const dispatch = useDispatch();
  const themeColor = useThemeColor();
  const primaryBgColor = themeColor.btnStyle;
  // console.log(showPaymentOptionPage);
  const orgInfo = useSelector((state) => state.orgInfo.responseData);
  const amount = useSelector((state) => state.donatedAmountData.amount);
  const processPaypalData = useSelector((state) => state.processPaypalData);
  const contributionProcessingEnginesData = useSelector(
    (state) =>
      state.contributionProcessingEnginesData?.responseData?.returnedRecords
  );
  const selectedPayment = useSelector((state) => state.selectedPaymentOption);
  const showPaymentOptionPage = useContributionProcessEngine(selectedPayment);

  // useEffect(() => {
  //   if(processPaypalData?.links && processPaypalData?.links[1].rel === 'approve'){
  //     window.open(processPaypalData?.links[1].href, '_self')
  //   }
  // }, [processPaypalData.status])

  const creditCardInfo = useSelector((state) => state.creditCardInfo);

  const handleClick = () => {
    setActive((prevState) => ({ active: !prevState.active }));
    setActiveRec(false);
  };
  const handleClickRec = () => {
    setActiveRec((prevState) => ({ activeRec: !prevState.activeRec }));
    setActive(false);
  };
  const goBack = () => {
    setGoBackStep(true);
  };

  const handleChangeInColor = (status) => {
    if (status) {
      return {
        backgroundColor: themeColor.color,
        color: "#fff",
      };
    }
  };

  // console.log(selectedPayment, 'payment');

  const goNext = () => {
    // console.log(selectedValue, 'selected value');
    const errors = {};
    let payload = {};
    if (active) {
      if (selectedValue === "" || selectedValue === null) {
        errors.giveType = "Select a Give Type from the list";
      }
      if (errors) setGiveError(errors);
      if (isEmpty(errors)) {
        dispatch(
          setGiveInfo({
            contributionType: selectedValue,
            recurring: "once",
            isActive: active,
          })
        );
        // if(showPaymentOptionPage.hasMultiple) {
        //   setGoNextStep(true)
        //   return setShowMultiple({ paymentList: 'multiple'})
        // } else {
        if (amount < parseInt(selectedPayment.minimumAmount)) {
          const errorAmount = `Amount cannot be less ${selectedPayment.minimumAmount} ${selectedPayment.currency}`;
          dispatch(
            displayDonatedAmountErrorMsg({
              amountErrorMsg: errorAmount,
              showAmountError: true,
            })
          );
          return dispatch(scrollToAmountErrorMsg(true));
        } else if (amount > parseInt(selectedPayment.maximumAmount)) {
          const errorAmount = `Amount cannot be more than ${selectedPayment.maximumAmount} ${selectedPayment.currency}`;
          return dispatch(
            displayDonatedAmountErrorMsg({
              amountErrorMsg: errorAmount,
              showAmountError: true,
            })
          );
        }
        setShowMultiple({
          paymentList: "single",
          type: showPaymentOptionPage.type,
        });
        return setGoNextStep(true);
        // }
      }
    } else {
      // console.log(!active, 'inactive')
      if (selectedValue === "" || selectedValue === null) {
        errors.giveType = "Select a Give Type from the list";
      }
      if (selectedFreqValue === "" || selectedFreqValue === null) {
        errors.freqValue = "Select a Give Frequency";
      }
      if (startDate === null || startDate === "") {
        errors.startDate = "Select a Start Date";
      }
      if (errors) {
        setGiveError(errors);
      }
      if (isEmpty(errors)) {
        payload = {
          contributionType: selectedValue,
          recurring: selectedFreqValue,
          recurringStartDate: startDate,
          isActive: active,
        };
        dispatch(setGiveInfo(payload));
        if (showPaymentOptionPage.hasMultiple) {
          setGoNextStep(true);
          return setShowMultiple({ paymentList: "multiple" });
        } else {
          if (
            amount <
            parseInt(showPaymentOptionPage.paymentList[0].minimumAmount)
          ) {
            const errorAmount = `Amount cannot be less ${showPaymentOptionPage.paymentList[0].minimumAmount} ${showPaymentOptionPage.paymentList[0].currency}`;
            dispatch(
              displayDonatedAmountErrorMsg({
                amountErrorMsg: errorAmount,
                showAmountError: true,
              })
            );
            return dispatch(scrollToAmountErrorMsg(true));
          } else if (
            amount >
            parseInt(showPaymentOptionPage.paymentList[0].maximumAmount)
          ) {
            const errorAmount = `Amount cannot be more than ${showPaymentOptionPage.paymentList[0].maximumAmount} ${showPaymentOptionPage.paymentList[0].currency}`;
            return dispatch(
              displayDonatedAmountErrorMsg({
                amountErrorMsg: errorAmount,
                showAmountError: true,
              })
            );
          }

          setShowMultiple({
            paymentList: "single",
            type: showPaymentOptionPage.type,
          });
          return setGoNextStep(true);
        }
      }
    }
  };

  const getSelectValue = (value) => {
    // console.log(value, 'value')
    setSelectedValue(value);
  };
  const getFrequencyValue = (value) => {
    setSelectedFreqValue(value);
  };
  const getStartDate = (value) => {
    const formattedDate = moment(value).format("MM/DD/YYYY");
    setStartDate(formattedDate);
  };

  const setPaymentView = () => {
    if (goNextStep && !goBackStep) {
      // if(showMultiple.paymentList === 'multiple') return <InitialGiveForm />;
      // else{
      // handlePaymentOptionType(selectedPayment?.type)
      if (
        selectedPayment.type === "CARDANDCHECK" ||
        selectedPayment.type === "CARD" ||
        selectedPayment.type === "CHECK" ||
        selectedPayment.type === "PPS"
      )
        return setIsCard(true);

      if (
        selectedPayment.type !== "CARDANDCHECK" ||
        selectedPayment.type !== "CARD" ||
        selectedPayment.type !== "CHECK" ||
        selectedPayment.type !== "PPS"
      )
        return handlePaymentOptionType(selectedPayment?.type);

      // }
    }
  };

  // This method return the payment object
  const paymentMethod = (type) => {
    return contributionProcessingEnginesData.filter((item) => {
      if (item.type === type) {
        return item;
      }
    });
  };

  const handlePaypalPayment = () => {
    // const paypalPayment = paymentMethod('PAYPAL')
    if (amount <= 0) {
      dispatch(
        displayDonatedAmountErrorMsg({
          amountErrorMsg: "Amount is Reqquired",
          showAmountError: true,
        })
      );
      return dispatch(scrollToAmountErrorMsg(true));
      // setError('Enter a Give Amount')
    }
    if (!selectedValue) {
      // return;
      return setGiveError({ giveType: "Select a Contribution Type" });
    }
    const currencyCode = selectedPayment.currency;
    const payload = {
      cancelUrl: `${window.location.origin}/give`,
      currencyCode,
      email: creditCardInfo.email,
      firstName: creditCardInfo.firstName,
      lastName: creditCardInfo.lastName,
      listOfContributions: [
        {
          amount,
          contributionType: selectedValue,
          memo: creditCardInfo.memo,
        },
      ],
      mobile: creditCardInfo.mobile,
      orgId: orgInfo.orgId,
      successUrl: `${window.location.origin}/success`,
      totalAmount: amount,
    };
    // selectedPayment.type === "PAYSTACK" ? dispatch(paystack(payload)) : selectedPayment.type === "FLUTTERWAVE" ? dispatch(Flutterwave(payload)) : null
    dispatch(processPaypal(payload));
    // handlePaymentOptionType(selectedPayment?.type, payload)
  };

  const handlePaymentOptionType = (type) => {
    if (!amount) {
      return;
      // setError('Enter a Give Amount')
    }
    if (!selectedValue) {
      // return;
      return setGiveError({ giveType: "Select a Contribution Type" });
    }
    const currencyCode = selectedPayment.currency;
    const payload = {
      cancelUrl: `${window.location.origin}/payment_status/give/failure`,
      currencyCode,
      email: creditCardInfo.email,
      firstName: creditCardInfo.firstName,
      lastName: creditCardInfo.lastName,
      listOfContributions: [
        {
          amount,
          contributionType: selectedValue,
          memo: creditCardInfo.memo,
        },
      ],
      mobile: creditCardInfo.mobile,
      orgId: orgInfo.orgId,
      successUrl: `${window.location.origin}/payment_status/give/success`,
      totalAmount: amount,
    };

    if (type === "PAYSTACK") {
      return dispatch(paystack(payload));
    }
    if (type === "FLUTTERWAVE") {
      return dispatch(Flutterwave(payload));
    }
    if (type === "PAYPAL") {
      return dispatch(processPaypal(payload));
    }
    if (type === "STRIPE") {
      return dispatch(StripePayment(payload));
    }

    // switch (type) {
    //   case 'PAYSTACK':
    //     return dispatch(paystack(payload))
    //   case 'FLUTTERWAVE':
    //     return dispatch(Flutterwave(payload))
    //   case 'PAYPAL':
    //     return dispatch(processPaypal(payload))

    //   default:
    //     break;
    // }
  };

  useEffect(() => {
    if (goNextStep) {
      setPaymentView(selectedPayment?.type);
    }
  }, [goNextStep]);
  useEffect(() => {
    dispatch(selectedPaymentOption({ ...selectedPayment, disabled: true }));
    return () =>
      dispatch(selectedPaymentOption({ ...selectedPayment, disabled: true }));
  }, []);

  // displays paypal button when contributionEngine has only one content
  const displayPaymentBtn = () => {
    // if (!showPaymentOptionPage.hasMultiple) {
    if (showPaymentOptionPage.type === "PAYPAL")
      return (
        <Button
          onClick={handlePaypalPayment}
          className={`btn btn-lg py-3 py-4 text-white`}
          style={themeColor.btnStyle}
        >
          Give via PayPal{" "}
          {processPaypalData.loading && (
            <span className="ml-3 spinner spinner-white"></span>
          )}
        </Button>
      );
    // }
  };

  return (
    <>
      {goBackStep && <ActiveForm />}
      {isCard && <CreditCardComponent />}
      {/* {setPaymentView()} */}
      {!goBackStep && !goNextStep && (
        <div className="container-md login-form login-signin activeForm-wrapper">
          <PaymentModule
            primaryBgColor={primaryBgColor}
            showPaymentOptionPage={showPaymentOptionPage}
          />
          {showPaymentOptionPage.type === "PAYPAL" && (
            <div className="text-center">
              <img
                height="70px"
                src="images/paypal_label.png"
                alt="paypal_banner"
              />
            </div>
          )}
          <hr />
          <Form>
            <div>
              <p className="my-5 ">Give Option</p>
              <div
                className="btn-group w-100 buttons btn-group-lg form-control-color text-center border border-1 d-flex align-items-center justify-content-center"
                role="group"
                aria-label=""
              >
                <div
                  className={`p-4 w-100 fix-mobile-line-height`}
                  onClick={handleClick}
                  style={handleChangeInColor(active)}
                >
                  <i className="las la-donate"></i> Give Once
                </div>
                <div
                  className={`p-4 w-100`}
                  onClick={handleClickRec}
                  style={handleChangeInColor(activeRec)}
                >
                  {" "}
                  <i className="las la-sync"></i> Recurring Giving
                </div>
              </div>
            </div>
            {active ? (
              <GiveOption
                getSelectValue={getSelectValue}
                giveError={giveError}
              />
            ) : (
              <RecurringGivingPlan
                getFrequencyValue={getFrequencyValue}
                getSelectValue={getSelectValue}
                giveError={giveError}
                getStartDate={getStartDate}
                selectedFreqValue={selectedFreqValue}
              />
            )}
            <Navigator
              goBack={goBack}
              goNext={goNext}
              isCustomBtn={showPaymentOptionPage.type === "PAYPAL"}
              className="py-4"
            >
              {displayPaymentBtn()}
            </Navigator>
          </Form>
        </div>
      )}
      {goNextStep && !isCard && (
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <h3>Please wait...</h3>
        </div>
      )}
    </>
  );
};
