import React from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "./Prayer.module.scss";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { addPrayerWalls, prayerWallsWithoutLoader } from "../../../store/actions/userDashboardActions/PrayerWall";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import NotificationMesage from "../../Common/NotificationMesage";
import { TextField, MenuItem } from "@material-ui/core";
import { ButtonContainer } from "../../Common/Button";
import CustomizedMenu from "../../Common/DropDownList";
import ImageUpload from "./Media/ImageUpload";
import VideoUpload from "./Media/VideoUpload";
import AudioUpload from "./Media/AudioUpload";
import CameraComponent from "./Media/Camera";
import AudioRecorder from "./Media/AudioRecorder";
import VideoRecorder from "./Media/VideoRecorder";
import ModalComponent from "../../Common/Modal";
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import "./Prayerwall.scss";
import CustomizedMenus from './Media/AddMediaBtn'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import Alert from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/core/styles';
import {dataURLtoFile} from '../../../utils/useableFunctions'

export default function NewPrayerWalls({
  setIsModal,
  userId,
  type = "prayerRequest",
  handleNotification,
  onHide,
  setPrayer,
  prayer
}) {
  const themeColor = useThemeColor();
  const { register, handleSubmit, watch, errors, control } = useForm({
    mode: "onBlur",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [cameraOpen, setCameraOpen] = React.useState(false);
  const [audioOpen, setAudioOpen] = React.useState(false);
  const [videoOpen, setVideoOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState({
    show: false,
    type: "",
  });
  const [image, setImage] = React.useState([])
  const [audioUrl, setAudioUrl] = React.useState(null)
  const [videoUrl, setVideoUrl] = React.useState(null)
  // const [isOpen, setIsOpen] = React.useState({})
  const theme = useTheme();

  const category = useSelector(
    (state) => state.orgInfoList.responseData.fellowshipWallsCategories,
    shallowEqual
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const addPrayerWallsLoading = useSelector(
    (state) => state.addPrayer.loading,
    shallowEqual
  );
  const addPrayerWallsData = useSelector(
    (state) => state.addPrayer,
    shallowEqual
  );

  const primaryBgColor = {
    backgroundColor: orgInfo ? orgInfo?.colorThemes[0]?.primary : "",
    border: "none",
  };

  const handleComplete = () => {
    setIsModal(false)
    const newPrayerObj = { newPrayer: false, top10: false, myPrayer: false };
    setPrayer(newPrayerObj)
    setPrayer(prevState => ({...prevState, newPayer: false}))
    dispatch(prayerWallsWithoutLoader({
      orgId: orgInfo.orgId,
      userId: userId.userId,
      fellowshipType: type, 
      mineOnlySwitch: 'false'
    }))
  }
  const backgroundColor = themeColor.color;
  const backgroundColorSecondary = themeColor.secondaryColor;
  const dispatch = useDispatch();
  const watchTextField = watch("messageOrMediaUrl");
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('orgId', orgInfo.orgId);
    formData.append('userId', userId.userId);
    formData.append('fellowshipType', type);
    formData.append('categoryName', data.categoryName)
    formData.append('message', data.messageOrMediaUrl)
    formData.append('markAsAnonymous', data.markAsAnonymous)
    formData.append('image1', image[0])
    formData.append('image2', image[1])
    formData.append('image3', image[2])
    formData.append('image4', image[3])
    formData.append('audio', audioUrl);
    formData.append('video', videoUrl)
    dispatch(addPrayerWalls(formData, handleComplete))
  };

  let memoColor;
  if (watchTextField?.length >= 280 && watchTextField?.length < 295) {
    memoColor = "text-warning";
  } else if (watchTextField?.length >= 295) {
    memoColor = "text-danger";
  } else {
    memoColor = "text-muted";
  }

  const cameraOpenFunc = () => {
    setCameraOpen(true);
  };

  const handleModalOpen = (type) => {
    setOpenModal((prevState) => ({ ...prevState, show: true, type }));
  };

  const handleCloseModal = () => {
    setOpenModal({ ...openModal, show: false, type: "" });
    setImage([])
    setAudioUrl(null)
    setVideoUrl(null)
  };

  const handleUpload = () => {
    setOpenModal({ ...openModal, show: false, type: "" });

  }
  const menuList = [
    {
      iconName: "far fa-images icon-size",
      title: "Image",
      func: () => handleModalOpen("image"),
    },
    {
      iconName: "fas fa-microphone icon-size",
      title: "Audio",
      func: () => handleModalOpen("audio"),
    },
    {
      iconName: "fas fa-video icon-size",
      title: "Video",
      func: () => handleModalOpen("video"),
    },
  ];

  // console.log(audioUrl && Object.entries(audioUrl), 'audio')
  // console.log(audioUrl && Object.entries(audioUrl).length, 'audiossss')
  // console.log(Object.keys(videoUrl), 'audio')
  // console.log(image.length, 'image');
  // console.log(audioUrl);

  const updateUploadedFiles = (files) => setImage(files)
  const UploadedFiles = (files) => setImage(files)
  const updateUploadedFilesVideo = (files) => setVideoUrl(files);
  const updateUploadedFilesAudio = (files) => setAudioUrl(files);
  const UploadedFilesVideo = (files) => setVideoUrl(files);
  const UploadedFilesAudio = (files) => setAudioUrl(files);
  // setNewUserInfo({ ...newUserInfo, profileImages: files });
  return (
    <div>
      <div className={styles.formContainer}>
      <h6 className={styles.headerText}>
        <i class="fa fa-images"></i>
        <span className="pl-2 font-size-lg">
         {type === 'prayerRequest' ? 'Prayer is the link that connects us with GOD' : 'And they overcame him by the blood of the Lamb, and by the word of their testimony; and they loved not their lives unto the death.'}
        </span>
      </h6>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="fv-plugins-icon-container mb-8">
            <div class="position-relative">
              <Controller
                as={
                  <TextField
                    id="outlined-select-catergory"
                    select
                    label="Category"
                    name="categoryName"
                    // onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    // error={giveError.giveType}
                    // value={selectValue || ""}
                  >
                    <MenuItem value="">Please select One</MenuItem>
                    {category.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                }
                name="categoryName"
                control={control}
                rules={{ required: true }}
              />
            </div>
            {errors.categoryName && (
              <span className={styles.formErrors}>Category is Required</span>
            )}
          </div>
          <div className="fv-plugins-icon-container mb-8">
            <div className="position-relative">
              <Controller
                as={
                  <TextField
                    id="exampleFormControlTextarea1"
                    rows="5"
                    label={type === 'prayerRequest' ? 'Your Request' : 'Your Testimony'}
                    multiline
                    variant="outlined"
                    name="messageOrMediaUrl"
                    fullWidth
                    error={errors.messageOrMediaUrl}
                    inputProps={{ maxLength: 300, minLength: 5 }}
                  />
                }
                name="messageOrMediaUrl"
                control={control}
                rules={{ required: true }}
              />
            </div>

            <p className={`${memoColor}`}>{`${
              watchTextField?.length > 0 ? 300 - watchTextField?.length : 300
            } characters remaining`}</p>
            {errors.messageOrMediaUrl && (
              <span className={styles.formErrors}>
                {type === 'prayerRequest' ? 'Prayer Request is Required' : 'Your Testimony is Required'}
              </span>
            )}
          </div>
          <div className='pt-0 pb-8'> 
            <CustomizedMenus
                menuList={menuList}
                // menuIcon={<span>Add Media</span>}
                backgroundColor={backgroundColor}
            />
          </div>
          <div className="d-flex justify-content-between">
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="markAsAnonymous"
                ref={register}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Mark as Anonymous
              </label>
            </div>
            {/* <div>
              <CustomizedMenu
                menuList={menuList}
                menuIcon={<i class="fas fa-paperclip icon-size"></i>}
              />
            </div> */}
          </div>
          <ResizeableModalWithoutBtn
            className="ml-5"
            modalState={openModal.show}
            title={
              openModal.type === "image"
                ? "Image Upload"
                : openModal.type === "audio"
                ? "Audio Upload"
                : "Video Upload"
            }
            resetModalState={handleCloseModal}
            hideFooter={true}
            maxWidth='xs'
            fullWidth={true}
            useType='prayerwalls'
          >
            {openModal.type === "image" && (
              <>
                <div className="d-flex justify-content-between attachment-modal py-3">
                  <p className="text-header">
                    {!cameraOpen ? "Upload Image" : "Snap Image"}
                  </p>
                  <div className="d-flex py-3">
                    <div
                      className="file-container"
                      onClick={() => setCameraOpen(false)}
                    >
                      <i className="fas fa-desktop icon-size"></i>
                      <p>My Files</p>
                    </div>
                    <div
                      className="file-container"
                      onClick={() => setCameraOpen(true)}
                    >
                      <i className="fas fa-camera icon-size"></i>
                      <p>Camera</p>
                    </div>
                  </div>
                </div>
                <div>
                  {!cameraOpen && openModal.type === "image" ? (
                    <ImageUpload
                      accept=".jpg,.png,.jpeg,.PNG,.JPG,.JPEG"
                      label={
                        type === "prayerRequest"
                          ? "Prayer Image(s)"
                          : "Testimony Image(s)"
                      }
                      multiple
                      updateFilesCb={updateUploadedFiles}
                      maxFileSizeInBytes={10000000}
                    />
                  ) : (
                    <div className='py-4'>
                      {/* <CameraComponent updateFilesCb={updateUploadedFiles} /> */}
                      <p>This is not supported yet. Kindly Upload from your device</p>
                    </div>
                  )}
                </div>
              </>
            )}
            {openModal.type === "audio" && (
              <>
                <div className="d-flex justify-content-between attachment-modal py-3">
                  <p className="text-header">
                    {!audioOpen ? "Upload Audio" : "Record Audio"}
                  </p>
                  <div className="d-flex py-3">
                    <div
                      className="file-container"
                      onClick={() => setAudioOpen(false)}
                    >
                      <i className="fas fa-desktop icon-size"></i>
                      <p>My Files</p>
                    </div>
                    <div
                      className="file-container"
                      onClick={() => setAudioOpen(true)}
                    >
                      <i className="fas fa-microphone icon-size"></i>
                      <p>Audio</p>
                    </div>
                  </div>
                </div>
                <div>
                  {!audioOpen && openModal.type === "audio" ? (
                    <AudioUpload
                      label={
                        type === "prayerRequest"
                          ? "Prayer Audio"
                          : "Testimony Audio"
                      }
                      accept=".mp4,.mp3"
                      updateFilesCb={updateUploadedFilesAudio}
                      maxFileSizeInBytes={20000000}
                    />
                  ) : (
                    <div className='py-3' style={{width: '330px'}}>
                      {/* <AudioRecorder updateFilesCb={UploadedFilesAudio} backgroundColorSecondary={backgroundColorSecondary} backgroundColor={backgroundColor}/> */}
                      <p>This is not supported yet. Kindly Upload from your device</p>
                    </div>
                  )}
                </div>
              </>
            )}
            {openModal.type === "video" && (
              <>
                <div className="d-flex justify-content-between attachment-modal py-3">
                  <p className="text-header">
                    {!videoOpen ? "Upload Video" : "Record Video"}
                  </p>
                  <div className="d-flex py-3">
                    <div
                      className="file-container"
                      onClick={() => setVideoOpen(false)}
                    >
                      <i className="fas fa-desktop icon-size"></i>
                      <p>My Files</p>
                    </div>
                    <div
                      className="file-container"
                      onClick={() => setVideoOpen(true)}
                    >
                      <i className="fas fa-video icon-size"></i>
                      <p>Video</p>
                    </div>
                  </div>
                </div>
                <div>
                  {!videoOpen && openModal.type === "video" ? (
                    <VideoUpload
                      accept=".mp4"
                      label={
                        type === "prayerRequest"
                          ? "Prayer Video"
                          : "Testimony Video"
                      }
                      updateFilesCb={updateUploadedFilesVideo}
                      maxFileSizeInBytes={50000000}
                    />
                  ) : (
                    <div className=''>
                      {/* <VideoRecorder updateFilesCb={UploadedFilesVideo} backgroundColorSecondary={backgroundColorSecondary} backgroundColor={backgroundColor}  /> */}
                      <p>This is not supported yet. Kindly Upload from your device</p>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="d-flex justify-content-between">
              {image?.length > 0 || audioUrl || videoUrl ? <ButtonContainer onClick={handleUpload} backgroundColor={backgroundColor}> 
                Upload
              </ButtonContainer> : null}
              <ButtonContainer
                onClick={handleCloseModal}
                backgroundColor={backgroundColor}
              >
                close
              </ButtonContainer>
            </div>
          </ResizeableModalWithoutBtn>
       
          {/* <ModalComponent
            show={openModal.show}
            backgroundColor={backgroundColor}
            title={
              openModal.type === "image"
                ? "Image Upload"
                : openModal.type === "audio"
                ? "Audio Upload"
                : "Video Upload"
            }
            onHide={handleCloseModal}
            hideFooter={false}
          >
            {openModal.type === "image" && (
              <>
                <div className="d-flex justify-content-between attachment-modal py-3">
                  <p className="text-header">
                    {!cameraOpen ? "Upload Image" : "Snap Image"}
                  </p>
                  <div className="d-flex py-3">
                    <div
                      className="file-container"
                      onClick={() => setCameraOpen(false)}
                    >
                      <i className="fas fa-desktop icon-size"></i>
                      <p>My Files</p>
                    </div>
                    <div
                      className="file-container"
                      onClick={() => setCameraOpen(true)}
                    >
                      <i className="fas fa-camera icon-size"></i>
                      <p>Camera</p>
                    </div>
                  </div>
                </div>
                <div>
                  {!cameraOpen && openModal.type === "image" ? (
                    <ImageUpload
                      accept=".jpg,.png,.jpeg,.PNG,.JPG,.JPEG"
                      label={
                        type === "prayerRequest"
                          ? "Prayer Image(s)"
                          : "Testimony Image(s)"
                      }
                      multiple
                      updateFilesCb={updateUploadedFiles}
                      maxFileSizeInBytes={10000000}
                    />
                  ) : (
                    <div className='py-4'>
                      <CameraComponent updateFilesCb={updateUploadedFiles} />

                    </div>
                  )}
                </div>
              </>
            )}
            {openModal.type === "audio" && (
              <>
                <div className="d-flex justify-content-between attachment-modal py-3">
                  <p className="text-header">
                    {!audioOpen ? "Upload Audio" : "Record Audio"}
                  </p>
                  <div className="d-flex py-3">
                    <div
                      className="file-container"
                      onClick={() => setAudioOpen(false)}
                    >
                      <i className="fas fa-desktop icon-size"></i>
                      <p>My Files</p>
                    </div>
                    <div
                      className="file-container"
                      onClick={() => setAudioOpen(true)}
                    >
                      <i className="fas fa-microphone icon-size"></i>
                      <p>Audio</p>
                    </div>
                  </div>
                </div>
                <div>
                  {!audioOpen && openModal.type === "audio" ? (
                    <AudioUpload
                      label={
                        type === "prayerRequest"
                          ? "Prayer Audio"
                          : "Testimony Audio"
                      }
                      accept=".mp4,.mp3"
                      updateFilesCb={updateUploadedFilesAudio}
                      maxFileSizeInBytes={20000000}
                    />
                  ) : (
                    <div className='py-3'>
                      <AudioRecorder updateFilesCb={UploadedFilesAudio} backgroundColorSecondary={backgroundColorSecondary} backgroundColor={backgroundColor}/>

                    </div>
                  )}
                </div>
              </>
            )}
            {openModal.type === "video" && (
              <>
                <div className="d-flex justify-content-between attachment-modal py-3">
                  <p className="text-header">
                    {!videoOpen ? "Upload Video" : "Record Video"}
                  </p>
                  <div className="d-flex py-3">
                    <div
                      className="file-container"
                      onClick={() => setVideoOpen(false)}
                    >
                      <i className="fas fa-desktop icon-size"></i>
                      <p>My Files</p>
                    </div>
                    <div
                      className="file-container"
                      onClick={() => setVideoOpen(true)}
                    >
                      <i className="fas fa-video icon-size"></i>
                      <p>Video</p>
                    </div>
                  </div>
                </div>
                <div>
                  {!videoOpen && openModal.type === "video" ? (
                    <VideoUpload
                      accept=".mp4"
                      label={
                        type === "prayerRequest"
                          ? "Prayer Video"
                          : "Testimony Video"
                      }
                      updateFilesCb={updateUploadedFilesVideo}
                      maxFileSizeInBytes={50000000}
                    />
                  ) : (
                    <div className=''>
                      <VideoRecorder updateFilesCb={UploadedFilesVideo} backgroundColorSecondary={backgroundColorSecondary} backgroundColor={backgroundColor}  />

                    </div>
                  )}
                </div>
              </>
            )}
            <div className="d-flex justify-content-between">
              <ButtonContainer onClick={handleUpload} backgroundColor={backgroundColor}>
                Upload
              </ButtonContainer>
              <ButtonContainer
                onClick={handleCloseModal}
                backgroundColor={backgroundColor}
              >
                close
              </ButtonContainer>
            </div>
          </ModalComponent> */}
          {image && image.length !== 0 && <p>{`${image.length} ${image.length === 1 ? 'photo' : 'photos'} has been uploaded`} <i className="fas fa-check" style={{color: themeColor.secondaryColor}}></i></p>}
          {/* {image && cameraOpen && image.length + 1 !==  && <p>{`${cameraOpen ? image.length + 1: image.length} photos has been uploaded`}</p>} */}
          {audioUrl && <p>{`Audio has been uploaded`}{' '}<i className="fas fa-check" style={{color: themeColor.secondaryColor}}></i></p>}
          {/* {audioUrl && audioOpen && Object.entries(audioUrl).length !== 0 && <p>{`Audios has been uploaded`}</p>} */}
          {videoUrl && <p>{`Video has been uploaded`} <i className="fas fa-check" style={{color: themeColor.secondaryColor}}></i></p>}
          {/* {videoUrl && videoOpen && Object.entries(audioUrl).length !== 0 && <p>{`Videos has been uploaded`}</p>} */}
          <div className={styles.submitContainer}>
            {addPrayerWallsLoading ? (
              <button class="btn btn-primary" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              !openModal.show && <div className="d-flex justify-content-between">
                <ButtonContainer
                  type="submit"
                  backgroundColor={backgroundColor}
                  disabled={watchTextField?.length === 0}
                >
                  submit
                </ButtonContainer>
                <ButtonContainer
                  onClick={onHide}
                  backgroundColor={backgroundColor}
                  type="button"
                >
                  close
                </ButtonContainer>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
