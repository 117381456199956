import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
// import Skeleton from "react-loading-skeleton";
import { Container } from "react-bootstrap";
import { getUserDetails } from "../../../utils/authHelpers";
import { prayerWalls, myPrayerWalls } from "../../../store/actions/userDashboardActions/PrayerWall";
import PrayerWalls from "../Prayer/PrayerWalls";
import PageLoader from "../../Loader/PageLoader";

export const TestimonyWall = () => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [prayerLike, setPrayerLike] = React.useState();

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const prayerWallData = useSelector(
    (state) => state.prayerWalls?.responseData?.returnedRecords,
    shallowEqual
  );

  const prayerWallLoading = useSelector(
    (state) => state.prayerWalls?.loading,
    shallowEqual
  );

  let dispatch = useDispatch();
  React.useEffect(() => {
    const user = getUserDetails();
    dispatch(prayerWalls({orgId: orgInfo.orgId, userId: user.userId, fellowshipType: "testimony", mineOnlySwitch: "false"}));
    dispatch(myPrayerWalls({orgId: orgInfo.orgId, userId: user.userId, fellowshipType: "testimony", mineOnlySwitch: "true"}))
    // setIsLoading(true);
    // prayerWalls({
    //   orgId: orgInfo.orgId,
    //   userId: user.userId,
    //   fellowshipType: "testimony",
    // })
    //   .then((result) => {
    //     const { responseData } = result;
    //     const { returnedRecordsList } = responseData;
    //     setData(returnedRecordsList);
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //   });
  }, []);

  const prayerLikeIndex = (data) => {
    setPrayerLike(data);
  };

  if (prayerWallLoading) {
    return <PageLoader />;
  }
  return (
    <>
      {/* <h6 className="mt-2"> Testimony Wall - It's time to celebrate!!</h6> */}
      {prayerWallData && <PrayerWalls
        data={prayerWallData}
        userId={getUserDetails()}
        type="testimony"
        wallTitle="Testimony Wall"
        requestIcon="fa fa-smile"
        top10Icon="fas fa-people-carry"
        myRequestIcon="fas fa-clipboard"
        tableActionIconsPositive="fa fa-thumbs-up"
        tableActionIconsNegative="fas fa-hand-rock"
        prayerLikeIndex={prayerLikeIndex}
        chartTitle="Top 10 Testimonies"
        categoryName="Testimonies Category"
      />}
    </>
  );
};