import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Tab,
  Divider,
} from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getFormQuestionAndAnswer,
  getOrganizationForms,
} from "../../../store/actions/userDashboardActions/forms";
import moment from "moment";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import { useHistory } from "react-router-dom";
import SubmittedForms from "./SubmittedForms";
import { getUserDetails } from "../../../utils/authHelpers";
import { toast } from "react-toastify";

const names = ["Name", "Description", "Start Date", "End Date", ""];

export default function OrganizationForms() {
  let dispatch = useDispatch();
  const history = useHistory();
  const themeColor = useThemeColor();
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const organizationFormsData = useSelector(
    (state) => state.getOrganizationForms.data,
    shallowEqual
  );

  const getFormQuestionAndAnswerData = useSelector(
    (state) => state.getFormQuestionAndAnswer.responseData,
    shallowEqual
  );

  const [formItemData, setFormItemData] = React.useState(null);

  console.log(organizationFormsData, 'getFormQuestionAndAnswer');
  let user = getUserDetails();

  const callback = (data, formData) => {
    console.log(data, 'form');
    console.log(formData, 'formData');
    // if (data !== null && formData?.canResubmit) {
    //   return history.push({
    //     pathname: "/view_forms",
    //     state: formData,
    //     search: "?editable=true",
    //   });
    // } else if (data && !formData?.canResubmit) {
    //   return toast.error(
    //     "You have submitted this form. You are not permitted to edit. Contact the church admin"
    //   );
    // } else {
    // }
    return history.push({
      pathname: `/view_forms`,
      state: formData,
    });
  };

  const handleViewForm = (data) => {
    console.log(data);
    // history.push({
    //   pathname: '/view_forms',
    //   state: data
    // })
    setFormItemData(data);
    dispatch(
      getFormQuestionAndAnswer(
        orgInfo.orgId,
        data.id,
        callback,
        data
      )
    );
  };

  const handleViewResponse = (data) => {
    history.push({
      pathname: `/submitted_form/${data.id}`,
      state: data,
    });
  }
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (e, value) => {
    setTabIndex(value);
  };
  const tabList = [`${orgInfo?.fullName}'s Forms`];

  useEffect(() => {
    if (tabIndex === 0) dispatch(getOrganizationForms(orgInfo.orgId));
  }, [tabIndex]);


  return (
    <Paper className="card card-custom gutter-b px-2">

      {tabIndex === 0 && organizationFormsData?.length > 0 && (
        <div className="organization-container"> 
          <TableContainer component={Paper}>
            <Table className="table table-stripe">
              <TableHead>
                {names?.map((item, index) => (
                  <TableCell className="fw-bold" key={index}>
                    {item}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {organizationFormsData?.map((item, index) => {
                  // if (item.status === "ACTIVE") {
                    return (
                      <TableRow>
                        <>
                          {/* <TableCell>{index + 1}</TableCell> */}
                          <TableCell>{item.name}</TableCell>
                          <TableCell className="dynamic_form_description">{item.description}</TableCell>
                          <TableCell>
                            {item.startDate
                              ? moment(item.startDate).format("ddd MMM D, YYYY")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {item.endDate
                              ? moment(item.endDate).format("ddd MMM D, YYYY")
                              : ""}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="fill form">
                              <i
                                className="fas fa-arrow-right"
                                onClick={() => handleViewForm(item)}
                                style={{
                                  color: themeColor.iconColor,
                                  cursor: "pointer",
                                }}
                              ></i>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="View Response">
                              <i
                                className="far fa-eye"
                                onClick={() => handleViewResponse(item)}
                                style={{
                                  color: themeColor.iconColor,
                                  cursor: "pointer",
                                }}
                              ></i>
                            </Tooltip>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  // }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {/* {tabIndex === 1 && <SubmittedForms />} */}
    </Paper>
  );
}
