import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import {useThemeColor} from '../../../utils/customHooks/useThemeColor';

import { Paper } from '@material-ui/core';

export const SuccessGivePage = ({className }) => {
  const themeColor = useThemeColor();
  const history = useHistory();

  const goNext = () => {
    history.push('/paypal')
  }

  const goBack = () => {
    history.push('/dashboard')
  }

  return <Paper className="w-100 page-wrapper my-8 p-6">
  <div className="col-md-10 m-auto col-lg-6">
    <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={themeColor.btnStyle}>
              <i className="fa fa-thumbs-up text-white"></i>
            </span>
          </div>
          <h3 className="font-size-h3 mb-2">Thank You for Giving</h3>
        </div>

        <div className="d-flex justify-content-between  navigator-wrapper mt-15 ">
          <Button
            onClick={goBack}
            variant="secondary"
            className={`btn btn-lg py-3 btn-secondary ${className}`}
          >
            Dashboard
          </Button>
          <Button
            onClick={goNext}
            variant="primary"
            className={`btn btn-lg py-3 ${className} text-white`}
            style={themeColor.btnStyle}
          >
            Give Again
          </Button>
        </div>
  </div>
</Paper>
}

export const FailureGivePage = ({className }) => {
  const themeColor = useThemeColor();
  const history = useHistory();

  const goNext = () => {
    history.push('/paypal')
  }

  const goBack = () => {
    history.push('/dashboard')
  }

  return <Paper className="w-100 page-wrapper my-8 p-6">
  <div className="col-md-10 m-auto col-lg-6">
    <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={themeColor.btnStyle}>
              <i className="fas fa-exclamation text-white"></i>
            </span>
          </div>
          <h3 className="font-size-h3 mb-2">Unsuccessful Giving</h3>
        </div>

        <div className="d-flex justify-content-between  navigator-wrapper mt-15 ">
          <Button
            onClick={goBack}
            variant="secondary"
            className={`btn btn-lg py-3 btn-secondary ${className}`}
          >
            Dashboard
          </Button>
          <Button
            onClick={goNext}
            variant="primary"
            className={`btn btn-lg py-3 ${className} text-white`}
            style={themeColor.btnStyle}
          >
            Give Again
          </Button>
        </div>
  </div>
</Paper>
}