import React from "react";

const OurSimpleCharge = () => {
    return (
        <div className="charge-container">
            <h3>Our charge is simple:</h3>
            <p className="top-description">Feed the people at no cost, ensuring that none goes to bed hungry. To do this, we give out over:</p>
            <div>
                <img 
                    src="/images/heading.png"
                    alt="Heading"
                    className="img-container"
                />
            </div>
            <p className="bottom-description">boxes filled with high-quality, nutritious food annually to individuals and families within the Albany Park neighborhood of Chicago.</p>
        </div>
    )
}

export default OurSimpleCharge;