import {actionTypes} from '../actionTypes'

export const getFollowUpByOrganization = (state={}, action) => {
    switch(action.type) {
        case actionTypes.GET_FOLLOWUP_ORGANISATION_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case actionTypes.GET_FOLLOWUP_ORGANISATION_DATA_FAILURE:
          return {
              ...state,
              ...action.payload
          } 
        default:
          return state;
      }

}
export const getForm = (state={}, action) => {
    switch(action.type) {
        case actionTypes.GET_FOLLOWUP_FORM_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case actionTypes.GET_FOLLOWUP_FORM_FAILURE:
          return {
              ...state,
              ...action.payload
          } 
        default:
          return state;
      }

}

export const submitForm = (state={}, action) => {
    switch(action.type) {
        case actionTypes.SUBMIT_FOLLOWUP_FORM_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case actionTypes.SUBMIT_FOLLOWUP_FORM_FAILURE:
          return {
              ...state,
              ...action.payload
          } 
        case actionTypes.SUBMIT_FOLLOWUP_FORM_STOP:
            return {
                ...state,
                loading: false
            } 
        case actionTypes.SUBMIT_FOLLOWUP_FORM_START:
            return {
                ...state,
                loading: true
            } 
        case actionTypes.SUBMIT_FOLLOWUP_FORM_STOP:
            return {
                ...state,
                loading: false
            } 
        default:
          return state;
      }

}