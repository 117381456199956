import React from "react";
import "./styles.scss";

const CustomInput = (props) => {
  const { errors, name, className, ...rest } = props;
  return (
    <div className="mb-3">
      <input
        name={name}
        className={`mb-0 w-full ${
          errors?.[name]?.message ? "border-danger border" : ""
        } ${className}`}
        {...rest}
      />
      {errors?.[name]?.message && (
        <p className="text-danger">{errors?.[name]?.message}</p>
      )}
    </div>
  );
};

export default CustomInput;
