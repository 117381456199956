import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {useThemeColor} from '../utils/customHooks/useThemeColor';
import {
  setHideAmountInput,
  donatedAmount,
} from '../store/actions';
import {Home} from '../pages/LandingPage/Home';

export default function SuccessPage({customPath, fromMobileApp=false, giveFailure=false, className}) {
  let history = useHistory();
  const dispatch = useDispatch();
  //   const orgInfo = useSelector(
  //     (state) => state.orgInfo.responseData,
  //     shallowEqual
  //   );
  const themeColor = useThemeColor();

  const goBack = () => {
    history.push('/');
  };

  const goNext = () => {
    dispatch(setHideAmountInput(false));
    dispatch(donatedAmount(0.0));
    // setGiveAgin(true);
    history.push('/give');
  };

  React.useEffect(() => {
    dispatch(setHideAmountInput(true));
  }, []);

  return (
    <Home>
      <div className="login-form login-signin activeForm-wrapper px-3">
        <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={themeColor.btnStyle}>
              {giveFailure ? <i className="fas fa-exclamation"></i> :
              <i className="fa fa-thumbs-up"></i>}
            </span>
          </div>
          {!giveFailure ? 
          <h3 className="font-size-h3 mb-2">Thank You for Giving</h3> :
          <h3 className="font-size-h3 mb-2">Unsuccessful Giving</h3>}
          {!giveFailure && <p>Please note that it may take up to <strong>two (2) minutes</strong> before this contribution is posted to your account. Thank you.</p>}
        </div>
        {giveFailure && <div className="d-flex justify-content-center  navigator-wrapper mt-15 ">
          <p>Attempt to give failed. Please try giving again</p>
        </div>}
        {(fromMobileApp && !giveFailure) ?
        <div className="d-flex text-center  navigator-wrapper mt-15 ">
          <p>Please close the browser and continue on your mobile app</p>
        </div>
        : (!giveFailure && <div className="d-flex justify-content-between  navigator-wrapper mt-15 ">
           <Button
            onClick={goBack}
            variant="secondary"
            className={`btn btn-lg py-3 ${className}`}
          >
            Go to Home
          </Button>
          <Button
            onClick={goNext}
            variant="primary"
            className={`btn btn-lg py-3 ${className}`}
            style={themeColor.btnStyle}
          >
            Give Again
          </Button>
        </div>)}
      </div>
    </Home>
  );
}
