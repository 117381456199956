import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { getUserDetails } from '../../../utils/authHelpers';
import { CreditCardForm } from '../../Common/CreditCardForm';
import { AccountCheckForm } from '../../Common/AccountCheckForm';
import { getFormattedDate } from '../../../utils';
import {
  Grid,
  Radio,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import * as yup from 'yup'
import {setupANewRecurringPortal} from '../../../store/actions/userDashboardActions/give'
import { InlineErrorMsg } from '../../Common/InlineErrorMsg';
import {ColorButton} from './AddMoreContributionForm'
import { useWindowDimensions } from '../../../utils/customHooks/useWindowDimensions'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';

import './Give.scss';



export const NewAutoDebitEnrollmentForm = ({closeModal}) => {
  const { handleSubmit, errors, control, watch } = useForm({
    mode: "onBlur",
  });

  const [startDate, setStartDate] = useState(null);
  const [paymentMode, setPaymentMode] = useState('')
  const [firstName, setFirstName] = useState('')
  const [cardDetails, setCardDetails] = useState({});
  const [checkMemo, setCheckMemo] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [checkFirstName, setCheckFirstName] = useState('');
  const [checkLastName, setCheckLastName] = useState('');
  const [paymentOptionError, setPaymentOptionError] = useState('');
  const dispatch = useDispatch();
  const date = new Date();

  
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const cardsOrCheckingProfilesData = useSelector(state => state.cardsOrCheckingProfilesData.responseData, shallowEqual);
  const setupANewRecurringPortalData = useSelector(state => state.setupANewRecurringPortalData, shallowEqual);
  const themeColor = useThemeColor();
  
  const user = getUserDetails();
  const { width } = useWindowDimensions();
  const recordId = watch('recordId');

  const onHandleChange = (e) => setPaymentMode(e.target.value)
  const getMemo = (memo) => setCardDetails({...cardDetails, memo});
  const getCardNumber = (cardNumber) => setCardDetails({...cardDetails, cardNumber});
  const getLastName = (lastName) => setCardDetails({...cardDetails, lastName});
  const getFirstName = (firstName) => setFirstName(firstName);
  const getCVV = (cvv) => setCardDetails({...cardDetails, cvv});
  const getExpiryMonth = (expirationMonth) => setCardDetails({...cardDetails, expirationMonth});
  const getExpiryYear = (expirationYear) => setCardDetails({...cardDetails, expirationYear});
  const getAccMemo = (memo) => setCheckMemo(memo)
  const getAccountNumber = (accountNumber) => setAccountNumber(accountNumber)
  const getRoutingNumber = (routingNumber) => setRoutingNumber(routingNumber)
  const getAccFirstName = (firstName) => setCheckFirstName(firstName)
  const getAccLastName = (lastName) => setCheckLastName(lastName)
  
  const onSubmit = (formValues) => {
    const values = { ...formValues, orgId: orgInfo.orgId, userId: user.userId, givingCurrency: orgInfo.defaultCurrency };
    if(!paymentMode){
      return setPaymentOptionError('Select a payment option')
    }
    if(paymentMode === 'existingCard') {
      const payload = {
        ...values,
        'recurringStartDate': getFormattedDate(startDate),
        recordId
      }
      console.log(payload, 'payload')
      return dispatch(setupANewRecurringPortal(payload, closeModal));
      
    }
    else if(paymentMode === 'newCard') {
      const cardPayload = {
        ...values,
        ...cardDetails,
        firstName,
        'recurringStartDate': getFormattedDate(startDate)
      }
      return dispatch(setupANewRecurringPortal(cardPayload, closeModal));
    }
    else if(paymentMode === 'newCheck') {
      const checkPayload = {
        ...values,
        'memo': checkMemo,
        'accountNumber': accountNumber,
        'routingNumber': routingNumber,
        'firstName': checkFirstName,
        'lastName': checkLastName, 
        'recurringStartDate': getFormattedDate(startDate)
      }
      return dispatch(setupANewRecurringPortal(checkPayload, closeModal));
    }
  }

  return (
    <div className="new-auto-debit-enrollment">
      <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
              <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    className="mb-8"
                    name="amount"
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    error={errors.amount ? true : false}
                  />
                }
                name="amount"
                control={control}
                defaultValue={""}
                rules={{
                  required: true
                }}
              />
              <Controller
                as={
                  <TextField
                    className="mb-8"
                    label="Contribution Type"
                    name="contributionType"
                    size="small"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    {orgInfo.contributionTypes?.map((list, index) => (
                      <MenuItem value={list} key={index}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                  }
                  name="contributionType"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: true
                  }}
                />
                <div className="w-100">
                  <DatePicker selected={startDate}
                    onChange={date => setStartDate(date)}
                    minDate={new Date()}
                    maxDate={new Date(date.setMonth(date.getMonth() + 1))}
                    className="form-control p-5 w-100 mb-8"
                    placeholderText="Click here to select start date" />
                  {/* {giveError.recurringStartDate && <small className="text-danger errorText">{giveError.recurringStartDate}</small>} */}
                </div>
                <Controller
                  as={
                  <TextField
                    className="mb-8"
                    label="Auto-Debit Type"
                    name="recurring"
                    size="small"
                    variant="outlined"
                    fullWidth
                    select
                    // defaultValue={item.autoDebitType || ''}
                    // onBlur={handleChange}
                  >
                    {orgInfo.recurringOptions?.map((list, index) => (
                      <MenuItem value={list} key={index}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="recurring"
                control={control}
                defaultValue={""}
                rules={{
                  required: true
                }}
              />
              {console.log(width, 'width')}
              <FormControl component="fieldset" className="mb-5">
                {/* <FormLabel component="legend">Select Payment Mode</FormLabel> */}
                  <RadioGroup
                    aria-label="paymentMode"
                    name="paymentMode"
                    value={paymentMode}
                    onChange={onHandleChange}
                    row={width < 720 ? 'false' : 'true'}
                  >
                    <FormControlLabel
                      value="existingCard"
                      control={<Radio color="primary" />}
                      label="Existing Card/Check"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                        value="newCard"
                        control={<Radio color="primary" />}
                        label="New Card"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="newCheck"
                        control={<Radio color="primary" />}
                        label="New Check"
                        labelPlacement="end"
                    />
                  </RadioGroup>
                {paymentOptionError && <InlineErrorMsg msg={paymentOptionError} />}
                  {/* <FormHelperText>labelPlacement start</FormHelperText> */}
                </FormControl>
                {/* <div className="d-flex w-100 align-items-center justify-content-between"> */}
                {paymentMode === 'existingCard' && 
                  <Controller
                  as={
                    <TextField
                      className="mb-5"
                      label="Existing Card/Check"
                      name="recordId"
                      size="small"
                      variant="outlined"
                      fullWidth
                      select
                      // defaultValue={item.contributionType || ''}
                      // onBlur={handleChange}
                    >
                      {cardsOrCheckingProfilesData?.returnedRecords?.map((list, index) => (
                        <MenuItem value={list.recordId} key={index}>
                          {`${list.firstName} ${list.lastName} - ${list.last4Digits}`}
                        </MenuItem>
                      ))}
                    </TextField>
                    }
                    name="recordId"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: true
                    }}
                  />
                }
                
                {paymentMode === 'newCard' && 
                  <CreditCardForm 
                    getCardNumber={getCardNumber} 
                    getMemo={getMemo}
                    getCVV={getCVV}
                    getExpiryMonth={getExpiryMonth}
                    getExpiryYear={getExpiryYear}
                    getFirstName={getFirstName}
                    getLastName={getLastName}
                />}
                {paymentMode === 'newCheck' && 
                  <AccountCheckForm 
                    getAccMemo={getAccMemo}
                    getAccountNumber={getAccountNumber}
                    getRoutingNumber={getRoutingNumber}
                    getAccFirstName={getAccFirstName}
                    getAccLastName={getAccLastName}
                  />}
                <ColorButton className="w-100 btn px-7 py-3" type="submit" variant="contained" backgroundColor={themeColor.color}>
                  <span>{setupANewRecurringPortalData.loading ? 'Please wait...' : 'Enroll Auto-Debit'}</span>
                  {setupANewRecurringPortalData?.loading && <span className="ml-3 spinner spinner-white"></span>}
                </ColorButton>
                {/* </div> */}
              </Grid>
              
          </Grid>
      </form>
    </div>
  )
}