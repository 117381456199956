import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import { Home } from "./Home";
import { ActiveForm } from "../../components/Forms/GiveForm/ActiveForm";
import { InitialGiveForm } from "../../components/Forms/GiveForm/InitialGiveForm";
import { UnAuthorizedUser } from "../../components/Forms/GiveForm/UnAuthorizedUser";
import { Helmet } from "react-helmet";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

export const GivePage = () => {
  /* Check if the user is authorized */
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  const siteKey = process.env.REACT_APP_RECAPTCHA
  return (
    <>
      {secondOrgInfo && (
        <Helmet>
          <title>{secondOrgInfo.metaData.give.title}</title>
          <meta
            name={secondOrgInfo.metaData.give.author}
            content={secondOrgInfo.metaData.give.description}
          />
          <meta name='keywords' content={secondOrgInfo.metaData.give.keywords.toString()} />
        </Helmet>
      )}
      <Home>
        {orgInfo ? (
          <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
            <ActiveForm />
          </GoogleReCaptchaProvider>
        ) : (
          // <InitialGiveForm /> :
          <UnAuthorizedUser />
        )}
      </Home>
    </>
  );
};
