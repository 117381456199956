import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import DatePicker from "react-datepicker";
import { MenuItem, TextField } from "@material-ui/core";
// import { addDays } from '../../../utils/constants';
import "react-datepicker/dist/react-datepicker.css";

// styly imported
// import { ButtonGroup, ToggleButton} from 'react-bootstrap'
import "./RecurringGivingPlan.scss";

// components
import { GiveOption } from "./GiveOption";

const RecurringGivingPlan = ({
  getFrequencyValue,
  getStartDate,
  giveError,
  getSelectValue,
  selectedFreqValue,
  selectedValue = "",
  date = null
}) => {
  // console.log(startDateError, 'startDateError')
  const [startDate, setStartDate] = useState(date);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const [endDate, setEndDate] = React.useState();

  const handleChange = (e) => {
    getFrequencyValue(e.target.value || null);
  };

  React.useEffect(() => {
    const date = new Date();
    setEndDate(new Date(date.setMonth(date.getMonth() + 1)));
  }, []);

  return (
    <div className="recurring-giving-plan-wrapper my-8">
      <div className="d-md-flex justify-content-md-between">
        <div className="mt-5 pt-3 mr-md-3 w-100">
          {/* <p className="mt-5 mb-3 text-dark-50">Give Frequency</p> */}
          <TextField
            id="outlined-select-give-freq"
            select
            label="Give Frequency"
            name="giveFrequency"
            onChange={handleChange}
            variant="outlined"
            fullWidth
            error={giveError.freqValue}
            value={selectedFreqValue || ""}
          >
            <MenuItem value="">Please select One</MenuItem>
            {orgInfo?.recurringOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {giveError.freqValue && (
            <small className="text-danger errorText">
              {giveError.freqValue}
            </small>
          )}
        </div>
        <GiveOption
          classname="ml-md-3"
          getSelectValue={getSelectValue}
          giveError={giveError}
          selectedValue={selectedValue}
        />
      </div>
      <p className="mt-5 mb-3 pt-5">Give Start Date</p>
      <div className="w-100">
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            getStartDate(date);
          }}
          minDate={new Date()}
          maxDate={endDate}
          className="form-control p-8 w-100"
          placeholderText="Click here to select start date"
        />
        {giveError.startDate && (
          <small className="text-danger errorText">{giveError.startDate}</small>
        )}
      </div>
    </div>
  );
};

export default RecurringGivingPlan;
