import React from "react";
// import { Tabs, Tab, TabContainer } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import {FormControlLabel, Switch} from '@material-ui/core'
// import { useForm } from "react-hook-form";
import "./update.scss";
import Forms from "../Emergency/Forms";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {updateUserEmailAndPassword} from '../../../store/actions/index'
import { toast } from "react-toastify";
import NotificationMesage from "../../Common/NotificationMesage";
import {TabPanel } from '../Emergency';
import {useWindowDimensions} from '../../../utils/customHooks/useWindowDimensions'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { withStyles } from "@material-ui/core/styles";


export function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

export const UpdateEmail = () => {
  // const userData = JSON.parse(localStorage.getItem("user"));
  const userData = useSelector((state) => state.getUserProfileData.responseData)
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const theme = useTheme();
  const [IsLoading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState(0);

  const PurpleSwitch = withStyles({
    switchBase: {
      // color: props => props.primaryColor,
      '&$checked': {
        color: props => props.primaryColor,
      },
      '&$checked + $track': {
        backgroundColor: props => props.primaryColor,
      },
    },
    checked: {},
    track: {},
  })(Switch);


  const themeColor = useThemeColor();
  const emailDetails = [
    {
      label: "Current Email",
      name: "currentEmail",
      defaultValue: userData && userData?.userEmail,
      type: "email",
      errorMsg: "Phone Number is Required",
      readOnly: true,
    },
    {
      label: "Password",
      name: "currentPassword",
      type: "password",
      errorMsg: "Password is Required",
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/
    },
    {
      label: "New Email",
      name: "email",
      type: "email",
      errorMsg: "Email is Required",
      pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    {
      label: "Confirm Email",
      name: "confirmEmail",
      type: "email",
      pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      errorMsg: "Email is Required",
    },
  ];
  const passwordDetails = [
    {
      label: "Password",
      name: "currentPassword",
      type: "password",
      errorMsg: "Password is Required",
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/,
      patternMsg: 'Password is Incorrect'
    },
    {
      label: "New Password",
      name: "newPassword",
      type: "password",
      errorMsg: "Password is Required",
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/,
      patternMsg: 'Password is incorrect'
    },
    {
      label: "Confirm New Password",
      name: "confirmPassword",
      type: "password",
      errorMsg: "Password is Required",
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/,
      patternMsg: 'Password is incorrect'
    },
  ];

  const handleFormData = (data) => {
    setLoading(true)
    if (data.confirmEmail !== data.email) {
      setLoading(false)
      toast.error(<NotificationMesage message="Email did not match" responseStatus='01' />)
    } else {
      const inputData = {
        orgId: orgInfo.orgId,
        userId: userData.userId,
        type: "email",
        email: data.email,
        password: data.currentPassword,
      };
      updateUserEmailAndPassword(inputData).then((result) => {
        setLoading(false)
        toast.success(<NotificationMesage message={result.responseMessage} responseStatus={result.responseCode} />)
        console.log(result, 'data')
      }).catch((e) => {
        setLoading(false)
        toast.error(<NotificationMesage message="Please try again" responseStatus='01' />)
      })
    }
  };
  const handlePasswordData = (data) => {
    setLoading(true)
    if (data.confirmPassword !== data.newPassword) {
      toast.error(<NotificationMesage message="Password did not match" responseStatus='01' />)
      setLoading(false)
    } else {
      const inputData = {
        orgId: orgInfo.orgId,
        userId: userData.userId,
        type: "password",
        password: data.currentPassword,
        newPassword: data.newPassword
      };
      updateUserEmailAndPassword(inputData).then((result) => {
        setLoading(false)
        toast.success(<NotificationMesage message={result.responseMessage} responseStatus={result.responseCode} />)
        console.log(result, 'data')
      }).catch((e) => {
        setLoading(false)
        toast.error(<NotificationMesage message="Please try again" responseStatus='01' />)
      })
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  let {width} = useWindowDimensions()
  return (
    <Paper className="w-100 page-wrapper my-8 p-6">
      <div>
        <span className="font-size-h3 mr-2">Update my Email and Password</span>
        <span className="text-muted">
          {" "}
          Please don't share your password with anyone. It is your personal
          property!
        </span>
      </div>
      <hr />
      <div className="col-md-10 m-auto">
        <Paper className={`${width >= 720 ? 'w-50 mx-auto': 'w-100 mx-auto'}`} elevation={2}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="update Email and password"
            variant="fullWidth"
          >
            <Tab label="Change Email" {...a11yProps(0)} />
            <Tab label="Change Password" {...a11yProps(1)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0}>
              <Forms
                formDetails={emailDetails}
                buttonName="Update"
                handleFormData={handleFormData}
                loading={IsLoading}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Forms
                formDetails={passwordDetails}
                buttonName="Update"
                handleFormData={handlePasswordData}
                loading={IsLoading}
              />
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </div>
    </Paper>
  );
};