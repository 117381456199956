import React from "react";
import amexIcon from "../Icons/cc Icons/amex-svgrepo-com.svg";
import discoverIcon from "../Icons/cc Icons/1933701_charge_credit card_discover_payment_icon.svg";
import mastercardIcon from "../Icons/cc Icons/2629972_card_cash_checkout_credit_mastercard_icon.svg";
import visaIcon from "../Icons/cc Icons/38610_visa_curved_icon.png";

const imageUrls = [
  visaIcon,
  mastercardIcon,
  discoverIcon,
  amexIcon,
  "https://cdn4.iconfinder.com/data/icons/simple-peyment-methods/512/diners_club-512.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/JCB_logo.svg/1280px-JCB_logo.svg.png",
];

const CreditCardIcons = ({ imgType }) => {
  let img = "";
  switch (imgType) {
    case "visa":
      img = imageUrls[0];
      break;
    case "mastercard":
      img = imageUrls[1];
      break;
    case "discover":
      img = imageUrls[2];
      break;
    case "amex":
      img = imageUrls[3];
      break;
    case "dniners":
      img = imageUrls[4];
      break;
    case "JCB":
      img = imageUrls[5];
      break;
    default:
      return img;
  }
  return <img className="logo w-40" src={img} alt="Card logo" />;
};

export default CreditCardIcons;
