import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Button, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setThemeChange } from "../../store/actions";
import { useWindowDimensions } from "../../utils/customHooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  themeIcon: {
    margin: "10px",
    cursor: "pointer",
  },
}));

const CustomAppBar = ({
  classes,
  logo,
  showLogo,
  appBarClass,
  menu,
  scrollToGiveCards,
  handleHomeBtnClick,
  indexPage = true,
  isDynamicGiveCampaign,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  let { width } = useWindowDimensions();

  const [appBarDistanceFromTop, setAppBarDistanceFromTop] = useState(0);

  const getThemeChangeData = useSelector(
    (state) => state.getThemeChangeData,
    shallowEqual
  );

  const handleThemeChange = () => {
    dispatch(setThemeChange({ theme: !getThemeChangeData.theme }));
  };

  useEffect(() => {
    if (indexPage) {
      dispatch(setThemeChange({ theme: true }));
    }
    if (isDynamicGiveCampaign) {
      dispatch(setThemeChange({ theme: false }));
    }
  }, [indexPage]);

  useEffect(() => {
    const handleScroll = () => {
      const appBarTopPosition = document
        .querySelector("#app-bar")
        .getBoundingClientRect().top;
      const scrollPosition = window.scrollY;
      const distanceFromTop = Math.abs(appBarTopPosition) + scrollPosition;
      setAppBarDistanceFromTop(distanceFromTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showHomeBtnWidth = () => {
    if (width <= 768) return 760;
    if (width > 768 && width < 1679) return 660;
    if (width > 1679) return 660;
  };

  const hideHomeBtn = () => {
    if (indexPage && appBarDistanceFromTop >= showHomeBtnWidth()) return true;
    if (!indexPage) return true;
    return false;
  };

  return (
    <>
      <AppBar
        position="sticky"
        id="app-bar"
        className={appBarClass}
        style={hideHomeBtn() ? { backdropFilter: "blur(15px)" } : null}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoWrapper}>
            {showLogo && <img src={logo} alt="Logo" className={classes.logo} />}
          </div>

          {hideHomeBtn() && !isDynamicGiveCampaign && (
            <div className={menu}>
              <Button onClick={() => handleHomeBtnClick()} color="inherit">
                Home
              </Button>
            </div>
          )}

          <div>
            <img
              src="/images/Theme.svg"
              alt="Theme Icon"
              className={`${styles.themeIcon} mr-6 h-35px`}
              onClick={handleThemeChange}
            />
            {indexPage && !isDynamicGiveCampaign && (
              <Hidden smDown>
                <Button
                  variant="contained"
                  className={classes.startGivingButtonAppBar}
                  onClick={() => scrollToGiveCards()}
                >
                  Start Giving
                </Button>
              </Hidden>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default CustomAppBar;
