import { actionTypes } from "../actionTypes";

export const ipData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_IPDATA:
      return action.payload;
    default:
      return state;
  }
};

export const getThemeChangeData = (state = { theme: false }, action) => {
  switch (action.type) {
    case actionTypes.SET_THEME_CHANGE:
      return action.payload;
    default:
      return state;
  }
};
export const getPaymentMode = (state = { isAdvanced: false }, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PAYMENT_MODE:
      return action.payload;
    default:
      return state;
  }
};

export const orgInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORG_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const secondOrgInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SECOND_ORG_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const loginData = (state = { required2FAAuth: false }, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.AUTH_2FA_REQUIRED:
      return {
        ...state,
        required2FAAuth: true,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {
        required2FAAuth: false,
      };
    default:
      return state;
  }
};

export const signupData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SIGNUP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const forgotPasswordData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const forgotPinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FORGOT_PIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const verifyForgotPasswordOrPinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_PAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_PAGE_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const resetPasswordOrPinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_OR_PIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_PASSWORD_OR_PIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const initiateRSVPData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.INITIATE_RSVP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.INITIATE_RSVP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const verifyRSVPData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_RSVP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.VERIFY_RSVP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const processRSVPData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_RSVP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.PROCESS_RSVP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const cancelRSVPData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CANCEL_RSVP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CANCEL_RSVP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const rsvpInformationInstanceData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RSVP_INFORMATION_INSTANCE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RSVP_INFORMATION_INSTANCE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgInfoList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_INFO_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const multipleRsvpData = (
  state = { bulkRsvp: [], clearAllRsvp: false },
  action
) => {
  switch (action.type) {
    case actionTypes.CREATE_MULTIPLE_RSVP:
      return {
        ...state,
        bulkRsvp: action.payload,
      };
    case actionTypes.CLEAR_ALL_SELECTED_RSVP:
      return {
        ...state,
        clearAllRsvp: true,
      };
    default:
      return state;
  }
};

/* Reducer for User Update */
export const updateUserData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_RECORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPDATE_USER_RECORD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

/* Reducer for User profile */
export const getUserProfileData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const donatedAmountData = (
  state = {
    amount: 0.0,
    errorMsg: "",
    hideAmountInput: false,
    scrollToErrorMsg: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.DONATED_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case actionTypes.DONATED_AMOUNT_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SET_HIDE_AMOUNT_INPUT:
      return {
        ...state,
        hideAmountInput: action.payload,
      };
    case actionTypes.SCROLL_TO_AMOUNT_ERROR_MSG:
      return {
        ...state,
        scrollToErrorMsg: action.payload,
      };
    default:
      return state;
  }
};
export const setAmountInputStateData = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const creditCardInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_CREDIT_CARD_OWNER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_GIVE_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CREDIT_CARD_DETAILS:
      return {
        ...state,
        creditCardDetails: action.payload,
        checkDetails: null,
      };
    case actionTypes.SET_CHECK_DETAILS:
      return {
        ...state,
        checkDetails: action.payload,
        creditCardDetails: null,
      };
    default:
      return state;
  }
};

export const giveWithCardData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_WITH_CARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.GIVE_WITH_CARD_FAILURE:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.GIVE_WITH_CHECK_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.GIVE_WITH_CHECK_FAILURE:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.RESET_GIVE_RESPONSE:
      return {
        ...state,
        data: {},
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addChildData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_A_CHILD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.ADD_A_CHILD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const showMenuBar = (state = { isMobileShow: false }, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MOBILE_SIDE_BAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const updateUser2FA = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_2FA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const processCheckinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_CHECKIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.PROCESS_CHECKIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const deleteGuest = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_GUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.DELETE_GUEST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const processCheckOut = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_CHECKOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.PROCESS_CHECKOUT_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const contributionProcessingEnginesData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONTRIBUTION_PROCESSING_ENGINES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CONTRIBUTION_PROCESSING_ENGINES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const processPaypalData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_PAYPAL_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.PROCESS_PAYPAL_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const sermonData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SERMON_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SERMON_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const checkInListingsStatus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHECKIN_LISITNG_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CHECKIN_LISITNG_STATUS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const epayByToken = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EPAY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.EPAY_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const processEpayByToken = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_EPAY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.PROCESS_EPAY_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addPhoneNumber = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.ADD_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_PHONE_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_PHONE_NUMBER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deletePhoneNumber = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.DELETE_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_PHONE_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_PHONE_NUMBER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const selectedSermonData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_SERMON:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const selectedPaymentOption = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_PAYMENT_ENGINE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const enableGiveViaNav = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_VIA_NAV:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const checkinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FAMILY_CHECKIN:
      return {
        ...state,
        family: action.payload,
      };
    case actionTypes.GUEST_CHECKIN:
      return {
        ...state,
        guest: action.payload,
      };
    case actionTypes.SEND_NEW_GUEST:
      return {
        ...state,
        newGuest: action.payload,
      };
    case actionTypes.SPOUSE_CHECKIN:
      return {
        ...state,
        spouse: action.payload,
      };
    default:
      return state;
  }
};

export const preferredPaymentNewCard = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PREFERRED_PAYMENT_NEW_CARD_SUCCESS:
    case actionTypes.PREFERRED_PAYMENT_NEW_CARD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const preferredPaymentNewCheck = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PREFERRED_PAYMENT_NEW_CHECK_SUCCESS:
    case actionTypes.PREFERRED_PAYMENT_NEW_CHECK_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const appAccess = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.APP_ACCESS_SUCCESS:
    case actionTypes.APP_ACCESS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_APP_ACCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_APP_ACCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const quickGiveProcessingEngines = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.QUICKGIVE_SUCCESS:
    case actionTypes.QUICKGIVE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_APP_ACCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_APP_ACCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const celebrationsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CELEBRATION_SUCCESS_RESPONSE:
    case actionTypes.CELEBRATION_FAILURE_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_APP_ACCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_APP_ACCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getPrivacyPolicyHtml = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PRIVACY_SUCCESS_RESPONSE:
    case actionTypes.PRIVACY_FAILURE_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_APP_ACCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_APP_ACCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const generateUserOTP = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GENERATE_DELETION_OTP_SUCCESS:
    case actionTypes.GENERATE_DELETION_OTP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_APP_ACCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_APP_ACCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteUserAccount = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_USERS_ACCOUNT_SUCCESS:
    case actionTypes.DELETE_USERS_ACCOUNT_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_APP_ACCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_APP_ACCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const organizationList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MASTER_APP_ORGANIZATION_STOP:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case actionTypes.MASTER_APP_ORGANIZATION_START:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
