import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./styles.scss";
import { useWindowDimensions } from "../../utils/customHooks/useWindowDimensions";

const SupportRoles = ({ carouselImages = [] }) => {
  let { width } = useWindowDimensions();
  const images = [
    // {
    //   id: 1,
    //   url: "/images/courasel-one.png",
    //   subHeading: "Our Projects",
    //   heading:
    //     "Harvest of Kindness: Sustaining Lives Through Food Giving Project",
    //   description:
    //     "By providing nourishment to those without, we envision not only feeding hungry stomachs but also cultivating a landscape where empathy, support, and positive transformation flourish.",
    // },
    {
      id: 2,
      url: "/images/courasel-two.png",
      subHeading: "Our Projects",
      heading:
        "Harvest of Kindness: Sustaining Lives Through Food Giving Project",
      description:
        "By providing nourishment to those without, we envision not only feeding hungry stomachs but also cultivating a landscape where empathy, support, and positive transformation flourish.",
    },
    {
      id: 3,
      url: "/images/courasel-three.png",
      subHeading: "Our Projects",
      heading:
        "Harvest of Kindness: Sustaining Lives Through Food Giving Project",
      description:
        "By providing nourishment to those without, we envision not only feeding hungry stomachs but also cultivating a landscape where empathy, support, and positive transformation flourish.",
    },
    {
      id: 4,
      url: "/images/courasel-four.png",
      subHeading: "Our Projects",
      heading:
        "Harvest of Kindness: Sustaining Lives Through Food Giving Project",
      description:
        "By providing nourishment to those without, we envision not only feeding hungry stomachs but also cultivating a landscape where empathy, support, and positive transformation flourish.",
    },
  ];
  return (
    <div className="carousel">
      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        showIndicators={true}
        autoPlay={true}
        interval={3000}
        showStatus={false}
        stopOnHover={false}
        showArrows={false}
      >
        {images?.map((image, index) => (
          <div key={image.id}>
            <img
              src={width <= 768 ? carouselImages[index]?.bannerUrl : image.url}
              alt=""
            />
            <h3 className="sub-heading">{image.subHeading}</h3>
            <h2 className="sub-heading">{image.heading}</h2>
            <p className="sub-heading">{image.description}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SupportRoles;
