import React from 'react'
import { Home } from './Home'
import MasterApp from '../../components/MasterApp/MasterApp'

export default function MasterAppPage() {
  return (
    <Home>
        <MasterApp />
    </Home>
  )
}
