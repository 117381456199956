import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useSelector, shallowEqual } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useThemeColor } from '../../utils/customHooks/useThemeColor';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: props => props.backgroundColor,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem)

const ColorButton = withStyles((theme) => ({
    root: {
    //   color: '#111',
    //   backgroundColor: props => props.backgroundColor,
      '&:hover': {
        backgroundColor: props => props.backgroundColor,
      },
    },
  }))(IconButton);

function CustomizedMenu({menuList, menuIcon}) {
    const orgInfo = useSelector(
        (state) => state.orgInfo.responseData,
        shallowEqual
    );
    const [anchorEl, setAnchorEl] = React.useState(null)
    const themeColor = useThemeColor(); 
    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    return (
        <div>
            <ColorButton
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                variant="contained"
                // color="inherit"
                onClick={handleClick}
                backgroundColor={themeColor.color}
            >
                {menuIcon ? menuIcon :  <MoreVertIcon />}
            </ColorButton>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuList.map((item, index) => {
                    const handle = () => {
                        item.func();
                        handleClose()
                    }
                    return (
                <StyledMenuItem onClick={handle} key={index} backgroundColor={orgInfo.colorThemes[0].primary}>
                    <ListItemIcon>
                        <i className={item.iconName} />
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                </StyledMenuItem>

                    )

                })}
            </StyledMenu>
        </div>
    )
}

export const ReuseableMenu = ({children, openMenu, showBtn=false, btnText}) => {
    // const orgInfo = useSelector(
    //     (state) => state.orgInfo.responseData,
    //     shallowEqual
    //   );
    const themeColor = useThemeColor();

    function handleClick(event) {
        openMenu(event)
    }
    return (
        <div>
            {showBtn ? 
            // <Button size="small" variant="outlined" onClick={handleClick} className="px-5 py-2" style={{backgroundColor: themeColor.color, color: '#fff'}}>
            <Button size="small" variant="outlined" onClick={handleClick} className="px-5 py-2" color="inherit">
                {btnText}
            </Button> :
            <ColorButton
                variant="contained"
                // color="inherit"
                onClick={handleClick}
                backgroundColor={themeColor.color}
            >
                <MoreVertIcon />
            </ColorButton>}
            {children}
        </div>
    )
}

export default CustomizedMenu

