import React, {useEffect} from 'react'
import { getUserDetails } from '../../../utils/authHelpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DialogContent, Button } from "@material-ui/core";
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import { guestSubmittedForm } from '../../../store/actions/userDashboardActions/followup';

export default function GetFollowUpFormDetailsModal({open, onClose, userId}) {
    const orgInfo = useSelector(
        (state) => state.orgInfo.responseData,
        shallowEqual
      );
    
      const user = getUserDetails()
    
      const getFollowUpFormDetailsData = useSelector(
        (state) => state.getFollowUpFormDetailsData.responseData
      );
      const dispatch = useDispatch();
      
      // useEffect(() => {
      //   dispatch(getFollowUpByOrganizationID(orgInfo.orgId));
      // }, []);
    
      useEffect(() => {
        if (userId != undefined) {
          // dispatch(getFollowUpFormDetails(payload));
          const payload = {
            orgId: orgInfo?.orgId,
            adminUserId: user?.userId,
            recordId: userId?.recordId,
            guestId: userId?.guestId,
          };
          dispatch(guestSubmittedForm(payload))
        }
      }, [userId]);
  return (
    <div>
        <ResizeableModalWithoutBtn
            className="ml-5"
            modalState={open}
            title='Guest Details'
            // maxWidth="xs"
            fullWidth={true}
            resetModalState={onClose}
        >
            <DialogContent>
      <div>
        {getFollowUpFormDetailsData?.returnedRecords?.map((page, index) =>
          (
            <p key={index}>
              <strong>{page.question}</strong>: {page.answer}
            </p>
          ))
        }
      </div>
      <div
            className="flex items-center justify-between mt-4"
            style={{columnGap: '2rem'}}
          >

            <Button
              className="capitalize w-full"
              onClick={onClose}
              // disabled={loading}
              variant="outlined"
            >
              Ok
            </Button>
          </div>
      </DialogContent>

        </ResizeableModalWithoutBtn>
    </div>
  )
}
