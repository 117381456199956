import React from "react";
import { UserGive } from "../../components/User/Give";
import { Helmet } from "react-helmet";
import { useSelector, shallowEqual } from "react-redux";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import Layout from "./Layout";

export const Give = () => {
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  const siteKey = process.env.REACT_APP_RECAPTCHA
  return (
    <>
      {secondOrgInfo && (
        <Helmet>
          <title>{secondOrgInfo.metaData.give.title}</title>
          <meta
            name={secondOrgInfo.metaData.give.author}
            content={secondOrgInfo.metaData.give.description}
          />
        </Helmet>
      )}
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <Layout>
          <UserGive></UserGive>
        </Layout>
      </GoogleReCaptchaProvider>
    </>
  );
};
