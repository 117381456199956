import React from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useSelector, shallowEqual } from "react-redux";
import { ButtonContainer } from "../../Common/Button";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));
const OtherInfo = () => {
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const EmergencyContact = [
    {
      title: "Name",
      value: `${userProfileData?.userEmergencyContact1LastName} ${userProfileData?.userEmergencyContact1FirstName}`,
    },
    {
      title: "Email",
      value: userProfileData?.userEmergencyContact1Email,
    },
    {
      title: "Phone Number",
      value: userProfileData?.userEmergencyContact1Phone,
    },
  ];
  const EmergencyContact2 = [
    {
      title: "Name",
      value: `${userProfileData?.userEmergencyContact2LastName} ${userProfileData?.userEmergencyContact2FirstName}`,
    },
    {
      title: "Email",
      value: userProfileData?.userEmergencyContact2Email,
    },
    {
      title: "Phone Number",
      value: userProfileData?.userEmergencyContact2Phone,
    },
  ];
  const spouse = [
    {
      title: "Email",
      value: userProfileData?.userSpouseInfo?.spouseEmail,
    },
    {
      title: "Phone Number",
      value: userProfileData?.userSpouseInfo?.spousePhone,
    },
  ];
  const themeColor = useThemeColor();
  const classes = useStyles();
  let history = useHistory()

  const handleUpdate = (route) => {
    history.push(route)
  }
  return (
    <Card elevation={3} className="pt-2">
          <div className='d-flex'>
              <h5 className="p-4 m-0">Spouse</h5>
            <Tooltip title="Edit" aria-label="Edit">
          <span className="fa fa-pen icon-sm text-muted m-0 py-4 pt-5" role="button" onClick={() => handleUpdate('/family')}></span>
            </Tooltip>
          </div>
      {/* {userProfileData && Object.keys(userProfileData?.userSpouseInfo).length > 0 && (
        <> */}
          {" "}
          <Table className="mb-4">
            <TableBody>
            <TableRow>
                  <TableCell className="pl-4">Name</TableCell>
                  <TableCell>
                    <div className='d-flex'>
                    <Avatar
              alt="Remy Sharp"
              src={userProfileData?.userSpouseInfo?.spousePicture}
              // className={classes.large}
              /><span className='pt-3 pl-2'> {`${userProfileData?.userSpouseInfo?.spouseTitle ?? ''} ${userProfileData?.userSpouseInfo?.spouseFirstName ?? ''} ${userProfileData?.userSpouseInfo?.spouseLastName ?? ''}`}</span>
              </div>
              </TableCell>
                </TableRow>
              {spouse.map((item, ind) => (
                <TableRow key={ind}>
                  <TableCell className="pl-4">{item.title}</TableCell>
                  <TableCell>{item.value}</TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
                {/* <div className='d-flex justify-content-center'>

                  <Link to="/family">
                    <button
                      style={themeColor.btnStyle}
                      className="btn w-100 mt-5 font-weight-bold text-white py-4 px-9 mb-5"
                      >
                      {userProfileData?.userChildrenInfo.length > 0 ? 'View Children' : 'Add Children'}
                    </button>
                    </Link>
                      </div> */}
    
    </Card>
  );
};

export default OtherInfo;
