import React from 'react'
import {Paper, Button} from '@material-ui/core'
import { ButtonContainer } from '../../Common/Button'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { GenerateUserOtp, deleteUserAccount } from '../../../store/actions';
import PinInputComponent from '../../Common/PinInput';
import { toast } from 'react-toastify';

export default function DeleteAccount() {
  const generateUserOTPLoading = useSelector((state) => state.generateUserOTP.loading)
  const generateUserOTPData = useSelector((state) => state.generateUserOTP)
  const deleteUserAccountLoading = useSelector((state) => state.deleteUserAccount.loading)
  const userDetails = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const [loading, setIsLoading] = React.useState(false)
  const [firstLoader, setFirstLoader] = React.useState(false)
  const [pinDisplay, setPinDisplay] = React.useState(false)
  const [firstDialog, setFirstDialog] = React.useState(false)
  const [secondDialog, setSecondDialog] = React.useState(false)
  const [otpInput, setOTPInput] = React.useState('')
  const themeColor = useThemeColor();
  const dispatch = useDispatch()
  const backgroundColor= themeColor.color

  const user = JSON.parse(localStorage.getItem('user'))

  const handleClick = () => {
    // console.log('clicked');
    setFirstDialog(true)
  }
  const handleCallback = () => {
    setPinDisplay(true)
    setFirstDialog(false)

  }

  const handleGenerateOtp = () => {
    try {
      if (secondDialog && otpInput !== generateUserOTPData?.data?.otp) {
        return toast.error('Inputted OTP is wrong')
      }
      if (secondDialog) {
        const payload = {
          "user_id": user?.responseData?.userId,
          "org_id": orgInfo.orgId,
          "otp": generateUserOTPData?.data?.otp
        }
        return dispatch(deleteUserAccount(payload))
      }
      // setFirstLoader(true)
      dispatch(GenerateUserOtp(handleCallback))
    } catch (error) {
      console.log(error, 'error');
    }
  }

  const handleSubmit = (data) => {
    setFirstDialog(true)
    setSecondDialog(true)
    setOTPInput(data)
  }
  // console.log(generateUserOTPData, 'generateUserOTPData');
  return (
    <Paper className="w-100 page-wrapper my-8 p-6">
      {!pinDisplay && <div className="col-md-10 m-auto col-lg-6 flex text-center">
        <h6 className='py-3' style={{fontWeight: 'bold'}}>Delete my Account</h6>
        <h4 className='pb-3'>Please note that this action is irreversible. All data will be permanently deleted from the church database. Please proceed with care
        </h4>
        <ButtonContainer
          // id="kt_login_signin_submit"
          type="button"
          // disabled={loading}
          // style={primaryBgColor}
          backgroundColor={backgroundColor}
          className="col-sm-5 mr-5 py-4"
          onClick={handleClick}
        >
          <span>{loading ? "Please wait..." : 'Delete my Account'}</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </ButtonContainer>
      </div>}
      {pinDisplay && 
      <div className="col-md-10 m-auto col-lg-6">
        <h4 className='pt-4 text-center'>Please note that the action is irreversible. All data will be permanently deleted from church database.
          please proceed with care.
        </h4>
        <h6 className='text-center' style={{fontWeight: 'bold'}}>Delete Code: {generateUserOTPData?.data?.otp}</h6>
        <PinInputComponent 
          setInputValue={handleSubmit}
        />
      </div> }
      {
      <ResizeableModalWithoutBtn
        modalState={firstDialog}
        resetModalState={() => setFirstDialog(false)}
        title='Delete Account Confirmation'
        onClose={() => setFirstDialog(false)}
        maxWidth='xs'
      >
        <h4>Are you sure you want to delete your account?</h4>
        {generateUserOTPLoading || deleteUserAccountLoading ? (
          <Skeleton variant="text" />
        ) : (
          <div
            className="d-flex items-center  justify-content-between p-4"
            style={{ columnGap: "2rem" }}
          >
            <Button
              className="btn btn-primary w-full"
              onClick={handleGenerateOtp}
              backgroundColor={backgroundColor}
              variant="contained"
              color="primary"
            >
              Ok
            </Button>
            <Button
              className="btn btn-primary w-full"
              onClick={() => setFirstDialog(false)}
              backgroundColor={backgroundColor}
              variant="outlined"
            >
              Close
            </Button>
          </div>
        )}
        </ResizeableModalWithoutBtn>}
    </Paper>
  )
}
