import React from 'react'
import axios from 'axios';
import {toast} from 'react-toastify'
import { actionTypes } from '../../actionTypes';
import { checkNodeEnv } from '../../../utils/authHelpers';
import { defaultAction, apiUrl } from '../../../utils/constants';
import { axiosPayload, startLoadingAction, resetEndpointDataAction, stopLoadingAction, axiosMultipartPayload } from '../index';
import NotificationMesage from '../../../components/Common/NotificationMesage';



export const followUpListings = (payload) => {
    return async (dispatch) => {
      dispatch({
        type: actionTypes.START_LOADING_FOLLOWUP
      })
      const url = checkNodeEnv(
        apiUrl.followUpListings('live-api'),
        apiUrl.followUpListings('test-api')
      );
      try {
        const response = await axios(axiosPayload(url, payload));
        const data = await response.data;
        dispatch({
          type: actionTypes.STOP_LOADING_FOLLOWUP
        })
        if (data.responseCode === '00') {
          return dispatch(
            defaultAction(
              actionTypes.GET_FOLLOW_UP_BY_ORGANIZATION_ID_SUCCESS,
              data
            )
          );
        } else {
          return dispatch(
            defaultAction(
              actionTypes.GET_FOLLOW_UP_BY_ORGANIZATION_ID_FAILURE,
              data
            )
          );
        }
      } catch (error) {
        console.log(error, "error");
        dispatch({
          type: actionTypes.STOP_LOADING_FOLLOWUP
        })
        toast.warn("Network Error. Please check your connectivity and try again");
      }
    };
  };

  export const followUpLogActivity = (payload, callback) => {
    return async (dispatch) => {
      const url = checkNodeEnv(
        apiUrl.followUpLogActivity('live-api'),
        apiUrl.followUpLogActivity('test-api')
      );
      try {
        const response = await axios(axiosMultipartPayload(url, payload));
        const data = await response.data;
        if (data.responseCode === '00') {
          toast.success(
            <NotificationMesage
              responseStatus={data.responseCode}
              message={data.responseMessage}
            />
          );
          callback();
          return dispatch(
            defaultAction(actionTypes.CREATE_ACTIVITY_LOG_SUCCESS, data)
          );
        } else {
          toast.error(
            <NotificationMesage
              responseStatus={data.responseCode}
              message={data.responseMessage}
            />
          );
          return dispatch(
            defaultAction(actionTypes.CREATE_ACTIVITY_LOG_FAILURE, data)
          );
        }
      } catch (error) {
        console.log(error.response, "error");
        toast.warn("Network Error. Please check your connectivity and try again");
      }
    };
  };

  export const guestActivityLog = (payload, callback) => {
    return async (dispatch) => {
      const url = checkNodeEnv(
        apiUrl.guestActivityLog('live-api'),
        apiUrl.guestActivityLog('test-api')
      );
      try {
        const response = await axios(axiosPayload(url, payload));
        const data = await response.data;
        if (data.responseCode === '00') {
          callback && callback();
          return dispatch(
            defaultAction(actionTypes.GET_ACTIVITY_LOG_SUCCESS, data)
          );
        } else {
          toast.error(
            <NotificationMesage
              responseStatus={data.responseCode}
              message={data.responseMessage}
            />
          );
          return dispatch(
            defaultAction(actionTypes.GET_ACTIVITY_LOG_FAILURE, data)
          );
        }
      } catch (error) {
        console.log(error, "error");
        toast.warn("Network Error. Please check your connectivity and try again");
      }
    };
  };

  export const guestSubmittedForm = (payload) => {
    return async (dispatch) => {
      const url = checkNodeEnv(
        apiUrl.guestSubmittedForm('live-api'),
        apiUrl.guestSubmittedForm('test-api')
      );
      try {
        const response = await axios(axiosPayload(url, payload));
        const data = await response.data;
        if (data.responseCode === '00') {
          return dispatch(
            defaultAction(actionTypes.GET_FOLLOW_UP_FORM_SUCCESS, data)
          );
        } else {
          return dispatch(
            defaultAction(actionTypes.GET_FOLLOW_UP_FORM_FAILURE, data)
          );
        }
      } catch (error) {
        console.log(error, "error");
        toast.warn("Network Error. Please check your connectivity and try again");
      }
    };
  };