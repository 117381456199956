import React from 'react';
import { Button, withStyles } from '@material-ui/core'

 const ButtonStyle = withStyles((theme) => ({
	root: {
	//   color: theme.palette.getContrastText(purple[500]),
	  color: '#fff',
	  backgroundColor: props => props.backgroundColor,
	  '&:hover': {
		backgroundColor: props => props.backgroundColor,
		color: '#fff'
	  }
	},
  }))(Button);


export const ButtonContainer = (props) => {
    return (
        <ButtonStyle 
            backgroundColor={props.backgroundColor}
            className={`px-5 py-3 ${props.className}`}
            onClick={props.onClick}
            type={props.type || 'button'}
            disabled={props.disabled}
        >
            {props.children}
        </ButtonStyle>
    )
}