import { TextField } from "@material-ui/core";
import { addYears } from "date-fns";
import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function InputWithDate({ startDate, label, setStartDate, showTimeSelect =true, dateFormat="yyyy-MM-dd h:mm aa" }) {
  
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField label={label} variant='outlined' onClick={onClick} ref={ref} value={value} size='small' fullWidth className="w-full" />
     ));
  
  return (
    <div>
      <DatePicker
        dateFormat={dateFormat}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        customInput={<ExampleCustomInput />}
        isClearable
        className='datepicker-class'
        showTimeSelect={showTimeSelect}
        maxDate={addYears(new Date(), 1)}
        minDate={new Date()}
        placeholderText="Select a date between today and 1 years in the future"
        wrapperClassName='w-full'
      />
    </div>
  );
}
