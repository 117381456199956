import React from 'react'
import {useThemeColor} from '../../utils/customHooks/useThemeColor'
import {useHistory} from 'react-router-dom'
import {Button} from '@material-ui/core'

export default function Success() {
    const themeColor = useThemeColor();
    let history = useHistory()
    const goBack = () => {
        history.push('/')
      }
    return (
        <div className="login-form login-signin activeForm-wrapper px-3">
        <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={themeColor.btnStyle}>
              <i className="fa fa-thumbs-up"></i>
            </span>
          </div>
          <h3 className="font-size-h3 mb-2">Thank You</h3>
        </div>
        {/* <hr /> */}
            <div className="text-center my-10 my-lg-10">
              <h6><strong>We are glad to hear from you. Someone from the follow up team will reach out to you shortly.</strong></h6>
            </div>

              <div className="d-flex justify-content-center  navigator-wrapper mt-15 ">
              <Button onClick={goBack}  variant="secondary" className={`btn btn-lg py-3 text-white`} style={themeColor.btnStyle}>Go to Home</Button>
            </div>
        </div>
    )
}
