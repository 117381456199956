import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller, get } from "react-hook-form";
import { TextField } from '@material-ui/core'
import { getUserDetails } from '../../../utils/authHelpers'


export const AccountCheckForm = ({ getAccMemo, getAccountNumber, getRoutingNumber, getAccLastName, getAccFirstName }) => {

  const { handleSubmit, errors, control, watch } = useForm({
    mode: "onBlur",
  });
  
  const user = getUserDetails();
  // const orgInfo = useSelector((state) => state.orgInfo.responseData);
  // const allowCheck = orgInfo?.orgGeneralSettings?.allowCheckAch;
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo?.orgColors?.primary : '', border: 'none' }
  // const creditCardInfo = useSelector((state) => state.creditCardInfo);
  
  /** This is global state of memo */
  const memo = watch('memo')
  const accountNumber = watch('accountNumber')
  const routingNumber = watch('routingNumber')
  const firstName = watch('firstName')
  const lastName = watch('lastName')

  useEffect(() => {
    if(memo) getAccMemo(memo);
    if(accountNumber) getAccountNumber(accountNumber);
    if(routingNumber) getRoutingNumber(routingNumber);
    if(firstName) getAccLastName(firstName);
    if(lastName) getAccFirstName(lastName);
    console.log(memo, 'acct memo')
  }, [memo, accountNumber, routingNumber, firstName, lastName]);
  
let memoColor;
// let acctColor;

if (memo?.length >= 80 && memo?.length < 95) {
  memoColor = 'text-warning';
}else if(memo?.length >= 95) { 
  memoColor = 'text-danger';
} else {
  memoColor = 'text-muted'
}
// if (acctMemo?.length >= 80 && acctMemo?.length < 95) {
//   acctColor = 'text-warning';
// }else if(acctMemo?.length >= 95) { 
//   acctColor = 'text-danger';
// } else {
//   acctColor = 'text-muted'
// }


  return (
    <>
      <div>
        <div className="form-group fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <Controller
              as={
                <TextField
                  label='First Name'
                  name="firstName"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  error={errors.firstName ? true : false}
                  // inputProps={{ pattern: "/^[1-9]\d*$/g" }}
                />
              }
              name="firstName"
              control={control}
              defaultValue={user.userFirstName || ""}
              rules={{
                required: true
              }}
            />
          </div>
          {errors.firstName?.type === 'required' && <span className="text-danger errorText">{'First Name is Required'}</span>}
        </div>
        <div className="form-group fv-plugins-icon-container mb-8">
          <div className="position-relative">
          <Controller
              as={
                <TextField
                  label='Last Name'
                  name="lastName"
                  variant="outlined"
                  type="text"
                  size="small"
                  fullWidth
                  error={errors.lastName ? true : false}
                  // inputProps={{ pattern: "/^[1-9]\d*$/g" }}
                />
              }
              name="lastName"
              control={control}
              defaultValue={user.userLastName || ""}
              rules={{
                required: true
              }}
            />
          </div>
        {errors.lastName?.type === 'required' && <span className="text-danger errorText">{'Last Name is Required'}</span>}
        </div>
        <div className="form-group fv-plugins-icon-container mb-8">
          <div className="position-relative">
          <Controller
              as={
                <TextField
                  label='Account Number'
                  name="accountNumber"
                  variant="outlined"
                  type="number"
                  size="small"
                  fullWidth
                  error={errors.accountNumber ? true : false}
                  inputProps={{ pattern: "/^[1-9]\d*$/g" }}
                />
              }
              name="accountNumber"
              control={control}
              defaultValue={ ""}
              rules={{
                required: true
              }}
            />
          </div>
        {errors.accountNumber?.type === 'required' && <span className="text-danger errorText">{'Account Number is Required'}</span>}
        </div>
      </div>
      <div>
        <div className="form-group fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <Controller
              as={
                <TextField
                  label='Routing Number'
                  name="routingNumber"
                  variant="outlined"
                  type="number"
                  size="small"
                  fullWidth
                  defaultValue={""}
                  error={errors.routingNumber ? true : false}
                />
              }
              name="routingNumber"
              control={control}
              defaultValue={""}
              rules={{
                required: true,
              }}
            />
          </div>
        {errors.routingNumber && <span className="text-danger errorText">{'Rounting Number is Required'}</span>}
        </div>
      </div>
      <div>
      <div className="form-group fv-plugins-icon-container mb-8">
        <div className="position-relative">
          <Controller
              as={
                <TextField
                  label="Memo"
                  type="text"
                  name="memo"
                  maxLength={100}
                  defaultValue={''}
                  fullWidth
                  rows={3}
                  size="small"
                  multiline
                  variant="outlined"
                  inputProps={{maxLength: 100}}
                />
              }
              name="memo"
              control={control}
              defaultValue={""}
            />
          </div>
          <p className={`${memoColor}`}>{`${!memo ? 100 : 100 - memo?.length} characters remaining`}</p>
        </div>
      </div>
    </>
  )
}