import React from "react";
import { Signup } from "../../components/Forms/Signup";
import { Home } from "./Home";
import { Helmet } from "react-helmet";
import { useSelector, shallowEqual } from "react-redux";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

export const SignupPage = () => {
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  const siteKey = process.env.REACT_APP_RECAPTCHA
  return (
    <>
      {secondOrgInfo && (
        <Helmet>
          <title>{secondOrgInfo.metaData.signUp.title}</title>
          <meta
            name={secondOrgInfo.metaData.signUp.author}
            content={secondOrgInfo.metaData.signUp.description}
          />
          <meta
            name='Keywords'
            content={secondOrgInfo.metaData.signUp.keywords.toString()}
          />
        </Helmet>
      )}
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      <Home>
        <Signup />
      </Home>

      </GoogleReCaptchaProvider>
    </>
  );
};
