import React from "react";
import Login from "../../components/Forms/Login";
import { Home } from "./Home";
import { Helmet } from "react-helmet";
import { useSelector, shallowEqual } from "react-redux";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

export const SigninPage = () => {
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  const siteKey = process.env.REACT_APP_RECAPTCHA
  return (
    <>
      {secondOrgInfo && (
        <Helmet>
          <title>{secondOrgInfo.metaData.login.title}</title>
          <meta
            name={secondOrgInfo.metaData.login.author}
            content={secondOrgInfo.metaData.login.description}
          />
          <meta name='keywords' content={secondOrgInfo.metaData.login.keywords.toString()} />
        </Helmet>
      )}
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      <Home>
        <Login />
      </Home>
      </GoogleReCaptchaProvider>;
    </>
  );
};
