import React, { useState, useEffect} from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useForm, Controller } from "react-hook-form"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { updateUserRecord, getUserProfile } from '../../../store/actions/'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { toast } from 'react-toastify';
import NotificationMesage from '../../Common/NotificationMesage';
import {ButtonContainer} from '../../Common/Button'

import './Mailing.scss'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export const Mailing = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const themeColor = useThemeColor();
  const userData = JSON.parse(localStorage.getItem('user'))
  const [zipcodeOrPostalcodeError, setZipcodeOrPostalcodeError] = useState('')
  const [country, setCountry] = useState()
  const [stateOrProvince, setStateOrProvince] = useState('')
  const [cityOrTownError, setCityOrTownError] = useState('')
  

  const initialValues = {
    address1: '',
    address2: '',
    cityOrTown: '',
    zipcodeOrPostalcode: ''
  };

  // Global store data
const ipData = useSelector(state => state.ipData);
const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
const updateUserData = useSelector(state => state.updateUserData, shallowEqual)
const primaryBgColor = themeColor.btnStyle;
const userProfileData = useSelector(
  (state) => state.getUserProfileData.responseData,
  shallowEqual
);
const backgroundColor = themeColor.color;


  const UpdateMailingSchema = Yup.object().shape({
    address1: Yup.string()
      .min(2, 'Minimum of 2 characters')
      .max(100, 'Maximum of 100 characters'),
    address2: Yup.string()
      .min(2, 'Minimum of 2 characters')
      .max(100, 'Maximum of 100 characters'),
    cityOrTown: Yup.string()
      .min(2, 'Minimum of 2 characters')
      .max(64, 'Maximum of 64 characters'),
    zipcodeOrPostalcode: Yup.string()
      .min(2, 'Minimum of 2 characters')
      .max(20, 'Maximum of 20 characters'),
  });
 
   const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleCallback = () => {
    dispatch(getUserProfile({'orgId': orgInfo.orgId, 'userId': userProfileData?.userId}))
  }

  const { handleSubmit, errors, control, watch, register, getValues } = useForm({
    mode: "onBlur",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: UpdateMailingSchema,
    onSubmit: (values) => {
      console.log(values)
      const {address1, address2, cityOrTown, zipcodeOrPostalcode } = values;
    
     if (address1.trim() !== '' && cityOrTown.trim() === '') {
        setZipcodeOrPostalcodeError('')
        
        return setCityOrTownError('City or Province is required')
      } else if(address1.trim() !== '' && zipcodeOrPostalcode.trim() === '') {
        setCityOrTownError('')
        
        return setZipcodeOrPostalcodeError('Zip Code field is required')
      } else {
        setCityOrTownError('')
        setZipcodeOrPostalcodeError('')
        
        return dispatch(updateUserRecord({address1, address2, cityOrTown, country, stateOrProvince, zipcodeOrPostalcode, userId: userData.responseData.userId, orgId: orgInfo.orgId}, handleCallback)) 
      }
    },
  });

  React.useEffect(() => {
    if (userProfileData) {
      setCountry(userProfileData?.userCountry)
      setStateOrProvince(userProfileData?.userStateOrProvince)
    }
  
  }, [userProfileData])

  const onSubmit = (data) => {
    // console.log(data);
    const {address1, address2, cityOrTown, zipcodeOrPostalcode} = data;
    const payload = {
      address1,
      address2,
      cityOrTown,
      zipcodeOrPostalcode,
      country,
      stateOrProvince,
      userId: userProfileData.userId, 
      orgId: orgInfo.orgId
    }
    return dispatch(updateUserRecord(payload, handleCallback)) 
  }

  return (
    <Paper className="w-100 page-wrapper my-8 p-6">
      <div>
        <span className="mailing-address mr-2">My Mailing Address</span>
        <span className="text-muted"> Where do I live?</span>
      </div>
      <hr />
      <div className="col-md-5 m-auto">
        {userProfileData &&
        <form
        onSubmit={handleSubmit(onSubmit)}
        className="form fv-plugins-bootstrap fv-plugins-framework mt-10">
          <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <Controller
            as={<TextField
              id="outlined-with-address1"
              label="Address 1"
              placeholder="Address 1"
              name="address1"
              // className={classes.textField}
              // margin="normal"
              fullWidth
              variant="outlined"
              // error = {formik.touched.address1 && formik.errors.address1}
              // {...formik.getFieldProps('address1')}
              defaultValue={userProfileData?.userAddress1 || ''}
            />}
            control={control}
            name='address1'
            defaultValue={userProfileData?.userAddress1 || ''}
            />
          </div>
        </div>
            {/* Address 2 */}
        <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <Controller
            as={<TextField
              id="outlined-with-address2"
              label="Address 2"
              placeholder="Address 2"
              name="address2"
              // className={classes.textField}
              // margin="normal"
              fullWidth
              variant="outlined"
              defaultValue={userProfileData?.userAddress2 || ''}
              // error = {formik.touched.address2 && formik.errors.address2}
              // {...formik.getFieldProps('address2')}
            />}
            name='address2'
            defaultValue={userProfileData?.userAddress2 || ''}  
            control={control} 
            />
          </div>
        </div>
            {/* City / Town */}
        <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <Controller
            as={<TextField
              id="outlined-with-city"
              label="City/Town"
              placeholder="City/Town"
              name="cityOrTown"
              // className={classes.textField}
              // margin="normal"
              fullWidth
              defaultValue={userProfileData?.userCityOrTown || ''}
              variant="outlined"
              // error = {formik.touched.cityOrTown && formik.errors.cityOrTown}
              // {...formik.getFieldProps('cityOrTown')}
            />}
            name='cityOrTown'
            control={control}
            defaultValue={userProfileData?.userCityOrTown || ''}
            />
          </div>
        </div>

        <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <label htmlFor="country" className="font-weight-bolder">Country:</label>
            <CountryDropdown
              value={country || userProfileData?.userCountry}
              onChange={(val) => setCountry(val)}
              className={`form-control h-auto py-5 px-6 pl-9 ${getInputClasses(
                'country')}`}
          />
          <span className="far fa-flag email-icon left-span position-absolute"></span>
          </div>
        </div>
        
        <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <label htmlFor="stateOrProvince" className="font-weight-bolder">State/Province:</label>
            
         <RegionDropdown
            country={country || userProfileData?.userCountry}
            value={stateOrProvince || userProfileData?.userStateOrProvince}
            onChange={val => setStateOrProvince(val)}
            className={`form-control h-auto py-5 px-6 pl-9`}
          />
           <span className="far fa-handshake email-icon left-span position-absolute"></span>
        </div>
      </div>
          <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative">
            <Controller
             as={<TextField
              id="outlined-with-city"
              label="Zipcode/Postcode"
              placeholder="Zipcode/Postcode"
              name="zipcodeOrPostalcode"
              // className={classes.textField}
              // margin="normal"
              fullWidth
              variant="outlined"
              defaultValue={userProfileData?.userZipOrPostalCode}
            />}
            name='zipcodeOrPostalcode'
            control={control}
            defaultValue={userProfileData?.userZipOrPostalCode}
            />
          </div>
        </div>
        <hr className="py-2" />
          <div className="col-sm-12 p-0 d-flex justify-content-between">
            {/* <Link to="/dashboard" className="btn btn-secondary text-capitalize px-9 ml-3">cancel</Link> */}
            <ButtonContainer type="submit"
              className="col-sm-5 py-4"
              disabled={updateUserData.loading}
              // style={primaryBgColor}
              backgroundColor={backgroundColor}
            >
              <span>{updateUserData.loading ? 'please wait...' : 'Update'}</span>
              {updateUserData.loading && <span className="spinner spinner-white ml-2"></span>}
            </ButtonContainer>
            <Link component={Button} to="/dashboard" elevation={0} className=" px-5 py-4 col-sm-5" variant="contained">
              Cancel
            </Link>
          </div>
          </form> }
       </div>
      </Paper>
  )
}