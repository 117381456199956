import React, { useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CelebrationCard from './CelebrationCard';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';


export default function CelebrationCarousel() {
  const history = useHistory()
  const celebrationsListingData = useSelector((state) => state.celebrationsListing.responseData)
  const {name} = useParams()
  const [isPaused, setIsPaused] = useState(false)

  React.useEffect(() => {
    if (celebrationsListingData === undefined || celebrationsListingData[name] === undefined) {
      return history.push(`/celebration`)
    }
    const audio = new Audio();
    if (celebrationsListingData && name && celebrationsListingData[name]?.backgroundMusic === 'true') {
      let played = 0
      audio.addEventListener('ended', () => {
        played = ++played < celebrationsListingData[name]?.musicFiles?.length ? played : 0
        audio.src= celebrationsListingData[name]?.musicFiles[played]
        audio.play();
      })
      audio.volume = 0.3;
      audio.loop = false;
      audio.src = celebrationsListingData[name]?.musicFiles[0];
      audio.play();

    }

    if (isPaused) {
      audio.pause()
    } else {
      audio.play()
    }
    
    return () => {
      audio.removeEventListener('ended', () => {
        audio.pause()
      })
      audio.pause()
    }
  }, [celebrationsListingData, name, isPaused])
  

  if (celebrationsListingData === undefined) {
    history.push(`/celebration`)
    return null
  }
  return (
    <>
    {celebrationsListingData && celebrationsListingData[name]?.list?.length > 0 && 
      <Carousel
          autoPlay={celebrationsListingData[name]?.enableAutoScroll === 'true'}
          // centerMode={true}
          interval={Number(celebrationsListingData[name]?.autoScrollEvery)}
          infiniteLoop={true}
          stopOnHover={false}
      >
          {celebrationsListingData[name]?.list?.map((item, index) => (
              <CelebrationCard key={index} img={item} name={name} isPaused={isPaused} setIsPaused={setIsPaused} isShowPlay={celebrationsListingData[name]?.backgroundMusic} />
          ))}
      </Carousel> }
    </>
  )
}
