import React from 'react'
import { Home } from "./Home";
import Success from '../../components/Followup/Success'

export default function FollowupSuccessPage() {
    return (
        <Home>
          <Success />  
        </Home>
    )
}
