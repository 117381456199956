import React from 'react';
import { Card, Icon, Grid, Checkbox, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import './serviceUnit.css'


const useStyles = makeStyles(({ palette, ...theme }) => ({
    gridCard: {
      '& .grid__card-overlay': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        opacity: 0,
        transition: 'all 250ms ease-in-out',
        background: 'rgba(0, 0, 0, 0.67)',
  
        '& > div:nth-child(2)': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: -1,
        },
      },
      '& .grid__card-bottom': {
        '& .email': {
          display: 'none',
        },
      },
      '&:hover': {
        '& .grid__card-overlay': {
          opacity: 1,
        },
        '& .grid__card-bottom': {
          '& .email': {
            display: 'block',
          },
          '& .date': {
            display: 'none',
          },
        },
      },
    },
    gridCardToggle: {
      '& .grid__card-overlay': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        opacity: 1,
        transition: 'all 250ms ease-in-out',
        background: 'rgba(0, 0, 0, 0.67)',
  
        '& > div:nth-child(2)': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: -1,
        },
      },
      '& .grid__card-bottom': {
        '& .email': {
          display: 'block',
        },
        '& .date': {
          display: 'none',
        },
      },
      // '&:hover': {
      //   '& .grid__card-overlay': {
      //     opacity: 1,
      //   },
      //   '& .grid__card-bottom': {
      //     '& .email': {
      //       display: 'block',
      //     },
      //     '& .date': {
      //       display: 'none',
      //     },
      //   },
      // },
    },
  }));
  
  const calculateColumnPerRow = (value) => {
    if (value === 25) {
      return 2;
    }
    if (value === 50) {
      return 3;
    }
    if (value === 75) {
      return 4;
    }
    if (value === 100) {
      return 7;
    }
  };
  
  const conditionToDisable = (condition) => {
    if (condition === 'disabled') {  // add other conditions
      return true
    }
  }
export default function GridView({
    list = [],
    sliderValue = 50,
    buttonAction,
    iconEdit,
    iconDel,
    iconAdd,
    addTooltip,
    editTooltip,
    showIcon = true,
    name,
    showImage=false,
    imageTooltip,
    changeImage,
    gridClass='',
    toggleIcon,
    toggleTooltip,
    iconToggle,
    buttonActionText = 'View Details',
    bannerStyleImage={},
    imageStyle={},
    showThreeSubTitle=false,
    showMouseMove=false,
    isViewBackgroundImage = false,
    viewBackgroundImage,
    sendMessage = false,
    handleOpenSendMessage,
}) {
    const classes = useStyles();
  return (
    <div>
        <Grid container spacing={2} className={gridClass}>
        {list.map((item, index) => (
          <Grid item md={calculateColumnPerRow(sliderValue)} sm={12} key={item.id} className='gridview-container-view'>
            <Card
              className={conditionToDisable(item.status) ? clsx('flex-column h-full', classes.gridCardToggle) : clsx('flex-column h-full', classes.gridCard)}
              elevation={6}
              style={bannerStyleImage}
            >
              <div className="grid__card-top text-center position-relative">
                {item.type === 'video' ? (
                  <video className="d-block w-full" height='400' controls>
                  <source src={item?.projectImage} type={`video/${item?.type}`} />
                Your browser does not support the video tag.
                </video>
                ) : <img
                  className="block w-100"
                  src={item.projectImage}
                  alt="project"
                  style={imageStyle}
                />}
                <div className="grid__card-overlay flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <Checkbox className="text-white"></Checkbox> */}
                    <div
                      className="d-flex align-items-center pt-3"
                      style={{ marginRight: 'auto' }}
                    >
                      {isViewBackgroundImage && <Tooltip title='View Background Image' placement='top'>
                        <Icon
                            fontSize="small"
                            className="mx-3 cursor-pointer text-white"
                            onClick={() => viewBackgroundImage(item)}
                          >
                            filter_none
                          </Icon>

                      </Tooltip>}
                      {item.membersTotal >= 1 && sendMessage && <Tooltip title='Send Message' placement='top'>
                        <Icon
                            fontSize="small"
                            className="mx-2 cursor-pointer text-white"
                            onClick={() => handleOpenSendMessage(item)}
                          >
                            sms
                          </Icon>

                      </Tooltip>}

                    </div>
                    <div
                      className="d-flex align-items-center pt-3"
                      style={{ marginLeft: 'auto' }}
                    >
                      {/* <Icon
                        fontSize="small"
                        className="mr-3 cursor-pointer text-white"
                      >
                        filter_none
                      </Icon> */}

                      <React.Fragment>
                        {toggleIcon && (
                          <Tooltip title={toggleTooltip} placement="top">
                            <div className='pr-3'>
                            {/* <CustomizedSwitch
                              getSwitchValue={(value) => iconToggle(item, value)}
                              checked={item.status === 'enabled' ? true : false}
                            /> */}

                            </div>
                            <Icon
                              fontSize="small"
                              className="mr-3 cursor-pointer text-white"
                              onClick={() => iconToggle(item)}
                            >
                              toggle_on
                            </Icon>
                          </Tooltip>
                        )}
                        {showIcon && (
                          <Tooltip title={addTooltip} placement="top">
                            <Icon
                              fontSize="small"
                              className="mr-3 cursor-pointer text-white"
                              onClick={() => iconAdd(item)}
                            >
                              add
                            </Icon>
                          </Tooltip>
                        )}
                        <Tooltip title={editTooltip} placement="top">
                          <Icon
                            fontSize="small"
                            className="mr-3 cursor-pointer text-white"
                            onClick={() => iconEdit(item)}
                          >
                            edit
                          </Icon>
                        </Tooltip>
                        {showImage && <Tooltip title={imageTooltip} placement="top">
                          <Icon
                            fontSize="small"
                            className="mr-3 cursor-pointer text-white"
                            onClick={() => changeImage(item)}
                          >
                            image
                          </Icon>
                        </Tooltip>}
                      </React.Fragment>

                      {item.isDeletable === 'true' && (
                        <Tooltip title={`Delete ${name || item?.projectName}`} placement="top">
                          <Icon
                            fontSize="small"
                            className="mr-3 cursor-pointer text-white"
                            onClick={() => iconDel(item)}
                          >
                            delete
                          </Icon>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    {item.membersTotal >= 1 && (
                      <Button
                        variant="outlined"
                        className="text-white border-color-white"
                        onClick={() => buttonAction(item)}
                      >
                        {buttonActionText}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid__card-bottom text-center py-2">
                <p className="m-0" style={{fontSize: '20px'}}>{item.projectName}</p>
                <span className="date text-muted block">{item.email}</span>
                {showThreeSubTitle && <span className="date text-muted block">{item.lowerLabel2}</span>}
                {showMouseMove && <small
                  className="email text-muted cursor-pointer"
                  onClick={() => buttonAction(item)}
                >
                  {item.email}
                </small>}
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
