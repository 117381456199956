import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {
  giveWithAnExistingCardCheckingPortal,
  giveWithCard,
} from "../../store/actions/userDashboardActions/give";
import {
  giveWithCheck,
} from "../../store/actions";
import { getUserDetails } from "../../utils/authHelpers";
import SuccessGiveComponent from "./SuccessPage";
import Navigator from "../../components/Navigator";
import { capitalizeFirstLetter, maskString } from "../../utils";

export const CardDetailsReview = ({
  giveFormData,
  checkForm = false,
  goBack,
  contributionType,
  orgId,
  amount,
  userInfo,
  isExisting,
  selectedCard,
  isSuccessCard,
  setIsSuccessCard,
  successMsg,
  setSuccessMsg,
  memo,
}) => {
  const [showCvv, setShowCvv] = useState(false);
  const [showNumber, setShowNumber] = useState(false);

  const giveWithANewCardData = useSelector(
    (state) => state.giveWithANewCardData
  );
  const newCardDetails = useSelector((state) => state.newCardDetails);
  const newCheckDetails = useSelector((state) => state.newCheckDetails);
  const creditCardInfo = useSelector((state) => state.creditCardInfo);
  const selectedPayment = useSelector((state) => state.selectedPaymentOption);

  let totalContribution = 0;
  if (giveFormData?.listOfContributions) {
    giveFormData.listOfContributions.forEach((item) => {
      totalContribution += item.amount;
    });
  } else if (newCardDetails?.listOfContributions) {
    newCardDetails.listOfContributions.forEach((item) => {
      totalContribution += item.amount;
    });
  }

  const dispatch = useDispatch();

  const fullName = isExisting
    ? userInfo?.userFirstName + " " + userInfo?.userLastName
    : checkForm
    ? newCheckDetails?.firstName + " " + newCheckDetails?.lastName
    : newCardDetails?.cardFirstName + " " + newCardDetails?.cardLastName;
  const email = userInfo?.userEmail;
  const recurring = creditCardInfo?.recurring;
  const recurringStartDate = creditCardInfo?.recurringStartDate;


  const goNext = () => {
    if (isExisting) {
      const existingGivePayload = {
        orgId,
        userId: userInfo?.userId,
        givingCurrency: selectedPayment?.currency,
        recordId: selectedCard?.recordId,
        recurring,
        recurringStartDate:
          recurring === "once" ? undefined : recurringStartDate,
        listOfContributions: [
          {
            amount,
            contributionType,
          },
        ],
        memo,
      };

      return dispatch(
        giveWithAnExistingCardCheckingPortal(
          existingGivePayload,
          showSuccessCard,
          true
        )
      );
    }
    if (checkForm) {
      const giveWithNewCheckPayload = {
        contributionType,
        orgId,
        amount,
        email,
        recurring,
        recurringStartDate:
          recurring === "once" ? undefined : recurringStartDate,
        ...newCheckDetails,
        memo,
      };

      return dispatch(giveWithCheck(giveWithNewCheckPayload, showSuccessCard));
    }

    const {
      maskedCardNumber,
      cardFirstName,
      cardLastName,
      ...rest
    } = newCardDetails;
    const giveWithNewCardPayload = {
      firstName: cardFirstName,
      lastName: cardLastName,
      contributionType,
      orgId,
      amount,
      email,
      recurring,
      recurringStartDate: recurring === "once" ? undefined : recurringStartDate,
      ...rest,
      memo,
    };

    dispatch(giveWithCard(giveWithNewCardPayload, showSuccessCard));
  };

  const showSuccessCard = (data) => {
    setIsSuccessCard(true);
    setSuccessMsg(data);
  };

  return isSuccessCard ? (
    <SuccessGiveComponent customPath="/contribution" successMsg={successMsg} />
  ) : (
    <div className="container-md credit-card login-form login-signin activeForm-wrapper mt-3">
      <Alert severity="info">
        {" "}
        Please verify your contribution before clicking on the{" "}
        <strong>Process my Giving </strong> button below. If you need to cancel
        this transaction, click on the <strong>Back</strong> button.
      </Alert>
      <hr />
      <div className="d-flex justify-content-between ">
        <div>
          <div>
            {checkForm ? (
              <p className="m-0 text-weight-bold">
                <span className="mr-2">
                  <i className="fas fa-file-invoice font-size-sm"></i>
                </span>
                {`Account: ${
                  showNumber
                    ? window.atob(newCheckDetails?.accountNumber)
                    : newCheckDetails?.maskedAcctNumber
                }`}
              </p>
            ) : isExisting ? (
              <p className="m-0 text-weight-bold">
                <span className="mr-2">
                  <i className="fa fa-hashtag font-size-sm"></i>
                </span>
                <span className="text-capitalize">
                  {capitalizeFirstLetter(selectedPayment?.type)}:
                </span>
                {` **********${selectedCard?.last4Digits}`}
              </p>
            ) : (
              <p className="m-0 text-weight-bold">
                <span className="mr-2">
                  <i className="fa fa-hashtag font-size-sm"></i>
                </span>
                {`Card: ${
                  showNumber
                    ? window.atob(newCardDetails?.cardNumber)
                    : newCardDetails?.maskedCardNumber
                }`}
              </p>
            )}
          </div>
        </div>
        {!isExisting && !showNumber && (
          <i
            onClick={() => setShowNumber(true)}
            className="fa fa-eye font-size-sm cursor-pointer"
          ></i>
        )}
        {!isExisting && showNumber && (
          <i
            onClick={() => setShowNumber(false)}
            className="fa fa-eye-slash font-size-sm cursor-pointer"
          ></i>
        )}
      </div>
      <hr />

      <div className="final-give-info-container mt-10">
        {fullName && (
          <div className="final-give-info">
            <p className="pl-0">
              <span className="mr-2">
                <i className="fa fa-user font-size-sm"></i>
              </span>
              Full Name:
            </p>
            <p className="">
              {fullName}
            </p>
          </div>
        )}
        <div className="final-give-info">
          <p className="pl-0">
            <span className="mr-2">
              <i className="font-size-sm fa fa-hand-holding-heart"></i>
            </span>
            {contributionType}:
          </p>
          <p>{`${amount} ${selectedPayment?.currency}`}</p>
        </div>

        {!isExisting && !checkForm && newCardDetails?.cvv && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="final-give-info">
              <p className="pl-0">
                <span className="mr-2">
                  <i className="fa fa-lock font-size-sm"></i>
                </span>
                Security Code (CVV):
              </p>
              <p>
                {" "}
                <span className="font-weight-bold">
                  {showCvv
                    ? newCardDetails?.cvv
                    : maskString(newCardDetails?.cvv)}
                </span>
              </p>
            </div>
            {!showCvv && (
              <i
                onClick={() => setShowCvv(true)}
                className="fa fa-eye font-size-sm cursor-pointer"
              ></i>
            )}
            {showCvv && (
              <i
                onClick={() => setShowCvv(false)}
                className="fa fa-eye-slash font-size-sm cursor-pointer"
              ></i>
            )}
          </div>
        )}
        {!isExisting && !checkForm && newCardDetails?.expirationMonth && (
          <div className="final-give-info">
            <p className="pl-0">
              <span className="mr-2">
                <i className="fa fa-lock font-size-sm"></i>
              </span>
              Expiration Date:
            </p>
            <p>
              {" "}
              <span className="font-weight-bold">
                {newCardDetails?.expirationMonth}/
                {newCardDetails?.expirationYear?.substr(
                  newCardDetails?.expirationYear?.length - 2,
                  newCardDetails?.expirationYear?.length
                )}
              </span>
            </p>
          </div>
        )}
        {isExisting && selectedCard?.expirationDate && (
          <div className="final-give-info">
            <p className="pl-0">
              <span className="mr-2">
                <i className="fa fa-calendar font-size-sm"></i>
              </span>
              Expiration Date:
            </p>
            <p>
              {" "}
              <span className="font-weight-bold">
                {selectedCard?.expirationDate.substr(0, 2) +
                  "/ " +
                  selectedCard?.expirationDate.substr(2)}
              </span>
            </p>
          </div>
        )}
        {/* {((!checkForm && newCardDetails?.memo) ||
          (checkForm && newCheckDetails?.memo)) &&
          !isExisting && (
            <div>
              <p className="">Memo:</p>
              <div
                dangerouslySetInnerHTML={{
                  __html: !checkForm
                    ? newCardDetails?.memo
                    : newCheckDetails?.memo,
                }}
              />
            </div>
          )} */}

        {memo && (
          <div>
            <p className="">Memo:</p>
            <div
              dangerouslySetInnerHTML={{
                __html: memo,
              }}
            />
          </div>
        )}
      </div>
      <Navigator
        goBack={goBack}
        goNext={goNext}
        cardReview="Process my Giving"
        loading={giveWithANewCardData?.loading}
        className="py-3"
      />
    </div>
  );
};
