import moment from "moment";
import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import {sermon} from '../../store/actions/index'
import "./sermon.scss";
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import UpcomingEvent from "./UpcomingEvent";
import NextEevnt from "./NextEevnt";
import { useState, useMemo } from "react";
import {Typography} from '@material-ui/core'
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
// import groupBy from 'lodash.groupby';


export default function VideoPlayer() {
  let dispatch = useDispatch()
  const theme = useThemeColor()
  const [activeEvent, setActiveEvent] = useState([])
  const [nonActiveEvent, setNonActiveEvent] = useState([])
  const [currentlyPlaying, setCurrentlyPlaying] = useState('')
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const sermonData = useSelector(state => state.sermonData, shallowEqual)
  useEffect(() => {
    dispatch(sermon(orgInfo.orgId))
  }, [])

  const sortEvent = sermonData?.responseData?.entries.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.startDate) - new Date(b.startDate);
    });
  const sortedEvent = useMemo(() => sortEvent, [sermonData?.responseData])
  useEffect(() => {
    if (sermonData?.responseData?.entries?.length > 0 && sortedEvent) {
      const eventWithPlayerId = sortEvent?.filter((el) => el.playerId)
      const eventWithoutPlayerId = sortEvent?.filter((el) => !el.playerId)
      if (eventWithPlayerId.length > 0 ) {
        setActiveEvent(eventWithPlayerId)
      }
      if (eventWithoutPlayerId.length > 0 ) {
        const futureEvent = groupedEventFuture(eventWithoutPlayerId)
        setNonActiveEvent(futureEvent)
      }
      // if (moment(sortEvent[0].startDate).format() === moment().format() || moment(sortEvent[0].startDate).format() >= moment().format()) {
      //   setCurrentlyPlaying(sortEvent[0].playerId)
      // }
      setCurrentlyPlaying(sermonData?.responseData?.youTubeExternalUrl)
    }
  }, [sermonData, sortedEvent])

  // useEffect(() => {
  //   if (activeEvent?.length > 0) {
  //     setCurrentlyPlaying(activeEvent[0].playerId)
  //   }
  // }, [activeEvent])

  const handleNextEvent = () => {
    if (activeEvent?.length > 0) {
      setCurrentlyPlaying(activeEvent[0].playerId)
    }
  }

  const groupedEvent = useMemo(() => 
        groupBy(sermonData?.responseData?.entries, 'date')
  , [sermonData?.responseData])


  let startNow = true;
  // startNow = moment('2021-03-05T01:42:03Z').format() === moment().format() || moment('2021-03-05T01:42:03Z').format() >= moment().format() ? true : false
  return (
    <div className='px-3 pt-9 pb-12'>
      <div className='sermon-main-container'>
        {/* <> */}
        <div className="player-wrapper ">
          {sermonData?.responseData?.youTubeExternalUrl ? <div style={{paddingBottom: '0.3rem'}}>
          <ReactPlayer
            url={`${currentlyPlaying}`}
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
            width="100%"
            height="650px"
            playIcon={<i className="fas fa-play"></i>}
            controls={true}
            playing={startNow}
            muted={true}
            loop={sermonData?.responseData?.youTubeExternalUrl === currentlyPlaying}
          />
          </div> : sermonData?.responseData?.introImageUrl ? (
            <div style={{paddingBottom: '0.3rem'}}>
              <img
                src={sermonData?.responseData?.introImageUrl}
                width="100%"
                height="650px"
                style={{objectFit: 'cover'}}
              />
            </div>
          ) : null}
          {activeEvent[0]?.name && <Typography variant="h5" style={{ color: 'white', padding: '1rem', marginTop: '-4px', backgroundColor: 'black' }}>{activeEvent[0]?.name}</Typography>}
        {nonActiveEvent?.length > 0 && <NextEevnt data={nonActiveEvent[0]} setCurrentlyPlaying={setCurrentlyPlaying} />}
        </div>
        {/* </> */}
        <div className=' pt-4'>
            {groupedEvent && <UpcomingEvent data={groupedEvent} setCurrentlyPlaying={setCurrentlyPlaying} />}
        </div>
      </div>
    </div>
  );
}

const groupedEventFuture = (data) => {
  const futureEvent = data?.map((item) => {
    if (moment(item.startDate).format() > moment().format()) {
      return item
    }
  })
  return futureEvent;
}



var groupBy = function(xs, key) {
  return xs?.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
