import { nodeEnv } from './constants';

export const checkNodeEnv = (prodUrl, devUrl) => {
  let url = '';
  // if(process.env.NODE_ENV === nodeEnv.production) url = prodUrl;
  if(process.env.REACT_APP_ENV === "local") url = devUrl;
  else if(process.env.REACT_APP_ENV === "staging") url = devUrl
  else url = prodUrl;
  return url;
}

export const checkNodeEnvNextGen = (prodUrl, devUrl) => {
  let url = '';
  if(process.env.REACT_APP_ENV === "local") url = devUrl;
  else if(process.env.REACT_APP_ENV === "staging") url = devUrl
  else url = prodUrl;
  return url;
}

export const authHeader = () => {
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.authData) {
      return { 'Authorization': 'Basic ' + user.authData };
  } else {
      return {};
  }
}


export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('nextGenToken');
  localStorage.removeItem('mservice-token');
  localStorage.removeItem('master_app');
  // console.log(window, 'windows');
  window.location.href = '/'
  window.location.reload();
}

export const getUserDetails = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.responseData) {
      return user.responseData
  } else {
      return {};
  }
}