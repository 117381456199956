import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {useHistory} from 'react-router-dom'
import Navigator from "../../../Navigator";
import {
  giveWithCard,
  giveWithCheck,
  preferredPaymentNewCard,
  preferredPaymentNewCheck,
  resetEndpointDataAction,
  setHideAmountInput,
} from "../../../../store/actions";
import "./GiveDetails.scss";
import { CreditCardComponent } from "../CreditCardComponent";
import SuccessGiveComponent from "../SuccessGiveComponent";

export const GiveDetails = () => {
  const [goBackStep, setGoBackStep] = useState(false);
  const [goNextStep, setGoNextStep] = useState(false);
  const [goToGiveCard, setGoToGiveCard] = useState(false);
  const orgInfo = useSelector((state) => state.orgInfo.responseData);
  const creditCardInfo = useSelector((state) => state.creditCardInfo);
  const primaryBgColor = {
    backgroundColor: orgInfo ? orgInfo?.colorThemes[0]?.primary : "",
    border: "none",
  };
  const giveWithCardData = useSelector((state) => state.giveWithCardData);
  const { type: paymentType, currency } =
    useSelector((state) => state.selectedPaymentOption) || {};
  const {
    isActive,
    firstName,
    lastName,
    recurring,
    contributionType,
    mobile,
    email,
  } = creditCardInfo;
  const { amount } = useSelector((state) => state.donatedAmountData);
  const dispatch = useDispatch();
  // const history = useHistory();

  const goBack = () => {
    dispatch(setHideAmountInput(false));
    setGoBackStep(true);
  };



  const goNext = () => {
    if (!creditCardInfo?.creditCardDetails?.isCard) {
      const payload = {
        orgId: orgInfo.orgId,
        accountNumber: creditCardInfo?.checkDetails?.accountNumber,
        routingNumber: creditCardInfo?.checkDetails?.routingNumber,
        memo: creditCardInfo?.checkDetails?.memo,
        firstName,
        lastName,
        amount,
        recurring,
        contributionType,
        mobile,
        email,
      };
      if (paymentType === "PPS") {
        const nextgenPayload = {
          accountNumber: creditCardInfo?.checkDetails?.nextgenAccNo,
          routingNumber: creditCardInfo?.checkDetails?.routingNumber,
          checkingFirstName: firstName,
          checkingLastName: lastName,
          currencyCode: currency,
          email,
          listOfContributions: [
            {
              amount,
              contributionType,
              memo: creditCardInfo?.checkDetails?.memo,
            },
          ],
          mobile,
          orgId: orgInfo.orgId,
          totalAmount: amount,
          userId: null,
          saveCheck: creditCardInfo?.checkDetails?.saveCheck,
        };
        return dispatch(
          preferredPaymentNewCheck(nextgenPayload, showToGivePage)
        );
      }
      if (isActive === true) {
        return dispatch(giveWithCheck(payload, showToGivePage));
      } else {
        return dispatch(
          giveWithCheck(
            {
              ...payload,
              recurringStartDate: creditCardInfo?.recurringStartDate,
            },
            showToGivePage
          )
        );
      }
    } else {
      const payload = {
        orgId: orgInfo?.orgId,
        expirationMonth: creditCardInfo?.creditCardDetails?.expirationMonth,
        expirationYear: creditCardInfo?.creditCardDetails?.expirationYear,
        cvv: creditCardInfo?.creditCardDetails?.cvv,
        memo: creditCardInfo?.creditCardDetails?.memo,
        firstName,
        lastName,
        amount,
        recurring,
        contributionType,
        mobile,
        email,
        cardNumber: creditCardInfo?.creditCardDetails?.cardNumber,
      };
      if (paymentType === "PPS") {
        const nextgenPayload = {
          cardFirstName: firstName,
          cardLastName: lastName,
          cardNumber: creditCardInfo?.creditCardDetails?.nextgenCardNum,
          currencyCode: currency,
          cvv: creditCardInfo?.creditCardDetails?.cvv,
          email,
          expirationMonth: creditCardInfo?.creditCardDetails?.expirationMonth,
          expirationYear: creditCardInfo?.creditCardDetails?.expirationYear.substring(
            2
          ),
          listOfContributions: [
            {
              amount,
              contributionType,
              memo: creditCardInfo?.creditCardDetails?.memo,
            },
          ],
          mobile,
          orgId: orgInfo.orgId,
          saveCard: creditCardInfo?.creditCardDetails?.saveCard,
          totalAmount: amount,
          userId: null,
        };
        return dispatch(
          preferredPaymentNewCard(nextgenPayload, showToGivePage)
        );
      }

      if (isActive === true) {
        // console.log('oneTimeData card', payload);
        return dispatch(giveWithCard(payload, showToGivePage));
      } else {
        // console.log('recurringData card', payload);
        return dispatch(
          giveWithCard(
            {
              ...payload,
              recurringStartDate: creditCardInfo?.recurringStartDate,
            },
            showToGivePage
          )
        );
      }
    }
  };

  const showToGivePage = () => {
    setGoToGiveCard(true);
    dispatch(resetEndpointDataAction);
  };

  return goToGiveCard ? (
    <SuccessGiveComponent className="py-4" />
  ) : (
    <>
      {goBackStep && <CreditCardComponent />}
      {!goBackStep && !goNextStep && (
        <div className="container-md credit-card login-form login-signin activeForm-wrapper">
          <div className="text-center mb-10 mb-lg-10">
            <div>
              <span className="icon" style={primaryBgColor}>
                <i className="las la-clipboard-list"></i>
              </span>
            </div>
            <h3 className="font-size-h3 mb-2">Review Details</h3>
          </div>
          <hr />
          <div className="d-flex justify-content-between ">
            <div className="d-flex final-give-info-container">
              {/* <div className="final-give-info"><h4>Card: </h4><p>{creditCardInfo?.creditCardDetails?.maskedCreditCard}</p></div> */}
              {creditCardInfo?.checkDetails?.maskedAcctNum && (
                <div className="final-give-info">
                  <h4 className="d-flex align-items-center mb-0">Account: </h4>
                  <p className="d-flex align-items-center mb-0">
                    {creditCardInfo?.checkDetails?.maskedAcctNum}
                  </p>
                </div>
              )}
              {creditCardInfo?.creditCardDetails?.maskedCreditCard && (
                <div className="final-give-info">
                  <h4 className="d-flex align-items-center mb-0">Card: </h4>
                  <p className="d-flex align-items-center mb-0">
                    {creditCardInfo?.creditCardDetails?.maskedCreditCard}
                  </p>
                </div>
              )}
            </div>
            {creditCardInfo?.creditCardDetails?.isCard && (
              <p className="d-flex align-items-center mb-0">
                {creditCardInfo?.creditCardDetails?.expirationMonth}/
                {creditCardInfo?.creditCardDetails?.expirationYear.substr(
                  creditCardInfo?.creditCardDetails?.expirationYear?.length - 2,
                  creditCardInfo?.creditCardDetails?.expirationYear?.length
                )}
              </p>
            )}
          </div>
          <hr className="mb-0" />
          <>
            <div className="final-give-info-container mt-5">
              {firstName && (
                <div className="final-give-info">
                  <h4>Name:</h4>
                  <p>{firstName + " " + lastName}</p>
                </div>
              )}
              {amount && (
                <div className="final-give-info">
                  <h4>Amount:</h4>
                  <p>{amount + " " + orgInfo.defaultCurrency}</p>
                </div>
              )}
              {contributionType && (
                <div className="final-give-info">
                  <h4>Type:</h4>
                  <p>{contributionType}</p>
                </div>
              )}
              {
                <div className="final-give-info">
                  <h4>Recurring:</h4>
                  <p>
                    {!isActive && creditCardInfo?.recurring
                      ? creditCardInfo?.recurring
                      : "once"}
                  </p>
                </div>
              }
              {email && (
                <div className="final-give-info">
                  <h4>Email:</h4>
                  <p>{email}</p>
                </div>
              )}
              {mobile && (
                <div className="final-give-info">
                  <h4>Phone:</h4>
                  <p>{mobile}</p>
                </div>
              )}
              {!isActive && creditCardInfo?.recurringStartDate && (
                <div className="final-give-info">
                  <h4>Start Date:</h4>
                  <p>{creditCardInfo?.recurringStartDate}</p>
                </div>
              )}
            </div>
            <Navigator
              goBack={goBack}
              goNext={goNext}
              cardReview="Process"
              loading={giveWithCardData?.loading}
              className="py-4"
            />
          </>
        </div>
      )}
    </>
  );
};
