import React, { useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel
} from "./ImageUploadStyle";

export default function AudioUpload({
    label,
    updateFilesCb,
    ...otherProps
}) {
    const fileInputField = useRef(null);
    const [audioUrl, setAudioUrl] = React.useState(null)

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
      };

      const handleNewFileUpload = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        updateFilesCb(file)
        reader.onloadend = () => {
          setAudioUrl(reader.result)
        }
    
        reader.readAsDataURL(file)
    };
    
    return (
        <>
        <FileUploadContainer>
        <InputLabel>{label}</InputLabel>
        <DragDropText>Drag and drop your files anywhere or</DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={(e) => handleNewFileUpload(e)}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer> 
      <FilePreviewContainer>
        <span>To Upload</span>
        {audioUrl && <PreviewList>
        <audio
        controls
        src={audioUrl}>
            Your browser does not support the
            <code>audio</code> element.
    </audio>
        </PreviewList>}
      </FilePreviewContainer>
        </>
    )
}

