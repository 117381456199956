import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.scss';

export const PageLoader = () => <div className="page-loader w-100 d-flex flex-column align-items-center mx-auto justify-content-center" style={{height: '100vh'}}>
  <h3 className="mb-10 w-100 text-center text-primary">CHURCHPAD</h3>
  <div className="w-100 text-center">
    <Spinner animation="border" variant="primary" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
</div>