import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
export const CustomCheckBox = withStyles({
  root: {
    '& .MuiIconButton-label': {
      color: 'grey'
    }
  }
})(Checkbox);

export const CustomIconButton = withStyles({
  root: {
    '& .MuiIconButton-label': {
      color: 'grey'
    }
  }
})(IconButton);


export const RsvpCheckbox = ({ item, index, getChildValue, icon, checkedIcon, name }) => {
  const [isChecked, setIsChecked] = useState(false);
  
  const handleCheckState = () => {
    setIsChecked(!isChecked)
  }
  
  useEffect(() => {
    if(index >= 0) getChildValue(item, isChecked, index);
  }, [item, isChecked, index]);

  return <CustomCheckBox
    checked={isChecked} 
    onChange={handleCheckState} 
    icon={icon}
    checkedIcon={checkedIcon} 
    name={name} 
  />;
}

export const RsvpGuestCheckbox = ({ item, index, getGuestValue, icon, checkedIcon, name }) => {
  const [isChecked, setIsChecked] = useState(false);
  
  const handleCheckState = () => {
    setIsChecked(!isChecked)
  }
  
  useEffect(() => {
    if(index >= 0) getGuestValue(item, isChecked, index);
  }, [item, isChecked, index]);

  return <CustomCheckBox
    checked={isChecked} 
    onChange={handleCheckState} 
    icon={icon}
    checkedIcon={checkedIcon} 
    name={name} 
  />;
}