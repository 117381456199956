import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { usePaymentInputs } from "react-payment-inputs";
import AddMoreContributionForm from "./AddMoreContributionForm";
import { months, generateArrayOfYears } from "../../../utils/constants";
// import './Give.scss';
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { getUserDetails } from "../../../utils/authHelpers";
import { isEmpty } from "../../../utils";
import {
  setCardDetails,
  setCheckDetails,
} from "../../../store/actions/userDashboardActions/give";
import { CardForm } from "./CardForm";
import { CheckForm } from "./CheckForm";
import { useLocation } from "react-router-dom";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { InlineErrorMsg } from "../../Common/InlineErrorMsg";

const initialValues = {
  amount: "",
  contributionType: "",
  memo: "",
};

export const CardUserDetails = ({ updatePageData, checkForm = false }) => {
  const giveWithANewCardData = useSelector(
    (state) => state.giveWithANewCardData
  );
  const [contributionList, setContributionList] = useState(false);
  const [contributionValues, setContributionValues] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardLastName, setCardLastName] = useState("");
  const [cardFirstName, setCardFirstName] = useState("");
  const [cvc, setCVC] = useState("");
  const [cardErrors, setCardErrors] = useState({});
  const [cardForm, setCardForm] = useState(false);
  const [isCheckForm, setIsCheckForm] = useState(false);
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const ipData = useSelector((state) => state.ipData);

  const themeColor = useThemeColor();
  const { pathname } = useLocation();

  // const cardNumberValidator = ({ cardType }) => {
  //   if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard' || cardType.type === 'amex' || cardType === 'discover') {
  //     return;
  //   }
  //   return 'Card must be Visa, Mastercard, Amex or Discover';
  // }

  const validationSchema = yup.object().shape({
    amount: yup.string().required("Amount is required"),
    contributionType: yup.string().required("Contribution Type is required"),
  });

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const primaryBgColor = {
    backgroundColor: orgInfo ? orgInfo?.colorThemes[0]?.primary : "",
    border: "none",
  };
  const user = getUserDetails();

  // console.log(primaryBgColor, 'primaryBgColor', themeColor, 'themeColor' )
  const dispatch = useDispatch();

  const setListOfContribution = (updatedValues) => {
    if (updatedValues?.listOfContributions?.length) {
      setContributionList(updatedValues.listOfContributions);
    }
    // setContributionValues(updatedValues)
  };

  const handleFirstNameChange = (e) => {
    setCardFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setCardLastName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // const handleChangeCardNumber = (e) => {
  //   setCardNumber(e.target.value);
  // }

  // const handleChangeCVC = (e) => {
  //   setCVC(e.target.value);
  // }

  useEffect(() => {
    if (user?.userFirstName) setCardFirstName(user?.userFirstName);
    if (user?.userLastName) setCardLastName(user?.userLastName);
  }, [user?.userFirstName, user?.userLastName]);

  const handleSubmit = (cardData) => (values) => {
    const errors = {};
    if (isPPS() && (!mobile || !isValidPhoneNumber(mobile))) return;
    if (!cardFirstName) errors.firstName = "First Name is Required";
    if (!cardLastName) errors.lastName = "Last Name is Required";
    if (errors) {
      console.log(errors);
      setCardErrors(errors);
    }

    let payload = {
      cardFirstName,
      cardLastName,
      checkingFirstName: cardFirstName,
      checkingLastName: cardLastName,
      listOfContributions: [
        {
          amount: values.amount,
          contributionType: values.contributionType,
          memo: values.memo,
        },
      ],
      orgId: orgInfo.orgId,
      userId: user.userId,
      email,
      mobile,
    };
    if (contributionList) {
      payload.listOfContributions = [
        ...payload.listOfContributions,
        ...contributionList,
      ];
    }

    if (isEmpty(errors)) {
      dispatch(setCardDetails(payload));
      if (checkForm) {
        return setIsCheckForm(true);
      }
      setCardForm(true);
    }
  };

  const isPPS = () => {
    const ppsPath = "/user/donate/pps";
    return pathname === ppsPath;
  };

  return (
    <>
      {isCheckForm ? (
        <CheckForm />
      ) : cardForm ? (
        <CardForm updatePageData={updatePageData} />
      ) : (
        <div className="card-form">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit({ cardNumber, cvc })}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className="px-4" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {/* {giveWithANewCardData && giveWithANewCardData.responseCode === '01' && <Alert isSuccess={false} msg={giveWithANewCardData.responseMessage} />} */}
                    <AddMoreContributionForm
                      listOfContribution={setListOfContribution}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                    />
                    {/* {listOfContributionError && <span className="text-danger">Click on <strong>Add More Contribution Fields</strong> button to continue</span>} */}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      // className="mb-8"
                      name="amount"
                      label="Amount"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.amount || ""}
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Contribution Type"
                      name="contributionType"
                      // className="mb-8"
                      size="small"
                      variant="outlined"
                      fullWidth
                      select
                      defaultValue={""}
                      onBlur={handleChange}
                      error={Boolean(
                        touched.contributionType && errors.contributionType
                      )}
                      helperText={
                        touched.contributionType && errors.contributionType
                      }
                    >
                      {orgInfo.contributionTypes?.map((list) => (
                        <MenuItem value={list} key={list}>
                          {list}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      // className="mb-8"
                      name="cardFirstName"
                      label="First Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={(e) => handleFirstNameChange(e)}
                      value={cardFirstName}
                      // value={user.userFirstName || ''}
                      error={Boolean(
                        touched.cardFirstName && errors.cardFirstName
                      )}
                      helperText={touched.cardFirstName && errors.cardFirstName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      // className="mb-8"
                      name="cardLastName"
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // rows={8}
                      onBlur={handleBlur}
                      onChange={(e) => handleLastNameChange(e)}
                      value={cardLastName}
                      error={Boolean(
                        touched.cardLastName && errors.cardLastName
                      )}
                      helperText={touched.cardLastName && errors.cardLastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {isPPS() && (
                      <TextField
                        className="mb-8"
                        name="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        // rows={8}
                        onBlur={handleBlur}
                        onChange={(e) => handleEmailChange(e)}
                        value={email || ""}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        required
                      />
                    )}

                    {isPPS() && (
                      <div className="fv-plugins-icon-container mb-8">
                        <p style={{ marginBottom: "0.2rem" }}>Phone Number</p>
                        <PhoneInput
                          international
                          withCountryCallingCode
                          countryCallingCodeEditable={false}
                          defaultCountry={ipData ? ipData.country_code : null}
                          placeholder="Mobile"
                          value={mobile || ""}
                          onChange={setMobile}
                          className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${
                            mobile && !isValidPhoneNumber(mobile)
                              ? "is-invalid"
                              : mobile && isValidPhoneNumber(mobile)
                              ? "is-valid"
                              : ""
                          }`}
                          name="mobile"
                          error={
                            mobile
                              ? isValidPhoneNumber(mobile)
                                ? undefined
                                : "Invalid mobile number"
                              : "Mobile number is required"
                          }
                        />
                        <small className="text-muted">
                          (To get your giving notification by text)
                        </small>
                        {mobile && !isValidPhoneNumber(mobile) && (
                          <InlineErrorMsg msg="Invalid mobile number" />
                        )}
                        {!mobile && (
                          <InlineErrorMsg msg="mobile number is required" />
                        )}
                      </div>
                    )}

                    <TextField
                      // className="mb-8"
                      label="Memo"
                      name="memo"
                      maxLength={100}
                      size="small"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      // defaultValue={''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      value={values.memo || ""}
                      error={Boolean(touched.memo && errors.memo)}
                      helperText={touched.memo && errors.memo}
                    />
                    <p>{`${100 - values?.memo?.length} character remaining`}</p>
                    <div className="d-flex w-100 align-items-center justify-content-end">
                      <Button
                        type="submit"
                        className="btn w-100 font-weight-bold py-3 px-9 mb-5"
                        style={themeColor.btnStyle}
                      >
                        Next
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
