import React from "react";
import Icon from "@material-ui/core/Icon";
import moment from "moment";

export default function RsvpUpcomingCard({ item }) {
  return (
    <div>
      <div className="d-flex">
        <Icon className="far fa-calendar-alt font-size-h6 mt-1 mr-5" />
        <h5>{moment(item.date).format("MMM DD, YYYY")}</h5>
      </div>
      <div 
      // onClick={handleSelectedCard} 
    //   style={{border: `${isCardSelected ? '1px solid gray' : ''}`}} 
      className={`rsvp-card rounded-lg mb-5 ${item.isUserRsvped ? 'bg-dark text-light': 'bg-gray-300 text-dark'}`}
    >
        <div className="px-10 pt-10 d-flex flex-column flex-md-row justify-content-md-between">
        <h6>{item.name}</h6>
        <p><span className="d-inline d-md-none mr-2"><i className="far fa-clock font-size-sm"></i></span>{moment(item.timestamp, ["HH.mm"]).format("hh:mm A")}</p>
      </div>
      <div className="d-flex mx-10">
        {item.isUserRsvped ? 
          <span className="mr-5 label label-danger label-pill label-inline mr-2">RESERVED</span> :
            !item.isUserRsvped && item.filledUp ? 
              <span className=" label label-dark label-pill label-inline mr-2">SEATS FILLED UP</span> :
        !item.isUserRsvped && !item.filledUp ? <div className="w-100 d-flex justify-content-between" style={{paddingBottom: '15px'}}>
          <span className="label label-success bg-green label-pill label-inline">AVAILABLE</span>
          <span className={`label label-success bg-green  label-pill label-inline`}>{ `${item.availableSpots} SEATS`}</span>
        </div> : null}
      </div>

      <div className="">
        {!item.isUserRsvped && !item.filledUp && <div className="progress w-100" style={{height: '3px'}}>
          <div className={`progress-bar ${item.capacityPercentage <= 25 ? 'bg-green' : item.capacityPercentage <= 50 ? 'bg-primary' : item.capacityPercentage <= 75 ? 'bg-warning' : 'bg-danger' }`} role="progressbar" style={{width: `${item.capacityPercentage}%`}} aria-valuenow={item.capacityPercentage} aria-valuemin="0" aria-valuemax="100"></div>
        </div>}
      </div>
        </div>
    </div>
  );
}
