import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Container, Navbar, Nav } from 'react-bootstrap';
import clsx from 'clsx';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core/styles';
import Menu from "@material-ui/core/Menu";
import Icon from '@material-ui/core/Icon';
import Card from "@material-ui/core/Card";
import Badge from "@material-ui/core/Badge";
import DeleteIcon from '@material-ui/icons/Delete';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Divider from '@material-ui/core/Divider';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { DarkToggle } from '../Common/DarkToggle';
import { Paper } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { NavLink } from 'react-router-dom'
import { isEmpty } from '../../utils'
import { getQueryString } from '../../utils';
import ConfirmationDialog from "../Common/ConfirmDialog";
import { RsvpCheckbox, RsvpGuestCheckbox } from '../Forms/RSVP/RsvpCheckbox';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import { showMobileSideBar, setThemeChange, deleteGuest, getUserProfile, processRsvp, clearSelectedRsvp, rsvpInformationInstance, enableGiveViaNav } from '../../store/actions'
import { useHistory } from 'react-router-dom';

import './Navbar.scss'


const useStyles = makeStyles({
  root: {
    minWidth: 300,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 14,
  },
});

export const NavBar = () => {
  const classes = useStyles();
  const cpToken = getQueryString('cp');
  const dispatch = useDispatch();
  const themeColor = useThemeColor();
  const history = useHistory()
  const ipData = useSelector(state => state.ipData);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const getThemeChangeData = useSelector(state => state.getThemeChangeData, shallowEqual)
  const multipleRsvpData = useSelector(state => state.multipleRsvpData)
  const showMenuBar = useSelector(state => state.showMenuBar, shallowEqual)
  const deleteGuestData = useSelector((state) => state.deleteGuest);
  const userDetails = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  // const { give, checkIn, rsvp, rsvpLabel }  = orgInfo?.landingPageModules;
  const { give, checkIn, rsvp, newHere, liveStream, celebrationWall }  = orgInfo?.landingPageModulesAdvanced;
  const rsvpInformationInstanceData = useSelector(state => state.rsvpInformationInstanceData, shallowEqual);
  const processRSVPData = useSelector(state => state.processRSVPData, shallowEqual);
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSpouseChecked, setIsSpouseChecked] = useState(false);
  const [spouseId, setSpouseId] = useState('');
  const [mobile, setMobile] = useState('');
  const [guest, setGuest] = useState({});
  const [showGuestForm, setShowGuestForm] = useState(false);
  const [deleteExistingGuest, setDeleteExistingGuest] = useState({ show: false, guest: {}, index: -1 })
  const [childArr, setChildArr] = React.useState([]);
  const [guestArr, setGuestArr] = React.useState([]);
  const [newGuestList, setNewGuestList] = useState([]);
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('');
  const primaryColor = orgInfo.colorThemes[0].primary;
  const userId = rsvpInformationInstanceData?.responseData?.userId;
  const toggleMobileMenu = () => {
    dispatch(showMobileSideBar({isMobileShow: !showMenuBar.isMobileShow}))
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleShowGuestForm = () => setShowGuestForm(true);

  const handleCloseGuestForm = () => setShowGuestForm(false);

  const handleThemeChange = () => {
    dispatch(setThemeChange({theme: !getThemeChangeData.theme}));
  }

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(getThemeChangeData.theme))
  }, [getThemeChangeData])

  const handleSpouseCheckState = (spouseId) => {
    setIsSpouseChecked(!isSpouseChecked);
    setSpouseId(spouseId)
  }

  const getChildValue = (data, checkedState, index) => {
    if(checkedState){
      setChildArr([...childArr, { child: data, index }]);
    } 
    else {
      childArr.map((item, i) => {
        if(item.index === index) childArr.splice(i, 1);
      })
      setChildArr(childArr);
    }
  }

  const getGuestValue = (data, checkedState, index) => {
    if(checkedState){
      setGuestArr([...guestArr, { guest: data, index }]);
    } 
    else {
      guestArr.map((item, i) => {
        if(item.index === index) guestArr.splice(i, 1);
      })
      setGuestArr(guestArr);
    }
  }
  
  const triggerDeleteConfirmationBox = (guest) => {
    setDeleteExistingGuest({...deleteExistingGuest,
      show: true,
      guest
    })
  }

  const handleChange = (e) => setGuest({...guest, [e.target.name]: e.target.value})

  useEffect(() => {
    if(isSpouseChecked) setSpouseId(spouseId)
    else setSpouseId('');
  }, [isSpouseChecked])
  // console.log(multipleRsvpData, 'multipleRsvpData2')

  // useEffect(() => {
  // }, [multipleRsvpData.bulkRsvp])
  // console.log(multipleRsvpData, 'multipleRsvpData')
  
  const updateGuestProfile = () => {
    dispatch(getUserProfile({'orgId': orgInfo.orgId, userId}))
    setDeleteExistingGuest({...deleteExistingGuest, show: false})
  }

  const handleDeleteExistingGuest = (guest) => {
    const payload = {
      orgId: orgInfo.orgId,
      userId,
    }
    const guestUserIds = []
    if(guest){
      guestUserIds.push(guest.guestUserId);
      payload.guestUserIds = guestUserIds;
      console.log(payload, 'dispatch payload')
      dispatch(deleteGuest(payload, updateGuestProfile));
    }
  }

  const handleDeleteNewGuest = (index) => {
    const sampleList = newGuestList.filter((guest, i) => {
      if(i !== index) return newGuestList;
    })
    setNewGuestList(sampleList);
  }

  const handleAddGuest = (e) => {
    e.preventDefault();
    if(!guest.firstName){
      return setFirstNameErrorMsg('Firstname is Required');
    }
    if(!guest.lastName){
      guest.lastName = '';
    }
    if(mobile && isValidPhoneNumber(mobile)){
      guest.mobile = mobile;
    } else guest.mobile = '';
    setNewGuestList([...newGuestList, guest]);
    setGuest({firstName: '', lastName: ''})
    setMobile('')
    setShowGuestForm(false);
  }

  
  useEffect(() => {
    if(newGuestList?.length < 1) {
      setShowGuestForm(false);
    }
  }, [newGuestList]);

  const handleProcessBulkRSVP = () => {
    console.log(multipleRsvpData, 'multipleRsvpData')
    let payload = {};
    let userIdsToRsvp = [];
    let newGuestsToRsvp = [];
    let dates = [];
      payload.orgId = orgInfo.orgId;
      payload.userId = userId;
      // payload.dates[0].date = item.dates[0].date;
      // payload.dates[0].id = item.dates[0].id;
      // payload.userIdsToRsvp = [...payload.userIdsToRsvp, userId, spouseId];
      userIdsToRsvp.push(userId);
      if(spouseId){
        userIdsToRsvp.push(spouseId);
      }
      // dates.push({ date: item.dates[0].date, id: item.id})
      multipleRsvpData.bulkRsvp.map(item => {
        dates.push({ date: item.event.dates[0].date, id:item.event.id })
      })
      if(guestArr?.length > 0){
        guestArr.map((guest) => {
          userIdsToRsvp.push(guest.guest.guestUserId)
        })
      }
      if(childArr?.length > 0){
        childArr.map((child) => {
          userIdsToRsvp.push(child.child.childUserId)
        })
      }
      if(newGuestList?.length > 0){
        newGuestList.map((newGuest) => {
          newGuestsToRsvp.push(newGuest)
        })
      }
    
    payload.userIdsToRsvp = userIdsToRsvp;
    payload.newGuestsToRsvp = newGuestsToRsvp;
    payload.dates = dates
    console.log(payload, 'payload')
    dispatch(processRsvp(payload, handleClearSelectedRsvp))
  } 
  
  const handleClearSelectedRsvp = () => {
    dispatch(clearSelectedRsvp())
    updatePageData()
    handleClose()
  }
  const updatePageData = () => {
    dispatch(rsvpInformationInstance(cpToken))
    // setShowFamilyList(false)
  }
// console.log(orgInfo);

const handleGiveNav = () => {
dispatch(enableGiveViaNav({enable: true}))
}

const handleImageClick = () => {
history.push('/')
}

  return (
    <Paper square>
      <Navbar data-cy="navbar" expand="lg" className="navbar d-flex align-items-center">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt="logo"
              src={orgInfo ? orgInfo.logos.landingPages : ''}
              height="60"
              className="d-inline-block align-top"
              onClick={handleImageClick}
            />
          </Navbar.Brand>
          <div className="ml-auto d-flex align-items-center">
            <DarkToggle
              checked={getThemeChangeData.theme}
              onChange={handleThemeChange}
            />
            {/* <Switch checked={getThemeChangeData.theme} onChange={handleThemeChange} /> */}
            <Navbar className="d-none d-md-inline-block">
              <Nav>
                {give?.status === 'true' && <Nav.Item onClick={handleGiveNav}>
                  <NavLink to="/give" 
                    className="mr-5" 
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="las la-hand-holding-heart mr-2" ></span>
                {give?.label}</NavLink>
                </Nav.Item>}
                {checkIn?.status === 'true' &&
                <Nav.Item>
                  <NavLink to="/checkin" 
                    className="mr-5" 
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="las la-user-check mr-2" ></span>
                 {checkIn?.label}</NavLink>
                </Nav.Item>}
                
                {rsvp?.status === 'true' && 
                <Nav.Item>
                  <NavLink  to="/rsvp" 
                  className="mr-5" 
                  style={{
                    color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                  }}
                  activeStyle={{
                    color: "#fff",
                    backgroundColor: `${themeColor.color}`,
                    borderRadius: '5px',
                    padding: '8px 10px'
                  }}
                    exact
                  >
                    <span className="las la-bookmark mr-2"></span>
                  {rsvp?.label}</NavLink>
                </Nav.Item>}
                {newHere?.status === 'true' && <Nav.Item>
                  <NavLink to="/new" 
                    className="mr-5" 
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="far fa-address-book mr-2"></span>
                {newHere?.label}</NavLink>
                </Nav.Item>}
                {liveStream?.status === 'true' && <Nav.Item>
                  <NavLink to="/live" 
                    className="mr-5" 
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="fab fa-steam mr-2"></span>
                {liveStream?.label}</NavLink>
                </Nav.Item>}
                {celebrationWall?.status === 'true' && <Nav.Item>
                  <NavLink to="/celebration" 
                    className="mr-5" 
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="fas fa-glass-cheers mr-2"></span>
                {celebrationWall?.label}</NavLink>
                </Nav.Item>}
                <Nav.Item>
                  <NavLink to="/" 
                    className="mr-5" 
                    data-cy="signin"
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="las la-user mr-2"></span>
                Sign In</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/signup" 
                    className="mr-5" 
                    data-cy="signup"
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="las la-clipboard-check mr-2"></span>
                Sign Up</NavLink>
                </Nav.Item>
                {/* <Nav.Item>
                  <NavLink to="/sermon" 
                    className="mr-5" 
                    data-cy="sermon"
                    style={{
                      color: `${getThemeChangeData?.theme ? "#fff" : primaryColor}`,
                    }}
                    activeStyle={{
                      color: "#fff",
                      backgroundColor: `${themeColor.color}`,
                      borderRadius: '5px',
                      padding: '8px 10px'
                    }}
                    exact
                  >
                    <span className="las la-film mr-2"></span>
                Sermon</NavLink>
                </Nav.Item> */}
              </Nav>
            </Navbar>
            <div className="postion-relative">
              {multipleRsvpData?.bulkRsvp?.length > 0 && <Badge className='cursor mx-5' onClick={handleClick} badgeContent={multipleRsvpData?.bulkRsvp.length} color="secondary">
                <Icon className="cursor far fa-bell"/>
              </Badge>}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="rsvp-dropdown-menu"
              >
                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} gutterBottom>
                      RSVP Multiple Events
                    </Typography>
                    {multipleRsvpData?.bulkRsvp?.map((event, i) => <div key={i} className="d-flex justify-content-between">
                      <Typography className={classes.pos} component="p">
                        {event.event.name}
                      </Typography>
                      {/* <Icon className="far fa-times-circle" color="secondary"/> */}
                      {/* <Icon className="fa fa-plus-circle" color="secondary"/> */}
                    </div>)}
                    <Divider />
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">{`${userDetails?.userFirstName} ${userDetails?.userLastName}`}</p>
                      <Checkbox icon={<RadioButtonUncheckedIcon fontSize="medium" />} checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />} checked disabled name="checkedH" />
                    </div>
                    {(!isEmpty(userDetails?.userSpouseInfo) || userDetails?.userSpouseInfo?.length > 0) && <div className="d-flex justify-content-between align-items-center">
                      <p>{`${userDetails?.userSpouseInfo?.spouseFirstName} ${userDetails?.userSpouseInfo?.spouseLastName}`}</p>
                      <Checkbox
                        onChange={() => handleSpouseCheckState(userDetails?.userSpouseInfo?.spouseUserId)}
                        checked={isSpouseChecked} 
                        icon={<RadioButtonUncheckedIcon fontSize="small" />}
                        checkedIcon={<CheckCircleTwoToneIcon fontSize="small" />} 
                        name="spouse" />
                    </div>}
                    {userDetails?.userChildrenInfo?.map((child, index) => <div key={index} className="d-flex justify-content-between align-items-center">
                      <p>{`${child.childFirstName} ${child.childLastName}`}</p>
                      <RsvpCheckbox 
                        item={child}
                        index={index}
                        getChildValue={getChildValue}
                        icon={<RadioButtonUncheckedIcon fontSize="small" />} 
                        checkedIcon={<CheckCircleTwoToneIcon fontSize="small" />} 
                        name="children"
                      />
                    </div>)}
                    {(!isEmpty(userDetails?.userGuestsInfo) || userDetails?.userGuestsInfo?.length > 0) && <div className="d-flex justify-content-between align-items-center">
                      <h6 onClick={handleExpandClick} className="cursor">GUESTS</h6>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>}
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      {userDetails?.userGuestsInfo?.map((guest, index) => <div key={index} className="d-flex justify-content-between align-items-center">
                        <p>{`${guest.guestFirstName} ${guest.guestLastName}`}</p>
                        <div>
                          <DeleteIcon onClick={() => triggerDeleteConfirmationBox(guest)} className="cursor mr-3  text-danger"  fontSize="small"/>
                          {/* <DeleteIcon onClick={() => handleDeleteExistingGuest(guest)} className="cursor mr-3" color="danger"  fontSize="medium"/> */}
                          <RsvpGuestCheckbox 
                            item={guest}
                            index={index}
                            getGuestValue={getGuestValue}
                            icon={<RadioButtonUncheckedIcon fontSize="small" />} 
                            checkedIcon={<CheckCircleTwoToneIcon fontSize="small" />} 
                          />
                        </div>
                      </div>)}
                    </Collapse>
                    <div >
                      {newGuestList?.length > 0 && 
                      <><Divider className="mt-0"/>
                      <h6>NEW GUESTS</h6></>}
                      {newGuestList?.map((guest, index) => <div key={index} className="d-flex justify-content-between align-items-center">
                        <p>{`${guest.firstName} ${guest.lastName}: ${guest.mobile}`}</p>
                        <DeleteIcon onClick={() => handleDeleteNewGuest(index)} className="cursor mr-3 text-danger" color="danger"  fontSize="small"/>
                      </div>)}
                      
                      {!showGuestForm && 
                        <div className="d-flex justify-content-between">
                          <Button onClick={handleShowGuestForm} className={`${!getThemeChangeData.theme ? 'text-light' : 'text-dark' }`} style={themeColor.btnStyle} variant="contained" color="primary">
                            Add Guest
                          </Button>
                          <Button onClick={handleProcessBulkRSVP} className={`${!getThemeChangeData.theme ? 'text-light' : 'text-dark' }`} style={themeColor.btnStyle} variant="contained" color="primary">
                            {processRSVPData.loading ? 'PLEASE WAIT ...' : 'RESERVE ALL'}
                          </Button>
                        </div>
                      }
                      {showGuestForm && <div className="">
                        <hr className="mb-0" />
                        <TextField
                          label="FirstName"
                          name="firstName"
                          value={guest.firstName}
                          onChange={handleChange}
                          required
                          type="text"
                          id="firstName"
                          size="small"
                          className="mb-5"
                          variant="outlined"
                          error={!!firstNameErrorMsg}
                          helperText={firstNameErrorMsg}
                          fullWidth
                        />
                        <TextField
                          label="LastName"
                          name="lastName"
                          type="text"
                          value={guest.lastName}
                          onChange={handleChange}
                          id="lastName"
                          size="small"
                          className="mb-5"
                          variant="outlined"
                          fullWidth
                        />
                        <div className="mb-5">
                          <PhoneInput
                            international
                            withCountryCallingCode
                            countryCallingCodeEditable={false}
                            defaultCountry={ipData ? ipData.country_code : null}
                            placeholder="Mobile"
                            id="loginMobile"
                            value={mobile}
                            onChange={setMobile}
                            className={`form-control text-dark-50 h-auto py-3 px-6 mobile ${(mobile && !isValidPhoneNumber(mobile)) ? 'is-invalid' : (mobile && isValidPhoneNumber(mobile)) ? 'is-valid' : ''}`}
                            name="mobile"
                            error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid mobile number') : 'Mobile number is required'}
                          />
                          {(mobile && !isValidPhoneNumber(mobile)) ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">Invalid mobile number</div>
                            </div>
                          ) : null}
                        </div>
                        <div className="d-flex justify-content-between">
                          <Button onClick={handleAddGuest} variant="contained" style={themeColor.btnStyle} className={`${!getThemeChangeData.theme ? 'text-light' : 'text-dark' }`} color="primary">
                            Include Guest
                          </Button>
                          <Button onClick={handleCloseGuestForm} variant="contained" color="muted">
                            Close Form
                          </Button>
                        </div>
                      </div>}
                    </div>
                  </CardContent>
                </Card>
              </Menu>

            </div>
            
            
            <span className="hamburger d-flex justify-content-center align-items-center d-md-none ml-5" onClick={toggleMobileMenu}>
              <i className={`fas fa-${showMenuBar.isMobileShow ? 'times': 'bars'}`}></i>
            </span>
          </div>
        </Container>
      </Navbar>
      <ConfirmationDialog 
        open={deleteExistingGuest.show}
        text='Are you sure you want to delete this guest?'
        onConfirmDialogClose= {() => setDeleteExistingGuest({...deleteExistingGuest, show: false})}
        onYesClick={() => handleDeleteExistingGuest(deleteExistingGuest.guest)}
        isLoading={deleteGuestData?.loading}
      />
    </Paper>
  )
};
