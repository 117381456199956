import React, {useState} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { usePaymentInputs } from 'react-payment-inputs';
import { months, generateArrayOfYears } from '../../../utils/constants'
// import './Give.scss';
import moment from 'moment'
import {
    Grid,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { Formik } from 'formik'
import * as yup from 'yup'
import { getUserDetails } from '../../../utils/authHelpers';
import { CardUserDetails } from './CardUserDetails';
import { CardDetailsReview } from './CardDetailsReview';
import { isEmpty } from '../../../utils';
import Navigator from '../../Navigator';
import { getMaskedNumber, expiryDateCheck } from '../../../utils';
import { setCardOwnerDetails } from '../../../store/actions/userDashboardActions/give'
import images from 'react-payment-inputs/images';

const initialValues = {
  // cardFirstName: '',
  // cardLastName: '',
  // amount: '',
  saveCard: true,
  expirationMonth: '',
  expirationYear: '',
  // contributionType: '',
  // memo: ''
}

export const CardForm = ({ updatePageData }) => {
  const giveWithANewCardData = useSelector(state => state.giveWithANewCardData)
  const [contributionList, setContributionList] = useState(false);
  const [contributionValues, setContributionValues] = useState(false);
  const [goBackStep, setGoBackStep] = useState(false);
  const [goNextStep, setGoNextStep] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cvc, setCVC] = useState('');
  const [cardErrors, setCardErrors] = useState({});

  
  const cardNumberValidator = ({ cardType }) => {
    if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard' || cardType.type === 'amex' || cardType === 'discover') {
      return;
    }
    return 'Card must be Visa, Mastercard, Amex or Discover';
  }

  const { meta, getCardNumberProps, getCardImageProps, getCVCProps } = usePaymentInputs({ cardNumberValidator });
  const { erroredInputs } = meta;
  
  const validationSchema = yup.object().shape({
    // cardFirstName: yup.string().required('First Name is required'),
    // cardLastName: yup.string().required('Last Name is required'),
    expirationMonth: yup.string().required('Expiration Month is required'),
    expirationYear: yup.string().required('Expiration Year is required'),
    // amount: yup.string().required('Amount is required'),
    // contributionType: yup.string().required('Contribution Type is required'),
  })   

  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const arrayOfYears = generateArrayOfYears();
  const dispatch = useDispatch();

  const setListOfContribution = (updatedValues) => {
    if(updatedValues?.listOfContributions?.length){
      setContributionList(updatedValues.listOfContributions)
    }
    // setContributionValues(updatedValues)
  }
  

  const handleChangeCardNumber = (e) => {
    setCardNumber(e.target.value);
  }

  const handleChangeCVC = (e) => {
    setCVC(e.target.value);
  }

  const goBack = () => {
    setGoBackStep(true)
  }
  
  // useEffect(() => {
  //   if(user?.userFirstName) setCardFirstName(user?.userFirstName)
  //   if(user?.userLastName) setCardLastName(user?.userLastName)
  // }, [user?.userFirstName, user?.userLastName])

  const handleSubmit = (cardData) => (values) => {
    const { expirationMonth, expirationYear } = values;
    let errors = {};

    if (!cardData.cardNumber || (meta.isTouched && erroredInputs.cardNumber)) {
      errors.cardNumber = erroredInputs.cardNumber;
    }
    // if(!expirationMonth) errors.expirationMonth = 'Expiration Month is Required';
    // if(!expirationYear) errors.expirationYear = 'Expiration Year is Required';
    if (!cardData.cvc) errors.cvc = 'Enter a CVV';
    if (errors) {
      setCardErrors(errors)
    }
    let saveCard = '0';
    const cardNum = cardData?.cardNumber;
    const maskedCardNumber = getMaskedNumber(cardNum)
    const trimCardNum = cardNum.replace(/ /g,'');
    const encodedCardNumber = window.btoa(trimCardNum)
    console.log(encodedCardNumber, 'encodedCardNumber')
    if(values.saveCard) saveCard = '1';
    else saveCard = '0';
    let payload = {
      expirationMonth,
      expirationYear,
      maskedCardNumber,
      cardNumber: encodedCardNumber,
      saveCard,
      cvv: cvc //this key is changed because the endpoint require cvv while the third-party component is using cvc
    }
    console.log(payload, 'payload with new contrinbution list')
    if(isEmpty(errors)){
      dispatch(setCardOwnerDetails(payload));
      setGoNextStep(true);
      // dispatch(giveWithANewCard(payload, closeModal))
    }
    
  }

  return (
    <>
    {goBackStep && <CardUserDetails />}
    {goNextStep && <CardDetailsReview updatePageData={updatePageData} />}
    {!goBackStep && !goNextStep && <div className="card-form">
      <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit({cardNumber, cvc})}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                        <form className="px-4" onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <div className="position-relative mb-8">
                                      <input className="text-dark-50 form-control credit-card-input h-auto py-3 pr-18 pl-5"
                                        value={cardNumber}
                                        {...getCardNumberProps({ onChange: handleChangeCardNumber })}
                                        placeholder="Card Number"
                                      />
                                      <svg className="position-absolute card-img" {...getCardImageProps({ images })} />
                                      <p className="font-weight-bold font-size-xs m-0 text-muted"><span className="">Acceptable cards:</span> {orgInfo.allowedCards?.map((item, index) => {return (<span key={index}>{item}{`${orgInfo.allowedCards.length === index +1 ? '' : ','}`} {' '}</span>)})}</p>
                                      {cardErrors.cardNumber && <small className="text-danger errorText">{erroredInputs.cardNumber}</small>}
                                    </div>
                                    <Grid container spacing={3} >
                                      <Grid item xs={6} >
                                        <TextField
                                          className=""
                                          name="expirationMonth"
                                          label="Exp Month"
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                          select
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.expirationMonth || ''}
                                          error={Boolean(
                                            touched.expirationMonth && errors.expirationMonth
                                          )}
                                          helperText={
                                            touched.expirationMonth && errors.expirationMonth
                                          }
                                        >
                                          {values.expirationYear === moment().format('YYYY') ?
                                              (months.slice(expiryDateCheck).map((item, index) => 
                                                // <option key={`idx${index}`} value={item.id}>{item.value}</option>) :
                                                <MenuItem value={item.id} key={item.id}>
                                                  {item.value}
                                                </MenuItem>)) :
                                                (months.map((item, index) => <MenuItem value={item.id} key={item.id}>
                                                    {item.value}
                                                  </MenuItem>))
                                            }
                                      </TextField>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          className=""
                                          name="expirationYear"
                                          label="Exp Year"
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                          select
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.expirationYear || ''}
                                          error={Boolean(
                                            touched.expirationYear && errors.expirationYear
                                          )}
                                          helperText={
                                            touched.expirationYear && errors.expirationYear
                                          }
                                        >
                                          {arrayOfYears.map((year) => (
                                            <MenuItem value={year} key={year}>
                                              {year}
                                            </MenuItem>
                                          ))}
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        name="cvv"
                                        label="CVV"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        {...getCVCProps({ onChange: handleChangeCVC })}
                                        value={cvc || ''}
                                        error={cardErrors.cvc}
                                        helperText={cardErrors.cvc}
                                        placeholder="CVV"
                                      />
                                    </Grid>
                                   
                                  </Grid>
                                  <div className="d-flex w-100 align-items-center justify-content-between mt-8">
                                    <label htmlFor="saveCard">
                                      <input className="mr-3" name="saveCard" id="saveCard" type="checkbox" checked={values.saveCard} onChange={handleChange} value={values.saveCard || ''} ></input>
                                      <span>Save this card for future contributions</span>
                                    </label>
                                    
                                  </div>
                                  <Navigator goBack={goBack} goNext={handleSubmit} type="submit" />
                                  {/* <Navigator goBack={goBack} goNext={goNext} /> */}
                                </Grid>
                                
                            </Grid>

                            
                        </form>
                    )}
                </Formik>
       
    </div>}
    </>
  )
}