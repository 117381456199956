import React from "react";
import {
    Avatar,
    Button,
    Card,
    Divider,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableRow,
    makeStyles,
    Tooltip 
  } from "@material-ui/core";
  import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router";

export default function Occupation() {
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const data = [
    {
      title: "Job Title",
      value: userProfileData?.userJobTitle,
    },
    {
      title: "Occupation",
      value: userProfileData?.userOccupation,
    },
    {
      title: "Employment Status",
      value: userProfileData?.userEmploymentStatus,
    },
  ]
  let history = useHistory()
  const handleUpdate = (route) => {
    history.push(route)
  }
  return (
    <Card className="pt-2" elevation={3}>
      <div className='d-flex'>
      <h5 className="p-4 m-0">Occupation</h5>
      <Tooltip title="Edit" aria-label="Edit">
      <span className="fa fa-pen icon-sm text-muted m-0 py-4 pt-5" role="button" onClick={() => handleUpdate('/career')}></span>
      </Tooltip>
      </div>
      <Table className="mb-4">
        <TableBody>
        {data.map((item, ind) => (
            <TableRow key={ind}>
              <TableCell className="pl-4">{item.title}</TableCell>
              <TableCell>{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}
