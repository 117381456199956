import { contributionActionTypes } from "../actions/contributionActionType";

export const contributions = (state = {}, action) => {
  switch (action.type) {
    case contributionActionTypes.CONTRIBUTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case contributionActionTypes.CONTRIBUTION_SUCCESS_MONTH:
      return {
        ...state,
        contributionMonth: action.payload,
      };

    case contributionActionTypes.CONTRIBUTION_SUCCESS_YEAR:
      return {
        ...state,
        contributionYear: action.payload,
      };

    case contributionActionTypes.CONTRIBUTION_SUCCESS_RECENT:
      return {
        ...state,
        recentContribution: action.payload,
      };

    case contributionActionTypes.CONTRIBUTION_LAST_YEAR:
      return {
        ...state,
        previousYear: action.payload,
      };
    case contributionActionTypes.CONTRIBUTION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const contributionPDF = (state = {}, action) => {
  switch (action.type) {
    case contributionActionTypes.CONTRIBUTION_PDF_SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case contributionActionTypes.CONTRIBUTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case contributionActionTypes.CONTRIBUTION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
export const statementContribution = (state = {}, action) => {
  switch (action.type) {
    case contributionActionTypes.YEARLY_CONTRIBUTION_STATEMENT_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case contributionActionTypes.YEARLY_CONTRIBUTION_STATEMENT_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}
