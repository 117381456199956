import React from 'react'
import { Grid, Fade } from '@material-ui/core'
import OtherInfo from './OtherInfo'
// import CustomerEmailSender from './CustomerEmailSender'
import UserInfo from './UserInfo'
import Occupation from './Occupation'
import { useSelector, shallowEqual } from "react-redux";
import Emergency from './Emergency'
import PageLoader from '../../Loader/PageLoader'
// import CustomerActions from './CustomerActions'

export default function Dashboard() {
    const userProfileData = useSelector(
        (state) => state.getUserProfileData.responseData,
        shallowEqual
      );
    const userProfileDataLoading = useSelector(
        (state) => state.getUserProfileData,
        shallowEqual
      );
      // console.log(userProfileData, 'logg');
      const EmergencyContact = [
        {
          title: "Name",
          value: `${userProfileData?.userEmergencyContact1LastName} ${userProfileData?.userEmergencyContact1FirstName}`,
        },
        {
          title: "Email",
          value: userProfileData?.userEmergencyContact1Email,
        },
        {
          title: "Phone Number",
          value: userProfileData?.userEmergencyContact1Phone,
        },
      ];
      const EmergencyContact2 = [
        {
          title: "Name",
          value: `${userProfileData?.userEmergencyContact2LastName} ${userProfileData?.userEmergencyContact2FirstName}`,
        },
        {
          title: "Email",
          value: userProfileData?.userEmergencyContact2Email,
        },
        {
          title: "Phone Number",
          value: userProfileData?.userEmergencyContact2Phone,
        },
      ];

    if (userProfileDataLoading?.loading){
      return <PageLoader />
    } 
    return (
        <Fade in timeout={300}>
            { <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                    <UserInfo />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <OtherInfo />
                    <div className='pt-3'>
                    <Occupation />
                    </div>
                    <div className='pt-3'>
                        <Emergency 
                        title='Emergency Contact 1'
                        data={EmergencyContact}
                        /> 
                    </div>
                    <div className='pt-3'>
                        <Emergency 
                        title='Emergency Contact 2'
                        data={EmergencyContact2}
                        showEditIcon={true}
                        /> 
                    </div>
                </Grid>
                {/* <Grid item lg={4} md={6} xs={12}> */}
                    {/* oooooooooo */}
                    {/* <CustomerEmailSender /> */}
                {/* </Grid> */}
            </Grid>}
        </Fade>
    )
}
