import React, { useEffect } from "react";
import { Skeleton } from "@material-ui/lab";
import { ResizeableModalWithoutBtn } from "../../components/Common/ResizeableModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GiveMethodTable  from "../UserDashboardPage/GiveMethods/GiveMethodTable";
import { getGiveMethods } from "../../store/actions/userDashboardActions/give";

const GiveMethodsModal = ({ isOpen, onClose }) => {
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const lightTheme = orgInfo?.colorThemes[0]?.primary;
  const darkTheme = orgInfo?.colorThemes[1]?.primary;
  const getThemeChangeData = useSelector(
    (state) => state.getThemeChangeData,
    shallowEqual
  );

  const { data: giveMethodsData, loading } = useSelector(
    (state) => state?.giveMethods,
    shallowEqual
  );

  const dispatch = useDispatch();
  const backgroundColor = getThemeChangeData?.theme ? darkTheme : lightTheme;

  useEffect(() => {
    dispatch(getGiveMethods({ org_id: orgInfo.orgId }));
  }, []);

  return (
    <ResizeableModalWithoutBtn
      className="ml-5"
      modalState={isOpen}
      title="Give Methods"
      maxWidth="md"
      fullWidth={true}
      backgroundColor={backgroundColor}
      backgroundSecondaryColor={backgroundColor}
      resetModalState={onClose}
    >
      {loading ? (
        <Skeleton variant="rect" height={"300px"} />
      ) : (
        <GiveMethodTable giveMethodsData={giveMethodsData} />
      )}
    </ResizeableModalWithoutBtn>
  );
};

export default GiveMethodsModal;
