import { actionTypes } from '../../actionTypes';

export const groupUsers = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.GET_USERS_GROUP_SUCCESS:
        return {
          ...action.payload,
          loading: true,
        };
      case actionTypes.GET_USERS_GROUP_FAILURE:
        return {
          ...action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const groupUsersPending = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.GET_USERS_GROUP_PENDING_START:
        return {
          ...action.payload,
          loading: true,
        };
      case actionTypes.GET_USERS_GROUP_PENDING_STOP:
        return {
          ...action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const deleteUsersFromDepartmentGroup = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.DELETE_DEPARTMENT_GROUP_USERS_SUCCESS:
        return action.payload;
      case actionTypes.DELETE_DEPARTMENT_GROUP_USERS_FAILURE:
        return action.payload;
      case actionTypes.DEPARTMENT_USERS_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.DEPARTMENT_USERS_STOP:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const addRemoveUserAdminAccess = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.ADD_REMOVE_USER_ADMIN_ACCESS_SUCCESS:
        return action.payload;
      case actionTypes.ADD_REMOVE_USER_ADMIN_ACCESS_FAIL:
        return action.payload;
      case actionTypes.CREATE_DEPARTMENT_GROUP_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const childrenModuleSpecificInfo = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.CHILDREN_MODULE_SUCCESS:
        return action.payload;
      case actionTypes.CHILDREN_MODULE_FAILURE:
        return action.payload;
      default:
        return state;
    }
  };

  export const mediaVaultListing = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.MEDIA_VAULT_LISTING_SUCCESS:
        return action.payload;
      case actionTypes.MEDIA_VAULT_LISTING_FAILURE:
        return action.payload;
      case actionTypes.MEDIA_VAULT_LISTING_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.MEDIA_VAULT_LISTING_STOP:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const sendCommunicationChildren = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.SEND_CHILDREN_COMMUNICATION_SUCCESS:
        return action.payload;
      case actionTypes.SEND_CHILDREN_COMMUNICATION_FAILURE:
        return action.payload;
      case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_STOP:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const orgMessage = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.ORG_MESSAGE:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const sendCommunicationParentsProcess = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.SEND_CHILDREN_COMMUNICATION_PROCESS_SUCCESS:
        return action.payload;
      case actionTypes.SEND_CHILDREN_COMMUNICATION_PROCESS_FAILURE:
        return action.payload;
      case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_STOP:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const searchedUserToJoinDept = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.SEARCH_USER_TO_JOIN_SUCCESS:
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case actionTypes.SEARCH_USER_TO_JOIN_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.SEARCH_USER_TO_JOIN_STOP:
        return {
          ...state,
          loading: false,
        };
      case actionTypes.CLEAR_SEARCH_USERS_TO_JOIN:
        return {};
      default:
        return state;
    }
  };

// POSITIONS
export const positionsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.POSITIONS_LIST_SUCCESS:
      return action.payload;
    case actionTypes.POSITIONS_LIST_FAIL:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addDepartment = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_DEPARTMENT_LIST_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_LIST_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const renameDepartmentGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RENAME_DEPARTMENT_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.RENAME_DEPARTMENT_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_GROUPS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_GROUPS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_GROUPS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_GROUPS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GROUPS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_GROUPS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_GROUPS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_GROUPS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchedUsers = (state = { loading: false }, action) => {
  switch (action.type) {
    case actionTypes.SEARCHED_USERS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.SEARCH_USER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_USER_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeGroupBanner = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PHONE_NUMBER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_USER_PHONE_NUMBER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const approveUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.APPROVE_USER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.APPROVE_USER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.APPROVE_USER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.APPROVE_USER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

