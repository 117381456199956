import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function FloatingActionButton({value, text, isSpouse}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Fab variant="extended" className={classes.fab}>
                {isSpouse ? value + 1 : value} {text}
            </Fab>
    </div>
    )
}