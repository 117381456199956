import { combineReducers } from "redux";
import {
  ipData,
  orgInfo,
  loginData,
  signupData,
  forgotPasswordData,
  forgotPinData,
  verifyForgotPasswordOrPinData,
  resetPasswordOrPinData,
  orgInfoList,
  updateUserData,
  getUserProfileData,
  donatedAmountData,
  creditCardInfo,
  giveWithCardData,
  addChildData,
  showMenuBar,
  secondOrgInfo,
  getThemeChangeData,
  updateUser2FA,
  initiateRSVPData,
  verifyRSVPData,
  processCheckinData,
  deleteGuest,
  processCheckOut,
  rsvpInformationInstanceData,
  processRSVPData,
  cancelRSVPData,
  multipleRsvpData,
  contributionProcessingEnginesData,
  processPaypalData,
  sermonData,
  selectedSermonData,
  checkinData,
  selectedPaymentOption,
  enableGiveViaNav,
  checkInListingsStatus,
  epayByToken,
  processEpayByToken,
  addPhoneNumber,
  deletePhoneNumber,
  preferredPaymentNewCard,
  preferredPaymentNewCheck,
  appAccess,
  quickGiveProcessingEngines,
  celebrationsListing,
  getPrivacyPolicyHtml,
  getPaymentMode,
  generateUserOTP,
  deleteUserAccount,
  organizationList
} from "./";

import {
  contributions,
  contributionPDF,
  statementContribution,
} from "./ContributionReducer";
import {
  cardsOrCheckingProfilesData,
  hideGiveOnGivePage,
  existingRecurringProfilesPortalData,
  giveWithANewCardData,
  giveWithANewCheckData,
  deleteCardOrCheckingPortalData,
  giveWithAnExistingCardCheckingPortalData,
  setAsDefaultCardCheckingData,
  cancelARecurringData,
  setupANewRecurringPortalData,
  updateARecurringPortalData,
  newCardDetails,
  newCheckDetails,
  giveMethods
} from "./userDashboard/give";

import {
  prayerWalls,
  addPrayer,
  markedAnsweredPrayer,
  prayerWallsLikeOrPray,
  archivePrayer,
  myPrayerWalls,
} from "./userDashboard/Prayerwalls";
import {
  setOrUnsetSpouse,
  searchForSpouse,
  getUserFamilyDataMportal,
} from "./userDashboard/spouse";

import { getFollowUpByOrganization, getForm, submitForm } from "./followup";

import {
  getOrganizationForms,
  getFormQuestionAndAnswer,
  getFormSchema,
} from "./userDashboard/forms";

import {
  getFollowUpByOrganizationIdData,
  createActivityLogData,
  getActivityLogData,
  getFollowUpFormDetailsData,
} from "./userDashboard/followup";

import {
  groupUsers,
  groupUsersPending,
  deleteUsersFromDepartmentGroup,
  addRemoveUserAdminAccess,
  childrenModuleSpecificInfo,
  mediaVaultListing,
  sendCommunicationChildren,
  orgMessage,
  sendCommunicationParentsProcess,
  searchedUserToJoinDept,
  positionsListing,
  addDepartment,
  renameDepartmentGroup,
  searchedUsers,
  createGroup,
  changeGroupBanner,
  approveUser,
} from "./userDashboard/serviceUnit";

export default combineReducers({
  ipData,
  orgInfo,
  loginData,
  signupData,
  forgotPasswordData,
  forgotPinData,
  verifyForgotPasswordOrPinData,
  resetPasswordOrPinData,
  contributions,
  orgInfoList,
  updateUserData,
  getUserProfileData,
  donatedAmountData,
  creditCardInfo,
  giveWithCardData,
  cardsOrCheckingProfilesData,
  existingRecurringProfilesPortalData,
  hideGiveOnGivePage,
  giveWithANewCardData,
  giveWithANewCheckData,
  deleteCardOrCheckingPortalData,
  giveWithAnExistingCardCheckingPortalData,
  setAsDefaultCardCheckingData,
  cancelARecurringData,
  setupANewRecurringPortalData,
  updateARecurringPortalData,
  addChildData,
  contributionPDF,
  showMenuBar,
  prayerWalls,
  addPrayer,
  markedAnsweredPrayer,
  archivePrayer,
  prayerWallsLikeOrPray,
  setOrUnsetSpouse,
  searchForSpouse,
  secondOrgInfo,
  newCardDetails,
  newCheckDetails,
  myPrayerWalls,
  getThemeChangeData,
  updateUser2FA,
  initiateRSVPData,
  verifyRSVPData,
  processCheckinData,
  deleteGuest,
  processCheckOut,
  verifyRSVPData,
  rsvpInformationInstanceData,
  processRSVPData,
  cancelRSVPData,
  multipleRsvpData,
  contributionProcessingEnginesData,
  processPaypalData,
  sermonData,
  selectedSermonData,
  checkinData,
  statementContribution,
  getFollowUpByOrganization,
  getForm,
  submitForm,
  selectedPaymentOption,
  enableGiveViaNav,
  checkInListingsStatus,
  epayByToken,
  processEpayByToken,
  addPhoneNumber,
  deletePhoneNumber,
  preferredPaymentNewCard,
  preferredPaymentNewCheck,
  appAccess,
  quickGiveProcessingEngines,
  celebrationsListing,
  getOrganizationForms,
  getFormQuestionAndAnswer,
  getFormSchema,
  getFollowUpByOrganizationIdData,
  createActivityLogData,
  getActivityLogData,
  getFollowUpFormDetailsData,
  groupUsers,
  groupUsersPending,
  deleteUsersFromDepartmentGroup,
  addRemoveUserAdminAccess,
  childrenModuleSpecificInfo,
  mediaVaultListing,
  sendCommunicationChildren,
  orgMessage,
  sendCommunicationParentsProcess,
  searchedUserToJoinDept,
  positionsListing,
  addDepartment,
  renameDepartmentGroup,
  searchedUsers,
  createGroup,
  changeGroupBanner,
  approveUser,
  getUserFamilyDataMportal,
  getPrivacyPolicyHtml,
  giveMethods,
  getPaymentMode,
  generateUserOTP,
  deleteUserAccount,
  organizationList
});
