import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import CustomAppBar from "../../components/Nav/GiveCampaignV2AppBar";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LandingPage from "./LandingPage";
import SuccessModal from "./SuccessModal";
import GiveForm from "./GiveForm";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { getGivingCampaignInfo } from "../../store/actions/givingCampaign";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import OurSimpleCharge from "./OurSimpleCharge";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    minWidth: "100vw",
    color: "white",
  },
  topSection: {
    backgroundImage: 'url("/images/LandingPageBackgroundPic.png")',
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    minHeight: "770px",
    flexGrow: 1,
    marginTop: "-60px",
    paddingTop: "60px",
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      minHeight: "671px",
    },
    "@media (min-width: 1680px)": {
      minHeight: "770px",
    },
  },
  indexAppBar: {
    flexGrow: 1,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    borderBottom: "0.5px solid #CBD5E1",
  },
  formAppBar: {
    flexGrow: 1,
    backgroundColor: "transparent",
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    borderBottom: "0.5px solid #E2E8F0",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    marginLeft: theme.spacing(2),
    width: "50px",
  },
  indexMenu: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
    color: "#FFFFFF",
  },
  formMenu: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
  },
  headerContent: {
    padding: "1.25rem",
    maxWidth: "37.5rem",
    textAlign: "center",
    margin: "auto",
    marginTop: "20.42rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (min-width: 1440px)": {
      marginTop: "12.42rem",
    },
  },
  typography1: {
    fontSize: "64px",
    fontWeight: "700",
    lineHeight: "84px",
    width: "734px",
    "@media (max-width: 768px)": {
      fontSize: "28px",
      lineHeight: "37.8px",
      width: "auto",
    },
  },
  typography2: {
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: "1rem",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  startGivingButton: {
    backgroundColor: "#2E53E2", // Set background color
    color: "#FFFFFF", // Set text color
    padding: "12px 24px",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#1D3C9D", // Darker color on hover
    },
  },
  startGivingButtonAppBar: {
    backgroundColor: "#2E53E2",
    color: "#FFFFFF",
    borderRadius: "100px", // Add border radius for round shape
    padding: "12px 24px",
    fontSize: "14px",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#1D3C9D",
    },
  },
  middleSection: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      columnGap: "1rem", // Add column gap for smaller devices
    },
  },
  middleSectionContainer: {
    background: theme.palette.type === "light" ? "#f1f5f9" : "#0F172A",
    height: "auto",
    width: "auto",
    padding: "6.25rem",
    paddingTop: "4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem", // Adjust padding for smaller devices
      paddingTop: " 3rem",
    },
    "@media (min-width: 1680px)": {
      padding: "6rem 18.25rem",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      padding: "6rem 11.25rem",
    },
  },
  sectionLeft: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "3rem",
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      width: "87%",
      rowGap: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      rowGap: "4rem",
    },
  },
  sectionRight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "14rem",
    "@media (min-width: 1680px)": {
      rowGap: "14rem",
      marginTop: "2rem",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      rowGap: "18rem",
      marginTop: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      rowGap: "8rem",
    },
  },
  typography3: {
    fontSize: "14px",
    fontWeight: "500",
    Height: "1.1875rem",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "1.18rem",
    marginBottom: "1.25rem",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  typography4: {
    fontSize: "24px",
    fontWeight: "600",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "2.025rem",
    marginBottom: "2.5rem",
    "@media (max-width: 768px)": {
      fontSize: "16px",
    },
    "@media (min-width: 1680px)": {
      marginBottom: "5.5rem",
    },
  },
  typography5: {
    fontSize: "20px",
    fontWeight: "400",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "1.68rem",
    left: "12.6875rem",
    lineHeight: "27px",
    "@media (max-width: 768px)": {
      fontSize: "12px",
      lineHeight: "16.2px",
    },
  },
  typography6: {
    fontSize: "20px",
    fontWeight: "400",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "1.68rem",
    paddingTop: "1.75rem",
    paddingLeft: "1.875rem",
    lineHeight: "22px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "16.2px",
      paddingTop: "15px",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  img1: {
    height: "35.9375rem",
    maxWidth: "100%",
    height: "auto",
    borderRadius: "0.75rem",
    marginBottom: "2.5rem",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5rem", // Increase margin bottom by 3 times for smaller devices
    },
    "@media (min-width: 1680px)": {
      marginBottom: "5.5rem",
      width: "73%",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      width: "77%",
    },
  },
  img2: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "0.75rem",
    paddingLeft: "5rem",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2rem", // Remove paddingLeft for smaller devices
    },
    "@media (min-width: 1680px)": {
      width: "85%",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      width: "74%",
    },
  },
  img3: {
    maxWidth: "100%",
    width: "100%",
  },
  footer: {
    marginTop: "auto",
  },
}));

const GivingCampaign = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [indexPage, setIndexPage] = useState(true);
  const [giveForm, setGiveForm] = useState(false);
  const [donatedAmount, setDonatedAmount] = useState("");
  const [giveInfo, setGiveInfo] = useState({});

  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const giveContainerRef = useRef(null);

  const themeMode = theme.palette.type;

  const orgInfo = useSelector((state) => state.orgInfo.responseData);

  const handleFormSubmision = () => {
    setGiveForm(false);
    setSuccessModal(true);
  };

  const handleDonation = (donation) => {
    setDonatedAmount(donation);
    setIndexPage(false);
    setGiveForm(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
    setIndexPage(true);
  };

  useEffect(() => {
    dispatch(getGivingCampaignInfo({ orgId: orgInfo.orgId }, givingCampaignCB));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [indexPage]);

  const givingCampaignCB = (data) => {
    if (data.isGivingCampaignEnable !== "true") return history.push("/");
    setGiveInfo(data);
  };

  let appBarStyle = classes.indexAppBar;
  let menu = classes.indexMenu;

  if (!indexPage) {
    appBarStyle = classes.formAppBar;
    menu = classes.formMenu;
  }

  const scrollToGiveCards = () => {
    giveContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleHomeBtnClick = () => {
    setIndexPage(true);
    setGiveForm(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {successModal && (
        <SuccessModal
          openModal={successModal}
          onClose={handleCloseSuccessModal}
          heading="Thank You For Your Donation"
          subContent="You have just made a family very happy!"
          btnText="Back to Homepage"
        />
      )}
      <div id="give-campaign-v2">
        {giveForm && (
          <GiveForm
            onSubmit={handleFormSubmision}
            donatedAmount={donatedAmount}
            giveInfo={giveInfo}
            classes={classes}
            showLogo={giveInfo.showLogo}
            logo={orgInfo.logo}
            appBarClass={appBarStyle}
            menu={menu}
            themeMode={themeMode}
            indexPage={indexPage}
            handleHomeBtnClick={handleHomeBtnClick}
          />
        )}

        {indexPage && (
          <div className={classes.root}>
            <CustomAppBar
              classes={classes}
              showLogo={giveInfo.showLogo}
              logo={orgInfo.logo}
              appBarClass={appBarStyle}
              menu={menu}
              scrollToGiveCards={scrollToGiveCards}
              handleHomeBtnClick={scrollToTop}
            />
            <div className={classes.topSection}>
              <div className={classes.headerContent}>
                <h6 className={classes.typography1}>
                  Welcome to The Lord's Table Food Pantry
                </h6>
                <p className={classes.typography2}>
                  Welcome to The Lords Table Pantry! Join us in our mission to
                  feed the hungry. Your passion can help make a difference!
                </p>
                <Button
                  variant="contained"
                  className={classes.startGivingButton}
                  onClick={scrollToGiveCards}
                >
                  Start Giving
                </Button>
              </div>
            </div>
            {/* Middle section */}
            <div className={classes.middleSectionContainer}>
              <div>
                <h3 className={classes.typography3}>
                  What we are passionate about?
                </h3>
                <p className={classes.typography4}>
                  Empowering Communities Through <br /> Compassion: Our Story at
                  Lord's Food Pantry
                </p>
              </div>
              <div className={classes.middleSection}>
                <div className={classes.sectionLeft}>
                  <img
                    src="/images/Frame 3.png"
                    alt="Image 1"
                    className={classes.img1}
                  />
                  <p className={classes.typography5}>
                    The Lord's Table Food Pantry is a not-for-profit
                    organization committed to feeding the hungry at no cost to
                    them or their families. Our desire is to create a community
                    that fosters hope and allows people to reach their full
                    potential.
                    <br />
                    <br />
                    Our mission is to ensure that no one goes to bed hungry,
                    fostering hope and allowing people to reach their full
                    potential.
                  </p>
                </div>
                <div className={classes.sectionRight}>
                  <p className={classes.typography6}>
                    Chicago, our beloved 'small town in a big city', continues
                    to attract individuals and families from far and near.
                    However, this influx of people has come at a cost,
                    stretching local resources and leaving many unsure of their
                    next meal.
                  </p>
                  <img
                    src="/images/Frame 2.png"
                    alt="Image 2"
                    className={classes.img2}
                  />
                </div>
              </div>
            </div>
            <OurSimpleCharge />
            <LandingPage
              onDonation={handleDonation}
              themeMode={themeMode}
              giveContainerRef={giveContainerRef}
              carouselImages={giveInfo.pageImages}
            />
          </div>
        )}
        {/* Footer section */}
        {giveForm && <Footer orgInfo={orgInfo} themeMode={themeMode} />}
      </div>
    </>
  );
};

export default GivingCampaign;
