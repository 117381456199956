import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: props => props.backgroundColor,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ColorButton = withStyles((theme) => ({
    root: {
      // color: '#111',
      // backgroundColor: props => props.backgroundColor,
      '&:hover': {
        backgroundColor: props => props.backgroundColor,
      },
    },
  }))(Button);

export default function CustomizedMenus({backgroundColor, menuList}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ColorButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="inherit"
        onClick={handleClick}
        backgroundColor={backgroundColor}
        type='button'
      >
          {/* <i className="fas fa-plus icon-size"> */}
        Add Media
        {/* </i> */}
      </ColorButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
                       {menuList.map((item, index) => {
                    const handle = () => {
                        item.func();
                        handleClose()
                    }
                    return (
                <StyledMenuItem onClick={handle} key={index} backgroundColor={backgroundColor}>
                    <ListItemIcon>
                        <i className={item.iconName} />
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                </StyledMenuItem>

                    )

                })}
      </StyledMenu>
    </div>
  );
}