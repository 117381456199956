import React, { useState } from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import { resetPasswordOrPin, resetEndpointDataAction } from '../../store/actions';
import './form.css';
import NotificationMesage from '../Common/NotificationMesage';
import {toast} from 'react-toastify'
import {TextField} from '@material-ui/core'
import {Button} from 'react-bootstrap'

const initialValues = {
  password: '',
  passwordConfirmation: ''
};
export const ResetPasswordForm = () => {
  // Local State
  const [toggleShowPassword, setToggleShowPassword] = useState(false);
  
  // Global store data
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const resetPasswordOrPinData = useSelector(state => state.resetPasswordOrPinData, shallowEqual)
  const token = useSelector(state => state.verifyForgotPasswordOrPinData.responseData.token, shallowEqual)
  const tokenType = useSelector(state => state.verifyForgotPasswordOrPinData.responseData.tokenType, shallowEqual)
  // const secondaryBgColor = {backgroundColor: orgInfo ? orgInfo.orgColors.secondary : ''}
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.orgColors.primary : '' }
  
  const dispatch = useDispatch();
  let history = useHistory();
  const themeColor = useThemeColor();

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(32, 'Maximum 32 characters')
      .matches(/[A-Z]/, 'Must contain atleast one uppercase character')
      .matches(/[a-z]/, 'Must contain atleast one lowercase character')
      .matches(/\d/, 'Must contain atleast one number')
      .matches(/[@#$%!]/, 'Must contain atleast one @, #, $, %, or !')
      .required('Required field'),
    passwordConfirmation: Yup.string()
      .test('password-match', 'Passwords must match', function(value){
        return this.parent.password === value
      })

  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  
  const toggleShowPasswordFunc = () => {
    setToggleShowPassword(!toggleShowPassword);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      const { password } = values;
      if(password) dispatch(resetPasswordOrPin({password, token, tokenType, orgId: orgInfo.orgId}));
    }
  });

  React.useEffect(() => {
    if (resetPasswordOrPinData && resetPasswordOrPinData.responseCode === '00') {
      toast.success(<NotificationMesage message={resetPasswordOrPinData.responseMessage} responseStatus={resetPasswordOrPinData.responseCode} />, {
        onClose: () => {
          dispatch(resetEndpointDataAction);
          history.push('/')
        }
      })
    }
    if (resetPasswordOrPinData && resetPasswordOrPinData.responseCode === '01') {
      toast.error(<NotificationMesage message={resetPasswordOrPinData.responseMessage} responseStatus={resetPasswordOrPinData.responseCode} />, {
        onClose: () => {
          dispatch(resetEndpointDataAction);
        } 
      })
    }
  }, [resetPasswordOrPinData.responseCode])


  return (
    <>
      {/* {resetPasswordOrPinData && resetPasswordOrPinData.responseCode === '00' ? 
      <Alert isSuccess showCloseBtn={false} showBackToLoginBtn={true} msg={resetPasswordOrPinData.responseMessage} /> : */}
      <>
        {/* {resetPasswordOrPinData.responseCode === '01' && <Alert isSuccess={false} msg={resetPasswordOrPinData.responseMessage} />} */}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework mt-10">
            <div className="fv-plugins-icon-container mb-8">
              <div className="position-relative password">
                <div className="position-relative">
                  <TextField
                    label="Password"
                    type={toggleShowPassword ? 'text' : 'password'}
                    className={` ${getInputClasses(
                      'password'
                    )}`}
                    name="password"
                    fullWidth
                    variant="outlined"
                    error={formik.touched.password && formik.errors.password}
                    {...formik.getFieldProps('password')}
                  />
                  <span className="position-absolute p-2" onClick={toggleShowPasswordFunc}>
                    {toggleShowPassword ? (
                      <i className="far fa-eye"></i>
                      ) : (
                        <i className="far fa-eye-slash"></i>
                        )}
                  </span>
                  {/* <i className="fas fa-lock email-icon position-absolute"></i> */}
                </div>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block errorText">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>

            <div className="fv-plugins-icon-container mb-8">
              <div className="position-relative password">
                <div className="position-relative">
                  <TextField
                    placeholder="Confirm Password"
                    type={toggleShowPassword ? 'text' : 'password'}
                    className={` ${getInputClasses(
                      'passwordConfirmation'
                    )}`}
                    name="passwordConfirmation"
                    fullWidth
                    variant="outlined"
                    error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                    {...formik.getFieldProps('passwordConfirmation')}
                  />
                  <span className="position-absolute p-2" onClick={toggleShowPasswordFunc}>
                    {toggleShowPassword ? (
                      <i className="far fa-eye"></i>
                      ) : (
                        <i className="far fa-eye-slash"></i>
                        )}
                  </span>
                  {/* <i className="fas fa-lock email-icon position-absolute"></i> */}
                </div>
              </div>
              {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block errorText">{formik.errors.passwordConfirmation}</div>
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-between align-items-center my-5">
            <div className="d-flex align-items-center">
              <Link className="text-dark-50 text-hover-primary" to="/">
              <Button variant="secondary" className="btn px-7 py-4">Back to Login</Button>
                {/* <span><i className="las la-angle-left mr-2"></i></span><span className="text-primary">Back to Login</span> */}
                </Link>
            </div>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={resetPasswordOrPinData && resetPasswordOrPinData.loading}
              style={themeColor.btnStyle}
              className={`btn font-weight-bold px-7 py-4 text-white`}>
              <span>{resetPasswordOrPinData.loading ? 'Please wait...' : 'Reset Password'}</span>
              {resetPasswordOrPinData && resetPasswordOrPinData.loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            {/* <div className="d-flex justify-content-center align-items-center">
              <Link className="text-dark-50 text-hover-primary" to="/"><span><i className="las la-angle-left mr-2"></i></span><span className="text-primary">Back to Login</span></Link>
            </div> */}
            </div>
          </form>
          </>
          {/* } */}
      </>
  )
}