import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CustomizedMenu from "../../Common/DropDownList";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  Paper,
} from "@material-ui/core";
import ConfirmationDialog from "../../Common/ConfirmDialog";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  cardsOrCheckingProfiles,
  hideGiveOnGivePageAction,
  deleteCardOrCheckingPortal,
  setAsDefaultCardChecking,
} from "../../../store/actions/userDashboardActions/give";
import { getUserDetails } from "../../../utils/authHelpers";
import ResizeableModal from "../../Common/ResizeableModal";
import { ResizeableModalWithoutBtn } from "../../Common/ResizeableModal";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CardUserDetails } from "./CardUserDetails";
import { GiveWithExistingCardForm } from "./GiveWithExistingCardForm";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import { useWindowDimensions } from "../../../utils/customHooks/useWindowDimensions";
import { AutoDebitEnrollmmentTable } from "./AutoDebitEnrollmentTable";
import { useContributionProcessEngine } from "../../../utils/customHooks/useContributionProcessEngine";
import "./Give.scss";
import { contributionProcessingEngines } from "../../../store/actions";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { logout } from "../../../utils/authHelpers";
import MasterCard from "../../Icons/mastercard.svg";
import Amex from "../../Icons/amex.svg";
import Discover from "../../Icons/discover.svg";
import Visa from "../../Icons/visa.svg";
import DefaultCard from "../../Icons/default_credit_card.svg";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  productTable: {
    "& thead": {
      "& th:first-child": {
        paddingLeft: 16,
      },
    },
    "& td": {
      borderBottom: "none",
    },
    "& td:first-child": {
      paddingLeft: "16px !important",
    },
  },
}));

export const UserGive = () => {
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [expYear, setExpYear] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [hiddenData, setHiddenData] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalState, setModalState] = React.useState({});
  const [recordLast4Digits, setRecordLast4Digits] = useState("");
  const [shouldOpenSetDefaultDialog, setShouldOpenSetDefaultDialog] = useState(
    false
  );
  const [
    shouldOpenConfirmationDialog,
    setShouldOpenConfirmationDialog,
  ] = useState(false);
  const [isCardAndCheck, setIsCardAndCheck] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isPaypal, setIsPaypal] = useState(false);
  const [token, setToken] = useState("");
  const [dynamicAction, setDynamicAction] = useState("submit");
  const themeColor = useThemeColor();
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const cardsOrCheckingProfilesData = useSelector(
    (state) => state.cardsOrCheckingProfilesData.responseData,
    shallowEqual
  );
  const deleteCardData = useSelector(
    (state) => state.deleteCardOrCheckingPortalData.responseData,
    shallowEqual
  );
  const contributionData = useSelector(
    (state) =>
      state.contributionProcessingEnginesData?.responseData?.returnedRecords,
    shallowEqual
  );
  console.log(contributionData, "sdsfs");

  const open = Boolean(anchorEl);
  const user = getUserDetails();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  // const cont

  const classes = useStyles();
  const today = new Date();
  const mm = today.getMonth() + 1;
  const yy = today
    .getFullYear()
    .toString()
    .substr(-2);

  useEffect(() => {
    const checkContributions = contributionData?.map((item) => {
      console.log(item, "item");
      if (item.type === "CARDANDCHECK") {
        console.log(item, "CARDANDCHECKitem");
        setIsCardAndCheck(true);
      }
      if (item.type === "CARD") setIsCard(true);
      if (item.type === "CHECK") setIsCheck(true);
      if (item.type === "PAYPAL") setIsPaypal(true);
    });
  }, [contributionData]);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("recaptcha verifiction not successful");
      return;
    }

    const result = await executeRecaptcha("dynamicAction");
    // console.log(result, 'result');
    setToken(result);
  }, [dynamicAction, executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      if (!token) {
        logout();
      } else {
        setToken(token);
      }
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, dynamicAction]);

  console.log(contributionData, "contribuitionData");

  const handleDialogClose = () => {
    setShouldOpenSetDefaultDialog(false);
    setShouldOpenConfirmationDialog(false);
    updatePageData();
  };

  const handleDeleteCard = (recordId) => {
    setRecordId(recordId);
    setShouldOpenConfirmationDialog(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeMenuItem = () => {
    handleClose();
  };

  const handleSetDefault = (recordId, last4Digits) => {
    setRecordId(recordId);
    setRecordLast4Digits(last4Digits);
    setShouldOpenSetDefaultDialog(true);
    // setShouldOpenConfirmationDialog(true)
  };

  const handleDeleteCardResponse = () => {
    dispatch(
      deleteCardOrCheckingPortal(
        {
          recordId,
          orgId: orgInfo.orgId,
          userId: user.userId,
        },
        handleDialogClose
      )
    );
  };

  const handleSetDefaultCardResponse = () => {
    dispatch(
      setAsDefaultCardChecking(
        {
          recordId,
          orgId: orgInfo.orgId,
          userId: user.userId,
        },
        handleDialogClose
      )
    );
  };

  const resetCloseModal = () => {
    setIsModalClosed(false);
  };
  const resetModalState = () => {
    setModalState({ card: false, check: false, autoDebit: false });
  };

  const closeModal = () => {
    updatePageData();
    setIsModalClosed(true);
  };
  const setCardImg = (cardMaker) => {
    let src = "";
    switch (cardMaker) {
      case "mastercard":
        src = "images/mastercard.svg";
        return (
          <img
            src={MasterCard}
            className={`mr-2 ${width < 720 ? "mobile-width" : "web-width"}`}
          />
        );
      case "visa":
        src = "images/visa.svg";
        return (
          <img
            src={Visa}
            className={`mr-2 ${width < 720 ? "mobile-width" : "web-width"}`}
          />
        );
      case "discover":
        src = "images/discover.svg";
        return (
          <img
            src={Discover}
            className={`mr-2 ${width < 720 ? "mobile-width" : "web-width"}`}
          />
        );
      case "amex":
        src = "images/amex.svg";
        return (
          <img
            src={Amex}
            className={`mr-2 ${width < 720 ? "mobile-width" : "web-width"}`}
          />
        );
      default:
        src = "images/default_credit_card.svg";
        return (
          <img
            src={DefaultCard}
            className={`mr-2 ${width < 720 ? "mobile-width" : "web-width"}`}
          />
        );
    }
  };

  const handleAnchorEl = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHiddenData = (item, expYear, expMonth, e) => {
    setHiddenData(item);
    setExpYear(expYear);
    setExpMonth(expMonth);
    handleAnchorEl(e);
  };

  const updatePageData = () => {
    dispatch(
      cardsOrCheckingProfiles({
        orgId: orgInfo.orgId,
        userId: user.userId,
      })
    );
  };

  useEffect(() => {
    dispatch(
      cardsOrCheckingProfiles({
        orgId: orgInfo.orgId,
        userId: user.userId,
      })
    );
    dispatch(hideGiveOnGivePageAction(true));
  }, []);

  useEffect(() => {
    if (cardsOrCheckingProfilesData?.returnedRecords) {
      if (isPPS()) {
        const filteredToken = cardsOrCheckingProfilesData.returnedRecords.filter(
          (item) => item.token && item.token !== ""
        );
        setFilteredData(filteredToken);
      } else {
        const filteredToken = cardsOrCheckingProfilesData.returnedRecords.filter(
          (item) => item.token === ""
        );
        setFilteredData(filteredToken);
      }
    }
  }, [cardsOrCheckingProfilesData]);

  const triggerModal = (modalType) => {
    if (modalType == "card")
      setModalState({ card: true, check: false, autoDebit: false });
    if (modalType == "check")
      setModalState({ card: false, check: true, autoDebit: false });
    if (modalType == "autoDebit")
      setModalState({ card: false, check: false, autoDebit: true });
  };

  const menuList = [
    {
      iconName: "fa fa-credit-card",
      title: "New Card",
      func: () => triggerModal("card"),
    },
    {
      iconName: "fa fa-money-check ",
      title: "New Check",
      func: () => triggerModal("check"),
    },
    {
      iconName: "fa fa-life-ring",
      title: "Auto-Debit Giving",
      func: () => triggerModal("autoDebit"),
    },
  ];

  const isPPS = () => {
    const ppsPath = "/user/donate/pps";
    return pathname === ppsPath;
  };

  // console.log(paymentType, 'paymentType')
  return (
    <Paper className="user-give w-100 my-8 p-6">
      {width < 720 && (
        <div className="d-flex justify-content-end">
          <CustomizedMenu menuList={menuList} />
        </div>
      )}
      {width >= 720 && (
        <div className="d-flex justify-content-end">
          {(isCard || isCardAndCheck) && (
            <ResizeableModal
              openModalBtnIcon={
                <i className="fa fa-credit-card kt-font-primary text-white px-2"></i>
              }
              openModalBtnText="New Card"
              title="Giving with a new card"
              maxWidth="xs"
              className="py-3"
              singleModal={true}
              closeModal={isModalClosed}
              resetCloseModal={resetCloseModal}
              fullWidth={true}
              titleIcon={<i className="fa fa-credit-card kt-font-primary"></i>}
              backgroundColor={themeColor.color}
            >
              <CardUserDetails updatePageData={updatePageData} />
            </ResizeableModal>
          )}
          {(isCheck || isCardAndCheck) && (
            <ResizeableModal
              className="ml-5 py-3"
              openModalBtnIcon={
                <i className="fa fa fa-money-check text-white px-2"></i>
              }
              openModalBtnText="New Check"
              title="Giving with a new checking account"
              maxWidth="xs"
              singleModal={true}
              closeModal={isModalClosed}
              resetCloseModal={resetCloseModal}
              fullWidth={true}
              titleIcon={<i className="fa fa-money-check kt-font-primary"></i>}
              backgroundColor={themeColor.color}
            >
              <CardUserDetails
                checkForm={true}
                updatePageData={updatePageData}
              />
              {/* <CheckForm closeModal={closeModal} /> */}
            </ResizeableModal>
          )}
          <ResizeableModal
            className="ml-5 py-3"
            openModalBtnIcon={
              <i className="fa fa-life-ring  kt-font-primary text-white px-2"></i>
            }
            openModalBtnText="Auto-Debit Giving"
            title="Create/Manage Auto-Debit enrollment"
            maxWidth="sm"
            fullWidth={true}
            titleIcon={<i className="fa fa-life-ring kt-font-primary"></i>}
            backgroundColor={themeColor.color}
          >
            <AutoDebitEnrollmmentTable />
          </ResizeableModal>
          {console.log(isModalClosed, "isModalClosed")}
        </div>
      )}
      <hr />
      {filteredData?.length < 1 ? (
        <p className="my-5 text-center">No record Found!</p>
      ) : (
        <Table
          className={clsx("whitespace-pre min-w-750", classes.productTable)}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className="px-0">Name</TableCell>
              <TableCell className="px-0" align="center">
                Card
              </TableCell>
              <TableCell className="px-0" align="center">{`${
                width < 720 ? "Exp." : "Expiration"
              } Date`}</TableCell>
              {
                <TableCell className="px-0 pl-2" align="center">
                  Default
                </TableCell>
              }
              {width < 720 && (
                <TableCell className="px-0" align="center"></TableCell>
              )}
              {width >= 720 && (
                <TableCell className="px-0" align="center">
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map((profile, index) => {
              const expYear = profile?.expirationDate?.substr(
                profile?.expirationDate?.length - 2,
                profile?.expirationDate?.length - 1
              );
              const expMonth = profile?.expirationDate?.substr(0, 2);
              return (
                <TableRow hover key={`${index}a`}>
                  <TableCell className="px-0" align="left">
                    {index + 1}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {`${profile.firstName} ${
                      width >= 720 ? profile.lastName : ""
                    }`}
                  </TableCell>
                  <TableCell
                    className="px-0 card-icon"
                    align="center"
                    style={{ width: `${width <= 320 ? "7ch" : ""}` }}
                  >
                    {setCardImg(profile.cardMaker)}
                    {profile.type === "card" ? profile.last4Digits : "N/A"}
                  </TableCell>
                  <TableCell className="px-0" align="center">
                    {profile.expirationDate ? profile.expirationDate : "N/A"}
                  </TableCell>
                  <TableCell className="px-0" align="center">
                    {profile.defaultCard === "1" ? (
                      <i className="fa fa-check-square text-success"></i>
                    ) : (
                      <span
                        className="cursor"
                        onClick={() =>
                          handleSetDefault(
                            profile.recordId,
                            profile.last4Digits
                          )
                        }
                      >
                        set
                      </span>
                    )}
                  </TableCell>
                  {width < 720 && (
                    <TableCell className="px-0" align="center">
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e) =>
                          handleHiddenData(profile, expYear, expMonth, e)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  {width >= 720 && (
                    <TableCell className="px-0 border-none" align="center">
                      {expYear <= yy && expMonth < mm ? (
                        <span className="text-danger">expired</span>
                      ) : (
                        <ResizeableModal
                          openModalBtnIcon={
                            <i className="fa fa-credit-card kt-font-primary"></i>
                          }
                          openModalBtnText="New Card"
                          title="Giving with an existing card"
                          maxWidth="xs"
                          showSpan={true}
                          singleModal={true}
                          closeModal={isModalClosed}
                          resetCloseModal={resetCloseModal}
                          fullWidth={true}
                          titleIcon={<i className="fa fa-credit-card"></i>}
                          hideBtn={true}
                          backgroundColor={themeColor.color}
                        >
                          <GiveWithExistingCardForm
                            maskedCardNumber={profile.last4Digits}
                            expirationDate={profile.expirationDate}
                            fullName={`${profile.firstName} ${
                              width >= 720 ? profile.lastName : ""
                            }`}
                            recordId={profile.recordId}
                            closeModal={closeModal}
                            token={profile?.token || ""}
                          />
                        </ResizeableModal>
                      )}
                      <span
                        onClick={() => handleDeleteCard(profile.recordId)}
                        style={{
                          color: `${
                            themeColor.isDarkMode ? "#fff" : themeColor.color
                          }`,
                        }}
                        className="cursor ml-3"
                      >
                        <i
                          style={{
                            color: `${
                              themeColor.isDarkMode ? "#fff" : themeColor.color
                            }`,
                          }}
                          className="fa fa-trash-alt font-size-sm"
                        ></i>
                        &nbsp;Delete
                      </span>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}

      {shouldOpenConfirmationDialog && (
        <ConfirmationDialog
          open={shouldOpenConfirmationDialog}
          onConfirmDialogClose={handleDialogClose}
          onYesClick={handleDeleteCardResponse}
          text="Are you sure to delete?"
          loading={deleteCardData?.loading}
        />
      )}
      {shouldOpenSetDefaultDialog && (
        <ConfirmationDialog
          open={shouldOpenSetDefaultDialog}
          onConfirmDialogClose={handleDialogClose}
          onYesClick={handleSetDefaultCardResponse}
          text={`Are you sure to set card ${recordLast4Digits} as default?`}
          // loading={deleteCardData?.loading}
        />
      )}

      <Menu
        id="short-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: "25ch",
          },
        }}
      >
        <div className="fix-focus-outline text-center py-3">
          {hiddenData &&
            (expYear <= yy && expMonth < mm ? (
              <span className="text-danger">expired</span>
            ) : (
              <>
                <ResizeableModal
                  // openModalBtnIcon={<i className="fa fa-credit-card kt-font-primary"></i>}
                  // openModalBtnText='New Card'
                  title="Giving with an existing card"
                  maxWidth="xs"
                  showSpan={true}
                  singleModal={true}
                  menuItem={true}
                  closeMenuItem={closeMenuItem}
                  closeModal={isModalClosed}
                  resetCloseModal={resetCloseModal}
                  fullWidth={true}
                  titleIcon={
                    <i className="fa fa-credit-card kt-font-primary"></i>
                  }
                  hideBtn={true}
                  backgroundColor={themeColor.color}
                >
                  <GiveWithExistingCardForm
                    maskedCardNumber={hiddenData.last4Digits}
                    recordId={hiddenData.recordId}
                    closeModal={closeModal}
                  />
                </ResizeableModal>
                <span
                  onClick={() => handleDeleteCard(hiddenData.recordId)}
                  className="text-blue cursor ml-3"
                >
                  <i className="fa fa-trash-alt font-size-sm text-blue"></i>
                  &nbsp;Delete
                </span>
              </>
            ))}
        </div>
      </Menu>
      {width < 720 && (
        <>
          <ResizeableModalWithoutBtn
            title="Giving with a new card"
            maxWidth="xs"
            modalState={modalState.card}
            singleModal={true}
            closeModal={isModalClosed}
            resetModalState={resetModalState}
            fullWidth={true}
            titleIcon={<i className="fa fa-credit-card kt-font-primary"></i>}
            backgroundColor={themeColor.color}
          >
            <CardUserDetails closeModal={closeModal} />
          </ResizeableModalWithoutBtn>
          <ResizeableModalWithoutBtn
            className="ml-5"
            title="Giving with a new checking account"
            maxWidth="xs"
            singleModal={true}
            closeModal={isModalClosed}
            modalState={modalState.check}
            resetModalState={resetModalState}
            fullWidth={true}
            titleIcon={<i className="fa fa-money-check kt-font-primary"></i>}
            backgroundColor={themeColor.color}
          >
            <CardUserDetails checkForm={true} updatePageData={updatePageData} />
            {/* <CheckForm closeModal={closeModal} /> */}
          </ResizeableModalWithoutBtn>
          <ResizeableModalWithoutBtn
            className="ml-5"
            modalState={modalState.autoDebit}
            title="Create/Manage Auto-Debit enrollment"
            maxWidth="sm"
            fullWidth={true}
            resetModalState={resetModalState}
            titleIcon={<i className="fa fa-life-ring kt-font-primary"></i>}
            backgroundColor={themeColor.color}
          >
            <AutoDebitEnrollmmentTable />
          </ResizeableModalWithoutBtn>
        </>
      )}
    </Paper>
  );
};
