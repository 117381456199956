import React, { useEffect, useState } from "react";
import Donate from "./Donate";
import SupportRoles from "./SupportRoles";
import Footer from "./Footer";
import "./styles.scss";

const LandingPage = ({
  onDonation,
  themeMode,
  giveContainerRef,
  carouselImages,
}) => {
  const [activeCard, setActiveCard] = useState(2);

  const handleCardClick = (id) => {
    setActiveCard(id);
  };

  const donationCardData = [
    {
      id: 1,
      isPopular: false,
      donation_amount: 25,
      first_guarantee_description: "A week worth of groceries",
      second_guarantee_description: "Manageable for a family in need",
    },
    {
      id: 2,
      isPopular: true,
      donation_amount: 50,
      first_guarantee_description: "Two weeks worth of groceries",
      second_guarantee_description: "Manageable for a family in need",
    },
    {
      id: 3,
      isPopular: false,
      donation_amount: 100,
      first_guarantee_description: "A month’s worth of groceries",
      second_guarantee_description: "Manageable for a family in need",
    },
  ];

  let background = "";
  let makeADifference = "";

  if (themeMode === "light") {
    background = "give-campaign-light-container";
    makeADifference = "make-a-difference-light-mode";
  } else if (themeMode === "dark") {
    background = "give-campaign-dark-container";
    makeADifference = "make-a-difference-dark-mode";
  }
  return (
    <div className={background}>
      <div className="benefits-of-giving">
        <div className="benefits-of-giving-header">
          <h3>Benefits of donating</h3>
          <h2>
            Unveiling the Power of Giving: Impactful Testimonials on the
            Benefits of Your Donations
          </h2>
          <div className="details-of-benefits">
            <p className="top-left-detail">
              "Contributing to Lord's Food Pantry was a deeply rewarding
              experience. Knowing that my donation played a part in providing
              nourishment and hope to families in need filled my heart with joy.{" "}
            </p>
            <img
              src="/images/divider.png"
              alt="Divider"
              className="top-divider"
            />
            <p className="top-right-detail">
              Every contribution, big or small, makes a difference, and being
              part of that change is a privilege."
            </p>
          </div>
          <div className="details-of-benefits">
            <p className="bottom-left-detail">
              "I choose to donate to Lord's Food Pantry because it's not just
              about charity; it's about building a better tomorrow.{" "}
            </p>
            <img
              src="/images/bottom_divider.png"
              alt="Divider"
              className="bottom-divider"
            />
            <p className="bottom-right-detail">
              Being a donor has shown me the power of collective giving. The
              ripple effect of my contributions has touched numerous lives,
              creating a stronger, more compassionate community.{" "}
            </p>
          </div>
        </div>
      </div>
      <div
        className={`make-a-difference ${makeADifference}`}
        ref={giveContainerRef}
      >
        <h2>Your donations can make a difference</h2>
        <p>
          The Significance of Your Contribution in Building a Stronger
          Community. You can choose to make any of the donations below or{" "}
          <a onClick={() => onDonation(0.00)}>Donate a large sum today</a>
        </p>
      </div>
      <div className="mapped-donation-card">
        {donationCardData.map((data) => {
          return (
            <div key={data.id}>
              <Donate
                data={data}
                isActive={data.id === activeCard}
                handleClick={() => handleCardClick(data.id)}
                onDonation={onDonation}
                themeMode={themeMode}
              />
            </div>
          );
        })}
      </div>
      <SupportRoles carouselImages={carouselImages} />
      <Footer themeMode={themeMode} />
    </div>
  );
};

export default LandingPage;
