import React, {useState} from 'react'
import DynamicForms from '../../Forms/Dynamic'
import { Button, Paper } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import './forms.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { ButtonContainer } from '../../Common/Button';
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import { submitFormAnswer } from '../../../store/actions/userDashboardActions/forms';
import { useHistory } from 'react-router-dom';

export default function PublicViewForm({data}) {
    console.log(data, 'data');
    const history = useHistory()
    const dispatch = useDispatch()
    const themeColor = useThemeColor();
    const backgroundColor = themeColor.color
    const [isLoading, setLoading] = useState(false)
    const [viewMedia, setViewMedia] = useState({
        show: false,
        item: {}
    })
    const [displayForm, setDisplayForm] = React.useState({})
    const [sliderData, setSliderData] = useState([])
    const handleChange = ({ target: { name, value } }, indexOfForm) => {
      const temp = {...displayForm};
      const tempForm = [...temp.schemaDefinition]
      if (temp.schemaDefinition[indexOfForm].type === 'date') {
        value = moment(value).format('YYYY-MM-DD')
      }
      temp.schemaDefinition[indexOfForm].answer = value;
      setDisplayForm(temp);
    };
    const handleChangeCheckbox = ({ target: { name, checked } }, indexOfForm) => {
        const temp = {...displayForm};
        const tempForm = [...temp.schemaDefinition]
        const payload = {
          name,
          checked
        }
        let checkbox = [...temp.schemaDefinition[indexOfForm].answer]
        const objIndex= checkbox.findIndex((el) => el.name === name)
        if (objIndex === -1) {
            checkbox = [...temp.schemaDefinition[indexOfForm].answer, {...payload}]
        } else {
          checkbox[objIndex].checked = checked
        }
        const filteredData = checkbox.filter((el) => el.checked);
  
        temp.schemaDefinition[indexOfForm].answer = filteredData
        setDisplayForm(temp);
      };
      const handleChangeDate = (data, indexOfForm) => {
        const temp = {...displayForm};
        const tempForm = [...temp.schemaDefinition]
        temp.schemaDefinition[indexOfForm].answer = data;
        setDisplayForm(temp);
      };
  
    React.useEffect(() => {
        if (data) {
            setDisplayForm(data)
            if (data?.banner1Url) {
                setSliderData([...sliderData, data?.banner1Url])
            }
            if (data?.banner2Url) {
                setSliderData([...sliderData, data?.banner2Url])
            }
            // console.log(state, 'state data');
        }
        // if (isEdit === 'true' && getFormQuestionAndAnswerData) {
        //     // merge questions and answers
        //     const dataWithAnswer = state.schemaDefinition?.map((item, ind) => {
        //         console.log(Object.keys(getFormQuestionAndAnswerData.answers[ind]), 'yesse');
        //         if (item.question === Object.keys(getFormQuestionAndAnswerData.answers[ind])[0]) {
        //             item.answer = Object.values(getFormQuestionAndAnswerData.answers[ind])[0]
        //         }
        //     })
        // }
    }, [data])
    const handleSubmitCallback = () => {
        setLoading(false)
        // dispatch(getOrganizationForms(orgInfo.orgId))
        history.push('/')
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = displayForm.schemaDefinition?.map((item) => {
            return {
                [item.question]: item.answer
            } 
        })
        const payload = {
            answers: data,
            // legacyUserId: userData?.responseData?.userId
        }
        // if (isEdit === 'true') {
        //     return dispatch(submitFormAnswerUpdate(state.id, payload, handleSubmitCallback, () => setLoading(false)))
        // }
        return dispatch(submitFormAnswer(displayForm.id, payload, handleSubmitCallback, () => setLoading(false)))
    }
    const determineUrlType = (data) => {
        const fileType = data?.split('.').pop()
        // console.log(data.split('.'));

        if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'PNG' || fileType === 'JPG' || fileType === 'JPEG') {
            return 'image'
        }
        return 'mp4'
    }

    const handleOpenModal = (item) => {
        setViewMedia({...viewMedia, show: true, item})
    }
    const handleClose = () => {
        setViewMedia({...viewMedia, show: false, item: {}})
    }
  return (
    <div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Carousel
                // centerMode={true}
                // dynamicHeight='60%'
                showThumbs={false}
            >
                {sliderData?.map((item, index) => (
                <div className='form_img_carousel'>
                    {determineUrlType(item) === 'image' ? <img src={item} key={index} alt="" srcset="" className='form_img px-2' onClick={() => handleOpenModal(item)} /> :
                    <video className='form_img' onClick={() => handleOpenModal(item)}>
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>}

                </div>
            ))}
            </Carousel>
        </div>
        {/* <div className={sliderData?.length === 1 ? 'form_img_container_height' : 'form_img_container'}>
            {sliderData?.map((item, index) => (
                determineUrlType(item) === 'image' ? <img src={item} key={index} alt="" srcset="" className='form_img px-2' onClick={() => handleOpenModal(item)} /> :
                <video className='form_img' onClick={() => handleOpenModal(item)}>
                <source src={item} type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            ))}
        </div> */}
        <div className='form_words_body'>
            <div>
                <h5 className='form_header'>{data?.name}</h5>
                <p>{data?.description}</p>
            </div>
        </div>

        <div className='form_container'>
            <form className='form_container_sub' onSubmit={handleSubmit}>
                {displayForm?.schemaDefinition?.length > 0 && displayForm?.schemaDefinition?.map((item, index) => <DynamicForms {...item} index={index} handleChange={handleChange} handleChangeDate={handleChangeDate} handleChangeCheckbox={handleChangeCheckbox} />)}

                <div className='form_btn'>
                    <Button color='primary'>Back</Button>
                    <ButtonContainer type='submit' disabled={isLoading} backgroundColor={backgroundColor}>{isLoading ? 'Loading...' : 'Submit'}</ButtonContainer>
                </div>
            </form>
        </div>
        {viewMedia?.show && <ResizeableModalWithoutBtn
            className="ml-5"
            modalState={viewMedia?.show}
            // title={viewForm?.item?.formName}
            maxWidth="md"
            fullWidth={true}
            resetModalState={handleClose}
            backgroundColor={backgroundColor}
            backgroundSecondaryColor={backgroundColor}
        >
            {determineUrlType(viewMedia?.item) === 'image' ? <img src={viewMedia.item} alt="" srcset="" className='form_img_modal px-2' /> : 
            <video className='form_img_modal' controls>
            <source src={viewMedia?.item} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
            }
        </ResizeableModalWithoutBtn>}
    </div>
  )
}
