import React, { useState } from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
// import './Give.scss';
import {
    Grid,
    TextField,
} from '@material-ui/core';
import { Formik } from 'formik'
import * as yup from 'yup'
import Navigator from '../../Navigator';
import { getMaskedNumber } from '../../../utils';
import { CardUserDetails } from './CardUserDetails';
import { CardDetailsReview } from './CardDetailsReview';
import {setCheckDetails} from '../../../store/actions/userDashboardActions/give'
import { getUserDetails } from '../../../utils/authHelpers';

const initialValues = {
  checkingFirstName: '',
  checkingLastName: '',
  routingNumber: '',
  confirmRoutingNumber: '',
  saveChecking: true,
  accountNumber: '',
  confirmAccountNumber: '',
  memo: ''
}

export const CheckForm = ({closeModal, updatePageData}) => {
  const [contributionList, setContributionList] = useState(false);
  const [goBackStep, setGoBackStep] = useState(false);
  const [goNextStep, setGoNextStep] = useState(false);

  const dispatch = useDispatch();
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const user = getUserDetails();

  const validationSchema = yup.object().shape({
    routingNumber: yup.string()
      .required('Routing Number is required')
      .max(9, 'Maximum 9 digits'),
    confirmRoutingNumber: yup.string()
      .required('Confirm Routing Number is required')
      .when('routingNumber', {
        is: val => (val && val.length > 0 ? true : false),
          then: yup.string().oneOf(
            [yup.ref('routingNumber')],
              "Routing Number need to be the same")}),
    accountNumber: yup.string()
      .required('Account Number is required')
      .min(4, 'Minimum 4 digits')
      .max(20, 'Maximum 20 digits'),
    confirmAccountNumber: yup.string()
      .required('Confirm Account Number is required')
      .when('accountNumber', {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf(
          [yup.ref('accountNumber')],
          "Account Number need to be the same")
      }),
  });

  const goBack = () => {
    setGoBackStep(true)
  }

  const handleSubmit = (values) => {
    let saveChecking = '0';
    if(values.saveChecking) saveChecking = '1';
    else saveChecking = '0';
    let payload = {
      saveChecking,
      accountNumber: window.btoa(values?.accountNumber.trim()),
      routingNumber: values?.routingNumber,
      orgId: orgInfo.orgId,
      userId: user.userId,      
    }
    const maskedAcctNumber = getMaskedNumber(values?.accountNumber)
    payload.maskedAcctNumber = maskedAcctNumber;
    if(contributionList){
      payload.listOfContributions = [...payload.listOfContributions, ...contributionList]
    }
    dispatch(setCheckDetails(payload))
    setGoNextStep(true)
  }

  

  return (
    <>
      {goBackStep && <CardUserDetails checkForm={true} updatePageData={updatePageData} />}
      {goNextStep && <CardDetailsReview checkForm={true} updatePageData={updatePageData} />}
      {!goBackStep && !goNextStep && <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className="px-4" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="mt-5"
                      name="routingNumber"
                      label="Routing Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      maxLength={9}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.routingNumber || ''}
                      error={Boolean(
                        touched.routingNumber && errors.routingNumber
                      )}
                      helperText={touched.routingNumber && errors.routingNumber }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="mt-5"
                      name="confirmRoutingNumber"
                      label="Confirm Routing Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      maxLength={9}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmRoutingNumber || ''}
                      error={Boolean(
                        touched.confirmRoutingNumber && errors.confirmRoutingNumber
                      )}
                      helperText={touched.confirmRoutingNumber && errors.confirmRoutingNumber }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="accountNumber"
                      label="Account Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      maxLength={20}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accountNumber || ''}
                      error={Boolean(
                        touched.accountNumber && errors.accountNumber
                      )}
                      helperText={touched.accountNumber && errors.accountNumber }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="confirmAccountNumber"
                      label="Confirm Account Number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      maxLength={20}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmAccountNumber || ''}
                      error={Boolean(
                        touched.confirmAccountNumber && errors.confirmAccountNumber
                      )}
                      helperText={touched.confirmAccountNumber && errors.confirmAccountNumber }
                    />
                  </Grid> 
                  <Grid item xs={12} className="mb-0 pb-0">
                    <label htmlFor="saveCard">
                      <input className="mr-3 mb-3" name="saveChecking" id="saveChecking" type="checkbox" checked={values.saveChecking} onChange={handleChange} value={values.saveChecking || ''} ></input>
                      <span>Save this checking account information for future contributions</span>
                    </label>
                  </Grid>
                  <Grid item xs={12} className="py-0">
                    <Navigator goBack={goBack} goNext={handleSubmit} className="" type="submit" />
                  </Grid>    
                </Grid>
              </form>
            )}
          </Formik>}
    </>
  )
}