import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Home } from '../../../pages/LandingPage/Home'
import {Button} from 'react-bootstrap';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor'

export default function PaymentStatus() {
    const themeColor = useThemeColor()
    const history = useHistory()
    const {name, status} = useParams()

    const goNext = (name) => {
        switch (name) {
            case 'give':
                return history.push('/give')
            case 'qgive': 
                return history.push('/qgive')
        
            default:
                return history.push('/')
        }
    }
    const goBack = () => {
        return history.push('/')
    }
  return (
    <Home>
    <div className="login-form login-signin activeForm-wrapper px-3">
        <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={themeColor.btnStyle}>
              {status === 'failure' ? <i className="fas fa-info-circle"></i> : <i className="fa fa-thumbs-up"></i>}
            </span>
          </div>
          <h3 className="font-size-h3 mb-2">{status === 'failure' ? 'Oh Snap!!' : 'Thank You'}</h3>
        </div>
        {/* <hr /> */}
            <div className="text-center my-10 my-lg-10">
                {status === 'failure' ? (
                    <h6 className='text-danger'><strong>This transaction was not completed!</strong></h6>
                ) : (
                    <h6><strong>Transaction Successful!</strong></h6>
                )}
            </div>


              <div className="d-flex justify-content-between  navigator-wrapper mt-15 ">
              <Button onClick={goBack}  variant="secondary" className={`btn btn-lg py-3 `}>Go to Home</Button>
              <Button onClick={() => goNext(name)} variant="primary" className={`btn btn-lg py-3 `} style={themeColor.btnStyle}>Give Again</Button>
            </div>
        </div>
    </Home>
  )
}
