import React from "react";
import CelebrationType from "./CelebrationType";
import BirthdayCele from '../Icons/birthdayCele.jpg'
import Newborn from '../Icons/newborn.jpg'
import Wedding from '../Icons/wedding.jpg'
import Children from '../Icons/children.jpg'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { celebrationsListing } from "../../store/actions";
import { useHistory } from "react-router-dom";

export default function CelebrationMain() {
  const dispatch = useDispatch()
  const history = useHistory()
  const celebrationsListingData = useSelector((state) => state.celebrationsListing.responseData)
 
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(celebrationsListing({orgId: orgInfo.orgId}))
  }, [])

  const handleClick = (name) => {
    if(celebrationsListingData[name]?.list?.length > 0) {
      history.push(`/celebration/${name}`)
    }
  }
  
  return (
    <div className="container">
      <h4 style={{display: 'flex', justifyContent: 'center'}}>Celebration Wall</h4>
      <div className="row">
        {celebrationsListingData && Object.entries(celebrationsListingData).map((item) => (
            <div class="col-md-4 col-sm-12" key={item.name}>
              <CelebrationType celebrationsListingData={celebrationsListingData} name={item} handleClick={() => handleClick(item[0])} />
            </div>
        ))}
      </div>
    </div>
  );
}

const dummyData = [
    {
        name: 'Birthday Celebrations',
        img: BirthdayCele,
        route: 'birthdayCelebrations'
    },
    {
        name: 'Wedding Anniversary',
        img: Wedding,
        route: 'weddingCelebrations'
    },
    {
        name: "Children's Birthday",
        img: Children,
        route: 'childrenBirthdayCelebrations'
    },
    {
        name: "New Born Babies in 2022 Celebrations",
        img: Newborn,
        route: 'newBabiesCelebrations'
    },
]