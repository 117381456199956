import React from 'react'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { withStyles } from "@material-ui/core/styles";
import {FormControlLabel, Paper, Switch} from '@material-ui/core'
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {useWindowDimensions} from '../../../utils/customHooks/useWindowDimensions'
import Forms from '../Emergency/Forms'
import {updateUser2FA, getUserProfile} from '../../../store/actions'



export default function TwoFA() {
    const userData = useSelector((state) => state.getUserProfileData.responseData)
    const [isSetTwoFA, setTwoFA] = React.useState(false)
    const [openInput, setOpenInput] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isUpdated, setIsUpdated] = React.useState('')
    const user2FAUpdate = useSelector((state) => state.updateUser2FA)
    const [showPassword, setShowPassword] = React.useState(false)
    const user = JSON.parse(localStorage.getItem('user'))
    const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
    const dispatch = useDispatch();
    const PurpleSwitch = withStyles({
        switchBase: {
          // color: props => props.primaryColor,
          '&$checked': {
            color: props => props.primaryColor,
          },
          '&$checked + $track': {
            backgroundColor: props => props.primaryColor,
          },
        },
        checked: {},
        track: {},
      })(Switch);

      React.useEffect(() => {
          setIsUpdated(userData?.twoFAStatus)
      }, [userData])


      const reload = () => {
        setOpenInput(false)
        dispatch(getUserProfile({'orgId': orgInfo.orgId, 'userId': userData?.userId}))
      };
    
      const themeColor = useThemeColor();
      let {width} = useWindowDimensions()
      const handleToggle = () => {
        setOpenInput(true)
        setTwoFA(!isSetTwoFA);
      }

      const formDetails = [
        {
            label: "Password",
            name: "currentPassword",
            type: showPassword ? 'text': "password",
            errorMsg: "Password is Required",
            // pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/
          },
      ]
      const handleFormData = (data) => {
          // console.log(data, 'passowrd');
          const payload = {
              'orgId': orgInfo.orgId,
              'userId': user.responseData.userId,
              'password': data.currentPassword,
              'twoFAStatus': isUpdated === 'true' ?  'false' :  'true'
          }
          return dispatch(updateUser2FA(payload, reload))
      }
      const hanldeShowPassword = () => {
        setShowPassword(!showPassword)
      }
    return (
        <Paper className="w-100 page-wrapper my-8 p-6">
        {userData && <div className="col-md-10 m-auto col-lg-6">
            <h6 className='py-3'>Two-Factor Authentication</h6>
            <div className="d-flex my-2">
              <p >Status:</p>
              <p className='ml-5' style={{fontSize: '13px'}}><strong>{isUpdated === 'true' ?  'Enabled' :  'Disabled'}</strong></p>
            </div>
            <FormControlLabel
              className="font-size-sm"
              control={<PurpleSwitch  size="small" primaryColor={themeColor.color} checked={isSetTwoFA} onChange={handleToggle} />}
              label={`${isUpdated === 'true' ? 'Click to Disable Two-Factor Authentication' : 'Click to Enable Two-Factor Authentication'}`}
            />
            {openInput && (
                <Forms
                formDetails={formDetails}
                buttonName={isUpdated === 'true' ?  'Disable' :  'Enable'}
                handleFormData={handleFormData}
                loading={user2FAUpdate.loading}
                hanldeShowPassword={hanldeShowPassword}
                showPasswordIcon={true}
                showPassword={showPassword}
                />
            )}
        </div>}
        </Paper>
    )
}
