import React from 'react';
// import parsePhoneNumber from 'libphonenumber-js'
import {useSelector, shallowEqual} from 'react-redux';
import moment from 'moment';
import Link from '@material-ui/core/Link'
import { FooterIcon } from './FooterIcon';
import { useThemeColor } from '../../utils/customHooks/useThemeColor'
import './Footer.css';

const Footer = () => {
  const themeColor = useThemeColor();
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual)

  return (
  <div className="mb-10">
    <div className="mx-auto text-center footer">
      <p className="font-weight-bolder font-size-h4" style={{color: themeColor.textColor}}>Contact Us</p>
      <div className="d-flex flex-column flex-md-row justify-content-md-center">
        <div className="col-xs-12 mobile-font-size">
          <p><span className="las la-phone mr-1"></span>
            <Link href={`tel:+${orgInfo.phone}`} style={{color: themeColor.textColor}}>
              {orgInfo ? orgInfo.phone : ''}
            </Link>
          </p>
        </div>
        <div className="col-xs-12 mobile-font-size">
          <p style={{color: themeColor.textColor}}><span  className="lar la-envelope mr-1"></span>
            <Link href={`mailto:${orgInfo ? orgInfo.email : ''}`}>
              {orgInfo ? orgInfo.email : ''}
            </Link>
          </p>
        </div>
        <div className="col-xs-12 mobile-font-size">
          <p style={{color: themeColor.textColor}}><span  className="las la-globe mr-2"></span>
            <Link href={`http://${orgInfo ? orgInfo.website : ''}`} target="_blank" rel="noopener noreferrer" style={{color: themeColor.textColor}}>
              {orgInfo ? orgInfo.website : ''}
            </Link>
        </p>
        </div>
      </div>
      <p className="maps mobile-font-size" style={{color: themeColor.textColor}}>
        <Link target="_blank" rel="noopener noreferrer" href={`http://maps.google.com/?q=${orgInfo ? `${orgInfo.address.address1} ${orgInfo.address.address2} ${orgInfo.address.city} ${orgInfo.address.state} ${orgInfo.address.zipOrPostalCode}` : ''}`}>
         <span  className="las la-map-marker mr-1"></span>{orgInfo ? `${orgInfo.address.address1} ${orgInfo.address.address2} ${orgInfo.address.city} ${orgInfo.address.state} ${orgInfo.address.zipOrPostalCode}` : ''}
        </Link>
      </p>
    </div>
    <hr />
    <div className="mx-auto text-center footer">
    <p className="contact" style={{color: themeColor.textColor}}>© Copyright {moment().format('YYYY')}. ChurchPad All Rights Reserved</p>
      <div className="d-flex justify-content-center contact">
        <Link href={orgInfo ? orgInfo.privacyPolicy : ''} className="mr-5">Privacy Policy</Link>
        <Link href={orgInfo ? orgInfo.termsOfService : ''} className="ml-3">Terms of Service</Link>
      </div>
      <div className="d-flex justify-content-center social">
        <FooterIcon 
          socialMediaLink={orgInfo ? orgInfo.socialMedia.facebook : ''}
          socialMedia='facebook'
          socialMediaStyle='lab la-facebook-f'
        />
        <FooterIcon 
          socialMediaLink={orgInfo ? orgInfo.socialMedia.twitter : ''}
          socialMedia='twitter'
          socialMediaStyle='lab la-twitter'
        />
        <FooterIcon 
          socialMediaLink={orgInfo ? orgInfo.socialMedia.instagram : ''}
          socialMedia='instagram'
          socialMediaStyle='lab la-instagram'
        />
        <FooterIcon 
          socialMediaLink={orgInfo ? orgInfo.socialMedia.youtube : ''}
          socialMedia='youtube'
          socialMediaStyle='lab la-youtube'
        />
      </div>
    </div>
  </div>
)};

export default Footer;