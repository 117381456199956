import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TextField,
  Avatar,
  Icon,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ConfirmationDialog from "../../Common/ConfirmDialog";
import {
  addRemoveUserAdminAccess,
  approveUser,
} from "../../../store/actions/userDashboardActions/serviceUnit";
// import { addRemoveUserAdminAccess } from 'redux/actions/People';
// import AlertDialog from 'components/Dialog/SimpleAlerts';
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import FormsModal from "./FormsModal";
import './serviceUnit.css'
import { useWindowDimensions } from "../../../utils/customHooks/useWindowDimensions";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";

export default function ViewGroupUsers({ status, getData, handleDelete }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useThemeColor()
  const groupUsersData = useSelector((state) => state.groupUsers.responseData);
  const orgInfo = useSelector(
    (state) => state?.orgInfo.responseData,
    shallowEqual
  );

  const approveUserState = useSelector(
    (state) => state?.approveUser,
    shallowEqual
  );
  let {width} = useWindowDimensions()
  // console.log(groupUsersData, 'groupUsersData');

  const deleteUsersFromContactGroupData = useSelector(
    (state) => state.deleteUsersFromDepartmentGroup
  );

  const [isOpenModal, setIsOpenModal] = useState({
    show: false,
    item: {},
  });
  const [reasonInput, setReasonInput] = useState("");
  const [isAcceptOrReject, setIsAcceptOrDeny] = useState({
    isAccept: false,
    acceptItem: {},
    rejectItem: {},
    isReject: false,
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const handleAdminAccess = (item) => {
    const action = item.admin ? "remove" : "add";
    const payload = {
      orgId: orgInfo.orgId,
      adminUserId: user?.responseData?.userId,
      userId: item?.userId,
      id: item?.groupId,
      action,
    };
    dispatch(addRemoveUserAdminAccess(payload, getData));
  };

  const closeDeleteModal = () => {
    setIsOpenModal({ ...isOpenModal, show: false, item: {} });
  };
  const handleDeleteCallback = () => {
    closeDeleteModal();
    getData();
  };

  const handleDeleteEdit = (item) => {
    setIsOpenModal({ ...isOpenModal, show: true, item });
  };

  const handleDeleteUsers = () => {
    handleDelete(isOpenModal?.item, handleDeleteCallback);
  };
  const handleRejectOrAccept = (row, name) => {
    // console.log();
    if (name === "reject") {
      setIsAcceptOrDeny({
        ...isAcceptOrReject,
        isReject: true,
        rejectItem: row,
        acceptItem: {},
        isAccept: true,
      });
    }
    if (name === "accept") {
      setIsAcceptOrDeny({
        ...isAcceptOrReject,
        isAccept: true,
        acceptItem: row,
        rejectItem: {},
        isReject: false,
      });
    }
  };

  const handleClose = () => {
    const payload = {
      isAccept: false,
      acceptItem: {},
      rejectItem: {},
      isReject: false,
    };
    setIsAcceptOrDeny(payload);
    setReasonInput("");
  };

  const handleApproveCallback = () => {
    handleClose();
    getData();
  };

  const handleApprove = () => {
    console.log(isAcceptOrReject?.acceptItem, "isAcceptOrReject?.acceptItem?");
    const { groupId, legacyUserId, role } = isAcceptOrReject?.acceptItem;
    dispatch(
      approveUser(
        legacyUserId,
        groupId,
        { role, status: "APPROVED" },
        handleApproveCallback
      )
    );
  };
  const handleChange = ({ target: { value } }) => {
    setReasonInput(value);
  };
  const handleReject = () => {
    console.log(reasonInput);
    const { groupId, legacyUserId, role } = isAcceptOrReject?.rejectItem;
    dispatch(
      approveUser(
        legacyUserId,
        groupId,
        { role, status: "REJECTED", reason: reasonInput },
        handleApproveCallback
      )
    );
  };
  return (
    <div className="view-group-users">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {width >= 769 && <TableCell style={{ width: "4rem" }}></TableCell>}
              {/* <TableCell style={{ width: '5rem' }}>Picture</TableCell> */}
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell style={{ width: "17rem" }}>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Role</TableCell>
              {status === "REJECTED" && <TableCell>Reason</TableCell>}
              <TableCell style={{ width: "4rem" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupUsersData?.userList?.map((item, id) => (
              <TableRow key={id}>
                {width >= 769 && <TableCell
                  size="small"
                  //   className={classes.sizeSmall}
                  padding="checkbox"
                >
                  {id + 1}
                </TableCell>}
                {/* <TableCell>
                  <Avatar className="w-30 h-30" src={item.profilePicture} />
                </TableCell> */}
                <TableCell>
                  <span
                    className="d-flex align-items-center w-100"
                    style={{ columnGap: "0.5rem" }}
                  >
                    {item.firstName}
                    {item.admin && (
                      <Tooltip title="Admin" placement="top">
                        <Icon
                          fontSize="small"
                          style={{ fontWeight: "bolder", fontSize: "1rem", color: theme.color }}
                          // className="pl-2"
                          // color="secondary"
                        >
                          verified
                        </Icon>
                      </Tooltip>
                    )}
                  </span>
                </TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.telephoneNumber}</TableCell>
                <TableCell>{item.departmentRole}</TableCell>
                {status === "REJECTED" && <TableCell>{item.reason}</TableCell>}
                <TableCell>
                  <div className="d-flex justify-content-center">
                    {/* <span> */}
                    {status === "APPROVED" && (
                      <Tooltip
                        title="Add | Remove - Admin Access"
                        placement="top"
                      >
                        <Checkbox
                          className={classes.icon}
                          checked={item.admin}
                          style={{color: theme.color}}
                          onChange={() => handleAdminAccess(item)}
                        />
                      </Tooltip>
                    )}
                    {status === "PENDING" && (
                      <Tooltip title="Accept" placement="top">
                        <ThumbUpIcon
                          className={`${classes.iconColor} cursor mt-2 pr-2`}
                          style={{ fontSize: 20, color: theme.color }}
                          onClick={() => handleRejectOrAccept(item, "accept")}
                        />
                      </Tooltip>
                    )}
                    {status === "PENDING" && (
                      <Tooltip title="Reject" placement="top">
                        <ThumbDownIcon
                          className={`${classes.iconColor} cursor mt-2 pr-2`}
                          style={{ fontSize: 20, color: theme.color }}
                          onClick={() => handleRejectOrAccept(item, "reject")}
                        />
                      </Tooltip>
                    )}
                    {/* </span> */}
                    {/* <span> */}{" "}
                    {status !== "PENDING" && (
                      <Tooltip title="Delete" placement="top">
                        <DeleteIcon
                          onClick={() => handleDeleteEdit(item)}
                          className={`${classes.iconColor} cursor mt-2`}
                          style={{ fontSize: 20, color: theme.color }}
                        />
                      </Tooltip>
                    )}
                    {/* </span> */}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        title={`Delete ${isOpenModal?.item?.firstName}  ${isOpenModal?.item?.lastName}`}
        open={isOpenModal?.show}
        onConfirmDialogClose={closeDeleteModal}
        onYesClick={handleDeleteUsers}
        loading={deleteUsersFromContactGroupData?.loading}
      />
      <ConfirmationDialog
        title={`${
          Object.values(isAcceptOrReject?.acceptItem).length > 0
            ? `Accept the user to ${isAcceptOrReject?.acceptItem?.groupName}`
            : `Reject the user request to join ${isAcceptOrReject?.rejectItem?.groupName}`
        }?`}
        open={isAcceptOrReject?.isAccept}
        onConfirmDialogClose={handleClose}
        onYesClick={handleApprove}
        isLoading={approveUserState?.loading}
      />

      <FormsModal
        open={isAcceptOrReject?.isReject}
        onClose={handleClose}
        title={`Reason for Rejecting`}
        submitText="Submit"
        handleFormSubmit={handleReject}
        loading={approveUserState?.loading}
      >
        <TextField
          className="w-100 mb-5 mt-3"
          label="Reason for Rejection"
          name="reason"
          variant="outlined"
          multiline
          required
          fullWidth
          minRows={4}
          value={reasonInput}
          onChange={handleChange}
        />
      </FormsModal>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  sizeSmall: {
    width: "20px",
  },
  // iconColor: {
  //   // color: '#626262',
  // },
  icon: {
    fontSize: "1.2rem",
  },
}));
