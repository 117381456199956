import { actionTypes } from '../../actionTypes';

export const getOrganizationForms = (state = {}, action) => {
    switch(action.type) {
      case actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS:
      case actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true
        }
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false
        }
      default:
        return state;
    }
}
export const getFormQuestionAndAnswer = (state = {}, action) => {
    switch(action.type) {
      case actionTypes.DYNAMIC_FORMS_ANSWER_AND_QUESTION_SUCCESS:
      case actionTypes.DYNAMIC_FORMS_ANSWER_AND_QUESTION_SUCCESS:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true
        }
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false
        }
      default:
        return state;
    }
}

export const getFormSchema = (state = {}, action) => {
    switch(action.type) {
      case actionTypes.DYNAMIC_FORMS_GET_SCHEMA_SUCCESS:
      case actionTypes.DYNAMIC_FORMS_GET_SCHEMA_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true
        }
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false
        }
      default:
        return state;
    }
}