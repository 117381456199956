import React from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import "./user.scss";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { ResizeableModalWithoutBtn } from "../../Common/ResizeableModal";
import PhoneInputComponent from "../../Common/PhoneInputComponent";
import { ButtonContainer } from "../../Common/Button";
import { addPhoneNumber, deletePhoneNumber, getUserProfile } from "../../../store/actions";
import ConfirmationDialog from "../../Common/ConfirmDialog";
import {PageLoader} from '../../Loader/Loader'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));
const UserInfo = () => {
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const themeColor = useThemeColor();
  const backgroundColor = themeColor.color;
  const addPhoneNumberLoading = useSelector((state) => state.addPhoneNumber)
  const deletePhoneNumberLoading = useSelector((state) => state.deletePhoneNumber)
  const UserInfo = [
    {
      title: "Phone",
      value: userProfileData?.userPhoneNumbersList,
      isbtn: true,
    },
    {
      title: "Country",
      value: userProfileData?.userCountry,
    },
    {
      title: "State/Region",
      value: userProfileData?.userStateOrProvince,
    },
    {
      title: "Address 1",
      value: userProfileData?.userAddress1,
    },
    {
      title: "Address 2",
      value: userProfileData?.userAddress2,
    },
    {
      title: "City/Town",
      value: userProfileData?.userCityOrTown,
    },
    {
      title: "Postal Code",
      value: userProfileData?.userZipOrPostalCode,
    },
    {
      title: "Date Of Birth",
      value:
        !moment(new Date(userProfileData?.userBirthDay)).isValid() ? '' :
        userProfileData?.userBirthDay.slice(0, 4) === "0000"
          ? moment(userProfileData?.userBirthDay).format("MMM D")
          : moment(userProfileData?.userBirthDay).format("MMM D, YYYY"),
    },
    {
      title: "Martial Status",
      value: userProfileData?.userMaritalStatus,
    },
    {
      title: "Wedding Date",
      value:
      !moment(new Date(userProfileData?.userWeddingDate)).isValid() ? '' :
      userProfileData?.userWeddingDate.slice(0, 4) === "0000"
      ? moment(userProfileData?.userWeddingDate).format("MMM D") :
        userProfileData?.userWeddingDate != "0000-00-00" &&
        moment(userProfileData?.userWeddingDate).format("MMM D, YYYY"),
    },
    // {
    //   title: 'Number of Guests',
    //   value: userProfileData?.userGuestsInfo?.length
    // }
  ];
  console.log(userProfileData?.userBirthDay.slice(0, 4), 'userProfileData?.userBirthDay.slice(4)');
  const dispatch = useDispatch()
  const classes = useStyles();
  const [isAddPhone, setAddPhone] = React.useState({
    show: false,
    item: {},
  });
  const [isDelete, setIsDelete] = React.useState({
    show: false,
    item: ''
  })
  let mobile;
  const handlePhone = (data) => {
    mobile = data
    // setAddPhone({ ...isAddPhone, item: data });
  };
  const handleCloseModal = () => {
    setAddPhone({ ...isAddPhone, item: {}, show: false });
  };
  const handleCallback = () => {
    dispatch( getUserProfile({
      orgId: orgInfo?.orgId,
      userId: userProfileData?.userId,
    }))
    handleCloseModal()
  }
  const handleAddPhoneNumber = () => {
    dispatch(
      addPhoneNumber(
        {
          orgId: orgInfo?.orgId,
          userId: userProfileData?.userId,
          phone: mobile,
        },
        handleCallback
      )
    );
  };

  const handleDeleteModal = (text) => {
    setIsDelete({...isDelete, show: true, item: text})
  }

  const closeDeleteModal = () => {
    setIsDelete({...isDelete, show: false, item: ''})
  }
  const handleCallbackDelete = () => {
    dispatch( getUserProfile({
      orgId: orgInfo?.orgId,
      userId: userProfileData?.userId,
    }))
    closeDeleteModal()
  }
  const handleDeletePhone = () => {
    dispatch(deletePhoneNumber({
      orgId: orgInfo?.orgId,
          userId: userProfileData?.userId,
          phone: isDelete.item,
    }, handleCallbackDelete))
  }
  let history = useHistory();
  const handleUpdate = (route) => {
    history.push(route);
  };
  return (
    <Card className="pt-2" elevation={3}>
      <div className="d-flex">
        <h5 className="p-4 m-0">Personal Information</h5>
        <Tooltip title="Edit" aria-label="Edit">
          <span
            className="fa fa-pen icon-sm text-muted m-0 py-4 pt-5"
            role="button"
            onClick={() => handleUpdate("/my_info")}
          ></span>
        </Tooltip>
      </div>
      <div className="d-flex justify-content-center">
        <div className="mb-6 text-center">
          <div className="d-flex justify-content-center">
            <Avatar
              alt="Remy Sharp"
              src={userProfileData?.userProfilePicOriginal}
              className={classes.large}
            />
          </div>
          <h5 className="mt-4 mb-2">
            {userProfileData?.userFirstName} {userProfileData?.userMiddleName}{" "}
            {userProfileData?.userLastName} {userProfileData?.userSuffix}
          </h5>
          <small className="text-muted">
            {userProfileData?.userSalutation}
          </small>
        </div>
      </div>

      <Divider />
      <Table className="mb-4">
        <TableBody>
          <TableRow>
            <TableCell className="pl-4">Email</TableCell>
            <TableCell>
              <div>{userProfileData?.userEmail}</div>
            </TableCell>
          </TableRow>
          {UserInfo.map((item, ind) => (
            <TableRow key={ind}>
              <TableCell className="pl-4">
                {item.title}{" "}
                {item.isbtn && (
                  <p
                    className="pt-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setAddPhone({ ...isAddPhone, show: true })}
                  >
                    <i className="fas fa-plus pr-2 icon-sm"></i>Add Phone Number
                  </p>
                )}
              </TableCell>
              <TableCell>
                {typeof item.value === "object"
                  ? item.value.map((opt, index) => (
                    <div>
                      <p key={index} style={{display: 'block'}}>
                        {opt} <i className="fas fa-trash pl-2 user_delete_icon icon-sm" onClick={() => handleDeleteModal(opt)}></i>
                      </p>
                    </div>
                    ))
                  : item.value}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell className="pl-4">Number of Children</TableCell>
            <TableCell>
              <div className="d-flex justify-content-between">
                <p className="pt-2">
                  {userProfileData?.userChildrenInfo?.length}
                </p>
                <Link to="/family">
                  <button
                    className="btn w-100 font-weight-bold"
                    style={{ color: themeColor.iconColor }}
                  >
                    {userProfileData?.userChildrenInfo?.length > 0
                      ? "Manage"
                      : "Add Child"}
                  </button>
                </Link>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className="d-flex justify-content-center pb-3">
        <Link to="/my_info">
          <button
            style={themeColor.btnStyle}
            className="btn w-100 font-weight-bold text-white"
          >
            Edit Profile
          </button>
        </Link>
      </div>
      <ResizeableModalWithoutBtn
        modalState={isAddPhone.show}
        resetModalState={handleCloseModal}
        title="Add Phone Number"
        maxWidth="xs"
        fullWidth={true}
      >
        <PhoneInputComponent handlePhone={handlePhone} />
        {addPhoneNumberLoading?.loading ? (<CircularProgress />) :  <div style={{display: 'flex', justifyContent: "end"}} className='pt-2'>
          <ButtonContainer onClick={handleAddPhoneNumber} 
           backgroundColor={backgroundColor} 
          >
            Submit
          </ButtonContainer>
        </div>}
      </ResizeableModalWithoutBtn>
      <ConfirmationDialog
        open={isDelete.show}
        onConfirmDialogClose={closeDeleteModal}
        text={`Are you sure you want to delete ${isDelete?.item}?`}
        onYesClick={handleDeletePhone}
        isLoading={deletePhoneNumberLoading?.loading}
      />
    </Card>
  );
};

export default UserInfo;
