import { actionTypes } from '../../actionTypes';

export const setOrUnsetSpouse = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.SET_OR_UNSET_SPOUSE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export const searchForSpouse = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.SEARCH_FOR_SPOUSE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export const getUserFamilyDataMportal = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.GET_USER_FAMILY_SUCCESS:
    case actionTypes.GET_USER_FAMILY_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}