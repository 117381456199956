import React from "react";
import { DonateAmount } from "../../components/Forms/GiveForm/DonateAmount";
import { GiveOption } from "../../components/Forms/GiveForm/GiveOption";
import RecurringGivingPlan from "../../components/Forms/GiveForm/RecurringGivingPlan";
import "./style.css";
import { Button } from "react-bootstrap";
import { MenuItem, TextField } from "@material-ui/core";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { InlineErrorMsg } from "../../components/Common/InlineErrorMsg";

const StepOne = ({
  handleChangeInColor,
  handleClick,
  active,
  activeRec,
  giveError,
  getFrequencyValue,
  getSelectValue,
  getStartDate,
  selectedFreqValue,
  handleClickRec,
  bgColor,
  goNext,
  selectedValue,
  date,
  handleContributionChange,
  quickGiveProcessingEngines,
  selectedPayment,
  handleBlur,
  memo,
  handleMemoChange,
  mobile,
  setMobile,
  mobileRequired,
  loading
}) => {
  const ipData = useSelector((state) => state.ipData);
  return (
    <>
      <section className="p-5 flex-grow-1">
        {quickGiveProcessingEngines?.length > 1 && (
          <div>
            <TextField
              label={
                selectedPayment.type
                  ? "Contribution Option"
                  : "Contribution Options - Please select one"
              }
              select
              name="paymentOption"
              variant="outlined"
              fullWidth
              onChange={handleContributionChange}
              // error={enableGive.disable}
              value={selectedPayment.type || ""}
              className="select-option text-white mb-5"
              disabled={selectedPayment?.disabled}
            >
              {quickGiveProcessingEngines?.map((item, index) => (
                <MenuItem key={index} value={item.type}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        <div className="donate" style={{ background: "" }}>
          <DonateAmount
            handleBlur={handleBlur}
            defaultWrap="pt-0"
            textColor={bgColor}
          />
        </div>

        {selectedPayment?.type && (
          <div className="">
            <div>
              <span className="mt-5 mb-2 ">Give Option</span>
              <div
                className="btn-group w-100 buttons btn-group-lg form-control-color text-center border border-1 d-flex align-items-center justify-content-center"
                role="group"
                aria-label=""
              >
                <div
                  className={`p-4 w-100 fix-mobile-line-height`}
                  onClick={handleClick}
                  style={handleChangeInColor(active)}
                >
                  <i className="las la-donate"></i> Give Once
                </div>
                <div
                  className={`p-4 w-100`}
                  onClick={handleClickRec}
                  style={handleChangeInColor(activeRec)}
                >
                  {" "}
                  <i className="las la-sync"></i> Recurring Giving
                </div>
              </div>
            </div>

            {active ? (
              <GiveOption
                getSelectValue={getSelectValue}
                giveError={giveError}
                selectedValue={selectedValue}
              />
            ) : (
              <RecurringGivingPlan
                getFrequencyValue={getFrequencyValue}
                getSelectValue={getSelectValue}
                giveError={giveError}
                getStartDate={getStartDate}
                selectedFreqValue={selectedFreqValue}
                selectedValue={selectedValue}
                date={date}
              />
            )}

            {mobileRequired() && (
              <div className="fv-plugins-icon-container mt-10">
                <p style={{ marginBottom: "0.2rem" }}>Phone Number</p>
                <PhoneInput
                  international
                  withCountryCallingCode
                  countryCallingCodeEditable={false}
                  defaultCountry={ipData ? ipData.country_code : null}
                  placeholder="Mobile"
                  value={mobile}
                  onChange={setMobile}
                  className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${
                    mobile && !isValidPhoneNumber(mobile)
                      ? "is-invalid"
                      : mobile && isValidPhoneNumber(mobile)
                      ? "is-valid"
                      : ""
                  }`}
                  name="mobile"
                  error={
                    mobile
                      ? isValidPhoneNumber(mobile)
                        ? undefined
                        : "Invalid mobile number"
                      : "Mobile number is required"
                  }
                />
                {mobileRequired() && !mobile && (
                  <InlineErrorMsg msg="mobile number is required" />
                )}
              </div>
            )}

            <TextField
              label="Memo"
              type="text"
              name="memo"
              maxLength={100}
              fullWidth
              minRows={3}
              multiline
              variant="outlined"
              inputProps={{ maxLength: 100 }}
              helperText={`${memo?.length || 0} / 100`}
              style={{ fontSize: "16px" }}
              className="mt-10 memo"
              value={memo}
              onChange={handleMemoChange}
            />

            <Button
              onClick={goNext}
              variant="primary"
              className={`btn btn-lg py-3 mt-20 w-100 p-7`}
              style={{ background: bgColor }}
              disabled={loading}
            >
              Next
            </Button>
          </div>
        )}
      </section>
    </>
  );
};

export default StepOne;
