import {useEffect, useState} from 'react';
import {  useSelector, shallowEqual } from 'react-redux';


export const useContributionProcessEngine = () => {
  const selectedPayment = useSelector((state) => state.selectedPaymentOption);
  const contributionProcessingEnginesData = useSelector(state => state.contributionProcessingEnginesData.responseData, shallowEqual);
  const [showPaymentOptionPage, setShowPaymentOptionPage] = useState({});

  useEffect(() => {
    if (contributionProcessingEnginesData) {
      const availablePaymentGateway = contributionProcessingEnginesData?.returnedRecords.filter((item) => item !== null);
      if(availablePaymentGateway?.length > 1) setShowPaymentOptionPage({hasMultiple: true, type: selectedPayment?.type, paymentList: availablePaymentGateway});
      else setShowPaymentOptionPage({hasMultiple: false, type: availablePaymentGateway[0]?.type, paymentList: availablePaymentGateway})
    }
  }, [contributionProcessingEnginesData])

  return showPaymentOptionPage;
}