import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ResizeableModalWithoutBtn } from '../ResizeableModal';
import {ButtonContainer} from '../Button'
import '../Alert.scss'

export const ModalComponent = ({ children, show, onHide, title, header , size="lg", backgroundColor, onSubmit, buttonName='Submit'}) => {
  return (
    <div className='second-modal'>
      {/* <ResizeableModalWithoutBtn
        modalState={show}
        resetModalState={onHide}
        title={title}
        maxWidth={size}
        fullWidth={true}
      >
        {children}
      </ResizeableModalWithoutBtn> */}
      <Modal
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onHide}
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title ?? ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>{header ?? ''}</h4>
            <p>
            {children}
            </p>
            </Modal.Body>
        </Modal>
            {/* <Modal.Footer className='second-modal'> */}
              {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <ButtonContainer backgroundColor={backgroundColor} type='submit'>{buttonName}</ButtonContainer>
              <ButtonContainer onClick={onHide} backgroundColor={backgroundColor}>Close</ButtonContainer>
              </div> */}
            {/* </Modal.Footer> */}
    </div>
  )
}