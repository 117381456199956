import {
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    TableContainer,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    ImageListItemBar,
    ImageList,
    ImageListItem,
    ListSubheader,
    IconButton,
    makeStyles,
    TableHead,
    Icon
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector, shallowEqual } from "react-redux";
//   import CustomButton from "components/Button/index";
  import { format } from "date-fns";
  import { useHistory } from "react-router-dom";
  import DeleteIcon from "@material-ui/icons/Delete";
import { childrenModuleSpecificInfo, mediaVaultListing, sendCommunicationChildren, sendCommunicationToSpecialGroupAnalyze, getOrgMessage } from "../../../store/actions/userDashboardActions/serviceUnit";
import AutocompleteComponent from "./AutocompleteComponent";
import InputWithDate from "../../Common/InputWithDate";
import { ResizeableModalWithoutBtn } from "../../Common/ResizeableModal";
import CustomButton from "../../Common/CustomButton";
  
  const senderType = ["sms", "mms", "voice"];
  export default function Communication({
    title,
    onClose,
    recipientType,
    selectedChild,
    setIsClose,
    getAnalyzeData,
    type,
    specialGroup,
  }) {
    // global state
    const childrenModuleSpecificInfoData = useSelector(
      (state) => state.childrenModuleSpecificInfo.responseData
    );
    const sendCommunicationChildrenData = useSelector(
      (state) => state.sendCommunicationChildren.responseData
    );
    const sendCommunicationChildrenLoading = useSelector(
      (state) => state.sendCommunicationChildren
    );
    const mediaVaultListiLoading = useSelector(
      (state) => state.mediaVaultListing
    );
    const mediaVaultListiData = useSelector(
      (state) => state.mediaVaultListing.responseData
    );
    const orgInfo = useSelector(
      (state) => state.orgInfo.responseData,
      shallowEqual
    );
    const userData = useSelector(
      (state) => state.getUserProfileData.responseData,
      shallowEqual
    );
    const messaging = useSelector(
      (state) => state?.orgMessage.responseData,
      shallowEqual
    );
    const orgInfoListData = useSelector(
      (state) => state.orgInfoList.responseData,
      shallowEqual
    );
  
    let dispatch = useDispatch();
    let history = useHistory();
    const user = JSON.parse(localStorage.getItem("user"));
  
    console.log("selectedChild", orgInfoListData);
  
    // localState
    const [communicationType, setCommunicationType] = useState("");
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [senderNumber, setSenderNumber] = useState(
      messaging?.approvedSmsMmsSenderIds[0]
    );
    const handleCommunicationType = (e) => setCommunicationType(e.target.value);
  
    const handleReloadSms = () => {
      onClose();
      setIsClose(true);
    };
    const handleSubmitData = (data) => {
      // setShowSuccessPage(true)
      // console.log(recipientType, "data");
      const sendDate =
        data?.value === "sendNow"
          ? "now"
          : format(data?.startDate, "yyyy-MM-dd hh:mm");
      const formData = new FormData();
      formData.append("orgId", orgInfo.orgId);
      data.senderNumber && formData.append("senderNumber", data.senderNumber);
      formData.append("adminUserId", user?.responseData?.userId);
      formData.append("recipientType", recipientType);
      formData.append("sendDate", sendDate);
      formData.append("communicationChannel", communicationType);
      // const recipient = [
      //   {mobile: selectedChild?.parent1Phone, email: selectedChild?.parent1Email, firstName: selectedChild?.parent1FirstName, lastName: selectedChild?.parent1LastName},
      //   {mobile: selectedChild?.parent2Phone, email: selectedChild?.parent2Email, firstName: selectedChild?.parent2FirstName, lastName: selectedChild?.parent2LastName},
      // ]
  
      const { salutation, userId, ...rest } =
        selectedChild?.parent1Information || {};
      const {
        salutation: parent2Salutation,
        userId: parent2Salution,
        ...others
      } = selectedChild?.parent2Information || {};
  
      const recipient = [{ ...rest }, { ...others }];
      formData.append(
        "personalizeMessage",
        data?.personalizeMessage === "standardMesssage" ? "false" : "true"
      );
      recipientType === "individual" &&
        formData.append("recipients", JSON.stringify(recipient));
      recipientType === "group" &&
        formData.append("recipients[]", [selectedChild?.name]);
      if (communicationType === "sms") {
        formData.append("textMessage", data?.textMessage);
        getAnalyzeData(formData);
        if (type === "special") {
          console.log(selectedChild, "select");
          formData.delete("recipients");
          formData.delete("recipientType");
          formData.delete("recipients[]");
          formData.append("specialGroup", specialGroup);
          formData.append("id", selectedChild?.id);
          return dispatch(
            sendCommunicationToSpecialGroupAnalyze(formData, handleReloadSms)
          );
        }
        dispatch(sendCommunicationChildren(formData, handleReloadSms));
      } else if (communicationType === "mms") {
        const mmid = data?.selectedImage?.map((item) => {
          return item.id;
        });
        formData.append("textMessage", data?.textMessage);
        formData.append("mmsImageIds[]", mmid);
        getAnalyzeData(formData);
        if (type === "special") {
          console.log(selectedChild, "select");
          formData.delete("recipients");
          formData.delete("recipientType");
          formData.delete("recipients[]");
          formData.append("specialGroup", specialGroup);
          formData.append("id", selectedChild?.id);
          return dispatch(
            sendCommunicationToSpecialGroupAnalyze(formData, handleReloadSms)
          );
        }
        dispatch(sendCommunicationChildren(formData, handleReloadSms));
      } else if (communicationType === "mms") {
        const mmid = data?.selectedImage?.map((item) => {
          return item.id;
        });
        formData.append("textMessage", data?.textMessage);
        formData.append("mmsImageIds[]", mmid);
        getAnalyzeData(formData);
        if (type === "special") {
          console.log(selectedChild, "select");
          formData.delete("recipients");
          formData.delete("recipientType");
          formData.delete("recipients[]");
          formData.append("specialGroup", specialGroup);
          formData.append("id", selectedChild?.id);
          return dispatch(
            sendCommunicationToSpecialGroupAnalyze(formData, handleReloadSms)
          );
        }
        dispatch(sendCommunicationChildren(formData, handleReloadSms));
      } else if (communicationType === "email") {
        formData.append("emailSenderEmail", data?.emailSenderEmail);
        formData.append("emailSubject", data?.emailSubject);
        formData.append("emailSenderName", data?.emailSenderName);
        formData.append("emailMessage", data?.emailMessage);
        getAnalyzeData(formData);
        if (type === "special") {
          console.log(selectedChild, "select");
          formData.delete("recipients");
          formData.delete("recipientType");
          formData.delete("recipients[]");
          formData.append("specialGroup", specialGroup);
          formData.append("id", selectedChild?.id);
          return dispatch(
            sendCommunicationToSpecialGroupAnalyze(formData, handleReloadSms)
          );
        }
        dispatch(sendCommunicationChildren(formData, handleReloadSms));
      } else if (communicationType === "voice") {
        formData.append("audioId", data?.selectedVoiceNote?.id);
        getAnalyzeData(formData);
        if (type === "special") {
          console.log(selectedChild, "select");
          formData.delete("recipients");
          formData.delete("recipientType");
          formData.delete("recipients[]");
          formData.append("specialGroup", specialGroup);
          formData.append("id", selectedChild?.id);
          return dispatch(
            sendCommunicationToSpecialGroupAnalyze(formData, handleReloadSms)
          );
        }
        dispatch(sendCommunicationChildren(formData, handleReloadSms));
      } else if (communicationType === "push") {
        formData.append("mmsImageIds[]", [data?.selectedImage?.id]);
        formData.append("pushSubject", data?.pushSubject);
        formData.append("textMessage", data?.textMessage);
        formData.append('targetAppModule', data?.targetAppModule)
        getAnalyzeData(formData);
        if (type === "special") {
          formData.delete("recipients");
          formData.delete("recipientType");
          formData.delete("recipients[]");
          formData.append("specialGroup", specialGroup);
          formData.append("id", selectedChild?.id);
          return dispatch(
            sendCommunicationToSpecialGroupAnalyze(formData, handleReloadSms)
          );
        }
        dispatch(sendCommunicationChildren(formData, handleReloadSms));
      }
    };
  
    useEffect(() => {
      if (communicationType === "mms" || communicationType === "push") {
        dispatch(
          mediaVaultListing({
            orgId: orgInfo.orgId,
            adminUserId: user?.responseData?.userId,
            type: "image",
          })
        );
      }
      if (communicationType === "voice") {
        dispatch(
          mediaVaultListing({
            orgId: orgInfo.orgId,
            adminUserId: user?.responseData?.userId,
            type: "audio",
          })
        );
      }
    }, [communicationType]);
  
    useEffect(() => {
      if (messaging?.approvedSmsMmsSenderIds?.length === 1) {
        setSenderNumber(messaging?.approvedSmsMmsSenderIds[0]);
      }
    }, [messaging?.approvedSmsMmsSenderIds]);
  
    useEffect(() => {
      if (!messaging) {
        dispatch(
          getOrgMessage({
            orgId: orgInfo.orgId,
            adminUserId: user?.responseData?.userId,
          })
        );
      }
      if (!childrenModuleSpecificInfoData) {
        dispatch(
          childrenModuleSpecificInfo({
            orgId: orgInfo.orgId,
            adminUserId: user?.responseData?.userId,
          })
        );
      }
    }, []);
  
    const handleSenderNumber = ({ target: { value } }) => {
      setSenderNumber(value);
    };
  
    // console.log(sendCommunicationChildrenData, 'data');
    const handleSelectionType = (type) => {
      if (type) {
      const isDisable = childrenModuleSpecificInfoData?.availableCommunicationOptions?.filter((item) => item?.name === type)
      if (isDisable[0]?.isEnabled === 'false') {
        return <p className="text-center pt-4" style={{fontWeight: 'bold'}}>{isDisable[0]?.messageToShow}</p>
      }
      }
      switch (type) {
        case "sms":
          return (
            <SMSCommunication
              onClose={onClose}
              handleSubmitData={handleSubmitData}
              recipientType={recipientType}
              loading={sendCommunicationChildrenLoading?.loading}
              orgMessageData={messaging}
              senderNumber={senderNumber}
              handleSenderNumber={handleSenderNumber}
            />
          );
        case "mms":
          return (
            <MMSCommunication
              onClose={onClose}
              handleSubmitData={handleSubmitData}
              autoCompleteData={childrenModuleSpecificInfoData?.foodAllergiesList}
              recipientType={recipientType}
              loading={sendCommunicationChildrenLoading?.loading}
              vaultLoading={mediaVaultListiLoading}
              vaultData={mediaVaultListiData}
              orgMessageData={messaging}
              senderNumber={senderNumber}
              handleSenderNumber={handleSenderNumber}
            />
          );
        case "email":
          return (
            <EmailCommunication
              onClose={onClose}
              handleSubmitData={handleSubmitData}
              recipientType={recipientType}
              loading={sendCommunicationChildrenLoading?.loading}
              user={userData}
              orgInfo={orgInfo}
              orgMessageData={messaging}
              senderNumber={senderNumber}
              handleSenderNumber={handleSenderNumber}
            />
          );
        case "voice":
          return (
            <VoiceNoteCommuncation
              onClose={onClose}
              handleSubmitData={handleSubmitData}
              recipientType={recipientType}
              loading={sendCommunicationChildrenLoading?.loading}
              vaultLoading={mediaVaultListiLoading}
              vaultData={mediaVaultListiData}
              orgMessageData={messaging}
              senderNumber={senderNumber}
              handleSenderNumber={handleSenderNumber}
            />
          );
        case "push":
          return (
            <PushNotification
              onClose={onClose}
              handleSubmitData={handleSubmitData}
              recipientType={recipientType}
              loading={sendCommunicationChildrenLoading?.loading}
              vaultLoading={mediaVaultListiLoading}
              vaultData={mediaVaultListiData}
              pushData={orgInfoListData}
            />
          );
        default:
          break;
      }
    };
    // console.log(data, 'here...........');
    return (
      <div className="px-3 py-3 mt-2">
        {/* <div>
                  <p>{title}</p>
              </div> */}
        {senderType.includes(communicationType) && (
          <div className="px-3 pb-4">
            {messaging?.approvedSmsMmsSenderIds?.length === 1 ? (
              <p>From: {messaging?.approvedSmsMmsSenderIds[0]}</p>
            ) : (
              <TextField
                name="approvedSmsMmsSenderIds"
                label="Sender Number"
                variant="outlined"
                select
                onChange={handleSenderNumber}
                fullWidth
                size="small"
              >
                {messaging?.approvedSmsMmsSenderIds?.map((item, id) => (
                  <MenuItem key={id} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </div>
        )}
        <div className="px-3">
          <TextField
            label="Communication Type"
            variant="outlined"
            required
            fullWidth
            onChange={handleCommunicationType}
            size="small"
            select
          >
            {childrenModuleSpecificInfoData?.availableCommunicationOptions.map(
              (item, i) => {
                return (
                  <MenuItem value={item.name} key={i}>
                    {item.title}
                  </MenuItem>
                );
              }
            )}
          </TextField>
          {handleSelectionType(communicationType)}
        </div>
        <ResizeableModalWithoutBtn
          modalState={showSuccessPage}
          title="Confirm Details"
          resetModalState={() => setShowSuccessPage(false)}
          maxWidth={"md"}
        >
          {/* sendCommunicationChildrenData */}
          {/* heherererer */}
          {/* <TableContainer component={Paper}> */}
          <div className="px-3">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Billable</TableCell>
                  <TableCell>
                    {sendCommunicationChildrenData?.isBilliable ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Message Credit Balance</TableCell>
                  <TableCell>
                    {sendCommunicationChildrenData?.messagingCreditBalance}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Message Credit Cost</TableCell>
                  <TableCell>
                    {sendCommunicationChildrenData?.messagingCreditCost}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Valid Recipients</TableCell>
                  <TableCell>
                    {sendCommunicationChildrenData?.validRecipientsCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Invalid Recipients</TableCell>
                  <TableCell>
                    {sendCommunicationChildrenData?.invalidRecipientsCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Duplicate Recipients</TableCell>
                  <TableCell>
                    {sendCommunicationChildrenData?.duplicateRecipientsCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Do Not Disturb Recipients</TableCell>
                  <TableCell>
                    {sendCommunicationChildrenData?.doNotDisturbRecipientsCount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {sendCommunicationChildrenData?.validRecipients?.length > 0 && (
              <div className="py-4">
                <p>
                  Valid Recipients (
                  {sendCommunicationChildrenData?.validRecipients?.length})
                </p>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sendCommunicationChildrenData?.validRecipients?.map(
                      (item, id) => (
                        <TableRow key={id}>
                          <TableCell>{item?.mobile}</TableCell>
                          <TableCell>{item?.email} </TableCell>
                          <TableCell>{item?.firstName}</TableCell>
                          <TableCell>{item?.lastName}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            )}
            {sendCommunicationChildrenData?.duplicateRecipients?.length > 0 && (
              <div className="py-3">
                <p>
                  Duplicate Recipients (
                  {sendCommunicationChildrenData?.duplicateRecipients?.length})
                </p>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sendCommunicationChildrenData?.duplicateRecipients?.map(
                      (item, id) => (
                        <TableRow key={id}>
                          <TableCell>{item?.mobile}</TableCell>
                          <TableCell>{item?.email}</TableCell>
                          <TableCell>{item?.firstName}</TableCell>
                          <TableCell>{item?.lastName}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            )}
            {sendCommunicationChildrenData?.invalidRecipients?.length > 0 && (
              <div className="py-3">
                <p>
                  Invalid Recipients (
                  {sendCommunicationChildrenData?.invalidRecipients?.length})
                </p>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sendCommunicationChildrenData?.invalidRecipients?.map(
                      (item, id) => (
                        <TableRow key={id}>
                          <TableCell>{item?.mobile}</TableCell>
                          <TableCell>{item?.email}</TableCell>
                          <TableCell>{item?.firstName}</TableCell>
                          <TableCell>{item?.lastName}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            )}
            {sendCommunicationChildrenData?.doNotDisturbRecipients?.length >
              0 && (
              <div className="py-3">
                <p>
                  Do Not Disturb Recipients (
                  {sendCommunicationChildrenData?.doNotDisturbRecipients?.length})
                </p>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sendCommunicationChildrenData?.doNotDisturbRecipients?.map(
                      (item, id) => (
                        <TableRow key={id}>
                          <TableCell>{item?.mobile}</TableCell>
                          <TableCell>{item?.email}</TableCell>
                          <TableCell>{item?.firstName}</TableCell>
                          <TableCell>{item?.lastName}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
          {/* </TableContainer> */}
          <div
            className="d-flex justify-content-between mt-3 pb-3"
            style={{ columnGap: "1rem" }}
          >
            <React.Fragment>
              <CustomButton
                label="Send"
                variant="contained"
                className="w-full"
                color="primary"
                type="submit"
                loading={sendCommunicationChildrenLoading?.loading}
                disabled={sendCommunicationChildrenLoading?.loading}
              />
              <CustomButton
                label="Cancel"
                variant="outlined"
                className="w-full"
                onClick={onClose}
                loading={sendCommunicationChildrenLoading?.loading}
                disabled={sendCommunicationChildrenLoading?.loading}
                // disabled={disabled}
              />
            </React.Fragment>
          </div>
        </ResizeableModalWithoutBtn>
      </div>
    );
  }
  
  const SMSCommunication = ({
    onClose,
    handleSubmitData,
    recipientType,
    loading,
    orgMessageData,
    senderNumber,
    handleSenderNumber,
  }) => {
    let today = new Date();
    today.setHours(today.getHours() + 2);
    const [startDate, setStartDate] = useState(today);
    const [data, setData] = useState({
      textMessage: "",
      personalizeMessage: "standardMesssage",
    });
    const [value, setValue] = React.useState("sendNow");
    const [textLength, setTextLength] = useState({
      totalLength: 0,
      pageLength: 1,
      currentLength: 0,
    });
  
    const handleChangeRadio = (event) => {
      setValue(event.target.value);
    };
  
    const handleChange = ({ target: { name, value } }) => {
      let temp = { ...data };
      temp[name] = value;
      setData(temp);
      if (name === "textMessage") {
        if (temp?.textMessage.length > 0 && temp?.textMessage.length < 160) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length,
            pageLength: 1,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 160 && temp?.textMessage.length < 320) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 160,
            pageLength: 2,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 320 && temp?.textMessage.length < 380) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 320,
            pageLength: 3,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 380 && temp?.textMessage.length < 440) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 320,
            pageLength: 3,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 440 && temp?.textMessage.length < 600) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 440,
            pageLength: 4,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 600 && temp?.textMessage.length < 760) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 600,
            pageLength: 5,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 760 && temp?.textMessage.length < 920) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 760,
            pageLength: 6,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 920 && temp?.textMessage.length < 1080) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 920,
            pageLength: 7,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 1080 && temp?.textMessage.length < 1240) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 1080,
            pageLength: 8,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 1240 && temp?.textMessage.length < 1400) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 1240,
            pageLength: 9,
            totalLength: temp?.textMessage.length,
          });
        }
        if (temp?.textMessage.length > 1400 && temp?.textMessage.length < 1560) {
          setTextLength({
            ...textLength,
            currentLength: temp?.textMessage.length - 1400,
            pageLength: 10,
            totalLength: temp?.textMessage.length,
          });
        }
      }
    };
    const handleCheck = ({ target: { name, value } }) => {
      let temp = { ...data };
      temp[name] = value;
      setData(temp);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const payload = {
        ...data,
        startDate,
        value,
        senderNumber,
      };
      handleSubmitData(payload);
    };
  
    // console.log(startDate, '..........');
    return (
      <>
        <form action="" onSubmit={handleSubmit}>
          {recipientType === "group" && (
            <div className="py-2">
              <RadioGroup
                aria-label="personalizeMessage"
                name="personalizeMessage"
                value={data?.personalizeMessage}
                onChange={handleCheck}
                row
              >
                <FormControlLabel
                  value="standardMesssage"
                  control={<Radio />}
                  label="Standard Message"
                />
                <FormControlLabel
                  value="personalizeMessage"
                  control={<Radio />}
                  label="Personalize Message"
                />
              </RadioGroup>
            </div>
          )}
          <div className="py-3 mt-3">
            <TextField
              id="sms-text-field"
              multiline
              rows={4}
              fullWidth
              value={data?.textMessage}
              variant="outlined"
              name="textMessage"
              onChange={handleChange}
              required
              label="message"
              error={textLength?.totalLength > 1560 ? true : false}
              inputProps={{
                maxlength: 1560,
                minlength: 2,
              }}
            />
            <span>{`${textLength?.currentLength} characters | ${textLength?.pageLength}-page SMS`}</span>
          </div>
          <div className="pt-2">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel
                value="sendNow"
                control={<Radio />}
                label="Send Now"
              />
              <FormControlLabel
                value="sendLater"
                control={<Radio />}
                label="Send Later"
              />
            </RadioGroup>
          </div>
          {value === 'sendLater' && <p className='mt-0 mb-2'> <span className="pr-2"><Icon style={{fontSize: '16px'}}>info</Icon></span>Your future message delivery must be set at least 2 hours ahead.</p>}
          {value === "sendLater" && (
            <div className="py-2">
              <InputWithDate
                date={today}
                label="Send Date"
                startDate={startDate}
                setStartDate={setStartDate}
              />
            </div>
          )}
          <div
            className="d-flex justify-content-between mt-3 pb-3"
            style={{ columnGap: "1rem" }}
          >
            <React.Fragment>
              <CustomButton
                label="Send"
                variant="contained"
                className="w-full"
                color="primary"
                type="submit"
                loading={loading}
              />
              <CustomButton
                label="Cancel"
                variant="outlined"
                className="w-full"
                onClick={onClose}
                loading={loading}
                // disabled={disabled}
              />
            </React.Fragment>
          </div>
        </form>
      </>
    );
  };
  const EmailCommunication = ({
    onClose,
    handleSubmitData,
    recipientType,
    loading,
    user,
    orgInfo,
    orgMessageData,
    senderNumber,
    handleSenderNumber,
  }) => {
    let today = new Date();
    today.setHours(today.getHours() + 2);
    const [startDate, setStartDate] = useState(today);
    const [data, setData] = useState({
      emailSenderName: `${user?.userSalutation} ${user?.userFirstName} ${user?.userMiddleName} ${user?.userLastName} (${orgInfo?.shortName})`,
      personalizeMessage: "standardMesssage",
      emailSenderEmail: `${user?.userEmail}`,
      emailSubject: "",
      emailMessage: "",
    });
    const [value, setValue] = React.useState("sendNow");
    const [emailIsValid, setValidEmail] = useState(false);
    const [textLength, setTextLength] = useState(0);
    console.log(orgInfo, 'orgInfo');
  
    const handleChangeRadio = (event) => {
      setValue(event.target.value);
    };
    const handleChange = ({ target: { name, value } }) => {
      let temp = { ...data };
      temp[name] = value;
      setData(temp);
      if (temp?.emailSenderEmail)
        setValidEmail(!validateEmail(temp?.emailSenderEmail));
      if (name === "textMessage") setTextLength(temp?.emailMessage.length);
    };
    const handleCheck = ({ target: { name, value } }) => {
      let temp = { ...data };
      temp[name] = value;
      setData(temp);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const payload = {
        ...data,
        startDate,
        value,
        senderNumber,
      };
      handleSubmitData(payload);
    };
    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
    return (
      <form action="" onSubmit={handleSubmit}>
        <div className="py-3 mt-3">
          <TextField
            id="email-name-text-field"
            fullWidth
            value={data?.emailSenderName}
            variant="outlined"
            name="emailSenderName"
            onChange={handleChange}
            required
            label="Sender Name"
            size="small"
            error={
              data?.emailSubject > 5 && data?.emailSubject > 128 ? true : false
            }
            inputProps={{
              maxlength: 128,
              minlength: 5,
            }}
          />
        </div>
        <div className="py-2">
          <TextField
            id="email-sender-text-field"
            fullWidth
            value={data?.emailSenderEmail}
            variant="outlined"
            name="emailSenderEmail"
            onChange={handleChange}
            required
            type="email"
            size="small"
            label="Sender Email"
            error={emailIsValid}
            inputProps={{
              maxlength: 620,
              minlength: 2,
            }}
          />
        </div>
        <div className="py-2">
          <TextField
            id="email-subject-text-field"
            fullWidth
            value={data?.emailSubject}
            variant="outlined"
            name="emailSubject"
            onChange={handleChange}
            required
            type="text"
            label="Subject"
            size="small"
            error={
              data?.emailSubject < 5 || data?.emailSubject > 200 ? true : false
            }
            inputProps={{
              maxlength: 200,
              minlength: 5,
            }}
          />
        </div>
        <div className="py-2">
          <TextField
            id="email-message-text-field"
            multiline
            rows={4}
            fullWidth
            value={data?.emailMessage}
            variant="outlined"
            name="emailMessage"
            onChange={handleChange}
            required
            type="text"
            label="Message"
            // error={textLength > 620 || textLength < 2 ? true : false}
            inputProps={{
              // maxlength: 620,
              minlength: 2,
            }}
          />
          {/* <span>{`${620 - textLength} characters remaining`}</span> */}
        </div>
        {recipientType === "group" && (
          <div className="py">
            <RadioGroup
              aria-label="personalizeMessage"
              name="personalizeMessage"
              value={data?.personalizeMessage}
              onChange={handleCheck}
              row
            >
              <FormControlLabel
                value="standardMesssage"
                control={<Radio />}
                label="Standard Message"
              />
              <FormControlLabel
                value="personalizeMessage"
                control={<Radio />}
                label="Personalize Message"
              />
            </RadioGroup>
          </div>
        )}
        <div className="pt-2">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChangeRadio}
            row
          >
            <FormControlLabel
              value="sendNow"
              control={<Radio />}
              label="Send Now"
            />
            <FormControlLabel
              value="sendLater"
              control={<Radio />}
              label="Send Later"
            />
          </RadioGroup>
        </div>
        {value === 'sendLater' && <p className='mt-0 mb-2'> <span className="pr-2"><Icon style={{fontSize: '16px'}}>info</Icon></span>Your future message delivery must be set at least 2 hours ahead.</p>}
        {value === "sendLater" && (
          <div className="py-2">
            <InputWithDate
              date={today}
              label="Send Date"
              startDate={startDate}
              setStartDate={setStartDate}
            />
          </div>
        )}
        <div
          className="d-flex justify-content-between mt-3 pb-3"
          style={{ columnGap: "1rem" }}
        >
          <React.Fragment>
            <CustomButton
              label="Send"
              variant="contained"
              className="w-full"
              color="primary"
              type="submit"
              loading={loading}
            />
            <CustomButton
              label="Cancel"
              variant="outlined"
              className="w-full"
              onClick={onClose}
              loading={loading}
              // disabled={disabled}
            />
          </React.Fragment>
        </div>
      </form>
    );
  };
  const VoiceNoteCommuncation = ({
    vaultData,
    vaultLoading,
    recipientType,
    handleSubmitData,
    onClose,
    orgMessageData,
    senderNumber,
    handleSenderNumber,
    loading,
  }) => {
    let today = new Date();
    today.setHours(today.getHours() + 2);
    const [startDate, setStartDate] = useState(today);
    const [data, setData] = useState({
      textMessage: "",
    });
    const [selectedVoiceNote, setSelectedVoiceNote] = useState({});
    const [value, setValue] = React.useState("sendNow");
  
    const handleChangeRadio = (event) => {
      setValue(event.target.value);
    };
    const getSearchText = (data) => {
      console.log(data, "data");
    };
    const selectedMedia = (data) => {
      // console.log(data, "selected");
      if (data === null) {
        return setSelectedVoiceNote({});
      }
      setSelectedVoiceNote(data);
    };
    const handleCheck = ({ target: { name, checked } }) => {
      let temp = { ...data };
      temp[name] = checked;
      setData(temp);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      const payload = {
        ...data,
        value,
        selectedVoiceNote,
        senderNumber,
        startDate,
      };
      handleSubmitData(payload);
    };
    return (
      <form action="" onSubmit={handleSubmit}>
        {/* <div className="py-4">
          {orgMessageData?.approvedSmsMmsSenderIds?.length === 1 ? (
            <p>From: {orgMessageData?.approvedSmsMmsSenderIds[0]}</p>
          ) : (
            <TextField
              name="approvedSmsMmsSenderIds"
              label="Sender Number"
              variant="outlined"
              select
              onChange={handleSenderNumber}
              fullWidth
              size="small"
              // className="pt-4"
            >
              {orgMessageData?.approvedSmsMmsSenderIds?.map((item, id) => (
                <MenuItem key={id} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div> */}
        <div className="py-3 mt-1">
          {vaultData && (
            <AutocompleteComponent
              label="Search Media"
              // value={searchTeacher?.item}
              setValue={getSearchText}
              labelFunc={(option) => option.name}
              data={vaultData?.ReturnedRecords}
              selectedTeacher={selectedMedia}
              multiple={false}
              loading={vaultLoading}
              disable={selectedVoiceNote.length > 3 ? true : false}
              freeSoloo={false}
            />
          )}
        </div>
        {Object.keys(selectedVoiceNote).length > 0 && (
          <div className="py-2">
            <audio
              style={{ width: "100%" }}
              controls
              src={selectedVoiceNote?.url}
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </div>
        )}
        <div className="pt-2">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChangeRadio}
            row
          >
            <FormControlLabel
              value="sendNow"
              control={<Radio />}
              label="Send Now"
            />
            <FormControlLabel
              value="sendLater"
              control={<Radio />}
              label="Send Later"
            />
          </RadioGroup>
        </div>
        {value === 'sendLater' && <p className='mt-0 mb-2'> <span className="pr-2"><Icon style={{fontSize: '16px'}}>info</Icon></span>Your future message delivery must be set at least 2 hours ahead.</p>}
        {value === "sendLater" && (
          <div className="py-2">
            <InputWithDate
              date={today}
              label="Send Date"
              startDate={startDate}
              setStartDate={setStartDate}
            />
          </div>
        )}
        <div
          className="d-flex justify-content-between mt-3 pb-3"
          style={{ columnGap: "1rem" }}
        >
          <React.Fragment>
            <CustomButton
              label="Send"
              variant="contained"
              className="w-full"
              color="primary"
              type="submit"
              loading={loading}
            />
            <CustomButton
              label="Cancel"
              variant="outlined"
              className="w-full"
              onClick={onClose}
              // disabled={disabled}
              loading={loading}
            />
          </React.Fragment>
        </div>
      </form>
    );
  };
  const MMSCommunication = ({
    onClose,
    handleSubmitData,
    autoCompleteData,
    recipientType,
    vaultLoading,
    vaultData,
    orgMessageData,
    senderNumber,
    handleSenderNumber,
    loading,
  }) => {
    let today = new Date();
    today.setHours(today.getHours() + 2);
    const [startDate, setStartDate] = useState(today);
    const [data, setData] = useState({
      textMessage: "",
      personalizeMessage: "standardMesssage",
    });
    const [selectedImage, setSelectedImage] = useState([]);
    const [value, setValue] = React.useState("sendNow");
    const [textLength, setTextLength] = useState({
      totalLength: 0,
      pageLength: 1,
      currentLength: 0,
    });
    const [message, setMessage] = useState("");
  
    const handleChangeRadio = (event) => {
      setValue(event.target.value);
    };
  
    const handleChangeInput = ({ target: { name, value } }) => {
      setMessage(value);
      let temp = { ...data };
      temp.textMessage = value;
      setData(temp);
    };
    const handleCheck = ({ target: { name, value } }) => {
      let temp = { ...data };
      temp[name] = value;
      setData(temp);
    };
    const getSearchText = (data) => {
      console.log(data, "data");
    };
    const selectedMedia = (data) => {
      // console.log(data, "selected");
      setSelectedImage(data);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      const payload = {
        ...data,
        value,
        selectedImage,
        senderNumber,
        startDate,
      };
      handleSubmitData(payload);
    };
    const removeImage = (id) => {
      const selected = [...selectedImage];
      selected.splice(id, 1);
      setSelectedImage(selected);
    };
    const classes = useStyles();
    return (
      <form action="" onSubmit={handleSubmit}>
        <div className="py-3 mt-3">
          {vaultData && (
            <AutocompleteComponent
              label="Search Media"
              // value={searchTeacher?.item}
              setValue={getSearchText}
              labelFunc={(option) => option.name}
              data={vaultData?.ReturnedRecords}
              selectedTeacher={selectedMedia}
              multiple={true}
              loading={vaultLoading}
              disable={selectedImage.length > 3 ? true : false}
              freeSoloo={false}
              value={selectedImage}
            />
          )}
        </div>
        {selectedImage?.length > 0 && (
          <div className="py-2">
            <ImageList rowHeight={100} className={classes.imageList} cols={2}>
              <ImageListItem key="Subheader" cols={2} style={{ height: "auto" }}>
                <ListSubheader component="div">Selected Image(s)</ListSubheader>
              </ImageListItem>
              {selectedImage?.map((item, id) => (
                <ImageListItem key={id}>
                  <img
                    src={item.url}
                    alt={item.name}
                    width="100px"
                    height="100px"
                    style={{ objectFit: "contain" }}
                  />
                  <ImageListItemBar
                    title={item.name}
                    // subtitle={<span>by: {item.author}</span>}
                    actionIcon={
                      <IconButton
                        aria-label={`info about ${item.name}`}
                        className={classes.icon}
                        onClick={() => removeImage(id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        )}
        {recipientType === "group" && (
          <div className="py-2">
            <RadioGroup
              aria-label="personalizeMessage"
              name="personalizeMessage"
              value={data?.personalizeMessage}
              onChange={handleCheck}
              row
            >
              <FormControlLabel
                value="standardMesssage"
                control={<Radio />}
                label="Standard Message"
              />
              <FormControlLabel
                value="personalizeMessage"
                control={<Radio />}
                label="Personalize Message"
              />
            </RadioGroup>
          </div>
        )}
        <div className="py-2">
          <TextField
            id="sms-text-field"
            multiline
            rows={4}
            fullWidth
            value={data?.textMessage}
            variant="outlined"
            name="textMessage"
            onChange={handleChangeInput}
            label="message"
            required
            error={message?.length > 1400 ? true : false}
            inputProps={{
              maxlength: 1400,
              minlength: 2,
            }}
          />
          <span
            style={{ fontSize: "11px" }}
          >{`${message?.length}/1400 character remaining`}</span>
        </div>
        <div className="pt-2">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChangeRadio}
            row
          >
            <FormControlLabel
              value="sendNow"
              control={<Radio />}
              label="Send Now"
            />
            <FormControlLabel
              value="sendLater"
              control={<Radio />}
              label="Send Later"
            />
          </RadioGroup>
        </div>
        {value === 'sendLater' && <p className='mt-0 mb-2'> <span className="pr-2"><Icon style={{fontSize: '16px'}}>info</Icon></span>Your future message delivery must be set at least 2 hours ahead.</p>}
        {value === "sendLater" && (
          <div className="py-2">
            <InputWithDate
              date={today}
              label="Send Date"
              startDate={startDate}
              setStartDate={setStartDate}
            />
          </div>
        )}
        {/* {recipientType === "group" && (
          <div className="py-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={data?.personalizeMessage}
                  onChange={handleCheck}
                  name="personalizeMessage"
                  color="primary"
                />
              }
              label="Personalize Message"
            />
          </div>
        )} */}
        <div
          className="d-flex justify-content-between mt-3 pb-3"
          style={{ columnGap: "1rem" }}
        >
          <React.Fragment>
            <CustomButton
              label="Send"
              variant="contained"
              className="w-full"
              color="primary"
              type="submit"
              loading={loading}
            />
            <CustomButton
              label="Cancel"
              variant="outlined"
              className="w-full"
              onClick={onClose}
              loading={loading}
              // disabled={disabled}
            />
          </React.Fragment>
        </div>
      </form>
    );
  };
  
  const PushNotification = ({
    onClose,
    handleSubmitData,
    autoCompleteData,
    recipientType,
    vaultLoading,
    vaultData,
    loading,
    pushData,
  }) => {
    let today = new Date();
    today.setHours(today.getHours() + 2);
    const [startDate, setStartDate] = useState(today);
    const [data, setData] = useState({
      textMessage: "",
      pushSubject: "",
    });
    const [selectedImage, setSelectedImage] = useState({});
    const [value, setValue] = React.useState("sendNow");
    const [textLength, setTextLength] = useState(0);
    const [pushTextLength, setPushTextLength] = useState(0);
  
    const handleChangeRadio = (event) => {
      setValue(event.target.value);
    };
  
    const handleChange = ({ target: { name, value } }) => {
      let temp = { ...data };
      temp[name] = value;
      setData(temp);
      if (name === "textMessage") setTextLength(temp?.textMessage.length);
      if (name === "pushSubject") setPushTextLength(temp?.pushSubject.length);
    };
    const handleCheck = ({ target: { name, checked } }) => {
      let temp = { ...data };
      temp[name] = checked;
      setData(temp);
    };
    const getSearchText = (data) => {
      console.log(data, "data");
    };
    const selectedMedia = (data) => {
      console.log(data, "selected");
      if (data === null) {
        return setSelectedImage({});
      }
      setSelectedImage(data);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      const payload = {
        ...data,
        value,
        selectedImage,
        startDate,
      };
      handleSubmitData(payload);
    };
    const classes = useStyles();
    return (
      <form action="" onSubmit={handleSubmit}>
        <div className="py-2">
          <TextField
            id="sms-text-field"
            fullWidth
            value={data?.pushSubject}
            variant="outlined"
            name="pushSubject"
            onChange={handleChange}
            label="Subject"
            size="small"
            error={pushTextLength > 100 || pushTextLength < 5 ? true : false}
            required
            inputProps={{
              maxlength: 60,
              minlength: 5,
            }}
          />
          <span
            style={{ fontSize: "11px" }}
          >{`${data?.pushSubject?.length}/60 characters remaining`}</span>
        </div>
        <div className="py-2">
          <TextField
            id="sms-text-field"
            multiline
            rows={4}
            fullWidth
            value={data?.textMessage}
            variant="outlined"
            name="textMessage"
            onChange={handleChange}
            label="message"
            error={textLength > 300 || textLength < 2 ? true : false}
            required
            inputProps={{
              maxlength: 170,
              minlength: 2,
            }}
          />
          <span
            style={{ fontSize: "11px" }}
          >{`${textLength}/170 characters`}</span>
        </div>
          {pushData?.pushNotificationTargetsMobileAppPagesOptions?.length > 0 && <div className="py-2">
          <TextField
            variant="outlined"
            label='Push Notification Target'
            select
            required
            fullWidth
            onChange={handleChange}
            name='targetAppModule'
            size='small'
          >
            {pushData?.pushNotificationTargetsMobileAppPagesOptions?.map((item, index) => (
              <MenuItem key={index} value={item.name}>{item.title}</MenuItem>
            ))}
            </TextField>
          </div>}
          <div className="py-3 mt-3">
          {vaultData && (
            <AutocompleteComponent
              label="Search Media"
              // value={searchTeacher?.item}
              setValue={getSearchText}
              labelFunc={(option) => option.name}
              data={vaultData?.ReturnedRecords}
              selectedTeacher={selectedMedia}
              multiple={false}
              loading={vaultLoading}
              // disable={selectedImage.length > 3 ? true : false}
              freeSoloo={false}
              value={selectedImage}
            />
          )}
        </div>
        {Object.keys(selectedImage).length > 0 && (
          <div className="py-2 d-flex justify-content-center">
            <img
              src={selectedImage.url}
              alt={selectedImage.name}
              className={classes.imageListSingle}
            />
          </div>
        )}
        <div className="pt-2">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChangeRadio}
            row
          >
            <FormControlLabel
              value="sendNow"
              control={<Radio />}
              label="Send Now"
            />
            <FormControlLabel
              value="sendLater"
              control={<Radio />}
              label="Send Later"
            />
          </RadioGroup>
        </div>
        {value === 'sendLater' && <p className='mt-0 mb-2'> <span className="pr-2"><Icon style={{fontSize: '16px'}}>info</Icon></span>Your future message delivery must be set at least 2 hours ahead.</p>}
        {value === "sendLater" && (
          <div className="py-2">
            <InputWithDate
              date={today}
              label="Send Date"
              startDate={startDate}
              setStartDate={setStartDate}
            />
          </div>
        )}
        {/* {recipientType === "group" && (
        <div className="py-2">
          <FormControlLabel
            control={
              <Checkbox
                checked={data?.personalizeMessage}
                onChange={handleCheck}
                name="personalizeMessage"
                color="primary"
              />
            }
            label="Personalize Message"
          />
        </div>
      )} */}
        <div
          className="d-flex justify-content-between mt-3 pb-3"
          style={{ columnGap: "1rem" }}
        >
          <React.Fragment>
            <CustomButton
              label="Send"
              variant="contained"
              className="w-full"
              color="primary"
              type="submit"
              loading={loading}
            />
            <CustomButton
              label="Cancel"
              variant="outlined"
              className="w-full"
              onClick={onClose}
              loading={loading}
              // disabled={disabled}
            />
          </React.Fragment>
        </div>
      </form>
    );
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      width: 400,
      // height: 350,
    },
    imageListSingle: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
  }));
  