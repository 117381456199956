import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { RsvpCardDetails } from './RsvpCardDetails';
import './rsvp.scss';
import 'react-phone-number-input/style.css';

export const RsvpCard = ({ item, index, getEventObj }) => {
  const [isCardSelected, setIsCardSelected] = useState(false);
  const multipleRsvpData = useSelector(state => state.multipleRsvpData)
  // console.log(multipleRsvpData.clearAllRsvp)
  const handleSelectedCard = () => setIsCardSelected(!isCardSelected);
  
  useEffect(() => {
    if(multipleRsvpData?.clearAllRsvp){
      setIsCardSelected(false)
    }
  }, [multipleRsvpData.clearAllRsvp])

  useEffect(() => {
    getEventObj(item, index, isCardSelected)
  }, [isCardSelected])

  return (
    <div 
      // onClick={handleSelectedCard} 
      style={{border: `${isCardSelected ? '1px solid gray' : ''}`}} 
      className={`rsvp-card rounded-lg mb-5 ${item.isUserRsvped ? 'bg-dark text-light': 'bg-gray-300 text-dark'}`}
    >
      {/* {!item.isUserRsvped && !item.filledUp && 
        <Tooltip title="Click here to rsvp multiple event" placement="top" arrow>
          <Checkbox
            checked={isCardSelected}
            onChange={handleSelectedCard}
            icon={<RadioButtonUncheckedIcon fontSize="small" />}
            checkedIcon={<CheckCircleTwoToneIcon fontSize="small" />} 
            name="selectedCard"
            className="check-box"
          />
      </Tooltip>} */}
      <div className="px-10 pt-10 d-flex flex-column flex-md-row justify-content-md-between">
        <h6>{item.name}</h6>
        <p><span className="d-inline d-md-none mr-2"><i className="far fa-clock font-size-sm"></i></span>{moment(item.time, ["HH.mm"]).format("hh:mm A")}</p>
      </div>
      <div className="d-flex mx-10">
        {item.isUserRsvped ? 
          <span className="mr-5 label label-danger label-pill label-inline mr-2">RESERVED</span> :
            !item.isUserRsvped && item.filledUp ? 
              <span className=" label label-dark label-pill label-inline mr-2">SEATS FILLED UP</span> :
        !item.isUserRsvped && !item.filledUp ? <div className="w-100 d-flex justify-content-between">
          <span className="label label-success bg-green label-pill label-inline">AVAILABLE</span>
          <span className={`label ${item.capacityPercentage <= 25 ? 'label-success bg-green' : item.capacityPercentage <= 50 ? 'label-primary' : item.capacityPercentage <= 75 ? 'label-warning' : 'label-danger' } label-pill label-inline`}>{item.capacityPercentage <=75 ? `${item.capacityPercentage}% FILLED` : `${item.dates[0].availableSpots} SEAT${item.remainingSeats > 1 ? 'S' : ''} LEFT`}</span>
        </div> : null}
      </div>
      <RsvpCardDetails item={item} />
      <div className="">
        {!item.isUserRsvped && !item.filledUp && <div className="progress w-100" style={{height: '3px'}}>
          <div className={`progress-bar ${item.capacityPercentage <= 25 ? 'bg-green' : item.capacityPercentage <= 50 ? 'bg-primary' : item.capacityPercentage <= 75 ? 'bg-warning' : 'bg-danger' }`} role="progressbar" style={{width: `${item.capacityPercentage}%`}} aria-valuenow={item.capacityPercentage} aria-valuemin="0" aria-valuemax="100"></div>
        </div>}
      </div>
    </div>
  )
}