import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Container} from 'react-bootstrap/';
import {Paper} from '@material-ui/core';
import ReactReadMoreReadLess from "react-read-more-read-less";
import Footer from '../../components/Footer';
import {NavBar} from '../../components/Nav';
import {SubNavBar} from '../../components/Nav/SubNavbar';
import {MobileMenu} from '../../components/Nav/MobileMenu';
import MainSection from '../../components/MainSection';
import {useSelector, shallowEqual} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useThemeColor} from '../../utils/customHooks/useThemeColor'
import { contributionProcessingEngines } from '../../store/actions/'

import './Home.scss';

export const Home = ({children}) => {
  const themeColor = useThemeColor()
  const dispatch = useDispatch()
  const ipData = useSelector(state => state.ipData);
  const showMenuBar = useSelector((state) => state.showMenuBar, shallowEqual);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const location = useLocation();
  const sermonRoute = location.pathname === '/live' || location.pathname === '/celebration';
  const isGiveRoute = location.pathname === '/give';
  const isSignupRoute = location.pathname === '/signup';
  const isLoginRoute = location.pathname === '/';
  const epay = location.pathname === '/epay/'
  const iAmNew = location.pathname === '/new';
  // const combineRoute = sermonRoute || iAmNew

  useEffect(() => {
    if(orgInfo?.orgId){
      dispatch(contributionProcessingEngines({orgId: orgInfo.orgId }))
    }
  }, [orgInfo?.orgId])

  return (
    <Paper className="position-relative">
      <MobileMenu showAside={showMenuBar.isMobileShow} />
      <main>
         <div className="home-navbar position-fixed w-100">
          <NavBar />
          {!sermonRoute  && <SubNavBar />}
        </div>
        {/* {iAmNew && <div className="home-navbar position-fixed w-100">
          <NavBar />
        </div>} */}
        {!sermonRoute && <MainSection>{children}</MainSection>}
        {sermonRoute && children}
        {(isGiveRoute || isSignupRoute || isLoginRoute || epay) && <div className="py-7 px-15 mb-5 text-center read-more-text" style={{background: themeColor.color}}>
          <div className="mx-auto text-center displayed-text text-white" style={{maxWidth: '500px'}}>
            <h4 className="font-weight-bold text-white mb-4"><i className="fas fa-lock font-size-h3 mr-2"></i>{orgInfo?.privacySecurityStuff?.securityNoteLabel}</h4>
            <ReactReadMoreReadLess
              charLimit={33}
              readMoreText={"Learn more ▼"}
              readLessText={"Hide ▲"}
              readMoreClassName="show-more"
              readLessClassName="show-more"
              ellipsis=''
              readMoreStyle={{
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                display: 'block',
                color: '#fff',
                fontSize: '16px',
                textDecoration: 'none',
              }}
              readLessStyle={{
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                display: 'block',
                color: '#fff',
                fontSize: '16px',
                textDecoration: 'none',
              }}
            >
               {`${orgInfo?.privacySecurityStuff?.securityNoteContent} ${ipData?.ip}`}
          </ReactReadMoreReadLess>
            {/* <ReadMore lines={3}>
            {`${orgInfo?.privacySecurityStuff?.securityNoteContent} ${ipData?.ip}`}
          </ReadMore> */}
          {/* {`${orgInfo?.privacySecurityStuff?.securityNoteContent} ${ipData?.ip}`} */}
          </div>
        </div>}
        <Container>
          <Footer />
        </Container>
      </main>
    </Paper>
  );
};
