import React from 'react'
import {useSelector, shallowEqual} from 'react-redux';
import { Paper } from '@material-ui/core';
import { useThemeColor } from '../utils/customHooks/useThemeColor'

import './Section.scss'

export const SubSection = ({ children }) => {
  const themeColor = useThemeColor();
    // const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual)
    // const mainSectionBg = {backgroundColor: orgInfo ? orgInfo.orgColors.primary : ''}; 
    return (
        <Paper square className="text-center text-white mainsection" style={themeColor.btnStyle}>
          {children}
        </Paper>
    )
  }