import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Grid,
    TextField,
    Button,
    MenuItem,
	withStyles
} from '@material-ui/core'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { FieldArray } from 'formik'
import './Give.scss';   

export const ColorButton = withStyles((theme) => ({
	root: {
	//   color: theme.palette.getContrastText(purple[500]),
	color: '#fff',
	  backgroundColor: props => props.backgroundColor,
	  '&:hover': {
		backgroundColor: props => props.backgroundColor,
		color: '#fff'
	  },
	},
  }))(Button);

const AddMoreContributionForm = ({ values, btnWidth, handleChange, listOfContribution }) => {
	const themeColor = useThemeColor();
	const orgInfo = useSelector(state => state.orgInfo.responseData);
	
	console.log(values, 'add more contribution values')
	useEffect(() => {
		if(values.listOfContributions){
			listOfContribution(values)
		}
	}, [values])
  return (
    <FieldArray name="listOfContributions">
      {(arrayHelpers) => (
        <>
					<Grid container spacing={3} >
						<Grid item xs={btnWidth ?? 12}>
							<ColorButton variant="contained" 
								className="btn  mb-1 py-3 w-100"
								onClick={() => arrayHelpers.push({})}
								backgroundColor={themeColor.color}
							>
								+ Add More Contribution Fields
							</ColorButton>
						</Grid>
					</Grid>
          {values?.listOfContributions?.map((item, ind) => {
						if(ind < 5){
							return (
								<>
							<Grid key={ind} container spacing={3} className="my-5">
								<Grid item xs={4}>
									<TextField
										label="Amount"
										name={`listOfContributions[${ind}].amount`}
										size="small"
										variant="outlined"
										type='number'
										fullWidth
										defaultValue={item.amount || ''}
										onBlur={handleChange}
									/>
									{/* <span>Amount is Required</span> */}
								</Grid>
								<Grid item xs={6}>
									<TextField
										label="Give Type"
										name={`listOfContributions[${ind}].contributionType`}
										size="small"
										variant="outlined"
										fullWidth
										select
										defaultValue={item.contributionType || ''}
										onBlur={handleChange}
									>
										{orgInfo.contributionTypes?.map((list) => (
											<MenuItem value={list} key={list}>
												{list}
											</MenuItem>
										))}
									</TextField>
									{/* <span>Contribution Type is Required</span> */}
								</Grid>
								<Grid item xs={1}>
									<div className="symbol cursor symbol-35" onClick={() =>
											arrayHelpers.remove(ind)
										}>
										<span className="symbol-label transparent-bg">
											<span className="svg-icon svg-icon-md ">
											<i style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}} className="fas fa-trash-alt font-size-md"></i>
											</span>
										</span>
									</div>
								</Grid>	
								<Grid item xs={12}>
									<TextField
										label="Memo"
										name={`listOfContributions[${ind}].memo`}
										maxLength={100}
										size="small"
										variant="outlined"
										fullWidth
										multiline
										rows={3}
										onChange={handleChange}
										defaultValue={item.memo || ''}
										onBlur={handleChange}
										inputProps={{maxLength: 100}}
									/>
									<span>{`${100 - (item?.memo?.length ?? 0)} characters remaining`}</span>
									{/* <span>{`${100 - touched?.listOfContributions[ind]?.memo?.length} characters remaining`}</span> */}
								</Grid>
							</Grid>
							{/* <hr /> */}
            </> 
          )}})}
				</>
      )}
    </FieldArray>
  )
}
// const salutationList = ['Mr.', 'Mrs.', 'Ms.', 'Miss.', 'Dr.']
export default AddMoreContributionForm;
