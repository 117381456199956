import React from 'react';
import { Menu, MenuItem, Link } from '@material-ui/core'
import {ReuseableMenu} from '../../Common/DropDownList';
import { useWindowDimensions } from '../../../utils/customHooks/useWindowDimensions';
import {useThemeColor} from '../../../utils/customHooks/useThemeColor'
import './church411.scss'
import ViewUserImage from '../Prayer/ViewUserImage'

export const WebCard = ({ item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { width } = useWindowDimensions();
  const [viewDetails, setViewDetails] = React.useState({
    show: false,
    item: ''
  })
  let themeColor = useThemeColor();
  const backgroundColor = themeColor.color;
  const addDefaultSrc = (e, item) => {
    e.target.src =
      item.officerSalutation === "Mr."
        ? "https://dev.churchpad.com/xTextersImageFilesLoc/anonymous_user_100.png"
        : 'https://dev.churchpad.com/xTextersImageFilesLoc/1/2/t12__1612373884__hkm2xgo9rtuq__ddesign1.png';
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewImage = (item) => {
    setViewDetails({...viewDetails, show: true, item})
  }

  const handleCloseView = () => {
    setViewDetails({...viewDetails, show: false, item: ''})
  }

  return (
    <div className="web-card-container py-10 d-flex flex-column justify-content-between w-100">
      <div className="card-user-container mx-10 d-flex flex-column">
        <div className="user-img-container position-relative text-center">
          <img
            alt="Pic"
            src={item.officerPicture}
            className="rounded-circle"
            onError={(e) => addDefaultSrc(e, item)}
            onClick={()=>handleViewImage(item)}
            role='button'
          />
          {/* <div className="position-absolute customize-menu">
            <ReuseableMenu openMenu={handleClick}>
              <Menu
                id="simple-menu2"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {item.departmentAndPosition.map((dept, index) =>
                  <MenuItem key={index} onClick={handleClose}>
                    <p>{dept.department}: (<span>{dept.position}</span>)</p>
                  </MenuItem>
                )}
              </Menu>
            </ReuseableMenu>
          </div> */}
        </div>
        <div className="user-profile-container d-flex flex-column align-items-center">
          <h4 className="font-size-h4 my-5 mt-7 text-center">
            {item.officerSalutation} {item.officerFirstName} {item.officerLastName}
          </h4>
          {item.officerEmail && (
            <div className="font-size-lg mb-4 text-center">
              {<Link href={`mailto:${item.officerEmail}`} >{width > 350 && <i className={`fa fa-envelope icon_color mr-3`}></i>}
              {item.officerEmail}</Link>}
            </div>
          )}
          {item.officerMobile && (
            <div className='font-size-lg mb-4 text-center'>
              {<Link href={`tel:${item.officerMobile}`} >{width > 350 && <i className={`fa fa-phone-square icon_color mr-3`}></i>}
              {item.officerMobile}</Link>}
            </div>
          )}
          {item?.departmentAndPosition !== null && item?.departmentAndPosition.length > 0 && 
            <p>{item?.departmentAndPosition[0].department}: (<span>{item?.departmentAndPosition[0].position}</span>)</p>
          }
          {item?.departmentAndPosition !== null && item?.departmentAndPosition.length > 1 && <ReuseableMenu openMenu={handleClick} showBtn={true} btnText="show more positions">
            <Menu
              id="simple-menu2"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {item.departmentAndPosition.map((dept, index) => {
                if(index > 0) {
                  return <MenuItem key={index} onClick={handleClose}>
                    <p>{dept.department}: (<span>{dept.position}</span>)</p>
                  </MenuItem>
                }
              })}
            </Menu>
          </ReuseableMenu>}
        </div>
      </div>
      <ViewUserImage
        isOpen={viewDetails?.show}
        handleCloseModal={handleCloseView}
        image={viewDetails?.item?.officerPicture}
        backgroundColor={backgroundColor}
        title={`${viewDetails?.item?.officerSalutation} ${viewDetails?.item?.officerLastName} ${viewDetails?.item?.officerFirstName}` || 'Image'}
      />
    </div>
  );
}