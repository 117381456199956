import React from "react";
// import {Link} from "react-router-dom";
import {useSelector, shallowEqual} from 'react-redux';
// import objectPath from "object-path";
// import SVG from "react-inlinesvg";
// import {useHtmlClassService} from "../../_core/MetronicLayout";
// import {toAbsoluteUrl} from "../../../_helpers";
import './Aside.scss'

export function Brand() {
  const orgInfo = useSelector(state => state.orgInfo?.responseData, shallowEqual);
  const userData = JSON.parse(localStorage.getItem('user'));
  const userDetails = useSelector(
    (state) => state.getUserProfileData?.responseData,
    shallowEqual
  );

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto`}
          id="kt_brand"
      >
        {/* begin::Logo */}
          <div className="square-flip">
            <div className='square' >
              <div className="square-container">
                <div className="brand-logo">
                  <img alt={orgInfo?.fullName} src={orgInfo ? orgInfo?.logos?.landingPages : ''} className="w-50 pt-5" />
                  </div>
              </div>
              <div className="flip-overlay"></div>
            </div>
            <div className='square2' >
              <div className="square-container2">
                <div className="brand-logo">
                <img alt={userData?.responseData?.userLastName} src={userDetails?.userProfilePicSmall || ''} className="w-100" height="60px"/>
                </div>
              </div>
              <div className="flip-overlay"></div>
            </div>
          </div>
     {/*  <div className="scene">
          <div className={`card ${isFlipped && 'is-flipped'}`} onClick={toggleCardFlip}>
            <div className="card__face card__face--front">
                <img alt={orgInfo.orgFullName} src={orgInfo ? orgInfo.orgLogo : ''} className="w-50 pt-5" />
            </div>
            <div className="card__face card__face--back">
                <img alt={orgInfo.orgFullName} src={userProfilePicLarge} className="w-50 pt-5" />
            </div>
          </div>
        </div>  */}
       
        {/* end::Logo */}

        {/* {layoutProps.asideSelfMinimizeToggle && (
          <>
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
          </>
        )} */}
      </div>
      {/* end::Brand */}
      </>
  );
}
