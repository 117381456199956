import axios from 'axios'
import { checkNodeEnvNextGen, logout } from './authHelpers';
import { nextgenUrl, cpUrls } from './constants';



const AxiosInstance = axios.create({
    baseURL: checkNodeEnvNextGen(nextgenUrl.live, nextgenUrl.staging),
});

export const AxiosInstanceCP = axios.create({
  baseURL: checkNodeEnvNextGen(cpUrls.live, cpUrls.staging),
});

export const AxiosInstancePublicCp = axios.create({
  baseURL: checkNodeEnvNextGen(cpUrls.live, cpUrls.staging),
});


export const setupAxiosInterceptors = () => {
    const onRequestSuccess = async (config) => {
      let authToken = localStorage.getItem('nextGenToken') || '';

      if (!authToken) {
        logout()
      }
      
      if (authToken) {
        config.headers['Authorization'] = 'Bearer ' + authToken;
      }

      return config;
    };
  
    const onRequestError = (err) => {

  
        return Promise.reject(err);
    };
  
    const onResponseSuccess = (response) => response;
    const onResponseError = (err) => {
      //console.log(err)

      return Promise.reject(err);
    };
  
    AxiosInstance.interceptors.request.use(onRequestSuccess, onRequestError);
    AxiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);
    AxiosInstanceCP.interceptors.request.use(onRequestSuccess, onRequestError);
    AxiosInstanceCP.interceptors.response.use(onResponseSuccess, onResponseError);

  };

  export default AxiosInstance