import React from "react";
import PinInput from "react-pin-input";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import "./PinInput.scss";

export default function PinInputComponent({setInputValue}) {
    const themeColor = useThemeColor();
  return (
    <div>
      <PinInput
        length={6}
        initialValue=""
        focus={true}
        onChange={(value, index) => {}}
        type="numeric"
        inputMode="number"
        style={{ padding: "20px 0px", display: "flex" }}
        inputStyle={{
          borderColor: "grey",
          borderRadius: "5px",
          fontSize: "30px",
          color: themeColor.textColor,
        }}
        inputFocusStyle={{ borderColor: themeColor.color }}
        onComplete={(value, index) => {
          setInputValue(value);
        }}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
    </div>
  );
}
