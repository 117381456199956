import React from 'react'
import {
    GoogleReCaptchaProvider,
  } from 'react-google-recaptcha-v3';
import Epay from '../../components/Forms/Epay';
import { Home } from './Home';

export default function EpayPage() {
    const siteKey = process.env.REACT_APP_RECAPTCHA
  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
    <Home>
        <Epay />
    </Home>
    </GoogleReCaptchaProvider>
  )
}
