import React, {useEffect} from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PublicViewForm from '../../components/User/DynamicForms/PublicViewForm';
import { getFormSchema } from '../../store/actions/userDashboardActions/forms';

export default function Forms() {
  const params = new URLSearchParams(window.location.search).get('id')
  const dispatch = useDispatch()
  const organizationFormsData = useSelector(
    (state) => state.getFormSchema,
    shallowEqual
  );
  console.log(params, 'params');
  console.log(organizationFormsData, 'organizationFormsData');
  useEffect(() => {
    if (params) {
      dispatch(getFormSchema(params))
    }
  }, [params])

  // 02d8b127-a230-471b-b111-3c51a1d074cd
  
  return (
    <div className='d-flex justify-content-center dynamic_form_view_form_container'>
        <Card>
            <PublicViewForm data={organizationFormsData}/>
        </Card>
    </div>
  )
}
