import React from 'react';
import { Paypal } from "../../components/User/Paypal";
import { Helmet } from "react-helmet";
import { useSelector, shallowEqual } from "react-redux";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import Layout from './Layout';


export const PaypalPage = () => {
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  const siteKey = process.env.REACT_APP_RECAPTCHA
  return <>
    {secondOrgInfo && (
      <Helmet>
        <title>{secondOrgInfo.metaData.give.title}</title>
        <meta
          name={secondOrgInfo.metaData.give.author}
          content={secondOrgInfo.metaData.give.description}
        />
      </Helmet>
    )}
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      <Layout>
        <Paypal />
      </Layout>
    </GoogleReCaptchaProvider>
  </>
}