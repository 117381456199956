import React from 'react';
import Paper from '@material-ui/core/Paper'
import {useSelector, shallowEqual} from 'react-redux';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import './Navbar.scss';

export const SubNavBar = () => {
  const themeColor = useThemeColor();
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  // const secondaryBgColor = {backgroundColor: orgInfo ? orgInfo.orgColors.secondary : ''}
  const secondaryBgColor = { backgroundColor: themeColor.secondaryColor }
  return (
    <Paper square className="subnavbar w-100 row mx-0" style={secondaryBgColor}>
      <div className="text-center mx-auto py-2">
        <p className="text-light font-weight-bold mb-0 py-1 churchName">{orgInfo ? orgInfo.fullName : ''}</p>
        <p className="text-light mb-0 pb-1 msg">{orgInfo ? (`${orgInfo.systemGeneralSettings.messagingNumberLabel} ${orgInfo.systemGeneralSettings.messagingNumberValue}`) : '' }</p>
      </div>
    </Paper>)
};
