import React from "react";
// import Cropper from "../Cropper";
import Cropper from '../Cropper'
import { Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ResizeableModalWithoutBtn } from "../../Common/ResizeableModal";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";

export default function ChangeGropuBanner({
  openCoverModal,
  setOpenCoverModal,
  handleSubmit,
  closeCoverModal,
  title,
  info1 = "Note: png or jpeg file only. (1 mb maximum file size)",
  info2 = "For the best result you should upload a rectangular image (Width: 640px and Height: 480px).",
  submitText = "Submit",
  getImg,
}) {
  const uploadMediaData = useSelector((state) => state.changeGroupBanner);
  const handleMediaUpload = (data, file) => {
    // console.log(data, file, 'crop');
    setOpenCoverModal({ ...openCoverModal, imgUrl: data, imgFile: file });
  };

  const handleBtnClick = () => {
    if (getImg) return getImg(openCoverModal.imgUrl, openCoverModal.imgFile);

    return handleSubmit();
  };
  const themeColor = useThemeColor()
  // console.log(openCoverModal, 'openCoverModal');

  return (
    <ResizeableModalWithoutBtn
      modalState={openCoverModal?.show}
      resetModalState={closeCoverModal}
      title={title}
    >
      <div className="px-3 pt-4">
        
        <Cropper family defaultImage={openCoverModal?.item?.coverPhotoUrl} handleCropImage={handleMediaUpload} showAvater={false} />
        
        <p className="font-bold">Note:</p>
        <p>{info1}</p>
        <p>{info2}</p>
        {/* {openCoverModal?.imgUrl &&
        openCoverModal?.imgUrl?.type === "video/mp4" ? (
          <video
            width="100%"
            height="300"
            controls
            src={URL.createObjectURL(openCoverModal.imgUrl)}
          ></video>
        ) : openCoverModal?.imgUrl ? (
          <div className="pt-3">
            <img
              src={openCoverModal.imgUrl}
              srcset=""
              style={{ objectFit: "contain" }}
              alt="upload"
              width="130px"
              height="130px"
            />
          </div>
        ) : null} */}
        <div className="d-flex justify-content-end py-3">
          {uploadMediaData?.loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              // color="primary"
              type="button"
              onClick={handleBtnClick}
              disabled={!openCoverModal?.imgUrl}
              style={{textTransform: 'capitalize', background: themeColor.color, color: '#fff'}}
            >
              {submitText}
            </Button>
          )}
        </div>
      </div>
    </ResizeableModalWithoutBtn>
  );
}
