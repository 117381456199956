import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import "./styles.scss";
import MUIDataTable from "mui-datatables";
import { Grid } from "@material-ui/core";

const GiveMethodTable = ({ giveMethodsData = [], showTitle = false }) => {
  const columns = [
    {
      name: "",
      label: "",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="flex items-center">
              <p>{dataIndex + 1}</p>
            </div>
          );
        },
      },
    },
    {
      name: "", // field name in the row object
      label: "Image", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let giveType = giveMethodsData[dataIndex];

          return (
            <div className="flex items-center">
              <img
                src={giveType?.image}
                alt=""
                style={
                  giveType?.name.toLowerCase() === "venmo"
                    ? { height: "18px" }
                    : { height: "30px" }
                }
              />
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
      },
    },
  ];
  return (
    <div className="give-method-table" id="give-method-table">
      <MUIDataTable
        title={showTitle && `Give Methods`}
        data={giveMethodsData}
        columns={columns}
        options={{
          filterType: "textField",
          responsive: "standard",
          search: false,
          filter: false, // set data filter option
          download: false, // set download option
          print: false, // set print option
          pagination: false, //set pagination option
          viewColumns: false, // set column option
          elevation: 0,
          rowsPerPage: 100,
          selectableRows: false, // set selected
          rowsPerPageOptions: [100, 200, 500, 1000],
          expandableRows: true,
          expandableRowsHeader: false,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const info = giveMethodsData[rowMeta.rowIndex].data;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Grid container spacing={4} alignItems="center">
                    {info?.map(({ label, value }) => {
                      if (value)
                        return (
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <p className="text-center text-capitalize">
                              {label}
                            </p>
                            <p className="text-center font-bold">{value}</p>
                          </Grid>
                        );
                    })}
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
      />
    </div>
  );
};

export default GiveMethodTable;
