import React from "react";
import { Switch, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

/**
 * This is a custom switch component
 * @param {Object} props
 * @returns jsx
 */
export const CustomSwitch = withStyles((props) => ({
  switchBase: {
    color: `white !important`,
    "&$checked": {
      color: `white !important`,
    },
    "&$checked + $track": {
      backgroundColor: props.primaryColor,
    },
  },
  checked: {},
  track: {},
}))(Switch);

/**
 * This is a custom switch component
 * @param {Object} props
 * @returns jsx
 */
// export const CustomRadio = withStyles((props) => ({
//   switchBase: {
//     // color: props => props.primaryColor,
//     '&$checked': {
//       color: props.primaryColor,
//     },
//     '&$checked + $track': {
//       backgroundColor: props.primaryColor,
//     },
//   }
// }))(Radio);
