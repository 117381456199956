import React from "react";
import CreditCardIcons from "../CreditCardIcons";
import "./styles.css";

const CreditCardUi = ({
  last4Digits,
  lastName,
  firstName,
  expirationDate,
  cardMaker,
  styles
}) => {
  return (
    <div className="d-block">
      <div id="card" className="fade-in" style={styles}>
        <div className="header">
          {/* <div className="sticker"></div> */}
          <div className=""></div>
          <div>
            <CreditCardIcons imgType={cardMaker} />
          </div>
        </div>
        <div className="body">
          <h2 id="creditCardNumber">************{last4Digits}</h2>
        </div>
        <div className="footer">
          <div>
            <h5 className="text-white">Card Holder</h5>
            <h3>
              {firstName} {lastName}
            </h3>
          </div>
          <div>
            <h5 className="text-white">Expires</h5>
            <h3>
              {expirationDate.substr(0, 2) +
                "/ " +
                expirationDate.substr(2)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardUi;
