import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { usePaymentInputs } from "react-payment-inputs";
import Navigator from "../../Navigator";
import { PaymentForm } from "./PaymentForm";
import { InitialGiveForm } from "./InitialGiveForm";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import { GiveDetails } from "./GiveDetails/GiveDetails";
import { isEmpty, expiryDateCheck } from "../../../utils";
import { months, generateArrayOfYears } from "../../../utils/constants";
import {
  setCreditCardDetails,
  setAccCheckDetails,
  setHideAmountInput,
} from "../../../store/actions";
import "./CreditCardComponent.scss";
import images from "react-payment-inputs/images";
import moment from "moment";
import { getMaskedNumber } from "../../../utils";
import { useContributionProcessEngine } from "../../../utils/customHooks/useContributionProcessEngine";
import {
  TextField,
  Switch,
  FormControlLabel,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: (props) => props.primaryColor,
    },
    "&$checked + $track": {
      backgroundColor: (props) => props.primaryColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const CreditCardComponent = ({ setPreviousPage }) => {
  const { handleSubmit, errors, control, watch } = useForm({
    mode: "onBlur",
  });
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvc, setCVC] = useState("");
  const [cardErrors, setCardErrors] = useState({});
  const [checkError, setCheckError] = useState({});
  const [showCheque, setShowCheque] = useState(false);
  const [goBackStep, setGoBackStep] = useState("");
  const [goNextStep, setGoNextStep] = useState(false);

  const orgInfo = useSelector((state) => state.orgInfo.responseData);
  const allowCheck = orgInfo?.orgGeneralSettings?.allowCheckAch;
  const creditCardInfo = useSelector((state) => state.creditCardInfo);
  const { type: paymentType } =
    useSelector((state) => state.selectedPaymentOption) || {};

  const showPaymentOptionPage = useContributionProcessEngine();
  // console.log(showPaymentOptionPage, 'showPaymentOptionPage')
  const themeColor = useThemeColor();

  useEffect(() => {
    if (
      creditCardInfo &&
      creditCardInfo.checkDetails &&
      creditCardInfo.checkDetails.isCard === false
    ) {
      setShowCheque(true);
    } else {
      setShowCheque(false);
    }
  }, [creditCardInfo.checkDetails]);

  const cardNumberValidator = ({ cardType }) => {
    if (
      cardType.displayName === "Visa" ||
      cardType.displayName === "Mastercard" ||
      cardType.type === "amex" ||
      cardType === "discover"
    ) {
      return;
    }
    return "Card must be Visa, Mastercard, Amex or Discover";
  };

  const {
    meta,
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
  } = usePaymentInputs({ cardNumberValidator });

  const dispatch = useDispatch();
  const { erroredInputs } = meta;

  const handleChangeCardNumber = (e) => setCardNumber(e.target.value);
  const handleChangeCVC = (e) => setCVC(e.target.value);

  const goBack = () => {
    if (setPreviousPage === "giveOptionForm") {
      setGoBackStep("giveOptionForm");
    } else setGoBackStep("others");
  };

  const handleMonthChange = (e) => {
    setExpiryMonth(e.target.value || null);
  };

  const handleYearChange = (e) => {
    setExpiryYear(e.target.value || null);
  };

  const onSubmit = (data) => {
    // console.log(data, "data form");
    if (!showCheque) {
      let errors = {};
      if (!cardNumber || (meta.isTouched && erroredInputs.cardNumber)) {
        errors.cardNumber = erroredInputs.cardNumber;
      }
      if (expiryMonth === "" || expiryMonth === null) {
        errors.expiryMonth = "Select Expiry Month";
      }
      if (expiryYear === "" || expiryYear === null) {
        errors.expiryYear = "Select Expiry Year";
      }
      if (!cvc) {
        errors.cvc = "Enter a CVV";
      }
      if (errors) {
        setCardErrors(errors);
      }
      if (isEmpty(errors)) {
        const maskedCreditCard = getMaskedNumber(cardNumber);
        const expirationMonth = expiryMonth;
        const expirationYear = expiryYear;
        const trimCardNum = cardNumber?.replace(/ /g, "");
        const encodedCardNum = window.btoa(trimCardNum);
        const payload = {
          orgId: orgInfo.orgId,
          cardNumber: encodedCardNum,
          isCard: true,
          expirationMonth,
          maskedCreditCard,
          expirationYear,
          cvv: cvc,
          memo: data?.memo,
          showCheque,
          nextgenCardNum: trimCardNum,
          saveCard: data?.saveCard || '0',
        };
        // console.log(payload, "payload");
        dispatch(setCreditCardDetails(payload));
        dispatch(setHideAmountInput(true));
        setGoNextStep(true);
      }
    } else {
      // const lastFourAcctDigit = data?.accountNumber?.substr(data?.accountNumber?.length - 4, data?.accountNumber?.length);
      // const maskedNum = data?.accountNumber?.substr(0, data?.accountNumber?.length - 4);
      // const maskedAcctNum = maskedNum?.replace(/\d/g, "*") + lastFourAcctDigit;
      const maskedAcctNum = getMaskedNumber(data?.accountNumber);
      let error = {};
      if (!data.accountNumber) {
        error.accountNumber = "Account Number is Required";
      }
      if (!data.routingNumber) {
        error.routingNumber = "Routing Number is Required";
      }
      if (error) {
        setCheckError(error);
      }
      if (isEmpty(error)) {
        const accNum = window.btoa(data.accountNumber);
        const payload = {
          orgId: orgInfo.orgId,
          maskedAcctNum,
          isCard: false,
        };
        const newPayload = {
          ...payload,
          accountNumber: accNum,
          nextgenAccNo: data.accountNumber,
          routingNumber: data.routingNumber,
          memo: data.memo2,
          saveCheck: data.saveCheck || undefined,
        };
        dispatch(setAccCheckDetails(newPayload));
        dispatch(setHideAmountInput(true));
        setGoNextStep(true);
      }
    }
  };

  /** This is global state of memo */
  const acctMemo = watch("memo2");
  const cardMemo = watch("memo");
  let memoColor;
  let acctColor;

  if (cardMemo?.length >= 80 && cardMemo?.length < 95) {
    memoColor = "text-warning";
  } else if (cardMemo?.length >= 95) {
    memoColor = "text-danger";
  } else {
    memoColor = "text-muted";
  }
  if (acctMemo?.length >= 80 && acctMemo?.length < 95) {
    acctColor = "text-warning";
  } else if (acctMemo?.length >= 95) {
    acctColor = "text-danger";
  } else {
    acctColor = "text-muted";
  }

  const toggleShowCheque = () => {
    setShowCheque(!showCheque);
  };

  const cardForm = () => {
    return (
      <div className="d-flex flex-column">
        <div className="position-relative mb-8">
          <input
            className="text-dark-50 form-control credit-card-input h-auto py-5 pr-21 pl-5"
            value={cardNumber}
            {...getCardNumberProps({ onChange: handleChangeCardNumber })}
            placeholder="Card Number"
          />
          <svg
            className="position-absolute ccard-img"
            {...getCardImageProps({ images })}
          />
          <p className="font-weight-bold font-size-xs m-0 text-muted">
            <span className="">Acceptable cards:</span>{" "}
            {orgInfo.allowedCards?.map((item, index) => {
              return (
                <span>
                  {item}
                  {`${
                    orgInfo.allowedCards.length === index + 1 ? "" : ","
                  }`}{" "}
                </span>
              );
            })}
          </p>
          {cardErrors.cardNumber && (
            <small className="text-danger errorText">
              {erroredInputs.cardNumber}
            </small>
          )}
        </div>
        <div className="mb-8 all-select-container d-flex justify-content-between">
          <div className="select-container">
            <TextField
              name="expiryMonth"
              label="Exp Month"
              variant="outlined"
              fullWidth
              select
              onChange={handleMonthChange}
              value={expiryMonth || ""}
            >
              {expiryYear === moment().format("YYYY")
                ? months.slice(expiryDateCheck).map((item, index) => (
                    <option key={`idx${index}`} value={item.id}>
                      {item.value}
                    </option>
                  ))
                : months.map((item, index) => (
                    <MenuItem key={`idx${index}`} value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))}
            </TextField>
            {cardErrors.expiryMonth && (
              <small className="text-danger errorText">
                {cardErrors.expiryMonth}
              </small>
            )}
          </div>
          <div className="select-container">
            <TextField
              name="expiryYear"
              label="Exp Year"
              onChange={handleYearChange}
              value={expiryYear || ""}
              variant="outlined"
              fullWidth
              select
            >
              {generateArrayOfYears().map((year, index) => (
                <MenuItem key={`idx${index}`} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
            {cardErrors.expiryYear && (
              <small className="text-danger errorText">
                {cardErrors.expiryYear}
              </small>
            )}
          </div>
        </div>
        <div className="position-relative mb-8">
          <TextField
            label="CVV"
            {...getCVCProps({ onChange: handleChangeCVC, placeholder: "CVV" })}
            value={cvc || ""}
            variant="outlined"
            fullWidth
            error={cardErrors.cvc}
            placeholder="CVV"
          />
          <p className="font-weight-bold mb-0 font-size-xs text-muted">
            3 or 4 digits usually found on the signature strip
          </p>
          {cardErrors.cvc && (
            <small className="text-danger errorText">{cardErrors.cvc}</small>
          )}
        </div>
        <div className="fv-plugins-icon-container">
          <div className="position-relative">
            <Controller
              as={
                <TextField
                  label="Memo"
                  type="text"
                  name="memo"
                  maxLength={100}
                  defaultValue={creditCardInfo?.creditCardDetails?.memo || ""}
                  fullWidth
                  rows={3}
                  multiline
                  maxlength="100"
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}
                />
              }
              name="memo"
              control={control}
              defaultValue={creditCardInfo?.creditCardDetails?.memo || ""}
            />
          </div>
          <p className={`${memoColor}`}>{`${100 -
            cardMemo?.length} characters remaining`}</p>
        </div>
      </div>
    );
  };

  const checkForm = () => {
    return (
      <>
        <div className="d-md-flex justify-content-md-between">
          <div className="fv-plugins-icon-container mr-md-3 mb-8">
            <div className="position-relative">
              <Controller
                as={
                  <TextField
                    label="Account Number"
                    name="accountNumber"
                    variant="outlined"
                    type="number"
                    fullWidth
                    error={errors.accountNumber ? true : false}
                    inputProps={{ pattern: "/^[1-9]d*$/g" }}
                  />
                }
                name="accountNumber"
                control={control}
                defaultValue={
                  creditCardInfo?.checkDetails?.accountNumber
                    ? window.atob(creditCardInfo?.checkDetails?.accountNumber)
                    : "" || ""
                }
                rules={{
                  required: true,
                }}
              />
            </div>
            {errors.accountNumber?.type === "required" && (
              <span className="text-danger errorText">
                {"Account Number is Required"}
              </span>
            )}
          </div>
          <div className="fv-plugins-icon-container ml-md-3 mb-8">
            <div className="position-relative">
              <Controller
                as={
                  <TextField
                    label="Routing Number"
                    name="routingNumber"
                    variant="outlined"
                    type="number"
                    fullWidth
                    defaultValue={
                      creditCardInfo?.checkDetails?.routingNumber || ""
                    }
                    error={errors.routingNumber ? true : false}
                  />
                }
                name="routingNumber"
                control={control}
                defaultValue={creditCardInfo?.checkDetails?.routingNumber || ""}
                rules={{
                  required: true,
                }}
              />
            </div>
            {errors.routingNumber && (
              <span className="text-danger errorText">
                {"Rounting Number is Required"}
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="fv-plugins-icon-container">
            <div className="position-relative">
              <Controller
                as={
                  <TextField
                    label="Memo"
                    type="text"
                    name="memo2"
                    maxLength={100}
                    defaultValue={creditCardInfo?.checkDetails?.memo || ""}
                    fullWidth
                    rows={3}
                    multiline
                    maxlength="100"
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                  />
                }
                name="memo2"
                control={control}
                defaultValue={creditCardInfo?.checkDetails?.memo || ""}
              />
              <p className={`${acctColor}`}>{`${100 -
                acctMemo?.length} characters remaining`}</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (showPaymentOptionPage.type === "CHECK") {
      return setShowCheque(true);
    }
    if (showPaymentOptionPage.type === "CARD") {
      return setShowCheque(false);
    }
  }, [showPaymentOptionPage.type]);

  const displayForm = () => {
    switch (showPaymentOptionPage.type) {
      case "CARD":
        // setShowCheque(false);
       return cardForm();
      case "CHECK":
        // setShowCheque(true);
        if (showCheque === false) return cardForm();
        else return checkForm();
      case "PPS":
        // setShowCheque(true);
        return cardForm();
      case "CARDANDCHECK":
        if (showCheque === false) return cardForm();
        else return checkForm();
      default:
        return;
    }
  };

  return (
    <>
      {goBackStep === "giveOptionForm" ? (
        <InitialGiveForm />
      ) : goBackStep === "others" ? (
        <PaymentForm />
      ) : null}
      {goNextStep && <GiveDetails />}

      {goBackStep === "" && !goNextStep && (
        <div className="container-md credit-card login-form login-signin activeForm-wrapper">
          <div className="text-center mb-10 mb-lg-10">
            <div>
              <span className="icon" style={themeColor.btnStyle}>
                <i className="las la-credit-card"></i>
              </span>
            </div>
            <h3 className="font-size-h3 mb-2">
              {showCheque ? "Bank Details" : "Card Details"}
            </h3>
          </div>
          <hr />
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            {(showPaymentOptionPage.type === "CARD" || showPaymentOptionPage.type === "CHECK") && (
              <FormControlLabel
                className="mb-10"
                control={
                  <PurpleSwitch
                    checked={showCheque}
                    onChange={toggleShowCheque}
                    size="small"
                    primaryColor={themeColor.color}
                  />
                }
                label={`Or Pay with ${!showCheque ? "Bank Account" : "Card"}`}
              />
            )}
            {displayForm()}
            <Navigator
              goBack={goBack}
              goNext={onSubmit}
              type="submit"
              className="py-4"
            />
          </form>
        </div>
      )}
    </>
  );
};
