import React from "react";
import Point from "./Point";
import styles from "./Prayer.module.scss";
import PrayerTable from "./PrayerTable";
import ModalComponent from "../../Common/Modal";
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import PrayerCharts from "./PrayerCharts";
import NewPrayerWalls from "./NewPrayerWalls";
import { useSelector, shallowEqual } from "react-redux";
import CustomizedMenu from "../../Common/DropDownList";
import {ButtonContainer} from '../../Common/Button'
import { Paper } from '@material-ui/core';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import {useWindowDimensions} from '../../../utils/customHooks/useWindowDimensions'

import './Prayerwall.scss'

export default function PrayerWalls({
  data,
  prayerLikeIndex,
  userId,
  type,
  wallTitle,
  requestIcon,
  top10Icon,
  myRequestIcon,
  tableActionIconsPositive,
  tableActionIconsNegative,
  chartTitle,
  categoryName
}) {
  const themeColor = useThemeColor();
  const [isOpen, setIsModal] = React.useState(false);
  const [prayer, setPrayer] = React.useState({
    newPrayer: false,
    top10: false,
    myPrayer: false,
  });
  const [isNotify, setIsNotify] = React.useState(false)

  const myPrayerData = useSelector((state) => state.myPrayerWalls?.responseData?.returnedRecords)

  const prayerTableNames = [
    {name: " ", iconName: ''},
    {name:"Date", iconName: 'fas fa-arrow-down'},
    {name: "Picture", icosnName: ''},
    {name: "Name", icosnName: ''},
    {name: "Category", iconName: 'fas fa-ellipsis-v'},
    {name: "View", iconName: ''},
    {name: "Likes", iconName: 'fas fa-arrow-down'},
    {name: "Action", iconName: ''}
  ];
  const myPrayerTableNames = [
    {name: " "},
    {name: "Date"},
    {name: "Category"},
    {name: "View"},
    {name: "Likes"},
    {name: "Action"},
  ];

  const handleNewPrayer = (name) => {
    setIsModal(true);
    if (name === "newPrayer") {
      const newPrayerObj = { newPrayer: true, top10: false, myPrayer: false };
      setPrayer(newPrayerObj);
    }
    if (name === "top10") {
      const newPrayerObj = { newPrayer: false, top10: true, myPrayer: false };
      setPrayer(newPrayerObj);
    }
    if (name === "myPrayer") {
      const newPrayerObj = { newPrayer: false, top10: false, myPrayer: true };
      setPrayer(newPrayerObj);
    }
  };

  const details = data.reduce((total, item) => {
    const { categoryName } = item;
    if (!categoryName) return total;
    if (!total[categoryName]) {
      total[categoryName] = { label: categoryName, value: 1 };
    } else {
      total[categoryName] = {
        ...total[categoryName],
        value: total[categoryName].value + 1,
      };
    }
    return total;
  }, {});

  const chartData = Object.values(details)
    .sort((a, b) => {
      return b.value - a.value;
    })
    .slice(0, 10);


  const ownedByUser = () => {
    let ownedList = [];
    data.map((item) => {
      if (item.ownedByThisUser === "1") {
        ownedList.push(item);
      }
    });
    return ownedList;
  };
  const prayerwallLike = (data) => {
    prayerLikeIndex(data);
  };
  const handleNotification = (data) => {
    setIsNotify(true)
    // toast(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
    // prayerLikeIndex(data);
  };
  const menuList = [
    {iconName: requestIcon, title: type === 'testimony'? 'New Testimony' : 'New Prayer Request', func:()=> handleNewPrayer('newPrayer')},
    {iconName: top10Icon, title: type === 'testimony'? 'Top 10 Testimonies' : 'Top 10 Prayer Request', func:()=> handleNewPrayer('top10')},
    {iconName: myRequestIcon, title: type === 'testimony'? 'My Testimony' : 'My Prayer Request', func:()=> handleNewPrayer('myPrayer')}
  ]

  let {width} = useWindowDimensions();
  return (
    <Paper className="card card-custom gutter-b px-2">
    {/* <div className="cards card-custom gutter-b px-3"> */}
      <div className="card-header">
        <div
          className="w-100 d-flex flex-row justify-content-between align-items-center"
        >
          <div className={styles.prayer_container}>
            <Point backgroundColor={themeColor.color} />
            <h5 className="px-2">{wallTitle}</h5>
          </div>
          <div className="d-md-block d-lg-none d-sm-block">
              <CustomizedMenu menuList={menuList}/>
          </div>
          <div className="d-lg-flex justify-content-end d-none">
            <div className="ml-3 mb-0">
              <ButtonContainer  backgroundColor= {themeColor.color}
                onClick={() => handleNewPrayer("newPrayer")}
              >
                <i className={`mr-2 font-size-xs ${requestIcon}`} style={{ color: "#DFEBFC" }}></i>
                <span>
                  {type === "testimony"
                    ? "New Testimony"
                    : "New prayer request"}
                </span>

              </ButtonContainer>
            </div>
            <div className="px-2">
            <ButtonContainer backgroundColor={themeColor.color}
                onClick={() => handleNewPrayer("top10")}
            >
                <i className={`mr-2 font-size-sm ${top10Icon}`} style={{ color: "#DFEBFC" }}></i>
                <span>
                  {type === "testimony"
                    ? "Top 10 Testimonies"
                    : "Top 10 prayer requests"}
                </span>
              </ButtonContainer>
            </div>
            <div className="px-2">
            <ButtonContainer  backgroundColor={themeColor.color}
                onClick={() => handleNewPrayer("myPrayer")}
            >
                <i className={`mr-2 font-size-xs ${myRequestIcon}`} style={{ color: "#DFEBFC" }}></i>
                <span>
                  {type === "testimony"
                    ? "My Testimonies"
                    : " My prayer requests"}
                </span>
              </ButtonContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-sm-0">
        <PrayerTable
          data={data}
          prayerLike={prayerwallLike}
          names={prayerTableNames}
          myrequest
          tableActionIconsNegative={tableActionIconsNegative}
          tableActionIconsPositive={tableActionIconsPositive}
          type={type}
          userId={userId}
        />
      </div>
      <ResizeableModalWithoutBtn
        className="ml-5"
        modalState={isOpen}
        title={
            type === 'prayerRequest' && prayer.newPrayer
              ? "Never doubt the power of prayer - It's time to pray!"
              : type === 'prayerRequest' && prayer.myPrayer
              ? "My Prayer Request"
              : type === "testimony" && prayer.newPrayer
              ? "Halleluyah!! - It's time to celebrate!"
              : type === "testimony" && prayer.myPrayer
              ? "My Testimonies"
              : null
        }
        maxWidth={width >= 720 ? "sm" : "xs"}
        fullWidth={true}
        resetModalState={() => setIsModal(false)}
        useType='prayerwalls'
        // titleIcon={<i className="fa fa-life-ring kt-font-primary"></i>}
        // backgroundColor={backgroundColor}
        // backgroundSecondaryColor={backgroundColor}
      >
        {prayer.newPrayer && (
          <NewPrayerWalls setIsModal={setIsModal} userId={userId} type={type} handleNotification={handleNotification} onHide={() => setIsModal(false)} setPrayer={setPrayer} prayer={prayer}/>
        )}
        {prayer.top10 && <PrayerCharts data={chartData} chartTitle={chartTitle} categoryName={categoryName}/>}
        {prayer.myPrayer && (
          <PrayerTable
            data={myPrayerData}
            names={myPrayerTableNames}
            type={type}
            userId={userId}
            prayerLike={prayerwallLike}
            setIsModal={setIsModal}
          />
        )}
      </ResizeableModalWithoutBtn>
      {/* <ModalComponent
        show={isOpen}
        onHide={() => setIsModal(false)}
        title={
          type === 'prayerRequest' && prayer.newPrayer
            ? "Never doubt the power of prayer - It's time to pray!"
            : type === 'prayerRequest' && prayer.myPrayer
            ? "My Prayer Request"
            : type === "testimony" && prayer.newPrayer
            ? "Halleluyah!! - It's time to celebrate!"
            : type === "testimony" && prayer.myPrayer
            ? "My Testimonies"
            : null
        }
        backgroundColor={themeColor.color}
        hideFooter={type === 'prayerRequest' && prayer.newPrayer ? false : type === "testimony" && prayer.newPrayer ? false : true}
      >
        {prayer.newPrayer && (
          <NewPrayerWalls setIsModal={setIsModal} userId={userId} type={type} handleNotification={handleNotification} onHide={() => setIsModal(false)} setPrayer={setPrayer} prayer={prayer}/>
        )}
        {prayer.top10 && <PrayerCharts data={chartData} chartTitle={chartTitle} categoryName={categoryName}/>}
        {prayer.myPrayer && (
          <PrayerTable
            data={myPrayerData}
            names={myPrayerTableNames}
            type={type}
            userId={userId}
            prayerLike={prayerwallLike}
            setIsModal={setIsModal}
          />
        )}
      </ModalComponent> */}
    {/* </div> */}
    </Paper>
  );
}