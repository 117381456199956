import React from 'react'

const iconStyle = {
  backgroundColor: 'transparent'
}
const userData = JSON.parse(localStorage.getItem('user'))
const ImageUpload = ({ onChange, cropData, defaultImage }) => {

  const preview = cropData !== "#" ? `url(${cropData})`: `url(${defaultImage})`
  return (
    <div className="image-input image-input-outline image-input-circle" id="kt_image_3">
 <div className="image-input-wrapper" style={{'background-image': `${preview}`}}></div>

 <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
  <span className="fa fa-pen icon-sm text-muted" style={iconStyle}></span>
  <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" onChange={onChange} />
  <input type="hidden" name="profile_avatar_remove"/>
 </label>

 <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
  <i className="ki ki-bold-close icon-xs text-muted"></i>
 </span>
</div>
  )
}

export default ImageUpload
