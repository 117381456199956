import { useSelector } from 'react-redux';

export const useThemeColor = () => {
  const orgInfo = useSelector(state => state.orgInfo.responseData);
	const getThemeChangeData = useSelector(state => state.getThemeChangeData)
  const lightTheme = orgInfo?.colorThemes[0]?.primary;
  const lightSecondaryTheme = orgInfo?.colorThemes[0]?.secondary;
  const darkTheme = orgInfo?.colorThemes[1]?.primary
  const darkSecondaryTheme = orgInfo?.colorThemes[1]?.secondary;
  const themeColor = getThemeChangeData?.theme ? darkTheme : lightTheme;
  const themeSecondaryColor = getThemeChangeData?.theme ? darkSecondaryTheme : lightSecondaryTheme;
  const themeTextColor = getThemeChangeData?.theme ? 'white' : 'black' ;
  const iconColor = getThemeChangeData?.theme ? '#eeeeee' : '#2d2d2d'
  const isDarkMode = getThemeChangeData?.theme;

  return {
    color: themeColor,
    lightTheme,
    darkTheme,
    btnStyle: {
      backgroundColor: themeColor,
      border: 'none'
    },
    secondaryColor: themeSecondaryColor,
    textColor: themeTextColor,
    iconColor,
    isDarkMode
  };
}