import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import styles from "./Prayer.module.scss";
import PrayerCharts from "./PrayerCharts";
import PrayerWalls from "./PrayerWalls";
import { prayerWalls, myPrayerWalls } from "../../../store/actions/userDashboardActions/PrayerWall";
import { getUserDetails } from "../../../utils/authHelpers";
import PageLoader from "../../Loader/PageLoader";
import './Prayerwall.scss'


export const PrayerWall = () => {
  const [data, setData] = React.useState([]);
  const [prayerLike, setPrayerLike] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const prayerWallData = useSelector(
    (state) => state.prayerWalls?.responseData?.returnedRecords,
    shallowEqual
  );
  const prayerWallLoading = useSelector(
    (state) => state.prayerWalls?.loading,
    shallowEqual
  );
  const addPrayer = useSelector(
    (state) => state.addPrayer,
    shallowEqual
  );
  let dispatch = useDispatch();
  React.useEffect(() => {
    const user = getUserDetails();
    dispatch(prayerWalls({orgId: orgInfo.orgId, userId: user.userId,fellowshipType: "prayerRequest",  mineOnlySwitch: "false"}))
    dispatch(myPrayerWalls({orgId: orgInfo.orgId, userId: user.userId,fellowshipType: "prayerRequest",  mineOnlySwitch: "true"}))
  }, [addPrayer]);

  const prayerLikeIndex = (data) => {
    setPrayerLike(data);
    // console.log(data, 'data');
  };
  if (prayerWallLoading) {
    return <PageLoader />;
  }
  return (
    <>
      {/* <h6 className="mt-2">Prayer Wall - It's time to pray!</h6> */}
      {/* <div className={styles.container}> */}
      {prayerWallData && <PrayerWalls
        data={prayerWallData}
        prayerLikeIndex={prayerLikeIndex}
        userId={getUserDetails()}
        wallTitle="Prayer Wall"
        requestIcon="fa fa-pray"
        top10Icon="fa fa-praying-hands"
        myRequestIcon="fas fa-clipboard"
        tableActionIconsPositive="fa fa-thumbs-up"
        tableActionIconsNegative="fa fa-pray"
        chartTitle="Top 10 Prayer Request"
        categoryName="Prayer Category"
        type='prayerRequest'
      />}
      {/* <PrayerCharts /> */}
      {/* </div> */}
    </>
  );
};