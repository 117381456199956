import React from 'react'
import './index.scss'
import { Link } from "react-router-dom";
import Background from './errorImage.jpg'

export default function index() {
    return (
        <div className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center" style={{backgroundImage: `url(${Background})`}}>
            <div className="d-flex flex-column flex-row-fluid text-center">
                <h1 className="error-title font-weight-boldest text-white mb-12" style={{marginTop: '4rem'}}>
                    Oops...
                </h1>
                <p className="display-4 font-weight-bold text-white px-1 pb-3">
                    Looks like something went wrong.We're working on it
                </p>
                <Link to="/">
                    <button type="button" className="btn btn-secondary fw-bold">Go To Home</button>
                </Link>
            </div>
        </div>
    )
}