import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Card from '@material-ui/core/Card';
import f2 from "../../../_metronic/_assets/f2.png";
import defaultMale from "../../../_metronic/_assets/defaultMale.png";
import ConfirmationDialog from "../../Common/ConfirmDialog";
import { deleteAChild } from "../../../store/actions/";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import NotificationMesage from "../../Common/NotificationMesage";
import {ButtonContainer} from '../../Common/Button'

import "./ChildrenInfo.scss";

export const ChildrenInfo = ({ item, handleEditData, redirectToFamily }) => {
  let ageSuffix;
  let age = moment().diff(moment(`${item.childBirthday}`, "YYYY-MM-DD"), 'years');

  if(age === 0 || age === 1) {
    ageSuffix = `${'yr'}`;
  } else {
    ageSuffix = `${'yrs'}`;
  }
   const userData = JSON.parse(localStorage.getItem('user'))
    const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);

    const backgroundColor = orgInfo?.colorThemes[0]?.primary;

  const [show, setShow] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [, forceUpdate] = useState();
  const [isLoading, setIsLoading] = useState(false)

  const handleDialogClose = () => {
    setShow(false);
  };

  const handleDeleteUser = (user) => {
    setShow(true);
    setCurrentId(user);
  };

  const handleEdit = (item) => {
    handleEditData(item)
  }

    const handleConfirmationResponse = () => {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("orgId", orgInfo.orgId);
      formData.append("userId", userData.responseData.userId);
      formData.append("action", "delete");
      formData.append("childUserId", currentId);
      deleteAChild(formData).then((result) => {
        setIsLoading(false)
        if(result.responseCode === '00'){
          redirectToFamily()
          toast.success(<NotificationMesage message={result.responseMessage} responseStatus={result.responseCode} />)
          handleDialogClose();
        } else {
          toast.error(<NotificationMesage message={result.responseMessage} responseStatus={result.responseCode} />)
        }
        // window.location.reload()
        // forceUpdate()
      });
    };
  return (
    <>
    {/* <Card className="card shadow-sm my-6" style={{borderRadius: '20px'}}> */}
    <Card elevation={3} className="my-6 children-container" style={{borderRadius: '20px'}}>
      <div className="row w-100 m-0">
        <div className="col-sm-5 p-0 text-center">
          <img
              src={
               item.childPicture
              }
              alt={item.childFirstName}
              className="user-image-holder img-fluid"
            />
          </div>
          <div className="col-12 col-sm-7 p-10">
            <p className="h5 pt-5 pt-md-0 pl-md-0">
              {item.childFirstName} {item.childMiddleName} {item.childLastName}
            </p>
            
              <div className=" py-2">
                <span
                  className="fa fa-user-clock mr-1"
                  style={{ color: item.favoriteColor }}
                ></span>{" "}
                Age:{" "}
                {moment().diff(
                  moment(`${item.childBirthday}`, "YYYY-MM-DD"),
                  "years"
                )}{" "}
                {ageSuffix}
              </div>
              <div className=" py-2 ">
                <span
                  className="fa fa-universal-access mr-1"
                  style={{ color: item.favoriteColor }}
                ></span>{" "}
                Gender: {item.childGender}
              </div>
              <div className=" py-2">
                <span
                  className="fa fa-calendar-day mr-1"
                  style={{ color: item.favoriteColor }}
                ></span>{" "}
                Date of Birth:{" "}
                {moment(`${item.childBirthday}`).format("MMM D, YYYY")}
              </div>
              <div className=" py-2">
                <span
                  className="fas fa-cookie mr-1"
                  style={{ color: item.favoriteColor }}
                ></span>{" "}
                Food Allergy:{" "}
                {item.foodAllergies}
              </div>
              <div className=" py-2">
                <span
                  className="fas fa-palette mr-1"
                  style={{ color: item.favoriteColor }}
                ></span>{" "}
                Favorite Color:{" "}
                {item.favoriteColor ?? ''}
              </div>
              <div className=" py-2">
                <span
                  className="fas fa-graduation-cap mr-1"
                  style={{ color: item.favoriteColor }}
                ></span>{" "}
                School Name:{" "}
                {item.schoolName ?? 'N/A'}
              </div>
              
              <div className=" pt-md-10 pt-6 d-flex justify-content-between child-btn">
                <ButtonContainer backgroundColor={backgroundColor} onClick={() => handleEdit(item)}>
                <span className="fas fa-edit font-size-sm mr-3"></span>
                  Edit
                </ButtonContainer>
                <ButtonContainer
                  to="#"
                  onClick={() => handleDeleteUser(`${item.childUserId}`)}
                  backgroundColor="#F64E60"
                >
                  <span className="fa fa-trash-alt font-size-sm mr-3"></span>
                  Delete
                </ButtonContainer>
              </div>
            
          </div>
        </div>
    </Card>
   {show && (
          <ConfirmationDialog
            open={show}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={handleConfirmationResponse}
            title="Confirm"
            text="Are you sure you want to DELETE this child's profile? Please note that you CANNOT undo this action once it is performed. Click Yes to continue or click No to abort."
            isLoading={isLoading}
          />
        )}
        
    </>
  );
};
