import React, { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { TextField, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RSVPVerification } from './RSVPVerifcation';
import { CustomSwitch } from '../../Common/CustomSwitch';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { checkInListingsStatus, initiateRSVP, rsvpListingsStatus } from '../../../store/actions'
import 'react-phone-number-input/style.css';
import '../form.css';
import "./rsvp.scss";
import { FutureEventCard } from './CheckinCard';
import RsvpUpcomingCard from './RsvpUpcomingCard';
import PageLoader from '../../Loader/PageLoader';


const initialValues = {
  email: '',
  password: ''
};

const ReserveASeat = () => {
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('')
  const [rsvpPayload, setRsvpPayload] = useState({})
  const [isRSVPInitialized, setIsRSVPInitialized] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [mobileError, setMobileError] = useState('');
  const [emptyInputError, setEmptyInputError] = useState('');
  const [selectionError, setSelectionError] = useState('');
  const [toggleMobile, setToggleMobile] = useState('');
  const [pageUrl, setPageUrl] = useState('');
  const themeColor = useThemeColor();
  // Global variables
  const ipData = useSelector(state => state.ipData);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const checkInListingsStatusData = useSelector((state) => state.checkInListingsStatus.responseData)
  const checkInListingsStatusLoading = useSelector((state) => state.checkInListingsStatus)
  const initiateRSVPData = useSelector(state => state.initiateRSVPData, shallowEqual);
  const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.colorThemes[0].primary : '' }

  // console.log(checkInListingsStatusLoading, 'initiateRSVPData')
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleRadioChange = (e) => {
    setToggleMobile(e.target.value);
    setSelectionError(null)
  }

  const handleRsvp = () => {
    setIsRSVPInitialized(true)
  }

  useEffect(() => {
    if(isEmail(email)){
      setEmailError('');
    }
  }, [email])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (pageUrl === 'checkin') {
    //   return console.log(pageUrl);
    // }
    const payload = {
      orgId: orgInfo.orgId,
      typeRsvpOrCheckIn: "rsvp",
    }
    if(toggleMobile === '') return setSelectionError('select email or mobile to continue');
    if(!isEmail(email) && !mobile) return setEmailError('invalid email');
    if(!email && !mobile) return setEmptyInputError('email or mobile cannot be empty');
    if(toggleMobile === 'email' && email) {
      payload.email = email;
      payload.typeEmailOrMobile = "email";
      payload.mobile = '';
      const newPaylaod = {...payload};
      newPaylaod.typeRsvpOrCheckIn = 'checkin'
      pageUrl === 'checkin' ? setRsvpPayload(newPaylaod) : setRsvpPayload(payload)
      return pageUrl === 'checkin' ?  dispatch(initiateRSVP(newPaylaod, handleRsvp)) : dispatch(initiateRSVP(payload, handleRsvp))
    }
    if(toggleMobile === 'mobile' && mobile) {
      payload.email = '';
      payload.typeEmailOrMobile = 'mobile';
      payload.mobile = mobile;
      const newPaylaod = {...payload};
      newPaylaod.typeRsvpOrCheckIn = 'checkin'
      setRsvpPayload(payload)
      pageUrl === 'checkin' ? setRsvpPayload(newPaylaod) : setRsvpPayload(payload)
      return pageUrl === 'checkin' ?  dispatch(initiateRSVP(newPaylaod, handleRsvp)) : dispatch(initiateRSVP(payload, handleRsvp))
    }
  }

  useEffect(() => {
    if(initiateRSVPData?.responseCode === '00'){
      setIsRSVPInitialized(true);
    }
  }, [initiateRSVPData?.responseCode])

  useEffect(() => {
    // console.log(window.location.pathname.substr(1), 'rue');
    setPageUrl(window.location.pathname.substr(1))
    setIsRSVPInitialized(false)
  }, [window.location.pathname])

  useEffect(() => {
    if (pageUrl === 'checkin') {
      dispatch(checkInListingsStatus({
        orgId: orgInfo.orgId
      }))
    }
    if (pageUrl === 'rsvp') {
      dispatch(rsvpListingsStatus({ orgId: orgInfo.orgId}))
    }
  }, [pageUrl])
  
  if (checkInListingsStatusLoading?.loading) {
    return <PageLoader />
  }
  return (
    <>
    {isRSVPInitialized ? <RSVPVerification rsvpInitialPayload={rsvpPayload} setIsRSVPInitialized={setIsRSVPInitialized}/> :
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-10">
        <div>
          <span className="icon" style={primaryBgColor}>
            <i className={pageUrl === 'checkin' ? 'las la-user-check': "las la-bookmark"}></i>
          </span>
        </div>
        <h3 className="font-size-h1 mb-5">{pageUrl === 'checkin' ? "Check In" : "Reserve A Seat"}</h3>
        {/* <p className="text-muted font-weight-bold">{`Enter your ${toggleMobile ? 'mobile' : 'email'} and we'll send you a verification code`}</p> */}
      </div>
      <hr />
      {/* end::Head */}

      {/*begin::Form*/}
      {checkInListingsStatusData?.currentEventsList.length === 0 ? (<p className='d-flex justify-content-center pb-3' style={{fontSize: '18px'}}>{checkInListingsStatusData?.messageToShowIfNoCurrentEvent}</p>) : 
      <>
      <h5>Choose a verification option to {`${pageUrl === 'checkin' ? 'Check In' :  'reserve a seat'}`}</h5>
      <div className="mt-8">
        <RadioGroup row onChange={handleRadioChange}>
          <FormControlLabel
            value='email'
            className="mb-0"
            control={<Radio />}
            label="Email"
          />   
          <div className='px-7'></div>
          <FormControlLabel
            value='mobile'
            className="mb-0"
            control={<Radio />}
            label="Mobile"
          />
        </RadioGroup>
        {selectionError &&
          <div className="fv-plugins-message-container mt-0">
            <div className="fv-help-block">{selectionError}</div>
          </div>}
      </div>
      <form
        // onSubmit={formik.handleSubmit({toggleMobile})}
        className="form fv-plugins-bootstrap fv-plugins-framework mt-5">
        <div className="form-group fv-plugins-icon-container mb-10">
          {toggleMobile  === 'mobile' && (
            <PhoneInput
              international
              withCountryCallingCode
              countryCallingCodeEditable={false}
              defaultCountry={ipData ? ipData.country_code : null}
              placeholder="Mobile"
              id="loginMobile"
              value={mobile}
              onChange={setMobile}
              className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${(mobile && !isValidPhoneNumber(mobile)) ? 'is-invalid' : (mobile && isValidPhoneNumber(mobile)) ? 'is-valid' : ''}`}
              name="mobile"
              error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid mobile number') : 'Mobile number is required'}
            />
          )}
          {toggleMobile === 'email' && (
            <div className="position-relative">
              <TextField
                label="Email"
                type="email"
                value={email}
                // className={` ${getInputClasses(
                //   'email'
                // )}`}
                onChange={handleChange}
                name="email"
                variant="outlined"
                fullWidth
                error={!!emailError}
                // {...formik.getFieldProps('email')}
              />
              {/* <i className="fas fa-user email-icon position-absolute"></i> */}
            </div>
          )}
          {((toggleMobile === 'email' && emailError) || emptyInputError ) ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block errorText">{emailError}</div>
            </div>
          ) : null}
          {((toggleMobile === 'mobile' && mobile && !isValidPhoneNumber(mobile)) || mobileError || emptyInputError) ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{(mobile ? (isValidPhoneNumber(mobile) ? setMobileError('') : 'Invalid mobile number') : 'Mobile number is required') || mobileError}</div>
            </div>
          ) : null}
        </div>
        <button
          id="kt_login_signin_submit"
          type="button"
          style={primaryBgColor}
          onClick={handleSubmit}
          disabled={initiateRSVPData?.loading}
          className={`btn w-100 font-weight-bold text-white py-4 px-9 mb-5`}>
          <span>Send Code</span>
          {initiateRSVPData?.loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
      </form>
      </>}
      {pageUrl === 'checkin' && checkInListingsStatusData?.currentEventsList.length === 0 && checkInListingsStatusData?.futureEventsList.length > 0 && (
        <div className='py-4'>
          <h4 className='d-flex justify-content-center'>Upcoming Events</h4>
          {checkInListingsStatusData?.futureEventsList?.map((item) =><div className='py-4'> <FutureEventCard item={item} key={item.id} /> </div>)}
        </div>
      )}
      {pageUrl === 'rsvp' && checkInListingsStatusData?.currentEventsList.length === 0 && checkInListingsStatusData?.futureEventsList.length > 0 && (
        <div className='py-4'>
          <h4 className='d-flex justify-content-center'>Upcoming Events</h4>
          {checkInListingsStatusData?.futureEventsList?.map((item) =><div className='py-4'> <RsvpUpcomingCard item={item} key={item.id} /> </div>)}
        </div>
      )}
      {/*end::Form*/}
    </div>}
    </>
  );
}

export default ReserveASeat;