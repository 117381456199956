import React from "react";
import FollowupHome from "../../components/Followup/FollowupHome";
import { Home } from "./Home";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

export default function FollowupPage() {
  const siteKey = process.env.REACT_APP_RECAPTCHA
  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
    <Home>
        <FollowupHome />
    </Home>
    </GoogleReCaptchaProvider>
  );
}
