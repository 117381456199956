import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import './DarkToggle.scss';


export const DarkToggle = ({ checked, onChange}) => {
  return (
    <Toggle
      className="DarkToggle"
      checked={checked}
      onChange={onChange}
      icons={{ unchecked: "🌙", checked: "🔆" }}
      aria-label="Dark mode"
    />
  );
};
