import React from 'react'
import { Button, CircularProgress, Icon } from "@material-ui/core";
import { useTheme } from '@material-ui/styles';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';

const UploadBtn = ({
  btnLabel = "Upload Image",
  disabled,
  loading,
  onChange,
  accept = ".png, .jpg, .jpeg",
  id = "upload-single-file2",
  multiple = false,
  className = "",
  variant='outlined',
}) => {
  const onInputClick = (event) => {
    event.target.value = "";
  };
  const theme = useThemeColor()

  return (
    <div className="d-flex items-center mb-1">
      <div className=" mr-5">
        <label htmlFor={id}>
          <Button
            className={`capitalize ${className}`}
            component="span"
            variant={variant}
            disabled={disabled}
            style={{color: theme.color}}
          >
            <div className="d-flex text-center">
              <Icon className="pr-8">cloud_upload</Icon>
              <span>{btnLabel}</span>
            </div>
          </Button>
        </label>
        <input
          className="d-none"
          onChange={onChange}
          id={id}
          type="file"
          disabled={disabled}
          accept={accept}
          onClick={onInputClick}
        />
      </div>
      <div className="d-flex text-center">
        {loading && (
          <>
            <CircularProgress size={20} className="mr-2" />
            <p>Processing...</p>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadBtn;
