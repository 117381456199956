import React from 'react';
import {Home} from './Home';
import VideoPlayer from '../../components/Sermon/VideoPlayer'
import { Helmet } from "react-helmet";
import { useSelector, shallowEqual } from "react-redux";

export const LivePage = () => {
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  return (
    <Home>
      {secondOrgInfo && (
        <Helmet>
          <title>{secondOrgInfo.metaData.liveStream.title}</title>
          <meta
            name={secondOrgInfo.metaData.liveStream.author}
            content={secondOrgInfo.metaData.liveStream.description}
          />
          <meta name='keywrods' content={secondOrgInfo.metaData.liveStream.keywords.toString()} />
        </Helmet>
      )}
      <div
        style={{
          // border: 'solid 5px red',
          marginBottom: '4rem',
          //   height: '100vh',
          position: 'relative',
          top: '4rem',
        }}
      >
        <VideoPlayer />
      </div>
    </Home>
  );
};

// export default SermonPage;
