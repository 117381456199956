import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

// eslint-disable-next-line react/prop-types
export default ({ row, onGet, size }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null);

  const hiddenNames = ["Date", "Source", "Transaction ID", "Mode"];

  const handleClick = (event) => {
    deleteRow();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteRow = () => {
    if (onGet) {
      onGet(row);
      setSelected(row);
    }
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {selected && (
          <div className="py-2 px-4 text-start">
            <h5>Date</h5>
            <p>{moment(selected?.date).format("ddd, MMM Do, YYYY")}</p>
            <hr />
            <h5>Source</h5>
            <p>{selected?.source}</p>
            <hr />
            {selected?.memo?.length > 0 && <><h5>Memo</h5>
            <p>{selected?.memo}</p>
            <hr /> </>}
            <h5>Transaction Id</h5>
            <p>{selected?.transactionId}</p>
            <hr />
            <h5>Payment Mode</h5>
            <p style={{ textTransform: "capitalize" }}>
              {selected?.paymentMode}
            </p>
          </div>
        )}
      </Menu>
    </div>
  );
};
