import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PinInputComponent from '../../Common/PinInput'
import { resetEndpointDataAction, verifyRSVP } from '../../../store/actions'
import { smallLoader } from '../../Loader/smallLoader'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';

export const RSVPVerification = ({rsvpInitialPayload, setIsRSVPInitialized}) => {
  const [inputValue, setInputValue] = useState('')
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const initiateRSVPData = useSelector(state => state.initiateRSVPData, shallowEqual);
  const verifyRSVPData = useSelector(state => state.verifyRSVPData);
  const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.colorThemes[0].primary : '' }
  let themeColor = useThemeColor();
  const dispatch = useDispatch();
  const [pageUrl, setPageUrl] = useState('');
  let history = useHistory();
  const { orgId, typeEmailOrMobile, email, mobile, typeRsvpOrCheckIn } = rsvpInitialPayload;
  
  const handleVerifyRSVP = (input) => {
    const payload = {
      orgId,
      typeEmailOrMobile,
      email,
      mobile,
      typeRsvpOrCheckIn,
      serviceUrl: `${window.location.origin}/a/${typeRsvpOrCheckIn}/main`,
      verificationCode: input,
    }
    dispatch(verifyRSVP(payload));
  }

  useEffect(() => {
    if(verifyRSVPData?.responseCode === '00' && !verifyRSVPData.loading){
      const urlWithoutDomain = verifyRSVPData?.responseData?.serviceUrlFull.split('/a')[1]
      history.push(urlWithoutDomain);
    }
    if(verifyRSVPData?.responseCode === '00' && verifyRSVPData?.responseData?.nextAction === 'signup') {
      dispatch(resetEndpointDataAction)
      history.push('/signup', {from: {
        pathname:'/rsvp'}})
    }
  }, [verifyRSVPData?.responseCode])

  useEffect(() => {
    setPageUrl(window.location.pathname.substr(1))
  }, [window.location.pathname])

  const handleResendCode = () => {
    setIsRSVPInitialized(false)
  }
  return (
    <div>
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-10">
            <div>
              <span className="icon" style={primaryBgColor}>
                <i className="las la-user"></i>
              </span>
            </div>
            <h3 className="font-size-h1 mb-5">{pageUrl === 'checkin' ? "Check In" : "Reserve A Seat"}</h3>
            {/* <p className="text-muted font-weight-bold">{`Enter your ${toggleMobile ? 'mobile' : 'email'} and we'll send you a verification code`}</p> */}
          </div>
          <hr />
          {/* end::Head */}

          {/*begin::Form*/}
          <Alert variant="outlined" severity="info">{initiateRSVPData?.responseMessage}</Alert>
          {verifyRSVPData.loading ? 
            <div className="w-100 mx-auto my-10 text-center">
              <span className={`ml-3 font-size-lg spinner ${themeColor.isDarkMode ? 'spinner-white' : 'spanner-dark'}`}></span>
            </div> :
            <PinInputComponent setInputValue={handleVerifyRSVP} />}
          {/*end::Form*/}
          <div className='d-flex justify-content-center cursor' onClick={handleResendCode}>
          <p style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}}>Resend Code</p>
          </div>
        </div>
    </div>
  )
}