import React, {useEffect} from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import { getFormQuestionAndAnswer, submitFormAnswerForUser } from '../../../store/actions/userDashboardActions/forms'
import moment from 'moment';
import { getUserDetails } from '../../../utils/authHelpers';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SubmittedForms() {
    const dispatch = useDispatch()
    const themeColor = useThemeColor();
    const history = useHistory()
    const {id} = useParams()
    
    const [viewForm, setViewForm] = useState({
        show: false,
        item: {}
    })
    const [displayForm, setDisplayForm] = React.useState([])
    
    const orgInfo = useSelector(
        (state) => state.orgInfo.responseData,
        shallowEqual
    );
    const lightTheme = orgInfo?.colorThemes[0]?.primary;
    const darkTheme = orgInfo?.colorThemes[1]?.primary;
    const getThemeChangeData = useSelector(state => state.getThemeChangeData, shallowEqual);
    const backgroundColor = getThemeChangeData?.theme ? darkTheme : lightTheme;

    const organizationFormsData = useSelector(
        (state) => state.getOrganizationForms.data,
        shallowEqual
    );

    
    let user = getUserDetails()
    
    useEffect(() => {
      if (id) {
        dispatch(submitFormAnswerForUser(id))
      }
    }, [id])
    
    useEffect(() => {
      if (organizationFormsData && organizationFormsData[0]?.content) {
        if (typeof organizationFormsData[0]?.content === 'string') {
          setDisplayForm(JSON.parse(organizationFormsData[0]?.content))
        } else if (typeof organizationFormsData[0]?.content === 'object') {
          setDisplayForm(organizationFormsData[0]?.content)
        }
        console.log( JSON.parse(organizationFormsData[0]?.content), 'form data111');
      }
    }, [organizationFormsData])
    

    const handleGoBack = () => {
      history.push('/user/forms')
    }
    
  return (
    <div>
      {organizationFormsData?.length === 0 && <div><h4 style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '2rem'}}>No submission yet for this form</h4></div>}
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{width: '50%', paddingTop: '2rem'}}>
          {displayForm.length > 0 && displayForm.map((item, index) => (
            <TextField
              key={index}
              className='mb-4'
              fullWidth
              label={item.question}
              variant='outlined'
              value={item.answer}
              inputProps={{
                readonly: true
              }}
              size='small'
            />
          ))}

        <Button
          onClick={handleGoBack}
          variant="contained"
          type="submit"
          backgroundColor={backgroundColor}
          style={{textTransform: 'capitalize', background: themeColor.color, color: '#fff'}}
        >Back</Button>
        </div>
      </div>
    </div>
  )
}


const names = ['', 'Name', 'Description', 'Date Submitted', '']
