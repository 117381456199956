import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead
  } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendCommunicationToSpecialGroupProcess } from "../../../store/actions/userDashboardActions/serviceUnit";
import CustomButton from "../../Common/CustomButton";
import { ResizeableModalWithoutBtn } from "../../Common/ResizeableModal";

  
  
  export default function SuccessPage({setIsClose, isClose, width, analyzeData}) {
    // global state
    const sendCommunicationChildrenData = useSelector(
      (state) => state.sendCommunicationChildren.responseData
    );
  
    const classes = useStyles();
    let history = useHistory()
    let dispatch = useDispatch()
    const handleClose = () => {
        setIsClose(false)
      // history.push('/dashboard')
    }
    const handleApprove = () => {
      dispatch(sendCommunicationToSpecialGroupProcess(analyzeData, handleClose))
    }
    return (
        <ResizeableModalWithoutBtn
        modalState={isClose}
        title="Confirm Details"
        resetModalState={() => setIsClose(false)}
        maxWidth={width}
      >
        <div className="px-3">
        <Table>
            <TableBody>
            <TableRow>
              <TableCell>Billable</TableCell>
              <TableCell>
                {sendCommunicationChildrenData?.isBilliable === 'true' ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Credit Balance</TableCell>
              <TableCell>
                {sendCommunicationChildrenData?.messagingCreditBalance}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Message Credit Cost</TableCell>
              <TableCell>
                {sendCommunicationChildrenData?.messagingCreditCost}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Valid Recipients</TableCell>
              <TableCell>
                {sendCommunicationChildrenData?.validRecipientsCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Invalid Recipients</TableCell>
              <TableCell>
                {sendCommunicationChildrenData?.invalidRecipientsCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Duplicate Recipients</TableCell>
              <TableCell>
                {sendCommunicationChildrenData?.duplicateRecipientsCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Do Not Disturb Recipients</TableCell>
              <TableCell>
                {sendCommunicationChildrenData?.doNotDisturbRecipientsCount}
              </TableCell>
            </TableRow>
            </TableBody>
          </Table>
          {sendCommunicationChildrenData?.validRecipients?.length > 0 && (
            <div className="py-4">
                <p >Valid Recipients ({sendCommunicationChildrenData?.validRecipients?.length})</p>
              <Table>
              <TableHead>
                <TableRow>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
              </TableRow>
                </TableHead>
                <TableBody>
                {sendCommunicationChildrenData?.validRecipients?.map(
                  (item, id) => (
                    <TableRow key={id}>
                      <TableCell>
                        {item?.mobile}
                      </TableCell>
                      <TableCell>{item?.email} </TableCell>
                      <TableCell>
                        {item?.firstName}
                      </TableCell>
                      <TableCell>
                      {item?.lastName}
                      </TableCell>
                    </TableRow>
                  )
                )}
                </TableBody>
              </Table>
            </div>
          )}
          {sendCommunicationChildrenData?.duplicateRecipients?.length > 0 && (
            <div className="py-3">
                <p >Duplicate Recipients ({sendCommunicationChildrenData?.duplicateRecipients?.length})</p>
              <Table>
                <TableHead>
                <TableRow>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
              </TableRow>
                </TableHead>
                <TableBody>
                {sendCommunicationChildrenData?.duplicateRecipients?.map(
                  (item, id) => (
                    <TableRow key={id}>
                      <TableCell>
                        {item?.mobile}
                      </TableCell>
                      <TableCell>
                        {item?.email}
                      </TableCell>
                      <TableCell>
                        {item?.firstName}
                      </TableCell>
                      <TableCell>
                       {item?.lastName}
                      </TableCell>
                    </TableRow>
                  )
                )}
                </TableBody>
              </Table>
            </div>
          )}
          {sendCommunicationChildrenData?.invalidRecipients?.length > 0 && (
            <div className="py-3">
                <p >Invalid Recipients ({sendCommunicationChildrenData?.invalidRecipients?.length})</p>
              <Table>
              <TableHead>
                <TableRow>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
              </TableRow>
                </TableHead>
                <TableBody>
                {sendCommunicationChildrenData?.invalidRecipients?.map(
                  (item, id) => (
                    <TableRow key={id}>
                      <TableCell>
                        {item?.mobile}
                      </TableCell>
                      <TableCell>
                        {item?.email}
                      </TableCell>
                      <TableCell>
                        {item?.firstName}
                      </TableCell>
                      <TableCell>
                       {item?.lastName}
                      </TableCell>
                    </TableRow>
                  )
                )}
                </TableBody>
              </Table>
            </div>
          )}
          {sendCommunicationChildrenData?.doNotDisturbRecipients?.length > 0 && (
            <div className="py-3">
                <p >Do Not Disturb Recipients ({sendCommunicationChildrenData?.doNotDisturbRecipients?.length})</p>
              <Table>
              <TableHead>
                <TableRow>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
              </TableRow>
                </TableHead>
                <TableBody>
                {sendCommunicationChildrenData?.doNotDisturbRecipients?.map(
                  (item, id) => (
                    <TableRow key={id}>
                     <TableCell>
                        {item?.mobile}
                      </TableCell>
                      <TableCell>
                        {item?.email}
                      </TableCell>
                      <TableCell>
                        {item?.firstName}
                      </TableCell>
                      <TableCell>
                       {item?.lastName}
                      </TableCell>
                    </TableRow>
                  )
                )}
                </TableBody>
              </Table>
            </div>
          )}
          <div
            className="d-flex justify-content-between mt-3 pb-3"
            style={{ columnGap: "1rem" }}
          >
            <React.Fragment>
              <CustomButton
                label="Approve"
                variant="contained"
                className="w-full"
                color="primary"
                type="submit"
                onClick={handleApprove}
              />
              <CustomButton
                label="Cancel"
                variant="outlined"
                className="w-full"
                onClick={() => setIsClose(false)}
              />
            </React.Fragment>
          </div>
        </div>
      </ResizeableModalWithoutBtn>
    );
  }
  
  const useStyles = makeStyles(() => ({
    cardContainer: {
      margin: 'auto',
      width: '50%',
      paddingTop: '2rem'
    },
    root: {
      //   minWidth: 350
      margin: 'auto'
    }
  }));
  