import React from "react";
import moment from "moment";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import ConfirmDialog from "../../Common/ConfirmDialog";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  prayerWallsLikeOrPray,
  markedAnsweredPrayer,
  archivePrayer,
  prayerWalls,
  prayerWallsWithoutLoader,
  myPrayerWalls,
} from "../../../store/actions/userDashboardActions/PrayerWall";
import ModalComponent from "../../Common/Modal";
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import Empty from "../../Common/Empty";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import NotificationMesage from "../../Common/NotificationMesage";
import styles from "./Prayer.module.scss";
import styled from "styled-components";
import screenSize from "../../Common/screenSize";
import SmallTable from "./SmallTable";
import ViewUserImage from "./ViewUserImage";
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { Media, Player } from "react-media-player";
import AudioPlayer from './Media/AudioPlayer'
import { Divider } from "@material-ui/core";

const IconComponent = styled.i`
  color: #fff;
  padding: 0px 5px;
  cursor: pointer;
  &: hover {
    color: #111;
  }
`;

export default function PrayerTable({
  data,
  prayerLike,
  names,
  myrequest,
  tableActionIconsPositive,
  tableActionIconsNegative,
  type,
  userId,
  // setIsModal
}) {
  const [isOpen, setIsModal] = React.useState({
    open: false,
    type: "",
    mode: ''
  });
  const [viewDetails, setViewDetails] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isViewImage, setIsViewImage] = React.useState({
    isView: false,
    item: {},
  });
  let dispatch = useDispatch();
  const themeColor = useThemeColor();
  // console.log(themeColor, 'themecolor');
  // const names = [" ", "Date", "Category", "User", "Pray", "View", "Action"];

  const handleView = (item, type, mode) => {
    setIsModal((prevState) => ({ ...prevState, open: true, type, mode }));
    // setIsModal(true);
    setViewDetails(item);
  };
  const handleOpenDialog = (item, type, status) => {
    setOpenDialog(true);
    setViewDetails(item);
  };

  const handleViewImage = (item) => {
    setIsViewImage((prevState) => ({ ...prevState, isView: true, item }));
  };

  const handleCloseImage = () => {
    setIsViewImage((prevState) => ({ ...prevState, isView: false, item: {} }));
  };

  const { width } = screenSize();

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const prayerLikeData = useSelector(
    (state) => state.prayerWallsLikeOrPray,
    shallowEqual
  );
  const answeredPrayerData = useSelector(
    (state) => state.markedAnsweredPrayer,
    shallowEqual
  );
  const archivedPrayerData = useSelector(
    (state) => state.archivePrayer,
    shallowEqual
  );
  const backgroundColor = themeColor.color;
  const backgroundColorSecondary = themeColor.secondaryColor;

  const handleReload = (status='false') => {
    dispatch(
      prayerWallsWithoutLoader({
        orgId: orgInfo.orgId,
        userId: userId.userId,
        fellowshipType: type, 
        mineOnlySwitch: status
      })
    );
  };

  const handleReloadMyPrayer = () => {
    dispatch(
      myPrayerWalls({
        orgId: orgInfo.orgId,
        userId: userId.userId,
        fellowshipType: type, 
        mineOnlySwitch: 'true'
      })
    )
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseModal = () => {
    setIsModal((prevState) => ({ ...prevState, open: false, type: "", mode: '' }));
  };
  const handleLikedOrPrayed = (item) => {
    dispatch(
      prayerWallsLikeOrPray(
        {
          orgId: orgInfo.orgId,
          userId: userId.userId,
          recordId: item.id,
        },
        handleReload,
        handleCloseDialog
      )
    );
  };

  const [page, setPage] = React.useState(1);
  const [next, setNext] = React.useState(50);
  const [selectedArchive, setSelectedArchive] = React.useState({
    isOpen: false,
    item: {},
  });
  const [selectedTestimony, setSelectedTestimony] = React.useState({
    isOpen: false,
    item: {},
  });
  const [selectedLikedOrPray, setSelectedLikedOrPray] = React.useState({
    isOpen: false,
    item: {},
  });

  // const [isOpen, setIsOpen] = React.useState(false)
  const handleChange = (event, value) => {
    setPage(value);
  };

  const indexOfData = page * next;
  const indexOfFirstData = indexOfData - next;
  const currentTodos = data.slice(indexOfFirstData, indexOfData);

  // Logic for displaying page numbers
  let pageNumbers;
  pageNumbers = Math.ceil(data.length / next);

  const handleCloseArchive = () => {
    setSelectedArchive((prevState) => ({ ...prevState, isOpen: false }));
  };
  const handleArchive = (item) => {
    dispatch(
      archivePrayer(
        {
          orgId: orgInfo.orgId,
          userId: userId.userId,
          recordId: item.id,
        },
        handleReloadMyPrayer,
        handleCloseArchive
      )
    );
  };

  const handleCloseTestimony = () => {
    setSelectedTestimony((prevState) => ({ ...prevState, isOpen: false }));
  };
  const handleMarkTestimony = (item) => {
    const formData = new FormData();
    formData.append('orgId', orgInfo.orgId);
    formData.append('userId', userId.userId);
    formData.append('message', item.message);
    formData.append('categoryName', item.categoryName)
    formData.append('recordId', item.id)
    formData.append('markAsAnonymous', 'false')
    dispatch(
      markedAnsweredPrayer(
       formData,
        handleReloadMyPrayer,
        handleCloseTestimony
      )
    );
  };
  const handleOpenArchive = (item) => {
    setSelectedArchive((prevState) => ({ ...prevState, isOpen: true, item }));
  };

  const handleOpenTestimony = (item) => {
    setSelectedTestimony((prevState) => ({ ...prevState, isOpen: true, item }));
  };
  const handleLiked = (item) => {
    setSelectedLikedOrPray((prevState) => ({
      ...prevState,
      isOpen: true,
      item,
    }));
  };
  const handleLikedClose = () => {
    setSelectedLikedOrPray((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleTableIcon = (item) => {
    console.log(item);
    // if (item === 'Pray') {
    //   currentTodos = currentTodos.sort((a, b) => {
    //     return b.likeOrPrayedCounter - a.likeOrPrayedCounter
    //   });
    // }
    // return data
  };
  console.log(width, 'width');
  // console.log(selectedTestimony, 'sixe');
  return (
    <div className="w-100 prayer-table-page">
      <div className="d-flex justify-content-between align-content-center"></div>
      {/* <hr /> */}
      <div className="row pt-3">
        <div className="col-md-12 pl-2">
          {width <= 720 && myrequest ? (
            <SmallTable
              data={currentTodos}
              myrequest={myrequest}
              handleView={handleView}
              backgroundColor={backgroundColor}
              backgroundColorSecondary={backgroundColorSecondary}
              tableActionIconsNegative={tableActionIconsNegative}
              tableActionIconsPositive={tableActionIconsPositive}
              isLoading={isLoading}
              handleOpenArchive={handleOpenArchive}
              handleOpenTestimony={handleOpenTestimony}
              type={type}
              handleOpenDialog={handleOpenDialog}
              handleImageView={handleViewImage}
            />
          ) : (
            <div class="table-responsive w-100">
              {data.length === 0 ? (
                <Empty />
              ) : (
                <TableContainer component={Paper}>
                  <Table className="table table-stripe">
                    <TableHead>
                      {names.map((item, index) => {
                        return (
                          <TableCell className="fw-bold" key={index}>
                            {item.name}
                            {/* <i className={item.iconName} onClick={() => handleTableIcon(item.name)}></i>   */}
                          </TableCell>
                        );
                      })}
                    </TableHead>
                    <TableBody>
                      {currentTodos.map((item, index) => {
                        return (
                          <TableRow>
                            <>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{moment(item.date).format("ddd MMM D, YYYY h:mm A")}</TableCell>
                              {myrequest && (
                                <TableCell>
                                  <div
                                    className="symbol symbol-30 symbol-circle mr-3"
                                    style={{ cursor: "pointer" }}
                                    >
                                    <img
                                      src={item.creatorPicture}
                                      alt="user"
                                      onError={(e) => {
                                        if (e.target.src !== item.creatorPicture)
                                        e.target.src =
                                        "https://dev.churchpad.com/xTextersImageFilesLoc/i/2/vi2__1606075062__yawxmoxnukq8__ddesign9.png";
                                      }}
                                      onClick={() => handleViewImage(item)}
                                      />
                                  </div>
                                </TableCell>
                              )}
                              <TableCell>{item.creatorName}</TableCell>
                              <TableCell>{item.categoryName}</TableCell>
                              <TableCell>
                              <Tooltip title='View all Details' placement='top' TransitionComponent={Zoom}>
                                <i
                                  className="fa fa-arrow-right"
                                  style={{
                                    color: themeColor.iconColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleView(item, "view", 'all')}
                                  ></i>
                                  </Tooltip>
                                  {item?.message && <Tooltip title='message' placement='top' TransitionComponent={Zoom}>
                                  <i className="fas fa-envelope px-2" style={{fontSize: '1.1rem', cursor: 'pointer', color: themeColor.iconColor}} onClick={() => handleView(item, "view", 'message')}></i>
                                    </Tooltip>}
                                  {item?.audio && <Tooltip title='Audio' placement='top' TransitionComponent={Zoom}>
                                  <i className="fas fa-microphone px-1" onClick={() => handleView(item, "view", 'audio')} style={{fontSize: '1.1rem', cursor: 'pointer', color: themeColor.iconColor, padding : !item.message && !item.video && item.images.length === 0 ? '5px !important' : ''}}></i>
                                    </Tooltip>}
                                  {item.images.length > 0 && <Tooltip title={`${item.images.length} ${item.images.length === 1 ? 'Image' : 'Images'}`} placement='top' TransitionComponent={Zoom}>
                                  <i className="fas fa-images px-1" onClick={() => handleView(item, "view", 'images')} style={{fontSize: '1.1rem', cursor: 'pointer', color: themeColor.iconColor, padding : !item.message && !item.audio && !item.video ? '5px !important' : ''}}></i>
                                    </Tooltip>}
                                  {item?.video && <Tooltip title='Video' placement='top' TransitionComponent={Zoom}>
                                  <i className="fas fa-video px-2" onClick={() => handleView(item, "view", 'video')} style={{fontSize: '1.1rem', cursor: 'pointer', color: themeColor.iconColor, padding : !item.message && !item.audio && item.images.length === 0 ? '5px !important' : ''}}></i>
                                    </Tooltip>}
                              </TableCell>
                              <TableCell>{item.likeOrPrayedCounter}</TableCell>
                              {myrequest && (
                                <TableCell>
                                  {item.ownedByThisUser !== 'true' && item.thisUserlikedOrPrayed === "0" ? (
                                    <Tooltip
                                      title={type === "testimony" ? "Click to like the testimony" : "Click if you are praying for these person"}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                    >
                                      <i
                                        className={
                                          isLoading
                                            ? "fas fa-spinner fa-spin"
                                            : tableActionIconsNegative
                                        }
                                        style={{
                                          color: themeColor.iconColor,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleOpenDialog(item, "confirm", type)
                                        }
                                      ></i>
                                    </Tooltip>
                                  ) : ( item.ownedByThisUser !== 'true' &&
                                    <Tooltip
                                      title={type === 'prayerRequest' ? "I have prayed for this person" : 'I have liked this testimony'}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                    >
                                      <i
                                        className={tableActionIconsPositive}
                                        style={{
                                          color: themeColor.iconColor,
                                        }}
                                      ></i>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              )}
                              {!myrequest && (
                                <TableCell>
                                  {type === "testimony" ? null : (
                                    <Tooltip title='Mark Prayer'>
                                    <i
                                      className={
                                        isLoading
                                          ? "fas fa-spinner fa-spin"
                                          : "fa fa-check-double pr-md-2 pr-sm-1"
                                      }
                                      style={{
                                        color: themeColor.iconColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleOpenTestimony(item)}
                                    ></i></Tooltip>
                                  )}
                                  <Tooltip title='Archive'>
                                  <i
                                    // className="fa fa-praying-hands"
                                    className={
                                      isLoading
                                        ? "fas fa-spinner fa-spin"
                                        : "fa fa-archive"
                                    }
                                    style={{
                                      color: themeColor.iconColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleOpenArchive(item)}
                                  ></i></Tooltip>
                                </TableCell>
                              )}
                            </>
                          </TableRow>
                        );
                      })}
                      {/* } */}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
          <Pagination count={pageNumbers} page={page} onChange={handleChange} />
        </div>
      </div>
      <ConfirmDialog
        open={openDialog}
        onConfirmDialogClose={() => setOpenDialog(false)}
        text={type === 'prayerRequest' ? `Are you sure you want to pray for ${viewDetails?.creatorName}, please click Yes to continue. If not, click No to abort.`: 'If you have been blessed and liked this testimony, please click Yes to continue. If not, click No to abort.'}
        onYesClick={() => handleLikedOrPrayed(viewDetails)}
        isLoading={prayerLikeData.loading}
      />
      <ResizeableModalWithoutBtn
        className="ml-5"
        modalState={isOpen?.open && isOpen?.type === "view"}
        title={
          isOpen.type === "view"
            ? viewDetails &&
              `${viewDetails.creatorName} - ${viewDetails.categoryName}`
            : null
        }
        resetModalState={handleCloseModal}
        useType='prayerwalls'
      >
        {isOpen?.mode === 'all' && <h5 className='pb-3'>Message</h5>}
        <p> {isOpen.type === "view" && (isOpen?.mode === 'all' || isOpen?.mode === 'message') && viewDetails?.message}</p>
        {isOpen.type === "view" && viewDetails?.images && <div className='py-3'>
        {isOpen?.mode === 'all' && <Divider />}
        </div>}
        <div className={viewDetails?.images?.length > 1 ? 'view-image-walls' : 'view-image-wall-single '}>
        {isOpen.type === "view" && (isOpen?.mode === 'all' || isOpen?.mode === 'images') && viewDetails?.images && (
          viewDetails?.images.map((item, index) => {
            return (
              <div key={index} style={{textAlign: 'center', padding: '0.6rem 0px'}} onClick={() =>handleViewImage(item)}>
                <h5 style={{textAlign: 'left'}}>{`Image ${index + 1}`}</h5>
                <img src={item} height='100%' width='100%'style={{objectFit: 'cover'}}/>
              </div>
            )
          })
          )} 
          </div>
          {isOpen.type === "view" && viewDetails?.video && <div className='py-3'>
          {isOpen?.mode === 'all' && <Divider />}
          </div>}
        {isOpen.type === "view" && (isOpen?.mode === 'all' || isOpen?.mode === 'video') && viewDetails?.video && (
          <div style={{textAlign: 'center', padding: '1rem 0px'}}>
            <h5 style={{textAlign: 'left'}}>Video</h5>
            <video id="video" width="100%" height="100%" controls={true} src={viewDetails?.video} type='video/mp4'></video>
          </div>
        )
        }
        {isOpen.type === "view" && (isOpen?.mode === 'all' || isOpen?.mode === 'video') && viewDetails?.video && <div className='py-3'>
        {isOpen?.mode === 'all' && <Divider />}
        </div>}
        {isOpen.type === "view" && (isOpen?.mode === 'all' || isOpen?.mode === 'audio') && viewDetails?.audio && (
          <>
            <h5 style={{textAlign: 'left'}}>Audio</h5>
            <AudioPlayer audioUrl={viewDetails?.audio}/>
          {/* <div style={{backgroundImage: `url(${AudioBackgroundImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
          <div style={{textAlign: 'center', paddingTop: '10rem'}}>
            <div style={{backgroundColor: '#fff'}}>
          <audio controls={true} src={viewDetails?.audio} controlsList='' className='prayer-wall-aduio'>
            Your browser does not support the
            <code>audio</code> element.
          </audio>

            </div>
          </div>
          </div> */}
          </>
        )
        // <Media>
        // <div>
        //   <Player
        //     src={viewDetails?.audio}
        //   />
        // </div>
        // </Media>
        }
        {/* <p>
          {isOpen.type === "confirm" && viewDetails && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <h3 className="text-center">
                  Are you sure you want to pray for {viewDetails.creatorName} on{" "}
                  {viewDetails.messageOrMediaUrl}
                </h3>
                <div className={styles.confirmButtonContainer}>
                  {prayerLikeData?.loading ? (
                    <button class="btn btn-primary" type="button" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <>
                      <div className="px-2">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={() => handleLikedOrPrayed(viewDetails)}
                        >
                          Yes
                        </button>
                      </div>
                      <div className="px-2">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => handleCloseModal()}
                        >
                          No
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </p> */}
      </ResizeableModalWithoutBtn>

      {/* <ModalComponent
        show={isOpen?.open && isOpen?.type === "view"}
        onHide={handleCloseModal}
        title={
          isOpen.type === "view"
            ? viewDetails &&
              `${viewDetails.creatorName} - ${viewDetails.categoryName}`
            : null
        }
        backgroundColor={backgroundColor}
        hideFooter={true}
      >
        <p>{isOpen.type === "view" && viewDetails?.message}</p>
        <div className='view-image-walls'>

        {isOpen.type === "view" && viewDetails?.images && (
          viewDetails?.images.map((item, index) => {
            return (
              <div key={index} style={{textAlign: 'center', padding: '0.6rem 0px'}} onClick={() =>handleViewImage(item)}>
                <h5 style={{textAlign: 'left'}}>{`Image ${index + 1}`}</h5>
                <img src={item} height='100%' width='100%'style={{objectFit: 'cover'}}/>
              </div>
            )
          })
          )} 
          </div>
        {isOpen.type === "view" && viewDetails?.video && (
          <div style={{textAlign: 'center', padding: '1rem 0px'}}>
            <h2 style={{textAlign: 'left'}}>Video</h2>
            <video id="video" width="100%" height="100%" controls={true} src={viewDetails?.video} type='video/mp4'></video>
          </div>
        )
        }
        {isOpen.type === "view" && viewDetails?.audio && (
          <>
            <h2 style={{textAlign: 'left'}}>Audio</h2>
            <AudioPlayer audioUrl={viewDetails?.audio}/> */}
          {/* <div style={{backgroundImage: `url(${AudioBackgroundImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
          <div style={{textAlign: 'center', paddingTop: '10rem'}}>
            <div style={{backgroundColor: '#fff'}}>
          <audio controls={true} src={viewDetails?.audio} controlsList='' className='prayer-wall-aduio'>
            Your browser does not support the
            <code>audio</code> element.
          </audio>

            </div>
          </div>
          </div> 
          </>
        )
        // <Media>
        // <div>
        //   <Player
        //     src={viewDetails?.audio}
        //   />
        // </div>
        // </Media>
        }
        {/* <p>
          {isOpen.type === "confirm" && viewDetails && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <h3 className="text-center">
                  Are you sure you want to pray for {viewDetails.creatorName} on{" "}
                  {viewDetails.messageOrMediaUrl}
                </h3>
                <div className={styles.confirmButtonContainer}>
                  {prayerLikeData?.loading ? (
                    <button class="btn btn-primary" type="button" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <>
                      <div className="px-2">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={() => handleLikedOrPrayed(viewDetails)}
                        >
                          Yes
                        </button>
                      </div>
                      <div className="px-2">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={() => handleCloseModal()}
                        >
                          No
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </p> */}
      {/* </ModalComponent> */}
      <ConfirmDialog
        open={selectedArchive.isOpen}
        onConfirmDialogClose={handleCloseArchive}
        text={`Are you sure you want to archive this ${type === 'testimony'? 'testimony' : 'prayer request'}? Once it is archived, you won\'t be able to access it again. Also members will not be able to view it on the ${type === 'testimony'? 'Testimony Wall' : 'Prayer Wall'}. Click Yes to continue. If not, click No to abort.`}
        onYesClick={() => handleArchive(selectedArchive.item)}
        isLoading={archivedPrayerData.loading}
      />
      <ConfirmDialog
        open={selectedTestimony.isOpen}
        onConfirmDialogClose={handleCloseTestimony}
        text={`Halleluyah!! Are you sure you want to mark this Prayer Request as ANSWERED? Click Yes to continue. If not, No Cancel to abort.`}
        onYesClick={() => handleMarkTestimony(selectedTestimony.item)}
        isLoading={answeredPrayerData.loading}
      />
      <ViewUserImage
        isOpen={isViewImage?.isView}
        handleCloseModal={handleCloseImage}
        image={isViewImage?.item?.creatorPicture || isViewImage?.item}
        backgroundColor={backgroundColor}
        title={isViewImage?.item?.creatorName || 'Image'}
      />
    </div>
  );
}
