import React from "react";
// import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, TextField } from "@material-ui/core";
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import styles from "./emergency.scss";
import Button from '@material-ui/core/Button';
import {ButtonContainer} from '../../Common/Button'
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: "60ch",
  },
}));

export default function Forms({
  formDetails,
  buttonName,
  handleFormData,
  loading,
  hanldeShowPassword,
  showPasswordIcon,
  showPassword
}) {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onBlur",
  });
  const themeColor = useThemeColor();
  // const orgInfo = useSelector(
  //   (state) => state.orgInfo.responseData,
  //   shallowEqual
  // );
  const primaryBgColor = themeColor.btnStyle
  const backgroundColor= themeColor.color
  const onSubmit = (data) => {
    handleFormData(data);
  };
  const classes = useStyles();
  return (
    <form
      className="form fv-plugins-bootstrap fv-plugins-framework mt-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-md-12 px-0 m-auto">
        <div className="fv-plugins-icon-container mb-8">
          {/* <Grid className="" xs={12} spacing={2}> */}
          {/* <Grid item xs={6} md={3} container> */}
          {formDetails.map((item, index) => {
            return (
              <div key={index} className="py-4">
                <Controller
                  as={
                    <TextField
                      label={item.label}
                      name={item.name}
                      // className={classes.textField}
                      variant="outlined"
                      type={item.type}
                      fullWidth
                      defaultValue={item.defaultValue || ""}
                      value={item?.defaultValue}
                      error={errors[item.name] ? true : false}
                      InputProps={{
                        readOnly: item.readOnly ? true : false,
                        endAdornment: (
                          showPasswordIcon &&
                          <InputAdornment position="end">
                            <i className={showPassword ? "far fa-eye-slash cursor" : "far fa-eye cursor"} onClick={hanldeShowPassword}/>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  name={item.name}
                  control={control}
                  defaultValue={item.defaultValue || ""}
                  rules={{
                    required: item.errorMsg,
                    pattern: {
                      value: item.pattern ? item.pattern : null,
                      message: item.patternMsg,
                    },
                  }}
                />
                {errors[item.name]?.type === "required" && (
                  <div className="fv-plugins-message-container pl-2">
                    <div className="fv-help-block">
                      {errors[item.name].message}
                    </div>
                  </div>
                )}
                {errors[item.name]?.type === "pattern" && (
                  <div className="fv-plugins-message-container pl-2">
                    <div className="fv-help-block">
                      {errors[item.name].message}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {/* </Grid> */}
          <hr className="py-2" />
          {/* </Grid> */}
        </div>
      </div>
      <div className="col-sm-12 d-flex justify-content-between px-0 m-auto">
        <ButtonContainer
          // id="kt_login_signin_submit"
          type="submit"
          disabled={loading}
          // style={primaryBgColor}
          backgroundColor={backgroundColor}
          className="col-sm-5 mr-5 py-4"
        >
          <span>{loading ? "Please wait..." : `${buttonName}`}</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </ButtonContainer>
        <Link component={Button} to="/dashboard" elevation={0} className=" px-5 py-4 col-sm-5" variant="contained">
          Cancel
        </Link>
      </div>
    </form>
  );
}
