import React from "react";
import QGive from "../../components/QGive";
import { Home } from "./Home";
import {
    GoogleReCaptchaProvider,
  } from 'react-google-recaptcha-v3';
import { shallowEqual, useSelector } from "react-redux";
import { UnAuthorizedUser } from "../../components/Forms/GiveForm/UnAuthorizedUser";

export const QGivePage = () => {
  /* Check if the user is authorized */
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const siteKey = process.env.REACT_APP_RECAPTCHA

  return (
    <Home>
      {orgInfo ? (
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
          <QGive />
        </GoogleReCaptchaProvider>
      ) : (
        // <InitialGiveForm /> :
        <UnAuthorizedUser />
      )}
    </Home>
  );
};
