import { actionTypes } from '../../actionTypes';

export const getFollowUpByOrganizationIdData = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.GET_FOLLOW_UP_BY_ORGANIZATION_ID_SUCCESS:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.GET_FOLLOW_UP_BY_ORGANIZATION_ID_FAILURE:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.START_LOADING_FOLLOWUP:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING_FOLLOWUP:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const createActivityLogData = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.CREATE_ACTIVITY_LOG_SUCCESS:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.CREATE_ACTIVITY_LOG_FAILURE:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const getActivityLogData = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.GET_ACTIVITY_LOG_SUCCESS:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.GET_ACTIVITY_LOG_FAILURE:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const getFollowUpFormDetailsData = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.GET_FOLLOW_UP_FORM_SUCCESS:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.GET_FOLLOW_UP_FORM_FAILURE:
        return {
          ...state,
          ...action.payload,
        };
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };