import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { usePaymentInputs } from 'react-payment-inputs';
import moment from 'moment'
import { TextField } from '@material-ui/core'
import images from 'react-payment-inputs/images';
import { getUserDetails } from '../../../utils/authHelpers'
import { months, generateArrayOfYears } from '../../../utils/constants';
import './CreditCardForm.scss';


export const CreditCardForm = ({getCardNumber, getCVV, getExpiryMonth, getExpiryYear, getMemo, getFirstName, getLastName }) => {

  const { errors, control, watch } = useForm({
    mode: "onBlur",
  });

  const user = getUserDetails();
  const [cardNumber, setCardNumber] = useState('');
  const [cvc, setCVC] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cardErrors, setCardErrors] = useState({});
  

  const orgInfo = useSelector((state) => state.orgInfo.responseData);
  // const allowCheck = orgInfo?.orgGeneralSettings?.allowCheckAch;
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo?.orgColors?.primary : '', border: 'none' }
  // const creditCardInfo = useSelector((state) => state.creditCardInfo);

  // useEffect(() => {
  //   if (creditCardInfo && creditCardInfo.checkDetails &&  creditCardInfo.checkDetails.isCard === false) {
  //     setShowCheque(true)
  //   } else{
  //     setShowCheque(false)
  //   }
  // }, [creditCardInfo.checkDetails])

  const cardNumberValidator = ({ cardType }) => {
    if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard' || cardType.type === 'amex' || cardType === 'discover') {
      return;
    }
    return 'Card must be Visa, Mastercard, Amex or Discover';
  }

  const { meta, getCardNumberProps, getCardImageProps, getCVCProps } = usePaymentInputs({ cardNumberValidator });
   
  /** This is global state of memo */
  //  const acctMemo = watch('memo2')
  const cardMemo = watch('memo');
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const cardDetails = {};
  
  const dispatch = useDispatch();

  const { erroredInputs } = meta;

  const handleChangeCardNumber = (e) => {
    setCardNumber(e.target.value);
    getCardNumber(e.target.value)
  }

  const handleChangeCVC = (e) => {
    setCVC(e.target.value);
    getCVV(e.target.value)
  }

  useEffect(() => {
    getMemo(cardMemo)
    getFirstName(firstName)
    getLastName(lastName)
  }, [cardMemo, firstName, lastName])

  const expiryDateCheck = Number(moment().format('M')) - 1;

  // const goBack = () => {
  //   setGoBackStep(true)
  // }


  const handleMonthChange = e => {
    setExpiryMonth(e.target.value || null);
    getExpiryMonth(e.target.value)
  }

  const handleYearChange = e => {
    setExpiryYear(e.target.value || null);
    getExpiryYear(e.target.value);
  }

let memoColor;
// let acctColor;

if (cardMemo?.length >= 80 && cardMemo?.length < 95) {
  memoColor = 'text-warning';
}else if(cardMemo?.length >= 95) { 
  memoColor = 'text-danger';
} else {
  memoColor = 'text-muted'
}



  return (
    <div className="credit-card-form">
      <div className="form-group fv-plugins-icon-container mb-8">
        <div className="position-relative">
          <Controller
            as={
              <TextField
                label='First Name'
                name="firstName"
                variant="outlined"
                type="text"
                size="small"
                fullWidth
                error={errors.firstName ? true : false}
                // inputProps={{ pattern: "/^[1-9]\d*$/g" }}
              />
            }
            name="firstName"
            control={control}
            defaultValue={user.userFirstName || ""}
            rules={{
              required: true
            }}
          />
        </div>
        {errors.firstName?.type === 'required' && <span className="text-danger errorText">{'First Name is Required'}</span>}
      </div>
      <div className="form-group fv-plugins-icon-container mb-8">
        <div className="position-relative">
          <Controller
              as={
                <TextField
                  label='Last Name'
                  name="lastName"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  error={errors.lastName ? true : false}
                  // inputProps={{ pattern: "/^[1-9]\d*$/g" }}
                />
              }
              name="lastName"
              control={control}
              defaultValue={user.userLastName || ""}
              rules={{
                required: true
              }}
            />
          </div>
        {errors.lastName?.type === 'required' && <span className="text-danger errorText">{'Last Name is Required'}</span>}
      </div>
      <div className="position-relative mb-8">
        <input className="text-dark-50 form-control credit-card-input h-auto py-3 pr-18 pl-5"
          value={cardNumber}
          {...getCardNumberProps({ onChange: handleChangeCardNumber })}
          placeholder="Card Number"
        />
        <svg className="position-absolute card-img" {...getCardImageProps({ images })} />
        <p className="font-weight-bold font-size-xs m-0 text-muted"><span className="text-danger">Acceptable cards:</span> {orgInfo.allowedCards?.map((item) => {return (<span>{item}, {' '}</span>)})}</p>
        {cardErrors.cardNumber && <small className="text-danger errorText">{erroredInputs.cardNumber}</small>}
      </div>
      {/* <Grid container spacing={3} >
        <Grid item xs={6} >
          <TextField
            className=""
            name="expirationMonth"
            label="Exp Month"
            variant="outlined"
            size="small"
            fullWidth
            select
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.expirationMonth || ''}
            error={Boolean(
              touched.expirationMonth && errors.expirationMonth
            )}
            helperText={
              touched.expirationMonth && errors.expirationMonth
            }
          >
            {values.expirationYear === moment().format('YYYY') ?
                (months.slice(expiryDateCheck).map((item, index) => 
                  // <option key={`idx${index}`} value={item.id}>{item.value}</option>) :
                  <MenuItem value={item.id} key={item.id}>
                    {item.value}
                  </MenuItem>)) :
                  (months.map((item, index) => <MenuItem value={item.id} key={item.id}>
                      {item.value}
                    </MenuItem>))
              }
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className=""
            name="expirationYear"
            label="Exp Year"
            variant="outlined"
            size="small"
            fullWidth
            select
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.expirationYear || ''}
            error={Boolean(
              touched.expirationYear && errors.expirationYear
            )}
            helperText={
              touched.expirationYear && errors.expirationYear
            }
          >
            {arrayOfYears.map((year) => (
              <MenuItem value={year} key={year}>
                {year}
              </MenuItem>
            ))}
        </TextField>
      </Grid> */}
      <div className="mb-8 all-select-container d-flex justify-content-between">
        <div className="select-container">
          <select
            className="form-control pr-3 select-input form-select form-select-lg mb-0 col-md-12 "
            onChange={handleMonthChange}
            value={expiryMonth || ''}
          >
            <option value=''>Select One</option>
            {expiryYear === moment().format('YYYY') ?
              months.slice(expiryDateCheck).map((item, index) => <option key={`idx${index}`} value={item.id}>{item.value}</option>) :
              months.map((item, index) => <option key={`idx${index}`} value={item.id}>{item.value}</option>)
            }
          </select>
          {cardErrors.expiryMonth && <small className="text-danger errorText">{cardErrors.expiryMonth}</small>}
        </div>
        <div className="select-container">
          <select
            className="form-control pl-3 select-input form-select form-select-lg mb-0 col-md-12 "
            onChange={handleYearChange}
            value={expiryYear || ''}
          >
            <option value=''>Select One</option>
            {generateArrayOfYears().map((year, index) => <option key={`idx${index}`} value={year}>{year}</option>)}
          </select>
          {cardErrors.expiryYear && <small className="text-danger errorText">{cardErrors.expiryYear}</small>}
        </div>
      </div>
      <div className="position-relative mb-8">
        <TextField 
          label="CVV" 
          {...getCVCProps({ onChange: handleChangeCVC })} 
          value={cvc || ''} 
          variant="outlined"
          size="small" 
          fullWidth 
          error={cardErrors.cvc} 
          placeholder="CVV"
        />
        <p className="font-weight-bold mb-0 font-size-xs text-muted">3 or 4 digits usually found on the signature strip</p>
        {cardErrors.cvc && <small className="text-danger errorText">{cardErrors.cvc}</small>}
      </div>
      <div className="form-group fv-plugins-icon-container mb-8">
        <div className="position-relative">
        <Controller
            as={
              <TextField
                label="Memo"
                type="text"
                name="memo"
                maxLength={100}
                defaultValue={''}
                fullWidth
                rows={3}
                multiline
                size="small"
                maxlength="100"
                variant="outlined"
                inputProps={{maxLength: 100}}
              />
            }
            name="memo"
            control={control}
            defaultValue={""}
          />
        </div>
        <p className={`${memoColor}`}>{`${100 - cardMemo?.length} characters remaining`}</p>
      </div>
    </div>
  )
}