import React, { useState, useEffect } from "react";
import {
  checkInInformation,
  processCheckin,
  deleteGuest,
  processCheckout,
  checkinActionFamily,
  checkInListingsStatus,
} from "../../../store/actions/index";
import moment from "moment";
import "./rsvp.scss";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getUserProfile } from "../../../store/actions/index";
import { ButtonContainer } from "../../Common/Button";
import { useThemeColor } from "../../../utils/customHooks/useThemeColor";
import Radio from "@material-ui/core/Radio";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Button from "@material-ui/core/Button";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Fab,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import ConfirmationDialog from "../../Common/ConfirmDialog";
import { PurpleSwitch } from "../Login";
import { isEmpty } from "../../../utils";
import { useWindowDimensions } from "../../../utils/customHooks/useWindowDimensions";
import { CheckinCard } from "./CheckinCard";
import FloatingActionButton from "../../Common/FloatingActionButton";

export default function CheckInMainPage() {
  const [checkInData, setCheckInData] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  let themeColor = useThemeColor();
  const [value, setValue] = React.useState(0);
  const [formNumber, setFormNumber] = React.useState(false);
  const [mobile, setMobile] = useState("");
  const ipData = useSelector((state) => state.ipData);
  const [eventData, setEventData] = useState([]);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState([]);
  const [newGuestToCheckin, setNewGuestToCheckin] = useState([]);
  const [familyData, setFamilyData] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [deleteGuestShow, setDeleteGuest] = useState({
    show: false,
    id: "",
  });
  let { width } = useWindowDimensions();
  const [checkOutShow, setCheckoutShow] = useState({
    show: false,
    id: "",
  });
  const [selectionError, setSelectionError] = useState("");
  const [toggleCheckin, setToggleCheckin] = useState("");
  const [selectedSpouse, setSelectedSpouse] = useState({
    isSpouseSelected: false,
    spouseId: "",
  });
  const [hover, setHover] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('')
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const getUserProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const processCheckinData = useSelector(
    (state) => state.processCheckinData,
    shallowEqual
  );
  const deleteGuestData = useSelector(
    (state) => state.deleteGuest,
    shallowEqual
  );

  const processCheckoutData = useSelector(
    (state) => state.processCheckout,
    shallowEqual
  );
  
  let dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(checkInListingsStatus, "trnee");
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getcheckInInformation = () => {
    checkInInformation(window.location.search)
      .then((result) => {
        dispatch(
          getUserProfile({
            orgId: orgInfo?.orgId,
            userId: result.responseData.userId,
          })
        );
        // console.log(result.responseData, "checkin data");
        if (result.responseCode === "00") {
          setCheckInData(result.responseData);
          let newData = [];
          result.responseData.events.map((item, index) => {
            let exist = result.responseData.userExistingCheckedIn.find(
              (el) => el.eventId === item.id
            );
            if (exist) {
              item.check = true;
              newData.push(item);
            } else {
              item.check = false;
              newData.push(item);
            }
          });
          // console.log(newData, 'new data');
          newData.length <= 0
            ? setEventData(result.responseData.events)
            : setEventData(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getcheckInInformation();
  }, [processCheckinData.responseData]);

  useEffect(() => {
    setFamilyData(getUserProfileData?.userChildrenInfo);
  }, [getUserProfileData]);

  const handleCheckinCallback = () => {
    getcheckInInformation();
    setNewGuestToCheckin([]);
    setToggleCheckin("");
  };

  // const handleCheckIn = (item) => {
  //   // console.log(item, "datata");
  //   let userIdsToCheckIn = [];
  //   selectedItem.map((item) => {
  //     userIdsToCheckIn.push(item.id)
  //   })
  //   selectedGuest.map((item) => {
  //     userIdsToCheckIn.push(item.id)
  //   })
  //   // selectedSpouse.map((item) => {
  //   userIdsToCheckIn.push(selectedSpouse.spouseId)
  //   // })
  //   userIdsToCheckIn.push(getUserProfileData?.userId)
  //   const payload = {
  //     orgId: orgInfo.orgId,
  //     userId: getUserProfileData?.userId,
  //     eventId: item.id,
  //     userIdsToCheckIn,
  //     newGuestsToCheckIn: newGuestToCheckin,
  //     checkInLocation: toggleCheckin
  //   }
  //   console.log(payload, 'seleeeee');
  //   return dispatch(processCheckin(payload, handleCheckinCallback))
  // };

  const handleAddGuest = () => {
    setFormNumber(true);
  };

  const handleSelectedCard = (selected, index) => {
    setSelectedCard(selected)
    setSelectedIndex(index)
  }

  const handleIncludeGuest = () => {
    if (!firstName && !lastName && !mobile) {
      return setFormNumber(false);
    }
    const data = {
      firstName,
      lastName,
      mobile,
    };
    console.log(data, "data");
    setNewGuestToCheckin([...newGuestToCheckin, data]);
    setFormNumber(false);
    setFirstName("");
    setLastName("");
    setMobile("");
  };

  const handleRemoveGuest = (index) => {
    newGuestToCheckin.splice(index, 1);
    setNewGuestToCheckin([...newGuestToCheckin]);
  };
  const handleFamilyChanges = (item, isChecked, index) => {
    // console.log(isChecked, 'checked');
    if (isChecked) {
      const newObject = {
        id: item.childUserId,
        index,
      };
      setSelectedItem([...selectedItem, newObject]);
      dispatch(checkinActionFamily([...selectedItem, newObject]));
      // console.log(data, 'selected1111');
    } else {
      selectedItem.map((item, i) => {
        if (item.index === index) {
          selectedItem.splice(i, 1);
        }
      });
      setSelectedItem(selectedItem);
      dispatch(checkinActionFamily(selectedItem));
    }
  };

  const handleGuestChange = (item, isChecked, index) => {
    // console.log(item, isChecked, index, 'guest');
    if (isChecked) {
      const newObject = {
        id: item.guestUserId,
        index,
      };
      setSelectedGuest([...selectedGuest, newObject]);
      // console.log(data, 'selected1111');
    } else {
      selectedGuest.map((item, i) => {
        if (item.index === index) {
          selectedGuest.splice(i, 1);
        }
      });
      setSelectedGuest(selectedGuest);
    }
  };

  const handleDeleteGuestCallback = () => {
    getcheckInInformation();
    setDeleteGuest({ ...deleteGuestShow, show: false, id: "" });
  };
  const handleDeleteGuest = (index) => {
    console.log(deleteGuestShow.id, "index delete");
    let guestId = [deleteGuestShow.id];
    const payload = {
      orgId: orgInfo.orgId,
      userId: getUserProfileData?.userId,
      guestUserIds: guestId,
    };
    console.log(payload, "payload");
    return dispatch(deleteGuest(payload, handleDeleteGuestCallback));
  };
  // console.log(checkOutShow, "checkOutShow");
  const handleCheckoutCallback = () => {
    getcheckInInformation();
    setCheckoutShow({ ...checkOutShow, show: false, id: "" });
  };
  const handleCheckout = () => {
    console.log(checkInData, "checkout");
    console.log(checkOutShow, "checkoutshhow");
    let userIdsToCheckOut = [];
    checkInData.childrenExistingCheckedIn.map((item) => {
      if (item.eventId === checkOutShow.id) {
        userIdsToCheckOut.push(item.childUserId);
      }
    });
    checkInData.guestsExistingCheckedIn.map((item) => {
      if (item.eventId === checkOutShow.id) {
        userIdsToCheckOut.push(item.guestUserId);
      }
    });
    checkInData.userExistingCheckedIn.map((item) => {
      if (item.eventId === checkOutShow.id) {
        userIdsToCheckOut.push(item.userId);
      }
    });

    const payload = {
      orgId: orgInfo.orgId,
      userId: getUserProfileData?.userId,
      eventId: checkOutShow.id,
      userIdsToCheckOut,
    };

    console.log(payload, "checkout payload");
    return dispatch(processCheckout(payload, handleCheckoutCallback));
  };

  // const handleSpouseChange = (item, isChecked, index) => {
  //   if (isChecked) {
  //     const newObject = {
  //       id: item.spouseUserId,
  //       index,
  //     }
  //     setSelectedSpouse({...selectedSpouse, newObject})
  //     // console.log(data, 'selected1111');
  //   } else {
  //     setSelectedSpouse({})
  //   }
  // }

  const handleSpouseChange = (spouseId) => {
    console.log(spouseId, "spouseId");
    setSelectedSpouse({
      isSpouseChecked: !selectedSpouse.isSpouseChecked,
      spouseId,
    });
  };

  const handleReadyToCheckin = () => {
    const totalGuest = newGuestToCheckin.length + selectedItem.length + selectedGuest.length;
    if (toggleCheckin === "")
      return setSelectionError("select In-Person or Remote to continue");
    if (totalGuest > orgInfo.usersGeneralSettings.checkIn.numberOfGuests)
      return setSelectionError(
        `Guest cannot be more ${orgInfo.usersGeneralSettings.checkIn.numberOfGuests}`
      );
    return true;
  };

  // useEffect(() => {
  //   const totalGuest = selectedGuest.length + newGuestToCheckin.length;
  //   if(totalGuest <= orgInfo.usersGeneralSettings.checkIn.numberOfGuests) return setSelectionError('')
  // }, [selectedGuest.length + newGuestToCheckin.length])

  const handleRadioChange = (e) => {
    setToggleCheckin(e.target.value);
    setSelectionError(null);
  };

  const handleCheckinCancel = () => {
    setValue(0);
  };

  const getEventData = (data) => {
    setEventData(data);
  };

  const resetGuestCheckinData = () => {
    setNewGuestToCheckin([]);
    setToggleCheckin("");
  };

  const setCheckinCancel = (cancelCheckinItem) => {
    console.log(cancelCheckinItem, "cancelCheckinItem");
    setCheckoutShow({ ...checkOutShow, show: true, id: cancelCheckinItem.id });
  };
  let totalGuest;
  totalGuest = !selectedCard ? 0 : newGuestToCheckin.length + selectedItem.length + selectedGuest.length;
  const showMoreNumber = showMore
    ? 5
    : getUserProfileData?.userGuestsInfo.length;

  const handleMouseEnter = () => {
    setHover(!hover);
  };


  return (
    <div className="checkin login-signin" id="kt_login_signin_form">
      {width >= 720 && (
        <FloatingActionButton
          value={totalGuest}
          text={`${totalGuest > 1 ? "guests added" : "guest added"}`}
          isSpouse={selectedSpouse.isSpouseSelected}
        />
      )}
      <div className="text-center mb-10 mb-lg-10">
        <h5 className="mb-5">Check In System</h5>
      </div>
      <hr />
      <div className="w-100">
        <div className="date-container">
          <h5>Date:</h5>
          <h5 className="date-text">{moment().format("ddd, MMMM DD, YYYY")}</h5>
        </div>
        {eventData?.map((item, index) => {
          return (
            <CheckinCard
              index={index}
              item={item}
              selectedItem={selectedItem}
              selectedGuest={selectedGuest}
              selectedSpouse={selectedSpouse}
              checkInData={checkInData}
              toggleCheckin={toggleCheckin}
              getEventData={getEventData}
              resetGuestCheckinData={resetGuestCheckinData}
              newGuestToCheckin={newGuestToCheckin}
              setCheckinCancel={setCheckinCancel}
              handleSelectedCard={handleSelectedCard}
              setSelectionError={setSelectionError}
              handleReadyToCheckin={handleReadyToCheckin}
              setNewGuestToCheckin={setNewGuestToCheckin}
            > 
          {index === selectedIndex && <div className="tab-container px-3">
          <div>
            <p className="text-center">Select members and guests to check in</p>
            {/* {newGuestToCheckin.length != 0 && <p className='text-end'>{`${newGuestToCheckin.length} new ${newGuestToCheckin.length > 1 ? 'guests' : 'guest'} added`}</p>}
                {selectedItem.length != 0 && <p className='text-end'>{`${selectedItem.length} family ${selectedItem.length > 1 ? 'members' : 'member'} added`}</p>}
                {selectedGuest.length != 0 && <p className='text-end'>{`${selectedGuest.length} ${selectedGuest.length > 1 ? 'guests' : 'guest'} added`}</p>} */}

            <div className="mt-4">
              <h5>Member</h5>
              <div className="row justify-content-between px-4">
                <p>
                  {getUserProfileData?.userFirstName}{" "}
                  {getUserProfileData?.userLastName}
                </p>
                <div className='pr-3'>
                <GreenRadio
                  // checked={selectedValue === 'c'}
                  // onChange={handleChange}
                  // value="c"
                  name="radio-button-demo"
                  // inputProps={{ "aria-label": "C" }}
                  checked={true}
                  color={themeColor.secondaryColor}
                  icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                  checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />}
                  />
                  </div>
              </div>
            </div>
            {(!isEmpty(getUserProfileData?.userSpouseInfo) ||
              getUserProfileData?.userSpouseInfo?.length > 0) && (
              <div className="mt-4">
                <h5>Spouse</h5>
                <div className="row justify-content-between px-4 my-0">
                  <p>
                    {getUserProfileData?.userSpouseInfo?.spouseFirstName}{" "}
                    {getUserProfileData?.userSpouseInfo?.spouseLastName}
                  </p>
                  <div className='pr-3'>
                  <Checkbox
                    onChange={() =>
                      handleSpouseChange(
                        getUserProfileData?.userSpouseInfo?.spouseUserId
                        )
                      }
                      checked={selectedSpouse.isSpouseChecked}
                      icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                      checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />}
                      name="spouse"
                      />
                      </div>
                </div>
              </div>
            )}
            {getUserProfileData?.userChildrenInfo.length > 0 && (
              <div className="mt-4">
                <h5>Family</h5>
                {familyData?.map((item, index) => {
                  // item.isChecked = false;
                  return (
                    <div
                      className="row justify-content-between px-4"
                      key={index}
                    >
                      <p className="m-0">
                        {item.childFirstName} {item.childLastName}
                      </p>
                      <div className='pr-3'>
                      <RadioButton
                        // checked={item.isChecked}
                        handleChecked={handleFamilyChanges}
                        color={themeColor.secondaryColor}
                        index={index}
                        item={item}
                        icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                        checkedIcon={
                          <CheckCircleTwoToneIcon fontSize="medium" />
                        }
                        />
                        </div>
                    </div>
                  );
                })}
              </div>
            )}
            {getUserProfileData?.userGuestsInfo.length > 0 && (
              <div className="mt-4">
                <h5>Guests</h5>
                {getUserProfileData?.userGuestsInfo
                  .slice(0, showMoreNumber)
                  .map((item, index) => {
                    // item.isChecked = false;
                    return (
                      <div
                        className="row justify-content-between px-4"
                        key={index}
                      >
                        <p>
                          {item.guestFirstName} {item.guestLastName}
                        </p>
                        <div className="">
                          {/* <div className=''> */}
                          {/* </div> */}

                          <RadioButtonGuest
                            // checked={item.isChecked}
                            handleChecked={handleGuestChange}
                            color={themeColor.secondaryColor}
                            index={index}
                            item={item}
                            icon={
                              <RadioButtonUncheckedIcon fontSize="medium" />
                            }
                            checkedIcon={
                              <CheckCircleTwoToneIcon fontSize="medium" />
                            }
                          />
                          <i
                            className="fas fa-trash-alt icon-style"
                            onClick={() =>
                              setDeleteGuest({
                                ...deleteGuestShow,
                                show: true,
                                id: item.guestUserId,
                              })
                            }
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                {getUserProfileData?.userGuestsInfo.length > 5 && (
                  <p
                    onClick={() => setShowMore(!showMore)}
                    role="button"
                    className=""
                    style={{
                      fontSize: "12px",
                      textDecoration: "underline",
                      color: hover ? "#87CEEB" : "inherit",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseEnter}
                  >
                    {showMore ? "Show more" : "Show less"}
                  </p>
                )}
              </div>
            )}
            {newGuestToCheckin.length > 0 && (
              <div className="mt-4">
                <h5 style={{ textDecoration: "underline" }}>Extra Guests</h5>
                {/* <div className="row justify-content-between px-4"> */}
                {newGuestToCheckin.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row justify-content-between px-4"
                    >
                      <p>
                        {item.firstName} {item.lastName} {item.mobile}
                      </p>
                      <div>
                        <GreenRadio
                          // checked={selectedValue === 'c'}
                          // onChange={handleChange}
                          // value="c"
                          name="radio-button-demo"
                          // inputProps={{ "aria-label": "C" }}
                          checked={true}
                          color={themeColor.secondaryColor}
                          icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                          checkedIcon={
                            <CheckCircleTwoToneIcon fontSize="medium" />
                          }
                        />
                        <i
                          className="fas fa-trash-alt icon-size icon-style"
                          onClick={() => handleRemoveGuest(index)}
                        ></i>
                      </div>
                    </div>
                  );
                })}
                {/* </div> */}
              </div>
            )}
            {formNumber && (
              <div>
                {/* {formNumber.map((item, index) => {
                    return ( */}
                <div className="">
                  <div className="my-4">
                    <TextField
                      label="FirstName"
                      name="firstName"
                      required
                      type="text"
                      id="firstName"
                      size="small"
                      onChange={(e) => setFirstName(e.target.value)}
                      // error={!toggleMobile && formik.touched.email && formik.errors.email && true}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="my-4">
                    <TextField
                      label="LastName"
                      name="lastName"
                      type="text"
                      id="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      size="small"
                      // error={!toggleMobile && formik.touched.email && formik.errors.email && true}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <PhoneInput
                    international
                    withCountryCallingCode
                    countryCallingCodeEditable={false}
                    defaultCountry={ipData ? ipData.country_code : null}
                    placeholder="Mobile"
                    id="loginMobile"
                    value={mobile}
                    onChange={setMobile}
                    className={`form-control text-dark-50 h-auto py-3 px-6 mobile ${
                      mobile && !isValidPhoneNumber(mobile)
                        ? "is-invalid"
                        : mobile && isValidPhoneNumber(mobile)
                        ? "is-valid"
                        : ""
                    }`}
                    name="mobile"
                    error={
                      mobile
                        ? isValidPhoneNumber(mobile)
                          ? undefined
                          : "Invalid mobile number"
                        : "Mobile number is required"
                    }
                  />
                  <div className="my-4 row justify-content-between px-4">
                    <ButtonContainer
                      backgroundColor={themeColor.color}
                      type="button"
                      onClick={handleIncludeGuest}
                    >
                      Add Guest
                    </ButtonContainer>
                    <Button
                      variant="contained"
                      onClick={() => setFormNumber(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                {/* )
                  })} */}
              </div>
            )}
            {!formNumber && (
              <div>
                <p
                  // backgroundColor={themeColor.color}
                  onClick={handleAddGuest}
                  className=''
                  style={{textDecoration: 'underline', cursor: 'pointer'}}
                >
                  ADD MORE GUESTS
                </p>
              </div>
            )}
            <div className="py-3">
              <p className="my-0">Check In Type</p>
              <RadioGroup row onChange={handleRadioChange}>
                <FormControlLabel
                  value="Local"
                  className="mb-0"
                  control={<Radio checked={toggleCheckin === "Local"} />}
                  label="In-Person"
                />
                <div className="px-7"></div>
                <FormControlLabel
                  value="Remote"
                  className="mb-0"
                  control={<Radio checked={toggleCheckin === "Remote"} />}
                  label="Remote"
                />
              </RadioGroup>
              {selectionError && (
                <div className="fv-plugins-message-container mt-0">
                  <div className="fv-help-block">{selectionError}</div>
                </div>
              )}
            </div>
            {/* <div className="d-flex justify-content-center pt-3">
              <ButtonContainer
                backgroundColor={themeColor.color}
                type="button"
                onClick={handleReadyToCheckin}
              >
                Ready To Check In
              </ButtonContainer>
            </div> */}
          </div>

          {/* <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel> */}
          {/* </SwipeableViews> */}
        </div>}
            </CheckinCard>
          );
        })}

        <ConfirmationDialog
          open={deleteGuestShow.show}
          text="Are you sure you want to delete this guest?"
          onConfirmDialogClose={() =>
            setDeleteGuest({ ...deleteGuestShow, show: false })
          }
          onYesClick={handleDeleteGuest}
          isLoading={deleteGuestData.loading}
        />
        <ConfirmationDialog
          open={checkOutShow.show}
          text="Are you sure you want to checkout?"
          onConfirmDialogClose={() =>
            setCheckoutShow({ ...checkOutShow, show: false })
          }
          onYesClick={() => handleCheckout()}
          isLoading={processCheckinData.loading}
        />
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

const GreenRadio = withStyles({
  root: {
    color: (props) => props.color,
    "&$checked": {
      color: (props) => props.color,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RadioButton = ({
  handleChecked,
  color,
  index,
  item,
  icon,
  checkedIcon,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckState = () => {
    setIsChecked(!isChecked);
    // handleChecked(item, isChecked, index);
  };

  useEffect(() => {
    handleChecked(item, isChecked, index);
  }, [item, isChecked, index]);
  return (
    <GreenRadio
      checked={isChecked}
      onChange={handleCheckState}
      // value={isChecked}
      name="radio-button-demo"
      // inputProps={{ "aria-label": "C" }}
      color={color}
      icon={icon}
      checkedIcon={checkedIcon}
    />
  );
};
const RadioButtonGuest = ({
  handleChecked,
  color,
  index,
  item,
  icon,
  checkedIcon,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckState = () => {
    setIsChecked(!isChecked);
    // handleChecked(item, isChecked, index);
  };

  useEffect(() => {
    handleChecked(item, isChecked, index);
  }, [item, isChecked, index]);
  return (
    <GreenRadio
      checked={isChecked}
      onChange={handleCheckState}
      // value={isChecked}
      name="radio-button-demo"
      // inputProps={{ "aria-label": "C" }}
      color={color}
      icon={icon}
      checkedIcon={checkedIcon}
    />
  );
};

const RadioButtonSpouse = ({
  handleChecked,
  color,
  index,
  item,
  icon,
  checkedIcon,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckState = () => {
    setIsChecked(!isChecked);
    // handleChecked(item, isChecked, index);
  };

  useEffect(() => {
    handleChecked(item, isChecked, index);
  }, [item, isChecked, index]);
  return (
    <GreenRadio
      checked={isChecked}
      onChange={handleCheckState}
      // value={isChecked}
      name="radio-button-demo"
      // inputProps={{ "aria-label": "C" }}
      color={color}
      icon={icon}
      checkedIcon={checkedIcon}
    />
  );
};
