import React, { useState } from "react";
import Cropper from "react-cropper";
import { Paper } from '@material-ui/core'
import ImageUpload from "../../Common/ImageUpload";
import { ModalComponent } from "../../Common/Modal/index";
import { dataURLtoFile } from "../../../utils/constants";
import { getUserProfile, userImageUpload } from "../../../store/actions";
import Skeleton from "@material-ui/lab/Skeleton";
import { toast } from 'react-toastify';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import NotificationMesage from '../../Common/NotificationMesage'
import {ButtonContainer} from '../../Common/Button'
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';

import 'react-toastify/dist/ReactToastify.css';
import "cropperjs/dist/cropper.css";

import "./Cropper.scss";

const userData = JSON.parse(localStorage.getItem('user'))

export const Copper = ({ userId, orgId, defaultImage, family, handleCropImage }) => {
  const [image, setImage] = useState(defaultImage);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  let dispatch = useDispatch()

  const userDetails = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const backgroundColor = orgInfo?.colorThemes[0]?.primary

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    // setImgFile(files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      handleShow();
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setIsLoading(true);
      setCropData(cropper.getCroppedCanvas().toDataURL());
      // handleClose();
      if (family) {
        handleCropImage(cropper.getCroppedCanvas().toDataURL())
        handleClose();
      }else {
        const formData = new FormData();
        formData.append(
          "mediaFile",
          dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), "my.png")
        );
        formData.append("orgId", orgId);
        formData.append("userId", userId);
        formData.append("pictureType", "userProfilePicture");
        userImageUpload(formData).then((result) => {
          handleClose();
          dispatch(getUserProfile({'orgId': orgInfo.orgId, 'userId': userId}))
          setCropData(result.responseData.imageUrl)
          toast(<NotificationMesage message={result.responseMessage} responseStatus={result.responseCode} />)
        });
      }
    }
    // dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), 'my.png')
    // console.log(dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), 'my.png'));
  };

  return (
    <div className="w-100 m-auto text-center">
      <ImageUpload onChange={onChange} cropData={cropData} defaultImage={family ? defaultImage : userDetails?.userProfilePicLarge} />

      <ResizeableModalWithoutBtn
        modalState={show}
        resetModalState={handleClose}
        title="Image Crop"
        maxWidth='xs'
        fullWidth={true}
        // header="Crop and click the button below to continue"
      >
        <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <div className='d-flex justify-content-between pt-4'>
          <ButtonContainer className="btn btn-primary my-5" onClick={getCropData} backgroundColor={backgroundColor}>
            Save
          </ButtonContainer>
          <ButtonContainer className="btn btn-primary my-5" onClick={handleClose} backgroundColor={backgroundColor}>
            Close
          </ButtonContainer>
          </div>
        )}
      </ResizeableModalWithoutBtn>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Copper;
