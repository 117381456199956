import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import { InlineErrorMsg } from "../Common/InlineErrorMsg";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import { signup, resetEndpointDataAction } from "../../store/actions";
import NotificationMesage from "../Common/NotificationMesage";
import "react-phone-number-input/style.css";
import "./form.css";
import "./phone.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Alert from '@material-ui/lab/Alert';

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  // mobile: ''
};

export const Signup = () => {
  const ref = React.createRef();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const themeColor = useThemeColor();

  // Local State
  const [toggleShowPassword, setToggleShowPassword] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [mobile, setMobile] = useState("");
  const [signupAgreement, setSignupAgreement] = useState({
    hasAgreed: false,
    messagingOptIn: true,
  });
  const [agreementError, setAgreementError] = useState("");
  const [token, setToken] = useState("");
  const [dynamicAction, setDynamicAction] = useState("submit");

  // Global store data
  const ipData = useSelector((state) => state.ipData);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const signupData = useSelector((state) => state.signupData, shallowEqual);
  const orgInfoList = useSelector(
    (state) => state.orgInfoList.responseData,
    shallowEqual
  );
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.orgColors.primary : '' }

  let { from } = location.state || { from: { pathname: "/dashboard" } };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("recaptcha verifiction not successful");
      return;
    }

    const result = await executeRecaptcha("dynamicAction");
    // console.log(result, 'result');
    setToken(result);
    // setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
  }, [dynamicAction, executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      setToken(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, dynamicAction]);

  // console.log(token, 'token');
  Yup.addMethod(Yup.string, "mobileLength", function(errorMessage) {
    return this.test("test-mobile-length", errorMessage, function(value) {
      const { path, createError } = this;
      return (
        formik.values.length > 0 || createError({ path, message: errorMessage })
      );
    });
  });
  Yup.addMethod(Yup.string, "validMobileNumber", function(errorMessage) {
    return this.test("test-mobile-number-validity", errorMessage, function(
      value
    ) {
      const { path, createError } = this;
      return (
        (formik.values.mobile && isValidPhoneNumber(formik.values.mobile)) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const handleChange = (event) => {
    setSignupAgreement({
      ...signupAgreement,
      [event.target.name]: event.target.checked,
    });
  };

  // console.log(signupAgreement, 'signupAgreement')

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Required field"),
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(32, "Maximum 32 characters")
      .matches(/[A-Z]/, "Must contain atleast one uppercase character")
      .matches(/[a-z]/, "Must contain atleast one lowercase character")
      .matches(/\d/, "Must contain atleast one number")
      .matches(/[@#$%!]/, "Must contain atleast one @, #, $, %, or !")
      .required("Required field"),
    firstName: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(64, "Maximum 64 characters")
      .required("Required field"),
    lastName: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(64, "Maximum 64 characters")
      .required("Required field"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const redirectToDashboard = () => {
    history.replace(from);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      clickHandler();
      if (token) {
        const { password, email, firstName, lastName } = values;
        if (!mobile || mobile === null || !isValidPhoneNumber(mobile)) {
          return setMobileError("Mobile is Required");
        }
        if (!signupAgreement.hasAgreed) {
          return setAgreementError("Click the checkbox to continue");
        }
        return dispatch(
          signup(
            {
              email,
              password,
              mobile,
              firstName,
              lastName,
              orgId: orgInfo.orgId,
              messagingOptIn: `${signupAgreement.messagingOptIn}`,
            },
            redirectToDashboard
          )
        );
      } else {
        toast.error(
          <NotificationMesage
            message="Recaptcha Verification Failed!"
            responseStatus="01"
          />
        );
      }
    },
  });

  useEffect(() => {
    if (signupAgreement.hasAgreed && agreementError.length) {
      setAgreementError("");
    }
  }, [signupAgreement]);

  const toggleShowPasswordFunc = () => {
    setToggleShowPassword(!toggleShowPassword);
  };


  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="las la-clipboard-check"></i>
          </span>
        </div>
        <h3 className="font-size-h1 mb-5">Sign Up</h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>
      <hr />

      {/* Display sign up errro */}
      {signupData?.responseCode === "01" && <Alert severity='error'>{signupData?.responseMessage}</Alert>}
      <form
        onSubmit={formik.handleSubmit}
        // onSubmit={handleReCaptchaVerify}
        className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
      >
        {/* {signupData && signupData.responseCode === '01' && <Alert isSuccess={false} msg={signupData.responseMessage} />} */}
        <div className="d-md-flex justify-content-md-between">
          <div className="fv-plugins-icon-container mr-md-3 mb-8">
            <div className="position-relative">
              <TextField
                label="First Name"
                type="text"
                className={` ${getInputClasses("firstName")}`}
                variant="outlined"
                fullWidth
                error={formik.touched.firstName && formik.errors.firstName}
                name="firstName"
                {...formik.getFieldProps("firstName")}
              />
              {/* <i className="fas fa-user email-icon position-absolute"></i> */}
            </div>
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block errorText">
                  {formik.errors.firstName}
                </div>
              </div>
            ) : null}
          </div>
          <div className="fv-plugins-icon-container ml-md-3 mb-8">
            <div className="position-relative">
              <TextField
                label="Last Name"
                type="text"
                className={` ${getInputClasses("lastName")}`}
                name="lastName"
                variant="outlined"
                fullWidth
                error={formik.touched.lastName && formik.errors.lastName}
                {...formik.getFieldProps("lastName")}
              />
            </div>
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block errorText">
                  {formik.errors.lastName}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="d-md-flex justify-content-md-between">
          <div className="fv-plugins-icon-container mr-md-3 mb-8">
            <div className="position-relative">
              <TextField
                label="Email"
                type="email"
                className={` ${getInputClasses("email")}`}
                variant="outlined"
                error={formik.touched.email && formik.errors.email}
                fullWidth
                name="email"
                {...formik.getFieldProps("email")}
              />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block errorText">
                  {formik.errors.email}
                </div>
              </div>
            ) : null}
          </div>
          <div className="fv-plugins-icon-container ml-md-3 mb-8">
            <div className="position-relative password">
              <div className="position-relative">
                <TextField
                  label="Password"
                  type={toggleShowPassword ? "text" : "password"}
                  className={` ${getInputClasses("password")}`}
                  name="password"
                  fullWidth
                  error={formik.touched.password && formik.errors.password}
                  variant="outlined"
                  {...formik.getFieldProps("password")}
                />
                <span
                  className="position-absolute p-2"
                  onClick={toggleShowPasswordFunc}
                >
                  {toggleShowPassword ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </span>
                {/* <i className="fas fa-lock email-icon position-absolute"></i> */}
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block errorText">
                    {formik.errors.password}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="fv-plugins-icon-container mb-5">
          <p style={{ marginBottom: "0.2rem" }}>Phone Number</p>
          <PhoneInput
            international
            withCountryCallingCode
            countryCallingCodeEditable={false}
            defaultCountry={ipData ? ipData.country_code : null}
            placeholder="Mobile"
            value={mobile}
            onChange={setMobile}
            className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${
              mobile && !isValidPhoneNumber(mobile)
                ? "is-invalid"
                : mobile && isValidPhoneNumber(mobile)
                ? "is-valid"
                : ""
            }`}
            name="mobile"
            error={
              mobile
                ? isValidPhoneNumber(mobile)
                  ? undefined
                  : "Invalid mobile number"
                : "Mobile number is required"
            }
          />
          {mobile && !isValidPhoneNumber(mobile) ? (
            <InlineErrorMsg msg="Invalid mobile number" />
          ) : mobileError ? (
            <InlineErrorMsg msg={mobileError} />
          ) : (
            ""
          )}
        </div>

        <div className="d-flex mb-4">
          <input
            type="checkbox"
            className=" mr-2"
            id="messagingOptIn"
            color="default"
            checked={signupAgreement.messagingOptIn}
            onChange={handleChange}
            name="messagingOptIn"
          />
          <label htmlFor="messagingOptIn">
            {orgInfoList?.smsOptInMessage}{" "}
          </label>
        </div>
        <div className="d-flex">
          <input
            type="checkbox"
            className="mt-1 mr-2"
            id="signupAgreement"
            color="default"
            checked={signupAgreement.hasAgreed}
            onChange={handleChange}
            name="hasAgreed"
          />
          <label htmlFor="signupAgreement">
            By clicking Sign Up you are agreeing to{" "}
            <a className="" href={orgInfo.termsOfService} target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a className="" href={orgInfo.privacyPolicy} target="_blank">
              Privacy Policy
            </a>
          </label>
        </div>
        {agreementError && <p className="text-danger">{agreementError}</p>}
        <button
          id="kt_login_signin_submit"
          type="submit"
          disabled={
            (signupData && signupData.loading) || !signupAgreement.hasAgreed
          }
          style={themeColor.btnStyle}
          className={`btn w-100 font-weight-bold py-4 px-9 mb-5 ${
            !signupAgreement.hasAgreed ? "disabled-cursor" : ""
          }`}
        >
          <span className="text-white">
            {signupData.loading ? "Please wait..." : "Sign Up"}
          </span>
          {signupData && signupData.loading && (
            <span className="ml-3 spinner spinner-white"></span>
          )}
        </button>
        <div className="w-100 ">
          <p className="my-3 mr-2">
            Already have an account?
            <Link
              to="/"
              component={RouterLink}
              // style={{color: themeColor.color}}
            >
              <span
                className="ml-2"
                style={{
                  color: `${themeColor.isDarkMode ? "#fff" : themeColor.color}`,
                }}
              >
                Sign In
              </span>
            </Link>
          </p>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: (props) => props.primaryColor,
    "&$checked": {
      color: (props) => props.primaryColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
