import { actionTypes } from "../../actionTypes";

export const prayerWalls = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PRAYERWALLS_SUCCESS:
      return action.payload;
    case actionTypes.PRAYERWALLS_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const myPrayerWalls = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MY_PRAYERWALLS_SUCCESS:
      return action.payload;
    case actionTypes.MY_PRAYERWALLS_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const prayerWallsLikeOrPray = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PRAYERWALLSLIKEORPRAY_SUCCESS:
      return action.payload;
    case actionTypes.PRAYERWALLSLIKEORPRAY_FAILURE:
      return action.payload;
    case actionTypes.PRAYERWALLSLIKEORPRAY_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PRAYERWALLSLIKEORPRAY_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addPrayer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADDPRAYER_SUCCESS:
      return action.payload;
    case actionTypes.ADDPRAYER_FAILURE:
      return action.payload;
    case actionTypes.ADDPRAYER_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADDPRAYER_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const archivePrayer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ARCHIVEPRAYER_SUCCESS:
      return  action.payload;
    case actionTypes.ARCHIVEPRAYER_FAILURE:
      return action.payload;
    case actionTypes.ARCHIVEPRAYER_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ARCHIVEPRAYER_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const markedAnsweredPrayer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MARKED_ANSWERED_PRAYER_SUCCESS:
      return action.payload;
    case actionTypes.MARKED_ANSWERED_PRAYER_FAILURE:
      return action.payload;
    case actionTypes.MARKED_ANSWERED_PRAYER_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MARKED_ANSWERED_PRAYER_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
