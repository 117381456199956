import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import {
    // IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Menu
} from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConfirmationDialog from '../../Common/ConfirmDialog';
// import ProgressLoader from '../../Common/ProgressLoader';
import clsx from 'clsx';
import { UpdateRecurringGiveForm } from './UpdateRecurringGiveForm';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor'
import { makeStyles } from '@material-ui/core/styles';
import { existingRecurringProfilesPortal, cancelARecurring } from '../../../store/actions/userDashboardActions/give'
import { getUserDetails } from '../../../utils/authHelpers';
import ResizeableModal from '../../Common/ResizeableModal';
import {amountFormatter} from '../../../utils';
import { NewAutoDebitEnrollmentForm } from './NewAutoDebitEnrollmentForm';
import { useWindowDimensions } from '../../../utils/customHooks/useWindowDimensions';
import './Give.scss';

const useStyles = makeStyles(() => ({
    productTable: {
        '& thead': {
            '& th:first-child': {
                paddingLeft: 16,
            },
        },
        '& td': {
            borderBottom: 'none',
        },
        '& td:first-child': {
            paddingLeft: '16px !important',
        },
    },
}))


export const AutoDebitEnrollmmentTable = () => {
    const [recordId, setRecordId] = useState('')
    const [isModalClosed, setIsModalClosed] = useState(false);
    const [hiddenData, setHiddenData] = React.useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
    const [
        shouldOpenConfirmationDialog,
        setShouldOpenConfirmationDialog,
    ] = useState(false)

    const themeColor = useThemeColor();

    const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
    const existingRecurringProfilesPortalData = useSelector(state => state.existingRecurringProfilesPortalData.responseData, shallowEqual);
    const backgroundColor = orgInfo?.colorThemes[0]?.primary
	  const backgroundSecondaryColor = orgInfo?.colorThemes[0]?.secondary
    
    const user = getUserDetails();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const classes = useStyles();

    const open = Boolean(anchorEl);
    Number.prototype.format = amountFormatter

    const handleDialogClose = () => {
        setShouldOpenEditorDialog(false)
        setShouldOpenConfirmationDialog(false)
        updatePageData()
    }

    const handleDeleteUser = (recordId) => {
        setRecordId(recordId);
        setShouldOpenConfirmationDialog(true);
    }

    const handleDeleteResponse = () => {
      dispatch(cancelARecurring({
        recordId,
        orgId: orgInfo.orgId,
        userId: user.userId
      }, handleDialogClose))
    }

    const updatePageData = () => {
      dispatch(existingRecurringProfilesPortal({
        orgId: orgInfo.orgId,
        userId: user.userId
      }))
    }

    const resetCloseModal = () => {
      setIsModalClosed(false);
    }
    
    const closeModal = () => {
      updatePageData();
      setIsModalClosed(true);
    }

    useEffect(() => {
        dispatch(existingRecurringProfilesPortal({
          orgId: orgInfo.orgId,
          userId: user.userId
        }))
    }, [])

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleAnchorEl = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleHiddenData = (item, e) => {
      setHiddenData(item);
      handleAnchorEl(e);
    };

  return (
    // (cardsOrCheckingProfilesData && cardsOrCheckingProfilesData.loading) ?
    // <Skeleton count={25} /> :
    <div className="auto-debit-enrollment-table w-100 px-6">
      <div className='d-flex justify-content-start'>
      <ResizeableModal
          className="py-3"
          openModalBtnIcon={<i className="fa fa-life-ring kt-font-primary text-white mr-2"></i>}
          openModalBtnText='Auto-Debit Giving'
          title='Create/Manage Auto-Debit enrollment'
          maxWidth="xs"
          fullWidth={true}
          // resetCloseModal={resetCloseModal}
          closeModal={isModalClosed}
          titleIcon={<i className="fa fa-life-ring kt-font-primary"></i>}
          backgroundColor={themeColor.color}
        >
          <NewAutoDebitEnrollmentForm closeModal={closeModal} />
        </ResizeableModal>
      </div>
      <hr />
        {existingRecurringProfilesPortalData?.returnedRecords.length < 1 ?
          <p className="my-5 text-center">No record Found!</p> :
        <Table
          className={clsx(
              'whitespace-pre min-w-750',
              classes.productTable
          )}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className="px-0">Amount</TableCell>
              <TableCell className="px-0 pl-3">Contribution</TableCell>
              {width < 720 && <TableCell className="px-0"></TableCell>}
              {width >= 720 && <TableCell className="px-0">Type</TableCell>}
              {width >= 720 && <TableCell className="px-0">Next Billing</TableCell>}
              {width >= 720 && <TableCell className="px-0 text-center">Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {existingRecurringProfilesPortalData?.returnedRecords
              .map((profile, index) => {
                return(
                <TableRow hover key={`${index}a`}>
                  <TableCell className="px-0" align="left">
                      {index + 1}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {console.log(profile.amount)}
                    {parseFloat(profile.amount).format(2)}{' '}<span className="font-size-xs font-weight-lighter">{profile.currency}</span>
                  </TableCell>
                  <TableCell className="px-0 pl-3">
                      {profile.contributionType}
                  </TableCell>
                  {width < 720 && <TableCell className="px-0" align="center">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleHiddenData(profile, e)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>}
                  {width >= 720 && <TableCell className="px-0">
                      {profile.recurring}
                  </TableCell>}
                  {width >= 720 && <TableCell className="px-0">
                      {moment(profile.nextBillingDate).format("MMM D YYYY")}
                  </TableCell>}
                  {width >= 720 && <TableCell className="px-0 border-none text-center">
                    <ResizeableModal 
                      spanIcon={"far fa-edit"}
                      spanText='Update'
                      title='Update Recurring Giving'
                      maxWidth="xs"
                      showSpan={true}
                      fullWidth={true}
                      closeModal={isModalClosed}
                      titleIcon={<i className="fa fa-credit-card kt-font-primary"></i>}
                      hideBtn={true}
                      backgroundColor={themeColor.color}
                    >
                      <UpdateRecurringGiveForm closeModal={closeModal} amount={profile.amount} contributionType={profile.contributionType} recurring={profile.recurring} recordId={profile.recordId} handleDialogClose={handleDialogClose} />
                    </ResizeableModal >
                    <span style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}} onClick={() => handleDeleteUser(profile.recordId)} style={{ color: themeColor.color }} className="ml-3 cursor"><i style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}} className="fa fa-trash-alt font-size-sm"></i>&nbsp;Delete</span>
                  </TableCell>}
                </TableRow>
              )})}
            </TableBody>
          </Table>}

        
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={handleDeleteResponse}
            text="Are you sure to delete?"
          />
        )}
        <Menu
          id="short-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "50ch",
            },
          }}
        >
          <table className="table table-stripe">
          <thead>
            {/* {hiddenNames.map((item) => {
              return ( */}
                <th className="fw-bold">
                  Type
                </th>
                <th className="fw-bold">
                  Next Billing
                </th>
                <th className="fw-bold">
                  Action
                </th>
              {/* );
            })} */}
          </thead>
          <tbody>
            {hiddenData && (
              <tr>
                <td>{hiddenData.recurring}</td>
                <td>{moment(hiddenData.nextBillingDate).format("MMM D YYYY")}</td>
                <td>
                  <ResizeableModal 
                    spanIcon={"far fa-edit"}
                    spanText={width < 350 ? '' : 'Update'}
                    title='Update Recurring Giving'
                    maxWidth="xs"
                    showSpan={true}
                    fullWidth={true}
                    closeModal={isModalClosed}
                    titleIcon={<i className="fa fa-credit-card kt-font-primary"></i>}
                    hideBtn={true}
                    backgroundColor={backgroundColor}
                    backgroundSecondaryColor={backgroundSecondaryColor}
                  >
                    <UpdateRecurringGiveForm closeModal={closeModal} amount={hiddenData.amount} contributionType={hiddenData.contributionType} recurring={hiddenData.recurring} recordId={hiddenData.recordId} handleDialogClose={handleDialogClose} />
                  </ResizeableModal >
                  <span onClick={() => handleDeleteUser(hiddenData.recordId)} style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}} className="cursor ml-3"><i style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}} className="fa fa-trash-alt font-size-sm"></i>&nbsp;Delete</span>
                  {/* <span style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}} onClick={() => handleDeleteUser(hiddenData.recordId)} className="ml-3 cursor"><i style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}} className="fa fa-trash-alt font-size-sm"></i>&nbsp;{`${width < 350 ? '' :'Delete'}`}</span> */}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </Menu>
       </div>

  )
}