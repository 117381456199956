import React from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom'
import { Button } from 'react-bootstrap'
import {ActiveForm} from './ActiveForm'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { setHideAmountInput, donatedAmount, resetEndpointDataAction } from '../../../store/actions';

export default function SuccessGiveComponent({ customPath, className, isQGive = false, handleNext }) {
  const [giveAgain, setGiveAgin] = React.useState(false)
  let history = useHistory()
  const dispatch = useDispatch();
  // const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const giveResponse = useSelector(state => state.giveWithCardData.data, shallowEqual);
  const giveWithANewCardData = useSelector(state => state.giveWithANewCardData)
  const giveWithANewCheckData = useSelector(state => state.giveWithANewCheckData)
  // const primaryBgColor = {backgroundColor: orgInfo ? orgInfo?.orgColors?.primary : '', border: 'none'}
  const themeColor = useThemeColor();

  const goBack = () => {
    history.push('/')
  }

  const goNext = () => {
    if (isQGive) {
      return handleNext()
    }
    dispatch(setHideAmountInput(false))
    dispatch(donatedAmount(0.00));
    setGiveAgin(true)
  }

  const goCustomNext = () => {
    dispatch(resetEndpointDataAction)
    history.push(customPath)
  }

    return (
      giveAgain ? <ActiveForm /> :
        <div className="login-form login-signin activeForm-wrapper px-3">
        <div className="text-center mb-10 mb-lg-10">
          <div>
            <span className="icon" style={themeColor.btnStyle}>
              <i className="fa fa-thumbs-up"></i>
            </span>
          </div>
          <h3 className="font-size-h3 mb-2">Thank You</h3>
        </div>
        {/* <hr /> */}
            <div className="text-center my-10 my-lg-10">
              {giveWithANewCardData && <h6><strong>{giveWithANewCardData.responseMessage}</strong></h6>}
              {giveWithANewCheckData && <h6><strong>{giveWithANewCheckData.responseMessage}</strong></h6>}
              {giveResponse && <h6><strong>{giveResponse?.responseMessage}</strong></h6>}
            </div>

            {customPath ?
              <div className="d-flex justify-content-center  navigator-wrapper mt-15 ">
                <Button onClick={goCustomNext} variant="primary" className={`btn btn-lg w-50 py-3 ${className}`} style={themeColor.btnStyle}>Go To My Contributions</Button>
              </div> :
              <div className="d-flex justify-content-between  navigator-wrapper mt-15 ">
              <Button onClick={goBack}  variant="secondary" className={`btn btn-lg py-3 ${className}`}>Go to Home</Button>
              <Button onClick={goNext} variant="primary" className={`btn btn-lg py-3 ${className}`} style={themeColor.btnStyle}>Give Again</Button>
            </div>}
        </div>
    )
}