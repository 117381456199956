import React from 'react'
import { Home } from './Home'
import Privacy from '../../components/Privacy/Privacy'

export default function PrivacyPage() {
  return (
    <Home>
        <Privacy />
    </Home>
  )
}
