import React from 'react'
import NewHere from '../../components/User/NewHere'
import Layout from './Layout'


export default function NewHerePage() {
    return (
        <div>
            <Layout>
                <NewHere />
            </Layout>
        </div>
    )
}
