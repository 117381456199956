import React from "react";
import BigLoader from './loader_gif_churchpad.gif'

export default function PageLoader() {
  return (
    <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
      <img src={BigLoader} alt="loader"/>
       <div className="w-100 text-center text-primary">
          <span className="font-size-h3">Loading...</span>
      </div>
    </div>
  );
}
