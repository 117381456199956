import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector, shallowEqual } from "react-redux";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import { ButtonContainer } from "./Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "./ResizeableModal.scss";
import { ColorButton } from "../User/Give/AddMoreContributionForm";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, showClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose && showClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const ResizeableModal = ({
  openModalBtnText,
  singleModal = false,
  backgroundColor,
  resetCloseModal,
  closeModal,
  actionBtn,
  showSpan = false,
  maxWidth,
  fullWidth,
  titleIcon,
  openModalBtnIcon,
  title,
  children,
  className,
  hideFooter,
  spanIcon = "fa fa-hand-holding-heart",
  spanText = "Give",
  showClose=true
}) => {
  const [open, setOpen] = useState(false);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const getThemeChangeData = useSelector(
    (state) => state.getThemeChangeData,
    shallowEqual
  );
  const lightTheme = orgInfo?.colorThemes[0]?.primary;
  const darkTheme = orgInfo?.colorThemes[1]?.primary;
  const themeColor = getThemeChangeData?.theme ? darkTheme : lightTheme;

  const handleClickOpen = () => {
    setOpen(true);
    if (singleModal) resetCloseModal();
  };

  useEffect(() => {
    if (closeModal) handleClose();
  }, [closeModal]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <Button variant="primary">{openModalBtnIcon}&nbsp;{openModalBtnText}</Button> */}
      {!showSpan ? (
        <ColorButton
          className={className}
          variant="contained"
          onClick={handleClickOpen}
          // color='primary'
          backgroundColor={backgroundColor}
        >
          {openModalBtnIcon}&nbsp;{openModalBtnText}
        </ColorButton>
      ) : (
        <span
          onClick={handleClickOpen}
          style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}}
          className="cursor-pointer"
        >
          <i
            style={{color: `${themeColor.isDarkMode ? '#fff' : themeColor.color }`}}
            className={`${spanIcon} font-size-sm `}
          ></i>
          &nbsp; {spanText} &nbsp;
        </span>
      )}
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {titleIcon}&nbsp;{title}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>{actionBtn}</DialogActions>
      </Dialog>
    </>
  );
};

const DialogContainer = withStyles((theme) => ({
  paper: {
    margin: (props) => props.margin || null,
  },
  paperFullWidth: {
    width: (props) => props.width || null,
  },
}))(Dialog);

export const ResizeableModalWithoutBtn = ({
  modalState = false,
  menuItem = false,
  backgroundColor,
  closeMenuItem,
  closeModal,
  singleModal = false,
  resetCloseModal,
  maxWidth,
  fullWidth = "100%",
  titleIcon,
  title,
  children,
  hideFooter = false,
  resetModalState,
  useType,
  Spouse, 
  buttonName, 
  setIsConfirm,
  showClose=true
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // if(singleModal) resetCloseModal()
    // if(menuItem) closeMenuItem()
  };

  useEffect(() => {
    // console.log(closeModal, "closeModal");
    if (modalState) handleClickOpen();
    if (closeModal) handleClose();
  }, [modalState, closeModal]);

  const handleClose = () => {
    // console.log("handleClose");
    setOpen(false);
    resetModalState();
  };

  const classes = useStyles();
  return (
    <DialogContainer
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState}
      width={useType === "prayerwalls" ? "100%" : ""}
      margin={useType === "prayerwalls" ? "10px" : ""}
      // className={classes.paper}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} showClose>
        {Spouse && typeof buttonName === 'string' ? <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {titleIcon}&nbsp;{title}
        {Spouse && typeof buttonName === 'string' && 
          (<ButtonContainer 
            backgroundColor={backgroundColor} 
            onClick={setIsConfirm}>{buttonName}
          </ButtonContainer>)}

        </div> : <span>{titleIcon}&nbsp;{title}</span>}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {hideFooter && (
          <ButtonContainer
            onClick={resetModalState}
            backgroundColor={backgroundColor}
          >
            close
          </ButtonContainer>
        )}
      </DialogActions>
    </DialogContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    width: "100%",
  },
  paper: {
    margin: "10px",
  },
}));

export default ResizeableModal;
