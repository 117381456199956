import React from 'react'
import FollowupHome from '../../Followup/FollowupHome'

export default function NewHere() {
    return (
        <div>
            <FollowupHome isDashboard={true}/>
        </div>
    )
}
