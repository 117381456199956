import React from "react";
import "./styles.scss";

const SuccessModal = ({ onClose, heading, subContent, btnText }) => {
  return (
    <div className="modal-container">
      <img
        src="/images/x-mark.png"
        alt="Close modal"
        className="close-icon"
        onClick={onClose}
      />

      <div className="modal-content">
        <div className="content">
          <img
            src="/images/success_logo.png"
            alt="Success logo"
            style={{ width: "fit-content", margin: "auto" }}
          />
          <h2>{heading}</h2>
          <p>{subContent}</p>
        </div>
        <button onClick={onClose} color="primary" className="homepage-button">
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
