import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {CircularProgress} from '@material-ui/core';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {Icon} from '@material-ui/core';
import {DialogContent} from '@material-ui/core';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';

export default function FormsModal({
  onClose,
  children,
  open,
  handleFormSubmit,
  loading = false,
  options = [],
  submitText,
  title,
  disabled,
  maxWidth = 'xs',
  showSubmitBtn = true,
  cancelText='Cancel'
}) {
  const themeColor = useThemeColor()
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <div className="d-flex align-items-center py-3">
          <p className="text-center mb-0" style={{marginLeft: 'auto'}}>
            {title}
          </p>
          <Icon
            style={{
              fontSize: '2rem',
              cursor: 'pointer',
              marginLeft: 'auto',
            }}
            onClick={() => onClose()}
          >
            close
          </Icon>
        </div>
      </DialogTitle>
      <DialogContent className="pb-4">
        <ValidatorForm onSubmit={handleFormSubmit}>
          {children}
          <div
            className="d-flex align-items-center justify-content-between mt-4"
            style={{columnGap: '2rem'}}
          >
            {showSubmitBtn && <div className="position-relative w-100">
              <Button
                variant="contained"
                // color="primary"
                disabled={loading || disabled}
                type="submit"
                className="w-full"
                style={{textTransform: 'capitalize', background: themeColor.color, color: '#fff'}}
              >
                {submitText}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>}

            <Button
              className="capitalize w-full"
              onClick={() => onClose()}
              disabled={loading}
              variant="outlined"
              style={{textTransform: 'capitalize'}}
            >
              {cancelText}
            </Button>
          </div>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
}
