import React, { useState, useEffect, useRef } from 'react';
import { RsvpGroup } from './RsvpCardGroup';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getQueryString } from '../../../utils';
import { rsvpInformationInstance, createMultipleRsvp } from '../../../store/actions'
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';

export const RSVPMainPage = () => {
  const [eventArr, setEventArr] = useState([]);
  const [rsvpDateGroup, setRsvpDateGroup] = useState({});
  const [selected, setSelected] = useState(false);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const rsvpInformationInstanceData = useSelector(state => state.rsvpInformationInstanceData, shallowEqual);
  const multipleRsvpData = useSelector(state => state.multipleRsvpData)
  const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.colorThemes[0].primary : '' }
  // const [email, setEmail] = useState('')
  const dispatch = useDispatch();
  const cpToken = getQueryString('cp');
  
  const percentage = (percent, total) => {
    return (100 - ((percent/total) * 100)).toFixed(0)
  }

  useEffect(() => {
    if(rsvpInformationInstanceData?.responseData){
      let rsvpByDate = {};
      let rsvpInfo = rsvpInformationInstanceData?.responseData;
      rsvpInfo.Events.map((rsvp) => {
        rsvp.isUserRsvped = false;
        rsvp.filledUp = false;
        rsvp.capacityPercentage = percentage(rsvp.dates[0].availableSpots, rsvp.dates[0].capacity);

        rsvpInfo.userExistingRsvped.filter((item) => {
          if(item.eventId === rsvp.id) rsvp.isUserRsvped = true;
        })
        // console.log(Number(rsvp.dates[0].availableSpots) === 0, 'availableSpot')
        if(Number(rsvp.dates[0].availableSpots) === 0) {
          rsvp.filledUp = true;
        }

        if(rsvpByDate[rsvp?.dates[0]?.date]) {
          return rsvpByDate[rsvp?.dates[0]?.date].
            push({
              dates: rsvp.dates,
              day: rsvp.day,
              description: rsvp.description,
              id: rsvp.id,
              name: rsvp.name,
              time: rsvp.time,
              isUserRsvped: rsvp.isUserRsvped,
              filledUp: rsvp.filledUp,
              capacityPercentage: rsvp.capacityPercentage,
            })
        }
        else return rsvpByDate[rsvp?.dates[0]?.date] = [rsvp]
      })
      setRsvpDateGroup(rsvpByDate)
    }
  }, [rsvpInformationInstanceData?.responseData])

  // const handleHideFamilyList = () => setShowFamilyList(false)
  
  // const handleShowFamilyList = () => setShowFamilyList(true)

  // const handleChange = (e) => {
  //   setEmail(e.target.value)
  // }
  const getEventObj = (isCardSelected, firstIndex, index, item) => {
    if(isCardSelected){
      // console.log(isCardSelected)
      setSelected(isCardSelected)
      return setEventArr([...eventArr, {event: item, firstIndex, index }])
    } 
    else{
      eventArr.map((evt, i) => {
        // console.log('index - ', i)
        if(evt.firstIndex === firstIndex && evt.index === index){
          // console.log('deleted index - ', i)
          eventArr.splice(i, 1);
        }})
      // setSelected(isCardSelected)
      // console.log(eventArr, 'remaining array after deletion')
      dispatch(createMultipleRsvp(eventArr))
      setEventArr(eventArr);
    }
  }

  useEffect(() => {
    if(multipleRsvpData?.clearAllRsvp){
      setEventArr([])
    }
  }, [multipleRsvpData.clearAllRsvp])

  useEffect(() => {
    // console.log(eventArr, 'event Array')
    dispatch(createMultipleRsvp(eventArr))
  // }, [selected, eventArr.length])
  }, [eventArr])

  useEffect(() => {
    dispatch(rsvpInformationInstance(cpToken))
  }, [])

  return (
    <div>
      <div className="rsvp login-signin" id="kt_login_signin_form">
        <div className="text-center mb-10 mb-lg-10">
          <h5 className="mb-5">RSVP System in-person service</h5>
        </div>
        <hr />
        <div className="w-100">
          {rsvpDateGroup && Object.keys(rsvpDateGroup).sort((a, b) => new Date(b) - new Date(a)).map((item, firstIndex) => {
            return (
              <div key={`b${firstIndex}`}>
                <div className="d-flex">
                  <Icon className="far fa-calendar-alt font-size-h6 mt-1 mr-5" />
                  <h5>{moment(item).format("MMM DD, YYYY")}</h5>
                </div>
                <RsvpGroup subGroup={rsvpDateGroup[item]} getMainEventObj={getEventObj} firstIndex={firstIndex} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
