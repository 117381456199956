import React from "react";
import './styles.scss';

const Donate = ({ data, isActive, handleClick, onDonation, themeMode }) => {

    const handleAmountGiving = () => {
        onDonation(data.donation_amount);
    }
    
    let containerStyle = 'card-container';
    let cardContainerHeaderStyle = 'card-container-header';
    let cardDonationAmount = 'card-donation-amount';
    let guarantees = 'guarantees';
    let guaranteeDescription = 'guarantee-description';
    let icon_path = '/images/donation_icon.png';
    let cardButton = 'card-button';

    if (isActive){
        containerStyle = 'card-popular-container';
        cardContainerHeaderStyle = 'popular-card-container-header';
        cardDonationAmount = 'popular-card-donation-amount';
        guarantees = 'popular-guarantees';
        guaranteeDescription = 'popular-guarantee-description';
        icon_path = '/images/popular_donation_icon.png';
        cardButton = 'popular-card-button';
    }

    let donateText = '';
    let guaranteeDescriptionColor = '';

    if (themeMode === 'light' && !isActive) {
        donateText = 'donate-light-theme';
        guaranteeDescriptionColor = 'guarantee-light-mode';
    }else if (themeMode === 'dark' && !isActive) {
        donateText = 'donate-dark-theme';
        guaranteeDescriptionColor = 'guarantee-dark-mode';
    }

    return (
        <div className={containerStyle} onClick={handleClick}>
            <div className={cardContainerHeaderStyle}>
                <p className={donateText}>Donate</p>
                { data.isPopular && <div className='popular-card'>Popular</div> }
            </div>
            <div className={cardDonationAmount}>
                <p>${data.donation_amount}</p>
                <span>/month</span>
            </div>
            <p className={guarantees}>Guarantees:</p>
            <div className={guaranteeDescription}>
                <div className="card-icon">
                    <img 
                        src={icon_path}
                        alt='Donate Icon'
                    />
                    <img 
                        src={icon_path}
                        alt='Donate Icon'
                    />
                </div>
                <div>
                    <p className={guaranteeDescriptionColor}>{data.first_guarantee_description}</p>
                    <p className={guaranteeDescriptionColor}>{data.second_guarantee_description}</p>
                </div>
            </div>
            <button onClick={handleAmountGiving} className={cardButton} type='button'>Give Now</button>
        </div>
    )
}

export default Donate;