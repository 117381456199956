import React from "react";
import { CustomSwitch } from "./Common/CustomSwitch";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updatePaymentMode } from "../store/actions";
import { Icon } from "@material-ui/core";

const PaymentToggle = () => {
  const dispatch = useDispatch();
  const { isAdvanced } = useSelector(
    (state) => state.getPaymentMode,
    shallowEqual
  );

  const onChange = () => {
    dispatch(updatePaymentMode({ isAdvanced: !isAdvanced }));
  };
  return (
    <div className="d-flex justify-content-center align-items-center">
      <label
        htmlFor="togglePayment"
        className="text-light d-flex align-items-center"
      >
        <Icon className="mr-2">volunteer_activism</Icon> Switch to{" "}
        {!isAdvanced ? "Advanced" : "Simple"} Giving:{" "}
      </label>
      <CustomSwitch
        onChange={onChange}
        id="togglePayment"
        checked={isAdvanced}
      />
    </div>
  );
};

export default PaymentToggle;
