import React, { useRef } from "react";
import "./style.css";
import { Icon } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CardForm } from "./CardForm";
import Navigator from "../../components/Navigator";
import { useSelector } from "react-redux";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import CheckForm from "./CheckForm";
import CreditCardUi from "../../components/CreditCardUi/CreditCardUi";

export function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const ChoosePayment = ({
  existingCards,
  goBack,
  goNext,
  selectedValue,
  selectedCard,
  setSelectedCard,
  handleChange,
  tabIndex,
  userInfo,
  org_id,
}) => {
  const amount = useSelector((state) => state.donatedAmountData.amount);
  const selectedPayment = useSelector((state) => state.selectedPaymentOption);
  const { color } = useThemeColor();
  const navBtnRef = useRef(null);

  const getIndex = () => (existingCards?.length > 0 ? 1 : 0);

  React.useEffect(() => {
    if (existingCards?.length === 1) {
      const { recordId, last4Digits, expirationDate } = existingCards[0];
      setSelectedCard({
        recordId,
        last4Digits,
        expirationDate,
      });
    }
  }, [existingCards?.length]);

  const giveForm = () => {
    const paymentOption = selectedPayment?.type.toLowerCase();
    switch (paymentOption) {
      case "check":
        return (
          <CheckForm
            goBack={goBack}
            goNext={goNext}
            userInfo={userInfo}
            org_id={org_id}
          />
        );
      case "card":
        return <CardForm goBack={goBack} goNext={goNext} userInfo={userInfo} />;

      default:
        return <Navigator goBack={goBack} goNext={goNext} />;
    }
  };

  const scrollToView = () =>
    navBtnRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <div>
      <div className="details p-4">
        <p className="font-size-h4 text-center">Confirm your gift</p>
        <div className="d-flex justify-content-center mt-4">
          <p className="text-center selected-price">
            {Number(amount)
              .toFixed(2)
              .toString()}
          </p>
          <span style={{ fontSize: "12px" }}>{selectedPayment.currency}</span>
        </div>
        <p className="font-size-h4 text-center">
          Gift of{" "}
          {Number(amount)
            .toFixed(2)
            .toString()}{" "}
          <span>{selectedPayment.currency}</span> towards {selectedValue}.
        </p>

        {/* <p className="font-size-h5 text-center mt-5">change details</p> */}
      </div>

      <div className="p-4">
        <p className="mb-4" style={{ paddingLeft: "12px" }}>
          Choose a Payment method
        </p>

        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="emergency tabs"
          variant="fullWidth"
          className="mb-5"
        >
          {existingCards?.length > 0 && (
            <Tab
              label={`Give With Existing ${selectedPayment?.type}`}
              {...a11yProps(0)}
              className="upperCase font-size-sm"
            />
          )}
          <Tab
            label={`Give With New ${selectedPayment?.type}`}
            {...a11yProps(getIndex())}
            className="upperCase font-size-sm"
          />
        </Tabs>

        {tabIndex === 0 && existingCards?.length > 0 && (
          <>
            {existingCards?.map(
              ({
                last4Digits,
                firstName,
                lastName,
                recordId,
                cardMaker,
                expirationDate,
              }) => (
                <>
                  {selectedPayment?.type.toLowerCase() === "check" ? (
                    <div
                      className="credit-card-smart-give mb-3"
                      key={recordId}
                      style={
                        selectedCard?.recordId === recordId
                          ? { borderColor: color, borderWidth: "medium" }
                          : {}
                      }
                      onClick={() => {
                        setSelectedCard({ recordId, last4Digits });
                        scrollToView();
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="font-size-h5">
                          {firstName} {lastName}
                        </p>
                        {/* <Icon className="card-icon">check</Icon> */}
                        <p className="text-capitalize">{cardMaker}</p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <p className="font-size-h5">**********{last4Digits}</p>
                        <Icon className="card-icon">account_balance</Icon>
                        {/* <img src={checkIcon} alt="" /> */}
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setSelectedCard({
                          recordId,
                          last4Digits,
                          expirationDate,
                        });
                        scrollToView();
                      }}
                      className="mb-4"
                    >
                      <CreditCardUi
                        last4Digits={last4Digits}
                        lastName={lastName}
                        firstName={firstName}
                        cardMaker={cardMaker}
                        expirationDate={expirationDate}
                        styles={
                          selectedCard?.recordId === recordId
                            ? { border: `solid 5px ${color}` }
                            : {}
                        }
                      />
                    </div>
                  )}
                </>
              )
            )}
            <div ref={navBtnRef}>
              <Navigator goBack={goBack} goNext={goNext} />
            </div>
          </>
        )}

        {tabIndex === getIndex() && <>{giveForm()}</>}
      </div>
    </div>
  );
};

export default ChoosePayment;
