import React, { useState, useEffect } from 'react';
import renderHTML from 'react-render-html';
import moment from 'moment';
import clsx from 'clsx';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Paper from '@material-ui/core/Paper';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getQueryString } from '../../../utils';
import { CustomIconButton, CustomCheckBox, RsvpCheckbox, RsvpGuestCheckbox } from './RsvpCheckbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { TextField } from '@material-ui/core';
import ConfirmationDialog from "../../Common/ConfirmDialog";
import { isEmpty } from '../../../utils'
import { getUserProfile, processRsvp, cancelRsvp, deleteGuest, rsvpInformationInstance } from '../../../store/actions';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';


const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const CustomTextField = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: 'grey'
    },
    '& .MuiInputBase-input': {
      color: 'grey'
    },
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'grey',
      },
    },
  },
})(TextField);

export const RsvpCardDetails = ({ item }) => {
  const themeColor = useThemeColor();
  const classes = useStyles();
  const cpToken = getQueryString('cp');
  const [expanded, setExpanded] = React.useState(false);
  const [childArr, setChildArr] = React.useState([]);
  const [guestArr, setGuestArr] = React.useState([]);
  const [showCard, setShowCard] = useState(false);
  const [mobile, setMobile] = useState('');
  const [guest, setGuest] = useState({});
  const [isSpouseChecked, setIsSpouseChecked] = useState(false);
  const [spouseId, setSpouseId] = useState('');
  const [showFamilyList, setShowFamilyList] = useState(false);
  const [deleteExistingGuest, setDeleteExistingGuest] = useState({ show: false, guest: {}, index: -1 })
  const [cancelEvent, setCancelEvent] = useState({ show: false, event: {} })
  const [showGuestForm, setShowGuestForm] = useState(false);
  const [excessGuestError, setExcessGuestError] = useState(false);
  const [newGuestList, setNewGuestList] = useState([]);
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('');
  // const [isRsvpSuccess, setIsRsvpSuccess] = useState(false);
  const rsvpInformationInstanceData = useSelector(state => state.rsvpInformationInstanceData, shallowEqual);
  const processRSVPData = useSelector(state => state.processRSVPData, shallowEqual);
  const cancelRSVPData = useSelector(state => state.cancelRSVPData, shallowEqual);
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const userId = rsvpInformationInstanceData?.responseData?.userId;
  const deleteGuestData = useSelector((state) => state.deleteGuest);
  // const getThemeChangeData = useSelector(state => state.getThemeChangeData)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ipData = useSelector(state => state.ipData);
  const dispatch = useDispatch()

  const handleShowReviewDetails = () => setShowCard(true);

  const handleHideRsvpDetails = () => {
    // if(showCard) return setShowCard(false);
    if(showCard && !showFamilyList) {
      setShowFamilyList(false);
      return setShowCard(false)
    }
    if(showCard  && showFamilyList) {
      setShowCard(true);
      return setShowFamilyList(false);
    }
  } 
  
  const handleHideFamilyList = () => setShowFamilyList(false)
  
  const handleShowFamilyList = (item) => {
    let payload = {};
    let userIdsToRsvp = [];
    let newGuestsToRsvp = [];
    let dates = [];
    if(!showFamilyList){
      return setShowFamilyList(true);
    } else{
      payload.orgId = orgInfo.orgId;
      payload.userId = userId;
      if(guestArr.length > orgInfo?.usersGeneralSettings?.rsvp.numberOfGuests){
        return setExcessGuestError(true);
      }
      // payload.dates[0].date = item.dates[0].date;
      // payload.dates[0].id = item.dates[0].id;
      // payload.userIdsToRsvp = [...payload.userIdsToRsvp, userId, spouseId];
      userIdsToRsvp.push(userId);
      if(spouseId){
        userIdsToRsvp.push(spouseId);
      }
      dates.push({ date: item.dates[0].date, id: item.id})
      if(guestArr?.length > 0){
        guestArr.map((guest) => {
          userIdsToRsvp.push(guest.guest.guestUserId)
        })
      }
      if(childArr?.length > 0){
        childArr.map((child) => {
          userIdsToRsvp.push(child.child.childUserId)
        })
      }
      if(newGuestList?.length > 0){
        newGuestList.map((newGuest) => {
          newGuestsToRsvp.push(newGuest)
        })
      }
    }
    payload.userIdsToRsvp = userIdsToRsvp;
    payload.newGuestsToRsvp = newGuestsToRsvp;
    payload.dates = dates
    dispatch(processRsvp(payload, updatePageData))
  } 

  useEffect(() => {
    if(guestArr.length <= orgInfo?.usersGeneralSettings?.rsvp.numberOfGuests){
      setExcessGuestError(false);
    }
  }, [guestArr])

  useEffect(() => {
    if(excessGuestError){
      setTimeout(() => {
        setExcessGuestError(false)
      }, 6000)
    }
  }, [excessGuestError])

  const handleCancelRSVP = (item) => {
    // console.log(item, 'item')
    // console.log(rsvpInformationInstanceData, 'rsvpInformationInstanceData')
    let userIdsToUnRsvp = [];
    const payload = {
      orgId: orgInfo.orgId,
      userId,
      id: item.id,
      date: item.dates[0].date,
    };
    rsvpInformationInstanceData.responseData.childrenExistingRsvped.filter((child) => {
      if(child.eventId === item.id) userIdsToUnRsvp.push(child.childUserId)
    })
    rsvpInformationInstanceData.responseData.spouseExistingRsvped.filter((spouse) => {
      if(spouse.eventId === item.id) userIdsToUnRsvp.push(spouse.spouseUserId)
    })
    rsvpInformationInstanceData.responseData.userExistingRsvped.filter((user) => {
      if(user.eventId === item.id) userIdsToUnRsvp.push(user.userId)
    })
    rsvpInformationInstanceData.responseData.guestsExistingRsvped.filter((guest) => {
      if(guest.eventId === item.id) userIdsToUnRsvp.push(guest.guestUserId)
    })
    payload.userIdsToUnRsvp = userIdsToUnRsvp;
    dispatch(cancelRsvp(payload, updatePageData))
  }
  
  const updatePageData = () => {
    setCancelEvent({...cancelEvent, show: false}) 
    dispatch(rsvpInformationInstance(cpToken))
    setShowFamilyList(false)
    setShowCard(false)
  }

  const handleChange = (e) => setGuest({...guest, [e.target.name]: e.target.value})

  const handleShowGuestForm = () => setShowGuestForm(true);

  const userDetails = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getUserProfile({'orgId': orgInfo.orgId, userId}))
  }, [])
  
  const updateGuestProfile = () => {
    dispatch(getUserProfile({'orgId': orgInfo.orgId, userId}))
    setDeleteExistingGuest({...deleteExistingGuest, show: false})
  }

  const handleSpouseCheckState = (spouseId) => {
    setIsSpouseChecked(!isSpouseChecked);
    setSpouseId(spouseId)
  }

  useEffect(() => {
    if(isSpouseChecked) setSpouseId(spouseId)
    else setSpouseId('');
  }, [isSpouseChecked])
  
  const getChildValue = (data, checkedState, index) => {
    if(checkedState){
      setChildArr([...childArr, { child: data, index }]);
    } 
    else {
      childArr.map((item, i) => {
        if(item.index === index) childArr.splice(i, 1);
      })
      setChildArr(childArr);
    }
  }

  const getGuestValue = (data, checkedState, index) => {
    if(checkedState){
      setGuestArr([...guestArr, { guest: data, index }]);
    } 
    else {
      guestArr.map((item, i) => {
        if(item.index === index) guestArr.splice(i, 1);
      })
      setGuestArr(guestArr);
    }
  }
  
  // let guestId = -1;
  const handleAddGuest = (e) => {
    e.preventDefault();
    if(!guest.firstName){
      return setFirstNameErrorMsg('Firstname is Required');
    }
    if(!guest.lastName){
      guest.lastName = '';
    }
    if(mobile && isValidPhoneNumber(mobile)){
      guest.mobile = mobile;
    } else guest.mobile = '';
    setNewGuestList([...newGuestList, guest]);
    setGuest({firstName: '', lastName: ''})
    setMobile('')
  }

  const deleteGuestConfirmationBox = (guest) => {
    setDeleteExistingGuest({...deleteExistingGuest,
      show: true,
      guest
    })
  }

  const cancelRsvpConfirmationBox = (event) => {
    setCancelEvent({...cancelEvent,
      show: true,
      event
    })
  }
  // console.log(deleteExistingGuest, 'deleteExistingGuest')

  const handleDeleteExistingGuest = (guest) => {
    const payload = {
      orgId: orgInfo.orgId,
      userId,
    }
    const guestUserIds = []
    if(guest){
      guestUserIds.push(guest.guestUserId);
      payload.guestUserIds = guestUserIds;
      dispatch(deleteGuest(payload, updateGuestProfile));
    }
  }
 
  const handleDeleteNewGuest = (index) => {
    const sampleList = newGuestList.filter((guest, i) => {
      if(i !== index) return newGuestList;
    })
    setNewGuestList(sampleList);
  }
  
  useEffect(() => {
    if(newGuestList?.length < 1) {
      setShowGuestForm(false);
    }
  }, [newGuestList])

  const handleCloseForm = () => setShowGuestForm(false)


  return (
    <>
      {!item.filledUp ?
        !showCard && <div className="px-10 mt-2" >
          {!showFamilyList && <Button as={Link} onClick={handleShowReviewDetails} className={`px-0 mb-5 ${!item.isUserRsvped ? 'text-dark' : ''} ${item.isUserRsvped ? 'text-white' : ''}`}>{`CLICK TO ${item.isUserRsvped ? 'UN': ''}RESERVE`}</Button>}
        </div> : <div className="px-10 mt-2" >
          <Button as={Link} onClick={handleShowReviewDetails} className={`px-0 mb-5`}>{`CLICK TO VIEW`}</Button>
      </div>}
      {showCard && <div className="w-100 mt-3">
        <p className="px-10 mb-3">{renderHTML(item.description)}</p>
        {showFamilyList && <div className="px-10">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0">FAMILY MEMBERS</h6>
            <HighlightOffTwoToneIcon className="cursor mr-2" onClick={handleHideFamilyList} fontSize="medium" />
          </div>
          <p className="font-size-sm">{`${item.day} ${moment(item.dates[0].date).format("MMM, YY")}`}</p>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0">{`${userDetails?.userFirstName} ${userDetails?.userLastName}`}</h6>
            <CustomCheckBox icon={<RadioButtonUncheckedIcon fontSize="medium" />} checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />} checked disabled name="checkedH" />
          </div>
          {orgInfo?.usersGeneralSettings?.rsvp.allowSpouse && (!isEmpty(userDetails?.userSpouseInfo) || userDetails?.userSpouseInfo?.length > 0) && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center"><h6 className="mb-0 mr-1">{`${userDetails?.userSpouseInfo?.spouseFirstName} ${userDetails?.userSpouseInfo?.spouseLastName}`}</h6>
              <small>(spouse)</small> </div>
              <CustomCheckBox
                onChange={() => handleSpouseCheckState(userDetails?.userSpouseInfo?.spouseUserId)}
                checked={isSpouseChecked} 
                icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />} 
                name="spouse" />
            </div>
          )}
          {orgInfo?.usersGeneralSettings?.rsvp.allowChildren && userDetails?.userChildrenInfo?.map((child, index) => <div key={index} className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0">{`${child.childFirstName} ${child.childLastName}`}</h6>
            <RsvpCheckbox 
              item={child}
              index={index}
              getChildValue={getChildValue}
              icon={<RadioButtonUncheckedIcon fontSize="medium" />} 
              checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />} 
              name="children"
            />
          </div>)}
          {orgInfo.usersGeneralSettings.rsvp.allowGuests && (!isEmpty(userDetails?.userGuestsInfo) || userDetails?.userGuestsInfo?.length > 0) && <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0" onClick={handleExpandClick}>GUESTS</h6>
            <CustomIconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </CustomIconButton>
          </div>}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {userDetails?.userGuestsInfo?.map((guest, index) => <div key={index} className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0">{`${guest.guestFirstName} ${guest.guestLastName}`}</h6>
              <div>
                <DeleteIcon onClick={() => deleteGuestConfirmationBox(guest)} className="cursor mr-3 text-danger" fontSize="medium"/>
                {/* <DeleteIcon onClick={() => handleDeleteExistingGuest(guest)} className="cursor mr-3" color="danger"  fontSize="medium"/> */}
                <RsvpGuestCheckbox 
                  item={guest}
                  index={index}
                  getGuestValue={getGuestValue}
                  icon={<RadioButtonUncheckedIcon fontSize="medium" />} 
                  checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />} 
                />
              </div>
            </div>)}
          </Collapse>
        </div>} 
        {showFamilyList && <div className="px-10">
          {newGuestList?.length > 0 && <h6>NEW GUESTS</h6>}
          {newGuestList?.map((guest, index) => <div key={index} className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0">{`${guest.firstName} ${guest.lastName}: ${guest.mobile}`}</h6>
            <div>
              <DeleteIcon onClick={() => handleDeleteNewGuest(index)} className="cursor mr-3 text-danger" fontSize="medium"/>
              <CustomCheckBox icon={<RadioButtonUncheckedIcon fontSize="medium" />} checkedIcon={<CheckCircleTwoToneIcon fontSize="medium" />} checked disabled name="checkedH" />
            </div>
          </div>)}
          {!showGuestForm && <Button style={themeColor.btnStyle} onClick={handleShowGuestForm} className={`mb-5 text-light`} variant="contained" color="primary">
            Add Guest
          </Button>}
          <hr />
          {showGuestForm && <div className="mt-3">
            <CustomTextField
              label="FirstName"
              name="firstName"
              value={guest.firstName}
              onChange={handleChange}
              required
              type="text"
              id="firstName"
              size="small"
              className="mb-5 text-dark"
              variant="outlined"
              error={!!firstNameErrorMsg}
              helperText={firstNameErrorMsg}
              fullWidth
            />
            <CustomTextField
              label="LastName"
              name="lastName"
              type="text"
              value={guest.lastName}
              onChange={handleChange}
              id="lastName"
              size="small"
              className="mb-5"
              variant="outlined"
              fullWidth
            />
            <div className="mb-5">
              <PhoneInput
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                defaultCountry={ipData ? ipData.country_code : null}
                placeholder="Mobile"
                id="loginMobile"
                value={mobile}
                onChange={setMobile}
                className={`form-control text-dark-50 h-auto py-3 px-6 mobile ${(mobile && !isValidPhoneNumber(mobile)) ? 'is-invalid' : (mobile && isValidPhoneNumber(mobile)) ? 'is-valid' : ''}`}
                name="mobile"
                error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid mobile number') : 'Mobile number is required'}
              />
              {(mobile && !isValidPhoneNumber(mobile)) ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">Invalid mobile number</div>
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-between">
              <Button style={themeColor.btnStyle} onClick={handleAddGuest} className={`mb-5 text-light`}>
                Include Guest
              </Button>
              <Button onClick={handleCloseForm} className={`mb-5 bg-gray-400 text-dark`}>
                Cancel
              </Button>
            </div>
          </div>}
          {excessGuestError && <p className="text-danger mb-3">{`Guest cannot be more than ${orgInfo.usersGeneralSettings.rsvp.numberOfGuests}`}</p>}
        </div>} 
        <div className="w-100 d-flex">
          {!item.filledUp ? <>{item.isUserRsvped ? <div onClick={() => cancelRsvpConfirmationBox(item)} className="w-50 cursor p-5 text-center font-weight-bolder bg-danger">{`${'UNRESERVE' }`}</div> :
            <div style={themeColor.btnStyle} onClick={() => handleShowFamilyList(item)} className={`w-50 cursor p-5 text-center font-weight-bolder text-light`}>{`${processRSVPData?.loading && showFamilyList ? 'PLEASE WAIT...' : 'RESERVE' }`}</div>}</> : 
            <div className="w-50 px-10 py-5 text-center font-weight-bolder bg-success"></div>}
          <div onClick={handleHideRsvpDetails} className="w-50  px-10 py-5 cursor font-weight-bolder  text-center bg-gray-400">CANCEL</div>
        </div> 
      </div>}
      <ConfirmationDialog 
        open={deleteExistingGuest.show}
        text='Are you sure you want to delete this guest?'
        onConfirmDialogClose= {() => setDeleteExistingGuest({...deleteExistingGuest, show: false})}
        onYesClick={() => handleDeleteExistingGuest(deleteExistingGuest.guest)}
        isLoading={deleteGuestData?.loading}
      />
      <ConfirmationDialog 
        open={cancelEvent.show}
        text={`Are you sure you want to cancel your reservation for ${cancelEvent.event.name}?`}
        onConfirmDialogClose= {() => setCancelEvent({...cancelEvent, show: false})}
        onYesClick={() => handleCancelRSVP(cancelEvent.event)}
        isLoading={cancelRSVPData?.loading}
      />
    </>
  )
}