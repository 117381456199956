import { checkNodeEnvNextGen } from "./authHelpers";
import React from "react";
import { Tooltip } from "@material-ui/core";

const IPDATA_API_KEY =
  "dc8dbd11deadd2fcc5ade488093c6188888af0f9dc28c270bffb7a39";
const BASIC_AUTH_USERNAME = "cpGridLucky";
const BASIC_AUTH_PASSWORD = "L1feStyle2WEalth#1891";

export const SYSTEM_TIMEOUT = 1000 * 60 * 0.2;

export const URL = {
  ipDataUrl: `https://api.ipdata.co?api-key=${IPDATA_API_KEY}`,
  prodOrgUrl: "https://live-api.churchpad.com/v2/org-info-by-domain-name-web/",
  devOrgUrl: "https://test-api.churchpad.com/v2/org-info-by-domain-name-web/",
  testDomain: "demo.churchpad.com",
};

// asyncMethod needed as payload for axios calls
export const asyncMethod = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
};

export const nextgenUrl = {
  live: "https://apix.churchpad.com/nextgen",
  staging: "https://apps1.churchpad.com/nextgen",
};

export const cpUrls = {
  live: "https://cp-live.churchpad.com/api",
  staging: "https://cp-test.churchpad.com/api",
};


// api endpoint url
export const apiUrl = {
  orgInfo: (origin, params) => {
    if (process.env.REACT_APP_ENV === "local")
      return `${origin}?orgDomainName=${URL.testDomain}`;
    else return `${origin}?orgDomainName=${params}`;
  },
  orgInfoMaster: (origin, params) => {
    // if (process.env.REACT_APP_ENV === "local")
    //   return `${origin}?orgDomainName=${URL.testDomain}`;
    return `${origin}?orgDomainName=${params}`;
  },
  orgInfo2: (domain) =>
    `https://${domain}.churchpad.com/v2/org-info-by-id-web/`,
  forgotPassword: (domain) =>
    `https://${domain}.churchpad.com/v2/forgotpassword/`,
  forgotPin: (domain) => `https://${domain}.churchpad.com/v2/forgotpin/`,
  verifyForgotPasswordorPin: (domain) =>
    `https://${domain}.churchpad.com/v2/verifytokenforgotpasswordorpin/`,
  resetPassword: (domain) =>
    `https://${domain}.churchpad.com/v2/resetpasswordorpinbytoken/`,
  login: (domain) => `https://${domain}.churchpad.com/v2/login/`,
  signup: (domain) => `https://${domain}.churchpad.com/v2/signup/`,
  orgInfoList: (domain) =>
    `https://${domain}.churchpad.com/v2/org-info-additional-data-web/`,
  updateInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-profile/`,
  church411: (domain) =>
    `https://${domain}.churchpad.com/v2/church411-directory/`,
  addAChildurl: (domain) =>
    `https://${domain}.churchpad.com/v2/child-profile-manager/`,
  userProfileUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/get-user-profile/`,
  prayerWalls: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-walls/`,
  prayerWallsLikeOrPray: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-walls-like-or-pray/`,
  addPrayerWalls: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-fellowship-walls/`,
  orgOtherList: (domain) =>
    `https://${domain}.churchpad.com/v2/org-info-additional-data-web/`, //duplicated
  archivePrayerUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-walls-archive/`,
  answeredPrayerUrl: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/mark-answered-share-testimony/`,
  getUserProfile: (domain) =>
    `https://${domain}.churchpad.com/v2/get-user-profile-mportal/`,
  userContribution: (domain) =>
    `https://${domain}.churchpad.com/v2/user-contributions-history/`,
  // userImageUrl: domain => `https://${domain}.churchpad.com/v2/media-upload-api/`,
  giveWithCard: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-card/`,
  giveWithCheck: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-check/`,
  updatePasswordAndEmail: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-email-password-pin/`,
  cardsOrCheckingProfiles: (domain) =>
    `https://${domain}.churchpad.com/v2/cards-checking-profiles-on-file-portal/`,
  giveWithANewCard: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-a-new-card-portal/`,
  giveWithANewCheck: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-a-new-checking-portal/`,
  existingRecurringProfilesPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/existing-recurring-profiles-portal/`,
  deleteCardOrCheckingPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-card-or-checking-portal/`,
  giveWithAnExistingCardCheckingPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-an-existing-card-checking-portal/`,
  setAsDefaultCardChecking: (domain) =>
    `https://${domain}.churchpad.com/v2/set-as-default-card-checking/`,
  cancelARecurring: (domain) =>
    `https://${domain}.churchpad.com/v2/cancel-a-recurring/`,
  setupANewRecurringPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/setup-a-new-recurring-portal/`,
  updatePasswordAndEmail: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-email-password-pin/`,
  // addAChildurl: domain => `https://${domain}.churchpad.com/v2/child-profile-manager/`,
  contributionPdf: (domain) =>
    `https://${domain}.churchpad.com/v2/generate-pdf-documents/`,
  setOrUnset: (domain) =>
    `https://${domain}.churchpad.com/v2/set-or-unset-spouse/`,
  searchForSpouse: (domain) =>
    `https://${domain}.churchpad.com/v2/search-for-a-spouse/`,
  // updatePasswordAndEmail: domain => `https://${domain}.churchpad.com/v2/update-user-email-password-pin/`,
  deleteChildurl: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/child-profile-manager/`,
  updateARecurringPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/update-a-recurring-portal/`,
  contributionPdf: (domain) =>
    `https://${domain}.churchpad.com/v2/generate-pdf-documents/`,
  userImageUrl: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/media-upload/`,
  updateUser2FA: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-2fa/`,
  verify2FA: (domain) => `https://${domain}.churchpad.com/v2/verify-2fa/`,
  initiateRSVPUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/rsvp-checkin-verification-code-initiate/`,
  verifyRSVPUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/rsvp-checkin-process-verification-code/`,
  checkInInformation: (domain, payload) =>
    `https://${domain}.churchpad.com/v2/checkin-information-instances/${payload}`,
  processCheckin: (domain) =>
    `https://${domain}.churchpad.com/v2/process-check-in/`,
  deleteGuest: (domain) => `https://${domain}.churchpad.com/v2/delete-guests/`,
  processCheckOut: (domain) =>
    `https://${domain}.churchpad.com/v2/process-check-out/`,
  processRSVPUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/process-rsvp/`,
  cancelRSVPUrl: (domain) => `https://${domain}.churchpad.com/v2/cancel-rsvp/`,
  rsvpInformationInstanceUrl: (domain, queryString) =>
    `https://${domain}.churchpad.com/v2/rsvp-information-instances/?cp=${queryString}`,
  contributionProcessingEnginesUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/contribution-processing-engines-web/`,
  yearlyContributionStatements: (domain) =>
    `https://${domain}.churchpad.com/v2/yearly-contribution-statements/`,
  getUserProfileByWebAccessToken: (domain) =>
    `https://${domain}.churchpad.com/v2/get-user-profile/`,
  processPaypalUrl: (domain) =>
    `${domain}/payment-flow/api/nextgen/paypal/create-order`,
  getFollowUpByOrganization: (domain, orgId) =>
    `${domain}/api/follow-ups/organization/${orgId}`,
  getForm: (domain, id) => `${domain}/api/forms/${id}`,
  submitForm: (domain, id, formId) =>
    `${domain}/api/forms/${formId}/organization/${id}/submit-form`,
  jwtAccessToken: (domain) => `${domain}/api/authenticate`,
  paystack: (domain) =>
    `${domain}/payment-flow/api/nextgen/paystack/create-charge`,
  Flutterwave: (domain) =>
    `${domain}/payment-flow/api/nextgen/flutterwave/create-charge`,
  Stripe: (domain) => `${domain}/payment-flow/api/nextgen/stripe/create-charge`,
  imNewHereSubmission: (domain) =>
    `https://${domain}.churchpad.com/v2/im-new-here-submission-web/`,
  loginByToken: (domain) =>
    `https://${domain}.churchpad.com/v2/login-by-token/`,
  checkInListingsStatus: (domain) =>
    `https://${domain}.churchpad.com/v2/check-in-listings-status-web/`,
  rsvpListingsStatus: (domain) =>
    `https://${domain}.churchpad.com/v2/rsvp-listings-status-web/`,
  epayByToken: (domain) => `https://${domain}.churchpad.com/v2/epay-by-token/`,
  processEpayByToken: (domain) =>
    `https://${domain}.churchpad.com/v2/epay-by-token-process/`,
  addPhoneNumber: (domain) =>
    `https://${domain}.churchpad.com/v2/add-phone-number/`,
  deletePhoneNumber: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-phone-number/`,
  preferredPaymentNewCard: (domain) =>
    `${domain}/payment-flow/api/nextgen/pps/give-with-new-card`,
  preferredPaymentExistingCard: (domain) =>
    `${domain}/payment-flow/api/nextgen/pps/give-with-existing-card`,
  preferredPaymentNewCheck: (domain) =>
    `${domain}/payment-flow/api/nextgen/pps/give-with-new-checking`,
  appAccess: (domain) =>
    `https://${domain}.churchpad.com/v2/app-access-portal/`,
  quickGiveProcessingEngines: (domain) =>
    `https://${domain}.churchpad.com/v2/quick-give-processing-engines-web/`,
  upcomingEvent: (domain, query) =>
    `${domain}/nextgen/api/events/organization/${
      query.id
    }/upcoming-live-streaming?month=${query.month}&year=${query.year}&zone-id=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
  imNewHereDynamicForm: (domain) =>
    `https://${domain}.churchpad.com/v2/im-new-here-dynamic-form-web/`,
  celebrationsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/celebrations-listing-web/`,
  getPrivacyPolicyHtml: (domain) =>
    `https://${domain}.churchpad.com/v2/get-privacy-policy-html-web/`,
  getOrganizationForms: (orgId) => `forms?org_id=${orgId}`,
  submitFormAnswer: (formId) =>`forms/${formId}/submissions`,
  getFormSchema: (domain, schemaId) => `${domain}/api/formSchemas/${schemaId}`,
  submitFormAnswerForUser: (formId) =>`forms/${formId}/submissions/me`,
  getFormQuestionAndAnswer: (id, orgId) => `forms/${id}?org_id=${orgId}`,
  followUpListings: (domain) =>
    `https://${domain}.churchpad.com/v2/follow-up-listings/`,
  followUpLogActivity: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/follow-up-log-activity/`,
  guestActivityLog: (domain) =>
    `https://${domain}.churchpad.com/v2/guest-activity-log/`,
  guestSubmittedForm: (domain) =>
    `https://${domain}.churchpad.com/v2/guest-submitted-form/`,
  groupUsers: (domain, groupd, status) =>
    `${domain}/api/groups/${groupd}/users?page=0&size=50&status=${status}`,
  groupUsersPending: (domain, status, type) =>
    `${domain}/api/groups/organization/250/users?page=0&size=50&status=${status}&type=${type}`,
  deleteUsersFromDepartmentGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-users-from-department/`,
  childrenModuleSpecificInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/children-module-specific-info/`,
  mediaVaultListing: (domain) =>
    `https://${domain}.churchpad.com/v2/media-vault-listing/`,
  sendCommunicationChildren: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/send-communication-parents-analyze/`,
  sendCommunicationToSpecialGroupAnalyze: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/send-communication-to-special-group-analyze/`,
  getOrgMessage: (domain) =>
    `https://${domain}.churchpad.com/v2/org-messaging-data-web/`,
  sendCommunicationToSpecialGroupProcess: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/send-communication-to-special-group-process/`,
  doSearchUserToJoinDept: (domain) =>
    `https://${domain}.churchpad.com/v2/search-user-to-join-department/`,
  positionsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/positions-listing/`,
  addUsersToDepartment: (domain) =>
    `https://${domain}.churchpad.com/v2/add-user-to-department/`,
  renameDepartmentGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/rename-department/`,
  editInterestServiceGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/edit-interest-service-group/`,
  doSearchUser: (domain) => `https://${domain}.churchpad.com/v2/search-user/`,
  changeGroupBanner: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/change-group-banner/`,
  approveUser: (domain, id, groupId) =>`${domain}/api/groups/${groupId}/users/${id}/approve`,
  getUserFamilyDataMportal: (domain) =>
    `https://${domain}.churchpad.com/v2/get-user-family-data-mportal/`,
  addRemoveUserAdminAccess: (domain) =>
    `https://${domain}.churchpad.com/v2/add-remove-dept-admin-permission/`,
  getUserDataSmartGive: (domain) =>
    `https://${domain}.churchpad.com/v2/get-user-data-smart-give/`,
  verifyRouting: (domain) =>
    `https://${domain}.churchpad.com/v2/verify-usa-bank-routing-number/`,
  generateOtp: (domain) => `${domain}api/users/otp/`,
  deleteUserAccount: (domain) => `${domain}api/users/delete_account/`,
  organizationList: (domain) => `${domain}api/organizations/list_orgs/`,
  mserviceLogin: (domain) => `${domain}api/auth/`,
  getGivingCampaignInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/get-giving-campaign-info/`,
    getDynamicGivingCampaignInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/get-giving-campaign-info-dynamic/`,
    giveMethods: () => `give/methods`,
};

// basicAuthPayload payload needed for axios calls
export const basicAuthPayload = {
  username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
  password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
};

export const defaultAction = (type, payload) => ({ type, payload });

// grabs the domain name from browser URL like www.example.com
export const domainName = () => {
  if (window.location.hostname === "onboardinguser.churchpad.com")
    return `${window.location.hostname}${window.location.pathname}`;
  return window.location.hostname;
};

export const nodeEnv = {
  production: "production",
  development: "development",
  test: "test",
};

export const MAX_AMOUNT_LENGTH = 1000000000;

export const repeatNumbers = [
  "0000",
  "1111",
  "2222",
  "3333",
  "4444",
  "5555",
  "6666",
  "7777",
  "8888",
  "9999",
];

export const months = [
  { id: "01", value: "January" },
  { id: "02", value: "February" },
  { id: "03", value: "March" },
  { id: "04", value: "April" },
  { id: "05", value: "May" },
  { id: "06", value: "June" },
  { id: "07", value: "July" },
  { id: "08", value: "August" },
  { id: "09", value: "September" },
  { id: "10", value: "October" },
  { id: "11", value: "November" },
  { id: "12", value: "December" },
];

export const generateYears = () => {
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 100))
    .fill("")
    .map((v, idx) => now - idx);

  return years;
};

export const generateArrayOfYears = () => {
  const min = new Date().getFullYear();
  const max = min + 21;
  const years = [];

  for (let i = min; i < max; i++) {
    years.push(i.toString());
  }
  return years;
};

export const dateFormatter = (day, mon, year) => {
  const fDay = day.length === 1 ? `-0${day}` : `-${day}`;

  const fYear = year !== "" ? `${year}-` : "0000-";

  return fYear + mon + fDay;
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const addDays = (n) => {
  const t = new Date();
  t.setDate(t.getDate() + n);
  let month = "0" + (t.getMonth() + 1);
  let date = "0" + t.getDate();
  month = month.slice(-2);
  date = date.slice(-2);
  const newDate = date + "/" + month + "/" + t.getFullYear();
  // console.log(newDate, 'newDate');
  return newDate;
};

export const iconTypes = {
  jpeg: "image",
  png: "image",
  jpg: "image",
  svg: "image",
  doc: "document",
  docx: "document",
  xlsx: "excel",
  xls: "excel",
  pdf: "pdf",
  csv: "csv",
  ppt: "powerPoint",
  pptx: "powerPoint",
};

export const checkstringLength = (string, length) => {
  let newStrng;
  if (string && string.length > length) {
    newStrng = string.substring(0, length) + "...";
    return (
      <Tooltip title={string} placement="top">
        <div>{newStrng}</div>
      </Tooltip>
    );
  } else {
    return <div>{string}</div>;
  }
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}
