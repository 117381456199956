import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress  = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: props => props.pcolor,
  },
}))(LinearProgress);

export const StatsComponent = ({ growth, current, subtext, pcolor }) => {
  return (
      <div className="w-100 gutter-b pt-8">
        <div className="my-2">
            <span className="font-weight-bolder font-size-h1 mr-2">{growth}%</span>
            {/* <LinearProgress variant="determinate" value={growth}  /> */}
            <BorderLinearProgress variant="determinate" value={growth} pcolor={pcolor} />
            <p className="text-muted pt-2 font-size-md" style={{'font-size':'initial'}}>{subtext}</p>
        </div>
      </div>
  )
}
