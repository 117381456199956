export const Range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

export function dataURLtoFile(base64image, filename) {
    var byteString = atob(base64image.split(',')[1]);
    // separate out the mime component
    var mimeString = base64image.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
      alert("arrived here");
  
      // write the ArrayBuffer to a blob, and you're done
      return new Blob([ab], {
        type: mimeString
      });
    }
}
