import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { actionTypes } from "../actionTypes";
import { checkNodeEnv, checkNodeEnvNextGen, logout } from "../../utils/authHelpers";
import {
  URL,
  asyncMethod,
  basicAuthPayload,
  apiUrl,
  domainName,
} from "../../utils/constants";
import { contributionActionTypes } from "./contributionActionType";
import NotificationMesage from "../../components/Common/NotificationMesage";
import { browserDetails } from "../../utils";
import AxiosInstance from "../../utils/AxiosConfig";

const ipDataAction = (payload) => ({ type: actionTypes.GET_IPDATA, payload });
const orgInfoAction = (payload) => ({
  type: actionTypes.FETCH_ORG_INFO,
  payload,
});
export const resetEndpointDataAction = {
  type: actionTypes.RESET_ENDPOINT_DATA,
};
export const resetCreditDataAction = { type: actionTypes.RESET_CREDIT_DATA };
export const resetCheckDataAction = { type: actionTypes.RESET_CHECK_DATA };
export const startLoadingAction = { type: actionTypes.START_LOADING };
export const stopLoadingAction = { type: actionTypes.STOP_LOADING };
const startPageLoadingAction = { type: actionTypes.START_PAGE_LOADING };
const stopPageLoadingAction = { type: actionTypes.STOP_PAGE_LOADING };
const orgInfoListAction = (payload) => ({
  type: actionTypes.ORG_INFO_LIST,
  payload,
});
const startLoadingUser = { type: actionTypes.START_LOADING_USER_DETAILS };
const startContributionLoadingAction = {
  type: contributionActionTypes.CONTRIBUTION_LOADING,
};
const contributionActionMonth = (payload) => ({
  type: contributionActionTypes.CONTRIBUTION_SUCCESS_MONTH,
  payload,
});
const contributionActionYear = (payload) => ({
  type: contributionActionTypes.CONTRIBUTION_SUCCESS_YEAR,
  payload,
});
const contributionActionRecent = (payload) => ({
  type: contributionActionTypes.CONTRIBUTION_SUCCESS_RECENT,
  payload,
});
const contributionActionLastYear = (payload) => ({
  type: contributionActionTypes.CONTRIBUTION_LAST_YEAR,
  payload,
});
const stopContributionLoadingAction = {
  type: contributionActionTypes.CONTRIBUTION_ERROR,
};
// const contributionPdf = payload => ({type: contributionActionTypes.CONTRIBUTION_PDF_SUCCESS, payload})

export const getIpData = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(URL.ipDataUrl);
      localStorage.setItem("ipAddress", JSON.stringify(response.data.ip));
      return dispatch(ipDataAction(response.data));
    } catch (error) {
      console.log(error.response);
    }
  };
};

// const orgInfoAction = payload => ({ type: actionTypes.FETCH_ORG_INFO, payload })
// const startLoadingAction = { type: actionTypes.START_LOADING };
// const stopLoadingAction = { type: actionTypes.STOP_LOADING };

export const fetchOrgInfo = () => {
  return async (dispatch) => {
    // dispatch(startLoadingAction);
    // const url = checkNodeEnv(apiUrl.orgInfo(URL.prodOrgUrl, domainName), apiUrl.orgInfo(URL.devOrgUrl, URL.testDomain));
    const url = checkNodeEnv(
      apiUrl.orgInfo(URL.prodOrgUrl, domainName()),
      apiUrl.orgInfo(URL.devOrgUrl, domainName())
    );
    try {
      const response = await axios({
        method: asyncMethod.GET,
        url,
        auth: basicAuthPayload,
      });
      const data = await response.data;
      // console.log(data, 'fetchOrgInfoData')
      if (data) {
        const isOnBoarding = window.location.hostname === "onboarding.churchpad.com";
        if (isOnBoarding && data.responseData?.isCustomUrlEnabled === "true") {
          return (window.location.href = `${data.responseData.dedicatedUrl}`);
        }
        // console.log(data.responseData.orgId, 'actions');
        dispatch(orgInfoList({ orgId: data.responseData.orgId }));
        // dispatch(stopLoadingAction)
        return dispatch(orgInfoAction(data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error.response, 'error')
    }
  };
};

export const fetchOrgInfoMasterApp = (payload) => {
  return async (dispatch) => {
    // dispatch(startLoadingAction);
    // const url = checkNodeEnv(apiUrl.orgInfo(URL.prodOrgUrl, domainName), apiUrl.orgInfo(URL.devOrgUrl, URL.testDomain));
    const url = checkNodeEnv(
      apiUrl.orgInfoMaster(URL.prodOrgUrl, payload),
      apiUrl.orgInfoMaster(URL.devOrgUrl, payload)
    );
    try {
      const response = await axios({
        method: asyncMethod.GET,
        url,
        auth: basicAuthPayload,
      });
      const data = await response.data;
      // console.log(data, 'fetchOrgInfoData')
      if (data) {
        const isOnBoarding = window.location.hostname === "onboarding.churchpad.com";
        if (isOnBoarding && data.responseData?.isCustomUrlEnabled === "true") {
          return (window.location.href = `${data.responseData.dedicatedUrl}`);
        }
        // console.log(data.responseData.orgId, 'actions');
        dispatch(orgInfoList({ orgId: data.responseData.orgId }));
        // dispatch(stopLoadingAction)
        return dispatch(orgInfoAction(data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error.response, 'error')
    }
  };
};

const authAction = (type, payload) => ({ type, payload });

export const axiosGetPayload = (url) => ({
  method: asyncMethod.GET,
  headers: { "content-type": "application/json" },
  url,
  auth: basicAuthPayload,
  params: {...browserDetails()}
});

export const axiosPayload = (url, data) => ({
  method: asyncMethod.POST,
  headers: { "content-type": "application/json" },
  url,
  data: {...data, ...browserDetails()},
  auth: basicAuthPayload,
});
export const axiosPayloadMService = (url, data) => ({
  method: asyncMethod.POST,
  headers: { "content-type": "application/json", 'Authorization': `Bearer ${localStorage.getItem('mservice-token')}` },
  url,
  data: {...data, ...browserDetails()},
});
export const axiosPayload2 = (url) => ({
  method: asyncMethod.GET,
  headers: { "content-type": "application/json" },
  url,
  auth: basicAuthPayload,
});

export const setThemeChange = (payload) => {
  return (dispatch) => {
    dispatch(authAction(actionTypes.SET_THEME_CHANGE, payload));
  };
};
export const updatePaymentMode = (payload) => {
  return (dispatch) => {
    dispatch(authAction(actionTypes.UPDATE_PAYMENT_MODE, payload));
  };
};

export const axiosMultipartPayload = (url, data) => ({
  method: asyncMethod.POST,
  headers: { "content-type": "multipart/form-data" },
  url,
  data,
  auth: basicAuthPayload,
});

export const login = (payload) => {
  // console.log(callback2);
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.login("live-api"),
      apiUrl.login("test-api")
    );
    const urlMService = checkNodeEnv(
      apiUrl.mserviceLogin("https://mservice.churchpad.com/"),
      apiUrl.mserviceLogin("https://mservice-test.churchpad.com/")
    );
    // const jwtAccessTokenUrl = checkNodeEnvNextGen(apiUrl.jwtAccessToken('https://apix.churchpad.com/nextgen'), apiUrl.jwtAccessToken('https://apps1.churchpad.com/nextgen'));
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        let payloadMService = {...payload}
        payloadMService['org_id'] = payload.orgId
        payloadMService['user_id'] = data.responseData.userId
        const responseMservice = await axios(axiosPayload(urlMService, payloadMService));
        const dataMservice = await responseMservice.data;
        // console.log(data, 'data');
        if (responseMservice.status === 200) {
          // console.log(dataMservice, 'hjfjhcfvj');
          localStorage.setItem('mservice-token', dataMservice.data.access_token)
        }
        // const nextGenLoginDetails = {
        //   "email": payload.email,
        //   "organizationId": payload.orgId,
        //   "password": payload.password,
        //   "userId": data.responseData.userId
        // }
        // const jwtResponse = await axios({
        //   method: asyncMethod.POST,
        //   url: jwtAccessTokenUrl,
        //   data: nextGenLoginDetails
        // });
        // const jwtData = await jwtResponse.data;
        // console.log(jwtData, 'data');
        localStorage.setItem('nextGenToken', data?.responseData?.jwtToken)
        data.authData =
          payload.email && payload.password
            ? window.btoa(payload.email + ":" + payload.password)
            : window.btoa(payload.mobile + ":" + payload.pin);
        if (data.responseData.twoFactorAuthentication.isRequired === "true") {
          data.responseData.twoFactorAuthentication = {};
          localStorage.setItem("2fa", JSON.stringify(data));
          dispatch(authAction(actionTypes.AUTH_2FA_REQUIRED));
        } else {
          localStorage.setItem("user", JSON.stringify(data));
          // localStorage.setItem('jwtAccess', JSON.stringify(jwtData));
        }
        dispatch(stopLoadingAction);
        sessionStorage.removeItem("session-out");
        // callback();
        return dispatch(authAction(actionTypes.LOGIN_SUCCESS, data)); // check if the dispatch data from the store or localStorage
      } else {
        data.authData = {};
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.LOGIN_FAILURE, data));
      }
    } catch (error) {
      dispatch(stopLoadingAction);
      toast.warn("Network Error. Please check your connectivity and try again");
      console.log(error, "login error");
    }
  };
};

export const signup = (payload, callback) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.signup("live-api"),
      apiUrl.signup("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        localStorage.setItem('nextGenToken', data?.responseData?.jwtToken)
        data.authData =
          payload.email && payload.password
            ? window.btoa(payload.email + ":" + payload.password)
            : window.btoa(payload.mobile + ":" + payload.pin);
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(stopLoadingAction);
        callback();
        return dispatch(authAction(actionTypes.SIGNUP_SUCCESS, data)); // check if the dispatch data from the store or localStorage
      } else {
        data.authData = {};
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.SIGNUP_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error, 'signup error')
    }
  };
};

export const forgotPassword = (payload) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.forgotPassword("live-api"),
      apiUrl.forgotPassword("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.FORGOT_PASSWORD_SUCCESS, data));
      } else {
        
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.FORGOT_PASSWORD_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error, 'forgotPassword error')
    }
  };
};

export const forgotPin = (payload) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.forgotPin("live-api"),
      apiUrl.forgotPin("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.FORGOT_PIN_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.FORGOT_PIN_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error, 'forgotPassword error')
    }
  };
};

export const resetPasswordOrPin = (payload) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.resetPassword("live-api"),
      apiUrl.resetPassword("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        return dispatch(
          authAction(actionTypes.RESET_PASSWORD_OR_PIN_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        return dispatch(
          authAction(actionTypes.RESET_PASSWORD_OR_PIN_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error, 'forgotPassword error')
    }
  };
};

export const verifyForgotPassword = (payload) => {
  return async (dispatch) => {
    dispatch(startPageLoadingAction);
    const url = checkNodeEnv(
      apiUrl.verifyForgotPasswordorPin("live-api"),
      apiUrl.verifyForgotPasswordorPin("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopPageLoadingAction);
        return dispatch(
          authAction(actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS, data)
        );
      } else {
        dispatch(stopPageLoadingAction);
        return dispatch(
          authAction(actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error, 'forgotPassword error')
    }
  };
};

export const initiateRSVP = (payload, callback) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.initiateRSVPUrl("live-api"),
      apiUrl.initiateRSVPUrl("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        return dispatch(authAction(actionTypes.INITIATE_RSVP_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.INITIATE_RSVP_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      console.log(error, "forgotPassword error");
    }
  };
};

export const verifyRSVP = (payload) => {
  console.log(payload, "payload");
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.verifyRSVPUrl("live-api"),
      apiUrl.verifyRSVPUrl("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      console.log(data, "data");
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.VERIFY_RSVP_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(authAction(actionTypes.VERIFY_RSVP_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      // console.log(error, 'forgotPassword error')
    }
  };
};

export const processRsvp = (payload, callback) => {
  // console.log('processRsvp payload', payload)
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.processRSVPUrl("live-api"),
      apiUrl.processRSVPUrl("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        return dispatch(authAction(actionTypes.PROCESS_RSVP_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(authAction(actionTypes.PROCESS_RSVP_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const cancelRsvp = (payload, callback) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.cancelRSVPUrl("live-api"),
      apiUrl.cancelRSVPUrl("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback();
        return dispatch(authAction(actionTypes.CANCEL_RSVP_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(authAction(actionTypes.CANCEL_RSVP_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const createMultipleRsvp = (payload) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.CREATE_MULTIPLE_RSVP,
      payload,
    });
};

export const clearSelectedRsvp = () => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.CLEAR_ALL_SELECTED_RSVP,
    });
};

// export const deleteGuest = (payload, callback) => async dispatch => {
//   dispatch(startLoadingAction);
//   const url = checkNodeEnv(apiUrl.deleteGuestUrl('live-api'), apiUrl.deleteGuestUrl('test-api'));
//   try {
//    const response = await axios(axiosPayload(url, payload))
//    const data = await response.data
//    console.log(data, 'data');
//    if (data.responseCode === '00'){
//      dispatch(stopLoadingAction)
//      toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
//      callback();
//      return dispatch(authAction(actionTypes.DELETE_GUEST_SUCCESS, data))
//    } else {
//      dispatch(stopLoadingAction)
//      toast.error(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
//      return dispatch(authAction(actionTypes.DELETE_GUEST_FAILURE, data))
//    }
//   } catch (error) {
//    //  console.log(error, 'update2fa');
//    toast.warn('Network Error. Please check your connectivity and try again')
//   }
// }
export const rsvpInformationInstance = (queryString) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.rsvpInformationInstanceUrl("live-api", queryString),
      apiUrl.rsvpInformationInstanceUrl("test-api", queryString)
    );
    try {
      const response = await axios(axiosGetPayload(url));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        return dispatch(
          authAction(actionTypes.RSVP_INFORMATION_INSTANCE_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        return dispatch(
          authAction(actionTypes.RSVP_INFORMATION_INSTANCE_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const UserContribution = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.userContribution("live-api"),
    apiUrl.userContribution("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data)
    if (data.responseCode === "00") {
      return data.responseData;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

/* Get organization information List */
export const getOrgInfoList = (orgId) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.orgInfoList("live-api"),
    apiUrl.orgInfoList("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, orgId));    
    const data = await response.data;
    
    if (data) {
      return dispatch(authAction(actionTypes.GET_ORG_INFO_LIST, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

/* Update user information */
export const updateUserRecord = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingUser);
  const url = checkNodeEnv(
    apiUrl.updateInfo("live-api"),
    apiUrl.updateInfo("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      callback();
      return dispatch(authAction(actionTypes.UPDATE_USER_RECORD_SUCCESS, data));
    } else {
      data.authData = {};
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.UPDATE_USER_RECORD_FAILURE, data));
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const church411Directory = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.church411("live-api"),
    apiUrl.church411("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const prayerWalls = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.prayerWalls("live-api"),
    apiUrl.prayerWalls("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const prayerWallsLikeOrPray = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.prayerWallsLikeOrPray("live-api"),
    apiUrl.prayerWallsLikeOrPray("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addPrayerWalls = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.addPrayerWalls("live-api"),
    apiUrl.addPrayerWalls("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const orgInfoList = (payload) => {
  return async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.orgOtherList("live-api"),
      apiUrl.orgOtherList("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        return dispatch(orgInfoListAction(data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const archivePrayer = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.archivePrayerUrl("live-api"),
    apiUrl.archivePrayerUrl("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const markedAnsweredPrayer = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.answeredPrayerUrl("live-api"),
    apiUrl.answeredPrayerUrl("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

// get user profile
export const getUserProfile = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.getUserProfile("live-api"),
    apiUrl.getUserProfile("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.GET_USER_PROFILE_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.GET_USER_PROFILE_FAILURE, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const UserContributionPerMonth = (
  payload,
  payload2,
  payload3,
  payload4
) => {
  return async (dispatch) => {
    dispatch(startContributionLoadingAction);
    const url = checkNodeEnv(
      apiUrl.userContribution("live-api"),
      apiUrl.userContribution("test-api")
    );
    try {
      await Promise.allSettled([
        axios(axiosPayload(url, payload)),
        axios(axiosPayload(url, payload2)),
        axios(axiosPayload(url, payload3)),
        axios(axiosPayload(url, payload4)),
      ]).then((result) => {
        const [
          contributionperMonth,
          contributionPerYear,
          recentContribution,
          previousYear,
        ] = result;
        // console.log(result);
        const status = "fulfilled";

        if (
          contributionperMonth.status === status &&
          contributionperMonth.value.data.responseCode === "00"
        ) {
          dispatch(contributionActionMonth(contributionperMonth.value.data));
        }
        if (
          contributionPerYear.status === status &&
          contributionPerYear.value.data.responseCode === "00"
        ) {
          dispatch(contributionActionYear(contributionPerYear.value.data));
        }
        if (
          recentContribution.status === status &&
          recentContribution.value.data.responseCode === "00"
        ) {
          dispatch(contributionActionRecent(recentContribution.value.data));
        }
        if (
          previousYear.status === status &&
          previousYear.value.data.responseCode === "00"
        ) {
          dispatch(contributionActionLastYear(previousYear.value.data));
        }
        dispatch(stopContributionLoadingAction);
      });
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch(stopContributionLoadingAction);
    }
  };
};

export const contributionYearly = async (payload) => {
  // return async (dispatch) => {
  // dispatch(startPageLoadingAction);
  const url = checkNodeEnv(
    apiUrl.userContribution("live-api"),
    apiUrl.userContribution("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // console.log(data);
      return data.responseData;
      // dispatch(stopPageLoadingAction)
      // return dispatch(authAction(actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS, data))
    }
    // else{
    //   dispatch(stopPageLoadingAction)
    //   return dispatch(authAction(actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE, data))
    // }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
    // console.log(error, 'yealy contribution error')
  }
  // }
};

export const userImageUpload = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.userImageUrl("live-api"),
    apiUrl.userImageUrl("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
    // console.log(error, 'user image error');
  }
};

export const donatedAmount = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.DONATED_AMOUNT,
      payload,
    });
  };
};

export const displayDonatedAmountErrorMsg = (payload) => {
  console.log(payload, "payload");
  return (dispatch) => {
    return dispatch({
      type: actionTypes.DONATED_AMOUNT_ERROR_MSG,
      payload,
    });
  };
};

export const scrollToAmountErrorMsg = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SCROLL_TO_AMOUNT_ERROR_MSG,
      payload,
    });
  };
};

export const setCreditCardOwnerInfo = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_CREDIT_CARD_OWNER_INFO,
      payload,
    });
  };
};

export const setGiveInfo = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_GIVE_INFO,
      payload,
    });
  };
};

export const setCreditCardDetails = (payload) => {
  return (dispatch) => {
    // dispatch({type: actionTypes.RESET_GIVE_RESPONSE})
    return dispatch({
      type: actionTypes.SET_CREDIT_CARD_DETAILS,
      payload,
    });
  };
};

export const setAccCheckDetails = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_CHECK_DETAILS,
      payload,
    });
  };
};

export const setHideAmountInput = (payload) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_HIDE_AMOUNT_INPUT,
    payload,
  });

export const giveWithCard = (payload, callback, callback2) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.giveWithCard("live-api"),
      apiUrl.giveWithCard("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      // console.log(data, 'data')
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        return dispatch(authAction(actionTypes.GIVE_WITH_CARD_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        callback2 && callback2()
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        return dispatch(authAction(actionTypes.GIVE_WITH_CARD_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const giveWithCheck = (payload, callback, callback2) => {
  // console.log(payload, 'payload')
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.giveWithCheck("live-api"),
      apiUrl.giveWithCheck("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      // console.log(data, 'data')
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        return dispatch(authAction(actionTypes.GIVE_WITH_CHECK_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        callback2 && callback2()
        return dispatch(authAction(actionTypes.GIVE_WITH_CHECK_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};
/* Update user email and password */

export const updateUserEmailAndPassword = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.updatePasswordAndEmail("live-api"),
    apiUrl.updatePasswordAndEmail("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'index');
    if (data.responseCode === "00") {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addAchild = (payload, callback) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.deleteChildurl("live-api"),
    apiUrl.deleteChildurl("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    console.log(response, "dtatat");
    const data = await response.data;
    console.log(data);
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      callback && callback();
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.ADD_A_CHILD_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.ADD_A_CHILD_FAILURE, data));
    }
  } catch (error) {
    console.log(error, "add child");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const deleteAChild = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.deleteChildurl("live-api"),
    apiUrl.deleteChildurl("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log("error", error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
// Endpoint for pdf generation

export const generatePdf = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.contributionPdf("live-api"),
    apiUrl.contributionPdf("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'index');
    if (data.responseCode === "00") {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const showMobileSideBar = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SHOW_MOBILE_SIDE_BAR,
      payload,
    });
  };
};

// Second info list

export const secondOrgList = (payload) => async (dispatch) => {
  // dispatch(resetEndpointDataAction);
  //  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.orgInfo2("live-api"),
    apiUrl.orgInfo2("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      //  dispatch(stopLoadingAction)
      return dispatch(authAction(actionTypes.FETCH_SECOND_ORG_INFO, data));
    } else {
      //  dispatch(stopLoadingAction)
      return dispatch(authAction(actionTypes.START_LOADING, data));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateUser2FA = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateUser2FA("live-api"),
    apiUrl.updateUser2FA("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      // const user = JSON.parse(localStorage.getItem('2fa'))
      // localStorage.removeItem('2fa')
      // localStorage.setItem('user', JSON.stringify(user));
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      callback();
      return dispatch(authAction(actionTypes.SET_2FA_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.SET_2FA_ERROR, data));
    }
  } catch (error) {
    //  console.log(error, 'update2fa');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

//  verification of 2FA

export const verify2FA = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.verify2FA("live-api"),
    apiUrl.verify2FA("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'index');
    if (data.responseCode === "00") {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

// CheckIn Information

export const checkInInformation = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.checkInInformation("live-api", payload),
    apiUrl.checkInInformation("test-api", payload)
  );
  console.log(url, "url");
  try {
    const response = await axios(axiosPayload2(url));
    const data = await response.data;
    // console.log(data, 'index');
    if (data.responseCode === "00") {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

// process Checkin

export const processCheckin = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.processCheckin("live-api"),
    apiUrl.processCheckin("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      callback();
      return dispatch(authAction(actionTypes.PROCESS_CHECKIN_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.PROCESS_CHECKIN_FAILURE, data));
    }
  } catch (error) {
    //  console.log(error, 'update2fa');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

// delete guest

export const deleteGuest = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.deleteGuest("live-api"),
    apiUrl.deleteGuest("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      callback();
      return dispatch(authAction(actionTypes.DELETE_CHILD_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.DELETE_GUEST_FAILURE, data));
    }
  } catch (error) {
    //  console.log(error, 'update2fa');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

// Checkout Process

export const processCheckout = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.processCheckOut("live-api"),
    apiUrl.processCheckOut("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      callback();
      return dispatch(authAction(actionTypes.PROCESS_CHECKOUT_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.PROCESS_CHECKOUT_FAILURE, data));
    }
  } catch (error) {
    //  console.log(error, 'update2fa');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const contributionProcessingEngines = (payload) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.contributionProcessingEnginesUrl("live-api"),
    apiUrl.contributionProcessingEnginesUrl("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return dispatch(
        authAction(actionTypes.CONTRIBUTION_PROCESSING_ENGINES_SUCCESS, data)
      );
    } else {
      return dispatch(
        authAction(actionTypes.CONTRIBUTION_PROCESSING_ENGINES_FAILURE, data)
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const processPaypal = (payload, cb) => async (dispatch) => {
  // dispatch(startLoadingAction);
  const url = checkNodeEnvNextGen(
    apiUrl.processPaypalUrl("https://apix.churchpad.com"),
    apiUrl.processPaypalUrl("https://apps1.churchpad.com")
  );
  // const url = apiUrl.processPaypalUrl;
  try {
    const response = await axios({
      method: asyncMethod.POST,
      url,
      data: payload,
    });
    const data = await response.data;
    if (data.status === "CREATED") {
      // dispatch(stopLoadingAction)
      if (
        data?.links &&
        data?.links[1].rel === "approve"
      ) {
        window.open(data?.links[1].href, "_self");
      }
      return dispatch(authAction(actionTypes.PROCESS_PAYPAL_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.PROCESS_PAYPAL_FAILURE, data));
    }
  } catch (error) {
    // dispatch(stopLoadingAction)
    if (cb) {
      cb();
    }
    toast.error(error.response?.data?.responseMessage);
  }
};

export const sermon = (id) => async (dispatch) => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; //months from 1-12

  const year = dateObj.getUTCFullYear();

  const query = {
    id,
    month,
    year,
  }
  const url = checkNodeEnvNextGen(
    apiUrl.upcomingEvent("https://apix.churchpad.com", query),
    apiUrl.upcomingEvent("https://apps1.churchpad.com", query)
  );
  try {
    const response = await axios.get(url);
    const data = await response.data;
    if (data.responseCode === "00") {
      return dispatch(authAction(actionTypes.SERMON_SUCCESS, data));
    } else {
      return dispatch(authAction(actionTypes.SERMON_FAILURE, data));
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const selectedSermon = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SELECTED_SERMON,
      payload,
    });
  };
};

export const checkinActionFamily = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.FAMILY_CHECKIN,
      payload,
    });
  };
};
export const enableGiveViaNav = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GIVE_VIA_NAV,
      payload,
    });
  };
};
export const selectedPaymentOption = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SELECTED_PAYMENT_ENGINE,
      payload,
    });
  };
};

export const yearlyStatement = (payload) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.yearlyContributionStatements("live-api"),
    apiUrl.yearlyContributionStatements("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    //  console.log(data, 'data');
    if (data.responseCode === "00") {
      return dispatch(
        authAction(
          contributionActionTypes.YEARLY_CONTRIBUTION_STATEMENT_SUCCESS,
          data
        )
      );
    } else {
      return dispatch(
        authAction(
          contributionActionTypes.YEARLY_CONTRIBUTION_STATEMENT_FAILURE,
          data
        )
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const getUserProfileByWebAccessToken = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.getUserProfileByWebAccessToken("live-api"),
    apiUrl.getUserProfileByWebAccessToken("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      localStorage.setItem("user", JSON.stringify(data));
      dispatch(stopLoadingAction);
      window.location = "/user/donate";
      return dispatch(authAction(actionTypes.GET_USER_PROFILE_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.GET_USER_PROFILE_SUCCESS, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
export const checkInListingsStatus = (payload) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.checkInListingsStatus("live-api"),
    apiUrl.checkInListingsStatus("test-api")
  );
  dispatch(startLoadingAction);
  dispatch(resetEndpointDataAction);
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    //  console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(
        authAction(actionTypes.CHECKIN_LISITNG_STATUS_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      window.location = "/";
      return dispatch(
        authAction(actionTypes.CHECKIN_LISITNG_STATUS_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
export const rsvpListingsStatus = (payload, callback) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.rsvpListingsStatus("live-api"),
    apiUrl.rsvpListingsStatus("test-api")
  );
  dispatch(startLoadingAction);
  dispatch(resetEndpointDataAction);
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    //  console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(
        authAction(actionTypes.CHECKIN_LISITNG_STATUS_SUCCESS, data)
      );
    } else {
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      dispatch(stopLoadingAction);
      return dispatch(
        authAction(actionTypes.CHECKIN_LISITNG_STATUS_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
export const paystack = (payload, cb) => async (dispatch) => {
  // dispatch(startLoadingAction)
  const url = checkNodeEnvNextGen(
    apiUrl.paystack("https://apix.churchpad.com"),
    apiUrl.paystack("https://apps1.churchpad.com")
  );
  try {
    const response = await axios({
      method: asyncMethod.POST,
      url,
      data: payload,
    });
    const data = await response.data;
    //  console.log(data, 'data');
    if (response.status === 200) {
      window.open(data?.authorization_url, "_self");
    }
  } catch (error) {
    if (cb) {
      cb();
    }
    toast.error(error.response?.data?.responseMessage);
  }
};
export const Flutterwave = (payload, cb) => async (dispatch) => {
  // dispatch(startLoadingAction)
  const url = checkNodeEnvNextGen(
    apiUrl.Flutterwave("https://apix.churchpad.com"),
    apiUrl.Flutterwave("https://apps1.churchpad.com")
  );
  try {
    const response = await axios({
      method: asyncMethod.POST,
      url,
      data: payload,
    });
    const data = await response.data;
    //  console.log(data, 'data');
    if (response.status === 200) {
      window.open(data?.paymentLink, "_self");
    }
  } catch (error) {
    if (cb) {
      cb();
    }
    toast.error(error.response?.data?.responseMessage);
  }
};
export const StripePayment = (payload, cb) => async (dispatch) => {
  // dispatch(startLoadingAction)
  const url = checkNodeEnvNextGen(
    apiUrl.Stripe("https://apix.churchpad.com"),
    apiUrl.Stripe("https://apps1.churchpad.com")
  );
  try {
    const response = await axios({
      method: asyncMethod.POST,
      url,
      data: payload,
    });
    const data = await response.data;
    console.log(data, "data");
    if (response.status === 200) {
      window.open(data?.paymentLink, "_self");
    }
  } catch (error) {
    console.log(error, "=======");
    if (cb) {
      cb();
    }
    toast.error(error.response?.data?.responseMessage);
  }
};

export const loginByToken = (payload, callback) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.loginByToken("live-api"),
    apiUrl.loginByToken("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // console.log(jwtData, 'data');
      localStorage.setItem('nextGenToken', data?.responseData?.jwtToken)
      data.authData =
        payload.email && payload.password
          ? window.btoa(payload.email + ":" + payload.password)
          : window.btoa(payload.mobile + ":" + payload.pin);
      if (data.responseData.twoFactorAuthentication.isRequired === "true") {
        data.responseData.twoFactorAuthentication = {};
        localStorage.setItem("2fa", JSON.stringify(data));
        dispatch(authAction(actionTypes.AUTH_2FA_REQUIRED));
      } else {
        localStorage.setItem("user", JSON.stringify(data));
        callback();
      }
      dispatch(stopLoadingAction);
      sessionStorage.removeItem("session-out");
      // callback();
      return dispatch(authAction(actionTypes.LOGIN_SUCCESS, data)); // check if the dispatch data from the store or localStorage
    } else {
      data.authData = {};
      localStorage.setItem("user", JSON.stringify(data));
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.LOGIN_FAILURE, data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const epayByToken = (payload, callback) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.epayByToken("live-api"),
    apiUrl.epayByToken("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.EPAY_SUCCESS, data)); // check if the dispatch data from the store or localStorage
    } else {
      callback(data.responseMessage);
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.EPAY_FAILURE, data));
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    console.log(error);
  }
};
export const processEpayByToken = (payload, callback) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.processEpayByToken("live-api"),
    apiUrl.processEpayByToken("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    console.log(data, "data");
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      callback(data.responseCode);
      return dispatch(authAction(actionTypes.PROCESS_EPAY_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      callback(data.responseCode);
      return dispatch(authAction(actionTypes.PROCESS_EPAY_FAILURE, data));
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    console.log(error);
  }
};

export const addPhoneNumber = (payload, callback) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_PHONE_NUMBER,
  });
  const url = checkNodeEnv(
    apiUrl.addPhoneNumber("live-api"),
    apiUrl.addPhoneNumber("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.STOP_PHONE_NUMBER,
      });
      callback();
      return dispatch(authAction(actionTypes.ADD_PHONE_NUMBER_SUCCESS, data));
    } else {
      dispatch({
        type: actionTypes.STOP_PHONE_NUMBER,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(authAction(actionTypes.ADD_PHONE_NUMBER_FAILURE, data));
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_PHONE_NUMBER,
    });
    console.log(error);
  }
};

export const deletePhoneNumber = (payload, callback) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_PHONE_NUMBER,
  });
  const url = checkNodeEnv(
    apiUrl.deletePhoneNumber("live-api"),
    apiUrl.deletePhoneNumber("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.STOP_PHONE_NUMBER,
      });
      callback();
      return dispatch(
        authAction(actionTypes.DELETE_PHONE_NUMBER_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_PHONE_NUMBER,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.DELETE_PHONE_NUMBER_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_PHONE_NUMBER,
    });
    console.log(error);
  }
};

export const appAccess = (payload) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_APP_ACCESS,
  });
  const url = checkNodeEnv(
    apiUrl.appAccess("live-api"),
    apiUrl.appAccess("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      return dispatch(
        authAction(actionTypes.APP_ACCESS_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.APP_ACCESS_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_APP_ACCESS,
    });
    console.log(error);
  }
};

export const quickGiveProcessingEngines = (payload) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_APP_ACCESS,
  });
  const url = checkNodeEnv(
    apiUrl.quickGiveProcessingEngines("live-api"),
    apiUrl.quickGiveProcessingEngines("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      return dispatch(
        authAction(actionTypes.QUICKGIVE_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.QUICKGIVE_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_APP_ACCESS,
    });
    console.log(error);
  }
};

export const celebrationsListing = (payload) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_APP_ACCESS,
  });
  const url = checkNodeEnv(
    apiUrl.celebrationsListing("live-api"),
    apiUrl.celebrationsListing("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      return dispatch(
        authAction(actionTypes.CELEBRATION_SUCCESS_RESPONSE, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.CELEBRATION_FAILURE_RESPONSE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_APP_ACCESS,
    });
    console.log(error);
  }
};

export const preferredPaymentNewCard = (payload, cb, cb2) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnvNextGen(
    apiUrl.preferredPaymentNewCard("https://apix.churchpad.com"),
    apiUrl.preferredPaymentNewCard("https://apps1.churchpad.com")
  );
  try {
    const response = await AxiosInstance({
      method: asyncMethod.POST,
      url,
      data: payload,
    });
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      cb && cb();
      return dispatch(
        authAction(actionTypes.PREFERRED_PAYMENT_NEW_CARD_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      cb2 && cb2()
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.PREFERRED_PAYMENT_NEW_CARD_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    cb2 && cb2()
    toast.error(
      <NotificationMesage
        message={error.response.data.responseMessage}
        responseStatus={error.response.data.responseCode}
      />
    );
  }
};

export const preferredPaymentNewCheck = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnvNextGen(
    apiUrl.preferredPaymentNewCheck("https://apix.churchpad.com"),
    apiUrl.preferredPaymentNewCheck("https://apps1.churchpad.com")
  );
  try {
    const response = await AxiosInstance({
      method: asyncMethod.POST,
      url,
      data: payload,
    });
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      cb && cb();
      return dispatch(
        authAction(actionTypes.PREFERRED_PAYMENT_NEW_CHECK_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      console.log(data, "data");
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.PREFERRED_PAYMENT_NEW_CHECK_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    toast.error(
      <NotificationMesage
        message={error.response.data.responseMessage}
        responseStatus={error.response.data.responseCode}
      />
    );
    // toast.warn("Network Error. Please check your connectivity and try again");
  }
};


export const preferredPaymentExistingCard = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnvNextGen(
    apiUrl.preferredPaymentExistingCard("https://apix.churchpad.com"),
    apiUrl.preferredPaymentExistingCard("https://apps1.churchpad.com")
  );
  try {
    const response = await AxiosInstance({
      method: asyncMethod.POST,
      url,
      data: payload,
    });
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      cb && cb();
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    toast.error(
      <NotificationMesage
        message={error.response.data.responseMessage}
        responseStatus={error.response.data.responseCode}
      />
    );
  }
};

export const getPrivacyPolicyHtml = (payload) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_APP_ACCESS,
  });
  const url = checkNodeEnv(
    apiUrl.getPrivacyPolicyHtml("live-api"),
    apiUrl.getPrivacyPolicyHtml("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      return dispatch(
        authAction(actionTypes.PRIVACY_SUCCESS_RESPONSE, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.PRIVACY_FAILURE_RESPONSE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_APP_ACCESS,
    });
    console.log(error);
  }
};

export const getPrivacyPolicyHtmlWithoutPayload = () => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_APP_ACCESS,
  });
  const url = checkNodeEnv(
    apiUrl.getPrivacyPolicyHtml("live-api"),
    apiUrl.getPrivacyPolicyHtml("test-api")
  );
  try {
    const response = await axios(axiosPayload(url));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      return dispatch(
        authAction(actionTypes.PRIVACY_SUCCESS_RESPONSE, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.PRIVACY_FAILURE_RESPONSE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_APP_ACCESS,
    });
    console.log(error);
  }
};

export const GenerateUserOtp = (callback) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  console.log(localStorage.getItem('mservice-token'), 'mservice-token');
  dispatch({
    type: actionTypes.START_APP_ACCESS,
  });
  const url = checkNodeEnv(
    apiUrl.generateOtp("https://mservice.churchpad.com/"),
    apiUrl.generateOtp("https://mservice-test.churchpad.com/")
  );
  try {
    const response = await axios(axiosPayloadMService(url));
    const data = await response.data;
    if (response.status === 200) {
      callback()
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      return dispatch(
        authAction(actionTypes.GENERATE_DELETION_OTP_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      toast.error(
        <NotificationMesage
          message={data.responseMessage}
          responseStatus={data.responseCode}
        />
      );
      return dispatch(
        authAction(actionTypes.GENERATE_DELETION_OTP_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_APP_ACCESS,
    });
    console.log(error);
  }
};

export const deleteUserAccount = (payload) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.START_APP_ACCESS,
  });
  const url = checkNodeEnv(
    apiUrl.deleteUserAccount("https://mservice.churchpad.com/"),
    apiUrl.deleteUserAccount("https://mservice-test.churchpad.com/")
  );
  try {
    const response = await axios(axiosPayloadMService(url, payload));
    const data = await response.data;
    if (response.status === 200) {
      logout()
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      return dispatch(
        authAction(actionTypes.DELETE_USERS_ACCOUNT_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.STOP_APP_ACCESS,
      });
      toast.error(
        <NotificationMesage
          message={data.message}
          responseStatus={'01'}
        />
      );
      return dispatch(
        authAction(actionTypes.DELETE_USERS_ACCOUNT_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.STOP_APP_ACCESS,
    });
    toast.error(
      <NotificationMesage
        message={error.response.data.message}
        responseStatus={'01'}
      />
    );
    console.log(error.response, 'error');
  }
};

export const organizationList = () => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch({
    type: actionTypes.MASTER_APP_ORGANIZATION_START,
  });
  const url = checkNodeEnv(
    apiUrl.organizationList("https://mservice.churchpad.com/"),
    apiUrl.organizationList("https://mservice-test.churchpad.com/")
  );
  try {
    const response = await axios(url);
    const data = await response.data;
    if (response.status === 200) {
      return dispatch(
        authAction(actionTypes.MASTER_APP_ORGANIZATION_STOP, data)
      );
    } else {
      toast.error(
        <NotificationMesage
          message={data.message}
          responseStatus={'01'}
        />
      );
      return dispatch(
        authAction(actionTypes.MASTER_APP_ORGANIZATION_STOP, data)
      );
    }
  } catch (error) {
    dispatch({
      type: actionTypes.MASTER_APP_ORGANIZATION_STOP,
    });
    toast.error(
      <NotificationMesage
        message={error.response.data.message}
        responseStatus={'01'}
      />
    );
    console.log(error.response, 'error');
  }
};