import React from 'react'

import './UnAuthorizedUser.scss'

export const UnAuthorizedUser = () => {
  return (
    <div className="chpd-give-wrapper py-5">
      <span className="icon chpd_icon mb-10">
        <i className="fas fa-exclamation-triangle"></i>
      </span>
      <div>
        <h3 className="font-size-h1 mt-5 text-secondary clearfix">Please be advice:</h3>
        <p className="text-center my-5 py-4 text-black-50">
          This organization is not currently accepting donations at this time. Please contact your system administrator. Thank you.
        </p>
      </div>
    </div>
  )
}
