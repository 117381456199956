import React from "react";
import "./styles.scss";

const CustomSelect = (props) => {
  const { errors, name, className, children, ...rest } = props;
  return (
    <div className="mb-3 w-full">
      <select
        name={name}
        className={`mb-0 w-full ${
          errors?.[name]?.message ? "border-danger border" : ""
        } ${className}`}
        {...rest}
      >
        {children}
      </select>
      {errors?.[name]?.message && (
        <p className="text-danger">{errors?.[name]?.message}</p>
      )}
    </div>
  );
};

export default CustomSelect;
