import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from "moment";
// import { Link } from "react-router-dom";
import Link from '@material-ui/core/Link';
import { StatsComponent } from "../../Common/Stats";
import { Chart } from "../../Common/Chart";
import {
  UserContributionPerMonth,
  contributionYearly,
  generatePdf,
  yearlyStatement
} from "../../../store/actions/";
import { Range } from "../../../utils/useableFunctions";
import ModalComponent from "../../Common/Modal";
import { getUserDetails } from "../../../utils/authHelpers";
import Pagination from "@material-ui/lab/Pagination";
import PageLoader from '../../Loader/PageLoader';
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import "./Contribution.scss";
import DataTable from 'react-data-table-component';
import CustomizedMenuDashboard from './CustomizedMenuDashboard'
import screenSize from '../../Common/screenSize';
import memoize from 'memoize-one';
import styled from 'styled-components'
import { ButtonContainer } from "../../Common/Button";
import { Tooltip } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';

const columns = [
  {
    name: '',
    selector: '',
    // center: true,
    left: true,
    // maxWidth: '5px',
    width: '50px',
    cell: (row,index) => index + 1,

  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true,
    format: row => moment(row.date).format('MMM D, YYYY'),
  },
  {
    name: 'Amount',
    selector: row => Number(row.amount),
    sortable: true,
    center: true,
    cell: row => <div style={{display: 'flex', justifyContent: 'space-between'}}>
      {parseFloat(row.amount).toFixed(2)}
      <div style={{ fontSize: '10px', paddingLeft: '5px', paddingTop: '2px' }}>{row.currency}</div>
      {row.memo?.length > 0 && <Tooltip title={row.memo}><i  title={row.memo} className="far fa-file" style={{fontSize: '10px', paddingLeft: '10px', alignSelf: 'center', color: 'black'}}></i></Tooltip>}
      </div>,
  },
  {
    name: 'Category',
    selector: row => row.contributionType,
    center: true,
    sortable: true
  },
  {
    name: 'Source',
    selector: row => row.source,
    center: true
  },
  {
    name: 'Transaction ID',
    selector: row => row.transactionId,
    center: true,
  },
  {
    name: 'Mode',
    selector: row => row.paymentMode,
    center: true,
    sortable: true,
    cell: row => <div style={{textTransform: 'capitalize'}}>{row.paymentMode}</div>
  },
]

const columnsSmallScreen = memoize(getOneHandler =>  [
  // {
  //   name: '',
  //   selector: '',
  //   // center: true,
  //   left: true,
  //   // maxWidth: '5px',
  //   // width: '50px',
  //   cell: (row,index) => index + 1,
  //   // allowOverflow: true
  // },
  // {
  //   name: 'Date',
  //   selector: 'date',
  //   sortable: true,
  //   center: true,
  //   width: '80px',
  //   format: row => moment(row.date).format('MMM Do, YYYY'),
  // },
  {
    name: 'Amount',
    selector: row => row.amount,
    sortable: true,
    center: true,
    // width: '40px',
    cell: row => <div style={{display: 'flex', justifyContent: 'space-between'}}>{parseFloat(row.amount).toFixed(2)}</div>,
  },
  {
    name: 'Category',
    selector: row => row.contributionType,
    center: true,
    sortable: true,
    // width: '50px'
  },
  {
    cell: row => <CustomizedMenuDashboard row={row} onGet={getOneHandler}  />,
    allowOverflow: true,
    button: true,
    // hide: 'lg',
    width: '20px',
  }
]);
const TextField = styled.input`
height: 34px;
width: 200px;
border-radius: 3px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border: 1px solid #e5e5e5;
padding: 0 32px 0 16px;

&:hover {
  cursor: pointer;
}
&:focus{
  outline: none;
}
@media only screen and (max-width: 330px) {
  width: 120px;
}
@media only screen and (max-width: 720px) {
  width: 150px;
}
`;
const ClearButton = styled(ButtonContainer)`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
height: 34px;
width: 4.83rem;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
@media only screen and (max-width: 330px) {
  width: 3.0rem;
}
@media only screen and (max-width: 720px) {
  width: 3.5rem;
}
background-color: ${props =>  (props.backgroundColor || '')}
`;
const FilterComponent = ({ filterText, onFilter, onClear, backgroundColor }) => (
  <>
    <TextField id="search" type="text" placeholder="Filter By Category" aria-label="Search Input" value={filterText} onChange={onFilter} />
    {filterText && <ClearButton type="button" onClick={onClear} backgroundColor={backgroundColor}>clear</ClearButton>}
  </>
);
export const Contribution = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [year, setYear] = useState(moment().format('YYYY'));
  const [lastYearDate, setLastYear] = useState();
  const [allYears, setAllYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [amountToggle, setAmountToggle] = useState(false)

  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const lightTheme = orgInfo?.colorThemes[0]?.primary;
  const darkTheme = orgInfo?.colorThemes[1]?.primary;
  const getThemeChangeData = useSelector(state => state.getThemeChangeData, shallowEqual);
  const backgroundColor = getThemeChangeData?.theme ? darkTheme : lightTheme;
  // const [final, setFinal] = useState('')

  const resetModalState = () => {
    setIsOpen(false);
    setAllYears([]);
  }
 
  const yearData = useSelector((state) => state.contributions);
  const yearlyStatementData = useSelector((state) => state.statementContribution.responseData);

  const filteredItems = yearData?.recentContribution?.responseData?.contributionsHistory.filter(item => item.contributionType && item.contributionType.toLowerCase().includes(filterText.toLowerCase()));

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} backgroundColor={orgInfo?.colorThemes[0]?.primary} />;
  }, [filterText, resetPaginationToggle]);


  let user = getUserDetails();
  const dispatch = useDispatch();
  const tableTitle = [
    "",
    "Date and Time",
    "Amount",
    "Category",
    "Source",
    "Transaction ID",
    "Mode",
  ];
  const recentContribution = {
    orgId: orgInfo.orgId,
    userId: user.userId,
  };
  const contributionPerMonth = {
    orgId: orgInfo.orgId,
    userId: user.userId,
    startDate: `${moment().format("MM")}/01/${moment().format("YYYY")}`,
    endDate: `${moment().format("MM")}/31/${moment().format("YYYY")}`,
  };
  const contributionPerYear = {
    orgId: orgInfo.orgId,
    userId: user.userId,
    startDate: `01/01/${moment().format("YYYY")}`,
    endDate: `12/01/${moment().format("YYYY")}`,
  };
  const contributionLastYear = {
    orgId: orgInfo.orgId,
    userId: user.userId,
    startDate: `01/01/${moment()
      .subtract(1, "year")
      .format("YYYY")}`,
    endDate: `12/01/${moment()
      .subtract(1, "year")
      .format("YYYY")}`,
  };
  useEffect(() => {
    dispatch(
      UserContributionPerMonth(
        contributionPerMonth,
        contributionPerYear,
        recentContribution,
        contributionLastYear
      )
    );
    setLastYear(
      moment()
        .subtract(1, "year")
        .format("YYYY")
    );
  }, []);
  useEffect(() => {
    dispatch(yearlyStatement({
      orgId: orgInfo.orgId,
      userId: user.userId,
    }))
  }, [])

const getOneData = (row) => {
  console.log(row, 'get on');
}
  // money format 12234 = 12,234.00
  Number.prototype.format = function (n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
  };


  const handleClick = () => {
    setAmountToggle(true)
    const changeYear =
      moment().format("YYYY") === year
        ? moment()
          .subtract(1, "year")
          .format("YYYY")
        : moment().format("YYYY");
    setYear(changeYear);
    setLastYear(year);
    console.log(year, 'changeYear')
    const recentContribution = {
      orgId: orgInfo.orgId,
      userId: user.userId,
    };
    const contributionPerMonth = {
      orgId: orgInfo.orgId,
      userId: user.userId,
      startDate: `${moment().format("MM")}/01/${changeYear}`,
      endDate: `${moment().format("MM")}/31/${changeYear}`,
    };
    const contributionPerYear = {
      orgId: orgInfo.orgId,
      userId: user.userId,
      startDate: `01/01/${changeYear}`,
      endDate: `12/01/${changeYear}`,
    };
    const contributionLastYear = {
      orgId: orgInfo.orgId,
      userId: user.userId,
      startDate: `01/01/${year}`,
      endDate: `12/01/${year}`,
    };

    dispatch(
      UserContributionPerMonth(
        contributionPerMonth,
        contributionPerYear,
        recentContribution,
        contributionLastYear
      )
    );
  };

  const handleStatement = () => {
    setIsOpen(true);
  };

  const details =
    yearData &&
    yearData.contributionYear &&
    yearData.contributionYear.responseData.contributionsHistory.reduce(
      (total, item) => {
        const { contributionType, amount, currency } = item;
        if (!contributionType) return total;
        if (!total[contributionType]) {
          total[contributionType] = {
            name: contributionType,
            value: parseFloat(amount),
            currency
          };
        } else {
          total[contributionType] = {
            ...total[contributionType],
            value: total[contributionType].value + parseFloat(amount),
            currency
          };
        }
        return total;
      },
      {}
    );
  const totalMonthly =
    yearData &&
    yearData.contributionMonth &&
    yearData.contributionMonth.responseData.contributionsHistory.reduce(
      (total, item) => {
        const { amount, currency } = item;
        if (!amount) return total;
        if (!total["total"]) {
          total["total"] = { value: parseFloat(amount), currency };
        } else {
          total["total"] = {
            value: total["total"].value + parseFloat(amount),
            currency,
          };
        }
        return total;
      },
      {}
    );
  const totalYearly =
    yearData &&
    yearData.contributionYear &&
    yearData.contributionYear.responseData.contributionsHistory.reduce(
      (total, item) => {
        const { amount, currency } = item;
        if (!amount) return total;
        if (!total["total"]) {
          total["total"] = { value: parseFloat(amount), currency };
        } else {
          total["total"] = {
            value: total["total"].value + parseFloat(amount),
            currency,
          };
        }
        return total;
      },
      {}
    );

  const lastYear =
    yearData &&
    yearData.previousYear &&
    yearData.previousYear.responseData.contributionsHistory.reduce(
      (total, item) => {
        const { amount, currency } = item;
        if (!amount) return total;
        if (!total["total"]) {
          total["total"] = { value: parseFloat(amount), currency };
        } else {
          total["total"] = {
            value: total["total"].value + parseFloat(amount),
            currency,
          };
        }
        return total;
      },
      {}
    );
  const chartData = details && Object.values(details).sort((a, b) => {
    return b.value - a.value
  }).slice(0,5).map((item) => {
    return {
      name: item.name,
      value: Number(item.value).toFixed(2)
    }
  });

  const final = details &&
    Object.values(details).sort((a, b) => {
      return b.value - a.value
    }).slice(6).map((item, index) => {
      // console.log((item), 'here');
      let remainArray = 0
      return remainArray += parseFloat(item.value)
    })

  // const handleCloseModal = () => {
  //   setIsOpen(false);
  //   setAllYears([]);
  // };

  const handlePdfView = (data) => {
    window.open(`${data.pdfUrlStandard}`, "_blank");
  };
  const [page, setPage] = React.useState(1);
  const [next, setNext] = React.useState(50);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const indexOfData = page * next;
  const indexOfFirstData = indexOfData - next;
  const currentTodos = yearData && yearData.recentContribution && yearData.recentContribution.responseData.contributionsHistory.slice(indexOfFirstData, indexOfData);

  // Logic for displaying page numbers
  let pageNumbers;
  pageNumbers = yearData && yearData.recentContribution && Math.ceil(yearData.recentContribution.responseData.contributionsHistory.length / next);

  // React.useEffect(() => {
  //   if (contributionPdf && contributionPdf.responseData) {
  //     window.open(`${contributionPdf.responseData.pdfUrl}`, "_blank");
  //   }
  // }, [contributionPdf && contributionPdf.responseData])

 const {width} = screenSize();
  if (yearData.loading || isLoading) {
    return (
      <>
        <PageLoader />
      </>
    )
  } else {
    return (
      <div className="MuiPaper-root w-100 page-wrapper  my-8 p-6 shadow ">
        <div className="d-md-flex justify-content-between align-content-center">
          <div className="p-2 fw-500">{`${year} Contributions`}</div>

          <div>
            <p className="fw- text-md-center py-sm-3">
              {lastYearDate} :{" "}
              <Link to="#" className="font-weight-bolder" onClick={handleClick}>
                {lastYear &&
                  `${lastYear.total?.value?.format(2) ?? "0.00"} ${lastYear
                    .total?.currency ??
                  `${orgInfo?.defaultCurrency}`}`} 
              </Link>{" "}
              |{" "}
              {yearData &&
                yearData.contributionYear &&
                yearData.contributionYear.responseData.contributionsHistory
                  .length !== 0 ? (
                  <Link to="#" onClick={handleStatement}>
                    <i className="fas fa-file"></i> Statements
                  </Link>
                ) : <Link to="#">
                <i className="fas fa-file"></i> No Statement
              </Link>}
            </p>
          </div>
        </div>
        <hr />
        <div className="row p-6">
          <div className="d-flex align-items-center w-100">
            <div className="mr-2 amount-holder">
              {totalYearly &&
                `${totalYearly.total?.value?.format(2) ?? "0.00"}`}
                {/* {!totalYearly?.total && '0.00'} */}
            </div>
            <div className="display-4">
              {totalYearly &&
                `${totalYearly.total?.currency ??
                `${orgInfo.defaultCurrency}`}`}
                {/* {!totalYearly?.total && orgInfo.defaultCurrency} */}
            </div>
          </div>
          {moment().format("YYYY") === year && (
            <div className="text-muted w-100">
              {totalMonthly &&
                `This Month: ${totalMonthly.total?.value?.format(2) ??
                "0.00"} ${totalMonthly.total?.currency ??
                `${orgInfo.defaultCurrency}`}`}
                {!yearData?.contributionMonth && `This Month: 0.00 ${orgInfo.defaultCurrency}` }
            </div>
          )}
          <div className="col-md-12 my-5 pl-0">
            <div className="row">
              {details &&
                Object.values(details).sort((a, b) => {
                  return b.value - a.value
                }).slice(0, 5).map((item, index) => {
                  // console.log(Number(item.value) / 100 * (Number(totalYearly.total.value)));
                  const divideValue =
                    Number(item.value) / Number(totalYearly.total.value);
                  return (
                    <div className="col-md-4" key={index}>
                      <StatsComponent
                        growth={Number(divideValue * 100).toFixed(2)}
                        current={70}
                        subtext={`${item.name} ${Number(item.value).format(2)} ${totalYearly.total?.currency}`}
                        pcolor={Range(0, 10).includes(Math.ceil(divideValue * 100)) ? 'blue' : Range(11, 20).includes(Math.ceil(divideValue * 100)) ? 'green' :
                          Range(21, 40).includes(Math.ceil(divideValue * 100)) ? 'yellow' : Range(41, 80).includes(Math.ceil(divideValue * 100)) ? 'black' : 'red'
                        }
                      />
                    </div>
                  );
                })}
              {
               final?.length === 0  ?
                <div className="col-md-4">
                  <StatsComponent
                    growth={0}
                    current={0}
                    subtext={`Others 0.00 ${orgInfo.defaultCurrency}`}
                    pcolor=""
                  />
                </div> :
                !yearData?.contributionYear ? null :
                <div className="col-md-4">
                  <StatsComponent
                    growth={Number((parseFloat(final) / totalYearly?.total?.value) * 100).toFixed(2)}
                    current={30}
                    subtext={`Others ${parseFloat(final).format(2)} ${totalYearly?.total?.currency}`}
                    pcolor={Range(0, 20).includes(Math.ceil((parseFloat(final) / totalYearly?.total?.value) * 100)) ? 'blue' : Range(21, 40).includes(Math.ceil((parseFloat(final) / totalYearly?.total?.value) * 100)) ? 'green' :
                      Range(41, 60).includes(Math.ceil((parseFloat(final) / totalYearly?.total?.value) * 100)) ? 'yellow' : Range(61, 80).includes(Math.ceil((parseFloat(final) / totalYearly?.total?.value) * 100)) ? 'black' : 'red'
                    }
                  /> 
                </div>
              }
              {/* <div className="col-md-4">
                <StatsComponent
                  growth="45%"
                  current={30}
                  subtext="PMCH (0.00 USD)"
                  pcolor="bg-warning"
                />
              </div>
              <div className="col-md-4">
                <StatsComponent
                  growth="55%"
                  current={50}
                  subtext="Evangelization Funds (0.00 USD)"
                  pcolor="bg-success"
                />
              </div> */}
            </div>
            {/* Second layer */}
            {/* <div className="row">
              <div className="col-md-4">
                <StatsComponent
                  growth="65%"
                  current={70}
                  subtext="Feeding Others (0.00 USD)"
                  pcolor="bg-success"
                />
              </div>
              <div className="col-md-4">
                <StatsComponent
                  growth="45%"
                  current={30}
                  subtext="Shiloh Sacrifice (0.00 USD)"
                  pcolor="bg-primary"
                />
              </div>
              <div className="col-md-4">
                <StatsComponent
                  growth="100%"
                  current={50}
                  subtext="Others (0.00 USD)"
                  pcolor="bg-danger"
                />
              </div>
            </div> */}
          </div>
        </div>
        {/*  Chart */}
        <div className="d-flex justify-content-between align-content-center">
          <div className="p-2 fw-500">{`${year} Contributions`}</div>
        </div>
        <hr />
        {yearData?.contributionYear?.responseData?.contributionsHistory.length !== 0 && 
          <Chart data={chartData} totalYearly={totalYearly} final={final} />}
        <div className="w-100">
          {/* <hr /> */}
          {width <= 720 ? (
            <DataTable
              title="Recent Contributions"
              columns={columnsSmallScreen(getOneData)}
              data={filteredItems}
              pagination={true}
              paginationPerPage={50}
              theme={getThemeChangeData?.theme ? 'dark' : 'default'}
              noDataComponent='You have not made any donations yet!'
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
              />
          ): 
          <DataTable 
            title="Recent Contributions"
            columns={columns}
            data={filteredItems}
            pagination={true}
            theme={getThemeChangeData?.theme ? 'dark' : 'default'}
            paginationPerPage={50}
            paginationResetDefaultPage={resetPaginationToggle}
            noDataComponent='You have not made any donations yet!'
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
          />
          // <MUIDataTable
          //   data={filteredItems}
          //   columns={columns1}
            
          //   options={{
          //     filterType: "textField",
          //     responsive: "standard",
          //     filter: false,
          //     selectableRows: "none", // set checkbox for each row
          //     search: false, // set search option
          //     // filter: false, // set data filter option
          //     download: true, // set download option
          //     print: false, // set print option
          //     pagination: false, //set pagination option
          //     viewColumns: false, // set column option
          //     elevation: 0,
          //     //   customToolbar: CustomToolbar,
          //   }}
          // />
          }
          
          {/* </div> */}
        </div>
        {/* <ModalComponent
          show={isOpen}
          onHide={handleCloseModal}
          title="My Statement"
          backgroundColor={orgInfo?.orgColors?.primary}
          hideFooter={true}
        > */}
        <ResizeableModalWithoutBtn
            className="ml-5"
            modalState={isOpen}
            title='My Statement'
            maxWidth="xs"
            fullWidth={true}
            resetModalState={resetModalState}
            // titleIcon={<i className="fa fa-life-ring kt-font-primary"></i>}
            backgroundColor={backgroundColor}
            backgroundSecondaryColor={backgroundColor}
          >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell align="center">Contribution</TableCell>
                  <TableCell className="d-flex justify-content-center">View/Download/Print</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {yearlyStatementData?.returnedRecords.length > 0 ?
                  yearlyStatementData?.returnedRecords?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{item?.year}</TableCell>
                          <TableCell className="d-flex justify-content-center">
                            {item?.contribution}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                              }}
                              className="d-flex justify-content-center"
                            >
                              {item?.year}
                              <i
                                className="fas fa-file px-2"
                                style={{ cursor: "pointer", color: orgInfo?.colorThemes?.primary }}
                                onClick={() => handlePdfView(item)}
                              ></i>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }): (<p>No Statement</p>)}
              </TableBody>
            </Table>
          </TableContainer>
        </ResizeableModalWithoutBtn>
        {/* </ModalComponent> */}
      </div>
    );
  }
};
