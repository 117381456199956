import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardForm } from "./CardForm";
import { CheckForm } from "./CheckForm";
import Alert from "@material-ui/lab/Alert";
import { GiveWithExistingCardForm } from "./GiveWithExistingCardForm";
import {
  giveWithANewCard,
  giveWithAnExistingCardCheckingPortal,
  giveWithANewCheck,
  cardsOrCheckingProfiles,
} from "../../../store/actions/userDashboardActions/give";
import SuccessGiveComponent from "../../Forms/GiveForm/SuccessGiveComponent";
import Navigator from "../../Navigator";
import { useLocation } from "react-router-dom";
import {
  preferredPaymentExistingCard,
  preferredPaymentNewCard,
  preferredPaymentNewCheck,
} from "../../../store/actions";
import { getUserDetails } from "../../../utils/authHelpers";

export const CardDetailsReview = ({
  giveFormData,
  existingGive,
  maskedCardNumber,
  updatePageData,
  checkForm = false,
  closeModal,
  expirationDate,
  fullName,
}) => {
  const [goBackStep, setGoBackStep] = useState(false);
  const [goToCheckForm, setGoToCheckForm] = useState(false);
  const [goToExistingGiveForm, setGoToExistingGiveForm] = useState(false);
  const [isSuccessCard, setIsSuccessCard] = useState(false);

  const giveWithANewCardData = useSelector(
    (state) => state.giveWithANewCardData
  );
  const newCardDetails = useSelector((state) => state.newCardDetails);
  const newCheckDetails = useSelector((state) => state.newCheckDetails);
  const orgInfo = useSelector((state) => state.orgInfo.responseData);
  const defaultCurrency = orgInfo?.defaultCurrency;
  let totalContribution = 0;
  if (giveFormData?.listOfContributions) {
    giveFormData.listOfContributions.forEach((item) => {
      totalContribution += item.amount;
    });
  } else if (newCardDetails?.listOfContributions) {
    newCardDetails.listOfContributions.forEach((item) => {
      totalContribution += item.amount;
    });
  }

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const user = getUserDetails();

  const goBack = () => {
    console.log(checkForm, "checkForm props");
    if (existingGive) {
      setGoToExistingGiveForm(true);
      setGoToCheckForm(false);
      setGoBackStep(false);
    } else if (checkForm) {
      setGoToExistingGiveForm(false);
      setGoToCheckForm(true);
      setGoBackStep(false);
    } else {
      setGoToExistingGiveForm(false);
      setGoBackStep(true);
      setGoToCheckForm(false);
    }
  };

  const isPPS = () => {
    const ppsPath = "/user/donate/pps";
    return pathname === ppsPath;
  };

  const goNext = () => {
    if (giveFormData) {
      if (isPPS()) {
        const { givingCurrency, recordId, ...rest } = giveFormData;
        const ppsPayload = {
          ...rest,
          currencyCode: givingCurrency,
          totalAmount: giveFormData.listOfContributions.reduce(
            (acc, curr) => acc + curr.amount,
            0
          ),
        };
        return dispatch(preferredPaymentExistingCard(ppsPayload, showSuccessCard));
      }
      console.log(giveFormData, "giveFormData");
      return dispatch(
        giveWithAnExistingCardCheckingPortal(giveFormData, closeModal)
      );
    }
    if (checkForm) {
      if (isPPS()) {
        const {
          cardFirstName,
          cardLastName,
          expirationYear,
          accountNumber,
          maskedAcctNumber,
          ...rest
        } = newCheckDetails;
        const ppsPayload = {
          ...rest,
          currencyCode: defaultCurrency,
          totalAmount: newCardDetails.listOfContributions.reduce(
            (acc, curr) => acc + curr.amount,
            0
          ),
          accountNumber: Buffer.from(accountNumber, "base64").toString("ascii"),
        };
        return dispatch(preferredPaymentNewCheck(ppsPayload, showSuccessCard));
      }
      console.log("newCheckDetails", newCheckDetails);
      return dispatch(giveWithANewCheck(newCheckDetails, showSuccessCard));
    }
    if (isPPS()) {
      const {
        checkingFirstName,
        checkingLastName,
        expirationYear,
        cardNumber,
        maskedCardNumber,
        ...rest
      } = newCardDetails;
      const ppsPayload = {
        ...rest,
        currencyCode: defaultCurrency,
        expirationYear: expirationYear.substring(2),
        totalAmount: newCardDetails.listOfContributions.reduce(
          (acc, curr) => acc + curr.amount,
          0
        ),
        cardNumber: Buffer.from(cardNumber, "base64").toString("ascii"),
      };
      return dispatch(preferredPaymentNewCard(ppsPayload, showSuccessCard));
    }
    dispatch(giveWithANewCard(newCardDetails, showSuccessCard));
  };

  const showSuccessCard = () => {
    setIsSuccessCard(true);
    dispatch(
      cardsOrCheckingProfiles({
        orgId: orgInfo.orgId,
        userId: user.userId,
      })
    );
    // updatePageData()
  };

  return isSuccessCard ? (
    <SuccessGiveComponent customPath="/contribution" />
  ) : (
    <>
      {console.log("giveFormData", giveFormData)}
      {!goToCheckForm && !goBackStep && goToExistingGiveForm && (
        <GiveWithExistingCardForm
          maskedCardNumber={maskedCardNumber}
          fullName={fullName}
        />
      )}
      {!goBackStep && goToCheckForm && !goToExistingGiveForm && <CheckForm />}
      {goBackStep && !goToCheckForm && !goToExistingGiveForm && <CardForm />}
      {!goBackStep && !goToCheckForm && !goToExistingGiveForm && (
        <div className="container-md credit-card login-form login-signin activeForm-wrapper">
          <Alert severity="info">
            {" "}
            Please verify your contribution before clicking on the{" "}
            <strong>Process my Giving </strong> button below! If you need to
            cancel this transaction, click on the <strong>Back</strong> button.
          </Alert>
          <hr />
          <div className="d-flex justify-content-between ">
            <div>
              {checkForm ? (
                <p className="m-0 text-weight-bold">
                  <span className="mr-2">
                    <i className="fas fa-file-invoice font-size-sm"></i>
                  </span>
                  {`Account: ${newCheckDetails?.maskedAcctNumber}`}
                </p>
              ) : maskedCardNumber ? (
                <p className="m-0 text-weight-bold">
                  <span className="mr-2">
                    <i className="fa fa-hashtag font-size-sm"></i>
                  </span>
                  {`Card: ${maskedCardNumber}`}
                </p>
              ) : (
                <p className="m-0 text-weight-bold">
                  <span className="mr-2">
                    <i className="fa fa-hashtag font-size-sm"></i>
                  </span>
                  {`Card: ${newCardDetails?.maskedCardNumber}`}
                </p>
              )}
            </div>
            {checkForm || !goToExistingGiveForm ? null : (
              <p className="m-0 text-weight-bold">
                <span className="mr-2">
                  <i className="fa fa-calendar-day font-size-sm"></i>
                </span>
                <span>
                  {newCardDetails?.expirationMonth}/
                  {newCardDetails?.expirationYear?.substr(
                    newCardDetails?.expirationYear?.length - 2,
                    newCardDetails?.expirationYear?.length
                  )}
                </span>{" "}
              </p>
            )}
          </div>
          <hr />
          <>
            <div className="final-give-info-container mt-10">
              {giveFormData
                ? giveFormData?.listOfContributions?.map((item, index) => (
                    <div key={index} className="final-give-info">
                      <p className="pl-0">
                        <span className="mr-2">
                          <i className="font-size-sm fa fa-hand-holding-heart"></i>
                        </span>
                        {item?.contributionType}:
                      </p>
                      <p> {`${item?.amount} ${defaultCurrency}`}</p>
                    </div>
                  ))
                : newCardDetails?.listOfContributions?.map((item, index) => {
                    console.log(item, "item");
                    return (
                      <div key={index} className="final-give-info">
                        <p className="pl-0">
                          <span className="mr-2">
                            <i className="font-size-sm fa fa-hand-holding-heart"></i>
                          </span>
                          {item?.contributionType}:
                        </p>
                        {/* <p> {`${currencySymbol}${item?.amount}`}</p> */}
                        <p>
                          {item?.amount} {defaultCurrency}
                        </p>
                      </div>
                    );
                  })}
              {(giveFormData?.listOfContributions?.length > 1 ||
                newCardDetails?.listOfContributions?.length > 1 ||
                newCheckDetails.listOfContributions?.length > 1) && (
                <div className="final-give-info">
                  <p className="pl-0">
                    <span className="mr-2">
                      <i className="font-size-sm fa fa-money-bill"></i>
                    </span>
                    Total Contributions:{" "}
                  </p>
                  <p> {totalContribution + " " + orgInfo?.defaultCurrency}</p>
                </div>
              )}
              {fullName ? (
                <div className="final-give-info">
                  <p className="pl-0">
                    <span className="mr-2">
                      <i className="fa fa-user font-size-sm"></i> Full Name:
                    </span>
                  </p>
                  <p>
                    <span className="font-weight-bold">{fullName}</span>
                  </p>
                </div>
              ) : (
                <div className="final-give-info">
                  <p className="pl-0">
                    <span className="mr-2">
                      <i className="fa fa-user font-size-sm"></i>
                    </span>
                    Full Name:{" "}
                  </p>
                  <p>
                    {" "}
                    <span className="font-weight-bold">
                      {newCardDetails?.cardFirstName +
                        " " +
                        newCardDetails?.cardLastName}
                    </span>
                  </p>
                </div>
              )}
              {existingGive ? null : checkForm ? (
                <div className="final-give-info">
                  <p className="pl-0">
                    <span className="mr-2">
                      <i className="fa fa-hashtag font-size-sm"></i>
                    </span>
                    Routing Number:{" "}
                  </p>
                  <p>
                    {" "}
                    <span className="font-weight-bold">
                      {newCheckDetails?.routingNumber}
                    </span>
                  </p>
                </div>
              ) : (
                <div className="final-give-info">
                  <p className="pl-0">
                    <span className="mr-2">
                      <i className="fa fa-lock font-size-sm"></i>
                    </span>
                    Security Code (CVV):{" "}
                  </p>
                  <p>
                    {" "}
                    <span className="font-weight-bold">
                      {newCardDetails?.cvv}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <Navigator
              goBack={goBack}
              goNext={goNext}
              cardReview="Process my Giving"
              loading={giveWithANewCardData?.loading}
              className="py-3"
            />
          </>
        </div>
      )}
    </>
  );
};
