import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import StepOne from "./StepOne";
import { useHistory } from "react-router-dom";
import { getUserDataSmartGive } from "../../store/actions/smartGive";
import {
  Flutterwave,
  StripePayment,
  displayDonatedAmountErrorMsg,
  paystack,
  processPaypal,
  quickGiveProcessingEngines,
  scrollToAmountErrorMsg,
  selectedPaymentOption,
  setGiveInfo,
  setThemeChange,
} from "../../store/actions";
import { CardForm } from "./CardForm";
import { CardDetailsReview } from "./CardDetailsReview";
import {
  cardsOrCheckingProfiles,
  getGiveMethods,
} from "../../store/actions/userDashboardActions/give";
import ChoosePayment from "./ChoosePayment";
import { isEmpty } from "../../utils";
import { toast } from "react-toastify";
import GiveMethodsModal from "./GiveMethodsModal";

const SmartGive = () => {
  const currentURL = window.location.href;
  const searchParams = new URLSearchParams(new URL(currentURL).search);
  const t = searchParams.get("t");
  const org_id = searchParams.get("org_id");
  const themeColor = useThemeColor();
  const dispatch = useDispatch();
  let history = useHistory();

  const selectedPayment = useSelector((state) => state.selectedPaymentOption);
  const amount = useSelector((state) => state.donatedAmountData.amount);
  const { logo, fullName, orgId } = useSelector(
    (state) => state.orgInfo.responseData
  );
  const cardsOrCheckingProfilesData = useSelector(
    (state) => state.cardsOrCheckingProfilesData.responseData
  );
  const quickGiveProcessingEnginesData = useSelector(
    (state) => state.quickGiveProcessingEngines?.responseData?.returnedRecords
  );
  const { data: giveMethodsData } = useSelector((state) => state?.giveMethods);

  const existingCards =
    (selectedPayment?.type?.toLowerCase() &&
      cardsOrCheckingProfilesData?.returnedRecords?.filter(
        (item) =>
          item.type.toLowerCase() === selectedPayment?.type?.toLowerCase()
      )) ||
    [];

  const [giveError, setGiveError] = useState(false);
  const [selectedFreqValue, setSelectedFreqValue] = useState("");
  const [active, setActive] = useState(true);
  const [activeRec, setActiveRec] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [step, setStep] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isExisting, setIsExisting] = useState(false);
  const [isSuccessCard, setIsSuccessCard] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [memo, setMemo] = useState("");
  const [openGive, setOpenGive] = useState(false);
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!t || !org_id) return history.push("/");
    dispatch(getUserDataSmartGive({ orgId: org_id, token: t }, getUserDataCB));
    dispatch(
      quickGiveProcessingEngines({
        orgId: org_id,
      })
    );
    dispatch(setThemeChange({ theme: false }));
    dispatch(getGiveMethods({ org_id: orgId }));
    return () => {
      handleCloseAlert();
    };
  }, []);

  useEffect(() => {
    if (quickGiveProcessingEnginesData?.length === 1)
      dispatch(
        selectedPaymentOption({
          ...selectedPayment,
          type: quickGiveProcessingEnginesData[0]?.type,
          currency: quickGiveProcessingEnginesData[0]?.currency,
        })
      );
  }, [quickGiveProcessingEnginesData]);

  const handleCloseAlert = () => {
    dispatch(
      displayDonatedAmountErrorMsg({
        amountErrorMsg: "",
        showAmountError: false,
        scrollToAmountInput: false,
      })
    );
    dispatch(scrollToAmountErrorMsg(false));
  };

  const getUserDataCB = (data) => {
    const { responseCode, responseData } = data;
    if (responseCode !== "00") return history.push("/");
    dispatch(
      cardsOrCheckingProfiles({
        orgId: org_id,
        userId: responseData.userId,
      })
    );
    setStep(1);
    setUserInfo(responseData);
  };

  const handleClick = () => {
    setActive((prevState) => ({ active: !prevState.active }));
    setActiveRec(false);
  };
  const handleClickRec = () => {
    setActiveRec((prevState) => ({ activeRec: !prevState.activeRec }));
    setActive(false);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleContributionChange = ({ target: { value } }) => {
    const selectedItem = quickGiveProcessingEnginesData.find(
      (item) => item.type === value
    );
    dispatch(
      selectedPaymentOption({
        ...selectedPayment,
        type: value,
        currency: selectedItem?.currency,
        minimumAmount: selectedItem?.minimumAmount,
        maximumAmount: selectedItem?.maximumAmount,
      })
    );
  };

  const handleBlur = () => {
    if (amount > 0 && amount < parseInt(selectedPayment.minimumAmount)) {
      const errorAmount = `Amount cannot be less than ${selectedPayment.minimumAmount} ${selectedPayment.currency}`;
      return dispatch(
        displayDonatedAmountErrorMsg({
          amountErrorMsg: errorAmount,
          showAmountError: true,
        })
      );
      // return dispatch(scrollToAmountErrorMsg(true));
    } else if (amount > 0 && amount > parseInt(selectedPayment.maximumAmount)) {
      const errorAmount = `Amount cannot be more than ${selectedPayment.maximumAmount} ${selectedPayment.currency}`;
      return dispatch(
        displayDonatedAmountErrorMsg({
          amountErrorMsg: errorAmount,
          showAmountError: true,
        })
      );
    }
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const goNext = () => {
    if (step === 1) {
      if (amount === 0) {
        const errorAmount = `Amount is Required`;
        return dispatch(
          displayDonatedAmountErrorMsg({
            amountErrorMsg: errorAmount,
            showAmountError: true,
          })
        );
      }

      if (amount < parseInt(selectedPayment.minimumAmount)) {
        const errorAmount = `Amount cannot be less than ${selectedPayment.minimumAmount} ${selectedPayment.currency}`;
        return dispatch(
          displayDonatedAmountErrorMsg({
            amountErrorMsg: errorAmount,
            showAmountError: true,
          })
        );
        // return dispatch(scrollToAmountErrorMsg(true));
      } else if (amount > parseInt(selectedPayment.maximumAmount)) {
        const errorAmount = `Amount cannot be more than ${selectedPayment.maximumAmount} ${selectedPayment.currency}`;
        return dispatch(
          displayDonatedAmountErrorMsg({
            amountErrorMsg: errorAmount,
            showAmountError: true,
          })
        );
      }

      const errors = {};
      let payload = {};
      if (active) {
        if (selectedValue === "" || selectedValue === null) {
          errors.giveType = "Select a Give Type from the list";
        }
        if (errors?.giveType) {
          return setGiveError(errors);
        } else setGiveError({});
        if (isEmpty(errors)) {
          dispatch(
            setGiveInfo({
              contributionType: selectedValue,
              recurring: "once",
              isActive: active,
            })
          );
        }
      } else {
        // console.log(!active, 'inactive')
        if (selectedValue === "" || selectedValue === null) {
          errors.giveType = "Select a Give Type from the list";
        }
        if (selectedFreqValue === "" || selectedFreqValue === null) {
          errors.freqValue = "Select a Give Frequency";
        }
        if (startDate === null || startDate === "") {
          errors.startDate = "Select a Start Date";
        }
        if (!isEmpty(errors)) {
          return setGiveError(errors);
        }
        if (isEmpty(errors)) {
          payload = {
            contributionType: selectedValue,
            recurring: selectedFreqValue,
            recurringStartDate: moment(startDate).format("MM/DD/YYYY"),
            isActive: active,
          };
          dispatch(setGiveInfo(payload));
        }
      }

      const nextGenPayload = {
        email: userInfo?.userEmail,
        firstName: userInfo?.userFirstName,
        lastName: userInfo?.userLastName,
        listOfContributions: [
          {
            amount,
            contributionType: selectedValue,
            memo: memo,
          },
        ],
        mobile,
        orgId: orgId,
        totalAmount: amount,
      };
      switch (selectedPayment.type) {
        case "PAYPAL":
          setLoading(true);
          return dispatch(processPaypal(nextGenPayload, stopLoading));
        case "STRIPE":
          setLoading(true);
          return dispatch(StripePayment(nextGenPayload, stopLoading));
        case "FLUTTERWAVE":
          setLoading(true);
          return dispatch(Flutterwave(nextGenPayload, stopLoading));
        case "PAYSTACK":
          setLoading(true);
          return dispatch(paystack(nextGenPayload, stopLoading));
      }
    }
    if (step === 2) {
      if (tabIndex === 0 && !selectedCard && existingCards?.length > 0) {
        return toast.error("Please select a card");
      }

      if (tabIndex === 0 && existingCards?.length > 0) {
        setIsExisting(true);
      } else setIsExisting(false);
    }

    setStep(step + 1);
  };

  const handleChangeInColor = (status) => {
    if (status) {
      return {
        backgroundColor: themeColor.color,
        color: "#fff",
      };
    }
  };

  const getSelectValue = (value) => {
    setSelectedValue(value);
  };
  const getFrequencyValue = (value) => {
    setSelectedFreqValue(value);
  };
  const getStartDate = (value) => {
    // const formattedDate = moment(value).format("MM/DD/YYYY");
    setStartDate(value);
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const checkForm = () => {
    if (
      selectedPayment?.type.toLowerCase() === "check" &&
      existingCards?.length === 0
    )
      return true;

    if (
      selectedPayment?.type.toLowerCase() === "check" &&
      tabIndex !== 0 &&
      existingCards?.length > 0
    )
      return true;

    return false;
  };

  const handleMemoChange = ({ target: { value } }) => setMemo(value);

  const handleOpenGive = () => {
    setOpenGive(!openGive);
  };

  const mobileRequired = () => {
    const numberRequiredTypes = ["PAYPAL", "STRIPE", "FLUTTERWAVE", "PAYSTACK"];
    return numberRequiredTypes.includes(selectedPayment?.type);
  };

  return (
    <div className="smart-give-container h-100">
      {step === 1 && !isSuccessCard && (
        <div className="px-5 pt-2">
          <div className="d-flex p-2 justify-content-between mb-10 align-items-center">
            <p className="font-size-h3">Hi {userInfo?.userFirstName},</p>
            <img src={logo} className="h-60px" alt="church logo" />
          </div>

          <h5 className="text-center mb-2">
            Welcome To <span className="font-weight-boldest">{fullName}</span>
          </h5>
          {quickGiveProcessingEnginesData?.length > 1 && (
            <h6 className="text-center mt-8">
              Please choose a contribution type to proceed with your giving
            </h6>
          )}
        </div>
      )}

      {step > 1 && !isSuccessCard && (
        <div className="d-block">
          <div
            className="p-2"
            // style={{ backgroundColor: themeColor.color }}
          >
            <p className="text-center font-size-h5">
              Hi {userInfo?.userFirstName},
            </p>
          </div>
          <div className="d-flex p-2">
            <img src={logo} className="h-60px mx-auto" alt="church logo" />
          </div>
        </div>
      )}

      {step === 1 && (
        <StepOne
          getSelectValue={getSelectValue}
          handleChangeInColor={handleChangeInColor}
          handleClick={handleClick}
          active={active}
          activeRec={activeRec}
          giveError={giveError}
          getFrequencyValue={getFrequencyValue}
          getStartDate={getStartDate}
          selectedFreqValue={selectedFreqValue}
          handleClickRec={handleClickRec}
          bgColor={themeColor.color}
          goNext={goNext}
          selectedValue={selectedValue}
          date={startDate}
          handleContributionChange={handleContributionChange}
          quickGiveProcessingEngines={quickGiveProcessingEnginesData}
          selectedPayment={selectedPayment}
          handleBlur={handleBlur}
          handleMemoChange={handleMemoChange}
          memo={memo}
          mobile={mobile}
          setMobile={setMobile}
          mobileRequired={mobileRequired}
          loading={loading}
        />
      )}

      {step === 2 && (
        <ChoosePayment
          existingCards={existingCards}
          goBack={goBack}
          goNext={goNext}
          selectedValue={selectedValue}
          setSelectedCard={setSelectedCard}
          selectedCard={selectedCard}
          tabIndex={tabIndex}
          handleChange={handleChange}
          userInfo={userInfo}
          org_id={org_id}
        />
      )}

      {step === 3 && (
        <CardDetailsReview
          goBack={goBack}
          userInfo={userInfo}
          contributionType={selectedValue}
          orgId={org_id}
          amount={amount}
          checkForm={checkForm()}
          isExisting={isExisting}
          selectedCard={selectedCard}
          isSuccessCard={isSuccessCard}
          setIsSuccessCard={setIsSuccessCard}
          successMsg={successMsg}
          setSuccessMsg={setSuccessMsg}
          memo={memo}
        />
      )}

      {step === 1 && (
        <>
          {giveMethodsData?.length > 0 && (
            <div className="w-100 d-block p-5">
              <p
                className="text-center mx-auto cursor-pointer text-blue"
                style={{ textDecoration: "underline" }}
                onClick={handleOpenGive}
              >
                More ways to give
              </p>
            </div>
          )}
          {openGive && (
            <GiveMethodsModal isOpen={openGive} onClose={handleOpenGive} />
          )}
        </>
      )}
    </div>
  );
};

export default SmartGive;
