import React, {useEffect, useState} from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ModalComponent } from "../../Common/Modal/index";
import {ButtonContainer} from '../../Common/Button'

export default function ImageCropper({show, handleClose, image, backgroundColor, handleCropImage}) {
    const [cropper, setCropper] = useState();
    const getCropData = () => {
        if (typeof cropper !== "undefined") {
        //   setIsLoading(true);
        //   setCropData(cropper.getCroppedCanvas().toDataURL());
        // console.log(cropper.getCroppedCanvas().toDataURL());
        handleCropImage(cropper.getCroppedCanvas().toDataURL())
          // handleClose();
        }
        // dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), 'my.png')
        // console.log(dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), 'my.png'));
      };
    return (
        <div>
        <ModalComponent
        show={show}
        onHide={handleClose}
        title="Image Crop"
        header="Crop and click the button below to continue"
      >
        <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
        {/* {isLoading ? (
          <Skeleton variant="text" />
        ) : ( */}
          <div className='d-flex justify-content-between pt-4'>
          <ButtonContainer className="btn btn-primary my-5" onClick={getCropData} backgroundColor={backgroundColor}>
            Save
          </ButtonContainer>
          <ButtonContainer className="btn btn-primary my-5" onClick={handleClose} backgroundColor={backgroundColor}>
            Close
          </ButtonContainer>
          </div>
        {/* )} */}
      </ModalComponent> 
        </div>
    )
}
