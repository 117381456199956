import React, { useEffect, useState, useRef } from "react";
import "./TwoFA.scss";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import { verify2FA } from "../../store/actions";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getUserDetails } from "../../utils/authHelpers";
import { toast } from "react-toastify";
import NotificationMesage from "../Common/NotificationMesage";
import Alert from "@material-ui/lab/Alert";
// import PinInput from 'react-pin-input'
import PinInputComponent from "../Common/PinInput/index";
import {resetEndpointDataAction} from '../../store/actions/index'

export default function TwoFactorAuthentication() {
  const themeColor = useThemeColor();
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [failedNum, setFailedNum] = React.useState(0);
  let history = useHistory();
  let dispatch = useDispatch();
  let location = useLocation();
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  let user = JSON.parse(localStorage.getItem("2fa"));

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  const redirectToDashboard = () => {
    history.replace(from);
  };

  useEffect(() => {
    localStorage.removeItem("failed_no");
    localStorage.setItem("failed_no", failedNum);
  }, [failedNum]);

  const handleSubmit = (data) => {
    // console.log(data);
    const payload = {
      orgId: orgInfo.orgId,
      userId: user?.responseData?.userId,
      advancedAccessCode: data,
    };
    setIsLoading(true);
    verify2FA(payload)
      .then((result) => {
        console.log(result, "result");
        if (result.responseCode === "00") {
          localStorage.setItem("user", JSON.stringify(result));
          toast.success(
            <NotificationMesage
              message={result.responseMessage}
              responseStatus={result.responseCode}
            />
          );
          redirectToDashboard();
        } else {
          toast.error(
            <NotificationMesage
              message={result.responseMessage}
              responseStatus={result.responseCode}
            />
          );
          if (failedNum && Number(failedNum) <= 5) {
            setFailedNum(Number(failedNum) + 1);
          }
          if (failedNum && Number(failedNum) === 5) {
            history.push("/");
          } else {
            setFailedNum(Number(failedNum) + 1);
          }
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const handleBackToLogin = () => {
    dispatch(resetEndpointDataAction)
    history.push('/')
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form 2fa">
      <div className="text-center mb-5 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="fas fa-key"></i>
          </span>
        </div>
        <h3 className="font-size-h1 mb-5">Authenticate Your Account</h3>
        <p className="text-muted font-weight-bold">
          Protecting your account is our top priority. Please confirm your
          account by entering the authorization code sent to you.
        </p>
      </div>
      <div className="">
        <PinInputComponent setInputValue={handleSubmit} />
      </div>
      <Alert variant="outlined" severity="info">
        It may take a minute to recieve your code
      </Alert>
      <div>
      <button
          id="kt_login_signin_submit"
          onClick={handleBackToLogin}
          style={themeColor.btnStyle}
          className={`btn w-100 mt-5 font-weight-bold text-white py-4 px-9 mb-5`}
        >
          <span>Back To Login</span>
        </button>
      </div>
      {/* <p>it may take a minute to recieve your code</p> */}
    </div>
  );
}
