import React from "react";
import { Skeleton } from "@material-ui/lab";
import './../styles.scss';

const CampaignLoader = () => {
  return (
    <div className=" m-auto campaign-loader">
      <div className="loader-wrapper">
        <div className="skeleton-container w-100">
          <Skeleton  variant="rect" height={40} />
        </div>
        <div className="skeleton-container">
          <Skeleton  variant="rect" height={40} />
        </div>
        <div className="skeleton-container">
          <Skeleton  variant="rect" height={40} />
        </div>
        <div className="skeleton-container">
          <Skeleton  variant="rect" height={40} />
        </div>
        <div className="skeleton-container">
          <Skeleton  variant="rect" height={40} />
        </div>
        <div className="skeleton-container">
          <Skeleton  variant="rect" height={40} />
        </div>
      </div>
    </div>
  );
};

export default CampaignLoader;
