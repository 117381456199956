import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './celebration.css'
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function CelebrationCard({img, name, isPaused, setIsPaused, isShowPlay}) {
  const history = useHistory()
  const handleClick = () => {
    history.push(`/celebration`)
  }
  const [fullscreen, setFullscreen] = useState(false)

  const handle = useFullScreenHandle();

  const toggleFullScreen = () => {
    setFullscreen(!fullscreen);
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {

  
    return () => {
      setFullscreen(false)
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }, [])
  
  
  return (
    <FullScreen handle={handle}>
      <div className={img?.profilePicture?.length > 1 ? 'celebration_card_2' : "celebration_card"}>
        <div style={{display: 'flex', justifyContent: 'center', padding: '2rem 0px'}}>
        { <i className="fa fa-arrows-alt" aria-hidden="true" onClick={toggleFullScreen}></i>}
        {isShowPlay === 'true' && isPaused && <i className="fa fa-play" aria-hidden="true" onClick={() => setIsPaused(!isPaused)}></i>}
        {isShowPlay === 'true' && !isPaused && <i onClick={() => setIsPaused(!isPaused)} className="fa fa-pause" aria-hidden="true"></i>}

      {img?.profilePicture?.length > 0 && img?.profilePicture?.map((item, index) => (
        <div className='px-2'>
        <img src={item} alt="birthday" className="birthday" key={index} />
        </div>
      ))}

        </div>
      
      <div className="text">
      <i className="fa fa-home" aria-hidden="true" onClick={handleClick}></i>
      <h1 className={img?.profilePicture?.length > 1 ? 'white-text' : img?.profilePicture?.length === 0 ? 'white-text_3' : 'white-text_2'}>{generalText(name)}</h1>
      <p className={img?.profilePicture?.length > 1 ? 'white-text' : img?.profilePicture?.length === 0 ? 'white-text_3' : 'white-text_2'} dangerouslySetInnerHTML={{__html: img?.fullName}} />
      <p className="muted white-text">{img?.eventDate}</p>
    </div>
    <div className="space"></div>
    </div>

    </FullScreen>
  )
}

const generalText = (name) => {
  if (name === 'birthdayCelebrations') return 'Happy Birthday!'
  if (name === 'weddingCelebrations') return 'Happy Wedding Anniversary!'
  if (name === 'childrenBirthdayCelebrations') return 'Happy Birthday!'
  if (name === 'newBabiesCelebrations') return 'Congratulations!'
}