export const contributionActionTypes = {
    CONTRIBUTION_LOADING: 'CONTRIBUTION_LOADING',
    CONTRIBUTION_SUCCESS_MONTH: 'CONTRIBUTION_SUCCESS_MONTH',
    CONTRIBUTION_SUCCESS_YEAR: 'CONTRIBUTION_SUCCESS_YEAR',
    CONTRIBUTION_SUCCESS_RECENT: 'CONTRIBUTION_SUCCESS_RECENT',
    CONTRIBUTION_ERROR: 'CONTRIBUTION_FAILURE',
    CONTRIBUTION_LAST_YEAR: 'CONTRIBUTION_LAST_YEAR',
    CONTRIBUTION_PDF_SUCCESS: 'CONTRIBUTION_PDF_SUCCESS',
    YEARLY_CONTRIBUTION_STATEMENT_SUCCESS: 'YEARLY_CONTRIBUTION_STATEMENT_SUCCESS',
    YEARLY_CONTRIBUTION_STATEMENT_FAILURE : 'YEARLY_CONTRIBUTION_STATEMENT_FAILURE'
} 