import React from "react";
import { useSelector } from "react-redux";
import ReactReadMoreReadLess from "react-read-more-read-less";
import "./styles.scss";

const Footer = ({ themeMode, isDynamicGiveCampaign }) => {
  const ipData = useSelector((state) => state.ipData);
  const orgInfo = useSelector((state) => state.orgInfo.responseData);

  let footerContainer = "";
  let prioritiseSecurityTextColor = "";
  let showMore = "";
  let readStyle = "";
  if (themeMode === "light") {
    footerContainer = "footer-light-container";
    prioritiseSecurityTextColor = "prioritise-security-light-mode-text";
    showMore = "show-more-light-mode";
    readStyle = "#64748B";
  } else if (themeMode === "dark") {
    footerContainer = "footer-dark-container";
    prioritiseSecurityTextColor = "prioritise-security-dark-mode-text";
    showMore = "show-more-dark-mode";
    readStyle = "#CBD5E1";
  }

  return (
    <div className={footerContainer}>
      {!isDynamicGiveCampaign && <div className="food-pantry">
        <img src={orgInfo?.logo} alt="Food Pantry image" className="w-50px" />
        <h3>The Lord’s Table Food Pantry</h3>
      </div>}
      <div className={`prioritise-security ${prioritiseSecurityTextColor}`}>
        {themeMode === "light" ? (
          <img src="/images/vector.png" alt="Security" />
        ) : (
          <img src="/images/security-dark.png" alt="Security" />
        )}
        <p>{orgInfo?.privacySecurityStuff?.securityNoteLabel}</p>
      </div>
      {/* <p className='transaction-secured'>Your transaction is 100% secured!</p> */}
      <div className={`show-more ${showMore}`}>
        <ReactReadMoreReadLess
          charLimit={34}
          readMoreText={"Learn more  ▼"}
          readLessText={"Hide ▲"}
          readMoreClassName=""
          readLessClassName=""
          ellipsis=""
          readMoreStyle={{
            whiteSpace: "nowrap",
            cursor: "pointer",
            display: "block",
            color: { readStyle },
            fontSize: "12px",
            textDecoration: "none",
            marginTop: "20px",
          }}
          readLessStyle={{
            whiteSpace: "nowrap",
            cursor: "pointer",
            display: "block",
            color: { readStyle },
            fontSize: "14px",
            textDecoration: "none",
            marginTop: "10px",
          }}
        >
          {`${orgInfo?.privacySecurityStuff?.securityNoteContent} ${ipData?.ip}`}
        </ReactReadMoreReadLess>
      </div>
      <p className={`service-provided ${showMore}`}>
        Service provided by ChurchPad
      </p>
      {/* <div className="app-download-button">
        <img
          src="images/apple_download_button.png"
          alt="Apple download button"
        />
        <img
          src="images/google_play_download_button.png"
          alt="Apple download button"
        />
      </div> */}
      <div className="church-logo">
        <img
          src="/images/church_logo.png"
          alt="Church Logo"
          // className='church-logo'
        />
      </div>
    </div>
  );
};

export default Footer;
