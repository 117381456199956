import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { DonutChartComponent } from "../Common/Donut";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export const Chart = ({ data, totalYearly, final }) => {
  Number.prototype.format = function(n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
  };
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const sliceDatas =final &&  [...data, {name: 'Others', value: parseFloat(final)}]
  const slicedData =final?.length !== 0 ? sliceDatas : data


  return (
    <div className="row p-6">
      <div className="col-md-6 pl-0">
        <div className="table-responsive w-100">
          <TableContainer component={Paper}>
            <Table >
              <TableHead>
                <TableRow className="font-weight-bolder">
                  <TableCell>Type</TableCell>
                  <TableCell className="text-right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.sort((a,b) => {
                    return b.value - a.value
                  }).slice(0,5).map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <>
                          <TableCell key={index}>{item.name}</TableCell>
                          <TableCell className="text-right ">
                            {item.value} {' '}
                            <span style={{fontSize: '8px'}}>
                            {item.currency}
                              </span>
                            </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
                  {final?.length !== 0 &&
                    <TableRow>
                      <>
                        <TableCell>Others</TableCell>
                        <TableCell className="text-right ">
                          {final && final.length !== 0 ? parseFloat(final).format(2) : "0.00"} {" "}
                          <span style={{fontSize: '8px'}}>
                          {totalYearly && totalYearly.total?.currency}
                            </span>
                          </TableCell>
                      </>
                    </TableRow>}
              </TableBody>
              <TableFooter>
                <TableRow className="font-weight-bolder">
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell className="text-right"><strong>
                    {totalYearly &&
                      `${totalYearly.total?.value?.format(2) ??
                        "0.00"} ${totalYearly.total?.currency ?? `${orgInfo?.defaultCurrency}`}`}
                        </strong>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
        </TableContainer>
        </div>
      </div>
      <div className="col-md-6 text-center" style={{marginTop: '-35px'}}>
        <DonutChartComponent data={slicedData} />
      </div>
    </div>
  );
};
