import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Paper } from '@material-ui/core'
import { processPaypal, contributionProcessingEngines } from '../../../store/actions'
import Navigator from '../../Navigator';
import { GiveOption } from '../../Forms/GiveForm/GiveOption';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor'
import { TextField } from '@material-ui/core'
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {logout} from '../../../utils/authHelpers'


export const Paypal = () => {
  const themeColor = useThemeColor();
  const history = useHistory();
  const dispatch = useDispatch();
  // const backgroundColor = themeColor.color;
  const [inputValue, setInputValue] = useState({amount: '', memo: ''})
  const [giveError, setGiveError] = useState({giveType: ''});
  const [error, setError] = useState('');
  const [selectedValue, setSelectedValue] = useState('')
  const [token, setToken] = useState('');
  const [dynamicAction, setDynamicAction] = useState('submit');

  const orgInfo = useSelector(state => state.orgInfo.responseData);
  const processPaypalData = useSelector((state) => state.processPaypalData);

  const contributionProcessingEnginesData = useSelector(
    (state) => state.contributionProcessingEnginesData?.responseData?.returnedRecords
  );
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  
    
  useEffect(() => {
    if(processPaypalData?.links && processPaypalData?.links[1].rel === 'approve'){
      window.open(processPaypalData?.links[1].href, '_self')
    }
  }, [processPaypalData.status])


  const getSelectValue = (value) => {
    setSelectedValue(value);
  }

  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('recaptcha verifiction not successful');
      return;
    }

    const result = await executeRecaptcha('dynamicAction');
    // console.log(result, 'result');
    setToken(result);
  }, [dynamicAction, executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      if  (!token) {
        logout()
      } else {
        setToken(token);
      }
    };

    handleReCaptchaVerify();
    
  }, [executeRecaptcha, dynamicAction]);

  // This method return the payment object
  const paymentMethod = (type) => {
    return contributionProcessingEnginesData.filter(item => {
      if(item.type === type){
        return item;
      }
    })
  }

  const handleChange = (e) => {
    setInputValue({...inputValue, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    if(inputValue.amount) setError('')
    if(selectedValue) setGiveError({giveType: ''})
  }, [inputValue.amount, selectedValue])

  const handlePaypalPayment = () => {
    if(!inputValue.amount){
      setError('Enter a Give Amount')
    }
    if(!selectedValue){
    return setGiveError({giveType: 'Select a Contribution Type'})
    }
    const paypalPayment = paymentMethod('PAYPAL')
    const currencyCode = paypalPayment.currency
    // const currencyCode = 'USD'
    const payload = {
      "cancelUrl": `${window.location.origin}/paypal/failure`,
      currencyCode,
      "email": userProfileData.userEmail,
      "firstName": userProfileData.userFirstName,
      "lastName": userProfileData.userLastName,
      "listOfContributions": [
        {
          amount: inputValue.amount,
          "contributionType": selectedValue,
          "memo": inputValue.memo
        }
      ],
      "mobile": userProfileData?.userPhoneNumbersList[0],
      "orgId": orgInfo.orgId,
      "successUrl": `${window.location.origin}/paypal/success`,
      "totalAmount": inputValue.amount
    }
    dispatch(processPaypal(payload))
  }

  
  const goToDashboard = () => {
    history.push('/dashboard')
  }


  return (
      <Paper className="w-100 page-wrapper my-8 p-6">
        <div className="col-md-10 m-auto col-lg-6">
          {/* <h5 className='py-3 text-center'>Give with PayPal</h5> */}
          <div className="text-center"><img height="70px" src="images/paypal_label.png" alt="paypal_banner" /></div>
          <div className="d-flex my-2">
            <p className='ml-5' style={{fontSize: '13px'}}><strong></strong></p>
          </div>
          <TextField
            // className="mb-8"
            name="amount"
            label="Amount"
            variant="outlined"
            type='number'
            fullWidth
            onChange={handleChange}
            value={inputValue.amount || ''}
            error={Boolean(error)}
            helperText={error}
          />
          <GiveOption getSelectValue={getSelectValue} giveError={giveError} />
          <TextField
            className="mt-8"
            label="Memo"
            name="memo"
            maxLength={100}
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            onChange={handleChange}
            inputProps={{maxLength: 100}}
            value={inputValue.memo || ''}
          />
          <p>{`${(100 - (inputValue?.memo?.length))} character remaining`}</p>
          <Navigator goBack={goToDashboard} backBtnName='Cancel' goNext={handlePaypalPayment} loading={processPaypalData?.loading} cardReview="PayPal" className="py-4" />
          
        </div>
      </Paper>
    )
}
