import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Paper } from '@material-ui/core';
import { useSelector, shallowEqual } from "react-redux";
import { WebCard } from './WebCard';import { church411Directory } from "../../../store/actions/index";
import PageLoader from "../../Loader/PageLoader";
import "./church411.scss"

export const Church411 = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  useEffect(() => {
    setIsLoading(true)
    church411Directory({ orgId: orgInfo.orgId }).then((result) => {
      const { responseData } = result;
      setData(responseData);
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }, []);

  if (isLoading) {
    return <PageLoader />
  }
  return (
    <div className="church-411-page">
      <div className="container-holder">
        <h6 className="mt-0 ml-5">{data.church411DirectoryLabel} - Directory Service</h6>
        <div
          className={`custom-card gutter-b church411Boxshadow`}
          // className="church-411-card"
        >
          {/* <div className="cards-header">
            <div className="cards-title">
              <p className="m-0">Who's Who - So that we may know you</p>
            </div> */}
          {/* </div> */}
          <div className="chruch411-grid">
            {data?.officersInformationList?.map((item, index) => {
              return (
                <Paper elevation={2} className="church411Background">
                  <WebCard item={item} key={index} />
                </Paper>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};