import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { resetPasswordOrPin, resetEndpointDataAction } from '../../store/actions';
import { repeatNumbers } from '../../utils/constants';
import 'react-phone-number-input/style.css';
import './form.css';
import { toast } from 'react-toastify';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import NotificationMesage from '../Common/NotificationMesage'
import {TextField} from '@material-ui/core'
import {Button} from 'react-bootstrap'

export const ResetPinForm = () => {
  const MAX_LENGTH = 4;
  const dispatch = useDispatch();
  const history = useHistory();
  const themeColor = useThemeColor();

  // Local State
  const [pin, setPin] = useState('');
  const [pinConfirmation, setPinConfirmation] = useState('');
  const [toggleShowPin, setToggleShowPin] = useState(false);
  const [pinError, setPinError] = useState({});
  const [pinConfirmationError, setPinConfirmationError] = useState({});

  // Global store data
  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const resetPasswordOrPinData = useSelector(state => state.resetPasswordOrPinData, shallowEqual)
  const token = useSelector(state => state.verifyForgotPasswordOrPinData.responseData.token, shallowEqual)
  const tokenType = useSelector(state => state.verifyForgotPasswordOrPinData.responseData.tokenType, shallowEqual)
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo.orgColors.primary : '' }

  const toggleShowPinFunc = () => {
    setToggleShowPin(!toggleShowPin);
  };

  const onPinChange = (e) => {
    const pinInput = e.target.value.replace(/\D/g, "");
    const letters = /^[A-Za-z]+$/;
    if(e.target.value.match(letters)) return setPinError({...pinError, msg: 'Pin must be a number', status: true})
    if(pinInput.length < MAX_LENGTH) setPinError({...pinError, msg: 'Pin must not be less than 4 numbers', status: true})
    if(pinInput.length >= MAX_LENGTH) setPinError({...pinError, msg: '', status: false});
    setPin(pinInput);
  };

  const onPinConfirmatioChange = (e) => {
    const pinInput = e.target.value.replace(/\D/g, "");
    const letters = /^[A-Za-z]+$/;
    if(e.target.value.match(letters)) return setPinConfirmationError({...pinError, msg: 'Pin must be a number', status: true})
    if(pinInput.length < MAX_LENGTH) setPinConfirmationError({...pinError, msg: 'Pin must not be less than 4 numbers', status: true})
    if(pinInput.length >= MAX_LENGTH) setPinConfirmationError({...pinError, msg: '', status: false});
    setPinConfirmation(pinInput);
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if(!pin) {
      return setPinError({...pinError, msg: 'Pin is required', status: true})
    }
    if(repeatNumbers.includes(pin)) return setPinError({...pinError, msg: 'Must not contain repeating numbers', status: true});
    // repeatNumbers.forEach(num => {
    //   if(pin === num) return setPinError({...pinError, msg: 'Must not contain repeating numbers', status: true});
    // })
    // if((pin === '0000') || (pin ==='1111')||(pin ==='2222')||(pin ==='3333')||(pin ==='4444')||(pin ==='5555')||(pin ==='6666')||(pin ==='7777')||(pin ==='8888')||(pin === '9999')) return setPinError({...pinError, msg: 'Must not contain repeating numbers', status: true})
    if(['1234', '4321'].includes(pin)) return setPinError({...pinError, msg: 'Pin cannot be 1234, or 4321', status: true})
    if(pin !== pinConfirmation) {
      setPinError({...pinError, msg: 'Pin and Confirm Pin must be the same', status: true})
      return setPinConfirmationError({...pinConfirmationError, msg: 'Pin and Confirm Pin must be the same', status: true})
    }
    if(pin & pinConfirmation && !pinError.status && !pinConfirmationError.status){
      const payload = {pin, token, tokenType, orgId: orgInfo.orgId};
      return dispatch(resetPasswordOrPin(payload));
    }
  }

  React.useEffect(() => {
    if (resetPasswordOrPinData.responseData && resetPasswordOrPinData.responseCode === '00') {
      toast.success(<NotificationMesage message={resetPasswordOrPinData.responseMessage} responseStatus={resetPasswordOrPinData.responseCode} />, {
        onClose : () => {
          dispatch(resetEndpointDataAction);
          history.push('/')
        }
      })
    }
    if (resetPasswordOrPinData && resetPasswordOrPinData.responseCode === '01') {
      toast.error(<NotificationMesage message={resetPasswordOrPinData.responseMessage} responseStatus={resetPasswordOrPinData.responseCode} />, {
        onClose: () => {
          dispatch(resetEndpointDataAction);
        }
      })
    }
  }, [resetPasswordOrPinData.responseCode])

  return (
    <>
      {/* {resetPasswordOrPinData && resetPasswordOrPinData.responseCode === '00' ?
        <Alert isSuccess showBackToLoginBtn={true} showCloseBtn={false} msg={resetPasswordOrPinData.responseMessage} /> : */}
        <>
          {/* {resetPasswordOrPinData.responseCode === '01' && <Alert isSuccess={false} msg={resetPasswordOrPinData.responseMessage} />}           */}
        <form className="form fv-plugins-bootstrap fv-plugins-framework mt-10">
        <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative password">
            <div className="position-relative">
              <TextField
                name="pin"
                label="Pin"
                type={toggleShowPin ? 'text' : 'password'}
                value={pin}
                maxLength={MAX_LENGTH}
                className={` ${pinError.status ? 'is-invalid' : pinError.status === false ? 'is-valid' :  ''}`}
                inputMode="numeric"
                onChange={onPinChange}
                fullWidth
                error={pinError.status}
                variant="outlined"
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
              }}
              />
              {/* <i className="las la-key email-icon key-icon position-absolute"></i> */}
              
            </div>
            <span className="position-absolute p-2" onClick={toggleShowPinFunc}>
              {toggleShowPin ? (
                <i className="far fa-eye"></i>
                ) : (
                  <i className="far fa-eye-slash"></i>
                  )}
            </span>
          </div>
          {pinError.status ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block errorText">{pinError.msg}</div>
            </div>
          ) : null}
        </div>

        <div className="fv-plugins-icon-container mb-8">
          <div className="position-relative password">
            <div className="position-relative">
              <TextField
                name="pinConfirmation"
                label="Confirm Pin"
                type={toggleShowPin ? 'text' : 'password'}
                value={pinConfirmation}
                maxLength={MAX_LENGTH}
                className={` ${pinConfirmationError.status ? 'is-invalid' : pinConfirmationError.status === false ? 'is-valid' :  ''}`}
                inputMode="numeric"
                onChange={onPinConfirmatioChange}
                fullWidth
                error={pinConfirmationError.status}
                variant="outlined"
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
              }}
              />
              {/* <i className="las la-key email-icon key-icon position-absolute"></i> */}
              
            </div>
            <span className="position-absolute p-2" onClick={toggleShowPinFunc}>
              {toggleShowPin ? (
                <i className="far fa-eye"></i>
                ) : (
                  <i className="far fa-eye-slash"></i>
                  )}
            </span>
          </div>
          {pinConfirmationError.status ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block errorText">{pinConfirmationError.msg}</div>
            </div>
          ) : null}
        </div>
        <div className="d-flex justify-content-between align-items-center my-5">
            <div className="d-flex align-items-center">
              <Link className="text-dark-50 text-hover-primary" to="/">
              <Button variant="secondary" className="btn px-7 py-4">Back to Login</Button>
                {/* <span><i className="las la-angle-left mr-2"></i></span><span className="text-primary">Back to Login</span> */}
                </Link>
            </div>
        <button
          id="kt_login_signin_submit"
          type="click"
          disabled={resetPasswordOrPinData && resetPasswordOrPinData.loading}
          style={themeColor.btnStyle}
          onClick={handleSubmit}
          className={`btn font-weight-bold px-7 py-4 text-white`}>
          <span>{resetPasswordOrPinData.loading ? 'Please wait...' : 'Reset Pin'}</span>
          {resetPasswordOrPinData && resetPasswordOrPinData.loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
            </div>
        {/* <div className="d-flex justify-content-center align-items-center">
          <Link className="text-dark-50 text-hover-primary" to="/"><span><i className="las la-angle-left mr-2"></i></span><span className="text-primary">Back to Login</span></Link>
        </div> */}
      </form>
      </>
      {/* } */}
    </>
  )
}