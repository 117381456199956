import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

export default function SmallTable({
  data,
  myrequest,
  handleView,
  backgroundColor,
  backgroundColorSecondary,
  isLoading,
  tableActionIconsNegative,
  tableActionIconsPositive,
  handleOpenDialog,
  handleImageView,
  type
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hiddenData, setHiddenData] = React.useState({});
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHiddenData = (item, e) => {
    setHiddenData(item);
    handleClick(e);
  };

  const openNames = ["","Picture", "Category", "Likes", " "];
  const hiddenNames = ["Date", "View", "Name", "Action"];

  const handle = (item, type, mode) => {
    handleClose();
    handleView(item, type, mode);
  };
  const handleDialog = (item, type, status) => {
    handleClose();
    handleOpenDialog(item, type, status);
  };
  return (
    <div className="table-responsive w-100">
      <TableContainer component={Paper}>
        <Table className="table table-stripe">
          <TableHead>
            {openNames.map((item) => {
              return (
                <TableCell className="fw-bold" key={item}>
                  {item}
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {myrequest && (
                    <TableCell>
                      <div className="symbol symbol-30 ml-2">
                        <img
                          src={item.creatorPicture}
                          alt="user"
                          className="rounded-circle"
                          onError={(e) => {
                            if (e.target.src !== item.creatorPicture)
                            e.target.src =
                            "https://dev.churchpad.com/xTextersImageFilesLoc/i/2/vi2__1606075062__yawxmoxnukq8__ddesign9.png";
                          }}
                          onClick={() => handleImageView(item)}
                          />
                      </div>
                    </TableCell>
                  )}
                  <TableCell>{item.categoryName}</TableCell>
                  <TableCell>{item.likeOrPrayedCounter}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleHiddenData(item, e)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: "40ch",
          },
        }}
      >
        <Table className="table table-stripe">
          <TableHead>
            {hiddenNames.map((item) => {
              return (
                <TableCell className="fw-bold" key={item}>
                  {item}
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {hiddenData && (
              <TableRow>
                <TableCell>{moment(hiddenData?.date).format("ddd MMM D, YYYY")}</TableCell>
                <TableCell>
                  <i
                    className="fa fa-search"
                    style={{ color: backgroundColor, cursor: "pointer" }}
                    onClick={() => handle(hiddenData, "view", 'all')}
                  ></i>
                </TableCell>
                <TableCell>{hiddenData?.creatorName}</TableCell>
                {myrequest && (
                  <TableCell>
                    {hiddenData.thisUserlikedOrPrayed === "0" ? (
                      <Tooltip
                        title="Click if you are praying for these person"
                        placement="top"
                        TransitionComponent={Zoom}
                      >
                        <i
                          className={
                            isLoading
                              ? "fas fa-spinner fa-spin"
                              : tableActionIconsNegative
                          }
                          style={{
                            color: backgroundColor,
                            cursor: "pointer",
                          }}
                          onClick={() => handleDialog(hiddenData, "confirm", type)}
                        ></i>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="I have prayed for these person"
                        placement="top"
                        TransitionComponent={Zoom}
                      >
                        <i
                          className={tableActionIconsPositive}
                          style={{ color: backgroundColorSecondary }}
                        ></i>
                      </Tooltip>
                    )}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Menu>
    </div>
  );
}
