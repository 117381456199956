import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { getForm, submitForm, submitFormWithoutToken } from "../../store/actions/followup";
import StepperComponent from "./Stepper";
import { makeStyles } from "@material-ui/core";
import {useHistory} from 'react-router-dom'
import PageLoader from '../../components/Loader/PageLoader'
import {useWindowDimensions} from '../../utils/customHooks/useWindowDimensions'
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import moment from "moment";

export default function FollowupHome({isDashboard=false}) {
  const getFollowUpByOrganizationData = useSelector(
    (state) => state.getFollowUpByOrganization
  );
  const submitFormLoading = useSelector(state => state.submitForm)
  const getFormData = useSelector((state) => state.getForm.responseData);
  const [pageNumber, setPageNumber] = useState(1)
  const themeColor = useThemeColor();
  // const [formInputData, setFormInputData] = useState([])
  // console.log(getFormData, "value");
  let history = useHistory()
  let dispatch = useDispatch();
  let isMobile = useWindowDimensions()
  // console.log(isMobile, 'mobile');
  // useEffect(() => {
  //   if (getFollowUpByOrganizationData?.responseData) {
  //     dispatch(
  //       getForm(getFollowUpByOrganizationData.responseData?.followUpFormId)
  //     );
  //   }
  // }, [getFollowUpByOrganizationData]);
  let formInputData = [];
  const classes = useStyes();
  const callbackFunction = () => {
   !isDashboard ? history.push('/follow_up_success') : history.push('/dashboard')
  }
  // console.log(isDashboard, 'dashboard');
  const handleSubmit = (data, index) => {
    // console.log(data, 'index');
    // console.log(index, 'index----');
    // setPageNumber(pageNumber + 1)
    if (data !== undefined) {
      const formData = getFormData.pages[index]?.elements?.map((item) => {
        const payload = {
          answer: item.name === 'Date of Birth' ? moment(data[item.name]).format('MMM D, YYYY') : data[item.name] ? data[item.name] : null,
          pageId: item.pageId,
          formElementId: item.id,
        };
        // console.log(payload, 'load');
        // return payload
        formInputData.push(payload);
      });
      // setFormInputData((prev) => [...prev, formData])
      // setFormInputData([...formInputData, formData])
      // console.log(formData, 'another');
      if (getFormData.pages.length - 1 === index) {
        const payload = {
          answers: formInputData,
        };
        // console.log(payload, "payload");
        if (isDashboard) {
          return dispatch(
            submitForm(payload, getFormData.legacyOrganizationId, getFormData.id, callbackFunction)
          );
        } 
        return dispatch(submitFormWithoutToken(payload, getFormData.legacyOrganizationId, getFormData.id, callbackFunction))
      }
    }
  };
// console.log(submitFormLoading, 'laoding');
// console.log(formInputData, 'data input');
  // if (submitFormLoading?.loading) {
  //     return <PageLoader />
  // }
  return (
    <div className="px-3 pt-7 pb-4 login-form login-signin">
      {/* <div className="py-4">
        <Carousel
          centerMode={true}
          centerSlidePercentage={21}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
        >
          {getFollowUpByOrganizationData?.responseData?.followUpIntros?.map(
            (item, index) =>
              item?.type === "IMAGE" ? (
                <img
                  src={item?.url}
                  alt={item?.description}
                  style={{ height: "250px", width: "250px" }}
                />
              ) : item?.type === "VIDEO" ? (
                <video width="250px" height="250px" controls>
                  <source src={item?.url} />
                </video>
              ) : null
          )}
        </Carousel>
      </div> */}
      <div className={!isDashboard && classes.stepperContainer}>
        {/* <DynamicForms data={getFormData?.pages[0]?.elements} /> */}
        <div style={{ width: isDashboard && isMobile?.width >= 720? '80%' : isMobile?.width <= 720 && isDashboard || isMobile?.width <= 720 && !isDashboard ? '100%' : '100%' }}>
          
            <StepperComponent data={getFormData} handleSubmit={handleSubmit} isDashboard={isDashboard}/>
          {/* )} */}
        </div>
      </div>
      {/* <div className='d-flex justify-content-center py-4'>
                <ButtonContainer backgroundColor={'blue'}>First Timer</ButtonContainer>
            </div> */}
    </div>
  );
}

const useStyes = makeStyles(() => ({
  stepperContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));
