import React from 'react'
import './Alert.scss'

export default function NotificationMesage({ message, responseStatus }) {
    return (
        <>
            <div className='d-flex align-items-center'>
                <div className="alert-icon">
                    <i className={responseStatus === '00' ? 'fas fa-check-circle px-3 $white text-light' : 'fas fa-exclamation-circle px-3 $white text-light'}></i>
                </div>
                <h4 className="alert-heading">{responseStatus === '00' ? 'Success!' : 'Error'}</h4>
            </div>
            <p className="mt-1 mb-2 px-2 ml-2 text_color">{message}</p>
        </>
    )
}
