import React from 'react'
import CelebrationMain from '../../components/Celebration/CelebrationMain'
import { Home } from './Home'

export default function Celebration() {
  return (
   <Home>
    <div
        style={{
          // border: 'solid 5px red',
          marginBottom: '14rem',
          //   height: '100vh',
          position: 'relative',
          top: '10rem',
        }}
      >
        <CelebrationMain/>
      </div>
   </Home>
  )
}
