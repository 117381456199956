import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Flutterwave,
  giveWithCard,
  giveWithCheck,
  paystack,
  preferredPaymentExistingCard,
  preferredPaymentNewCard,
  processPaypal,
  quickGiveProcessingEngines,
  StripePayment,
} from "../../store/actions";
import CreditCardInput from "react-credit-card-input";
import "../Section.scss";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";
import { ButtonContainer } from "../Common/Button";
import PageLoader from "../Loader/PageLoader";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SuccessGiveComponent from "../Forms/GiveForm/SuccessGiveComponent";
import PaymentToggle from "../PaymentToggle";

export default function QGive() {
  const { handleSubmit, errors, control, watch, setValue, reset } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const themeColor = useThemeColor();
  const backgroundColor = themeColor.color;

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const quickGiveData = useSelector(
    (state) => state.quickGiveProcessingEngines.responseData,
    shallowEqual
  );
  const giveWithCardDataLoading = useSelector(
    (state) => state.giveWithCardData,
    shallowEqual
  );

  const [giveTypeData, setGiveTypeData] = useState(null);
  const [cardDetails, setCardDetails] = useState({});
  const [giveOptions, setGiveOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState({
    isSuccess: false,
    show: false,
  });

  const watchContributionType = watch("contributionOption");
  const watchAmount = watch("giveAmount");
  useEffect(() => {
    dispatch(
      quickGiveProcessingEngines({
        orgId: orgInfo.orgId,
      })
    );
  }, []);

  useEffect(() => {
    if (watchContributionType) {
      const data = quickGiveData?.returnedRecords?.find(
        (el) => el.label === watchContributionType
      );
      setGiveTypeData(data);
    }
  }, [watchContributionType]);

  useEffect(() => {
    if (quickGiveData?.returnedRecords?.length > 0) {
      const data = quickGiveData?.returnedRecords?.map((item) => {
        return item.label;
      });
      const dataGive = quickGiveData?.returnedRecords?.find(
        (el) => el.label === data[0]
      );
      setGiveTypeData(dataGive);
      setGiveOption(data);
      setValue("contributionOption", data[0]);
    }
  }, [quickGiveData]);

  const handleCardCVCChange = ({ target: { value, name } }) => {
    setCardDetails({ ...cardDetails, ["cvc"]: value });
  };

  const handleCardExpiryChange = ({ target: { value, name } }) => {
    setCardDetails({ ...cardDetails, ["expiry"]: value });
  };

  const handleCardNumberChange = (e) => {
    setCardDetails({ ...cardDetails, ["number"]: e.target.value });
  };

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      memo,
      giveAmountSpecify,
      giveAmount,
      contributionType,
      mobile,
      email,
    } = data;
    if (
      giveTypeData.processEndPointName === "give-with-card" ||
      giveTypeData.processEndPointName === "give-with-check"
    ) {
      // if (cardDetails.number && cardDetails.expiry && cardDetails.cvc) {
      // setIsLoading(true)
      if (giveTypeData.processEndPointName === "give-with-card") {
        if (!cardDetails.number) {
          return toast.error("Please Input Card Number");
        }
        if (!cardDetails.expiry) {
          return toast.error("Please Input Card Expiring Date");
        }
        if (!cardDetails.cvc) {
          return toast.error("Please Input Card CVC");
        }
        const trimCardNum = cardDetails.number?.replace(/ /g, "");
        const payload = {
          cardNumber: window.btoa(trimCardNum),
          memo,
          firstName,
          lastName,
          amount: giveAmount === "Specify" ? giveAmountSpecify : giveAmount,
          recurring: "once",
          contributionType,
          mobile,
          email,
          cvv: cardDetails.cvc,
          expirationMonth: cardDetails.expiry.substring(0, 2),
          expirationYear: `20${cardDetails.expiry.substring(5)}`,
          orgId: orgInfo?.orgId,
        };
        return dispatch(giveWithCard(payload, handleCallback, handleCallback2));
      }
    }
    if (giveTypeData.processEndPointName === "give-with-check") {
      const payload = {
        memo,
        firstName,
        lastName,
        amount: giveAmount === "Specify" ? giveAmountSpecify : giveAmount,
        recurring: "once",
        contributionType,
        mobile,
        email,
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        orgId: orgInfo?.orgId,
      };
      return dispatch(giveWithCheck(payload, handleCallback, handleCallback2));
      // }
    }
    const payloadNextGen = {
      cancelUrl: `${window.location.origin}/payment_status/qgive/failure`,
      currencyCode: giveTypeData.currency,
      email,
      firstName,
      lastName,
      listOfContributions: [
        {
          amount: giveAmount === "Specify" ? giveAmountSpecify : giveAmount,
          contributionType,
          memo,
        },
      ],
      mobile,
      orgId: orgInfo.orgId,
      successUrl: `${window.location.origin}/payment_status/qgive/success`,
      totalAmount: giveAmount === "Specify" ? giveAmountSpecify : giveAmount,
    };
    if (
      giveTypeData.processEndPointType === "nextGen" &&
      giveTypeData.type === "PAYPAL"
    ) {
      setIsLoading(true);
      return dispatch(processPaypal(payloadNextGen, stopLoading));
    }
    if (
      giveTypeData.processEndPointType === "nextGen" &&
      giveTypeData.type === "STRIPE"
    ) {
      setIsLoading(true);
      return dispatch(StripePayment(payloadNextGen, stopLoading));
    }
    if (
      giveTypeData.processEndPointType === "nextGen" &&
      giveTypeData.type === "FLUTTERWAVE"
    ) {
      setIsLoading(true);
      return dispatch(Flutterwave(payloadNextGen, stopLoading));
    }
    if (
      giveTypeData.processEndPointType === "nextGen" &&
      giveTypeData.type === "PAYSTACK"
    ) {
      setIsLoading(true);
      return dispatch(paystack(payloadNextGen, stopLoading));
    }
    if (
      giveTypeData.processEndPointType === "nextGen" &&
      giveTypeData.type === "PPS"
    ) {
      console.log(payloadNextGen, "payload");
      const payloadPps = {
        cardFirstName: firstName,
        cardLastName: lastName,
        cardNumber: cardDetails.number?.replace(/ /g, ""),
        currencyCode: giveTypeData.currency,
        cvv: cardDetails.cvc,
        email,
        expirationMonth: cardDetails.expiry.substring(0, 2),
        expirationYear: cardDetails.expiry.substring(5),
        listOfContributions: [
          {
            amount: giveAmount === "Specify" ? giveAmountSpecify : giveAmount,
            contributionType,
            memo,
          },
        ],
        mobile,
        orgId: orgInfo.orgId,
        saveCard: "0",
        totalAmount: giveAmount === "Specify" ? giveAmountSpecify : giveAmount,
        userId: null,
      };

      setIsLoading(true);
      return dispatch(
        preferredPaymentNewCard(payloadPps, handleCallback, handleCallback2)
      );
    }
  };

  const handleCallback = () => {
    setIsLoading(false);
    reset();
    setCardDetails({});
    setShowSuccess({ ...showSuccess, isSuccess: true, show: true });
  };
  const handleCallback2 = () => {
    setIsLoading(false);
    reset();
    setCardDetails({});
    setShowSuccess({ ...showSuccess, isSuccess: false, show: true });
  };

  const handleCancel = () => {
    history.push("/");
  };

  const handleNext = () => {
    setShowSuccess({ ...showSuccess, isSuccess: false, show: false });
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  if (giveWithCardDataLoading?.loading || isLoading) {
    return <PageLoader />;
  }

  return showSuccess?.show ? (
    <SuccessGiveComponent isQGive handleNext={handleNext} />
  ) : (
    <>
      <div className="payment-switch">
        <PaymentToggle />
      </div>
      <div className="login-form login-signin activeForm-wrapper">
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          {quickGiveData?.returnedRecords?.length > 1 && (
            <Grid container>
              <Controller
                as={
                  <TextField
                    name="contributionOption"
                    variant="outlined"
                    fullWidth
                    label="Contribution Option"
                    select
                  >
                    {giveOptions.map((item, index) => (
                      <MenuItem
                        value={item}
                        key={index}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="contributionOption"
                control={control}
                rules={{ required: "Required" }}
              />
            </Grid>
          )}

          <div className="py-2">
            <Grid container>
              <Controller
                as={
                  <TextField
                    name="contributionType"
                    variant="outlined"
                    fullWidth
                    label="Contribution Type"
                    select
                  >
                    {orgInfo?.contributionTypes.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="contributionType"
                control={control}
                rules={{ required: "Required" }}
              />
            </Grid>
          </div>

          <div className="py-2">
            <Grid container>
              <Controller
                as={
                  <TextField
                    name="giveAmount"
                    variant="outlined"
                    fullWidth
                    label={`Give Amount (${giveTypeData?.currency})`}
                    select
                  >
                    {giveTypeData?.giveAmountList.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="giveAmount"
                control={control}
                rules={{ required: "Required" }}
              />
            </Grid>
          </div>
          {watchAmount === "Specify" && (
            <div className="py-2">
              <Grid container>
                <Controller
                  as={
                    <TextField
                      name="giveAmountSpecify"
                      variant="outlined"
                      fullWidth
                      error={errors?.giveAmountSpecify}
                      helperText={errors?.giveAmountSpecify?.message}
                      label={`Give Amount (${giveTypeData?.currency})`}
                      type="number"
                      inputProps={{
                        max: Number(giveTypeData.maximumAmount),
                        min: Number(giveTypeData.minimumAmount),
                        step: "0.01",
                      }}
                    />
                  }
                  name="giveAmountSpecify"
                  control={control}
                  rules={{
                    required: true,
                    max: Number(giveTypeData.maximumAmount),
                    min: Number(giveTypeData.minimumAmount),
                  }}
                />
              </Grid>
            </div>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                as={
                  <TextField
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    error={errors?.firstName}
                    helperText={errors?.firstName?.message}
                    label="First Name"
                    inputProps={{
                      maxLength: 64,
                      minLength: 2,
                    }}
                  />
                }
                name="firstName"
                control={control}
                rules={{
                  required: true,
                  minLength: 2,
                  maxLength: 64,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                as={
                  <TextField
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    error={errors?.lastName}
                    helperText={errors?.lastName?.message}
                    label="Last Name"
                    inputProps={{
                      maxLength: 64,
                      minLength: 2,
                    }}
                  />
                }
                name="lastName"
                control={control}
                rules={{
                  required: true,
                  minLength: 2,
                  maxLength: 64,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                as={
                  <TextField
                    name="mobile"
                    variant="outlined"
                    fullWidth
                    error={errors?.mobile}
                    helperText={errors?.mobile?.message}
                    label="Phone"
                    type="tel"
                  />
                }
                name="mobile"
                control={control}
                rules={{
                  required: false,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                as={
                  <TextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    error={errors?.email}
                    helperText={errors?.email?.message}
                    label="Email"
                    type="email"
                  />
                }
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
          </Grid>

          {(giveTypeData?.type === "CARD" || giveTypeData?.type === "PPS") && (
            <div className="py-2 w-full mt-4">
              <Grid container>
                <CreditCardInput
                  // cardImageClassName='full-width'
                  containerClassName="full-width"
                  cardNumberInputProps={{
                    value: cardDetails.number || "",
                    onChange: handleCardNumberChange,
                    required: true,
                  }}
                  cardExpiryInputProps={{
                    value: cardDetails.expiry || "",
                    onChange: handleCardExpiryChange,
                    required: true,
                  }}
                  cardCVCInputProps={{
                    value: cardDetails.cvc || "",
                    onChange: handleCardCVCChange,
                    required: true,
                  }}
                  fieldClassName="input"
                />
              </Grid>
            </div>
          )}

          {giveTypeData?.type === "CHECK" && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    as={
                      <TextField
                        label="Account Number"
                        name="accountNumber"
                        variant="outlined"
                        type="number"
                        fullWidth
                        error={errors?.accountNumber}
                        helperText={errors?.accountNumber?.message}
                        inputProps={{ pattern: "/^[1-9]d*$/g" }}
                      />
                    }
                    name="accountNumber"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    as={
                      <TextField
                        label="Routing Number"
                        name="routingNumber"
                        variant="outlined"
                        type="number"
                        fullWidth
                        error={errors?.routingNumber}
                        helperText={errors?.routingNumber?.message}
                      />
                    }
                    name="routingNumber"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <div className="py-2">
            <Grid container>
              <Controller
                as={
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="memo"
                    label="Memo"
                    multiline
                    minRows={1}
                  />
                }
                name="memo"
                control={control}
                rules={{ required: false }}
              />
            </Grid>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
            }
            <ButtonContainer
              type="submit"
              backgroundColor={backgroundColor}
              // className="col-sm-5 mr-5 py-4"
            >
              {"Give"}
            </ButtonContainer>
          </div>
        </form>
      </div>
    </>
  );
}
