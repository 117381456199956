import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import { useForm, Controller } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";
import NotificationMesage from "../../Common/NotificationMesage";
import {ButtonContainer} from '../../Common/Button'
import styled from 'styled-components'

import { updateUserRecord, getUserProfile } from "../../../store/actions/";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

const SpanText = styled.span`
font-size: 1.5rem;
@media only screen and (max-width: 550px) {
  font-size: 1rem;
}
`;

export const Career = () => {
  const classes = useStyles();
  const themeColor = useThemeColor();
  const userData = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [jobTitleError, setJobTitleError] = useState("");
  const [employmentStatusError, setEmploymentStatusError] = useState("");

  const initialValues = {
    occupation: "",
    jobTitle: "",
    employmentStatus: "",
  };

  const { handleSubmit, errors, control, watch, register, getValues } = useForm(
    {
      mode: "onBlur",
    }
  );

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const orgInfoList = useSelector(
    (state) => state.orgInfoList.responseData,
    shallowEqual
  );
  const updateUserCarrerData = useSelector(
    (state) => state.updateUserData,
    shallowEqual
  );
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const primaryBgColor = themeColor.btnStyle;
  const backgroundColor = themeColor.color;

  const handleCallback = () => {
    dispatch(getUserProfile({'orgId': orgInfo.orgId, 'userId': userProfileData?.userId}))
  }

  const onSubmit = (data) => {
    console.log(data);
    const { occupation, jobTitle, employmentStatus } = data;
    const payload = {
      occupation,
      jobTitle,
      employmentStatus,
      userId: userProfileData.userId,
      orgId: orgInfo.orgId,
    };
    return dispatch(updateUserRecord(payload, handleCallback));
  };

  return (
    <Paper className="w-100 page-wrapper my-8 p-6">
      <div>
        <SpanText className="mr-2">
          My Career/Occupation/Employment Profile
        </SpanText>
        <span className="text-muted"> What I do</span>
      </div>
      <hr />

      {userProfileData && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
        >
          <div className="col-md-6 m-auto">
            <div className="fv-plugins-icon-container mb-8">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-select-occupation"
                      select
                      label="Occupation"
                      name="occupation"
                      // onChange={formik.handleChange}
                      // className={classes.textField}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      fullWidth
                      variant="outlined"
                      defaultValue={userProfileData.userOccupation || ""}
                    >
                      <MenuItem value="">Please select One</MenuItem>
                      {orgInfoList &&
                        orgInfoList.occupationList.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                  }
                  name="occupation"
                  control={control}
                  defaultValue={userProfileData.userOccupation || ""}
                />
              </div>
            </div>

            <div className="fv-plugins-icon-container mb-8">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-address1"
                      label="Job Title/Description"
                      placeholder="Job Title"
                      name="jobTitle"
                      // className={classes.textField}
                      // margin="normal"
                      fullWidth
                      variant="outlined"
                      defaultValue={userProfileData?.userJobTitle || ""}
                    />
                  }
                  name="jobTitle"
                  control={control}
                  defaultValue={userProfileData?.userJobTitle || ""}
                />
              </div>
              {/* {formik.touched.jobTitle && formik.errors.jobTitle ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block ml-2">{formik.errors.jobTitle}</div>
            </div>
          ) : null} */}
            </div>

            {/* Employement Status */}
            <div className="fv-plugins-icon-container mb-8">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-select-staus"
                      select
                      label="Employment Status"
                      name="employmentStatus"
                      // onChange={formik.handleChange}
                      // className={classes.textField}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      fullWidth
                      variant="outlined"
                      defaultValue={userProfileData.userEmploymentStatus || ""}
                    >
                      <MenuItem value="">Please select One</MenuItem>
                      {orgInfoList &&
                        orgInfoList.employmentStatusList.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                  }
                  name="employmentStatus"
                  control={control}
                  defaultValue={userProfileData.userEmploymentStatus || ""}
                />
              </div>
            </div>
            <hr className="py-4" />
            <div className="col-sm-12 p-0 w-100 d-flex justify-content-between">
              <ButtonContainer
                type="submit"
                disabled={updateUserCarrerData.loading}
                className="py-4 col-sm-5"
                // style={primaryBgColor}
                backgroundColor={backgroundColor}
              >
                <span>
                  {updateUserCarrerData.loading ? "please wait..." : "Update"}
                </span>
                {updateUserCarrerData.loading && (
                  <span className="spinner spinner-white ml-2"></span>
                )}
              </ButtonContainer>
              <Link component={Button} to="/my_info" elevation={0} className="px-5 py-4 col-sm-5" variant="contained">
                Cancel
              </Link>
            </div>
          </div>
        </form>
      )}
    </Paper>
  );
};
