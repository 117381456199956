import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import {ModalComponent as AddaChildModal} from '../../Common/Modal/index'
import {ResizeableModalWithoutBtn} from "../../Common/ResizeableModal";
import { ChildrenInfo as UserChildren} from './ChildrenInfo'
import MenuItem from '@material-ui/core/MenuItem'
import { addAchild, getUserProfile  } from '../../../store/actions'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import { toast } from 'react-toastify';
import NotificationMesage from '../../Common/NotificationMesage';
import Spouse from './Spouse'
import Cropper from "../Cropper";
import { dataURLtoFile } from "../../../utils/constants";
import { useForm, Controller } from "react-hook-form";
import {ButtonContainer} from '../../Common/Button';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';

import "./ChildrenInfo.scss";
import { getUserFamilyDataMportal } from '../../../store/actions/userDashboardActions/spouse'

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export const Family = () => {
  const { handleSubmit, errors, control } = useForm({
    mode: "onBlur",
  });
  const classes = useStyles();
  const themeColor = useThemeColor();
  const userData = useSelector((state) => state.getUserProfileData)
  const [show, setShow] = useState(false);
  const [editData, setEditData] = React.useState({});
  const [image, setImage] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false)
  let history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const orgInfoList = useSelector(
    (state) => state.orgInfoList.responseData,
    shallowEqual
  );
  const addAChildData = useSelector(
    (state) => state.addChildData,
    shallowEqual
  );
  const userDetails = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const userFamilyDetails = useSelector(
    (state) => state.getUserFamilyDataMportal.responseData,
    shallowEqual
  );



  const primaryBgColor = themeColor.btnStyle;
  const backgroundColor = themeColor.color;

  const initialValues = {
    childFirstName: "",
    childMiddleName: "",
    childLastName: userData?.responseData?.userLastName,
    childBirthday: "",
    childGender: "",
  };

  const [birthDateErrorGeneral, setBirthDateErrorGeneral] = useState(false);

  const handleClose = () => {
    setShow(false);
    setEditData({});
  };
  const redirectToFamily = () => {
    handleClose();
    dispatch(getUserFamilyDataMportal({'orgId': orgInfo.orgId, 'userId': user?.responseData?.userId}))
  };

  const onSubmit = (data) => {
    const {
      childBirthday,
      childFirstName,
      childGender,
      childLastName,
      childMiddleName,
      favoriteColor,
      foodAllergies,
      schoolName
    } = data;
    console.log(data, 'data');
    let imageData;
    imageData = !image ? null : dataURLtoFile(image, "my.png")
    if (Object.keys(editData).length !== 0) {
      const formData = new FormData();
      formData.append("childFirstName", childFirstName);
      formData.append("childMiddleName", childMiddleName);
      formData.append("childBirthday", childBirthday);
      formData.append("childLastName", childLastName);
      formData.append("childGender", childGender);
      formData.append("action", "update ");
      formData.append("userId", user?.responseData?.userId);
      formData.append("orgId", orgInfo.orgId);
      formData.append('childUserId', editData.childUserId)
      formData.append('favoriteColor', favoriteColor)
      formData.append('foodAllergies', foodAllergies)
      formData.append('schoolName', schoolName)
      // formData.append("pictureType", "childProfilePicture");
      // formData.append("mediaFile", imageData);
      return dispatch(addAchild(formData, redirectToFamily));
    } else {
      const formData = new FormData();
      formData.append("childFirstName", childFirstName);
      formData.append("childMiddleName", childMiddleName);
      formData.append("childBirthday", childBirthday);
      formData.append("childLastName", childLastName);
      formData.append("childGender", childGender);
      formData.append("action", "create");
      formData.append("userId", user?.responseData?.userId);
      formData.append("orgId", orgInfo.orgId);
      formData.append("pictureType", "childProfilePicture");
      formData.append("mediaFile", imageData);
      formData.append('favoriteColor', favoriteColor)
      formData.append('foodAllergies', foodAllergies)
      formData.append('schoolName', schoolName)
      return dispatch(addAchild(formData, redirectToFamily));
    }
  };

  const handleShow = () => setShow(true);

  const handleEditData = (data) => {
    setEditData(data);
    handleShow();
  };
  // useEffect(() => {
  //   if (addAChildData?.responseCode === "00") {
  //     toast.success(
  //       <NotificationMesage
  //         message={addAChildData?.responseMessage}
  //         responseStatus={addAChildData?.responseCode}
  //       />
  //     );
  //   }

  //   if (addAChildData?.responseCode === "01") {
  //     toast.error(
  //       <NotificationMesage
  //         message={addAChildData?.responseMessage}
  //         responseStatus={addAChildData?.responseCode}
  //       />
  //     );
  //   }
  // }, [addAChildData?.responseCode]);

  const handleCropImage = (data) => {
    if (Object.keys(editData).length !== 0) {
      const formData = new FormData();
      formData.append("action", "update ");
      formData.append("pictureType", "childProfilePicture");
      formData.append("userId", user?.responseData?.userId);
      formData.append("orgId", orgInfo.orgId);
      formData.append('childUserId', editData.childUserId)
      formData.append("mediaFile", dataURLtoFile(data, "my.png"));
      return dispatch(addAchild(formData, redirectToFamily));
    }
    setImage(data);
  };

  useEffect(() => {
    dispatch(getUserFamilyDataMportal({
      orgId: orgInfo.orgId,
      userId: user?.responseData?.userId,
    }))
  }, [])

  return (
    <Paper>
      <div className="w-100 page-wrapper my-8 p-6">
        <div className="d-flex px-5 flex-column flex-md-row justify-content-between header-container">
          <div>
            <span className="font-size-h3 mr-2">My Family Information </span>
            <span className="text-muted">Family is forever!</span>
          </div>
          {/* <div className="col-md-6 p-0 small-screen-holder">
            <Spouse />
          </div > */}
          <ButtonContainer
            // className="btn text-white"
            onClick={handleShow}
            // style={primaryBgColor}
            backgroundColor={backgroundColor}
          >
            <span className="fas fa-child mr-2"></span>Add a child
          </ButtonContainer>
        </div>
        <hr />
        {/* <div className=" hide-large-screen"> */}
          <Spouse />
        {/* </div > */}
        <hr className='hide-large-screen'/>
        <div className="row w-100 mobile-row">
          {userFamilyDetails && userFamilyDetails?.userChildrenInfo?.length < 1 && (
            <p className="font-weight-bolder font-size-h3">No record found!</p>
          )}
          {userFamilyDetails?.userChildrenInfo.map((item, index) => {
              return (
                <div className="col-xl-6">
                  <UserChildren
                    item={item}
                    key={index}
                    handleEditData={handleEditData}
                    redirectToFamily={redirectToFamily}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <ResizeableModalWithoutBtn
        className="ml-5"
        modalState={show}
        resetModalState={handleClose}
        maxWidth="xs"
        fullWidth={true}
        title={
          Object.keys(editData).length !== 0
            ? "Edit Child Details"
            : "Add a Child"
        }
        backgroundColor={backgroundColor}
        buttonName={Object.keys(editData).length !== 0
          ? "Edit"
          : "Add"}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="col-md-10 m-auto">
            <Cropper
              userId={userData?.responseData?.userId}
              orgId={orgInfo.orgId}
              defaultImage={editData.childPicture}
              family
              handleCropImage={handleCropImage}
            />
            {/* {console.log(editData)}  */}
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-first-name"
                      label="First Name"
                      name="childFirstName"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.childFirstName || ""}
                      error={errors.childFirstName}
                      inputProps={{maxLength: 64, minLength: 2}}
                      // {...formik.getFieldProps("childFirstName")}
                    />
                  }
                  name="childFirstName"
                  control={control}
                  defaultValue={editData.childFirstName || ""}
                  rules={{
                    required: {
                      value: true,
                      message: "First Name is Required",
                    },
                    maxLength: {
                      value: 64,
                      message: "Exceeded the max. character",
                    },
                    minLength: {
                      value: 2,
                      message: "Min of 2 characters",
                    },
                  }}
                />
              </div>
              {errors.childFirstName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childFirstName.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-middle-name"
                      label="Middle Name"
                      name="childMiddleName"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.childMiddleName || ""}
                      inputProps={{maxLength: 64, minLength: 2}}
                      // {...formik.getFieldProps("childMiddleName")}
                    />
                  }
                  name="childMiddleName"
                  control={control}
                  defaultValue={editData.childMiddleName || ""}
                  rules={{
                  }}
                />
              </div>
              {/* {console.log(errors.childMiddleName)} */}
              {errors.childMiddleName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childMiddleName.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-last-name"
                      label="Last Name"
                      name="childLastName"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      inputProps={{maxLength: 64, minLength: 2}}
                      defaultValue={
                        editData.childLastName || initialValues.childLastName
                      }
                      error={errors.childLastName}
                      // {...formik.getFieldProps("childLastName")}
                    />
                  }
                  name="childLastName"
                  control={control}
                  defaultValue={
                    editData.childLastName || initialValues.childLastName
                  }
                  rules={{
                    required: {
                      value: Object.keys(editData).length !== 0 ? true : false,
                      message: 'Last Name is Required'
                    },
                  }}
                />
              </div>
              {errors.childLastName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childLastName.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-gender"
                      label="Gender"
                      name="childGender"
                      select
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.childGender || ""}
                      error={errors.childGender}
                      // {...formik.getFieldProps("childGender")}
                    >
                      <MenuItem value="">Please select one</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                  }
                  name="childGender"
                  control={control}
                  defaultValue={editData.childGender || ""}
                  rules={{
                    required: {
                      value: Object.keys(editData).length !== 0 ? true : false,
                      message: 'Gender is Required'
                    }, 
                  }}
                />
              </div>
              {errors.childGender ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childGender.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-gender"
                      label="Food Allergies"
                      name="foodAllergies"
                      select
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.foodAllergies || ""}
                      // error={errors.childGender}
                      // {...formik.getFieldProps("childGender")}
                    >
                      <MenuItem value="">Please select one</MenuItem>
                      {orgInfoList?.foodAllergiesList?.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>{item}</MenuItem>
                        )
                      })}
                      {/* <MenuItem value="Female">Female</MenuItem> */}
                    </TextField>
                  }
                  name="foodAllergies"
                  control={control}
                  defaultValue={editData.foodAllergies || ""}
                  // rules={{
                  //   required: {
                  //     value: Object.keys(editData).length !== 0 ? true : false,
                  //     message: 'Gender is Required'
                  //   }, 
                  // }}
                />
              </div>
              {/* {errors.childGender ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childGender.message}
                  </div>
                </div>
              ) : null} */}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-gender"
                      label="Child Favorite Color"
                      name="favoriteColor"
                      select
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.favoriteColor || ""}
                      // error={errors.childGender}
                      // {...formik.getFieldProps("childGender")}
                    >
                      <MenuItem value="">Please select one</MenuItem>
                      {orgInfoList?.favoriteColorsList?.map((item, index) => {
                        return (
                          <MenuItem value={item.color}>{item.color}</MenuItem>
                        )
                      })}
                      {/* <MenuItem value="Female">Female</MenuItem> */}
                    </TextField>
                  }
                  name="favoriteColor"
                  control={control}
                  defaultValue={editData.favoriteColor || ""}
                  // rules={{
                  //   required: {
                  //     value: Object.keys(editData).length !== 0 ? true : false,
                  //     message: 'Gender is Required'
                  //   }, 
                  // }}
                />
              </div>
              {/* {errors.childGender ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childGender.message}
                  </div>
                </div>
              ) : null} */}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-birthdate"
                      label="Birthday"
                      name="childBirthday"
                      type="date"
                      format="yyyy-mm-dd"
                      className={classes.textField}
                      // value={editData.childBirthday || ""}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={editData.childBirthday || ""}
                      variant="outlined"
                      error={errors.childBirthday}
                      // {...formik.getFieldProps("childBirthday")}
                    />
                  }
                  name="childBirthday"
                  control={control}
                  defaultValue={editData.childBirthday || ""}
                  rules={{
                    required: {
                      value: Object.keys(editData).length !== 0 ? true : false,
                      message: 'Birthday is Required'
                    },
                  }}
                />
                {errors.childBirthday && (
                  <span className="text-danger font-size-h6">
                    Date is Required
                  </span>
                )}
              </div>
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-last-name"
                      label="School Name"
                      name="schoolName"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      // inputProps={{maxLength: 64, minLength: 2}}
                      defaultValue={
                        editData.schoolName || ''
                      }
                      // error={errors.childLastName}
                      // {...formik.getFieldProps("childLastName")}
                    />
                  }
                  name="schoolName"
                  control={control}
                  defaultValue={
                    editData.schoolName || ''
                  }
                  // rules={{
                  //   required: {
                  //     value: Object.keys(editData).length !== 0 ? true : false,
                  //     message: 'Last Name is Required'
                  //   },
                  // }}
                />
              </div>
              {/* {errors.childLastName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childLastName.message}
                  </div>
                </div>
              ) : null} */}
            </div>
            <div className="d-flex align-content-center ml-3 mt-2 justify-content-between">
                <ButtonContainer
                  type="submit"
                  disabled={addAChildData.loading}
                  backgroundColor={backgroundColor}    
                >
                  {addAChildData.loading && <span className="child-font-family">Please wait...</span>}
                  {!addAChildData.loading &&<span className="fa fa-plus-circle">{Object.keys(editData).length !== 0 ? <span className='pl-2 child-font-family'>Edit</span> : <span className='pl-2 child-font-family'>Add</span>}</span>}
                </ButtonContainer>
                <ButtonContainer
                  type='button'
                  disabled={addAChildData.loading}
                  backgroundColor={backgroundColor}
                  onClick={handleClose}
                >
                 Close
                </ButtonContainer>
            </div>
          </div>
        </form>
      </ResizeableModalWithoutBtn>

      {/* <AddaChildModal
        show={show}
        onHide={handleClose}
        size="md"
        title={
          Object.keys(editData).length !== 0
            ? "Edit Child Details"
            : "Add a Child"
        }
        backgroundColor={backgroundColor}
        buttonName={Object.keys(editData).length !== 0
          ? "Edit"
          : "Add"}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="col-md-10 m-auto">
            <Cropper
              userId={userData.responseData.userId}
              orgId={orgInfo.orgId}
              defaultImage={editData.childPicture}
              family
              handleCropImage={handleCropImage}
            />
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-first-name"
                      label="First Name"
                      name="childFirstName"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.childFirstName || ""}
                      error={errors.childFirstName}
                      inputProps={{maxLength: 64, minLength: 2}}
                    />
                  }
                  name="childFirstName"
                  control={control}
                  defaultValue={editData.childFirstName || ""}
                  rules={{
                    required: {
                      value: true,
                      message: "First Name is Required",
                    },
                    maxLength: {
                      value: 64,
                      message: "Exceeded the max. character",
                    },
                    minLength: {
                      value: 2,
                      message: "Min of 2 characters",
                    },
                  }}
                />
              </div>
              {errors.childFirstName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childFirstName.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-middle-name"
                      label="Middle Name"
                      name="childMiddleName"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.childMiddleName || ""}
                      inputProps={{maxLength: 64, minLength: 2}}
                    />
                  }
                  name="childMiddleName"
                  control={control}
                  defaultValue={editData.childMiddleName || ""}
                  rules={{
                  }}
                />
              </div>
              {errors.childMiddleName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childMiddleName.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-last-name"
                      label="Last Name"
                      name="childLastName"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      inputProps={{maxLength: 64, minLength: 2}}
                      defaultValue={
                        editData.childLastName || initialValues.childLastName
                      }
                      error={errors.childLastName}
                    />
                  }
                  name="childLastName"
                  control={control}
                  defaultValue={
                    editData.childLastName || initialValues.childLastName
                  }
                  rules={{
                    required: {
                      value: Object.keys(editData).length !== 0 ? true : false,
                      message: 'Last Name is Required'
                    },
                  }}
                />
              </div>
              {errors.childLastName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childLastName.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-child-gender"
                      label="Gender"
                      name="childGender"
                      select
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      defaultValue={editData.childGender || "mm/dd/yy"}
                      error={errors.childGender}
                    >
                      <MenuItem value="">Please select one</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                  }
                  name="childGender"
                  control={control}
                  defaultValue={editData.childGender || ""}
                  rules={{
                    required: {
                      value: Object.keys(editData).length !== 0 ? true : false,
                      message: 'Gender is Required'
                    }, 
                  }}
                />
              </div>
              {errors.childGender ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">
                    {errors.childGender.message}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="fv-plugins-icon-container mb-2">
              <div className="position-relative">
                <Controller
                  as={
                    <TextField
                      id="outlined-with-birthdate"
                      label="Birthday"
                      name="childBirthday"
                      type="date"
                      format="yyyy-mm-dd"
                      className={classes.textField}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={editData.childBirthday || ""}
                      variant="outlined"
                      error={errors.childBirthday}
                    />
                  }
                  name="childBirthday"
                  control={control}
                  defaultValue={editData.childBirthday || ""}
                  rules={{
                    required: {
                      value: Object.keys(editData).length !== 0 ? true : false,
                      message: 'Birthday is Required'
                    },
                  }}
                />
                {errors.childBirthday && (
                  <span className="text-danger font-size-h6">
                    Date is Required
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex align-content-center ml-3 mt-2 justify-content-between">
                <ButtonContainer
                  type="submit"
                  disabled={addAChildData.loading}
                  backgroundColor={backgroundColor}    
                >
                  {addAChildData.loading && <span className="child-font-family">Please wait...</span>}
                  {!addAChildData.loading &&<span className="fa fa-plus-circle">{Object.keys(editData).length !== 0 ? <span className='pl-2 child-font-family'>Edit</span> : <span className='pl-2 child-font-family'>Add</span>}</span>}
                </ButtonContainer>
                <ButtonContainer
                  type='button'
                  disabled={addAChildData.loading}
                  backgroundColor={backgroundColor}
                  onClick={handleClose}
                >
                 Close
                </ButtonContainer>
            </div>
          </div>
        </form>
      </AddaChildModal> */}
    </Paper>
  );
};
