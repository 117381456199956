import React, {useState} from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import {
  Grow,
  Icon,
  IconButton,
  TextField,
  Button,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { followUpListings, guestActivityLog } from "../../../store/actions/userDashboardActions/followup";
import { getUserDetails } from "../../../utils/authHelpers";
import PageLoader from "../../Loader/PageLoader";
import CreateFollowActivityLogForm from "./CreateFollowActivityLogForm";
import { useHistory } from "react-router-dom";
import GetFollowUpFormDetailsModal from "./GetFollowUpFormDetailsModal";
// import './followup.css'

export default function FollowUp() {
  const dispatch = useDispatch();
  const history = useHistory()
  const getFollowUpByOrganizationIdData = useSelector(
    (state) => state.getFollowUpByOrganizationIdData.responseData
  );

  const getFollowUpByOrganizationIdLoading = useSelector(
    (state) => state.getFollowUpByOrganizationIdData
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const [isLog, setIsLog] = useState({
    show: false,
    item: {}
  });
  const [openModalB, setOpenModalB] = useState({
    show: false,
    item: {},
  });

  const user = getUserDetails()

  const handleActivityLog = (data) => {
    setIsLog({...isLog, show: true, item: data})
  };
  const handleActivityLogClose = () => {
    setIsLog({...isLog, show: false, item: {}})
  };
  const viewLogs = (data) => {
    history.push({
      pathname: `/follow-up/view-log`,
      state: data,
    });
    dispatch(
      guestActivityLog({
        orgId: orgInfo?.orgId,
        adminUserId: user?.userId,
        recordId: data.recordId,
        guestId: data.guestId,
      })
    );
  };

  const handleGetGuestDetails = (item) => {
    setOpenModalB({ ...openModalB, show: true, item });
  };


  const columns = [
    {
      name: "", // field name in the row object
      label: "", // column title that will be shown in table
      options: {
        filter: true,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return <div className="">{dataIndex + 1}</div>;
        },
      },
    },
    {
      name: "guestFullName", // field name in the row object
      label: "Guest Full Name", // column title that will be shown in table
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let followUp =
            getFollowUpByOrganizationIdData?.returnedRecords[dataIndex];
          // console.log(followUp, 'assi');
          return (
            <div className="d-flex text-center">
              <p>{followUp?.guestFullName}</p>{" "}
              <Tooltip placement="top" title="View Submitted Form">
                  <span
                    className="print-hide pl-3"
                    style={{ textAlign: "center", textAlign: "center" }}
                  >
                    {/* <IconButton> */}
                      <Icon onClick={() => handleGetGuestDetails(followUp)}>
                        list_alt
                      </Icon>
                    {/* </IconButton> */}
                  </span>
                </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "phone", // field name in the row object
      label: "Phone", // column title that will be shown in table
      options: {
        filter: true,
      },
    },
    {
      name: "email", // field name in the row object
      label: "Email", // column title that will be shown in table
      options: {
        filter: true,
      },
    },

    {
      name: "assignedDate", // field name in the row object
      label: "Assigned Date", // column title that will be shown in table
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let followUp =
            getFollowUpByOrganizationIdData?.returnedRecords[dataIndex];
          return (
            <div className="flex items-center">
              {moment(followUp?.assignedDate).format("ddd MMM D, YYYY, h:mm A")}
            </div>
          );
        },
      },
    },
    {
      name: "", // field name in the row object
      label: "", // column title that will be shown in table
      options: {
        filter: true,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          let activityLog =
            getFollowUpByOrganizationIdData?.returnedRecords[dataIndex];
          return (
            <div className="flex items-center print-hide">
              <div className="flex items-center">
                <Tooltip title="Log Activities" placement="top">
                  <IconButton 
                    onClick={() => handleActivityLog(activityLog)}
                    >
                    <Icon>add_task </Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Activities log" placement="top">
                  <IconButton 
                  onClick={() => viewLogs(activityLog)}
                  >
                    <Icon>event</Icon>
                  </IconButton>
                </Tooltip>
                {/* <Button onClick={() => handleActivityLog(activityLog)} variant="contained" color="primary">Create Log</Button> */}
                {/* <Button onClick={()=>handleGetGuestDetails(activityLog)} variant="contained" color="primary" className="ml-2">Submitted Form</Button> */}
              </div>
            </div>
          );
        },
      },
    },
  ];

  const handleCloseModalB = () => {
    setOpenModalB({ ...openModalB, show: false, item: {} });
  };


  React.useEffect(() => {
    const user = getUserDetails();
    dispatch(
      followUpListings({
        orgId: orgInfo.orgId,
        adminUserId: user.userId,
        type: "assignedToMe",
      })
    );
  }, []);

  if (getFollowUpByOrganizationIdLoading?.loading) {
    return <PageLoader />;
  }
  return <div>
          {getFollowUpByOrganizationIdData && (
        <div className="listing">
          <MUIDataTable
            title={`Total: ${getFollowUpByOrganizationIdData.total}`}
            data={getFollowUpByOrganizationIdData?.returnedRecords}
            columns={columns}
            options={{
              filterType: "textField",
              responsive: "simple",
              // search: false, // set search option
              filter: false, // set data filter option
              download: false, // set download option
              downloadOptions: {
                filename: `Assigned_followup${moment().format(
                  "MMM_DD_YYYY_h_mma"
                )}.csv`,
              },
              print: false, // set print option
              pagination: true, //set pagination option
              viewColumns: false, // set column option
              elevation: 0,
              rowsPerPage: 100,
              rowsPerPageOptions: [100, 200, 500, 1000],
              selectableRows: "none",
              selectableRowsOnClick: false,
              search: false,
            //   customToolbar: () => <CustomPrintIcon tablePrint={tablePrint} />,
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => (
                <div>
                  <Tooltip title="Re-Assign" cursor="pointer" className="mr-6">
                    <Icon 
                    // onClick={() => handleMultipleAssign(selectedRows)}
                    >
                      assignment_ind
                    </Icon>
                  </Tooltip>
                </div>
              ),
              customSearchRender: (
                searchText,
                handleSearch,
                hideSearch,
                options
              ) => {
                return (
                  <Grow appear in={true} timeout={300}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                    //   onChange={({ target: { value } }) => handleSearch(value)}
                      InputProps={{
                        style: { paddingRight: 0 },
                        startAdornment: (
                          <Icon className="mr-2" fontSize="small">
                            search
                          </Icon>
                        ),
                        endAdornment: (
                          <IconButton 
                        //   onClick={hideSearch}
                          >
                            <Icon fontSize="small">clear</Icon>
                          </IconButton>
                        ),
                      }}
                    />
                  </Grow>
                );
              },
            }}
          />
        </div>
      )}
    {isLog.show && <CreateFollowActivityLogForm 
        open={isLog.show}
        selectedValue={isLog.item}
        onClose={handleActivityLogClose}
    />}

    {openModalB?.show && <GetFollowUpFormDetailsModal
        open={openModalB?.show}
        onClose={handleCloseModalB}
        userId={openModalB?.item}
      />} 
  </div>;
}
