import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Icon } from '@material-ui/core';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CustomButton = (props) => {
  const classes = useStyles();
  const {
    className,
    style,
    disabled,
    loading,
    label,
    onClick,
    color,
    variant = 'contained',
    type = 'button',
    icon = false,
    iconText,
    ...others
  } = props;
  return (
    <Button
      variant={variant}
      color={color}
      type={type}
      className={className}
      style={style}
      disabled={loading || disabled}
      onClick={onClick}
      {...others}
    >
      {icon && (
        <Icon
          fontSize="small"
          style={{ fontWeight: 'bolder', fontSize: '1.2rem' }}
        >
          {iconText}
        </Icon>
      )}

      {!icon && label}
      {icon && <span className="pl-2">{label}</span>}
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
};

export default CustomButton;
