import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { actionTypes } from "../../actionTypes";
import { checkNodeEnv, checkNodeEnvNextGen } from "../../../utils/authHelpers";
import { defaultAction, apiUrl, asyncMethod } from "../../../utils/constants";
import {
  axiosPayload,
  startLoadingAction,
  resetEndpointDataAction,
  stopLoadingAction,
  axiosMultipartPayload,
} from "../index";
import NotificationMesage from "../../../components/Common/NotificationMesage";
import AxiosInstance from "../../../utils/AxiosConfig";

// export const startLoadingAction = {type: actionTypes.START_LOADING};
export const startLoadingCheckinAction = {
  type: actionTypes.GET_CHILD_CHECKED_IN_TOKEN_START,
};
// export const stopLoadingAction = {type: actionTypes.STOP_LOADING};
export const stopLoadingCheckinLoading = {
  type: actionTypes.GET_CHILD_CHECKED_IN_TOKEN_STOP,
};
const startMediaVault = { type: actionTypes.MEDIA_VAULT_LISTING_START };
const stopMediaVault = { type: actionTypes.MEDIA_VAULT_LISTING_STOP };
const authAction = (type, payload) => ({ type, payload });

export const groupUsers = ({ status, groupd }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_USERS_GROUP_SUCCESS,
    });
    const url = checkNodeEnvNextGen(
      apiUrl.groupUsers("https://apix.churchpad.com/nextgen", groupd, status),
      apiUrl.groupUsers("https://apps1.churchpad.com/nextgen", groupd, status)
    );
    try {
      const response = await AxiosInstance({
        method: asyncMethod.GET,
        url,
        // params: {
        //   page: 0,
        //   size: 10,
        // },
      });
      const data = await response.data;
      if (data) {
        return dispatch(
          defaultAction(actionTypes.GET_USERS_GROUP_FAILURE, data) // BAD PRACTICE AND CONFUSING
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.GET_USERS_GROUP_FAILURE, data)
        );
      }
    } catch (error) {
      console.log(error, "error");
      dispatch({
        type: actionTypes.GET_USERS_GROUP_FAILURE,
      });
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const groupUsersPending = ({ status, orgId, type }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_USERS_GROUP_PENDING_START,
    });
    const url = checkNodeEnvNextGen(
      apiUrl.groupUsersPending(
        "https://apix.churchpad.com/nextgen",
        status,
        type
      ),
      apiUrl.groupUsersPending(
        "https://apps1.churchpad.com/nextgen",
        status,
        type
      )
    );
    try {
      const response = await AxiosInstance({
        method: asyncMethod.GET,
        url,
        // params: {
        //   page: 0,
        //   size: 10,
        // },
      });
      const data = await response.data;
      if (data) {
        return dispatch(
          defaultAction(actionTypes.GET_USERS_GROUP_PENDING_STOP, data) // BAD PRACTICE AND CONFUSING
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.GET_USERS_GROUP_PENDING_STOP, data)
        );
      }
    } catch (error) {
      console.log(error, "error");
      dispatch({
        type: actionTypes.GET_USERS_GROUP_PENDING_STOP,
      });
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const deleteUsersFromDepartmentGroup = (payload, callback) => async (
  dispatch
) => {
  dispatch({
    type: actionTypes.DEPARTMENT_USERS_START,
  });
  const url = checkNodeEnv(
    apiUrl.deleteUsersFromDepartmentGroup("live-api"),
    apiUrl.deleteUsersFromDepartmentGroup("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.DEPARTMENT_USERS_STOP,
      });
      callback && callback();
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(
        defaultAction(actionTypes.DELETE_DEPARTMENT_GROUP_USERS_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.DEPARTMENT_USERS_STOP,
      });
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.DELETE_DEPARTMENT_GROUP_USERS_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addRemoveUserAdminAccess = (payload, callback) => async (
  dispatch
) => {
  const url = checkNodeEnv(
    apiUrl.addRemoveUserAdminAccess("live-api"),
    apiUrl.addRemoveUserAdminAccess("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback && callback();
      return dispatch(
        defaultAction(actionTypes.ADD_REMOVE_USER_ADMIN_ACCESS_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.CREATE_DEPARTMENT_GROUP_STOP,
      });
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.ADD_REMOVE_USER_ADMIN_ACCESS_FAIL, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const childrenModuleSpecificInfo = (payload) => async (dispatch) => {
  // dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.childrenModuleSpecificInfo("live-api"),
    apiUrl.childrenModuleSpecificInfo("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // dispatch(stopLoadingAction);
      return dispatch(defaultAction(actionTypes.CHILDREN_MODULE_SUCCESS, data));
    } else {
      // dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.CHILDREN_MODULE_FAILURE, data));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const mediaVaultListing = (payload) => async (dispatch) => {
  dispatch(startMediaVault);
  const url = checkNodeEnv(
    apiUrl.mediaVaultListing("live-api"),
    apiUrl.mediaVaultListing("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopMediaVault);
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.MEDIA_VAULT_LISTING_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingCheckinLoading);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.MEDIA_VAULT_LISTING_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const sendCommunicationChildren = (payload, callback) => async (
  dispatch
) => {
  dispatch(startLoadingCheckinAction);
  const url = checkNodeEnv(
    apiUrl.sendCommunicationChildren("live-api"),
    apiUrl.sendCommunicationChildren("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingCheckinLoading);
      callback();
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingCheckinLoading);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const sendCommunicationToSpecialGroupAnalyze = (
  payload,
  callback
) => async (dispatch) => {
  // dispatch(startLoadingCheckinAction);
  const url = checkNodeEnv(
    apiUrl.sendCommunicationToSpecialGroupAnalyze("live-api"),
    apiUrl.sendCommunicationToSpecialGroupAnalyze("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // dispatch(stopLoadingCheckinLoading);
      callback();
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_SUCCESS, data)
      );
    } else {
      // dispatch(stopLoadingCheckinLoading);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const getOrgMessage = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.getOrgMessage("live-api"),
    apiUrl.getOrgMessage("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      cb && cb();
      return dispatch(authAction(actionTypes.ORG_MESSAGE, data));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const sendCommunicationToSpecialGroupProcess = (
  payload,
  callback
) => async (dispatch) => {
  // dispatch(startLoadingCheckinAction);
  const url = checkNodeEnv(
    apiUrl.sendCommunicationToSpecialGroupProcess("live-api"),
    apiUrl.sendCommunicationToSpecialGroupProcess("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === "00") {
      // dispatch(stopLoadingCheckinLoading);
      callback();
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(
          actionTypes.SEND_CHILDREN_COMMUNICATION_PROCESS_SUCCESS,
          data
        )
      );
    } else {
      // dispatch(stopLoadingCheckinLoading);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(
          actionTypes.SEND_CHILDREN_COMMUNICATION_PROCESS_FAILURE,
          data
        )
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const doSearchUserToJoinDept = (payload, cb) => async (dispatch) => {
  // dispatch({type: actionTypes.CLEAR_PEOPLE});
  dispatch({
    type: actionTypes.SEARCH_USER_TO_JOIN_START,
  });
  const url = checkNodeEnv(
    apiUrl.doSearchUserToJoinDept("live-api"),
    apiUrl.doSearchUserToJoinDept("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // toast.success(data.responseMessage);
      cb && cb(data?.responseData);
      return dispatch(
        defaultAction(actionTypes.SEARCH_USER_TO_JOIN_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.SEARCH_USER_TO_JOIN_STOP,
      });
      // return toast.error(data.responseMessage);
    }
  } catch (error) {
    // console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch({
      type: actionTypes.SEARCH_USER_TO_JOIN_STOP,
    });
  }
};

export const positionsListing = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.positionsListing("live-api"),
    apiUrl.positionsListing("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(defaultAction(actionTypes.POSITIONS_LIST_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.POSITIONS_LIST_FAIL, data));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addUsersToDepartment = (payload, callback) => async (dispatch) => {
  dispatch({
    type: actionTypes.CREATE_DEPARTMENT_GROUP_START,
  });
  const url = checkNodeEnv(
    apiUrl.addUsersToDepartment("live-api"),
    apiUrl.addUsersToDepartment("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.CREATE_DEPARTMENT_GROUP_STOP,
      });
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(
        defaultAction(actionTypes.ADD_USER_TO_DEPARTMENT_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.CREATE_DEPARTMENT_GROUP_STOP,
      });
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.ADD_USER_TO_DEPARTMENT_FAIL, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const renameDepartmentGroup =
  (payload, callback) => async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_DEPARTMENT_GROUP_START,
    });
    const url = checkNodeEnv(
      apiUrl.renameDepartmentGroup("live-api"),
      apiUrl.renameDepartmentGroup("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch({
          type: actionTypes.CREATE_DEPARTMENT_GROUP_STOP,
        });
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.RENAME_DEPARTMENT_GROUP_SUCCESS, data)
        );
      } else {
        dispatch({
          type: actionTypes.CREATE_DEPARTMENT_GROUP_STOP,
        });
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.RENAME_DEPARTMENT_GROUP_FAILURE, data)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

  export const editInterestServiceGroup = (payload, cb) => {
    return async (dispatch) => {
      dispatch({
        type: actionTypes.CREATE_GROUPS_LOADING_START,
      });
      const url = checkNodeEnv(
        apiUrl.editInterestServiceGroup("live-api"),
        apiUrl.editInterestServiceGroup("test-api")
      );
      try {
        const response = await axios(axiosPayload(url, payload));
        const data = await response.data;
        if (data.responseCode === "00") {
          toast.success(
            <NotificationMesage
              responseStatus={data.responseCode}
              message={data.responseMessage}
            />
          );
          cb && cb();
          return dispatch(defaultAction(actionTypes.CREATE_GROUPS_SUCCESS, data));
        } else {
          toast.error(
            <NotificationMesage
              responseStatus={data.responseCode}
              message={data.responseMessage}
            />
          );
          return dispatch(defaultAction(actionTypes.CREATE_GROUPS_FAIL, data));
        }
      } catch (error) {
        console.log(error, "error");
        dispatch({
          type: actionTypes.CREATE_GROUPS_STOP,
        });
        toast.warn("Network Error. Please check your connectivity and try again");
      }
    };
  };

  // export const updateGroup = (orgId, payload, cb) => {
  //   return async (dispatch) => {
  //     dispatch({
  //       type: actionTypes.UPDATE_GROUPS_LOADING_START,
  //     });
  //     const url = apiUrl.updateGroup(orgId);
  //     try {
  //       const response = await axios({
  //         method: asyncMethod.PUT,
  //         url,
  //         data: payload,
  //         // params: {
  //         //   page: 0,
  //         //   size: 10,
  //         // },
  //       });
  //       const data = await response.data;
  //       // console.log(response, 'res');
  //       if (data) {
  //         toast.success(
  //           <NotificationMesage
  //             responseStatus={data.responseCode}
  //             message={data.responseMessage}
  //           />
  //         );
  //         cb && cb();
  //         return dispatch(defaultAction(actionTypes.UPDATE_GROUPS_SUCCESS, data));
  //       } else {
  //         toast.error(
  //           <NotificationMesage
  //             responseStatus={data.responseCode}
  //             message={data.responseMessage}
  //           />
  //         );
  //         return dispatch(defaultAction(actionTypes.UPDATE_GROUPS_FAIL, data));
  //       }
  //     } catch (error) {
  //       console.log(error, "error");
  //       dispatch({
  //         type: actionTypes.UPDATE_GROUPS_STOP,
  //       });
  //       toast.warn("Network Error. Please check your connectivity and try again");
  //     }
  //   };
  // };

  export const doSearchUser = (payload, cb) => async (dispatch) => {
    // dispatch({type: actionTypes.CLEAR_PEOPLE});
    // dispatch(startLoadingAction);
    dispatch({
      type: actionTypes.SEARCH_USER_LOADING_START,
    });
    const url = checkNodeEnv(
      apiUrl.doSearchUser("live-api"),
      apiUrl.doSearchUser("test-api")
    );
  
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        // toast.success(data.responseMessage);
        dispatch(stopLoadingAction);
        cb && cb(data);
        return dispatch(authAction(actionTypes.SEARCHED_USERS, data));
      } else {
        // dispatch(stopLoadingAction);
        return dispatch({
          type: actionTypes.SEARCH_USER_LOADING_STOP,
        });
        // return toast.error(data.responseMessage);
      }
    } catch (error) {
      // dispatch(stopLoadingAction);
      dispatch({
        type: actionTypes.SEARCH_USER_LOADING_STOP,
      });
      // console.log(error);
      // toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

  export const changeGroupBanner = (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.changeGroupBanner("live-api"),
      apiUrl.changeGroupBanner("test-api")
    );
    dispatch({
      type: actionTypes.UPDATE_USER_PHONE_NUMBER_START,
    });
    try {
      const response = await axios(axiosMultipartPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        dispatch({
          type: actionTypes.UPDATE_USER_PHONE_NUMBER_STOP,
        });
        callback && callback();
        return dispatch(defaultAction(actionTypes.CHANGE_GROUP_BANNER_SUCCESS));
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        dispatch({
          type: actionTypes.UPDATE_USER_PHONE_NUMBER_STOP,
        });
        return dispatch(defaultAction(actionTypes.CHANGE_GROUP_BANNER_FAILURE));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.UPDATE_USER_PHONE_NUMBER_STOP,
      });
    }
  };

  export const approveUser = (orgId, groupId, payload, cb) => {
    return async (dispatch) => {
      dispatch({
        type: actionTypes.APPROVE_USER_LOADING_START,
      });
      // const url = apiUrl.approveUser(orgId, groupId);
      const url = checkNodeEnvNextGen(
        apiUrl.approveUser(
          "https://apix.churchpad.com/nextgen",
          orgId,
          groupId
        ),
        apiUrl.approveUser(
          "https://apps1.churchpad.com/nextgen",
          orgId,
          groupId
        )
      );
      try {
        const response = await AxiosInstance({
          method: asyncMethod.PUT,
          data: payload,
          url,
          // params: {
          //   page: 0,
          //   size: 10,
          // },
        });
        const data = await response.data;
        // console.log(response, 'res');
        if (data) {
          cb && cb();
          toast.success(
            <NotificationMesage
              responseStatus={data.responseCode}
              message={data.responseMessage}
            />
          );
          return dispatch(defaultAction(actionTypes.APPROVE_USER_SUCCESS, data));
        } else {
          toast.error(
            <NotificationMesage
              responseStatus={data.responseCode}
              message={data.responseMessage}
            />
          );
          return dispatch(defaultAction(actionTypes.APPROVE_USER_FAIL, data));
        }
      } catch (error) {
        dispatch({
          type: actionTypes.APPROVE_USER_STOP,
        });
        // toast.warn("Network Error. Please check your connectivity and try again");
        toast.error(
          <NotificationMesage
            responseStatus={error.response.data.responseCode}
            message={error.response.data.responseMessage}
          />
        );
      }
    };
  };
  
