import { Typography } from '@material-ui/core';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import moment from 'moment';
import React from 'react'
import "./sermon.scss";

export default function UpcomingEvent({data, setCurrentlyPlaying}) {
  return (
    <>
        {Object.entries(data)?.map(([key, item], ind) => <EventItem pair={key} item={item} key={ind} setCurrentlyPlaying={setCurrentlyPlaying} />)}
    </>
    
  )
}

const EventItem = ({pair, item, setCurrentlyPlaying}) => {
    const theme = useThemeColor()
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    return (
        <div>
            <Typography variant='h5' className='upcoming-event-title-text' style={{ color: theme.textColor }}>{item[0]?.formattedStartDate}</Typography>
            <div className={theme.isDarkMode ? 'upcoming-event-time-container-dark' : 'upcoming-event-time-container'} >
                    {Object.values(item)?.map((opt, index) => 
                <div className='upcoming-event-time-sub-container'key={index}>
                    <div>
                    <Typography variant='h6' className='upcoming-event-time-text'>{opt?.formattedStartTime}</Typography>
                    <Typography variant='h6' className='upcoming-event-time-desc'>{opt?.name}</Typography>
                    </div>
                    {opt?.playerId && <button onClick={() => setCurrentlyPlaying(opt.playerId)} className='styles__Button-sc-1ewtaim-2 iSoNZD'>Live</button>}
                    {/* <div>
                    <p className='upcoming-event-time-text'>{moment(item?.startDate).format('hh : mm a')}</p>
                    <p className='upcoming-event-time-desc'>{item?.name}</p>
                    </div> */}
                </div>)}
            </div>
        </div>
    )
}
