import moment from 'moment'
import DeviceDetector from 'device-detector-js'

export const isEmpty = (obj) => {
  for(var i in obj) return false; 
  return true;
};

export const getMonthYear = () => {
  const d = new Date();
  const month = new Array(12);
  month[0] = "January";
  month[1] = "February";
  month[2] = "March";
  month[3] = "April";
  month[4] = "May";
  month[5] = "June";
  month[6] = "July";
  month[7] = "August";
  month[8] = "September";
  month[9] = "October";
  month[10] = "November";
  month[11] = "December";
  // console.log(month[d.getMonth()], d.getFullYear())
  return `${month[d.getMonth()]}, ${d.getFullYear()}`;
}

export const getFormattedDate = (date) => {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  return month + '/' + day + '/' + year;
}

export function amountFormatter(n, x) {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

export const getMaskedNumber = (cardNumber) => {
  const lastFourDigit = cardNumber?.substr(cardNumber?.length - 4, cardNumber?.length);
  const maskedNum = cardNumber?.substr(0, cardNumber?.length - 4);
  const maskedNumber = maskedNum?.replace(/\d/g, "*") + lastFourDigit;
  return maskedNumber;
}

export const expiryDateCheck = Number(moment().format('M')) - 1;

/**
 * This function get the value of a query string from a url
 * 
 * @param {string} queryStringKey
 * @returns string
 */
export const getQueryString = (queryStringKey) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const value = urlParams.get(queryStringKey);
  return value;
}

export const browserDetails = () => {
  let ip = localStorage.getItem("ipAddress");
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const payload = {
    userDeviceType: `${device.os.name} ${device.os.version}`,
    userBrowserType: device.client.name,
    userIpAddress: JSON.parse(ip),
    userRefererUrl: "",
  };
  return payload;
};

export function capitalizeFirstLetter(str) {
  // Convert the entire string to lowercase
  const lowercasedStr = str.toLowerCase();

  // Capitalize the first letter
  const capitalizedStr = lowercasedStr.charAt(0).toUpperCase() + lowercasedStr.slice(1);

  return capitalizedStr;
}

export function maskString(inputString) {
  // Generate a new string with asterisks of the same length
  const maskedString = '*'.repeat(inputString?.length);
  return maskedString;
}