import { Button, Paper } from '@material-ui/core';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { getFormQuestionAndAnswer, getOrganizationForms, submitFormAnswer, submitFormAnswerUpdate } from '../../../store/actions/userDashboardActions/forms';
import { useThemeColor } from '../../../utils/customHooks/useThemeColor';
import { ButtonContainer } from '../../Common/Button';
import { ResizeableModalWithoutBtn } from '../../Common/ResizeableModal';
import DynamicForms from '../../Forms/Dynamic';
import './forms.css'

export default function ViewForm() {
    const location = useLocation()
    const {search} = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {state} = location
    const themeColor = useThemeColor();
    const backgroundColor = themeColor.color
    const [isLoading, setLoading] = useState(false)
    const [viewMedia, setViewMedia] = useState({
        show: false,
        item: {}
    })

    const userData = useSelector((state) => state.getUserProfileData)
    const getFormQuestionAndAnswerData = useSelector(
        (state) => state.getFormQuestionAndAnswer.data,
        shallowEqual
    );

    const orgInfo = useSelector(
        (state) => state.orgInfo.responseData,
        shallowEqual
    );

    console.log(getFormQuestionAndAnswerData, 'getFormQuestionAndAnswerData');

    const searchFc = new URLSearchParams(search)
    const isEdit = searchFc.get('editable')



    const [displayForm, setDisplayForm] = React.useState({})
    const [sliderData, setSliderData] = useState([])
    const handleChange = ({ target: { name, value } }, indexOfForm) => {
      const temp = [...displayForm];
      if (temp[indexOfForm].type === 'date') {
        value = moment(value).format('YYYY-MM-DD')
      }
      temp[indexOfForm].answer = value;
      setDisplayForm(temp);
    };
    const handleChangeCheckbox = ({ target: { name, checked } }, indexOfForm) => {
      const temp = [...displayForm];
      const payload = {
        name,
        checked
      }
      let checkbox = [...temp[indexOfForm].answer]
      const objIndex= checkbox.findIndex((el) => el.name === name)
      if (objIndex === -1) {
          checkbox = [...temp[indexOfForm].answer, {...payload}]
      } else {
        checkbox[objIndex].checked = checked
      }
      const filteredData = checkbox.filter((el) => el.checked);

      temp[indexOfForm].answer = filteredData
      setDisplayForm(temp);
    };
    const handleChangeDate = (data, indexOfForm) => {
      const temp = [...displayForm];
      temp[indexOfForm].answer = data;
      setDisplayForm(temp);
    };

    console.log(state);

    React.useEffect(() => {
        if (getFormQuestionAndAnswerData === undefined) {
            dispatch(getFormQuestionAndAnswer(
                orgInfo.orgId,
                state.id,
                () => {}
            ))
        }
        if (getFormQuestionAndAnswerData) {
            setDisplayForm(getFormQuestionAndAnswerData.content.map((item) => typeof item === 'string' ? JSON.parse(item) : item))
            // setDisplayForm(state)
            let bannerData = new Set()
            bannerData.add(getFormQuestionAndAnswerData?.banner)
            if (getFormQuestionAndAnswerData?.banner) {
                // setSliderData([...sliderData, state?.banner1Url])
            }
            // if (state?.banner2Url) {
            //     bannerData.add(state?.banner2Url)
            // }

            setSliderData([...bannerData])
            // console.log(state, 'state data');
            // setSliderData([state?.banner1Url, state?.banner2Url])
        }
        if (isEdit === 'true' && getFormQuestionAndAnswerData) {
            // merge questions and answers
            const dataWithAnswer = state.schemaDefinition?.map((item, ind) => {
                console.log(Object.keys(getFormQuestionAndAnswerData.answers[ind]), 'yesse');
                if (item.question === Object.keys(getFormQuestionAndAnswerData.answers[ind])[0]) {
                    item.answer = Object.values(getFormQuestionAndAnswerData.answers[ind])[0]
                }
            })
        }
    }, [state, getFormQuestionAndAnswerData])

    const handleSubmitCallback = () => {
        setLoading(false)
        dispatch(getOrganizationForms(orgInfo.orgId))
        history.push('/user/forms')
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = displayForm?.map((item) => {
            return {
                question: item.question,
                answer: item.answer
            } 
        })
        const payload = {
            content: data,
        }
        console.log(payload, 'response');
        // if (isEdit === 'true') {
        //     return dispatch(submitFormAnswerUpdate(state.id, payload, handleSubmitCallback, () => setLoading(false)))
        // }
        return dispatch(submitFormAnswer(state.id, payload, handleSubmitCallback, () => setLoading(false)))
    }


    const determineUrlType = (data) => {
        const fileType = data?.split('.').pop()
        // console.log(data);

        if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'PNG' || fileType === 'JPG' || fileType === 'JPEG') {
            return 'image'
        }
        return 'mp4'
    }

    const handleOpenModal = (item) => {
        setViewMedia({...viewMedia, show: true, item})
    }
    const handleClose = () => {
        setViewMedia({...viewMedia, show: false, item: {}})
    }

    const handleBack = () => {
        history.push('/user/forms')
    }

    console.log(displayForm, 'display');

  return (
    <Paper className="card card-custom gutter-b px-2">  

        <div className={sliderData?.length <= 1 ? 'form_img_container_height' : 'form_img_container'}>
            {sliderData?.map((item, index) => (
                determineUrlType(item) === 'image' ? <img src={item} key={index} alt="" srcset=""  style={{height: '360px', width: '100%', objectFit: "contain"}} onClick={() => handleOpenModal(item)} /> :
                <video className='form_img' onClick={() => handleOpenModal(item)}>
                <source src={item} type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            ))}
        </div>
        <div className='form_words_body'>
            <div>
                <h5 className='form_header'>{state?.name}</h5>
                <p>{getFormQuestionAndAnswerData?.description}</p>

            </div>
        </div>

        <div className='form_container'>
            <form className='form_container_sub' onSubmit={handleSubmit}>
                {displayForm?.length > 0 && displayForm?.map((item, index) => <DynamicForms {...item} index={index} handleChange={handleChange} handleChangeDate={handleChangeDate} handleChangeCheckbox={handleChangeCheckbox} />)}

                <div className='form_btn'>
                    <Button color='primary' onClick={handleBack}>Back</Button>
                    <ButtonContainer type='submit' disabled={isLoading} backgroundColor={backgroundColor}>{isLoading ? 'Loading...' : 'Submit'}</ButtonContainer>
                </div>
            </form>
        </div>
        {viewMedia?.show && <ResizeableModalWithoutBtn
            className="ml-5"
            modalState={viewMedia?.show}
            // title={viewForm?.item?.formName}
            maxWidth="md"
            fullWidth={true}
            resetModalState={handleClose}
            backgroundColor={backgroundColor}
            backgroundSecondaryColor={backgroundColor}
        >
            {determineUrlType(viewMedia?.item) === 'image' ? <img src={viewMedia.item} alt="" srcset="" className='form_img_modal px-2' /> : 
            <video className='form_img_modal' controls>
            <source src={viewMedia?.item} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
            }
        </ResizeableModalWithoutBtn>}
    </Paper>
  )
}
