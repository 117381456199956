import React from "react";
import { Button } from "react-bootstrap";
import { useThemeColor } from "../../utils/customHooks/useThemeColor";

export default function SuccessGiveComponent({
  className,
  successMsg,
}) {
  const themeColor = useThemeColor();

  return (
    <div className="login-form login-signin activeForm-wrapper p-5 pt-10">
      <div className="text-center mb-5 mb-lg-10">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="fa fa-thumbs-up"></i>
          </span>
        </div>
        <h3 className="font-size-h3 mb-2">Thank You</h3>
      </div>
      <div className="text-center my-10 my-lg-10">
        <h6>
          <strong>{successMsg || ""}</strong>
        </h6>
      </div>

      <div className="d-flex justify-content-between  navigator-wrapper mt-15 ">
        <Button
          onClick={() => window.location.reload()}
          variant="primary"
          className={`btn btn-lg py-3 w-100 ${className}`}
          style={themeColor.btnStyle}
        >
          Give Again
        </Button>
      </div>
    </div>
  );
}
