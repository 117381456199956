import React, { useState } from "react";
import { Paper, TextField } from "@material-ui/core";
import Navigator from "../../Navigator";
import { GiveOption } from "../../Forms/GiveForm/GiveOption";
import {useParams} from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Flutterwave, paystack, StripePayment } from "../../../store/actions";


export default function PaymentOption() {
    const dispatch = useDispatch();
    const history = useHistory();
    let {name} = useParams()
  const [inputValue, setInputValue] = useState({ amount: "", memo: "" });
  const [giveError, setGiveError] = useState({ giveType: "" });
  const [error, setError] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const orgInfo = useSelector(state => state.orgInfo.responseData);
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const contributionProcessingEnginesData = useSelector(
    (state) => state.contributionProcessingEnginesData?.responseData?.returnedRecords
  );
  const getSelectValue = (value) => {
    setSelectedValue(value);
  };
  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };
  const goToDashboard = () => {
    history.push("/dashboard");
  };
//   console.log(name, 'params name');
  const handlePayment = () => {
    if (!inputValue.amount) {
      setError("Enter a Give Amount");
    }
    if (!selectedValue) {
      return setGiveError({ giveType: "Select a Contribution Type" });
    }
    const paypalPayment = paymentMethod(name);
    if (inputValue.amount < parseInt(paypalPayment[0].minimumAmount)) {
      return setError(`Minimum amount is ${paypalPayment[0].minimumAmount} ${paypalPayment[0].currency}`)
    }
    if (inputValue.amount > parseInt(paypalPayment[0].maximumAmount)) {
      return setError(`Minimum amount is ${paypalPayment[0].maximumAmount} ${paypalPayment[0].currency}`)
    }
    // console.log(paypalPayment, 'pay');
    const currencyCode = paypalPayment[0].currency;
    // const currencyCode = 'USD'
    const payload = {
      cancelUrl: `${window.location.origin}/paypal/failure`,
      currencyCode,
      email: userProfileData.userEmail,
      firstName: userProfileData.userFirstName,
      lastName: userProfileData.userLastName,
      listOfContributions: [
        {
          amount: inputValue.amount,
          contributionType: selectedValue,
          memo: inputValue.memo,
        },
      ],
      mobile: userProfileData.userPhoneNumber,
      orgId: orgInfo.orgId,
      successUrl: `${window.location.origin}/paypal/success`,
      totalAmount: inputValue.amount,
    };
    name === 'PAYSTACK' && dispatch(paystack(payload));
    name === 'FLUTTERWAVE' && dispatch(Flutterwave(payload));
    name === 'STRIPE' && dispatch(StripePayment(payload));
  };
  const paymentMethod = (type) => {
    return contributionProcessingEnginesData.filter(item => {
      if(item.type === type){
        return item;
      }
    })
  }

  return (
    <Paper className="w-100 page-wrapper my-8 p-6">
      <div className="col-md-10 m-auto col-lg-6">
        <div className="text-center">
            {name === 'PAYSTACK' ? <img src={process.env.PUBLIC_URL +'/images/paystack_400.png'} height="70px" /> :
            name === 'FLUTTERWAVE' ? <img src={process.env.PUBLIC_URL +'/images/flutterwave_400.png'} height="70px" /> :
            name === 'STRIPE' ? <img src={process.env.PUBLIC_URL +'/images/stripe_400.png'} height="70px" /> :
            name === 'PAYPAL' ? 
          <img
            height="70px"
            src="images/paypal_label.png"
            alt="paypal_banner"
          /> : null}
        </div>
        <div className="d-flex my-2">
          <p className="ml-5" style={{ fontSize: "13px" }}>
            <strong></strong>
          </p>
        </div>
        <TextField
          // className="mb-8"
          name="amount"
          label="Amount"
          variant="outlined"
          type="number"
          fullWidth
          onChange={handleChange}
          value={inputValue.amount || ""}
          error={Boolean(error)}
          helperText={error}
        />
        <GiveOption getSelectValue={getSelectValue} giveError={giveError} />
        <TextField
          className="mt-8"
          label="Memo"
          name="memo"
          maxLength={100}
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          onChange={handleChange}
          inputProps={{ maxLength: 100 }}
          value={inputValue.memo || ""}
        />
        <p>{`${100 - inputValue?.memo?.length} character remaining`}</p>
        <Navigator
          goBack={goToDashboard}
          backBtnName="Cancel"
          goNext={handlePayment}
          className="py-4"
        />
      </div>
    </Paper>
  );
}
