import axios from 'axios'
import {actionTypes} from '../actionTypes'
import {defaultAction, apiUrl} from '../../utils/constants'
import { toast } from 'react-toastify'
import NotificationMesage from '../../components/Common/NotificationMesage'
import React from 'react'
import { checkNodeEnv, checkNodeEnvNextGen } from '../../utils/authHelpers';
import { axiosPayload } from '.'
import AxiosInstance from '../../utils/AxiosConfig'

export const getFollowUpByOrganization = (payload) =>async dispatch => {
    try {
        const url = checkNodeEnvNextGen(apiUrl.getFollowUpByOrganization('https://apix.churchpad.com/nextgen', payload), apiUrl.getFollowUpByOrganization('https://apps1.churchpad.com/nextgen', payload))
        const response =await AxiosInstance.get(url)
        const data = await response.data
        // console.log(data, 'data');
        if (data.responseCode === '00') {
            return dispatch(defaultAction(actionTypes.GET_FOLLOWUP_ORGANISATION_DATA_SUCCESS, data))
        } 
        return dispatch(defaultAction(actionTypes.GET_FOLLOWUP_ORGANISATION_DATA_FAILURE, data))
    } catch (error) {
       console.log(error, '======='); 
    }
}

export const getForm = (payload) => async dispatch => {
    try {
        const url = checkNodeEnvNextGen(apiUrl.getForm('https://apix.churchpad.com/nextgen', payload), apiUrl.getForm('https://apps1.churchpad.com/nextgen', payload))
        const response = await AxiosInstance.get(url)
        const data = await response.data
        // console.log(data, 'get form');
        if (data.responseCode === '00') {
            return dispatch(defaultAction(actionTypes.GET_FOLLOWUP_FORM_SUCCESS, data))
        }
        return dispatch(defaultAction(actionTypes.GET_FOLLOWUP_FORM_FAILURE, data))
    } catch (error) {
        console.log(error, '???????');
    }
}

export const getFormLegacy = (payload) => async dispatch => {
    try {
        const url = checkNodeEnv(apiUrl.imNewHereDynamicForm('live-api'), apiUrl.imNewHereDynamicForm('test-api'))
        const response = await axios(axiosPayload(url, payload))
        const data = await response.data
        // console.log(data, 'get form');
        if (data.responseCode === '00') {
            return dispatch(defaultAction(actionTypes.GET_FOLLOWUP_FORM_SUCCESS, data))
        }
        return dispatch(defaultAction(actionTypes.GET_FOLLOWUP_FORM_FAILURE, data))
    } catch (error) {
        console.log(error, '???????');
    }
}

export const submitForm = (payload, id, formId, callback) => async dispatch => {
    try {
        const url = checkNodeEnvNextGen(apiUrl.submitForm('https://apix.churchpad.com/nextgen', id, formId), apiUrl.submitForm('https://apps1.churchpad.com/nextgen', id, formId))
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_START
        })
        const token = JSON.parse(localStorage.getItem('jwtAccess'))
        // if (token) {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token['jwt-token']}` 
                }
            }
        // }
        const response = await AxiosInstance.put(url, payload, config)
        const data = response.data
        if (data.responseCode === '00') {
            callback()
            toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
            dispatch({
                type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
            })
            return dispatch(defaultAction(actionTypes.SUBMIT_FOLLOWUP_FORM_SUCCESS, data))
        }
        toast.error(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
        })
        return dispatch(defaultAction(actionTypes.SUBMIT_FOLLOWUP_FORM_FAILURE, data))
        // console.log(response, 'put form');
    } catch (error) {
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
        })
        if (error.response) {
            toast.error(<NotificationMesage message={error.response.data.responseMessage} responseStatus={error.response.data.responseCode} />)
        }
        console.log(error, '=========');
    }
}
export const submitFormWithoutToken = (payload, id, formId, callback) => async dispatch => {
    try {
        const url = checkNodeEnvNextGen(apiUrl.submitForm('https://apix.churchpad.com/nextgen', id, formId), apiUrl.submitForm('https://apps1.churchpad.com/nextgen', id, formId))
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_START
        })
        const response = await AxiosInstance.put(url, payload)
        const data = response.data
        console.log(data, 'data');
        if (data.responseCode === '00') {
            callback()
            toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
            dispatch({
                type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
            })
            return dispatch(defaultAction(actionTypes.SUBMIT_FOLLOWUP_FORM_SUCCESS, data))
        }
        toast.error(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
        })
        return dispatch(defaultAction(actionTypes.SUBMIT_FOLLOWUP_FORM_FAILURE, data))
        // console.log(response, 'put form');
    } catch (error) {
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
        })
        if (error.response) {
            toast.error(<NotificationMesage message={error.response.data.responseMessage} responseStatus={error.response.data.responseCode} />)
        }
        // console.log(error.response.data, '=========');
    }
}

export const submitFormLegacy = (payload, callback) => async dispatch => {
    try {
        const url = checkNodeEnv(apiUrl.imNewHereSubmission('live-api'), apiUrl.imNewHereSubmission('test-api'))
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_START
        })
        const response = await axios(axiosPayload(url, payload))
        const data = response.data
        if (data.responseCode === '00') {
            callback()
            toast.success(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
            dispatch({
                type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
            })
            return dispatch(defaultAction(actionTypes.SUBMIT_FOLLOWUP_FORM_SUCCESS, data))
        }
        toast.error(<NotificationMesage message={data.responseMessage} responseStatus={data.responseCode} />)
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
        })
        return dispatch(defaultAction(actionTypes.SUBMIT_FOLLOWUP_FORM_FAILURE, data))
        // console.log(response, 'put form');
    } catch (error) {
        dispatch({
            type: actionTypes.SUBMIT_FOLLOWUP_FORM_STOP
        })
        if (error.response) {
            toast.error(<NotificationMesage message={error.response.data.responseMessage} responseStatus={error.response.data.responseCode} />)
        }
        // console.log(error.response.data, '=========');
    }
}