import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { makeStyles } from "@material-ui/core/styles";
import SuccessModal from "./SuccessModal";
import GiveForm from "./GiveForm";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { getDynamicGivingCampaignInfo } from "../../store/actions/givingCampaign";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import OurSimpleCharge from "./OurSimpleCharge";
import { isEmpty } from "../../utils";
import CampaignLoader from "./CampaignLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    minWidth: "100vw",
    color: "white",
  },
  topSection: {
    backgroundImage: 'url("/images/LandingPageBackgroundPic.png")',
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    minHeight: "770px",
    flexGrow: 1,
    marginTop: "-60px",
    paddingTop: "60px",
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      minHeight: "671px",
    },
    "@media (min-width: 1680px)": {
      minHeight: "770px",
    },
  },
  indexAppBar: {
    flexGrow: 1,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    borderBottom: "0.5px solid #CBD5E1",
  },
  formAppBar: {
    flexGrow: 1,
    backgroundColor: "transparent",
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    borderBottom: "0.5px solid #E2E8F0",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    marginLeft: theme.spacing(2),
    width: "50px",
  },
  indexMenu: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
    color: "#FFFFFF",
  },
  formMenu: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
  },
  headerContent: {
    padding: "1.25rem",
    maxWidth: "37.5rem",
    textAlign: "center",
    margin: "auto",
    marginTop: "20.42rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (min-width: 1440px)": {
      marginTop: "12.42rem",
    },
  },
  typography1: {
    fontSize: "64px",
    fontWeight: "700",
    lineHeight: "84px",
    width: "734px",
    "@media (max-width: 768px)": {
      fontSize: "28px",
      lineHeight: "37.8px",
      width: "auto",
    },
  },
  typography2: {
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: "1rem",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  startGivingButton: {
    backgroundColor: "#2E53E2", // Set background color
    color: "#FFFFFF", // Set text color
    padding: "12px 24px",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#1D3C9D", // Darker color on hover
    },
  },
  startGivingButtonAppBar: {
    backgroundColor: "#2E53E2",
    color: "#FFFFFF",
    borderRadius: "100px", // Add border radius for round shape
    padding: "12px 24px",
    fontSize: "14px",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#1D3C9D",
    },
  },
  middleSection: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      columnGap: "1rem", // Add column gap for smaller devices
    },
  },
  middleSectionContainer: {
    background: theme.palette.type === "light" ? "#f1f5f9" : "#0F172A",
    height: "auto",
    width: "auto",
    padding: "6.25rem",
    paddingTop: "4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem", // Adjust padding for smaller devices
      paddingTop: " 3rem",
    },
    "@media (min-width: 1680px)": {
      padding: "6rem 18.25rem",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      padding: "6rem 11.25rem",
    },
  },
  sectionLeft: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "3rem",
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      width: "87%",
      rowGap: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      rowGap: "4rem",
    },
  },
  sectionRight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "14rem",
    "@media (min-width: 1680px)": {
      rowGap: "14rem",
      marginTop: "2rem",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      rowGap: "18rem",
      marginTop: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      rowGap: "8rem",
    },
  },
  typography3: {
    fontSize: "14px",
    fontWeight: "500",
    Height: "1.1875rem",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "1.18rem",
    marginBottom: "1.25rem",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  typography4: {
    fontSize: "24px",
    fontWeight: "600",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "2.025rem",
    marginBottom: "2.5rem",
    "@media (max-width: 768px)": {
      fontSize: "16px",
    },
    "@media (min-width: 1680px)": {
      marginBottom: "5.5rem",
    },
  },
  typography5: {
    fontSize: "20px",
    fontWeight: "400",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "1.68rem",
    left: "12.6875rem",
    lineHeight: "27px",
    "@media (max-width: 768px)": {
      fontSize: "12px",
      lineHeight: "16.2px",
    },
  },
  typography6: {
    fontSize: "20px",
    fontWeight: "400",
    color: theme.palette.type === "light" ? "#000" : "#F8FAFC",
    lineHeight: "1.68rem",
    paddingTop: "1.75rem",
    paddingLeft: "1.875rem",
    lineHeight: "22px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "16.2px",
      paddingTop: "15px",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  img1: {
    height: "35.9375rem",
    maxWidth: "100%",
    height: "auto",
    borderRadius: "0.75rem",
    marginBottom: "2.5rem",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5rem", // Increase margin bottom by 3 times for smaller devices
    },
    "@media (min-width: 1680px)": {
      marginBottom: "5.5rem",
      width: "73%",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      width: "77%",
    },
  },
  img2: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "0.75rem",
    paddingLeft: "5rem",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2rem", // Remove paddingLeft for smaller devices
    },
    "@media (min-width: 1680px)": {
      width: "85%",
    },
    "@media (min-width: 1440px) and (max-width: 1679px)": {
      width: "74%",
    },
  },
  img3: {
    maxWidth: "100%",
    width: "100%",
  },
  footer: {
    marginTop: "auto",
  },
}));

const DynamicGiveCampaign = () => {
  const currentURL = window.location.href;
  const searchParams = new URLSearchParams(new URL(currentURL).search);
  const gcid = searchParams.get("gcid");
  const [successModal, setSuccessModal] = useState(false);
  const [indexPage, setIndexPage] = useState(true);
  const [giveForm, setGiveForm] = useState(false);
  const [donatedAmount, setDonatedAmount] = useState("");
  const [giveInfo, setGiveInfo] = useState({});
  const [expiredGive, setExpiredGive] = useState({
    show: false,
    message: "",
  });

  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const giveContainerRef = useRef(null);

  const themeMode = theme.palette.type;

  const orgInfo = useSelector((state) => state.orgInfo.responseData);

  const handleFormSubmision = () => {
    setGiveForm(false);
    setSuccessModal(true);
  };

  const handleDonation = (donation) => {
    setDonatedAmount(donation);
    setIndexPage(false);
    setGiveForm(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
    setIndexPage(true);
  };

  useEffect(() => {
    dispatch(
      getDynamicGivingCampaignInfo(
        { orgId: orgInfo.orgId, gcid },
        givingCampaignCB
      )
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [indexPage]);

  //   const givingCampaignCB = (data) => {
  //     if (data.isGivingCampaignEnable !== "true") return history.push("/");
  //     setGiveInfo(data);
  //   };

  const givingCampaignCB = (data) => {
    if (data.responseCode === "00") {
      if (data.responseData.isGivingCampaignEnable !== "true")
        return history.push("/");
      setGiveInfo(data.responseData);
    }
    if (data.responseCode === "01")
      setExpiredGive({ show: true, message: data.responseMessage });
  };

  let appBarStyle = classes.indexAppBar;
  let menu = classes.indexMenu;

  if (!indexPage) {
    appBarStyle = classes.formAppBar;
    menu = classes.formMenu;
  }

  const handleHomeBtnClick = () => {
    setIndexPage(true);
    setGiveForm(false);
  };

  if (isEmpty(giveInfo)) return <CampaignLoader />;

  return (
    <>
      {successModal && (
        <SuccessModal
          onClose={handleCloseSuccessModal}
          heading="Thank You"
          subContent="Contribution sent"
          btnText="Back to Dashboard"
        />
      )}
      {!successModal && (
        <div id="give-campaign-v2">
          <GiveForm
            onSubmit={handleFormSubmision}
            donatedAmount={donatedAmount}
            giveInfo={giveInfo}
            classes={classes}
            showLogo={giveInfo.showLogo}
            logo={orgInfo.logo}
            appBarClass={appBarStyle}
            menu={menu}
            themeMode={themeMode}
            indexPage={false}
            handleHomeBtnClick={handleHomeBtnClick}
            isDynamicGiveCampaign={true}
          />

          {/* Footer section */}
          <Footer
            orgInfo={orgInfo}
            themeMode={themeMode}
            isDynamicGiveCampaign={true}
          />
        </div>
      )}
    </>
  );
};

export default DynamicGiveCampaign;
