import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { usePaymentInputs } from "react-payment-inputs";
import moment from "moment";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { getMaskedNumber, expiryDateCheck, isEmpty } from "../../utils";
import images from "react-payment-inputs/images";
import { setCardOwnerDetails } from "../../store/actions/userDashboardActions/give";
import { generateArrayOfYears, months } from "../../utils/constants";
import Navigator from "../../components/Navigator";

export const CardForm = ({ goBack, goNext, userInfo }) => {
  const newCardDetails = useSelector((state) => state.newCardDetails);

  const initialValues = {
    cardFirstName:
      newCardDetails?.cardFirstName || userInfo?.userFirstName || "",
    cardLastName: newCardDetails?.cardLastName || userInfo?.userLastName || "",
    saveCard: true,
    expirationMonth: newCardDetails?.expirationMonth || "",
    expirationYear: newCardDetails?.expirationYear || "",
    memo: newCardDetails?.memo || "",
  };

  const [cardNumber, setCardNumber] = useState(
    newCardDetails?.cardNumber ? window.atob(newCardDetails?.cardNumber) : ""
  );
  const [cvc, setCVC] = useState(newCardDetails?.cvv || "");
  const [cardErrors, setCardErrors] = useState({});
  const [showCard, setShowCard] = useState(false);

  const cardNumberValidator = ({ cardType }) => {
    if (
      cardType.displayName === "Visa" ||
      cardType.displayName === "Mastercard" ||
      cardType.type === "amex" ||
      cardType === "discover"
    ) {
      return;
    }
    return "Card must be Visa, Mastercard, Amex or Discover";
  };

  const {
    meta,
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
  } = usePaymentInputs({ cardNumberValidator });
  const { erroredInputs } = meta;

  const validationSchema = yup.object().shape({
    cardFirstName: yup.string().required("First Name is required"),
    cardLastName: yup.string().required("Last Name is required"),
    expirationMonth: yup.string().required("Expiration Month is required"),
    expirationYear: yup.string().required("Expiration Year is required"),
  });

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const arrayOfYears = generateArrayOfYears();
  const dispatch = useDispatch();


  const handleChangeCardNumber = (e) => {
    setCardNumber(`${e.target.value}`);
  };

  const handleChangeCVC = (e) => {
    setCVC(e.target.value);
  };


  const handleSubmit = (cardData) => (values) => {
    const {
      expirationMonth,
      expirationYear,
      cardFirstName,
      cardLastName,
      memo,
    } = values;
    let errors = {};

    if (!cardData.cardNumber || (meta.isTouched && erroredInputs.cardNumber)) {
      errors.cardNumber = erroredInputs.cardNumber;
    }
    if (!cardData.cvc) errors.cvc = "Enter a CVV";
    if (errors) {
      setCardErrors(errors);
    }
    let saveCard = "0";
    const cardNum = cardData?.cardNumber;
    const maskedCardNumber = getMaskedNumber(cardNum);
    const trimCardNum = cardNum.replace(/ /g, "");
    const encodedCardNumber = window.btoa(trimCardNum);
    if (values.saveCard) saveCard = "1";
    else saveCard = "0";
    let payload = {
      expirationMonth,
      expirationYear,
      maskedCardNumber,
      cardNumber: encodedCardNumber,
      saveCard,
      cvv: cvc, //this key is changed because the endpoint require cvv while the third-party component is using cvc
      cardFirstName,
      cardLastName,
      memo,
    };
    console.log(payload, "payload with new contrinbution list");
    if (isEmpty(errors)) {
      dispatch(setCardOwnerDetails(payload));
      goNext();
    }
  };

  return (
    <>
      <div className="card-form">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit({ cardNumber, cvc })}
          enableReinitialize={false}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form className="px-4" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="position-relative mb-5">
                    <input
                      className="text-dark-50 form-control credit-card-input h-auto py-3 pr-18 pl-5"
                      value={cardNumber}
                      {...getCardNumberProps({
                        onChange: handleChangeCardNumber,
                      })}
                      placeholder="Card Number"
                      type={showCard ? "text" : "password"}
                      // type="text"
                      onFocus={() => setShowCard(true)}
                      onBlur={() => setShowCard(false)}
                      // pattern="[0-9]*"
                      inputmode="numeric"
                      style={{ fontSize: "16px" }}
                    />
                    <svg
                      className="position-absolute card-img"
                      {...getCardImageProps({ images })}
                    />
                    <p className="font-weight-bold font-size-xs m-0 text-muted">
                      <span className="">Acceptable cards:</span>{" "}
                      {orgInfo.allowedCards?.map((item, index) => {
                        return (
                          <span key={index}>
                            {item}
                            {`${
                              orgInfo.allowedCards.length === index + 1
                                ? ""
                                : ","
                            }`}{" "}
                          </span>
                        );
                      })}
                    </p>
                    {cardErrors.cardNumber && (
                      <small className="text-danger errorText">
                        {erroredInputs.cardNumber}
                      </small>
                    )}
                  </div>
                  <Grid container spacing={4} className="mb-5">
                    <Grid item xs={6}>
                      <TextField
                        className=""
                        name="expirationMonth"
                        label="Exp Month"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.expirationMonth || ""}
                        error={Boolean(
                          touched.expirationMonth && errors.expirationMonth
                        )}
                        helperText={
                          touched.expirationMonth && errors.expirationMonth
                        }
                        style={{ fontSize: "16px" }}
                      >
                        {values.expirationYear === moment().format("YYYY")
                          ? months.slice(expiryDateCheck).map((item, index) => (
                              // <option key={`idx${index}`} value={item.id}>{item.value}</option>) :
                              <MenuItem value={item.id} key={item.id}>
                                {item.value}
                              </MenuItem>
                            ))
                          : months.map((item, index) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.value}
                              </MenuItem>
                            ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        className=""
                        name="expirationYear"
                        label="Exp Year"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.expirationYear || ""}
                        error={Boolean(
                          touched.expirationYear && errors.expirationYear
                        )}
                        helperText={
                          touched.expirationYear && errors.expirationYear
                        }
                        style={{ fontSize: "16px" }}
                      >
                        {arrayOfYears.map((year) => (
                          <MenuItem value={year} key={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        className=""
                        label="Card First Name"
                        name="cardFirstName"
                        maxLength={100}
                        size="small"
                        variant="outlined"
                        fullWidth
                        // defaultValue={''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cardFirstName || ""}
                        error={Boolean(
                          touched.cardFirstName && errors.cardFirstName
                        )}
                        helperText={
                          touched.cardFirstName && errors.cardFirstName
                        }
                        style={{ fontSize: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        className=""
                        label="Card Last Name"
                        name="cardLastName"
                        maxLength={100}
                        size="small"
                        variant="outlined"
                        fullWidth
                        // defaultValue={''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cardLastName || ""}
                        error={Boolean(
                          touched.cardLastName && errors.cardLastName
                        )}
                        helperText={touched.cardLastName && errors.cardLastName}
                        style={{ fontSize: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="cvv"
                        label="CVV"
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...getCVCProps({ onChange: handleChangeCVC })}
                        value={cvc || ""}
                        error={cardErrors.cvc}
                        helperText={cardErrors.cvc}
                        placeholder="CVV"
                        style={{ fontSize: "16px" }}
                      />
                    </Grid>
                  </Grid>

                  {/* <TextField
                    className=""
                    label="Memo"
                    name="memo"
                    maxLength={100}
                    size="small"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    // defaultValue={''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                    value={values.memo || ""}
                    error={Boolean(touched.memo && errors.memo)}
                    helperText={
                      (touched.memo && errors.memo) ||
                      `${values.memo.length} / 100`
                    }
                    style={{ fontSize: "16px" }}
                  /> */}

                  <div className="d-flex w-100 align-items-center justify-content-between mt-8">
                    <label htmlFor="saveCard">
                      <input
                        className="mr-3"
                        name="saveCard"
                        id="saveCard"
                        type="checkbox"
                        checked={values.saveCard}
                        onChange={handleChange}
                        value={values.saveCard || ""}
                      ></input>
                      <span>
                        Securely save this card for future contributions
                      </span>
                    </label>
                  </div>

                  <Navigator
                    goBack={goBack}
                    goNext={handleSubmit}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
