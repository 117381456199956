import { TextField } from '@material-ui/core';
import React, {useState} from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector, shallowEqual } from 'react-redux';

export default function PhoneInputComponent({defaultMoblie, handlePhone}) {
    const [mobile, setMobile] = useState('');

    const ipData = useSelector(state => state.ipData);
    React.useEffect(() => {
      if (defaultMoblie) {
        setMobile(`+${defaultMoblie}`)
      }
    }, [defaultMoblie])

    handlePhone(mobile)
    return (
        <div className="fv-plugins-icon-container">
        <span style={{marginBottom: '0.2rem', fontSize: '11px'}} className='text-muted'>Phone Number</span> 
        <PhoneInput
              international
              withCountryCallingCode
              countryCallingCodeEditable={false}
              defaultCountry={ipData ? ipData.country_code : null}
              placeholder="Mobile"
              // value={mobile}
              // inputComponent={CustomPhoneInput}
              onChange={setMobile}
              className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${(mobile && !isValidPhoneNumber(mobile)) ? 'is-invalid' : (mobile && isValidPhoneNumber(mobile)) ? 'is-valid' :  ''}`}
              name="mobile"
              error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid mobile number') : 'Mobile number is required'}
            />
        {mobile && !isValidPhoneNumber(mobile) ? <p style={{fontSize: '11px'}}>Invaild Phone Number</p> : ''}
      </div>
    )
}

