import React from 'react'
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    makeStyles,
    Tooltip 
  } from "@material-ui/core";
import { useHistory } from 'react-router';

export default function Emergency({title, data, showEditIcon=false}) {
    let history = useHistory()

    const handleUpdate = (route) => {
        history.push(route)
    }
    return (
        <Card className="pt-2" elevation={3}>
      <div className='d-flex'>
      <h5 className="p-4 m-0">{title}</h5>
      <Tooltip title="Edit" aria-label="Edit">
      <span className={`${showEditIcon ? '' : 'fa fa-pen'} icon-sm text-muted m-0 py-4 pt-5`} role="button" onClick={() => handleUpdate('/emergency')}></span>
      </Tooltip>
      </div>
      <Table className="mb-4">
        <TableBody>
        {data.map((item, ind) => (
            <TableRow key={ind}>
              <TableCell className="pl-4">{item.title}</TableCell>
              <TableCell>{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
    )
}
