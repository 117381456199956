import { checkNodeEnv } from "../../utils/authHelpers";

import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { actionTypes } from "../actionTypes";
import { defaultAction, apiUrl } from "../../utils/constants";
import NotificationMesage from "../../components/Common/NotificationMesage";
import {
  axiosPayload,
  startLoadingAction,
  resetEndpointDataAction,
  stopLoadingAction,
} from ".";

export const getUserDataSmartGive = (payload, callback) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.getUserDataSmartGive("live-api"),
      apiUrl.getUserDataSmartGive("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // toast.success(
        //   <NotificationMesage
        //     message={data.responseMessage}
        //     responseStatus={data.responseCode}
        //   />
        // );
        return callback(data);
        // return dispatch(
        //   defaultAction(actionTypes.GET_USER_DATA_SMART_GIVE_SUCCESS)
        // );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            message={data.responseMessage}
            responseStatus={data.responseCode}
          />
        );
        // return dispatch(
        //   defaultAction(actionTypes.UPDATE_A_RECURRING_PORTAL_FAILURE)
        // );
      }
    } catch (error) {}
  };
};


export const verifyRouting = (payload, callback) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.verifyRouting("live-api"),
    apiUrl.verifyRouting("test-api")
  );
  // dispatch({
  //   type: actionTypes.VERIFY_ROUTING_NUMBER_LOADING_START,
  // });
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return callback && callback(data.responseData);
      // return dispatch(
      //   defaultAction(actionTypes.VERIFY_ROUTING_NUMBER_LOADING_STOP)
      // );
    } else {
      // toast.error(
      //   <NotificationMesage
      //     responseStatus={data.responseCode}
      //     message={data.responseMessage}
      //   />
      // );
      callback &&
        callback({
          messageToShow: data.responseMessage,
          isValid: 'false',
        });
      // return dispatch(
      //   defaultAction(actionTypes.VERIFY_ROUTING_NUMBER_LOADING_STOP)
      // );
    }
  } catch (error) {
    console.log(error, "error");
    // toast.warn("Network Error. Please check your connectivity and try again");
    // dispatch({
    //   type: actionTypes.VERIFY_ROUTING_NUMBER_LOADING_STOP,
    // });
  }
};
