import {
  CircularProgress,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Navigator from "../../components/Navigator";
import { getMaskedNumber, isEmpty } from "../../utils";
import { setCheckDetails } from "../../store/actions/userDashboardActions/give";
import { debounce } from "lodash";
import { verifyRouting } from "../../store/actions/smartGive";
import "./style.css";

const CheckForm = ({ goBack, goNext, userInfo }) => {
  const dispatch = useDispatch();

  const { handleSubmit, errors, control, watch, setError } = useForm({
    mode: "onBlur",
  });

  const [routingMessage, setRoutingMessage] = useState({
    messageToShow: "",
    isValid: false,
  });
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const newCheckDetails = useSelector((state) => state.newCheckDetails);
  const { orgId } = useSelector((state) => state.orgInfo.responseData);

  const onSubmit = (data) => {
    const maskedAcctNum = getMaskedNumber(data?.accountNumber);
    const trimAccNum = data?.accountNumber.replace(/ /g, "");
    const encodedAccNumber = window.btoa(trimAccNum);
    dispatch(
      setCheckDetails({
        ...data,
        accountNumber: encodedAccNumber,
        maskedAcctNumber: maskedAcctNum,
      })
    );
    goNext();
  };

  const handleVerifyRoutingNumber = debounce((routingNumber) => {
    setSpinnerLoading(true);
    setRoutingMessage({
      messageToShow: "",
      isValid: false,
    });
    dispatch(
      verifyRouting(
        {
          orgId: orgId,
          userId: userInfo?.userId,
          routingNumber: routingNumber,
        },
        verifyRutingCB
      )
    );
  }, 700);

  const verifyRutingCB = ({ isValid, messageToShow }) => {
    setSpinnerLoading(false);
    if (isValid === "false") {
      setError("routingNumber", {
        type: "manual",
        message: messageToShow,
      });
    } else {
      setRoutingMessage({
        isValid: true,
        messageToShow,
      });
    }
  };

  const validateRoutingNumber = (value) => {
    const isValid = value.length === 9 && /^[0-9]+$/.test(value);

    if (!isValid) {
      setError("routingNumber", {
        type: "manual",
        message: "Routing Number must be 9 digits and contain only numbers.",
      });
    }

    if (isValid) handleVerifyRoutingNumber(value);

    return isValid;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-md-flex justify-content-md-between">
        <div className="fv-plugins-icon-container mr-md-3 mb-8">
          <div className="position-relative">
            <Controller
              as={
                <TextField
                  label="Account Number"
                  name="accountNumber"
                  variant="outlined"
                  fullWidth
                  error={errors.accountNumber ? true : false}
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  style={{ fontSize: "16px" }}
                />
              }
              name="accountNumber"
              control={control}
              defaultValue={
                newCheckDetails?.accountNumber
                  ? window.atob(newCheckDetails?.accountNumber)
                  : "" || ""
              }
              rules={{
                required: true,
              }}
            />
          </div>
          {errors.accountNumber?.type === "required" && (
            <span className="text-danger errorText">
              {"Account Number is Required"}
            </span>
          )}
        </div>
        <div className="fv-plugins-icon-container ml-md-3 mb-8 routing-number">
          <div className="position-relative d-flex">
            <Controller
              as={
                <TextField
                  label="Routing Number"
                  name="routingNumber"
                  variant="outlined"
                  fullWidth
                  error={errors.routingNumber ? true : false}
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  style={{ fontSize: "16px" }}
                  helperText={
                    routingMessage?.isValid && routingMessage?.messageToShow
                  }
                />
              }
              name="routingNumber"
              control={control}
              defaultValue={newCheckDetails?.routingNumber || ""}
              rules={{
                required: "Routing Number is Required",
                validate: validateRoutingNumber,
              }}
            />
            {spinnerLoading && (
              <CircularProgress
                size={20}
                className="spinner-position"
                color="default"
              />
            )}
          </div>
          {errors.routingNumber && (
            <span className="text-danger errorText">
              {/* {"Rounting Number is Required"} */}
              {errors.routingNumber.message}
            </span>
          )}
        </div>
      </div>
      <div
        className="d-flex align-items-center mb-8"
        style={{ columnGap: "10px" }}
      >
        <div className="w-100">
          <div className="position-relative w-100">
            <Controller
              as={
                <TextField
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  type="text"
                  fullWidth
                  error={errors.firstName ? true : false}
                  style={{ fontSize: "16px" }}
                />
              }
              name="firstName"
              control={control}
              defaultValue={
                newCheckDetails?.firstName || userInfo?.userFirstName
              }
              rules={{
                required: true,
              }}
            />
          </div>
          {errors.firstName && (
            <span className="text-danger errorText">
              {"First Name is Required"}
            </span>
          )}
        </div>

        <div className="w-100">
          <div className="position-relative">
            <Controller
              as={
                <TextField
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  type="text"
                  fullWidth
                  error={errors.lastName ? true : false}
                  style={{ fontSize: "16px" }}
                />
              }
              name="lastName"
              control={control}
              defaultValue={newCheckDetails?.lastName || userInfo?.userLastName}
              rules={{
                required: true,
              }}
            />
          </div>
          {errors.lastName && (
            <span className="text-danger errorText">
              {"Last Name is Required"}
            </span>
          )}
        </div>
      </div>
      {/* <div>
          <div className="fv-plugins-icon-container">
            <div className="position-relative">
              <Controller
                as={
                  <TextField
                    label="Memo"
                    type="text"
                    name="memo"
                    maxLength={100}
                    fullWidth
                    minRows={3}
                    multiline
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                    helperText={`${memoFieldValue?.length || 0} / 100`}
                    style={{ fontSize: "16px" }}
                  />
                }
                name="memo"
                control={control}
                defaultValue={newCheckDetails?.memo || ""}
              />
            </div>
          </div>
        </div> */}
      {/* <div>
          <div className="fv-plugins-icon-container">
            <div className="position-relative">
              <FormControlLabel
                control={
                  <Controller
                    as={<Checkbox name="saveCard" />}
                    name="saveCard"
                    control={control}
                    defaultValue={newCheckDetails?.saveCard || ""}
                  />
                }
                label="Save this checking account information for future contributions"
              />
            </div>
          </div>
        </div> */}
      <Navigator goBack={goBack} goNext={goNext} type="submit" />
    </form>
  );
};

export default CheckForm;
