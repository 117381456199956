import React, {useState, useEffect} from 'react';
import ModalComponent from '../../Common/Modal';
import {ResizeableModalWithoutBtn} from '../../Common/ResizeableModal';
import {TextField, FormControlLabel, Checkbox, Paper} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  getUserFamilyDataMportal,
  searchForSpouse,
  setOrUnsetSpouse,
} from '../../../store/actions/userDashboardActions/spouse';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import styled from 'styled-components';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import screenSize from '../../Common/screenSize';
import ConfirmationDialog from '../../Common/ConfirmDialog';
import {ButtonContainer} from '../../Common/Button';
import {getUserProfile} from '../../../store/actions';
import {useThemeColor} from '../../../utils/customHooks/useThemeColor';
import './Spouse.scss';

export default function Spouse({redirectToFamily}) {
  const dispatch = useDispatch();
  const secondOrgInfo = useSelector(
    (state) => state.secondOrgInfo.responseData,
    shallowEqual
  );
  const searchData = useSelector(
    (state) => state.searchForSpouse.responseData,
    shallowEqual
  );
  const setOrUnset = useSelector(
    (state) => state.setOrUnsetSpouse,
    shallowEqual
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );
  const userFamilyDetails = useSelector(
    (state) => state.getUserFamilyDataMportal.responseData,
    shallowEqual
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const themeColor = useThemeColor();
  const { width } = screenSize();

  const userData = JSON.parse(localStorage.getItem('user'));
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isConfrim, setIsConfirm] = useState(false);
  const [indexData, setIndexData] = useState('');
  const [selectedItem, setSelectedItem] = useState();
  const [isUnset, setIsUnset] = useState(false);

  const handleChange = (e) => {
    if (e.target.value.length >= 3) {
      dispatch(
        searchForSpouse({
          orgId: orgInfo.orgId,
          userId: userData.responseData.userId,
          searchString: e.target.value,
        })
      );
    }
  };
  const reloadPage = () => {
    handleClose();
    dispatch(
      getUserFamilyDataMportal({
        orgId: orgInfo.orgId,
        userId: user?.responseData?.userId,
      })
    );
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsConfirm(false);
    setIsUnset(false);
  };

  const handleChangeCheck = (index) => {
    setIndexData(index);
    const data = searchData?.returnedRecords[index];
    setSelectedItem(data);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    // setSelectedItem({})
  };

  const handleConfirm = () => {
    // if (!selectedItem) return;
    setIsConfirm(true);
    setIsOpen(false);
  };

  const handleUnsetAndSet = (id, option) => {
    dispatch(
      setOrUnsetSpouse(
        {
          orgId: orgInfo.orgId,
          userId: userData.responseData.userId,
          spouseUserId: id,
          setOrUnsetSwitch: option,
        },
        reloadPage
      )
    );
  };

  return (
    <SpouseWrapper className="spouse-wrapper">
      <div className=" col-xl-12 ">
        {userFamilyDetails?.userSpouseInfo !== null && userFamilyDetails?.userSpouseInfo.length !== 0 && (
          <Paper elevation={3} className="my-6">
            <div className="row w-100 m-0">
              <div className="col-sm-5 p-0 text-center col-xl-3">
                {/* {console.log(
                  userProfileData?.userSpouseInfo?.spousePicture,
                  'userProfileData?.userSpouseInfo?.spousePicture'
                )} */}
                <img
                  src={userFamilyDetails?.userSpouseInfo?.spousePicture}
                  alt={userFamilyDetails?.userSpouseInfo?.spouseFirstName}
                  className="user-image-holder img-fluid"
                  onError={(e) => {
                    if (
                      e.target.src !==
                      userFamilyDetails?.userSpouseInfo?.spousePicture
                    )
                      e.target.src = 'profile-placeholder-image.png';
                  }}
                />
              </div>
              <div className="col-12 col-sm-7 p-10">
                <p className="h5 pt-5 pt-md-0 pl-md-0">
                  Spouse: {userFamilyDetails?.userSpouseInfo?.spouseTitle}{' '}
                  {userFamilyDetails?.userSpouseInfo?.spouseFirstName}{' '}
                  {userFamilyDetails?.userSpouseInfo?.spouseLastName}
                </p>
                {secondOrgInfo?.usersGeneralSettings?.spouseSetup
                  ?.allowUsersToUnsetSpouse &&
                  userFamilyDetails?.userSpouseInfo?.spouseFirstName && (
                    <div style={{paddingTop: '2rem'}}>
                      <ButtonContainer
                        style={{cursor: 'pointer'}}
                        className="pb-0 mb-md-0"
                        onClick={() => setIsUnset(true)}
                        backgroundColor={themeColor.color}
                      >
                        Unset spouse
                        <p className="mb-0">
                          {/* <span className="text-white">Unset spouse</span>{' '} */}
                          <span
                            className="fa fa-cut text-white icon-size pl-1"
                            style={{verticalAlign: 'middle'}}
                          ></span>
                        </p>
                      </ButtonContainer>
                    </div>
                  )}
              </div>
            </div>
          </Paper>
        )}
        <div className="w-100 un-set-spouse">
          <span>
            {/* {secondOrgInfo?.orgGeneralSettings?.allowMembersToUnsetSpouse ===
            "1" && userProfileData?.userSpouseInfo?.spouseFirstName && (
              <span data-bs-toggle="tooltip" data-bs-placement="top" title="Unset as spouse" className=''>
            <ButtonContainer backgroundColor={backgroundColor} className=" mb-md-0" onClick={() => setIsUnset(true)}>
              <small><span className="">Unset spouse</span> <span className="fa fa-cut text-white icon-size pl-1 " style={{verticalAlign: 'middle'}}></span></small> 
             </ButtonContainer>
             </span>
           )} */}
            {!userFamilyDetails?.userSpouseInfo?.spouseFirstName &&
              secondOrgInfo?.usersGeneralSettings?.spouseSetup
                ?.allowUsersToSetSpouse && (
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Set as spouse"
                >
                  <ButtonContainer
                    backgroundColor={themeColor.color}
                    style={{cursor: 'pointer'}}
                    className="mb-md-0"
                    onClick={() => setIsOpen(true)}
                  >
                    <small>
                      <span className="pr-2">Set spouse</span>{' '}
                      <span className="fas fa-handshake text-white icon-size"></span>
                    </small>
                  </ButtonContainer>
                </span>
              )}
          </span>
        </div>
      </div>

      <ResizeableModalWithoutBtn
        className="ml-5"
        modalState={isOpen}
        title={!isConfrim && 'Search for a spouse'}
        resetModalState={handleCloseModal}
        hideFooter={true}
        // buttonName={!isConfrim && 'Set Spouse'}
        Spouse
        setIsConfirm={handleConfirm}
        backgroundColor={themeColor.color}
        showClose={false}
      >
        {!isConfrim && (
          <>
            <TextField
              variant="outlined"
              label="Search for spouse"
              onChange={handleChange}
              fullWidth
            />
            <TableContainer component={Paper}>
              <Table className="">
                {/* <caption>List of spouse</caption> */}
                <TableHead>
                  <TableRow>
                    <TableCell>Picture</TableCell>
                    <TableCell>First</TableCell>
                    <TableCell>Last</TableCell>
                    {width >= 720 && (
                      <TableCell align="center">Phone Number</TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchData?.returnedRecords?.map((item, index) => {
                    // item.isChecked = false;
                    return (
                      <TableItem
                        item={item}
                        key={index}
                        handleChangeCheck={() => handleChangeCheck(index)}
                        isChecked={index === indexData}
                        index={index}
                        width={width}
                        themeColor={themeColor}
                        handleConfirm={handleConfirm}
                      />
                    );
                  })}
                  {searchData?.returnedRecords === null && (
                    <p>No record Found</p>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </ResizeableModalWithoutBtn>

      {/* <ModalComponent
        show={isOpen}
        onHide={handleCloseModal}
        title={!isConfrim && "Search for a spouse"}
        width="65"
        buttonName={!isConfrim && "Set Spouse"}
        Spouse
        setIsConfirm={handleConfirm}
        backgroundColor={themeColor.color}
        hideFooter={true}
      >
        {!isConfrim && (
          <>
            <TextField
              variant="outlined"
              label="Search for spouse"
              onChange={handleChange}
              fullWidth
            />
            <div className="table-responsive">
              <table className="table table table-hover">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Picture</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    {width >= 720 && <th scope="col">
                      Phone Number
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  {searchData?.returnedRecords?.map((item, index) => {
                    return (
                      <TableItem
                        item={item}
                        key={index}
                        handleChangeCheck={() => handleChangeCheck(index)}
                        isChecked={index === indexData}
                        index={index}
                        width={width}
                      />
                    );
                  })}
                  {searchData?.returnedRecords === null && (
                    <p>No record Found</p>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </ModalComponent> */}
      <ConfirmationDialog
        open={isConfrim}
        onConfirmDialogClose={() => setIsConfirm(false)}
        text={`Are you sure you want to set ${selectedItem?.firstName} ${selectedItem?.lastName} as your spouse?`}
        onYesClick={() => handleUnsetAndSet(selectedItem.userId, 1)}
        isLoading={setOrUnset.loading}
      />
      <ConfirmationDialog
        open={isUnset}
        onConfirmDialogClose={() => setIsUnset(false)}
        text={`Are you sure you want to unset ${userFamilyDetails?.userSpouseInfo?.spouseFirstName} ${userProfileData?.userSpouseInfo?.spouseLastName} as your spouse?`}
        onYesClick={() =>
          handleUnsetAndSet(userFamilyDetails?.userSpouseInfo?.spouseUserId, 0)
        }
        isLoading={setOrUnset.loading}
      />
    </SpouseWrapper>
  );
}

const SpouseWrapper = styled.div``;

const TableItem = ({item, handleChangeCheck, isChecked, width, themeColor, handleConfirm}) => {
  return (
    <TableRow
      className={isChecked && 'table-active'}
      onClick={handleChangeCheck}
    >
      <TableCell>
        <div className="symbol symbol-30 mr-3">
          <img
            src={item.profilePicture}
            alt="user"
            className="rounded-circle"
            onError={(e) => {
              if (e.target.src !== item.profilePicture)
                e.target.src =
                  'https://dev.churchpad.com/xTextersImageFilesLoc/i/2/vi2__1606075062__yawxmoxnukq8__ddesign9.png';
            }}
          />
        </div>
      </TableCell>
      <TableCell>{item.firstName}</TableCell>
      <TableCell>{item.lastName}</TableCell>
      {width >= 720 && (
        <TableCell align="center">{`XX${item.last4DigitsPhoneNumber}`}</TableCell>
      )}
      <TableCell>
        {/* <FormControlLabel
          control={
            <Checkbox
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
              name="checkedH"
              checked={isChecked}
              // onChange={() => {item.isChecked = true}}
            />
          }
        /> */}
        <ButtonContainer
                    backgroundColor={themeColor.color}
                    style={{cursor: 'pointer'}}
                    className="mb-md-0"
                    onClick={handleConfirm}
                  >
                    <small>
                      <span className="pr-2">Set spouse</span>{' '}
                      <span className="fas fa-handshake text-white icon-size"></span>
                    </small>
                  </ButtonContainer>
      </TableCell>
    </TableRow>
  );
};
